import "./style.scss";
import { NursingCareService } from "./NursingCareService/NursingCareService";
import { AccidentCareService } from "./AccidentCareService/AccidentCareService";
import { Service } from "./Service/Service";
import { CareEnhance } from "./CareEnhance/CareEnhance";
import { DisabilityEnhance } from "./DisabilityEnhance/DisabilityEnhance";
import { Tabs } from "antd";
import { useMemo } from "react";
export default function CareService() {
  const items = useMemo(() => [
    {
      key: "1",
      label: "介護サービス名称",
      children: <NursingCareService />,
    },
    {
      key: "2",
      label: "障害サービス名称",
      children: <AccidentCareService />,
    },
    {
      key: "3",
      label: "サービス内容",
      children: <Service />,
    },
    {
      key: "4",
      label: "処遇改善加算(介護)",
      children: <CareEnhance />,
    },
    {
      key: "5",
      label: "処遇改善加算(障害)",
      children: <DisabilityEnhance />,
    },
  ], [])
  return (
    <div>
      <div className="page-title">管理メニュー　ケアマネプラン登録</div>
      <div className="page-container">
        <Tabs items={items} />
      </div>
    </div>
  );
}
