import axiosClient from "api/axiosClient";
import ROUTES from "constant/routes";
import Cookies from "js-cookie";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import userSlice from "store/slice/user";
import useService from "./useService";

export default function useAuthenticate() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const service = useService();
  const user = useSelector((state: RootState) => state.user);
  const isAuthenticated = Boolean(user) && !isLoading;
  useLayoutEffect(() => {
    try {
      if (!Cookies.get("access_token")) {
        setIsLoading(false);
      } else if (!user?.role) {
        setIsLoading(true);
        axiosClient.get(service.ME).then((res) => {
          dispatch(userSlice.actions.storeUser(res?.data));
          dispatch(userSlice.actions.setIdCurrent(res?.data?.id));
          dispatch(userSlice.actions.setRoleCls(res?.data?.data_role_cls));
          dispatch(
            userSlice.actions.setIsMessageUnread(res?.data?.is_message_unread)
          );
          dispatch(
            userSlice.actions.setIsNoticeUnread(res?.data?.is_notice_unread)
          );
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  return { isLoading, isAuthenticated };
}
