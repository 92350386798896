import { useSelector } from "react-redux";
import { RootState } from "store";

interface Services {
  LOGIN: string;
  NURSING_STAFF: string;
  ME: string;
  PATIENTS: string;
  PUBLIC_EXPENSE: string;
  NURSING_CARE_PLAN: string;
  DISABILITY_CARE_PLAN: string;
  SERVICES: string;
  SERVICE_TYPES: string;
  TREATMENT_IMPROVEMENTS: string;
  POST_CODE: string;
  INSURANCE_CARDS: string;
  DISTRICTS: string;
  CITIES: string;
  ADDRESSES: string;
  INFO_ADDRESS_BY_CODE: string;
  EVALUE_ONES: string;
  EVALUE_TWOS: string;
  PAYMENTS: string;
  NURSING_CARE_HISTORIES: string;
  EQUIPMENT_SERVICES: string;
  EQUIPMENT_SERVICE_PAYMENTS: string;
  CHANGE_HISTORIES: string;
  STAFF: string;
  RECEIPTS: string;
  USAGE_BILLINGS: string;
  RENT_BILLINGS: string;
  PAYMENT_HISTRORY: string;
  BANK_INFOS: string;
  BILLINGS: string;
  NURSING_SHIFT_MANAGES: string;
  SCHEDULES: string;
  SHIFT_REGISTRATIONS: string;
  SHIFT_MANAGEMENTS: string;
  SCHEDULE_DATES: string;
  HS_ADMIN: string;
  HS_STAFF: string;
}
const useService = () => {
  const { role } = useSelector((state: RootState) => state.user);
  // là những endpoint có prefix /admin hoặc /staff, /client ở đầu
  const prefixServices: Partial<Services> = {
    NURSING_STAFF: "nursing_staffs",
    PATIENTS: "patients",
    PUBLIC_EXPENSE: "public_expenses",
    NURSING_CARE_PLAN: "nursing_care_plans",
    DISABILITY_CARE_PLAN: "disability_care_plans",
    SERVICES: "services",
    SERVICE_TYPES: "service_types",
    TREATMENT_IMPROVEMENTS: "treatment_improvements",
    INSURANCE_CARDS: "insurance_cards",
    EVALUE_ONES: "evalue_ones",
    EVALUE_TWOS: "evalue_twos",
    PAYMENTS: "payments",
    NURSING_CARE_HISTORIES: "nursing_care_histories",
    EQUIPMENT_SERVICES: "equipment_services",
    EQUIPMENT_SERVICE_PAYMENTS: "equipment_service_payments",
    CHANGE_HISTORIES: "change_histories",
    STAFF: "staff",
    RECEIPTS: "receipts",
    USAGE_BILLINGS: "usage_billings",
    RENT_BILLINGS: "rent_billings",
    PAYMENT_HISTRORY: "payment_histories",
    BANK_INFOS: "bank_infos",
    BILLINGS: "billings",
    NURSING_SHIFT_MANAGES: "nursing_shift_manages",
    SCHEDULES: "schedules",
    SHIFT_REGISTRATIONS: "shift_registrations",
    SHIFT_MANAGEMENTS: "shift_managements",
    SCHEDULE_DATES: "schedule_dates"
  };
  // là những api không có prefix
  const originalServices = {
    POST_CODE: "post_code",
    DISTRICTS: "districts",
    CITIES: "cities",
    ADDRESSES: "addresses",
    INFO_ADDRESS_BY_CODE: "info_address_by_code",
    LOGIN: "login",
    ME: "me",
    HS_ADMIN: "admin_home_system",
    HS_STAFF: "staff_home_system"
  };

  const result: Services = {
    ...originalServices,
    ...(Object.keys(prefixServices).reduce((curr, key) => {
      return {
        ...curr,
        [key]: `${role}/${prefixServices[key as keyof Services]}`,
      };
    }, {}) as Services),
  };

  return result;
};

export default useService;
