import React, { useEffect, useState } from 'react';
import Table from 'component/admin/table/'
import type { ColumnsType } from 'antd/es/table';
import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from "react-router-dom";
import ROUTES from "constant/routes";
import { ButtonOutline, ButtonSolid } from 'component/button';
import { Styles } from './styled';
import axiosClient from 'api/axiosClient';
import useService from "hook/useService";
import { toast } from 'react-toastify';

interface DataType {
  key: string;
  id: string;
  service_name: string;
  display_name: string;
  address: string;
}
const columns: ColumnsType<DataType> = [
  {
    key: 'sort',
    className: 'column-sort-custom',
    width: 60
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'サービス名称',
    dataIndex: 'service_name',
  },
  {
    title: '表示名',
    dataIndex: 'display_name',
    className: 'column-action-custom',
  },
  {
    align: "center",
    title: "",
    dataIndex: "action",
    width: 200,
    render: (_, record) => (
      <Link to={`${record.id}`}>
        <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
          詳細
        </ButtonOutline>
      </Link>
    ),
  },
];
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

export default function NursingCareList() {
  const [loading, setLoading] = useState<boolean>(false);
  const service = useService();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [isChange, setIsChange] = useState(false);

  const Row = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'sort') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
      setIsChange(true);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: `${service.HS_ADMIN}/service_home_systems`,
        method: "get",
      });
      const results = res?.data?.data?.map((item: any) => {
        return {...item, key: item?.position}
      })
      setDataSource(results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const updatePostions = async() => {
    const records = dataSource?.map((item, index) => {
      return {id: item?.id, position: index + 1}
    })
    try {
      setLoading(true);
      const res = await axiosClient({
        url: `${service.HS_ADMIN}/service_home_systems/update_positions`,
        method: "put",
        data: {records: records}
      });
      if(res) {
        fetchData();
        toast.success(res.data.success);
        setIsChange(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Styles>
      <div className="page-title">介護内容登録・在庫</div>
      <div className="page-container">
        <div className="flex justify-end">
          <Link to={`${ROUTES.CREATE}`}>
            <ButtonSolid className='!px-[40px]'>新規追加</ButtonSolid>
          </Link>
        </div>
        <p className='text-[16px] leading-[27px] font-bold mb-[-16px]'>■介護内容　一覧 (介護記録へ表示)　</p>
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              columns={columns}
              data={dataSource}
              pagination={false}
            />
          </SortableContext>
        </DndContext>
        {
          isChange && (
            <div className='flex justify-end gap-x-[8px]'>
              <ButtonOutline className='' onClick={() => {fetchData(); toast.error("更新に失敗しました"); setIsChange(false)}}>キャンセル</ButtonOutline>
              <ButtonSolid className='!px-[54px]' onClick={updatePostions}>保存</ButtonSolid>
            </div>
          )
        }
      </div>
    </Styles>
  )
}
