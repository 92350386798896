import { TabsProps } from "antd";
import HiddenTab from "component/tab";
import { useState } from "react";
import DepositList from "./List";
import DepositDetail from "./Detail";

export interface ITabData {
  activeKey: string;
  data?: {
    selectedId: number;
  };
}

export default function DespositHistory() {
  const [subTab, setSubTab] = useState<ITabData>({
    activeKey: "list",
  });
  const [record, setRecord]: any = useState({})
  const items: TabsProps["items"] = [
    {
      key: "list",
      label: "請求一覧",
      children: (
        <DepositList
          onChangeTab={setSubTab}
          isActive={subTab.activeKey === "list"}
          setRecord={setRecord}
        />
      ),
    },
    {
      key: "detail",
      label: "入金履歴",
      children: (
        <DepositDetail
          onChangeTab={setSubTab}
          isActive={subTab.activeKey === "detail"}
          record={record}
        />
      ),
    },
  ];
  return <HiddenTab activeKey={subTab.activeKey} items={items} />;
}
