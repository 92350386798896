import {
  Button,
  Table,
  Tbody,
  Text,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  TableContainer,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  FlexRow,
  StyleCheckbox,
  StyleSelect,
  StyleTd,
  StyleTr,
} from "component/StyledComponent";
import { useState } from "react";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import { HOURS, MINUTES } from "utils";

const dataService = [
  {
    value: "身体介護1",
    name: "身体介護1",
  },
  {
    value: "身体介護2",
    name: "身体介護2",
  },
];

const dataServiceContent = [
  {
    value: "入浴",
    name: "入浴",
  },
  {
    value: "口腔ケア・排泄介助",
    name: "口腔ケア・排泄介助",
  },
];

const TableDailySchedule = ({
  totalDate,
  totalDayWeek,
  schedule,
  setSchedule,
}: any) => {
  const startModal = useDisclosure();

  const endModal = useDisclosure();
  // const [schedule, setSchedule] = useState([
  //   {
  //     serviceName: '身体介護1',
  //     offerStartHrs: '11',
  //     offerStartMin: '00',
  //     offerEndHrs: '11',
  //     offerEndMin: '30',
  //     serviceContent: '入浴',
  //     date: []
  //   }
  // ])
  const [listOpen, setListOpen] = useState([{ isOpen: false }]);

  const [listOpenEnd, setListOpenEnd] = useState([{ isOpen: false }]);
  const [testSet, setTestSet] = useState(1);

  const onPlus = () => {
    const plusItem = schedule;
    plusItem.push({
      serviceName: "",
      offerStartHrs: "11",
      offerStartMin: "00",
      offerEndHrs: "11",
      offerEndMin: "30",
      serviceContent: "",
      date: [],
    });
    const open = listOpen;
    open.push({ isOpen: false });
    setListOpenEnd(open);
    setListOpen(open);
    setSchedule(plusItem);
    setTestSet(testSet + 1);
  };

  const onMinus = () => {
    const minusItem = schedule;
    minusItem.pop();
    const open = listOpen;
    open.pop();
    setListOpenEnd(open);
    setListOpen(open);
    setSchedule(minusItem);
    setTestSet(testSet - 1);
  };

  const onChangeOfferTime = (evt: any, index: any) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setSchedule((prevValue: any) => {
      return prevValue.map((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return { ...item, [name]: value };
        } else {
          return item;
        }
      });
    });
  };

  const onChangeDateTime = (evt: any, index: any, date: any) => {
    const name = evt.target.name;
    // const value = evt.target.checked
    const tempDate = schedule[index].date;
    tempDate.push(date);
    setSchedule((prevValue: any) => {
      return prevValue.map((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return { ...item, [name]: tempDate };
        } else {
          return item;
        }
      });
    });
  };

  const onOpenModal = (evt: any, index: any) => {
    evt.preventDefault();
    setListOpen((prevValue: any) => {
      return prevValue.map((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return { ...item, isOpen: true };
        } else {
          return item;
        }
      });
    });
  };

  const onCloseModal = (evt: any, index: any) => {
    evt.preventDefault();
    setListOpen((prevValue: any) => {
      return prevValue.map((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return { ...item, isOpen: false };
        } else {
          return item;
        }
      });
    });
  };

  const onOpenModalEnd = (evt: any, index: any) => {
    evt.preventDefault();
    setListOpenEnd((prevValue: any) => {
      return prevValue.map((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return { ...item, isOpen: true };
        } else {
          return item;
        }
      });
    });
  };

  const onCloseModalEnd = (evt: any, index: any) => {
    evt.preventDefault();
    setListOpenEnd((prevValue: any) => {
      return prevValue.map((item: any, itemIndex: any) => {
        if (itemIndex === index) {
          return { ...item, isOpen: false };
        } else {
          return item;
        }
      });
    });
  };

  const renderEndModal = (index: any) => {
    return (
      <Modal
        isOpen={listOpenEnd[index].isOpen}
        size="xs"
        onClose={endModal.onClose}
      >
        {/* <ModalOverlay /> */}
        <ModalContent padding="5px" width="162px" position="absolute" top="35%">
          <ModalHeader px="15px" fontSize="16px">
            提供時間
          </ModalHeader>
          <ModalCloseButton onClick={(evt) => onCloseModalEnd(evt, index)} />
          <ModalBody px="15px" className="flex flex-row">
            <StyleSelect
              _placeholder={{ color: "var(--placeholder-color)" }}
              fontSize="11px"
              value={schedule[index].offerEndHrs}
              onChange={(evt) => onChangeOfferTime(evt, index)}
              name="offerEndHrs"
              style={{ fontWeight: "normal", borderRadius: "5px" }}
              width="80px"
              height="40px"
            >
              {HOURS?.map((item: any, index: any) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </StyleSelect>
            <Text mx="5px" mt="6px">
              :
            </Text>
            <StyleSelect
              _placeholder={{ color: "var(--placeholder-color)" }}
              fontSize="11px"
              value={schedule[index].offerEndMin}
              onChange={(evt) => onChangeOfferTime(evt, index)}
              name="offerEndMin"
              style={{ fontWeight: "normal", borderRadius: "5px" }}
              width="80px"
              height="40px"
            >
              {MINUTES?.map((item: any, index: any) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </StyleSelect>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  const renderStartModal = (index: any) => {
    return (
      <Modal
        isOpen={listOpen[index].isOpen}
        size="xs"
        onClose={startModal.onClose}
      >
        {/* <ModalOverlay /> */}
        <ModalContent padding="5px" width="162px" position="absolute" top="35%">
          <ModalHeader px="15px" fontSize="16px">
            提供時間
          </ModalHeader>
          <ModalCloseButton onClick={(evt) => onCloseModal(evt, index)} />
          <ModalBody px="15px" className="flex flex-row">
            <StyleSelect
              onChange={(evt) => onChangeOfferTime(evt, index)}
              name="offerStartHrs"
              value={schedule[index].offerStartHrs}
              _placeholder={{ color: "var(--placeholder-color)" }}
              fontSize="11px"
              style={{ fontWeight: "normal", borderRadius: "5px" }}
              width="80px"
              height="40px"
            >
              {HOURS?.map((item: any, index: any) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </StyleSelect>
            <Text mx="5px" mt="6px">
              :
            </Text>
            <StyleSelect
              _placeholder={{ color: "var(--placeholder-color)" }}
              fontSize="11px"
              value={schedule[index].offerStartMin}
              onChange={(evt) => onChangeOfferTime(evt, index)}
              name="offerStartMin"
              style={{ fontWeight: "normal", borderRadius: "5px" }}
              width="80px"
              height="40px"
            >
              {MINUTES?.map((item: any, index: any) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </StyleSelect>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <div>
      <TableContainer>
        <Table className="text-xs">
          <Tbody>
            <StyleTr style={{ height: "35px", background: "#fff" }}>
              <HeadTd>サービス名称</HeadTd>
              <HeadTd>提供時間</HeadTd>
              <HeadTd>サービス名称。</HeadTd>
              <HeadTd
                className="flex flex-col"
                style={{ padding: "0px", width: "730px" }}
              >
                <FlexRow style={{ width: "730px" }}>
                  {totalDate &&
                    totalDate.map((item: any, index: any) => (
                      <StyleTd
                        style={{
                          width: "22px",
                          padding: "0px 2px",
                          height: "17px",
                        }}
                        key={index}
                      >
                        <Text fontSize="12px" width="20px">
                          {item}
                        </Text>
                      </StyleTd>
                    ))}
                  <HeadTd
                    style={{
                      height: "17px",
                      width: "60px",
                      borderRight: "0px",
                    }}
                    p="0px"
                  >
                    合計
                  </HeadTd>
                </FlexRow>
                <FlexRow style={{ width: "730px" }}>
                  {totalDayWeek &&
                    totalDayWeek.map((item: any, index: any) => (
                      <StyleTd
                        style={{
                          width: "22px",
                          padding: "0px 2px",
                          height: "17px",
                        }}
                        key={index}
                      >
                        <Text fontSize="12px" width="20px">
                          {item}
                        </Text>
                      </StyleTd>
                    ))}
                  <HeadTd
                    style={{
                      height: "17px",
                      width: "60px",
                      borderRight: "0px",
                    }}
                    p="0px"
                  >
                    回数
                  </HeadTd>
                </FlexRow>
              </HeadTd>
            </StyleTr>
            {schedule &&
              schedule.map((scheduleItem: any, index: any) => (
                <StyleTr key={index}>
                  <BodyTd>
                    <StyleSelect
                      _placeholder={{ color: "var(--placeholder-color)" }}
                      fontSize="11px"
                      style={{
                        fontWeight: "normal",
                        borderRadius: "5px",
                        width: "150px",
                      }}
                      height="40px"
                      name="serviceName"
                      value={schedule[index].serviceName}
                      onChange={(evt) => onChangeOfferTime(evt, index)}
                    >
                      {dataService?.map((item: any, index: any) => (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </StyleSelect>
                  </BodyTd>
                  <BodyTd>
                    <FlexRow style={{ position: "relative" }}>
                      <StyleSelect
                        onClick={(evt) => onOpenModal(evt, index)}
                        placeholder={`${scheduleItem.offerStartHrs}:${scheduleItem.offerStartMin}`}
                        cursor="pointer"
                        _placeholder={{ color: "var(--placeholder-color)" }}
                        fontSize="11px"
                        style={{ fontWeight: "normal", borderRadius: "5px" }}
                        height="40px"
                        width="80px"
                      />
                      <Text mx="5px" mt="6px" fontSize="20px">
                        ~
                      </Text>
                      <StyleSelect
                        onClick={(evt) => onOpenModalEnd(evt, index)}
                        placeholder={`${scheduleItem.offerEndHrs}:${scheduleItem.offerEndMin}`}
                        cursor="pointer"
                        _placeholder={{ color: "var(--placeholder-color)" }}
                        fontSize="11px"
                        style={{ fontWeight: "normal", borderRadius: "5px" }}
                        height="40px"
                        width="80px"
                      />
                      {renderStartModal(index)}
                      {renderEndModal(index)}
                    </FlexRow>
                  </BodyTd>
                  <BodyTd>
                    <StyleSelect
                      _placeholder={{ color: "var(--placeholder-color)" }}
                      fontSize="11px"
                      style={{
                        fontWeight: "normal",
                        borderRadius: "5px",
                        width: "150px",
                      }}
                      height="40px"
                      name="serviceContent"
                      value={schedule[index].serviceContent}
                      onChange={(evt) => onChangeOfferTime(evt, index)}
                    >
                      {dataServiceContent?.map((item: any, index: any) => (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </StyleSelect>
                  </BodyTd>

                  <BodyTd paddingX="0px" className="flex flex-row">
                    {totalDate &&
                      totalDate.map((date: any, indexDate: any) => (
                        <div
                          key={indexDate}
                          style={{
                            width: "22px",
                            padding: "0px",
                            height: "50px",
                            border: "1px solid var(--secondary-color)",
                          }}
                        >
                          <StyleCheckbox
                            mt="18px"
                            colorScheme="brand2"
                            name="date"
                            onChange={(evt: any) =>
                              onChangeDateTime(evt, index, date)
                            }
                          />
                        </div>
                      ))}
                    <BodyTd px="16px" style={{ borderRight: "0px" }}>
                      <Text fontSize="11px" mt="15px">
                        25
                      </Text>
                    </BodyTd>
                  </BodyTd>
                </StyleTr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <FlexRow
        style={{ justifyContent: "flex-end", marginRight: "75px" }}
        className="mt-2"
      >
        <Button
          onClick={onPlus}
          variant="outline"
          transform="scale(0.75)"
          borderRadius="50%"
          width="40px"
          height="40px"
          p="0px"
          border="1px solid #000"
        >
          <img src={Plus} alt="" style={{ height: "20px", width: "20px" }} />
        </Button>
        <Button
          onClick={onMinus}
          variant="outline"
          transform="scale(0.75)"
          borderRadius="50%"
          width="40px"
          height="40px"
          p="0px"
          border="1px solid #000"
        >
          <img src={Minus} alt="" style={{ height: "20px", width: "20px" }} />
        </Button>
      </FlexRow>
    </div>
  );
};

const HeadTd = styled(StyleTd)({
  height: "35px",
  paddingTop: "0px",
  paddingBottom: "0px",
  whiteSpace: "nowrap",
  background: "var(--secondary-color) 0% 0% no-repeat padding-box",
});

const BodyTd = styled(StyleTd)({
  height: "50px",
  paddingTop: "0px",
  paddingBottom: "0px",
  background: "#fff",
});

export default TableDailySchedule;
