import { useEffect, useRef } from "react";

const withSelect = (Component: any) => {
  const WithSelect = (props: any) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const onSelect = (e: any) => {
        if (props.allowSelect) {
          containerRef.current?.classList.add("selected");
          props.onAreaSelect?.(
            true,
            props.parentRef.current.getElementsByClassName("selected")
          );
        }
      };
      const onDeselect = (e: any) => {
        containerRef.current?.classList.remove("selected");
        props.onAreaSelect?.(
          false,
          props.parentRef.current.getElementsByClassName("selected")
        );
      };
      containerRef.current?.addEventListener("select", onSelect);
      containerRef.current?.addEventListener("deselect", onDeselect);
      return () => {
        containerRef.current?.removeEventListener("select", onSelect);
        containerRef.current?.removeEventListener("deselect", onDeselect);
      };
    }, [props]);
    return (
      <div className="selectable" ref={containerRef}>
        <Component {...props} />
      </div>
    );
  };
  return WithSelect;
};
export default withSelect;
