import { Button, Form, Input, Select } from "antd";
import { MappingNursingStatus } from "enums/nursingCare";
import { memo } from "react";
import { NursingCareHistoryI } from "types/admin";

interface SearchFormProps {
  onSearch?: (
    formData: Pick<NursingCareHistoryI, "family_name" | "address" | "status">
  ) => void;
}

const statusOptions = [
  {
    label: MappingNursingStatus.active,
    value: "active",
  },
  {
    label: MappingNursingStatus.on_leave,
    value: "on_leave",
  },
  {
    label: MappingNursingStatus.resigned,
    value: "resigned",
  },
];

const SearchForm = (props: SearchFormProps) => {
  const { onSearch } = props;

  return (
    <Form onFinish={onSearch} className="flex gap-x-[10px]">
      <Form.Item name="family_name" className="w-[25%]">
        <Input
          allowClear
          placeholder="介護士名を入力してください（部分一致）。"
        />
      </Form.Item>

      <Form.Item name="address" className="w-[25%]">
        <Input allowClear placeholder="住所を入力してください（部分一致）。" />
      </Form.Item>

      <Form.Item name="status" className="w-[25%]">
        <Select
          placeholder="在籍状況を選択してください。"
          options={statusOptions}
          allowClear
        />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          className="bg-[#EA7E2C] text-white min-w-[73px] hover:!text-white"
        >
          検索
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(SearchForm);
