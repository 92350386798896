import styled from "@emotion/styled";
import { Tabs } from "antd";

export const StyledNotiTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #1890ff !important;
      }
    }
    .ant-tabs-tab-btn {
      font-weight: 400;
      &:after {
        content: none;
      }
    }
    .ant-tabs-ink-bar {
      background: #1890ff;
    }
  }
  .ant-tabs-content-holder {
  }
`;
