import { Button, Checkbox, Form, Table } from "antd";
import React, { useState, useEffect } from "react";
import Styles from "./styled";
import PageHeaderTitle from "component/PageHeaderTitle";
import { ButtonOutline, ButtonSolid } from "component/button";

const options: any = [
  { label: "一時停止", value: "1" },
  { label: "利用停止", value: "2" },
];

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: true,
  },
  {
    title: "お客様名",
    dataIndex: "customerName",
    key: "customerName",
    sorter: true,
  },
  {
    title: "生年月日",
    dataIndex: "birthDate",
    key: "birthDate",
    sorter: true,
  },
  {
    title: "介護度",
    dataIndex: "careLevel",
    key: "careLevel",
    sorter: true,
  },
  {
    title: "被保険者番号",
    dataIndex: "policyNumber",
    key: "policyNumber",
    sorter: true,
  },
  {
    title: "被保証適用期間",
    dataIndex: "coveragePeriod",
    key: "coveragePeriod",
    sorter: true,
  },
  {
    title: "担当ケアマネ",
    dataIndex: "careManager",
    key: "careManager",
    sorter: true,
  },
  {
    title: "電話番号",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    sorter: true,
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    sorter: true,
    render: (_: any, record: any) => (
      <ButtonOutline className="btn-round !h-[35px] !px-[16px] !text-[14px] !leading-[24px]">
        詳細
      </ButtonOutline>
    ),
  },
];

const dataSource = [
  {
    id: "0011",
    customerName: "近藤 忠司 コウドウ タダシ",
    birthDate: "昭和 27年 05月 30日",
    careLevel: "--",
    policyNumber: "--",
    coveragePeriod: "--",
    careManager: "--",
    phoneNumber: "--",
  },
];

const CustomerHistoryDetail = () => {
  const [data, setData] = useState([]);
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    setMaxWidth(window.innerWidth);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const data: any = Array(12).fill(dataSource[0]);
    setData(data);
  }, []);

  return (
    <Styles>
      <PageHeaderTitle
        title="ルーティン=&gt; 介護記録"
        className="hidden lg:py-4 px-6 bg-white font-bold lg:block"
      />
      <section className="lg:py-5 lg:px-6" style={{ maxWidth: maxWidth }}>
        <div className="py-5 sm:px-3 lg:px-6 bg-white">
          <div className="mb-4 px-3">
            <Checkbox.Group options={options} defaultValue={[]} />
          </div>
          <div style={{ maxWidth: maxWidth }}>
            <Table scroll={{ x: 1000 }} dataSource={data} columns={columns} />
          </div>
        </div>
      </section>
    </Styles>
  );
};

export default CustomerHistoryDetail;
