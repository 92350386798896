import { FlexRow, StyleTitle } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useState, useEffect } from "react";
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Spin,
  Table,
  TimePicker,
} from "antd";
import { Box, Button, Text } from "@chakra-ui/react";
import Clock from "assets/time.png";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { toast } from "react-toastify";
// import Styles from "./styled";
import { DATE_FORMAT, JP_DATEPICKER_FORMAT, MONTH_FORMAT } from "constant/date";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import dayjs, { Dayjs } from "dayjs";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import SelectTime from "component/SelectTime";
import { Link, useParams } from "react-router-dom";
import SelectWithData from "component/SelectWithData";
import { IService, IServiceType, ITreatmentType } from "constant/interfaces";
import MESSAGES from "constant/messages";
import { ClockCircleFilled } from "@ant-design/icons";
import ROUTES from "constant/routes";
// import { toBEData, toFEData } from "./function";
import REGEX from "constant/regex";
import { useWatch } from "antd/es/form/Form";
import { toBEData, toFEData, toFEStaffData } from "page/Customer/CustomerDetail/CarePlan/function";
import Styles from "page/Customer/CustomerDetail/CarePlan/styled";

interface IProps {
  isNormal: boolean;
}

const ManagementPlanHeadForm = ({ isNormal }: IProps) => {
  const [form] = Form.useForm();
  const changeable_id = useWatch("id", form);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const schedules = Form.useWatch("schedules", form);
  const service = useService();
  const { id } = useParams();
  const [month, setMonth] = useState<Dayjs>(dayjs());
  const fetchInitialData = async (lastMonth = false) => {
    let year_month = month;
    if (lastMonth) year_month = year_month.add(-1, "month");
    try {
      const currentFormId = form.getFieldValue("id");
      form.resetFields();
      if (lastMonth) form.setFieldValue("id", currentFormId);
      form.setFieldValue("schedules", [{}]);
      setLoading(true);
      const { data } = await axiosClient({
        method: "get",
        url: isNormal
          ? service.NURSING_CARE_PLAN
          : service.DISABILITY_CARE_PLAN,
        params: {
          patient_id: id,
          year_month: year_month.format(MONTH_FORMAT),
        },
      });
      // console.log('data', data)
      if (data) {
        form.setFieldsValue(toFEStaffData(data, year_month, lastMonth, form));
        setLoading(false);

      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  // const handleSubmit = async (formData: any) => {
  //   try {
  //     const res = await axiosClient({
  //       method: formData.id ? "put" : "post",
  //       url: isNormal
  //         ? service.NURSING_CARE_PLAN
  //         : service.DISABILITY_CARE_PLAN,
  //       data: toBEData(formData, id, month),
  //     });
  //     toast.success(res.data.success);
  //     fetchInitialData();
  //     setIsEdit(false);
  //   } catch (error) {
  //     toast.error("Error");
  //     console.error(error);
  //   }
  // };
  const getServiceOptions = async () =>
    (
      await axiosClient.get(service.SERVICES, {
        params: { patient_type: isNormal ? "normal" : "disability" },
      })
    ).data.serialized_services.map((item: IService) => ({
      label: item.service_name,
      value: item.id,
    }));
  const getServiceTypeOptions = async () =>
    (
      await axiosClient.get(service.SERVICE_TYPES)
    ).data.serialized_service_types.map((item: IServiceType) => ({
      label: item.detail,
      value: item.id,
    }));
  const getTreatmentOptions = async () =>
    (
      await axiosClient.get(service.TREATMENT_IMPROVEMENTS, {
        params: { patient_type: isNormal ? "normal" : "disability" },
      })
    ).data.serialized_treatment_improvements.map((item: ITreatmentType) => ({
      label: item.name,
      value: item.id,
    }));
  useEffect(() => {
    fetchInitialData();
  }, [month]);
  return (
    <Styles>
      <Form
        disabled={!isEdit}
        form={form}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ flex: "110px" }}
        initialValues={{ schedules: [{}] }}
        labelWrap
        scrollToFirstError={{ block: "center" }}
      >
        <FlexRow className="justify-between">
          <SelectTime
            format={MONTH_FORMAT}
            type="month"
            value={month}
            onChange={setMonth}
          />
          <FlexRow>
            <ButtonSolid
              onClick={() => {
                fetchInitialData(true);
              }}
              hidden={!isEdit}
              width="137px"
              height="35px"
              fontSize={14}
            >
              先月よりコピー
            </ButtonSolid>
            <ButtonSolid
              hidden={isEdit}
              width="137px"
              height="35px"
              fontSize={14}
              onClick={() => setIsEdit(true)}
            >
              編集
            </ButtonSolid>
          </FlexRow>
        </FlexRow>
        <Spin spinning={loading}>
          <div className="text-left">
            <StyleTitle>■介護</StyleTitle>
            <Form.Item
              name="comprehensive_aid_policy"
              label="総合的な援助の方針"
            // rules={[
            //   { required: true, message: MESSAGES.requireField },
            //   {
            //     pattern: REGEX.atLeastOneNonSpaceCharacter,
            //     message: MESSAGES.requireField,
            //   },
            // ]}
            >
              <Input.TextArea readOnly />
            </Form.Item>
            <Form.Item
              name="individual_family_intention"
              label="本人及び家族の意向"
            // rules={[
            //   { required: true, message: MESSAGES.requireField },
            //   {
            //     pattern: REGEX.atLeastOneNonSpaceCharacter,
            //     message: MESSAGES.requireField,
            //   },
            // ]}
            >
              <Input.TextArea readOnly />
            </Form.Item>
            <Form.Item
              name="aid_purpose"
              label="援助、目的"
            // rules={[
            //   { required: true, message: MESSAGES.requireField },
            //   {
            //     pattern: REGEX.atLeastOneNonSpaceCharacter,
            //     message: MESSAGES.requireField,
            //   },
            // ]}
            >
              <Input.TextArea readOnly />
            </Form.Item>
            {isNormal && (
              <>
                <Text fontSize="14px" fontWeight="bold" mb="12px">
                  ◯長期目標
                </Text>
                <Form.Item
                  label="期間"
                  className=" max-w-[700px]"
                  // rules={[{ required: true, message: MESSAGES.requireField }]}
                  name="long_term_date"
                  style={{pointerEvents:"none"}}
                >
                  <DatePicker.RangePicker
                    className="w-full"
                    format={JP_DATEPICKER_FORMAT}
                  />
                </Form.Item>
                <Form.Item
                  name="long_term_goal_one"
                  label="長期目標1"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="long_term_goal_two"
                  label="長期目標2"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="long_term_goal_three"
                  label="長期目標3"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="long_term_goal_four"
                  label="長期目標4"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Text fontSize="14px" fontWeight="bold" mb="3">
                  ◯短期目標
                </Text>
                <Form.Item
                  label="期間"
                  name="short_term_date"
                  className="max-w-[700px]"
                  style={{pointerEvents:"none"}}
                // rules={[{ required: true, message: MESSAGES.requireField }]}
                >
                  <DatePicker.RangePicker
                    className="w-full"
                    format={JP_DATEPICKER_FORMAT}
                  />
                </Form.Item>
                <Form.Item
                  name="short_term_goal_one"
                  label="短期目標1"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="short_term_goal_two"
                  label="短期目標2"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="short_term_goal_three"
                  label="短期目標3"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="short_term_goal_four"
                  label="短期目標4"
                  className="max-w-[700px]"
                // rules={[
                //   { required: true, message: MESSAGES.requireField },
                //   {
                //     pattern: REGEX.atLeastOneNonSpaceCharacter,
                //     message: MESSAGES.requireField,
                //   },
                // ]}
                >
                  <Input readOnly />
                </Form.Item>
              </>
            )}
            {/* <Text fontSize="14px" mb="3" fontWeight="bold">
              ◯予定表
            </Text> */}
          </div>
          {/* Table here */}

          <Box height="85px" />
          <Text fontSize="14px" fontWeight="bold" mb="12px">
            ◯処遇改善加算
          </Text>
          <Form.List name="treatment_improvements" initialValue={[null]}>
            {(fields, { add, remove }) =>
              fields.map((item, index) => (
                <div key={index} className="flex items-center mb-3">
                  <Form.Item
                    label="項目名"
                    name={[index]}
                    className="mb-0 w-full max-w-[700px]"
                  >
                    {/* <SelectWithData
                      placeholder="選択してください"
                      getOptions={getTreatmentOptions}
                      allowClear
                    /> */}
                  </Form.Item>
                  {/* <Button
                    hidden={!isEdit}
                    onClick={() => add(undefined, index + 1)}
                    variant="outline"
                    transform="scale(0.75)"
                    borderRadius="50%"
                    width="40px"
                    height="40px"
                    p="0px"
                    ml="2"
                    border="1px solid #000"
                  >
                    <img
                      src={Plus}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                  <Button
                    hidden={!isEdit}
                    onClick={() => fields.length > 1 && remove(index)}
                    variant="outline"
                    transform="scale(0.75)"
                    borderRadius="50%"
                    width="40px"
                    height="40px"
                    p="0px"
                    border="1px solid #000"
                  >
                    <img
                      src={Minus}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button> */}
                </div>
              ))
            }
          </Form.List>
          <Form.Item
            name="remarks"
            label="備考"
            className="mt-10"
          // rules={[
          //   { required: true, message: MESSAGES.requireField },
          //   {
          //     pattern: REGEX.atLeastOneNonSpaceCharacter,
          //     message: MESSAGES.requireField,
          //   },
          // ]}
          >
            <Input.TextArea readOnly />
          </Form.Item>
        </Spin>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Styles>
  );
};

export default ManagementPlanHeadForm;
