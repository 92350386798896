import Cookies from "js-cookie";
import useAuthenticate from "hook/useAuthenticate";
import React, { FC, ReactNode, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "store";

type IProps = {
  redirectTo: string;
  children: ReactNode;
};

const ProtectedRoutes: FC<IProps> = ({ redirectTo, children }) => {
  const user = useSelector((state: RootState) => state.user);
  const token = Cookies.get("access_token");

  return (
    <Suspense fallback={<></>}>
      {Boolean(user?.role) && token ? (
        <>{children}</>
      ) : (
        <Navigate to={redirectTo} />
      )}
    </Suspense>
  );
};

export default ProtectedRoutes;
