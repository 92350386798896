import styled from "@emotion/styled";
import { Button } from "@chakra-ui/react";

const Styles = styled.div`
  .ant-row {
    display: flex;
  }
  th,
  td {
    &.ant-table-cell {
      padding: 24px !important;
      &.col-date {
        padding: 0 !important;
        min-width: 20px;
      }
    }
  }
  table,
  th,
  td {
    padding: 20px;
    white-space: nowrap;
    border: 1px solid lightgray !important;
    border-collapse: collapse;
    .ant-form-item-explain-error {
      font-size: 12px;
    }
    .ant-form-item-explain-error {
      height: 0 !important;
    }
  }
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0px;
    border: 1px solid #000000;
  }
`;
const StyleBtn = styled(Button)({
  border: "1px solid black",
  fontSize: "20px",
});

export {StyleBtn, Styles};
