import { DailyForm } from "component/form";
import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";

const DailyFormPage = () => {
  const [searchParams] = useSearchParams();
  const [canEdit, setCanEdit] = useState<boolean>(
    !JSON.parse(searchParams.get("isHistory") || "")
  );

  return (
    <Fragment>
      <div className="page-title bg-[#EEEEEE] w-full h-16 opacity-100 fixed z-10 py-6 page-title-fixed">{`${searchParams.get("patient") || ""} - ${
        searchParams.get("seriveName") || ""
      }`}</div>

      <div className="bg-white mt-24">
        <DailyForm
          wrapperClassName="mb-[70px] pb-[10px]"
          showServiceName={false}
          canEdit={canEdit}
          setCanEdit={setCanEdit}
        />
      </div>
    </Fragment>
  );
};

export default DailyFormPage;
