import {
  BellRingOutline,
  BookOpen,
  HealthyCare,
  House,
  Phone,
} from "assets/icons";
import Logo from "assets/logo_without_text.png";
import { useLocation } from "react-router-dom";
import StyledMenuLink from "./styled";

const menuItems = [
  // {
  //   icon: <House />,
  //   label: "シフト",
  //   href: "/staff",
  //   activePaths: ["/staff"],
  // },
  // {
  //   icon: <BookOpen />,
  //   label: "ルーティン",
  //   href: "/staff/routines",
  //   activePaths: ["/staff/routines"],
  // },
  {
    icon: <HealthyCare />,
    label: "介護記録",
    href: "/staff/nursing-care-record",
    activePaths: ["/staff/nursing-care-record"],
    exact: false,
  },
  {
    icon: <BellRingOutline />,
    label: "掲示板",
    href: "/staff/bulletin-board",
    activePaths: ["/staff/bulletin-board", "/staff/bulletin-board/create"],
  },
  {
    icon: <Phone />,
    label: "個人連絡",
    href: "/staff/contact-list",
    activePaths: ["/staff/contact-list"],
  },
];

const StaffMenu = () => {
  const { pathname } = useLocation();

  return (
    <section className="z-50 bg-white px-2 pt-1 fixed bottom-0 left-0 right-0 border border-solid border-[--border-color-default] lg:p-0 lg:top-0 lg:right-auto lg:min-w-[208px] lg:flex lg:flex-col lg:justify-between lg:gap-4 lg:shadow-right-default">
      {/* Logo */}
      <div className="hidden py-4 px-6 pb-0 lg:block">
        <img src={Logo} alt="logo" className="mx-auto" />
      </div>

      {/* Menu links */}
      <div className="flex lg:flex-col lg:mb-auto">
        {menuItems.map((item, index) => {
          const isActive =
            item.activePaths?.includes(pathname) || item.exact === false
              ? pathname.includes(item.href)
              : false;

          return (
            <StyledMenuLink
              key={index}
              to={item.href}
              className={`flex-1 text-center flex flex-col items-center gap-[2px] text-[--text-color] lg:flex-row lg:gap-4 lg:px-4 lg:py-3 lg:font-bold lg:border-solid lg:border-2 lg:border-transparent ${
                isActive ? "active" : ""
              }`}
            >
              <span className="h-[22px] inline-block lg:h-6">{item.icon}</span>
              <span className="text-xs lg:text-sm">{item.label}</span>
            </StyledMenuLink>
          );
        })}
      </div>

      {/* Copyright */}
      <div className="text-xs py-3 px-4 text-center hidden lg:block">
        All rights reserved. V. 15.1.2
      </div>
    </section>
  );
};

export default StaffMenu;
