import styled from "@emotion/styled";

const Styles = styled.div`
  table,
  th,
  td {
    border: 1px solid;
  }
  th {
    font-weight: 400;
  }
`;
export default Styles;
