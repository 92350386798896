import React, { useCallback, useEffect, useState } from "react";
import { TabPanels, Tabs, TabPanel } from "@chakra-ui/react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import apiFactory from "api";
import "./styles.scss";
import ScheduleRegister from "../components/Modal/ScheduleRegister";
import ShiftRegister from "../components/Modal/ShiftRegister";
import Month from "../components/Month";
import Week from "../components/Week";
import Day from "../components/Day";
import Bar from "../components/Bar";
import Schedule from "../Calendar";
import StaffSchedule from "../StaffSchedule";

dayjs.extend(customParseFormat);

export interface IShiftManagementScheduleByMonth {
  date: string;
  total_hours: number;
  number_of_patients: number;
  number_of_nurses: number;
}

interface IViewCalendar {
  type: "client" | "staff";
  data?: any;
  setIsChange?: any;
}

interface INurseTimeInfo {
  name: string;
  time: string;
}

export default function ViewCalendar({ type, data, setIsChange }: IViewCalendar) {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabName, setTabName] = useState("月別");
  const [openRegisterShift, setOpenRegisterShift] = useState(false);  
  const [changeMonth, setChangeMonth] = useState(dayjs());
  const [date, setDate] = useState(new Date("2023-12-31"));
  const [startWeek, setStartWeek] = useState(dayjs().startOf("week"));
  const [createShift, setCreateShift] = useState(1)  
  const [initial, setInitial]: any = useState(false);  
  const [nurseTimeInfo, setNurseTimeInfo] = useState<INurseTimeInfo[][]>([
    [
      { name: "", time: "" },
      { name: "", time: "" },
      { name: "", time: "" },
    ],
  ]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showRegisterButtons, setShowRegisterButtons] = useState<boolean>(true);
  const [isStaffScheduleEdited, setIsStaffScheduleEdited] = useState<boolean>(false);
  const [isClientScheduleEdited, setIsClientScheduleEdited] = useState<boolean>(false);
  const [isShiftEdited, setIsShiftEdited] = useState<boolean>(false);

  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
    if (evt === 0) {
      setTabName("月別");
    }
    if (evt === 1) {
      setTabName("週別");
    }
    if (evt === 2) {
      setTabName("日別");
    }
  };

  const formRegister = useCallback(
    () =>
      type === "client"
        ? {
          title: "スケジュール登録",
          child: (
            <ScheduleRegister
              title="スケジュール登録"
              openRegisterShift={openRegisterShift}
              setOpenRegisterShift={setOpenRegisterShift}
              setIsClientScheduleEdited={setIsClientScheduleEdited}
              setCreateShift={setCreateShift}
              setIsShiftEdited={setIsShiftEdited}
            />
          ),
        }
        : {
          title: "シフト登録",
          child: (
            <ShiftRegister
              title="シフト登録"
              openRegisterShift={openRegisterShift}
              setOpenRegisterShift={setOpenRegisterShift}
              setCreateShift={setCreateShift}
              createShift={createShift}
              setIsStaffScheduleEdited={setIsStaffScheduleEdited}
              setIsShiftEdited={setIsShiftEdited}
            />
          ),
        },
    [type, openRegisterShift]
  )();

  const tableHeads = ["介護スタッフ", "シフト合計時間"];

  // useEffect(() => {
  //   const startDateOfMonth = changeMonth.startOf("month").format("YYYY-MM-DD");
  //   const tab = "client";
  //   fetchListStaffTotalTime(startDateOfMonth, tab);
  // }, [changeMonth]);

  return (
    <Tabs
      tabIndex={tabIndex}
      variant="soft-rounded"
      colorScheme="brand"
      onChange={onChangeTab}
      isLazy={true}
    >
      <Bar
        tabIndex={tabIndex}
        type={type}
        dateState={[date, setDate]}
        weekState={[startWeek, setStartWeek]}
        monthState={[changeMonth, setChangeMonth]}
        setOpenRegisterShift={setOpenRegisterShift}
        formRegister={formRegister}
        editState={[editMode, setEditMode]}
        showRegisterButtons={showRegisterButtons}
      />
      {formRegister.child}
      <TabPanels className="tab-panel">
        <TabPanel className="tab-month-panel">
          <Month
            modalOpen={openRegisterShift}
            dataHeadTd={tableHeads}
            nurseTimeInfo={nurseTimeInfo}
            setChangeMonth={setChangeMonth}
            month={changeMonth}
            type={type}
            setInitial={setInitial}
            initial={initial}
            setShowRegisterButtons={setShowRegisterButtons}
            isShiftEditedState={[isShiftEdited, setIsShiftEdited]}
            createShift={createShift}
          />
        </TabPanel>
        <TabPanel className="tab-week-panel">
          <Week
            title={type === "client" ? "お客様" : "介護スタッフ"}
            startWeek={startWeek}
            type={type}
            createShift={createShift}
            openRegisterShift={openRegisterShift}
            setInitial={setInitial}
            initial={initial}
            setShowRegisterButtons={setShowRegisterButtons}
            isShiftEditedState={[isShiftEdited, setIsShiftEdited]}
          />
        </TabPanel>
        <TabPanel className="h-full">
          {
            type === "staff" && (
              // <Day />
              <StaffSchedule 
                type={type} 
                currentDate={date} 
                editMode={editMode}
                setShowRegisterButtons={setShowRegisterButtons}
                createShift={createShift}
                isStaffScheduleEditedState={[isStaffScheduleEdited, setIsStaffScheduleEdited]}
              />
            )
          }
          {
            type === "client" && (
              <Schedule 
                type={type} 
                currentDate={date} 
                editMode={editMode} 
                setShowRegisterButtons={setShowRegisterButtons}
                isClientScheduleEditedState={[isClientScheduleEdited, setIsClientScheduleEdited]}
                createShift={createShift}
                setCreateShift={setCreateShift}
                setIsShiftEdited={setIsShiftEdited}
              />
            )
          }
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
