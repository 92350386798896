import { useMemo, useState } from "react";
import { Tabs, TabsProps } from "antd";
import { Board } from "./Board/Board";
import { Communication } from "./Communication/Communication";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import noticeSlice from "store/slice/noticeSlice";
import styled from "@emotion/styled";
import NewBoard from "./NewBoard";

const StyledNotiTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #1890ff !important;
      }
    }
    .ant-tabs-tab-btn {
      font-weight: 400;
      &:after {
        content: none;
      }
    }
    .ant-tabs-ink-bar {
      background: #1890ff;
    }
  }
  .ant-tabs-content-holder {
  }
`;

export default function Notice() {
  const [tabKey, setTabKey] = useState("1");

  const [boardTitle, setBoardTitle] = useState("掲示板");
  const [contactTitle, setContactTitle] = useState("個別連絡");
  const [title, setTitle] = useState("掲示板");
  const [isImportant, setIsImportant] = useState<boolean>(false);
  const { tab } = useSelector((state: RootState) => state.notice);
  const dispatch = useDispatch();

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `掲示板`,
        children: <NewBoard />,
      },
      {
        key: "2",
        label: `個別連絡`,
        children: (
          <Communication
            onChangeTitle={setTitle}
            setContactTitle={setContactTitle}
          />
        ),
      },
    ],
    []
  );
  const onChange = (e: any) => {
    switch (e) {
      case "1":
        dispatch(noticeSlice?.actions.setTab(1));
        setTitle(boardTitle);
        setTabKey("1");
        break;
      case "2":
        dispatch(noticeSlice?.actions.setTab(2));
        setTitle(contactTitle);
        setTabKey("2");
        break;
      default:
        setTabKey("3");
        break;
    }
  };
  return (
    <div>
      <div className="page-title px-[20px]">
        {isImportant && <span className="important px-1 mr-3">重要</span>}
        お知らせ
      </div>
      <div>
        <StyledNotiTabs
          activeKey={tab.toString()}
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
