import { Col, Row } from "antd";
import Styles from "./styled";
import dayjs from "dayjs";

export default function AdvanceReceiptPDF({ data }: any) {
  if (data)
    return (
      <Styles className="w-[848px] h-[1121px] p-[30px_30px]">
        <div className="text-center text-[1.25rem] font-bold">立替金</div>
        <div className="text-center text-[1.25rem] font-bold">
          {dayjs(data.year_month).format("YYYY年M月分")} {data.patient_name} 様
        </div>
        <div className="border border-solid border-black flex w-[400px] mx-auto mt-3 h-[40px] items-center mb-10">
          <div className="w-[40%] text-center text-[1rem]">合計金額</div>
          <div className="w-[60%] text-center text-[1.75rem]">
            ¥ {(+data.total).toLocaleString()}
          </div>
        </div>
        <Row wrap gutter={[8, 16]} className="mt-10">
          {Array(...data.billing_detail).map((i, index) => (
            <Col span={8} key={index} style={{ pageBreakInside: "avoid" }}>
              <div className="text-center text-[1rem]">
                {i.price}
                <b>円</b>
              </div>
              <img
                src={i.file}
                height="240"
                width="100%"
                className="w-[180px] h-[240px] mt-1 border border-solid border-black m-auto bg-[var(--secondary-color)] p-2 object-fill"
              />
            </Col>
          ))}
        </Row>
      </Styles>
    );
  return <></>;
}
