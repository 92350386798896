import { ReactNode } from "react";
import { RightOutlined } from "@ant-design/icons";
import Header from "component/header/Header";
import Sidebar from "component/sidebarAdmin";

export interface ILayoutProps {
  children?: ReactNode;
  isHeader?: boolean;
  isTabs?: boolean;
  items?: Array<{ key: string; label: ReactNode; children: ReactNode }>;
  title?: ReactNode;
}
export default function ClientLayout({
  children,
  isHeader = true,
}: ILayoutProps) {
  return (
    <div className="">
      <div className="text-start ">
        <Header title="" isMaster={true} isMenu={false} />
        <div className={"w-[calc(100vw-5px)] mt-[50px]"}>
          <div className="min-h-[calc(100vh-50px)] bg-[#ffffff] flex-[1_1_82%] px-[0px] py-[32px] text-[#000000] ">
            {/* {isHeader && (
              <div className="flex items-center gap-x-[13px] text-[12px] leading-[20px]">
                <span>HOME</span>
                <RightOutlined />
                <span>Page</span>
              </div>
            )} */}
            <div className="py-[23px]">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
