import { Button, Checkbox, Form, Input, Select, TableProps } from "antd";
import apiFactory from "api";
import { FlexRow } from "component/StyledComponent";
import TableAdmin from "component/admin/table";
import RecordHistoryCustomerPDFForm from "component/form/RecordHistoryCustomerPDFForm";
import { DATE_FORMAT_2, TIME_FORMAT } from "constant/date";
import ROUTES from "constant/routes";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import usePrint from "hook/usePrint";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ja";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TIMEJAPAN } from "utils/constant";
import StyleFilterHistory from "./styled";
import "./table.scss";
import dataFields from "./_config";
import { ButtonSolid } from "component/button";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(TIMEJAPAN);

const data = [
  {
    value: "NursingCarePlan",
    content: "介護",
  },
  {
    value: "DisabilityCarePlan",
    content: "障害",
  },
];

export const getTypeCarePlan = (type: string) => {
  switch (type) {
    case "介護":
      return "NursingCarePlan";
    case "障害":
      return "DisabilityCarePlan";
    default:
      return null;
  }
};

export const getSortType = (value: any) => {
  switch (value) {
    case 1:
      return "ascend";
    case 2:
      return "descend";
    default:
      return null;
  }
};

export const getTypeDivision = (value: string) => {
  switch (value) {
    case "normal":
      return "介護";
    case "disability":
      return "障害";
    default:
      return "normal";
  }
};

export const getTypeCarePlanJP = (value: string) => {
  switch (value) {
    case "NursingCarePlan":
      return "介護";
    case "DisabilityCarePlan":
      return "障害";
    default:
      return "介護";
  }
};

const getCellValue = (
  value = "--",
  unit = "",
  key?: keyof typeof dataFields
) => {
  return (
    <>
      {key && value !== "--" && value
        ? dataFields[key].options.find((item) => item?.value === value)?.label
        : value
        ? value
        : "--"}
      {value && value !== "--" && unit ? ` ${unit}` : ""}
    </>
  );
};

const hasEnoughData = (record: any) => {
  return (
    record?.scheduleable?.care_plan_type &&
    record?.nursing_care_history?.complexion &&
    record?.nursing_care_history?.sweating &&
    record?.nursing_care_history?.nurse?.family_name &&
    record?.nursing_care_history?.urination?.toString() &&
    record?.nursing_care_history?.defecation?.toString() &&
    record?.nursing_care_history?.hydration?.toString() &&
    record?.nursing_care_history?.full_body_bath
  );
};

const TableRecordCustomer = ({ dateparam }: any) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [dataTable, setDataTable]: any = useState([]);
  const [dataPrint, setDataPrint]: any = useState(null);
  const { ref, handlePrint } = usePrint(true);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState({
    patient_id: id,
    date: moment(dateparam).format(DATE_FORMAT_2),
    care_plan_type: "",
    tab: "patient",
    nurse_id: "",
    page: 1,
    per: 10,
    sortKey: "",
    order: "",
  });
  const [isReadyPrint, setIsReadyPrint] = useState(false)
  const [form] = Form.useForm();

  const getRecordCustomer = async (args?: any, isPrint = false) => {
    let _filter = { ...filter, is_print: isPrint, ...(args && args) };

    setFilter(_filter);
    setLoading(true);

    if (!_filter.care_plan_type) delete _filter.care_plan_type;
    try {
      const res =
        await apiFactory.staffNursingCareRecord.getNursingCareHistories<any>(
          _filter
        );
      setLoading(false);
      if (isPrint && res) {
        setDataPrint(res.serialized_nursing_care_histories);
        return;
      }

      if (_filter.care_plan_type) {
        const _type =
          data.find((item) => item.value === _filter.care_plan_type)?.content ||
          "";

        setValue(_type);
      }

      if (res) {
        setTotal(res.total_items);
        setDataTable(res.serialized_nursing_care_histories);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateparam) {
      getRecordCustomer({
        date: moment(dateparam).format(DATE_FORMAT_2),
      });
    }
  }, [dateparam]);

  useEffect(() => {
    moment.locale("ja");
  }, []);

  useEffect(() => {
    if (dataPrint && isReadyPrint) {
      handlePrint();
      setDataPrint(null);
    }
  }, [dataPrint, isReadyPrint]);

  const columns: TableProps<any>["columns"] = [
    {
      title: "日付",
      dataIndex: "date",
      render: (value) => (
        <span className="whitespace-break-spaces">
          {moment(value).format("YYYY/MM/DD\n (ddd)")}
        </span>
      ),
      sorter: true,
    },
    {
      title: "時間",
      dataIndex: "time-range",
      render: (_, record) => (
        <>{`${moment(record?.start_time).format(TIME_FORMAT)} ~ ${moment(
          record?.end_time
        ).format(TIME_FORMAT)}`}</>
      ),
      sorter: true,
    },
    {
      title: "区分",
      render: (_, record) => (
        <>{getTypeCarePlanJP(record?.scheduleable?.care_plan_type)}</>
      ),
    },
    {
      title: "顔色",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.complexion,
            "",
            "complexion"
          )}
        </>
      ),
    },
    {
      title: "発汗",
      render: (_, record) => (
        <>
          {getCellValue(record?.nursing_care_history?.sweating, "", "sweating")}
        </>
      ),
    },
    {
      title: "排尿",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.urination?.toString(),
            "回"
          )}
        </>
      ),
    },
    {
      title: "排便",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.defecation?.toString(),
            "回"
          )}
        </>
      ),
    },
    {
      title: "水分",
      render: (_, record) => (
        <>
          {getCellValue(
            record?.nursing_care_history?.hydration?.toString(),
            "cc"
          )}
        </>
      ),
    },
    {
      title: "全身浴",
      render: (_, record) => (
        <>{getCellValue(record?.nursing_care_history?.full_body_bath)}</>
      ),
    },
    {
      title: "担当者",
      render: (_, record) => (
        <>{getCellValue(record?.nursing_care_history?.nurse?.family_name)}</>
      ),
    },
    {
      title: "",
      render: (_, item) =>
        hasEnoughData(item) ? (
          <Button
            shape="round"
            className="border-primary text-primary"
            onClick={() =>
              navigate({
                pathname: `/${ROUTES.STAFF}/${ROUTES.NURSING_CARE}/${ROUTES.CREATE_RECORD_DAILY}/${item?.nursing_care_history?.id}`,
                search: `?schedule_date_id=${item?.id}&date=${moment(
                  item?.date
                ).format(DATE_FORMAT_2)}&start_time=${moment(
                  item?.start_time
                ).format(TIME_FORMAT)}&end_time=${moment(item?.end_time).format(
                  TIME_FORMAT
                )}&type=edit&patient=${
                  item?.nursing_care_history?.patient?.family_name
                }&staff_id=${item?.nursing_care_history?.nurse?.id}`,
              })
            }
          >
            登録済
          </Button>
        ) : (
          <Button
            onClick={() =>
              navigate({
                pathname: `/${ROUTES.STAFF}/${ROUTES.NURSING_CARE}/${ROUTES.CREATE_RECORD_DAILY}/${item?.nursing_care_history?.id}`,
                search: `?schedule_date_id=${item?.id}&date=${moment(
                  item?.date
                ).format(DATE_FORMAT_2)}&start_time=${moment(
                  item?.start_time
                ).format(TIME_FORMAT)}&end_time=${moment(item?.end_time).format(
                  TIME_FORMAT
                )}&scheduleable=${item?.scheduleable.id}&type=create&division=${
                  item?.scheduleable?.care_plan_type
                }&patient=${item?.scheduleable?.patient?.family_name}`,
              })
            }
            shape="round"
            type="primary"
          >
            登録
          </Button>
        ),
    },
  ];

  return (
    <div className="w-full bg-white mt-5 mb-6">
      <RecordHistoryCustomerPDFForm
        ref={ref}
        setIsReadyPrint={setIsReadyPrint}
        dataPrint={dataPrint ? dataPrint : []}
      />
      <FlexRow className="justify-between mb-4 mt-3 text-xs">
        <StyleFilterHistory>
          <Form
            autoComplete="off"
            className="flex gap-y-[14px] gap-x-2 flex-wrap"
            onFinish={getRecordCustomer}
          >
            <Form.Item className="min-w-[173px]" name={"care_plan_type"}>
              <Select
                placeholder="区分"
                onChange={(value) =>
                  setFilter((prev) => ({ ...prev, care_plan_type: value }))
                }
                allowClear
                options={data.map((item) => ({
                  label: item.content,
                  value: item?.value,
                }))}
              />
            </Form.Item>

            <Form.Item name={"no_care_history"} valuePropName="checked">
              <Checkbox>未記入</Checkbox>
            </Form.Item>

            <Button className="ml-auto" htmlType="submit" type="primary">
              検索
            </Button>
          </Form>
        </StyleFilterHistory>

        {value && (
          <ButtonSolid
            isLoading={loading}
            className="!hidden md:!inline-block"
            fontSize="14px"
            width="101px"
            height="35px"
            onClick={() => getRecordCustomer({}, true)}
          >
            PDF保存
          </ButtonSolid>
        )}
      </FlexRow>
      <Form form={form} onFinish={getRecordCustomer}>
        <TableAdmin
          form={form}
          data={dataTable}
          columns={columns}
          loading={loading}
          total={total}
          bordered={false}
        />
      </Form>
    </div>
  );
};

export default TableRecordCustomer;
