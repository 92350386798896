import { SearchOutlined } from "@ant-design/icons";
import { Form, Popover } from "antd";
import { ColumnType } from "antd/es/table";
import axiosClient from "api/axiosClient";
import SelectTime from "component/SelectTime";
import EnhanceTable from "component/admin/table";
import { ButtonOutline, ButtonSolid } from "component/button";
import { MONTH_FORMAT } from "constant/date";
import { IPatient } from "constant/interfaces";
import ROUTES from "constant/routes";
import dayjs, { Dayjs } from "dayjs";
import useService from "hook/useService";
import useTable from "hook/useTable";
import { InputStyled } from "page/admin/Staff/StaffList/styled";
import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import BillingPDF from "../../PDF/BillingPDF";
import ReceiptPDF from "../../PDF/ReceiptPDF";
import UsagePDF from "../../PDF/UsagePDF";
import AdvanceReceiptPDF from "../../PDF/AdvanceReceiptPDF";
import usePrint from "hook/usePrint";

type TType = "nursing_care" | "disability" | "bill1" | "bill2" | "bill3";

export default function BillingList() {
  const { data, setData, loading, setLoading, total, setTotal, form } =
    useTable();
  const { isCapturing, setCapturing, ref } = usePrint(true);
  const [pdfData, setPdfData] = useState();
  const [selectedType, setSelectedType] = React.useState<TType>();
  const service = useService();
  const [month, setMonth] = React.useState<Dayjs>(dayjs());
  const handleClickPrintPDF = async (
    type: TType,
    patient_id: number | string
  ) => {
    setSelectedType(type);
    let response;
    switch (type) {
      case "nursing_care":
        response = await axiosClient.get(
          service.USAGE_BILLINGS + "/invoice_pdf",
          {
            params: {
              invoice_type: "nursing_care",
              patient_id,
              year_month: month.format("YYYY/MM"),
            },
          }
        );
        break;
      case "disability":
        response = await axiosClient.get(
          service.USAGE_BILLINGS + "/invoice_pdf",
          {
            params: {
              invoice_type: "disability",
              patient_id,
              year_month: month.format("YYYY/MM"),
            },
          }
        );
        break;
      case "bill1":
        response = await axiosClient.get(
          service.BILLINGS + "/billing_history_invoice_1",
          {
            params: {
              patient_id,
              year_month: month.format("YYYY/MM"),
            },
          }
        );
        break;
      case "bill2":
        response = await axiosClient.get(
          service.BILLINGS + "/service_payments",
          {
            params: {
              patient_id,
              year_month: month.format("YYYY/MM"),
            },
          }
        );
        break;
      case "bill3":
        response = await axiosClient.get(
          service.BILLINGS + "/advanced_receipts",
          {
            params: {
              patient_id,
              year_month: month.format("YYYY/MM"),
            },
          }
        );
        break;

      default:
        break;
    }
    setPdfData(response?.data.data);
  };
  const renderPDF = React.useMemo(() => {
    switch (selectedType) {
      case "nursing_care":
        return <BillingPDF type="nursing_care" data={pdfData} />;
      case "disability":
        return <BillingPDF type="disability" data={pdfData} />;
      case "bill1":
        return <ReceiptPDF type="bill" data={pdfData} />;
      case "bill2":
        return <UsagePDF data={pdfData} />;
      case "bill3":
        return <AdvanceReceiptPDF data={pdfData} />;
      default:
        return;
    }
  }, [selectedType, pdfData]);
  useLayoutEffect(() => {
    if (pdfData) setCapturing(true);
  }, [pdfData]);
  useLayoutEffect(() => {
    if (!isCapturing) setPdfData(undefined);
  }, [isCapturing]);
  const columns: ColumnType<IPatient>[] = React.useMemo(
    () => [
      {
        title: "氏名",
        sorter: true,
        align: "center",
        render: (value, record) => (
          <span>
            {record.family_name}
            <br />({record.name_kana})
          </span>
        ),
        dataIndex: "family_name",
        className: "min-w-[250px] max-w-[250px]",
        ellipsis: true,
      },
      {
        title: "備品利用",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.USAGE}?type=equipment&patient_id=${record.id}&month=${
              record?.equipent_payment?.year_month || month.format("YYYY/MM")
            }`}
          >
            {!record.equipent_payment ? (
              month.format("YYYY/MM") === dayjs().format("YYYY/MM") ? (
                <ButtonOutline h={23}>登録</ButtonOutline>
              ) : (
                ""
              )
            ) : record.equipent_payment.registered ? (
              <ButtonSolid h={23}>登録済</ButtonSolid>
            ) : (
              <ButtonOutline h={23}>登録</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "サービス利用",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.USAGE}?type=service&patient_id=${record.id}&month=${
              record?.service_payment?.year_month || month.format("YYYY/MM")
            }`}
          >
            {!record.service_payment ? (
              month.format("YYYY/MM") === dayjs().format("YYYY/MM") ? (
                <ButtonOutline h={23}>登録</ButtonOutline>
              ) : (
                ""
              )
            ) : record.service_payment.registered ? (
              <ButtonSolid h={23}>登録済</ButtonSolid>
            ) : (
              <ButtonOutline h={23}>登録</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "ぽとろ利用",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.USAGE}?type=potoro&patient_id=${record.id}&month=${
              record?.equipent_payment?.year_month || month.format("YYYY/MM")
            }`}
          >
            {!record.potoro_payment ? (
              month.format("YYYY/MM") === dayjs().format("YYYY/MM") ? (
                <ButtonOutline h={23}>登録</ButtonOutline>
              ) : (
                ""
              )
            ) : record.potoro_payment.registered ? (
              <ButtonSolid h={23}>登録済</ButtonSolid>
            ) : (
              <ButtonOutline h={23}>登録</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "立替レシート",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.RECEIPT}?patient_id=${record.id}&month=${
              record?.patient_receipt?.year_month || month.format("YYYY/MM")
            }`}
          >
            {!record.patient_receipt ? (
              month.format("YYYY/MM") === dayjs().format("YYYY/MM") ? (
                <ButtonOutline h={23}>登録</ButtonOutline>
              ) : (
                ""
              )
            ) : record.patient_receipt.registered ? (
              <ButtonSolid h={23}>登録済</ButtonSolid>
            ) : (
              <ButtonOutline h={23}>登録</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "利用料請求(介護)",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.BILLING}/${ROUTES.CAREPLAN}?patient_id=${
              record.id
            }&type=nursing_care&month=${
              record?.usage_billing_normal?.year_month ||
              month.format("YYYY/MM")
            }`}
          >
            {!record.usage_billing_normal &&
            month.format("YYYY/MM") !== dayjs().format("YYYY/MM") ? (
              ""
            ) : (
              <ButtonOutline h={23}>変更</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "利用料請求(障害)",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.BILLING}/${ROUTES.CAREPLAN}?patient_id=${
              record.id
            }&type=disability&month=${
              record?.usage_billing_disability?.year_month ||
              month.format("YYYY/MM")
            }`}
          >
            {!record.usage_billing_disability &&
            month.format("YYYY/MM") !== dayjs().format("YYYY/MM") ? (
              ""
            ) : (
              <ButtonOutline h={23}>変更</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "家賃等請求",
        align: "center",
        render: (value, record) => (
          <Link
            to={`${ROUTES.BILLING}/${ROUTES.RENT}?patient_id=${
              record.id
            }&month=${
              record?.rent_billing?.year_month || month.format("YYYY/MM")
            }`}
          >
            {!record.rent_billing &&
            month.format("YYYY/MM") !== dayjs().format("YYYY/MM") ? (
              ""
            ) : (
              <ButtonOutline h={23}>変更</ButtonOutline>
            )}
          </Link>
        ),
      },
      {
        title: "請求書ダウンロード",
        align: "center",
        render: (value, record) => {
          const col1 = !record.equipent_payment
            ? month.format("YYYY/MM") === dayjs().format("YYYY/MM")
              ? "white"
              : null
            : record.equipent_payment.registered
            ? "solid"
            : "white";
          const col2 = !record.service_payment
            ? month.format("YYYY/MM") === dayjs().format("YYYY/MM")
              ? "white"
              : null
            : record.service_payment.registered
            ? "solid"
            : "white";
          const col3 = !record.potoro_payment
            ? month.format("YYYY/MM") === dayjs().format("YYYY/MM")
              ? "white"
              : null
            : record.potoro_payment.registered
            ? "solid"
            : "white";
          const col4 = !record.patient_receipt
            ? month.format("YYYY/MM") === dayjs().format("YYYY/MM")
              ? "white"
              : null
            : record.patient_receipt.registered
            ? "solid"
            : "white";
          return (
            <div className="flex flex-col gap-1">
              <ButtonOutline
                hidden={
                  !record.usage_billing_normal &&
                  month.format("YYYY/MM") !== dayjs().format("YYYY/MM")
                }
                h={23}
                onClick={() => handleClickPrintPDF("nursing_care", record.id)}
              >
                利用料請求書(介護)
              </ButtonOutline>
              <ButtonOutline
                hidden={
                  !record.usage_billing_disability &&
                  month.format("YYYY/MM") !== dayjs().format("YYYY/MM")
                }
                h={23}
                onClick={() => handleClickPrintPDF("disability", record.id)}
              >
                利用料請求書(障害)
              </ButtonOutline>
              <Popover
                trigger="click"
                placement="bottom"
                content={
                  <div className="flex flex-col gap-1">
                    <ButtonOutline
                      h={23}
                      w={137}
                      onClick={() => handleClickPrintPDF("bill1", record.id)}
                    >
                      請求書1
                    </ButtonOutline>
                    <ButtonOutline
                      h={23}
                      w={137}
                      onClick={() => handleClickPrintPDF("bill2", record.id)}
                    >
                      請求書2
                    </ButtonOutline>
                    <ButtonOutline
                      h={23}
                      w={137}
                      onClick={() => handleClickPrintPDF("bill3", record.id)}
                    >
                      請求書3
                    </ButtonOutline>
                  </div>
                }
              >
                <ButtonOutline
                  h={23}
                  hidden={
                    [col1, col2, col3, col4].includes("white") ||
                    ![col1, col2, col3, col4].includes("solid")
                  }
                >
                  請求書
                </ButtonOutline>
              </Popover>
            </div>
          );
        },
      },
    ],
    [month]
  );
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.PATIENTS,
        method: "get",
        params: {
          ...form.getFieldsValue(),
          status: ["using"],
          year_month_payment: month.format("YYYY/MM"),
        },
      });
      setData(res.data.patients);
      setTotal(res.data.total_items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    form.resetFields();
    fetchData();
  }, [month]);
  return (
    <Form form={form} onFinish={fetchData}>
      <SelectTime
        type="month"
        format={MONTH_FORMAT}
        value={month}
        onChange={(value) => !value.isAfter(Date.now()) && setMonth(value)}
      />
      <div className="py-[16px] px-[25px] bg-[#F5F4F2] mt-[24px]">
        <p className="text-[#000000] text-[12px] leading-[20px]">キーワード</p>
        <div className="flex gap-x-[21px] items-center">
          <Form.Item name="fullname" className="w-full mb-0">
            <InputStyled
              className="flex items-center customInp border-[#707070] focus:!text-transparent"
              placeholder="氏名、カナ"
              prefix={
                <SearchOutlined className="text-[16px] text-[#2699FB] mr-[3px]" />
              }
            />
          </Form.Item>
          <ButtonOutline
            h={23}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              form.resetFields(["page"]);
              fetchData();
            }}
            className=" !px-[63px] !bg-[#FFFFFF] !max-h-[25px]"
            _hover={{
              color: "brand.500]",
              bg: "brand.100",
            }}
          >
            検索
          </ButtonOutline>
        </div>
      </div>
      <EnhanceTable
        form={form}
        data={data}
        columns={columns}
        total={total}
        loading={loading}
      />
      <div ref={ref} hidden={!isCapturing}>
        {renderPDF}
      </div>
    </Form>
  );
}
