import styled from "@emotion/styled";
import { Select } from "antd";

const StyledFormSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 2px !important;
  }

  .ant-form-item-explain-error {
    margin-left: 27%;
  }
`;

export default StyledFormSelect;
