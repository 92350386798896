import { DailyForm } from "component/form";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const CareRecord = () => {
  const [searchParams] = useSearchParams();
  const [canEdit, setCanEdit] = useState<boolean>(
    !JSON.parse(searchParams.get("isHistory") || "false")
  );

  return (
    <DailyForm
      showServiceName={false}
      canEdit={canEdit}
      setCanEdit={setCanEdit}
    />
  );
};

export default CareRecord;
