import { Tabs, TabsProps } from "antd";
import Tab3 from "./Attendance";
import ViewCalendar from "./ViewCalendar";
import Schedule from "./Calendar";
import React, { useState } from "react";
import ROUTES from "constant/routes";
import { useLocation, useNavigate } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";

export default function ShiftPage({ activeKey }: any) {
  const [tabIndex, setTabIndex] = useState(1);
  const tabItems: TabsProps["items"] = [
    {
      key: ROUTES.SHIFT_CLIENT,
      label: `お客様`,
      children: <ViewCalendar type="client" setIsChange={setTabIndex} />,
    },
    {
      key: ROUTES.SHIFT_STAFF,
      label: `介護スタッフ`,
      children: <ViewCalendar type="staff" setIsChange={setTabIndex} />,
    },
    {
      key: ROUTES.SHIFT_HOLIDAY,
      label: `休日・出勤申請`,
      children: <Tab3 />,
    },
  ];
  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
  };

  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div>
      <div className="page-title">シフト管理</div>
      <div className="page-container">
        {/* <Tabs items={tabItems}  onChange={(key: any) => navigate(`/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${key}`)} /> */}
        <Tabs
          destroyInactiveTabPane
          activeKey={location.pathname}
          onChange={(key: any) => navigate(`${key}`)}
        >
          <TabPane
            tab="お客様"
            key={`/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_CLIENT}`}
          >
            <ViewCalendar type="client" setIsChange={setTabIndex} />
          </TabPane>
          <TabPane
            tab="介護スタッフ"
            key={`/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_STAFF}`}
          >
            <ViewCalendar type="staff" setIsChange={setTabIndex} />
          </TabPane>
          <TabPane
            tab="休日・出勤申請"
            key={`/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_HOLIDAY}`}
          >
            <Tab3 />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
