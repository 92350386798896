import { STAFF_NOTICE, STAFF_NOTICE_GROUP } from "api/apiConstant";
import BaseApi from "api/baseApi";

class NoticeStaffApi extends BaseApi {
  createNotice(body: {
    patient_id: number;
    content: string;
    deployment_date: string;
  }) {
    return this.post(STAFF_NOTICE, body);
  }

  createNoticeGroup(body: {
    notification_category_id: number;
    content: string;
  }) {
    return this.post(STAFF_NOTICE_GROUP, body);
  }

  getNotifications(params?: {
    per?: number;
    page?: number;
    search?: string;
    notify_tab?: number;
    start_at?: string;
    end_at?: string;
    notification_category_id?: string;
  }) {
    return this.get(STAFF_NOTICE, params);
  }
}
export default NoticeStaffApi;
