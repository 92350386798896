import { TabsProps } from "antd";
import ROUTES from "constant/routes";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationForm from "../components/form";
import { StyledTabs } from "./styled";

const CreateNoti: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.has("tab") && queryParams.get("tab");

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "重要事項",
        children: <NotificationForm formType="important" />,
      },
      {
        key: "2",
        label: "申し送り事項",
        children: <NotificationForm formType="conveyed" />,
      },
    ],
    []
  );

  const onChange: TabsProps["onChange"] = (activeKey) => {
    navigate(
      `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.NOTICE}/${ROUTES.CREATE}?tab=${activeKey}`
    );
  };

  return (
    <>
      <div className="page-title">掲示板　親スレッド投稿</div>
      <div className="page-container">
        <div className="w-[490px]">
          <StyledTabs
            activeKey={activeTab || "1"}
            items={items}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default CreateNoti;
