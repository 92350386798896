import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FlexRow } from "component/StyledComponent";
import { ClientCalendarVisitSchedule } from "component/calendar";
import TableTimeScheduleClient from "component/table/TableTimeScheduleClient";
import TableVisitScheduleDayClient from "component/table/TableVisitScheduleDayClient";
import { DateButton, WeekButton } from "component/timeBtn";
import moment from "moment";
import { useState } from "react";
import dayjs from "dayjs";

const getMonday = (d: any) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

const ClientVisitSchedulePage = () => {
  const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('tabIndex') || '0'));
  const [tabName, setTabName] = useState("月別");
  const [date, setDate]: any = useState(new Date());
  const [week, setWeek] = useState(dayjs().startOf("week"));
  const [month, setMonth]: any = useState(getMonday(new Date()));

  const getPreviousDay = () => {
    const newDate = moment(date).subtract(1, "days").format();
    setDate(new Date(newDate));
  };
  const getNextDay = () => {
    const newDate = moment(date).add(1, "days").format();
    setDate(new Date(newDate));
  };

  const getPreviousWeek = () => setWeek(week.subtract(1, 'week'))
  const getNextWeek = () => setWeek(week.add(1, 'week'))

  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
    window.localStorage.setItem('tabIndex', evt);
    if (evt === 0) {
      setTabName("月別");
    }
    if (evt === 1) {
      setTabName("週別");
    }
    if (evt === 2) {
      setTabName("日別");
    }
  };

  const renderTimeButton = () => {
    switch (tabIndex) {
      case 0:
        return "";
      case 1:
        return (
          <WeekButton
            getPreviousWeek={getPreviousWeek}
            getNextWeek={getNextWeek}
            week={week.toDate()}
          />
        );
      case 2:
        return (
          <DateButton
            getPreviousDay={getPreviousDay}
            getNextDay={getNextDay}
            date={date}
          />
        );
      default:
        break;
    }
  };
  const handleSwitchToDayScreen = (pr: any) => {
    setDate(new Date(pr));
    setTabIndex(2);
  }
  return (
    <div className="page-container bg-[#FFFFFF] h-full !px-[10px]">
      <Tabs
        variant="soft-rounded"
        colorScheme="brand"
        onChange={onChangeTab}
        index={tabIndex}
      >
        <FlexRow className="mt-9">
          <TabList
            border="1px solid black"
            width="96px"
            height="30px"
            mt="5px"
            overflow="hidden"
            fontSize="12px"
            ml="10px"
            borderRadius="5px"
          >
            <StyleTab _selected={{ color: "white", bg: "brand.500" }}>
              月
            </StyleTab>
            <div className="h-[22px] m-auto border-r-[1px] border-solid border-[#000000]"></div>
            <Tab
              padding="0px"
              fontSize="12px"
              color="#000000"
              fontWeight="600"
              borderRadius={0}
              _selected={{ color: "white", bg: "brand.500" }}
            >
              {" "}
              <Text p="5px 10px" borderX="0px">
                週
              </Text>
            </Tab>
            <div className="h-[22px] m-auto border-r-[1px] border-solid border-[#000000]"></div>
            <StyleTab _selected={{ color: "white", bg: "brand.500" }}>
              日
            </StyleTab>
          </TabList>
          {renderTimeButton()}
        </FlexRow>
        <TabPanels>
          <TabPanel className="!px-[10px]">
            <ClientCalendarVisitSchedule onChangeDate={handleSwitchToDayScreen} />
          </TabPanel>
          <TabPanel className="!px-[10px]">
            <TableVisitScheduleDayClient
              onChangeDate={handleSwitchToDayScreen}
              weekStart={week.toDate()}
              weekEnd={
                week.endOf("week").toDate()
              }
            />
          </TabPanel>
          <TabPanel className="!px-[10px]">
            <TableTimeScheduleClient date={date} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

const StyleTab = styled(Tab)({
  fontSize: "12px",
  borderRadius: "0px",
  padding: "5px 9px",
  fontWeight: 600,
  color: '#000000'
});

export default ClientVisitSchedulePage;
