import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CalendarCustom,
  FlexRow,
  StyleCheckbox,
} from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useNavigate } from "react-router-dom";
import "moment/locale/ja";
import "moment-timezone";
import moment from "moment-timezone";
import { DatePicker, DatePickerProps } from "antd";
import styled from "@emotion/styled";
import { FORMAT, FORMATJP, HOURS } from "utils";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import "moment-timezone";
import "dayjs/locale/ja";
import ROUTES from "constant/routes";
import staffApi from "api/staffApi";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Cookies from "js-cookie";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const minuteHalf = ["00", "30"];

const CalendarMonthShiftAfter = () => {
  const location = useLocation();
  let monthAfter = new Date(location?.state?.dateTime);
  let HOUR24 = [...HOURS, '24'];

  const customDayInWeekend: any = {
    lang: {
      locale: "ja_JP",
      shortWeekDays: ["日", "月", "火", "水", "木", "金", "土"],
    },
  };
  const user = useSelector((state: RootState) => state.user);
  const [monthSelect, setMonthSelect]: any = useState(monthAfter);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dateEdit, setDateEdit] = useState(new Date());
  const [datePick, setDatePick] = useState(monthAfter);
  const [isrequset, setRequest] = useState(true);
  const [defaultValue, setDefaultValue] = useState({
    timeStart: "",
    timeEnd: "",
    id: 0,
    shift_date: "",
    revert_change: false
  });
  const [dateSelect, setDateSelect]: any = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const getNextMonthShift = async () => {
    const idToken = Cookies.get("access_token");
    try {
      const res = await staffApi.getShiftNextMonth(idToken, {year_month: dayjs(monthAfter).format('YYYY/MM')});
      setRequest(res?.data?.data?.confirmed_by_admin);
      setIsChange(res?.data?.data?.btn_adjustment_request_send);
      const dataStaffHolidays = res?.data?.data?.holidays?.map((item: any) => {
        return {
          date: new Date(item?.date)?.getDate(),
          status: "request",
          dayTime: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date(item?.date)?.getDate()
          ),
        };
      });
      const dataMasterHolidays = res?.data?.data?.shifts?.map((item: any) => {
        return {
          date: new Date(item?.shift_date)?.getDate(),
          status: "update",
          timeStart: item?.start_time,
          timeEnd: item?.end_time,
          dayTime: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date(item?.shift_date)?.getDate()
          ),
          id: item?.id,
          revert_change: item?.revert_change,
          shift_date: item?.shift_date
        };
      });
      setDateSelect(dataStaffHolidays?.concat(dataMasterHolidays));
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getNextMonthShift();
  }, []);
  const renderBG = (value: any) => {
    for (let index = 0; index < dateSelect.length; index++) {
      if (dateSelect[index].date === value) {
        return "#e07b2e";
      }
    }
  };

  const renderBGDate = (value: any) => {
    for (let index = 0; index < dateSelect.length; index++) {
      if (dateSelect[index].date === value) {
        if (dateSelect[index].status === "request") {
          return "#e07b2e";
        } else {
          return "#0062FF";
        }
      }
    }
  };

  const onChangeTime = (evt: any) => {
    setDefaultValue({
      ...defaultValue,
      [evt.target.name]: evt.target.value,
    });
  };

  const onChangeDate: DatePickerProps["onChange"] = (date: any) => {
    setDatePick(new Date(date));
    let dateStr = dayjs(new Date(date)).format('YYYY-MM-DD')
    setDefaultValue({...defaultValue, shift_date: dateStr})
  };

  const editDate = (value: any) => {
    const itemFind = dateSelect.find((item: any) => {
      return item.date === value.date();
    });
    if (itemFind) {
      let dateStr: any = "";
      if(itemFind?.dayTime) {
        dateStr = new Date(itemFind?.dayTime);
        dateStr.setMonth(dateStr.getMonth()+1);
        dateStr.toISOString();
      }
      setDefaultValue({
        ...defaultValue,
        timeStart: itemFind?.timeStart || '00:00',
        timeEnd: itemFind?.timeEnd || '00:00',
        id: itemFind?.id,
        shift_date: itemFind?.shift_date || dayjs(dateStr).format('YYYY-MM-DD'),
        revert_change: itemFind?.revert_change
      });
      setDateEdit(new Date(value));
      onOpen();
    }
  };

  const getDateContent = (value: any) => {
    const itemFind = dateSelect.find((item: any) => {
      return item.date === value;
    });

    return itemFind.timeStart && itemFind.timeEnd
      ? `${itemFind.timeStart} ~ ${itemFind.timeEnd}`
      : "";
  };

  const onSubmitDate = () => {
    const curDate = dateEdit.getDate();
    const newState = dateSelect.map((obj: any) => {
      // 👇️ if id equals 2, update country property
      if (obj.date === curDate) {
        if (
          obj.dayTime !== datePick ||
          obj.timeStart !== defaultValue.timeStart ||
          obj.timeEnd !== defaultValue.timeEnd
        ) {
          return {
            ...obj,
            timeStart: defaultValue.timeStart,
            timeEnd: defaultValue.timeEnd,
            status: "update",
            date: datePick.getDate(),
            dayTime: datePick,
          };
        }
      }

      // 👇️ otherwise return the object as is
      return obj;
    });
    setDateSelect(newState);
    onClose();
  };
  const onSelectDate = (newValue: any) => {
    const newDate = new Date(newValue);
    setMonthSelect(newDate);
  };
  
  const convertHourToMapDropdown = (time: string) => {
    if(!time) return;
    let hour = time.split(":")[0];
    let minute = time.split(":")[1];
    let firstNum = Array.from(hour)[0];
    let secondNum = Array.from(hour)[1];
    if(firstNum === '0' && hour === '0') {
      return time
    } else if(firstNum === '0' && secondNum !== '0') {
      return `${secondNum}:${minute}`;
    } else {
      return time;
    }
  }

  const convertTimeSendToBE = (time: string) => {
    if(!time) return;
    let hour = time.split(":")[0];
    let minute = time.split(":")[1];
    if(hour?.length < 2) {
      return `0${hour}:${minute}`
    } else {
      return time
    }
  }

  const handleEditStaffNursingShiftManages = async () => {
    const idToken = Cookies.get("access_token");
    const data = {
      id: defaultValue?.id,
      date: defaultValue?.shift_date,
      start_time: convertTimeSendToBE(defaultValue?.timeStart),
      end_time: convertTimeSendToBE(defaultValue?.timeEnd),
      revert_change: isReset
    }
    const res = await staffApi.editStaffNursingShiftManages(data, idToken);
    if(res) {
      getNextMonthShift();
      setIsReset(false);
      onClose();
    }
  }

  const handleSendShiftChangeToMaster = async() => {
    const idToken = Cookies.get("access_token");
    const res = await staffApi.updateShiftStaffNursingShiftManages({button_type: 'sent'}, idToken);
    if(res) {
    }
  }

  return (
    <div style={{ marginTop: "40px", padding: "0px 20px" }}>
      <CalendarCustom
        fullCellRender={(value: any) => {
          const curMonth = new Date(value).getMonth();
          if (monthSelect.getMonth() === curMonth) {
            return (
              <div
                onClick={() => {
                  setDatePick(new Date(value));
                  editDate(value);
                }}
                style={{ background: renderBGDate(value.date()) }}
                className="h-[92px] pt-[12px] pr-[9px]"
              >
                <Text color={`${renderBG(value.date()) ? "white" : ""}`}>
                  {dayjs(value).format("D")}
                </Text>
                {renderBG(value.date()) && (
                  <Text position="absolute" left="10px" top="10px" color="#fff">
                    {getDateContent(value.date())}
                  </Text>
                )}
              </div>
            );
          } else {
            return (
              <div className="h-[92px] pt-[12px] pr-[9px]">
                <Text>{dayjs(value).format("D")}</Text>
              </div>
            );
          }
        }}
        disabledDate={(date: any) => {
          if (new Date(date).getMonth() !== monthAfter.getMonth()) {
            return true;
          } else {
            return false;
          }
        }}
        headerRender={(props) => {
          return <div style={{ display: "hidden" }} />;
        }}
        onChange={onSelectDate}
        locale={customDayInWeekend}
        defaultValue={dayjs(monthAfter)}
      />
      <Modal isOpen={isOpen} onClose={() => {onClose(); setIsReset(false)}}>
        <ModalContent>
          <ModalHeader>調整依頼</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextP fontSize="12px">
              日にち　　　　：{moment(dateEdit).format(FORMATJP)}{" "}
            </TextP>
            <Text>↓</Text>
            <StyleTimePicker
              onChange={onChangeDate}
              locale={locale}
              format={FORMAT}
              value={dayjs(datePick)}
            />
            <TextP className="mt-[20px]">
              時間　　　　　：{defaultValue.timeStart}〜{defaultValue.timeEnd}{" "}
            </TextP>
            <Text>↓</Text>
            <FlexRow className="w-1/2">
              <CustomSelect
                value={convertHourToMapDropdown(defaultValue?.timeStart)}
                name="timeStart"
                onChange={onChangeTime}
              >
                {HOUR24?.map((hour: string, index: number) =>
                  minuteHalf.map((item: string, indexMin: any) => {
                    return hour === '24' && item === '30' ? <></> : (
                      <option
                        style={{ fontSize: "12px" }}
                        key={`${index}:${item}`}
                        value={`${index}:${item}`}
                      >
                        {" "}
                        {`${hour}:${item}`}{" "}
                      </option>
                    )
                  })
                )}
              </CustomSelect>
              <Text className="p-[5px] ">~</Text>
              <CustomSelect
                value={convertHourToMapDropdown(defaultValue.timeEnd)}
                name="timeEnd"
                onChange={onChangeTime}
              >
                {HOUR24?.map((hour: string, index: number) =>
                  minuteHalf.map((item: string, indexMin: any) => {
                    return hour === '24' && item === '30' ? <></> : (
                      <option
                        style={{ fontSize: "12px" }}
                        key={`${index}:${item}`}
                        value={`${index}:${item}`}
                      >
                        {" "}
                        {`${hour}:${item}`}{" "}
                      </option>
                    )
                  })
                )}
              </CustomSelect>
            </FlexRow>
          </ModalBody>

          <ModalFooter>
            <FlexRow className="justify-between">
              <StyleCheckbox onChange={(e) => setIsReset(e.target.checked)} borderColor="var(--primary-text-dark)">
                削除
              </StyleCheckbox>
              <FlexRow className="justify-end">
                <ButtonOutline
                  onClick={onClose}
                  width="137px"
                  height="35px"
                  fontSize="16px"
                  m={3}
                >
                  キャンセル
                </ButtonOutline>
                <ButtonSolid
                  //onClick={onSubmitDate}
                  onClick={() => handleEditStaffNursingShiftManages()}
                  width="137px"
                  height="35px"
                  fontSize="16px"
                  m={3}
                >
                  保存
                </ButtonSolid>
              </FlexRow>
            </FlexRow>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FlexRow className="justify-end" style={{ marginTop: "65px" }}>
        {isChange && (
          <ButtonSolid
            onClick={() =>
              handleSendShiftChangeToMaster()
            }
            width="137px"
            height="35px"
            fontSize="16px"
            m={3}
          >
            調整依頼送信
          </ButtonSolid>
        )}
        <ButtonSolid
          disabled={isChange}
          onClick={() =>
            navigate(`/${ROUTES.STAFF}/${ROUTES.MONTHSHIFTAFTER}`)
          }
          width="137px"
          height="35px"
          fontSize="16px"
          className={isChange ? 'cursor-not-allowed opacity-70' : ''}
          m={3}
        >
          承認
        </ButtonSolid>
      </FlexRow>
    </div>
  );
};

const StyleTimePicker = styled(DatePicker)({
  borderRadius: "0",
  fontWeight: "600",
  color: "var(--primary-text-dark)",
  border: "1px solid black",
  width: "100%",
});

const CustomSelect = styled(Select)({
  borderRadius: "4px",
  border: "1px solid #000",
  height: "40px",
  fontSize: "12px",
});

const TextP = styled(Text)({
  fontSize: "12px",
});

export default CalendarMonthShiftAfter;
