import { createSlice } from "@reduxjs/toolkit";

const initialState: IHStaffNoticeState = {
  tab: "1",
  title: "",
  detailType: "",
  isDeleted: false,
  boardInnerTab: "1",
};

export interface IHStaffNoticeState {
  tab: string;
  title: string;
  detailType: string;
  isDeleted: boolean;
  boardInnerTab: string;
}

const hsStaffNoticeSlice = createSlice({
  initialState,
  name: "hs-staff-notice",
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDetailType: (state, action) => {
      state.detailType = action.payload;
    },
    setIsDeleted: (state, action) => {
      state.isDeleted = action.payload;
    },
    setBoardInnerTab: (state, action) => {
      state.boardInnerTab = action.payload;
    },
  },
});

export const { setIsDeleted } = hsStaffNoticeSlice.actions;

export default hsStaffNoticeSlice;
