import { StyleGrid, StyleGridItem, StyleSelect } from 'component/StyledComponent'

const checkJapanWord = (value: string) => {
  if (value.match(/[\u3400-\u9FBF]/)) {
    return 40
  } else {
    return 60
  }
}

const SelectForm = ({ title, value, changeValue, data, name, disable, color = "#555555", fontSize = '16px', holder = '', col = 5 }: any) => {
  return (
    <StyleGrid templateColumns={`repeat(${col}, 1fr)`} gap={6}>
      <StyleGridItem colSpan={1} >
        <p className='mt-3' style={{ color: color, fontSize: fontSize }}>{title}</p>
      </StyleGridItem>
      <StyleGridItem colSpan={col - 1} >
        <StyleSelect
           placeholder={holder}
          _placeholder={{ color: 'var(--placeholder-color)' }} disabled={disable} fontSize={fontSize}
          style={{ fontWeight: 'normal', borderRadius: '5px' }} name={name} value={value} onChange={changeValue} >
          {/* <option hidden disabled={true} value="">{holder}</option> */}
          {data?.map((item: any, index: any) => (
            <option style={{ maxWidth: '500px' }} key={index} value={item.value}>{item.content.length < checkJapanWord(item.content) ? `${item.content}`
              : `${item.content.substring(0, checkJapanWord(item.content))}...`}</option>
          ))}
        </StyleSelect>
      </StyleGridItem>
    </StyleGrid>
  )
}

export default SelectForm
