
import { SHIFT_REGISTRATION } from '../apiConstant';
import BaseApi from 'api/baseApi';

class ShiftRegistrationApi extends BaseApi {
  create(body: {
    name: string,
    start_time: string,
    end_time: string,
    abbreviation: string,
    background_color: string,
    text_color: string
  }) {
    return this.post(SHIFT_REGISTRATION, body);
  }
  getList(body: {
    per: number,
    page: number,
    sortKey?: string,
    order?: string
  }) {
    return this.get(SHIFT_REGISTRATION, body);
  }
  getDetail(id:any) {
    return this.get(`${SHIFT_REGISTRATION}/${id}`);
  }
  update(body: {
    id: number,
    name: string,
    start_time: string,
    end_time: string,
    abbreviation: string,
    background_color: string,
    text_color: string
  }) {
    return this.put(`${SHIFT_REGISTRATION}/${body.id}`, body);
  }
}
export default ShiftRegistrationApi;
