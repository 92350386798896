import React, { FocusEventHandler, useRef } from "react";
import { Input, InputProps, InputRef } from "antd";
import REGEX from "constant/regex";

interface IProps extends InputProps {
  type?: "math" | "text";
  onChange?: (value: any) => void;
}
const NumericInput = (props: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const reg = props.type === "math" ? REGEX.onlyNumeric : REGEX.onlyNumber;
    if (reg.test(value) || value === "") {
      props.onChange?.(value);
    }
  };
  const handleBlur = () => {
    const value = props.value?.toString() || "";
    let valueTemp = value;
    if (!value) return;
    if (value.toString().charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    props.onChange?.(valueTemp);
  };
  return (
    <Input
      {...props}
      value={props.value || ""}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

export default NumericInput;
