import { Box, Table, Text, Tr } from "@chakra-ui/react";
import { FlexRow, StyleTd } from "component/StyledComponent";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import staffApi from "api/staffApi";
import { DATE_FORMAT, DATE_FORMAT_2 } from "constant/date";
import { getCookie } from "utils";

const dataFake = [
  {
    sun: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
    mon: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
    tues: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
    wed: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
    thus: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
    fri: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
    sar: {
      name: "お客様名",
      time: "04:00〜04:30",
      type: "排泄介助・体位交換",
    },
  },
  {
    sun: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
    mon: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
    tues: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
    wed: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
    thus: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
    fri: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
    sar: {
      name: "お客様名",
      time: "07:30〜08:00",
      type: "口腔ケア・排泄介助",
    },
  },
];
const TableVisitScheduleDay = ({
  weekStart,
  weekEnd,
  classNameBox1 = "",
  classNameBox2 = "",
  classNameTable1 = "",
  classNameTable2 = "",
  data = dataFake,
}: any) => {
  const renderClassNameBoxTd = (param: any) => {
    if (param?.isFull) {
      return "!bg-[#1AD633]";
    } else if (param?.isHalf) {
      return "!bg-[#FFFF00]";
    } else {
      return "";
    }
  };
  const idToken = getCookie("access_token")

  const [dataWeek, setDataWeek] = useState([])
  const getMonthSchedule = async () => {

    try {
      const param = {
        week_start_date: moment(weekStart).format(DATE_FORMAT_2),
        week_end_date: moment(weekEnd).format(DATE_FORMAT_2),
      }

      const res = await staffApi.getStaffVisitSchedule(idToken, param)
      if (res.data.data && res.data.data.length > 0) {
        // console.log('data', res.data.data)
        setDataWeek(res.data.data)
      } else {
        setDataWeek([])
      }
    } catch (error) {

    }
  }

  const getShift = (shift: any, day: string) => {
    if (Object.keys(shift).length > 0) {

      let newArr: any = []

      for (const [key, value] of Object.entries(shift)) {
        if (key === day) {
          newArr = value
        }

        // newArr.push({
        //   day: new Date(key).getDate(),
        //   data: [`訪問${newVal.count}`],
        //   color: newVal.has_color
        // })
      }
      return newArr?.map((item: any, index: any) => (
        <BoxTd className="my-[5px]" >

          <StyleText>{item.patient_name}</StyleText>
          <StyleText>{item.time_range}</StyleText>
          <StyleText>{item.service_name}</StyleText>
        </BoxTd>
      ))

    }

  }
  useEffect(() => {
    getMonthSchedule()
  }, [weekStart, weekEnd])

  return (
    <div>
      <FlexRow>
        <Box
          className="!text-center"
          fontWeight="bold"
          fontSize="14px"
          p="20px 0px"
          width="150px"
          bg="bgGray.100"
          border="1px solid #000"
          borderRight="0px"
        >
          介護スタッフ
        </Box>
        <Table className="text-xs">
          <HeadTd>{`${weekStart.getDate()}日(日)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(1, "day")
            .get("date")}日(月)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(2, "day")
            .get("date")}日(火)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(3, "day")
            .get("date")}日(水)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(4, "day")
            .get("date")}日(木)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(5, "day")
            .get("date")}日(金)`}</HeadTd>
          <HeadTd>{`${weekEnd.getDate()}日(土)`}</HeadTd>
        </Table>
      </FlexRow>
      {dataWeek && dataWeek.map((item: any, index: any) => (
        <FlexRow>
          <Box
            className={classNameBox1}
            bg={index === 0 ? "brand.500" : ""}
            display="block"
            position="relative"
            fontWeight="bold"
            fontSize="11px"
            p="20px 0px"
            width="150px"
            border="1px solid #000"
            borderRight="0px"
          >
            <Text
              position="absolute"
              top="49%"
              left="25%"
              height="100px"
              margin="auto"
            >
              {item?.family_name}
            </Text>
          </Box>
          <Table className={`text-xs ${classNameTable1}`}>
            <Tr key={index}>
              <TableTd>
              {getShift(item?.shifts, moment(weekStart).format("YYYY-MM-DD"))}

              </TableTd>
              <TableTd>
              {getShift(item?.shifts, moment(weekStart).add(1, "day").format("YYYY-MM-DD"))}
              </TableTd>
              <TableTd>
              {getShift(item?.shifts, moment(weekStart).add(2, "day").format("YYYY-MM-DD"))}
              </TableTd>
              <TableTd>
              {getShift(item?.shifts, moment(weekStart).add(3, "day").format("YYYY-MM-DD"))}
              </TableTd>
              <TableTd>
              {getShift(item?.shifts, moment(weekStart).add(4, "day").format("YYYY-MM-DD"))}
              </TableTd>
              <TableTd>
              {getShift(item?.shifts, moment(weekStart).add(5, "day").format("YYYY-MM-DD"))}
              </TableTd>
              <TableTd>
              {getShift(item?.shifts, moment(weekEnd).format("YYYY-MM-DD"))}
              </TableTd>
            </Tr>
          </Table>
        </FlexRow>
      ))}

      {/* <FlexRow>
        <Box
          className={classNameBox2}
          display="block"
          position="relative"
          fontWeight="bold"
          fontSize="11px"
          p="20px 0px"
          width="150px"
          border="1px solid #000"
          borderRight="0px"
          background="#f5f4f2"
        >
          <Text
            position="absolute"
            top="49%"
            left="25%"
            height="100px"
            margin="auto"
          >
            △△△△△
          </Text>
        </Box>
        <Table className={`text-xs ${classNameTable2}`}>
          {data?.map((item: any, index: any) => (
            <Tr key={index}>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.sun)}>
                  <StyleText>{item.sun.name}</StyleText>
                  <StyleText>{item.sun.time}</StyleText>
                  <StyleText>{item.sun.type}</StyleText>
                </BoxTd>
              </TableTd>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.mon)}>
                  <StyleText>{item.mon.name}</StyleText>
                  <StyleText>{item.mon.time}</StyleText>
                  <StyleText>{item.mon.type}</StyleText>
                </BoxTd>
              </TableTd>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.tues)}>
                  <StyleText>{item.tues.name}</StyleText>
                  <StyleText>{item.tues.time}</StyleText>
                  <StyleText>{item.tues.type}</StyleText>
                </BoxTd>
              </TableTd>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.wed)}>
                  <StyleText>{item.wed.name}</StyleText>
                  <StyleText>{item.wed.time}</StyleText>
                  <StyleText>{item.wed.type}</StyleText>
                </BoxTd>
              </TableTd>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.thus)}>
                  <StyleText>{item.thus.name}</StyleText>
                  <StyleText>{item.thus.time}</StyleText>
                  <StyleText>{item.thus.type}</StyleText>
                </BoxTd>
              </TableTd>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.fri)}>
                  <StyleText>{item.fri.name}</StyleText>
                  <StyleText>{item.fri.time}</StyleText>
                  <StyleText>{item.fri.type}</StyleText>
                </BoxTd>
              </TableTd>
              <TableTd>
                <BoxTd className={renderClassNameBoxTd(item?.sar)}>
                  <StyleText>{item.sar.name}</StyleText>
                  <StyleText>{item.sar.time}</StyleText>
                  <StyleText>{item.sar.type}</StyleText>
                </BoxTd>
              </TableTd>
            </Tr>
          ))}
        </Table>
      </FlexRow> */}
    </div>
  );
};
const HeadTd = styled(StyleTd)({
  background: "#f5f4f2",
  border: "1px solid #000",
  fontSize: "14px",
  padding: "20px 0px 0px 0px",
  width: "120px",
  fontWeight: "bold",
  height: "42px",
});

const BoxTd = styled(Box)({
  padding: "2px 5px 15px 2px",
  border: "1px solid black",
});

const TableTd = styled(StyleTd)({
  border: "1px solid #000",
  fontSize: "11px",
  padding: "5px",
  width: "120px",
  fontWeight: "bold",
  height: "42px",
  textAlign: "left",
});

const StyleText = styled(Text)({
  fontSize: "12px",
});

export default TableVisitScheduleDay;
