import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Text } from "@chakra-ui/react";
import { CalendarCustom, FlexRow } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useNavigate } from "react-router-dom";
import ROUTES from "constant/routes";
import { useSelector } from "react-redux";
import { RootState } from "store";
import staffApi from "api/staffApi";
import Cookies from "js-cookie";
import duration from 'dayjs/plugin/duration'
import { toast } from "react-toastify";
import { DATE_FORMAT_2 } from "constant/date";

dayjs.extend(duration)
const CalendarMonthShiftBerore = () => {
  const user = useSelector((state: RootState) => state.user);
  const customDayInWeekend: any = {
    lang: {
      locale: "ja_JP",
      shortWeekDays: ["日", "月", "火", "水", "木", "金", "土"],
    },
  };
  const nextMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1)
  const navigate = useNavigate();
  const [dateSelect, setDateSelect]: any = useState([
  ]);
  const [dataDate, setDataDate]: any = useState()
  const [status, setStatus]: any = useState("draft")
  const getHolidayData = async () => {
    const idToken = Cookies.get("access_token")
    try {
      const res = await staffApi.getHoliday(idToken)
      if (res.data) {
        setStatus(res.data.status)
        setDataDate(res.data)

        const newData = []
        for (let index = 0; index < res.data?.holidays.length; index++) {
          // const element =  res.data[index];
          newData.push(
            {
              date: new Date(res?.data?.holidays[index]?.date).getDate(),
              content: "休日申請",
              dayTime: new Date(res?.data?.holidays[index]?.date)
            }
          )

        }
        setDateSelect(newData)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getHolidayData()
  }, [])

  const renderBG = (value: any) => {
    for (let index = 0; index < dateSelect.length; index++) {
      if (dateSelect[index].dayTime.getDate() === value) {
        return "#e07b2e";
      }
    }
  };

  const editDate = (value: any) => {
    if (status === "draft") {
      const filtered = dateSelect.filter((item: any) => {
        return item.date === value.date();
      });

      if (filtered.length > 0) {
        setDateSelect((current: any) =>
          current.filter((item: any) => item.date !== value.date())
        );
      } else {
        const dateTemp = dateSelect;
        dateTemp.push({
          date: value.date(),
          content: "休日申請",
          dayTime: new Date(value),
        });
        setDateSelect(dateTemp);
      }
    }

  };

  const getDateContent = (value: any) => {
    const filtered = dateSelect.filter((item: any) => {
      return item.dayTime.getDate() === value;
    });
    return filtered[0].content;
  };

  const onSubmit = async (status: string) => {
    const body = []
    for (let index = 0; index < dateSelect.length; index++) {
      body.push(dayjs(dateSelect[index].dayTime).format(DATE_FORMAT_2))

    }
    try {
      const idToken = Cookies.get("access_token")
      const holiday = {
        dates: body,
        status: status
      }
      const res = await staffApi.RegisterHoliday(holiday, idToken, "post")
      if (res) {
        toast.success(res.data.success)
        getHolidayData()
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  return (
    <div style={{ marginTop: "40px", padding: "0px 20px" }}>
      <CalendarCustom
        value={dayjs(new Date(new Date().getFullYear(), new Date().getMonth() + 1))}
        // defaultValue={dayjs().add(dayjs.duration({ 'months': 1 }))}
        fullCellRender={(value: any) => {
          const curMonth = new Date(value).getMonth();
          if (nextMonth.getMonth() === curMonth) {
            return (
              <div
                onClick={() => editDate(value)}
                style={{ background: renderBG(value.date()) }}
                className="h-[92px] pt-[12px] pr-[9px]"
              >
                <Text color={`${renderBG(value.date()) ? "white" : ""}`}>
                  {dayjs(value).format("D")}
                </Text>
                {renderBG(value.date()) && (
                  <Text position="absolute" left="10px" top="10px" color="#fff">
                    {getDateContent(value.date())}
                  </Text>
                )}
              </div>
            );
          } else {
            return (
              <div className="h-[92px] pt-[12px] pr-[9px]">
                <Text>{dayjs(value).format("D")}</Text>
              </div>
            );
          }

          // return (
          //   <div className="h-[92px] pt-[12px] pr-[9px]">
          //     <Text>{dayjs(value).format("D")}</Text>
          //   </div>
          // );
        }}
        disabledDate={(date: any) => {
          if (new Date(date).getMonth() !== new Date().getMonth() + 1) {
            return true;
          } else {
            return false;
          }
        }}
        headerRender={(props) => {
          return <div style={{ display: "hidden" }} />;
        }}
        // onChange={onSelectDate}
        locale={customDayInWeekend}
      />
      <FlexRow className="justify-end" style={{ marginTop: "65px" }}>
        <ButtonOutline
          onClick={() => navigate(`/${ROUTES.STAFF}`)}
          width="137px"
          height="35px"
          fontSize="16px"
          m={3}
        >
          キャンセル
        </ButtonOutline>
        <ButtonSolid
          onClick={() => onSubmit("draft")}
          width="137px"
          height="35px"
          fontSize="16px"
          m={3}
          isDisabled={status === "sent"}
        >
          下書き保存
        </ButtonSolid>
      </FlexRow>
      <FlexRow className="justify-end" style={{ marginTop: "65px" }}>
        <ButtonSolid
          width="137px"
          height="35px"
          fontSize="16px"
          m={3}
          isDisabled={status === "sent"}
          onClick={() => onSubmit("sent")}
        >
          シフトを送付
        </ButtonSolid>
      </FlexRow>
    </div>
  );
};

export default CalendarMonthShiftBerore;
