import { Table, Tbody, Text, Tr } from "@chakra-ui/react";
import {
  FlexRow,
  StyleCheckbox,
  StyleDatePicker,
  StyleSelect,
  StyleTd,
  StyleTr,
} from "component/StyledComponent";
import TableHeadTd from "./HeadTd";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import moment from "moment";
import "moment/locale/ja";
import "moment-timezone";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { TIMEJAPAN, getCookie } from "utils";
import ROUTES from "constant/routes";
import { useSelector } from "react-redux";
import { RootState } from "store";
import locale from "antd/es/date-picker/locale/ja_JP";
import { DatePickerProps } from "antd";
import {
  getSortType,
  getTypeCarePlan,
  getTypeDivision,
} from "./TableRecordCustomer";
import { DATE_FORMAT, DATE_FORMAT_2 } from "constant/date";
import nursingCareRecordApi from "api/admin/nursingCareRecord";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(TIMEJAPAN);

const data = [
  {
    value: "1",
    content: "介護",
  },
  {
    value: "2",
    content: "障害",
  },
];
export const fakeData = [
  {
    id: "001",
    date: "2023/06/01(木)",
    timeStart: new Date(new Date()).setHours(6, 30, 0),
    timeEnd: new Date(new Date()).setHours(7, 0, 0),
    time: ` 8:30〜9:00`,
    name: "井上 隆代",
    nameKata: "(イノウエ タカヨ)",
    division: "",
    bodyTemp: "35.5",
    bloodPressure: "125/80",
    urination: "0",
    defecation: "0",
    moisture: "0",
    bodyBath: "なし",
    manager: "丹羽智大",
    register: "2023/06/01 8:31",
  },
  {
    id: "002",
    date: "2023/06/01(木)",
    timeStart: new Date(new Date()).setHours(9, 0, 0),
    timeEnd: new Date(new Date()).setHours(9, 30, 0),
    time: `11:00〜11:30`,
    name: "井上 隆代",
    nameKata: "(イノウエ タカヨ)",
    division: "",
    bodyTemp: "35.5",
    bloodPressure: "125/80",
    urination: "0",
    defecation: "0",
    moisture: "0",
    bodyBath: "なし",
    manager: "丹羽智大",
    register: "2023/06/01 13:36",
  },
  {
    id: "003",
    date: "2023/06/01(木)",
    timeStart: new Date(new Date()).setHours(11, 30, 0),
    timeEnd: new Date(new Date()).setHours(12, 0, 0),
    time: `13:30〜14:00`,
    name: "井上 隆代",
    nameKata: "(イノウエ タカヨ)",
    division: "",
    bodyTemp: "35.5",
    bloodPressure: "125/80",
    urination: "0",
    defecation: "0",
    moisture: "0",
    bodyBath: "なし",
    manager: "丹羽智大",
    register: "2023/06/01 13:36",
  },
  {
    id: "004",
    date: "2023/06/01(木)",
    timeStart: new Date(new Date()).setHours(14, 30, 0),
    timeEnd: new Date(new Date()).setHours(16, 0, 0),
    time: `16:30〜18:00`,
    name: "井上 隆代",
    nameKata: "(イノウエ タカヨ)",
    division: "",
    bodyTemp: "35.5",
    bloodPressure: "125/80",
    urination: "0",
    defecation: "0",
    moisture: "0",
    bodyBath: "なし",
    manager: "丹羽智大",
    register: "2023/06/01 17:20",
  },
];

const TableRecordHistoryByDate = ({ date }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState(date ? date : new Date());
  const [endDate, setEndDate] = useState(date ? date : new Date());
  const [value, setValue] = useState("介護");
  const [dataTable, setDataTable]: any = useState([]);
  const [headVal, setHeadVal]: any = useState({});
  const { id } = useParams();
  const [role, setRole] = useState("");
  const user = useSelector((state: RootState) => state.user);
  const idToken = getCookie("access_token");

  useEffect(() => {
    if (user?.role) {
      setRole(user?.role);
    }
  }, [user]);

  const getRecordCustomer = useCallback(async () => {
    try {
      const param = {
        // patient_id: id,
        nurse_id: id || user.id,
        sortKey: Object.keys(headVal)[0],
        order: getSortType(Object.values(headVal)[0]),
        start_date: moment(startDate).format(DATE_FORMAT_2),
        end_date: moment(endDate).format(DATE_FORMAT_2),
        care_plan_type: getTypeCarePlan(value),
        tab: "staff",
      };
      const res = await nursingCareRecordApi.getNursingCareHistory(
        idToken,
        param,
        user?.role
      );
      if (res) {
        setDataTable(res.serialized_nursing_care_histories);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [startDate, endDate, value]);

  useEffect(() => {
    getRecordCustomer();
  }, [getRecordCustomer]);

  useEffect(() => {
    if (date) {
      setStartDate(date);
      setEndDate(date);
    }
  }, [date]);

  // useEffect(() => {
  //   if (location.state) {
  //     const state = location.state;
  //     const data = localStorage.getItem("tableData2");
  //     if (data) {
  //       const dataParse = JSON.parse(data);
  //       const newState = dataParse.map((obj: any) => {
  //         if (obj.id === location.state.id) {
  //           return {
  //             ...obj,
  //             bodyBath: state.bodyBath || "なし",
  //             division: state.division || "介護",
  //             urination: state.urination || "0",
  //             defecation: state.defecation || "0",
  //             moisture: state.moisture || "0",
  //             register: state.register,
  //             timeStart: state?.timeStart,
  //             timeEnd: state?.timeEnd,
  //             timeStartReal: state?.timeStartReal,
  //             timeEndReal: state?.timeEndReal,
  //             serContent: state?.serContent,
  //             serDetail: state?.serDetail,
  //             staff: state?.staff,
  //             eating: state?.eating,
  //             cleaning: state?.cleaning,
  //             report: state?.report,
  //             supportActDetail: state?.supportActDetail,
  //             enviArr: state?.enviArr,
  //             consultAssist: state?.consultAssist,
  //             record: state?.record,
  //             toilet: state?.toilet,
  //             diaper: state?.diaper,
  //             pad: state?.pad,
  //             urinal: state?.urinal,
  //             maintain: state?.maintain,
  //             hair: state?.hair,
  //             bodyWash: state?.bodyWash,
  //             wash: state?.wash,
  //             oral: state?.oral,
  //             dressing: state?.dressing,
  //             position: state?.position,
  //             mobility: state?.mobility,
  //             watch: state?.watch,
  //             physicCare: state?.physicCare || "身体",
  //             complex: state?.complex || "良",
  //             supportAct: state?.supportAct || "身体",
  //             sweat: state?.sweat || "有",
  //           };
  //         }
  //         return obj;
  //       });
  //       localStorage.setItem("tableData2", JSON.stringify(newState));
  //       setDataTable(newState);
  //     }
  //   } else {
  //     setDataTable(fakeData);
  //   }
  // }, [location]);

  const onSortHead = (evt: any) => {
    evt.preventDefault();
    const name = evt.currentTarget.getAttribute("name");
    let value = evt.currentTarget.getAttribute("value");
    setHeadVal({ [name]: Number(value) === 2 ? 0 : Number(value) + 1 });
  };

  // useEffect(() => {
  //   const dataLocal: any = localStorage.getItem("tableData2");
  //   if (dataLocal) {
  //     setDataTable(JSON.parse(dataLocal));
  //   } else {
  //     setDataTable(fakeData);
  //     localStorage.setItem("tableData2", JSON.stringify(fakeData));
  //   }
  // }, []);

  const onChangeDate: DatePickerProps["onChange"] = (date: any) => {
    setStartDate(new Date(date));
  };

  const onChangeDateEnd: DatePickerProps["onChange"] = (date: any) => {
    setEndDate(new Date(date));
  };

  return (
    <div className="overflow-auto">
      <FlexRow className="justify-between mb-4 mt-3 text-xs">
        <FlexRow>
          <StyleDatePicker
            style={{ width: "180px" }}
            onChange={onChangeDate}
            format={DATE_FORMAT}
            locale={locale}
            value={dayjs(startDate)}
          />
          <Text mx="9px" mt="8px" fontSize="xl" fontWeight="bold">
            ~
          </Text>
          <StyleDatePicker
            style={{ width: "180px" }}
            onChange={onChangeDateEnd}
            format={DATE_FORMAT}
            locale={locale}
            value={dayjs(endDate)}
          />
          <StyleSelect
            mx="46px"
            style={{ fontWeight: "normal", borderRadius: "4px" }}
            w="78px"
            h="31px"
            fontSize="12px"
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
          >
            {data?.map((item: any, index: any) => (
              <option key={index} value={item.content}>
                {item.content}
              </option>
            ))}
          </StyleSelect>

          <StyleCheckbox size="md" ml="64px" mb="3px" colorScheme="brand2">
            <p className="text-xs">未記入</p>
          </StyleCheckbox>
        </FlexRow>
        {user?.role === "staff" && (
          <ButtonSolid
            width="137px"
            onClick={() =>
              navigate(`/${ROUTES.STAFF}/${ROUTES.HISTORYCUSTOMERLIST}`)
            }
          >
            お客様一覧
          </ButtonSolid>
        )}
      </FlexRow>
      <Table className="text-xs">
        <Tbody>
          <StyleTr>
            <TableHeadTd
              title="日付"
              changeValue={onSortHead}
              value={headVal.date}
              isSort={true}
              name="date"
            />
            <TableHeadTd
              title="時間"
              changeValue={onSortHead}
              value={headVal.time}
              isSort={true}
              name="time"
            />
            <TableHeadTd
              title="お客様名"
              changeValue={onSortHead}
              value={headVal.name}
              name="name"
            />
            <TableHeadTd
              title="区分"
              changeValue={onSortHead}
              value={headVal.division}
              name="division"
            />
            <TableHeadTd
              title="排尿"
              changeValue={onSortHead}
              value={headVal.urination}
              name="urination"
            />
            <TableHeadTd
              title="排便"
              changeValue={onSortHead}
              value={headVal.defecation}
              name="defecation"
            />
            <TableHeadTd
              title="水分"
              changeValue={onSortHead}
              value={headVal.moisture}
              name="moisture"
            />
            <TableHeadTd
              title="全身浴"
              changeValue={onSortHead}
              value={headVal.bodyBath}
              name="bodyBath"
            />
            <StyleTd />
          </StyleTr>
          {dataTable?.map((item: any, index: any) => (
            <Tr
              style={{
                background: `${
                  index % 2
                    ? "var(--secondary-color) 0% 0% no-repeat padding-box"
                    : ""
                }`,
                height: "55px",
              }}
            >
              <StyleTd whiteSpace="nowrap">
                {moment(item?.date).format("YYYY/MM/DD (ddd)")}
              </StyleTd>
              <StyleTd style={{ whiteSpace: "nowrap" }}>
                {`${item?.start_time} ~ ${item?.end_time}`}
                {/* {`${dayjs
                  .tz(item?.timeStart, TIMEJAPAN)
                  .format("HH:mm")}~${dayjs
                    .tz(item?.timeEnd, TIMEJAPAN)
                    .format("HH:mm")}`} */}
              </StyleTd>
              <StyleTd width="15%">
                {item?.scheduleable?.patient?.family_name} <br />
                {item?.scheduleable?.patient?.name_kana}
              </StyleTd>
              <StyleTd>
                {item?.nursing_care_history &&
                  getTypeDivision(item?.nursing_care_history?.division)}
              </StyleTd>
              <StyleTd>
                {item?.nursing_care_history &&
                  `${item?.nursing_care_history?.urination} 回`}
              </StyleTd>
              <StyleTd>
                {item?.nursing_care_history &&
                  `${item?.nursing_care_history?.defecation} 回`}
              </StyleTd>
              <StyleTd>
                {item?.nursing_care_history &&
                  `${item?.nursing_care_history?.hydration} cc`}
              </StyleTd>
              <StyleTd>
                {item?.nursing_care_history &&
                  item?.nursing_care_history?.full_body_bath}
              </StyleTd>
              {item?.nursing_care_history ? (
                // <StyleTd py={1}>
                //   <div className="flex flex-col items-center text-xs">
                //     <ButtonOutline
                //       onClick={() =>
                //         navigate(
                //           role === 'admin' ? `/${ROUTES.ADMIN}/${ROUTES.CREATE_RECORD_DAILY}/${item?.id}` : `/${ROUTES.STAFF}/${ROUTES.RECORDHISTORYDAILY}/${item?.id}`,
                //           {
                //             state: {
                //               id: item?.id,
                //               division: item?.division || "介護",
                //               urination: item?.urination,
                //               defecation: item?.defecation,
                //               moisture: item?.moisture,
                //               bodyBath: item?.bodyBath,
                //               timeStart: item?.timeStart,
                //               timeEnd: item?.timeEnd,
                //               serContent: item?.serContent,
                //               serDetail: item?.serDetail,
                //               staff: item?.staff,
                //               eating: item?.eating,
                //               cleaning: item?.cleaning,
                //               report: item?.report,
                //               supportActDetail: item?.supportActDetail,
                //               enviArr: item?.enviArr,
                //               consultAssist: item?.consultAssist,
                //               record: item?.record,
                //               toilet: item?.toilet,
                //               diaper: item?.diaper,
                //               pad: item?.pad,
                //               urinal: item?.urinal,
                //               maintain: item?.maintain,
                //               hair: item?.hair,
                //               bodyWash: item?.bodyWash,
                //               wash: item?.wash,
                //               oral: item?.oral,
                //               dressing: item?.dressing,
                //               position: item?.position,
                //               mobility: item?.mobility,
                //               watch: item?.watch,
                //               physicCare: item?.physicCare,
                //               complex: item?.complex,
                //               supportAct: item?.supportAct,
                //               sweat: item?.sweat,
                //               date: date,
                //               url: role === 'admin' ? `/${ROUTES.ADMIN}/${ROUTES.CREATE_RECORD_DAILY}/${item?.id}` : `/${ROUTES.STAFF}/${ROUTES.RECORDBYDAY}`,
                //               timeEndReal: item?.timeEndReal
                //                 ? item?.timeEndReal
                //                 : null,
                //               timeStartReal: item?.timeStartReal
                //                 ? item?.timeStartReal
                //                 : item?.timeStart,
                //             },
                //           }
                //         )
                //       }
                //       className="text-xs"
                //       width="62px"
                //       height="23px"
                //     >
                //       登録済
                //     </ButtonOutline>
                //     {/* <Text whiteSpace='nowrap' fontSize='10px' className='mt-3' >登録：{item?.register}</Text> */}
                //   </div>
                // </StyleTd>
                <StyleTd py={1}>
                  <div className="flex flex-col items-center text-xs">
                    <ButtonOutline
                      onClick={() =>
                        navigate({
                          pathname:
                            user?.role === "admin"
                              ? `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${ROUTES.CREATE_RECORD_DAILY}/${item?.scheduleable?.patient_id}`
                              : `/${ROUTES.STAFF}/${ROUTES.RECORDHISTORYDAILY}/${item?.scheduleable?.patient_id}`,
                          search: `?schedule_date_id=${item?.id}&date=${item?.date}&start_time=${item?.start_time}&end_time=${item?.end_time}&type=edit&patient=${item?.scheduleable?.patient?.family_name}`,
                        })
                      }
                      className="text-xs"
                      width="62px"
                      height="23px"
                    >
                      登録済
                    </ButtonOutline>
                    {/* <Text whiteSpace='nowrap' className='mt-3' fontSize='10px' >{item?.register}</Text> */}
                  </div>
                </StyleTd>
              ) : (
                <StyleTd pt={0}>
                  <div className="flex flex-col items-center">
                    <ButtonSolid
                      onClick={() =>
                        navigate({
                          pathname:
                            user?.role === "admin"
                              ? `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${ROUTES.CREATE_RECORD_DAILY}/${item?.scheduleable?.patient_id}`
                              : `/${ROUTES.STAFF}/${ROUTES.RECORDHISTORYDAILY}/${item?.scheduleable?.patient_id}`,
                          search: `?schedule_date_id=${item?.id}&date=${
                            item?.date
                          }&start_time=${item?.start_time}&end_time=${
                            item?.end_time
                          }&staff_id=${
                            id || user.id
                          }&type=create&scheduleable=${
                            item?.scheduleable.id
                          }&division=${
                            item?.scheduleable?.care_plan_type
                          }&patient=${
                            item?.scheduleable?.patient?.family_name
                          }`,
                        })
                      }
                      className="text-xs mb-2"
                      width="62px"
                      height="23px"
                    >
                      登録
                    </ButtonSolid>
                  </div>
                </StyleTd>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableRecordHistoryByDate;
