import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { ROLE } from "constant";
import MESSAGES from "constant/messages";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import store from "store";
import userSlice from "store/slice/user";
import { getCookie, removeCookie } from "utils/cookie";
// import queryString from 'query-string'

const otakeURL = process.env.REACT_APP_BASE_API_URL;

const axiosClient = axios.create({
  baseURL: otakeURL,
  headers: {
    "content-type": "application/json",
    "Device-Type": "Webapp",
    version: 9999,
  },
  // paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = Cookies.get("access_token") || undefined;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }
);

axiosClient.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    // Handle errors
    let errorMessage = error;
    if (error.response) {
      if (
        error?.response?.status === 401 &&
        !window.location.pathname.includes("/login")
      ) {
        localStorage.setItem("error", MESSAGES.tokenExpired);
        removeCookie("access_token");
        const role = localStorage.getItem("role");
        store.dispatch(userSlice.actions.clearUser());
        switch (role) {
          case ROLE.CLIENT:
            window.location.assign("/");
            break;
          default:
            window.location.assign(`/${role || ""}`);
            break;
        }
        return null;
      }
      errorMessage =
        error.response.data?.message ||
        error.response.data?.messages ||
        error.response.data;
    }
    return Promise.reject(errorMessage);
  }
);

export default axiosClient;
