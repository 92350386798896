import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Form, Checkbox, DatePicker, TimePicker, Select, Input, Spin } from "antd";
import "dayjs/locale/zh-cn";
// import "./style.scss";
import dayjs, { Dayjs } from "dayjs";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import ModalCustom from "component/admin/modal";
import { Row, Space, Col } from "antd";
import { toast } from "react-toastify";
import { useDrawerContext } from "@chakra-ui/react";
import { CloseOutlined } from "@ant-design/icons";
import MESSAGES from "constant/messages";
import SelectWithData from "component/SelectWithData";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import { LoadingOutlined } from "@ant-design/icons";

const { RangePicker } = TimePicker;
const { Item, useWatch } = Form;

dayjs.extend(customParseFormat);

const initialFormValues = {
  // date: dayjs(),
  // time: [dayjs(), undefined],
  // isFilterNurse: false,
  isDelete: false,
};

const antLoadIcon = <LoadingOutlined style={{ fontSize: 32, color: "#000" }} spin />;

interface IStaffScheduleDetail {
  openState: [boolean, Dispatch<SetStateAction<boolean>>]
  title: string;
  refetchData: () => void;
  selectedShiftItemState: [any, Dispatch<any>];
  allShiftDatasState: [any[], Dispatch<SetStateAction<any[]>>];
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}

const StaffScheduleDetail = ({
  openState,
  title,
  refetchData,
  selectedShiftItemState,
  allShiftDatasState,
  setIsEdited,
}: IStaffScheduleDetail) => {
  const [form] = Form.useForm();
  const disabledDate = (current: Dayjs) =>
    current && !current.isAfter(dayjs(), "M");
  const service = useService();
  const [open, setOpen] = openState;
  const [selectedShiftItem, setSelectedShiftItem] = selectedShiftItemState;
  const [allShiftDatas, setAllShiftDatas] = allShiftDatasState;
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedScheduleDetail, setFetchedScheduleDetail] = useState<any>();

  const handleClose = () => {
    setOpen(false);
    setSelectedShiftItem(undefined);
  };

  const handleSubmit = async (fieldValues: any) => {
    if (!selectedShiftItem?.schedule_date?.id) {
      return;
    }

    const { nurse } = fieldValues;
    // if (isDelete !== selectedShiftItem.delete) {
    //   const shiftDatas = [...allShiftDatas];
    //   const currentRow = shiftDatas[selectedShiftItem.originalRow];
    //   const updatedRow = currentRow.map((item: any) => {
    //     if (item.dragId === selectedShiftItem.dragId) {
    //       item.delete = isDelete;
    //       item.edited = true;
    //       return item;
    //     } else {
    //       return item;
    //     }
    //   });
    //   shiftDatas[selectedShiftItem.originalRow] = updatedRow;
    //   setAllShiftDatas(shiftDatas);
    //   handleClose();
    //   setIsEdited(true);
    // }

    if (
      !nurse 
      || nurse === selectedShiftItem.schedule_date.nurse_id
      || nurse.value === selectedShiftItem.schedule_date.nurse_id
    ) {
      return;
    }

    try {
      const response = await apiFactory.shiftManagementApi.updateNurseStaffForSchedule({
        scheduleDateId: selectedShiftItem.schedule_date.id,
        nurse_id: nurse,
      })
      if (response) {
        form.resetFields();
        refetchData();
        handleClose();
        setIsEdited(true);
      }
    } catch (error: any) {
      if (error && error.message && error.message.message) {
        toast.error(error.message.message);
      }
    }
  };

  const getStaffOptions = async () => (
      await axiosClient.get(service.SCHEDULES + "/nursing_staffs", {
        params: {
          date: dayjs(selectedShiftItem.schedule_date.date).format("YYYY/MM/DD"),
          time_range: selectedShiftItem.time_range,
        },
      })
    ).data.nursing_staffs.map((i: any) => ({
      label: i.family_name,
      value: i.id,
    }));

  useEffect(() => {
    if (!selectedShiftItem?.schedule_date?.id) {
      return;
    }

    // form.setFieldValue("nurse", {
    //   value: selectedShiftItem.staffId,
    //   label: selectedShiftItem.staffName
    // });

    const getScheduleDateDetail = async () => {
      try {
        const response = await axiosClient.get(`${service.SCHEDULE_DATES}/${selectedShiftItem.schedule_date.id}`);
        if (response) {
          setFetchedScheduleDetail(response.data);
          form.setFieldValue("nurse", { value: response.data.nurse_id, label: response.data.nurse.family_name});
        }
      } catch (error) {
        // error
      } finally {
        setLoading(false);
      }
    }

    getScheduleDateDetail();
  }, [selectedShiftItem])
  return (
    <ModalCustom
      className="shift-modal"
      title={title}
      closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      footer={null}
      open={open}
      onCancel={handleClose}
    >
      {
        loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin indicator={antLoadIcon} />
          </div>
        ) : (
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            className="mt-[26px]"
            form={form}
            initialValues={initialFormValues}
          >
            <Row style={{ marginBottom: "5px" }}>
              <Col span={5}>日にち</Col>
              <Col span={19}>：{fetchedScheduleDetail?.date || ""}</Col>
            </Row>
            <Row justify="start" style={{ marginBottom: "5px" }}>
              <Col span={5}>時間</Col>
              <Col span={19}>：{fetchedScheduleDetail?.time_range || ""}</Col>
            </Row>
            <Row style={{ marginBottom: "5px" }}>
              <Col span={5}>お客様</Col>
              <Col span={19}>：{fetchedScheduleDetail?.scheduleable?.patient?.family_name || ""}</Col>
            </Row>
            <Row style={{ marginBottom: "5px" }}>
              <Col span={5}>サービス名称</Col>
              <Col span={19}>：{fetchedScheduleDetail?.scheduleable?.service?.service_name || ""}</Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col span={5}>サービス内容</Col>
              <Col span={19}>：{fetchedScheduleDetail?.scheduleable?.service_type?.detail || ""}</Col>
            </Row>
            <Item
              name="nurse"
              label="介護スタッフ"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <SelectWithData
                getOptions={getStaffOptions}
                allowGetOption={Boolean(selectedShiftItem)}
                placeholder="選択してください"
              />
            </Item>

            <Row justify="end">
              {/* <Space>
                <Item name="isDelete" valuePropName="checked">
                  <Checkbox className="custom-checkbox" onChange={(e) => {
                    form.setFieldValue("isDelete", e.target.checked);
                  }}>削除</Checkbox>
                </Item>
              </Space> */}
              <Space size={8}>
                <ButtonOutline
                  onClick={handleClose}
                  className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[16px]"
                >
                  キャンセル
                </ButtonOutline>
                <ButtonSolid
                  // onClick={form.submit}
                  type="submit"
                  className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[54px]"
                >
                  保存
                </ButtonSolid>
              </Space>
            </Row>
          </Form>
        )
      }
    </ModalCustom>
  );
};

export default StaffScheduleDetail;
