import React from 'react'
import LeftArrow from 'assets/leftarrow.png'
import RightArrow from 'assets/rightarrow.png'
import styled from '@emotion/styled'
import { Button, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

const MonthButton = ({ getPreviousMonth, getNextMonth, month, className }: any) => {
  return (
    <div className={`flex flex-row justify-start ml-8 absolute ${className}`} style={{top:'85px', left:'110px'}} >
      <StyleBtn className='mr-3 font-bold' transform='scale(0.63)' p={0} variant="outline" onClick={() =>
        getPreviousMonth(month.clone().month(+month.format("MM") - 2))
      } >
        <img src={LeftArrow} alt="" style={{ height: '15px', width: '15px' }} />
      </StyleBtn>
      <Text className='mt-2 text-base'> {dayjs(month).format("YYYY/MM")} </Text>
      <StyleBtn className='ml-3 mr-6 font-bold' p={0} variant="outline" transform='scale(0.63)' onClick={() =>
        getNextMonth(month.clone().month(+month.format("MM")))
      } >
        <img src={RightArrow} alt="" style={{ height: '15px', width: '15px' }} />
      </StyleBtn>
    </div>
  )
}

const StyleBtn = styled(Button)({
  border: '1px solid black',
  fontSize: '20px'
})

export default MonthButton
