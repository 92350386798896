import styled from "@emotion/styled";
import { Tabs, TabsProps } from "antd";
import { ButtonSolid } from "component/button";
import { useMemo, useState } from "react";
import Important from "../components/board/Important";
import Conveyed from "../components/board/Conveyed";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import noticeSlice from "store/slice/noticeSlice";

const StyledBoardTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0;
    width: 80%;

    &:before {
      content: none;
    }

    .ant-tabs-tab {
      border: 1px solid #00000026;
      border-radius: 15px;
      .ant-tabs-tab-btn {
        color: #0000008c;
      }

      &.ant-tabs-tab-active {
        border: 1px solid #3492d7;
        background: #cae9ff;
        .ant-tabs-tab-btn {
          color: #0b3f65 !important;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
  .ant-tabs-content-holder {
  }
`;

const NewBoard: React.FC = () => {
  const { boardInnerTab } = useSelector((state: RootState) => state.notice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "重要事項",
        children: <Important />,
      },
      {
        key: "2",
        label: "申し送り事項",
        children: <Conveyed />,
      },
    ],
    []
  );

  const onChange: TabsProps["onChange"] = (activeKey) => {
    dispatch(noticeSlice.actions.setBoardInnerTab(activeKey));
  };

  return (
    <div className="bg-[#EEEEEE] px-[20px] py-[12px]">
      <div className="bg-white px-[12px] py-[18px] relative">
        <ButtonSolid
          className="!absolute top-[18px] right-[12px] w-[135px]"
          onClick={() => navigate(`/admin/notice/create?tab=${boardInnerTab}`)}
        >
          親スレッド投稿
        </ButtonSolid>
        <StyledBoardTabs
          activeKey={boardInnerTab}
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default NewBoard;
