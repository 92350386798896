import { Form, Input } from "antd";
import CustomPagination from "component/CustomPagination";
import { ButtonSolid } from "component/button";
import { INotification } from "constant/interfaces";
import { useCallback, useEffect, useState } from "react";
import apiFactory from "api";

const Important: React.FC = () => {
  const [notiData, setNotiData] = useState<INotification[]>([]);
  const [importantData, setImportantData] = useState<INotification[]>([]);
  const [form] = Form.useForm();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      setFetchLoading(true);
      const data = await apiFactory.hsNoticeStaffApi.getPostList({
        page,
        per: perPage,
        notify_tab: 1,
        search: form.getFieldValue("search"),
      });
      if (data) {
        setNotiData(data.notifications);
        setImportantData(data.importants);
        setTotalItems(data.total_items);
      }
    } catch (error) {
      //
    } finally {
      setFetchLoading(false);
    }
  }, [perPage, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="pt-[12px]">
      <Form
        form={form}
        onFinish={() => {
          setPage(1);
          fetchData();
        }}
      >
        <div className="flex">
          <Form.Item name="search" className="w-[200px] mr-2">
            <Input placeholder="検索" className="!border-[#D9D9D9] h-[35px]" />
          </Form.Item>
          <ButtonSolid type="submit">検索</ButtonSolid>
        </div>
      </Form>

      <ul>
        {importantData.map((item) => {
          return (
            <div
              className="flex border border-solid border-[#EB752626] px-[15px] py-[8px] mb-[5px] bg-[#FDF1E9]"
              key={item.id}
            >
              <div className="mr-[7px]">
                <span className="important p-[2px] rounded-[4px]">重</span>
              </div>
              <div className="mr-[80px] w-[180px]">
                <p className="font-semibold">{item.patient?.family_name}</p>
                <p>
                  <span className="text-[#0000008C]">作成日:</span>{" "}
                  {item.created_at}
                </p>
                <p>
                  <span className="text-[#0000008C]">展開日:</span>{" "}
                  {item.deployment_date}
                </p>
              </div>
              <div className="w-[700px]">
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </ul>

      <CustomPagination
        current={page}
        setCurrent={setPage}
        total={totalItems}
        perPage={perPage}
        setPerPage={setPerPage}
        pageSizeOptions={[10, 20, 50]}
      />

      <ul>
        {notiData.map((item) => {
          return (
            <div
              className="flex border-t border-b border-solid border-[#00000026] px-[15px] py-[8px]"
              key={item.id}
            >
              <div className="mr-[7px]">
                <span className="important p-[2px] rounded-[4px]">重</span>
              </div>
              <div className="mr-[80px] w-[180px]">
                <p className="font-semibold">{item.patient?.family_name}</p>
                <p>
                  <span className="text-[#0000008C]">作成日:</span>{" "}
                  {item.created_at}
                </p>
                <p>
                  <span className="text-[#0000008C]">展開日:</span>{" "}
                  {item.deployment_date}
                </p>
              </div>
              <div className="w-[700px]">
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Important;
