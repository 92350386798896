import { TabsProps } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import noticeSlice from "store/slice/hsStaffNoticeSlice";
import { Communication } from "./Communication/Communication";
import NewBoard from "./NewBoard";
import { StyledNotiTabs } from "./styled";
import PageHeaderTitle from "component/PageHeaderTitle";

export default function Notice() {
  const [tabKey, setTabKey] = useState("1");

  const [boardTitle, setBoardTitle] = useState("掲示板");
  const [contactTitle, setContactTitle] = useState("個別連絡");
  const [title, setTitle] = useState("掲示板");
  const [isImportant, setIsImportant] = useState<boolean>(false);
  const { tab } = useSelector((state: RootState) => state.hsStaffNotice);
  const dispatch = useDispatch();

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `掲示板`,
        children: <NewBoard />,
      },
      {
        key: "2",
        label: `個別連絡`,
        children: (
          <Communication
            onChangeTitle={setTitle}
            setContactTitle={setContactTitle}
          />
        ),
      },
    ],
    []
  );

  // Handle when change tab
  const onChange = (e: any) => {
    switch (e) {
      case "1":
        dispatch(noticeSlice?.actions.setTab(1));
        setTitle(boardTitle);
        setTabKey("1");
        break;
      case "2":
        dispatch(noticeSlice?.actions.setTab(2));
        setTitle(contactTitle);
        setTabKey("2");
        break;
      default:
        setTabKey("3");
        break;
    }
  };

  return (
    <main className="after:content-[''] after:absolute after:top-0 after:bg-white after:left-0 after:right-0 after:h-[110px] after:z-0">
      <PageHeaderTitle
        title={
          <>
            {isImportant && <span className="important px-1 mr-3">重要</span>}{" "}
            お知らせ{" "}
          </>
        }
      />

      <StyledNotiTabs
        className="relative z-[1]"
        activeKey={tab.toString()}
        items={items}
        onChange={onChange}
      />
    </main>
  );
}
