import React, { useEffect, useState } from "react";
import { Calendar } from "antd";
import styled from "@emotion/styled";
import dayjs, { Dayjs } from "dayjs";
import { MonthButton } from "component/timeBtn";
import { Text } from "@chakra-ui/react";
import staffApi from "api/staffApi";
import { getCookie } from "utils";
import moment from "moment";
import { DATE_FORMAT } from "constant/date";
let dataFake = [
  { day: 29, data: ["訪問2"] },
  { day: 9, data: ["訪問9"] },
  { day: 10, data: ["訪問11"] },
  { day: 12, data: ["訪問12"] },
]


const CalendarVisitSchedule = () => {
  const idToken = getCookie("access_token")
  const [dataMonth, setDataMonth]: any = useState([])
  const customDayInWeekend: any = {
    lang: {
      locale: "ja_JP",
      shortWeekDays: ["日", "月", "火", "水", "木", "金", "土"],
    },
  };
  const [monthSelect, setMonthSelect]: any = useState(new Date())
  const dateCellRender = (value: Dayjs) => {
    const listData: any = getListData(value);
    return (
      <ul className="events" style={{ background: '#e07b2e' }}>
        {listData?.data?.map((item: any) => (
          <Text bg='brand.500'>{item}</Text>
        ))}
      </ul>
    );
  };

  const getListData = (value: Dayjs) => {
    let listData;
    dataMonth.map((item: any) => {
      if (item.day === value.date()) {
        listData = {
          data: item.data,
          color: item.color
        };
        // listData = item.data
        return listData;
      }
    });
    // console.log('listData', listData)
    return listData || {
      data: [],
      color: false
    };;
  };

  const getMonthSchedule = async () => {
    try {
      const param = {
        month_date: moment(monthSelect).format(DATE_FORMAT)
      }

      const { data } = await staffApi.getStaffVisitSchedule(idToken, param)
      if (data) {
        const newRes: any = data.data
        const newArr: any = []

        for (const [key, value] of Object.entries(newRes)) {
          const newVal: any = value
          newArr.push({
            day: new Date(key).getDate(),
            data: [`訪問${newVal.count}`],
            color: newVal.has_color
          })
        }
        setDataMonth(newArr)
        // const mappingData = Object.values(newRes).map((item: any) => {
        //   console.log('item', item)

        // })
        // console.log('mappingData', mappingData)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getMonthSchedule()
  }, [monthSelect])

  const cellRender = (current: Dayjs) => {
    return dateCellRender(current);
  };

  const onSelectDate = (newValue: any) => {
    const newDate = new Date(newValue)
    setMonthSelect(newDate);

  };
  return (
    <div>
      <CalendarCustom
        fullCellRender={(value: any) => {
          const curMonth = new Date(value).getMonth()
          const newValue: any = getListData(value)
          if (monthSelect.getMonth() === curMonth) {
            return (
              <div style={{ background: `${(newValue.color) ? '#e07b2e' : '#fff'}` }} className="h-[92px] pt-[12px] pr-[9px]">
                <Text color={`${(newValue.color) ? 'white' : '#000'}`} >{dayjs(value).format('D')}</Text>
                <div className="absolute left-[10px] top-[10px]">
                  {newValue.data.map((item: any, index: any) => (
                    <Text color={`${(newValue.color) ? 'white' : '#000'}`} key={index} >{item}</Text>
                  ))}
                </div>

              </div>
            )
          } else {
            return (
              <div className="h-[92px] pt-[12px] pr-[9px]">
                <Text>{dayjs(value).format('D')}</Text>

              </div>
            )
          }

        }}
        // cellRender={cellRender}
        headerRender={(props: any) => {
          return (
            <div style={{ position: 'absolute', top: '95px', left: '48px' }} >
              <MonthButton
                month={props.value}
                getPreviousMonth={props.onChange}
                getNextMonth={props.onChange}
              />
            </div>
          );
        }}
        onChange={onSelectDate}
        locale={customDayInWeekend}
      />
      {/* <div className="flex justify-end mt-12 mb-2">
        <ButtonFooter title={"戻る"} />
      </div> */}
    </div>
  );
};

export default CalendarVisitSchedule;

export const CalendarCustom = styled(Calendar)`
  font-family: var(--primary-font-family);
  td>div {
    font-size: 13px;
  }
  th,
  td {
    border: 1px solid black !important;
    font-weight: 700 !important;
  }
  th {
    padding: 4.5px 4px !important;
    text-align: left;
    font-size: 15px !important;
    &:first-child {
      color: red;
    }
    &:last-child {
      color: #5C85B0;
    }
  }
  .ant-radio-group {
    display: none;
  }
  .ant-picker-calendar-header {
    justify-content: flex-start;
  }
  thead>tr {
    height: 28px;
    >th {
      background: var(--secondary-color);
      height: 28px;
      font-size: 14px !important;
    }
  }
`;
