import { Input, InputProps } from 'antd';
import './style.scss';

interface IInputProps extends InputProps {
    className?: string
}
export default function InputCustom({ className = '', ...restProps }: IInputProps) {
    return (
        <Input className={className} {...restProps} />
    )
}
