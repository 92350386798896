import styled from "@emotion/styled";
import { Td } from "@chakra-ui/react";

const Styles = styled.div`
  display: flex;
  align-items: center;
  .title {
    max-width: 30px;
    white-space: normal;
  }
  .cell-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    border-radius: 0px;
  }
  .checkboxCustom {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyleContainer = styled.div `
.ant-select-selector {
  border-radius: 6px !important;
}
`
export { Styles, StyleContainer };
