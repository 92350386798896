import styled from "@emotion/styled";

export const StylesPagination = styled.div`
  .ant-pagination-item {
    border: 1px solid #000 !important;
  }

  .ant-pagination-next {
    border: 1px solid #000 !important;
  }
  .ant-pagination-prev {
    border: 1px solid #000 !important;
  }
`;

const Styles = styled.div`
  white-space: nowrap;

  .ant-input-affix-wrapper {
    border-color: black;
    border-radius: 2px;
  }

  .ant-select-selector {
    border-radius: 2px;
  }
  .ant-pagination-options-quick-jumper input {
    border-color: black;
    border-radius: 2px;
  }
  .ant-pagination-item {
    border: 1px solid #000 !important;
  }
  .ant-pagination-jump-next, .ant-pagination-jump-prev {
    display: inline-flex;
  }

  .ant-pagination-item-link {
    border: 1px solid #000 !important;
    border-radius: 6px;
    width: 32px;
  }

  .ant-table-wrapper .ant-table-container {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }
  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0;
  }
  .rowHasBg {
    background: #f5f4f2;
  }
  .ant-table-column-sorter {
    color: #000000;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: unset;
  }
  // for table
  .tableCustom1 {
    tr > td {
      border: none;
      border-left: 1px solid #000;
      padding: 5px 5px 0px 5px;
    }

    tr:last-child > td {
      padding-bottom: 5px;
    }

    tr > td:last-child {
      border-right: 1px solid #000;
    }
  }

  .tableCustom2 {
    tr > td {
      border: none;
      border-left: 1px solid #000;
      padding: 5px 5px 0px 5px;
    }

    tr:first-child {
      border-top: 1px solid #000;
    }

    tr:last-child {
      border-bottom: 1px solid #000;
    }

    tr:last-child > td {
      padding-bottom: 5px;
    }

    tr > td:last-child {
      border-right: 1px solid #000;
    }
  }

  .table1ByDay {
    tr:first-child {
      height: 36.5px;
    }
    tr:first-child td {
      height: 36.5px;
    }
    tr:nth-child(3) > td:first-child {
      border-left: none;
    }
    tr:nth-child(4) > td:first-child {
      border-left: none;
    }
    tr:nth-child(4) > td {
      border-bottom: none;
    }
  }
  .boxTitleCustom {
    text-align: center;
    background-color: #ffffff;
    width: 106px;
    p {
      top: 50%;
      left: 50%;
      height: unset;
      transform: translate(-50%, -50%);
    }
  }
  .boxTitleCustom:nth-child(1) {
    padding: 0;
    height: 45px;
    line-height: 45px;
    text-align: center;
  }
  .boxTitleCustom:nth-child(2) {
    height: 206px;
    border-top: none;
    border-bottom: none;
  }
  .boxTitleCustom:last-child {
    height: 206px;
  }
`;
export default Styles;
