import dayjs, { Dayjs } from "dayjs";
import { createSearchParams, Route, useNavigate, useParams, useLocation } from "react-router-dom";
import {
  DATE_FORMAT,
  DAYS_IN_WEEK,
  MONTH_FORMAT,
  TIME_FORMAT,
  formatDate,
} from "constant/date";
import { Table, TableProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ButtonSolid } from "component/button";
import MonthChanger, { CURRENT_DATE_KEY } from "component/MonthChanger";
import ROUTES from "constant/routes";
import { useQuery } from "react-query";
import apiFactory from "api";
import { NursingCareHistoryI } from "types/admin";
import {
  MappingComplextion,
  MappingDevision,
  MappingSweating,
} from "enums/nursingCare";
import { MAX_QUERY_PER_PAGE } from "constant/apiRequest";
import { getCookie } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const START_OF_DATE = "00:00";
export const END_OF_DATE = "24:00";

function NursingCareRecordDetail() {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [currentDate, setCurrentDate] = useState<Dayjs>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const [nursingCareHitories, setNursingCareHitories] =
    useState<NursingCareHistoryI[]>();

  const [isFetchingNursingCareHistories, setIsFetchingNursingCareHistories] =
    useState<boolean>(false);

  const { isLoading } = useQuery(
    ["nursing-care-detail", currentDate, id],
    () =>
      apiFactory.adminNursingCareRecordApi.getNursingCareDetail({
        year_month: currentDate?.format(MONTH_FORMAT),
        nurse_id: id,
        per: MAX_QUERY_PER_PAGE,
      }),
    {
      enabled: !!currentDate,
      onSuccess: async (data) => {
        try {
          setIsFetchingNursingCareHistories(true);

          const newNursingCareHistories = await Promise.all(
            (data?.serialized_nursing_care_histories || [])?.map(
              (item: NursingCareHistoryI) => {
                const idToken = getCookie("access_token");

                return new Promise(async (resolve) => {
                  const nursingCareDetail =
                    await apiFactory.adminNursingCareRecordApi.getDetailNursingCare(
                      idToken,
                      { schedule_date_id: item?.schedule_date_id },
                      user?.role
                    );
                  resolve({
                    ...item,
                    updated_time: nursingCareDetail?.updated_time,
                  });
                });
              }
            )
          );

          setNursingCareHitories(newNursingCareHistories);
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetchingNursingCareHistories(false);
        }
      },
    }
  );

  const columns: TableProps<any>["columns"] = useMemo(() => {
    return [
      {
        title: "お客様",
        dataIndex: "id",
        key: "id",
        align: "center" as const,
        width: 200,
        render: (_: string, record: NursingCareHistoryI, index: number) => {
          const dayInWeek =
            DAYS_IN_WEEK?.[dayjs(searchParams.get("updated_time")).day()];

          return (
            <p className="whitespace-nowrap flex gap-x-[20px]">
              <span className="min-w-[20px]">{index + 1}</span>
              <span>{dayInWeek}</span>
              <span className="max-w-[200px] truncate">
                {record?.patient?.family_name}
              </span>
            </p>
          );
        },
      },
      {
        title: "開始時刻",
        dataIndex: "start_time",
        key: "start_time",
      },
      {
        title: "終了時刻",
        dataIndex: "end_time",
        key: "end_time",
        render: (text: string, record: NursingCareHistoryI) => {
          if (text === START_OF_DATE) {
            return END_OF_DATE;
          }

          return text;
        },
      },
      {
        title: "対応終了実時間",
        dataIndex: "updated_time",
        key: "updated_time",
        render: (_: string, record: NursingCareHistoryI) => {
          return dayjs(record?.updated_time).format(TIME_FORMAT)
        },
      },
      {
        title: "区分",
        dataIndex: "division",
        key: "division",
        render: (text: string) => {
          return MappingDevision[text as keyof typeof MappingDevision];
        },
      },
      {
        title: "顔色",
        dataIndex: "complexion",
        key: "complexion",
        render: (text: string) =>
          MappingComplextion[text as keyof typeof MappingComplextion],
      },
      {
        title: "発汗",
        dataIndex: "sweating",
        key: "sweating",
        render: (text: string) =>
          MappingSweating[text as keyof typeof MappingSweating],
      },
      {
        title: "排尿",
        dataIndex: "urination",
        key: "urination",
      },
      {
        title: "排便",
        dataIndex: "defecation",
        key: "defecation",
      },
      {
        title: "水分",
        dataIndex: "hydration",
        key: "hydration",
      },
      {
        title: "全身浴",
        dataIndex: "full_body_bath",
        key: "full_body_bath",
      },
      {
        title: "",
        dataIndex: "button_action",
        key: "button_action",
        render:(_, item) => (
          <ButtonSolid
            h="26px"
            fontSize="14px"
            w="46px"
            onClick={() => {
              const itemAny = item as any;
              navigate({
                pathname: `/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_MANAGEMENT}/${item.patient.id}/`,
                search: createSearchParams({
                  date: item.schedule_date.date,
                  seriveName: item.service.service_name,
                  isHistory: "true",
                  redirectPath: location.pathname + location.search,
                  schedule_date_id: item.schedule_date_id,
                  division: itemAny.division,
                  patient: item.patient.last_name + item.patient.first_name,
                  type: "edit",
                }).toString(),
              });
            }}
          >
            登録
          </ButtonSolid>
        )
      },
    ];
  }, []);

  const handleChangeCurrentDate = (date: Dayjs) => {
    setCurrentDate(date);
    navigate({
      pathname: window.location.pathname,
      search: createSearchParams({
        currentDate: date.format(DATE_FORMAT),
      }).toString(),
    });
  };

  const hanldeBack = () => {
    navigate(`/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}`);
  };

  useEffect(() => {
    const newDate = dayjs(searchParams.get(CURRENT_DATE_KEY)).isValid()
      ? dayjs(searchParams.get(CURRENT_DATE_KEY))
      : dayjs();
    setCurrentDate(newDate);
  }, [searchParams]);

  return (
    <div className="pb-[100px]">
      <div className="flex mb-[22px] mt-[10px]">
        <p className="font-bold mr-[10px]">介護記録・履歴　記入者</p>
        <p>{searchParams?.get("nursing_name") || ""}</p>
      </div>

      <div className="bg-white px-[10px] py-[20px]">
        <div className="mb-[20px]">
          <MonthChanger onChange={handleChangeCurrentDate} />
        </div>

        <Table
          pagination={false}
          loading={isLoading || isFetchingNursingCareHistories}
          dataSource={nursingCareHitories}
          columns={columns}
          scroll={{
            x: 600,
          }}
        />
      </div>

      <div className="bg-white fixed bottom-0 right-0 w-[100vw] h-[80px] flex justify-end items-center pr-[20px]">
        <ButtonSolid onClick={hanldeBack}>一覧に戻る</ButtonSolid>
      </div>
    </div>
  );
}

export default NursingCareRecordDetail;
