import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { ButtonOutline } from "component/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import EnhanceTable from "component/admin/table";
import useTable from "hook/useTable";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import dayjs from "dayjs";
import { JP_DATE_FORMAT, formatDate } from "constant/date";
import { Form } from "antd";
import { IChangeHistory } from "types/admin";

const columns: ColumnsType<IChangeHistory> = [
  {
    align: "center",
    title: "日時",
    dataIndex: "date",
    sorter: true,
    render: (value) => dayjs(value).format(JP_DATE_FORMAT),
  },
  {
    align: "center",
    title: "更新者",
    dataIndex: "user_admin",
    sorter: true,
    render: (value) => value.user_name,
  },
  {
    align: "center",
    title: "項目",
    dataIndex: "changed_attribute",
    sorter: true,
  },
  {
    align: "center",
    title: "変更前",
    dataIndex: "before_change",
    sorter: true,
  },
  {
    align: "center",
    title: "変更後",
    dataIndex: "after_change",
    sorter: true,
  },
  { align: "center", title: "", dataIndex: "", width: 200 },
];

export default function CustomerChangeHistory() {
  const { data, form, loading, setData, setLoading, setTotal, total } =
    useTable();
  const service = useService();
  const [title, setTitle] = useState(" ");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const changeable_id = searchParams.get("changeable_id");
  const changeable_type = searchParams.get("changeable_type");
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.CHANGE_HISTORIES,
        method: "get",
        params: { ...form.getFieldsValue(), changeable_id, changeable_type },
      });
      setData(res.data.change_histories);
      setTitle(res.data.title);
      setTotal(res.data.total_items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Form form={form} onFinish={fetchData}>
      <div className="page-title">{title}&nbsp;</div>
      <div className="page-container">
        <EnhanceTable
          data={data}
          form={form}
          loading={loading}
          columns={columns}
          total={total}
        />
        <div className="text-end mt-[39px]">
          {/* <p className='text-[#000AFF] text-[20px] leading-[13px] mt-[15px] ml-[70px]'>↑☆他の「変更履歴」も同じ</p> */}
          <ButtonOutline
            onClick={() => navigate(-1)}
            className="!px-[36px] !text-[14px] !leading-[24px] h-[35px]"
          >
            戻る
          </ButtonOutline>
        </div>
      </div>
    </Form>
  );
}
