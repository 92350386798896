export const notificationCategoryOptions = [
  { value: 1, label: "従業員申し送り（件ごと）　一覧" },
  {
    value: 2,
    label: "お客様申し送り（1日単位でまとめ）　ツリー",
  },
  { value: 3, label: "レクリエーション" },
  { value: 4, label: "事故報告" },
  { value: 5, label: "ヒアリハット" },
];
