interface ICheckBoxPDFProps {
  checked: boolean;
  className?: string;
}

const CheckBoxPDF = ({ className, checked }: ICheckBoxPDFProps) => {
  return (
    <div
      className={`w-[10px] h-[10px] border border-black ${
        className ? className : ""
      } ${checked ? "bg-black" : ""}`}
    ></div>
  );
};
export default CheckBoxPDF;
