import { FlexRow } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useState } from "react";
import BasicInfomationForm from "./BasicInfomationForm";
import { DatePickerProps, Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useNavigate, useParams } from "react-router-dom";
import { IContact, IHospital } from "constant/interfaces";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { toast } from "react-toastify";

const defaultContactRelative: Partial<IContact> = {
  address: "",
  cellphone_number: "",
  telephone_number: "",
  name: "",
  relationship: "",
};

const defaultHospital: Partial<IHospital> = {
  address: "",
  clinical_department: "",
  name: "",
  phone: "",
};

const BasicInformation = () => {
  const [defaultValue, setDefaultValue] = useState({
    id: "",
    password: "",
    surname: "",
    givenname: "",
    kataSurname: "",
    katagivenname: "",
    gender: "",
    birthDate: "",
    birthMonth: "",
    birthYear: "",
    eras: "heisei",
    postCode: "",
    prefecture: "",
    municipality: "",
    streetBunch: "",
    buildingName: "",
    phone: "",
    cellphone: "",
    familyName1: "",
    relationshop1: "",
    address1: "",
    telephone1: "",
    cellphoneNum1: "",
    familyName2: "",
    relationshop2: "",
    address2: "",
    telephone2: "",
    cellphoneNum2: "",
    usageSituation: "",
    hostpitalName: "",
    clinicDep: "",
    hospitalPhone: "",
    hospitalAddr: "",
    curDisease: "",
    familyInterview: "",
    personalInterview: "",
  });

  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const [disable, setDisable] = useState(true);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [dateContract, setDateContract] = useState();
  const service = useService();

  const onFinishFailed = (errorInfo: any) => {};

  const handleSubmit = async (formData: any) => {
    formData.contact_relatives_attributes = formData.contact_relatives;
    formData.hospital_attributes = formData.hospital;
    formData.password = formData.password || undefined;
    delete formData.contact_relatives;
    delete formData.hospital;
    try {
      const res = await axiosClient({
        method: id ? "put" : "post",
        url: `${service.PATIENTS}${id ? `/${id}` : ""}`,
        data: formData,
      });
      toast.success(res.data.success);
      setDisable(false);
      // !id && navigate(`/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}`);
    } catch (error) {
      toast.error("Error");
    }
  };

  const onChangeDateContract: DatePickerProps["onChange"] = (date: any) => {
    // const newDate = moment(date).format()
    setDateContract(date);
  };

  const onChangeValue = (evt: any) => {
    const name = evt.target.name;
    setDefaultValue({ ...defaultValue, [name]: evt.target.value });
  };

  return (
    <div>
      <Form
        disabled={!disable}
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        initialValues={{
          contact_relatives: [defaultContactRelative, defaultContactRelative],
          hospital: defaultHospital,
        }}
        autoComplete="off"
        scrollToFirstError={{ block: "center" }}
      >
        <FlexRow className="justify-end">
          {user?.role === "staff" && (
            <ButtonSolid
              onClick={() => navigate(-1)}
              width="137px"
              height="35px"
              fontSize="16px"
              m={3}
            >
              一覧へ
            </ButtonSolid>
          )}
        </FlexRow>
        <BasicInfomationForm
          value={defaultValue}
          onChangeValue={onChangeValue}
          setValue={setDefaultValue}
          disable={disable}
          form={form}
          onChangeDateContract={onChangeDateContract}
          dateContract={dateContract}
        />
        <FlexRow className="justify-between mt-3">
          {!disable && (
            <FlexRow
              style={{ justifyContent: "flex-end", margin: "100px 0px" }}
            >
              <ButtonOutline
                onClick={() => setDisable(true)}
                width="92px"
                m={3}
                height="32px"
              >
                キャンセル
              </ButtonOutline>
            </FlexRow>
          )}
        </FlexRow>
      </Form>
    </div>
  );
};

export default BasicInformation;
