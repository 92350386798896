import { Table } from "antd";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import SelectTime from "component/SelectTime";
import { ButtonOutline, ButtonSolid } from "component/button";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import Styles from "./styled";
import withSelect from "HOC/withSelect";
import useSelectArea, { deselectEvent } from "hook/useSelectArea";
import { useEffect, useLayoutEffect, useRef } from "react";
import { CreateModal, TModal } from "../components/Modal";
import usePrint from "hook/usePrint";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { toast } from "react-toastify";
import apiFactory from "api";
import { useLocation } from "react-router-dom";
require("dayjs/locale/ja");

export interface IAreaTableSelect {
  staff_id?: number;
  date_range: Dayjs[];
}

const ShiftItem = withSelect(({ data }: any) => {
  return (
    // <Popover content={<></>}>
    <div
      className="h-[100%] flex items-center justify-center"
      style={{
        background: data?.[0]?.background_color,
        color: data?.[0]?.text_color,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textOrientation: "upright",

        // height: 100,
      }}
    >
      <span style={{ writingMode: "vertical-lr" }}>
        {data?.[0]?.shift_text}
      </span>
    </div>
    // </Popover>
  );
});

export default function () {
  const location = useLocation();
  const service = useService();
  const [time, setTime] = useState<Dayjs>(dayjs(location?.state?.dateTime));
  const [summary, setSummary] = useState<any[]>([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllowSave, setIsAllowSave] = useState(false);
  const selectAreaRef = useSelectArea();
  const { ref, isCapturing, setCapturing } = usePrint();
  const [modalType, setModalType] = useState<TModal>("select-range-date");
  const [rangeSelect, setRangeSelect] = useState<IAreaTableSelect>({
    staff_id: undefined,
    date_range: [],
  });
  const [isCreateShiftDialogOpen, setIsCreateShiftDialogOpen] =
    useState<boolean>(false);
  const count = useRef(0);
  const fetchInitialData = async (showLoading?: boolean) => {
    count.current += 1;
    let currentCount = count.current;
    if (showLoading) setIsLoading(true);
    console.log('time.format("YYYY/MM")', time.format("YYYY/MM"));
    const response = await axiosClient.get(service.SHIFT_MANAGEMENTS, {
      params: { year_month: time.format("YYYY/MM") },
    });
    if (currentCount !== count.current) return;
    setIsLoading(false);
    setData(response.data.data);
    setSummary(response.data.sum_by_date);
    setIsAllowSave(Boolean(response.data.has_initial));
  };
  const handleAreaSelect = (isSelect: boolean, record: any, day: Dayjs) => {
    if (isSelect) {
      setRangeSelect((prev) => ({
        staff_id: record.staff.id,
        date_range: [...prev.date_range, day].sort((a, b) =>
          a.isAfter(b) ? 1 : -1
        ),
      }));
    } else
      setRangeSelect((prev: any) => ({
        staff_id: record.staff.id,
        date_range: prev.date_range.filter(
          (item: Dayjs) => item.format("DD") !== day.format("DD")
        ),
      }));
  };
  const handleDeselectAllShifts = () => {
    const currentSelectedElements =
      ref.current?.getElementsByClassName("selectable");
    if (!currentSelectedElements) return;
    for (let i = 0; i < currentSelectedElements.length; i++) {
      currentSelectedElements[i].dispatchEvent(deselectEvent);
    }
  };
  const handleAutoRegisterShift = async () => {
    try {
      const response = await apiFactory.shiftManagementApi.autoRegisterShift({
        year_month: dayjs(time).format("YYYY/MM")
      })
      if (response) {
        toast.success(response.success || "更新に成功しました");
        fetchInitialData();
      }
    } catch (error: any) {
      if (error && error.message && error.message.error) {
        toast.error(error.message.error);
      }
    }
  };
  const handleClosePopup = () => {
    setIsCreateShiftDialogOpen(false);
    handleDeselectAllShifts();
    setRangeSelect({ date_range: [], staff_id: undefined });
    fetchInitialData();
  };
  const handleSave = async (status: "cancel" | "draft" | "sent") => {
    try {
      const response = await axiosClient.post(
        service.SHIFT_MANAGEMENTS + "/update_status",
        {
          status,
          year_month: time.format("YYYY/MM"),
        }
      );
      toast.success(response.data.success);
      fetchInitialData();
    } catch (error) {
      toast.error((error as string).toString());
    }
  };
  const renderTable = useMemo(() => {
    return (
      <Styles>
        <Table
          loading={isLoading}
          scroll={{ x: true }}
          className="with-color-row-even"
          ref={selectAreaRef}
          dataSource={data}
          pagination={false}
          summary={() =>
            !isCapturing && (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="right">
                    休日　：
                  </Table.Summary.Cell>
                  {[...Array(time.daysInMonth())].map((item, index) => (
                    <Table.Summary.Cell align="center" index={index + 2}>
                      {summary[index]?.holiday || ""}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={0} />
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="right">
                    早番　：
                  </Table.Summary.Cell>
                  {[...Array(time.daysInMonth())].map((item, index) => (
                    <Table.Summary.Cell align="center" index={index + 2}>
                      {summary[index]?.early || ""}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={0} />
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="right">
                    遅番　：
                  </Table.Summary.Cell>
                  {[...Array(time.daysInMonth())].map((item, index) => (
                    <Table.Summary.Cell align="center" index={index + 2}>
                      {summary[index]?.late || ""}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={0} />
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} align="right">
                    夜勤　：
                  </Table.Summary.Cell>
                  {[...Array(time.daysInMonth())].map((item, index) => (
                    <Table.Summary.Cell align="center" index={index + 2}>
                      {summary[index]?.night || ""}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={0} />
                </Table.Summary.Row>
              </Table.Summary>
            )
          }
        >
          <Column
            title="介護スタッフ"
            dataIndex={["staff", "family_name"]}
            align="center"
          />
          {[...Array(time.daysInMonth())].map((item, index) => (
            <ColumnGroup title={index + 1} align="center" key={index}>
              <Column
                align="center"
                className="shift-status"
                title={time
                  .locale("ja")
                  .date(index + 1)
                  .format("ddd")}
                render={(value, record: any) => {
                  const shiftData = record.shift_data?.[index]?.shifts;
                  return (
                    <ShiftItem
                      data={shiftData}
                      parentRef={selectAreaRef}
                      text={shiftData?.shift_text}
                      color={shiftData?.text_color}
                      background={shiftData?.background_color}
                      allowSelect={
                        !rangeSelect.staff_id ||
                        rangeSelect.staff_id === record.staff.id
                      }
                      onAreaSelect={(isSelect: boolean) =>
                        handleAreaSelect(
                          isSelect,
                          record,
                          dayjs(time).date(index + 1)
                        )
                      }
                    />
                  );
                }}
              />
            </ColumnGroup>
          ))}
          <Column
            title=""
            render={(value, record: any) =>
              !isCapturing && (
                <div>
                  <div className="whitespace-nowrap">
                    休日　：{record.holidays_count}
                  </div>
                  <div className="whitespace-nowrap">
                    {" "}
                    早番　：{record.early_shift}
                  </div>
                  <div className="whitespace-nowrap">
                    {" "}
                    遅番　：{record.late_shift}{" "}
                  </div>
                  <div className="whitespace-nowrap">
                    夜勤　：{record.night_shift}
                  </div>
                </div>
              )
            }
          />
        </Table>
      </Styles>
    );
  }, [isCapturing, data, summary, time, rangeSelect.staff_id, isLoading]);
  useEffect(() => {
    const onAreaRemove = () => {
      setRangeSelect({ staff_id: undefined, date_range: [] });
    };
    const onAreaSelect = () => {
      if (rangeSelect.staff_id && rangeSelect.date_range.length) {
        setIsCreateShiftDialogOpen(true);
        setModalType("select-range-date");
      }
    };
    selectAreaRef.current?.addEventListener("arearemove", onAreaRemove);
    selectAreaRef.current?.addEventListener("areaselect", onAreaSelect);
    return () => {
      selectAreaRef.current?.removeEventListener("arearemove", onAreaRemove);
      selectAreaRef.current?.removeEventListener("areaselect", onAreaSelect);
    };
  }, [rangeSelect]);
  useLayoutEffect(() => {
    fetchInitialData(true);
  }, [time]);
  return (
    <div>
      <div className="flex justify-between ">
        <SelectTime
          format="YYYY/MM"
          type="month"
          value={time}
          onChange={setTime}
        />
        <div className="flex gap-[14px]">
          <ButtonSolid width={137} onClick={() => setCapturing(true)}>
            印刷
          </ButtonSolid>
          <ButtonSolid
            width={137}
            onClick={() => {
              setIsCreateShiftDialogOpen(true);
              setModalType("select-single-date");
            }}
          >
            休日・出勤登録
          </ButtonSolid>
          <ButtonSolid width={137} onClick={() => handleAutoRegisterShift()}>自動シフト登録</ButtonSolid>
        </div>
      </div>
      <div ref={ref} role="table" className="mt-[39px] overflow-auto">
        {renderTable}
      </div>
      <div className="text-right mt-[29px]" hidden={!isAllowSave}>
        <ButtonOutline onClick={() => handleSave("cancel")}>
          キャンセル
        </ButtonOutline>
        <ButtonSolid
          onClick={() => handleSave("draft")}
          className="w-[137px] ml-[8px]"
        >
          下書き保存
        </ButtonSolid>
      </div>
      <CreateModal
        type={modalType}
        open={isCreateShiftDialogOpen}
        onCancel={handleClosePopup}
        data={rangeSelect}
      />
    </div>
  );
}
