import BaseApi from "api/baseApi";
import { ROOM_HS_ADMIN } from "../../apiConstant";

class HSRoom extends BaseApi {
  createRoom(body: { target_id: number }) {
    return this.post(ROOM_HS_ADMIN, {
      ...body,
      target_type: "home_system_staff",
    });
  }

  getContactList(body: {
    per?: number;
    page?: number;
    unread?: boolean;
    search?: string;
  }) {
    return this.get(ROOM_HS_ADMIN, body);
  }

  getContactDetail(body: { id: number; per: number; page: number }) {
    return this.get(
      `${ROOM_HS_ADMIN}/${body.id}?per=${body.per}&page=${body.page}`
    );
  }

  createMessage(body: { id: number; content: string }) {
    return this.post(`${ROOM_HS_ADMIN}/${body.id}/messages`, body);
  }

  readMessage(id: number) {
    return this.put(`${ROOM_HS_ADMIN}/${id}`, {});
  }
}

export default HSRoom;
