import { Tooltip } from "antd";
import { CSSProperties, memo } from "react";
import { twMerge } from "tailwind-merge";

interface ScheduleCardProps {
  name?: string;
  serviceName?: string;
  staffName?: string;
  onClick?: () => void;
  isHistory?: boolean;
  numOfSections?: number;
  className?: string;
  style?: CSSProperties;
}

export const CARD_HEIGHT = 90;
export const GAP_Y = 10;

const ScheduleCard = (props: ScheduleCardProps) => {
  const {
    name,
    serviceName,
    staffName,
    isHistory,
    numOfSections = 1,
    className,
    style,
    onClick,
  } = props;

  return (
    <div
      className={twMerge(
        "text-[12px] p-[10px] relative overflow-y-visible w-full",
        className
      )}
      style={style}
    >
      <div
        style={{
          backgroundColor: isHistory ? "#666666" : "#ffff00",
          height: numOfSections * CARD_HEIGHT + (numOfSections - 1) * GAP_Y,
        }}
        className="absolute top-0 left-0 cursor-pointer p-[10px] w-full border border-[#e3e3e3]"
        onClick={onClick}
      >
        <p>お客様:</p>
        <Tooltip title={name}>
          <p className="truncate font-[600]">{name}</p>
        </Tooltip>

        <span>{serviceName}</span>

        <Tooltip title={staffName}>
          {staffName ? (
            <p className="truncate font-[600]">{staffName}</p>
          ) : (
            <p className="truncate">未対応</p>
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default memo(ScheduleCard);
