import { Checkbox, Form, Input } from "antd";
import MESSAGES from "constant/messages";

interface IOption {
  name: string;
  label: string;
  noteName: string;
  options: {
    label: string;
    value: string;
  }[];
}
interface IOptions {
  staple_food_option: IOption;
  side_meal_option: IOption;
  eating_utensil_option: IOption;
  luxury_goods_option: IOption;
  neck_option: IOption;
  upper_limb_option: IOption;
  belt_option: IOption;
  hip_joint_option: IOption;
  bottom_option: IOption;
  form_movement_option: IOption;
  excretory_form_option: IOption;
  dentures_option: IOption;
}
export const OPTIONS: IOptions = {
  staple_food_option: {
    name: "staple_food_option",
    label: "形態・主食",
    noteName: "staple_food",
    options: [
      { label: "常食", value: "has_regular_staple_food" },
      { label: "粥食", value: "has_porridge_staple_food" },
      { label: "軟飯", value: "has_rice_staple_food" },
    ],
  },
  side_meal_option: {
    name: "side_meal_option",
    label: "形態・副食",
    noteName: "side_meal",
    options: [
      { label: "常食", value: "has_regular_side_meal" },
      { label: "きざみ", value: "has_minced_side_meal" },
      { label: "ミキサー", value: "has_blended_side_meal" },
      { label: "一口大", value: "has_bite_sized_side_meal" },
    ],
  },
  eating_utensil_option: {
    name: "eating_utensil_option",
    label: "道具",
    noteName: "eating_utensil",
    options: [
      { label: "箸", value: "has_chopsticks" },
      { label: "スプーン", value: "has_spoon" },
      { label: "フォーク", value: "has_fork" },
    ],
  },
  luxury_goods_option: {
    name: "luxury_goods_option",
    label: "嗜好品",
    noteName: "luxury_goods",
    options: [
      { label: "あり(タバコ)", value: "has_tobacco" },
      { label: "あり(酒)", value: "has_alcohol" },
      { label: "なし", value: "has_no_luxury_goods" },
    ],
  },
  neck_option: {
    name: "neck_option",
    label: "首",
    noteName: "neck",
    options: [
      { label: "麻痺", value: "has_paralysis_neck" },
      { label: "拘縮", value: "has_contracture_neck" },
      { label: "その他", value: "has_other_neck" },
      { label: "問題なし", value: "has_no_problem_neck" },
    ],
  },
  upper_limb_option: {
    name: "upper_limb_option",
    label: "上肢",
    noteName: "upper_limb",
    options: [
      { label: "麻痺", value: "has_paralysis_upper_limb" },
      { label: "拘縮", value: "has_contracture_upper_limb" },
      { label: "その他", value: "has_other_upper_limb" },
      { label: "問題なし", value: "has_no_problem_upper_limb" },
    ],
  },
  belt_option: {
    name: "belt_option",
    label: "腰",
    noteName: "belt",
    options: [
      { label: "麻痺", value: "has_paralysis_belt" },
      { label: "拘縮", value: "has_contracture_belt" },
      { label: "その他", value: "has_other_belt" },
      { label: "問題なし", value: "has_no_problem_belt" },
    ],
  },
  hip_joint_option: {
    name: "hip_joint_option",
    label: "股関節",
    noteName: "hip_joint",
    options: [
      { label: "麻痺", value: "has_paralysis_hip_joint" },
      { label: "拘縮", value: "has_contracture_hip_joint" },
      { label: "その他", value: "has_other_hip_joint" },
      { label: "問題なし", value: "has_no_problem_hip_joint" },
    ],
  },
  bottom_option: {
    name: "bottom_option",
    label: "下肢",
    noteName: "bottom",
    options: [
      { label: "麻痺", value: "has_paralysis_bottom" },
      { label: "拘縮", value: "has_contracture_bottom" },
      { label: "その他", value: "has_other_bottom" },
      { label: "問題なし", value: "has_no_problem_bottom" },
    ],
  },
  form_movement_option: {
    name: "form_movement_option",
    label: "形態",
    noteName: "form_movement",
    options: [
      { label: "自立", value: "has_independence" },
      { label: "杖", value: "has_cane" },
      { label: "車椅子", value: "has_wheelchair" },
      { label: "歩行器", value: "has_walker" },
      { label: "付添", value: "has_support_person" },
    ],
  },
  excretory_form_option: {
    name: "excretory_form_option",
    label: "形態",
    noteName: "excretory_form",
    options: [
      { label: "自立", value: "has_independence_excretory" },
      { label: "リハパン", value: "has_rehab_pant_excretory" },
      { label: "紙オムツ", value: "has_paper_diaper_excretory" },
      { label: "パット", value: "has_pad_excretory" },
    ],
  },
  dentures_option: {
    name: "dentures_option",
    label: "義歯",
    noteName: "dentures",
    options: [
      { label: "あり(全)", value: "has_full_dentures" },
      { label: "あり(上)", value: "has_upper_dentures" },
      { label: "あり(下)", value: "has_lower_dentures" },
      { label: "あり(一部)", value: "has_partial_dentures" },
      { label: "なし", value: "has_no_dentures" },
    ],
  },
};

export const SelectOptions = ({ data, readOnly = false}: { data: IOption, readOnly: boolean }) => (
  <>
    <Form.Item
      label={data.label}
      className="mb-0"
      name={data.name}
      rules={[{ required: true, message: MESSAGES.requireField }]}
    >
      <Checkbox.Group disabled={readOnly} >
        {data.options.map((option) => (
          <Checkbox key={option.value} value={option.value}>
            {option.label}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Form.Item>
    <Form.Item label=" " name={data.noteName}>
      <Input placeholder="特記事項" readOnly={readOnly} />
    </Form.Item>
  </>
);

export const convertArrayToObject = (data: any, reverse = false) => {
  let newData: any = {};
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key] !== null) {
      if (!reverse)
        newData[key] = data[key].reduce(
          (cur: any, next: string) => ({ ...cur, [next]: true }),
          OPTIONS[key as keyof IOptions]
            ? OPTIONS[key as keyof IOptions].options.reduce(
                (c: any, n: any) => ({ ...c, [n.value]: false }),
                {}
              )
            : {}
        );
      else
        newData[key] = Object.keys(data[key]).filter(
          (subKey) => data[key][subKey]
        );
    } else newData[key] = data[key];
  });
  return newData;
};
