import { useState } from "react";
import { ContactList } from "./ContactList";
import { ContactChat } from "./ContactChat";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Communication = ({ onChangeTitle, setContactTitle }: any) => {
  const [part, setPart] = useState("ContactList");
  const [contactId, setContactId] = useState(null);
  const [contactName, setContactName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const choosePart = () => {
    switch (part) {
      case "ContactList":
        onChangeTitle("個別連絡");
        setContactTitle("個別連絡");
        return (
          <ContactList
            setPart={setPart}
            setContactId={setContactId}
            setContactName={setContactName}
            navigate={navigate}
            dispatch={dispatch}
          />
        );
      case "ContactChat":
        onChangeTitle(`個別連絡　${contactName}`);
        setContactTitle(`個別連絡　${contactName}`);
        return <ContactChat setPart={setPart} contactId={contactId} />;
      default:
        break;
    }
  };

  return (
    <div className="bg-white px-[12px] py-[18px] relative mt-4">
      {choosePart()}
    </div>
  );
};

export { Communication };
