import { Button, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import LeftArrow from "assets/leftarrow.png";
import RightArrow from "assets/rightarrow.png";
import dayjs, { Dayjs, ManipulateType } from "dayjs";

interface IProps {
  value: Dayjs;
  onChange?: (value: Dayjs) => void;
  type: ManipulateType;
  format: string;
}

export default function SelectTime({
  value,
  onChange,
  type,
  format = "YYYY/MM/DD",
}: IProps) {
  return (
    <div className="flex items-center">
      <StyleBtn
        className="mr-3 font-bold"
        transform="scale(0.63)"
        p={0}
        variant="outline"
        onClick={() => onChange?.(dayjs(value).add(-1, type))}
      >
        <img src={LeftArrow} alt="" style={{ height: "15px", width: "15px" }} />
      </StyleBtn>
      <Text>{dayjs(value).format(format)}</Text>
      <StyleBtn
        className="ml-3 mr-6 font-bold"
        p={0}
        variant="outline"
        transform="scale(0.63)"
        onClick={() => onChange?.(dayjs(value).add(1, type))}
      >
        <img
          src={RightArrow}
          alt=""
          style={{ height: "15px", width: "15px" }}
        />
      </StyleBtn>
    </div>
  );
}
const StyleBtn = styled(Button)({
  border: "1px solid black",
  fontSize: "20px",
});
