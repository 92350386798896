import { DATE_FORMAT, JP_DATE_FORMAT, formatJapanDate } from "constant/date";
import Styles from "./styled";
import dayjs from "dayjs";

interface IProps {
  type: "bill" | "invoice"; // type = bill tương ứng với page 66, invoice tương ứng page 71
  data: any;
}

export default function ReceiptPDF({ type, data }: IProps) {
  if (data)
    return (
      <Styles className="w-[848px] h-[1121px] p-[10px_30px]">
        <div className="text-[22px] font-bold text-center">
          {type === "bill" ? "請求書" : "領収書"}
        </div>
        <div className="text-right pr-10">{formatJapanDate(Date())}</div>
        <div className="flex relative mb-3">
          <div className="w-[55%] text-center">
            <div className="font-bold underline text-[22px] ">
              {data.patient_name}　様
            </div>
            <div className="border-2 border-solid border-black flex text-center items-center mt-5">
              <div className="w-[40%]">合計金額</div>
              <div className="w-[60%] text-[24px] font-bold">
                ¥{(+data.total).toLocaleString()}
              </div>
            </div>
            <div className="pl-40 mt-2 text-left font-bold">利用料</div>
          </div>
          <div className="text-center w-[45%]">
            <p className="text-[18px] font-bold">あっとほーむ　まるくる</p>
            <p className="text-[11px]">株式会社大嶽設備</p>
            <p className="text-[11px]">〒463–0036</p>
            <p className="text-[11px]">名古屋市守山区守山2–5–13</p>
            <p className="mt-2 text-[12px]">
              TEL 052–799–8443
              <br />
              FAX 052–799–8453
            </p>
          </div>
          <img
            src="/images/stamp1.png"
            alt="stamp"
            width={100}
            className="absolute right-[10px]"
            style={{ transform: "rotate(10deg)" }}
          />
        </div>
        <table className="w-full text-center">
          <thead>
            <tr>
              <th className="w-[80px]"></th>
              <th>費　用　明　細</th>
              <th>ご利用月</th>
              <th>金　額</th>
              <th>備　考</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>家　　　　 賃</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.rent_cost).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>光 　熱　 費</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.utility_cost).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>食　　　　 費</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.food_cost).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>生活管理費</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.living_cost).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr className="h-[5px]" />
            <tr>
              <td>A</td>
              <td>小　　計</td>
              <td></td>
              <td>{(+data.billing_sumerize).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td>B</td>
              <td>消費税</td>
              <td>非課税</td>
              <td>0</td>
              <td></td>
            </tr>
            <tr className="h-[5px]" />
            <tr>
              <td></td>
              <td>介護保険サービス費</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.nursing_care_service_fee).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>障害保険サービス費</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.disability_service_fee).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>立替金</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.patient_receipt_total).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>備品利用</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.equipment_total).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>サービス利用</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.service_total).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>ぽとろ</td>
              <td>{dayjs(data.year_month).format("M")}月分</td>
              <td>{(+data.potoro_total).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr className="h-[5px]" />
            <tr>
              <td>C</td>
              <td>小　　計</td>
              <td></td>
              <td>{(+data.sub_total).toLocaleString()}</td>
              <td></td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan={4}></td>
              <td>{(+data.total).toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <div className="text-[20px]" hidden={type !== "bill"}>
          瀬戸信用金庫　四軒家支店　普通　0838466　カ）オオタケセツビ
        </div>
        <div className="text-[20px] text-center" hidden={type !== "bill"}>
          毎月27日までにお振込みをお願いします。{" "}
        </div>
      </Styles>
    );
  return <></>;
}
