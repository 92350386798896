import BaseApi from "api/baseApi";
import { objectToFormData } from "utils/data";
import { ADMIN_SHIFT_MANAGEMENTS } from "../apiConstant";
import dayjs from "dayjs";

class evalueApi extends BaseApi {
  getList<T>(params: { date: string }) {
    return this.get<T>(ADMIN_SHIFT_MANAGEMENTS, params);
  }

  getPatients<T>(params: { patient_id: number | string; date: string }) {
    return this.get<T>(ADMIN_SHIFT_MANAGEMENTS + `/shifts-list`, params);
  }

  assignStaff<T>(body: { schedule_date_id: number; nurse_id: number }) {
    this.setSuccessMessage(true);

    return this.post<T>(
      ADMIN_SHIFT_MANAGEMENTS + `/assign-staff`,
      objectToFormData(body),
      {
        header: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  getListStaff<T>(params: { date: string }) {
    return this.get<T>(ADMIN_SHIFT_MANAGEMENTS + `/staffs-list`, params);
  }
}

export default evalueApi;
