import { STAFF_DASHBOARD } from "api/apiConstant";
import BaseApi from "api/baseApi";
import { MONTH_FORMAT } from "constant/date";
import dayjs from "dayjs";

class Dashboard extends BaseApi {
  getDashboard<T>(params: { date: string }) {
    return this.get<T>(STAFF_DASHBOARD, params);
  }

  getDashboardShiftsList<T>(params: { patient_id: string; date: string }) {
    return this.get<T>(STAFF_DASHBOARD + `/shifts-list`, params);
  }
}

export default Dashboard;
