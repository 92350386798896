import CalendarMonthShiftBerore from "component/calendar/CalendarMonthShiftBerore";
import React from "react";
import Header from "component/header/Header";

const NextMonthShiftBefore = () => {
  const displayMonth = () => {
    if (new Date().getMonth() + 1 === 12) {
      return 1
    } else {
      return new Date().getMonth() + 2
    }
  }
  return (
    <div className="page-container">
      <Header
        title={`${displayMonth()}月シフト申請`}
      />
      <CalendarMonthShiftBerore />
    </div>
  );
};

export default NextMonthShiftBefore;
