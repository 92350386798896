import {
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Typography,
  TimePicker,
  Checkbox,
} from "antd";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./style.scss";
import { ButtonOutline, ButtonSolid } from "component/button";
import ModalCustom from "component/admin/modal";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import { Button } from "@chakra-ui/react";
import { CloseOutlined } from "@ant-design/icons";
import SelectWithData from "component/SelectWithData";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import { toast } from "react-toastify";
import MESSAGES from "constant/messages";

dayjs.extend(customParseFormat);

const { Item } = Form;
const { Text } = Typography;
const { RangePicker } = TimePicker;

const initialFormValues = {
  // date: dayjs(),
  // shift: "a",
  // nurse: "a",
};

interface IShiftRegister {
  openRegisterShift: boolean;
  setOpenRegisterShift: (value: boolean) => void;
  title: string;
  setCreateShift: any;
  createShift: any;
  setIsStaffScheduleEdited: Dispatch<SetStateAction<boolean>>;
  setIsShiftEdited: Dispatch<SetStateAction<boolean>>;
}

const ShiftRegister = ({
  title,
  setOpenRegisterShift,
  openRegisterShift,
  setCreateShift,
  createShift,
  setIsStaffScheduleEdited,
  setIsShiftEdited,
}: IShiftRegister) => {
  const [form] = Form.useForm();
  const service = useService();
  const [checkbox, setCheckbox] = useState(false);
  const getStaffOptions = async () =>
    (
      await axiosClient.get(service.NURSING_STAFF, {
        params: { get_all: true, status: ["active"] },
      })
    ).data.data.map((i: any) => ({
      label: i.family_name,
      value: i.id,
    }));
  const getTimeOptions = async () =>
    (
      await axiosClient.get(service.SHIFT_REGISTRATIONS, {
        params: { get_all: true },
      })
    ).data.data.map((i: any) => ({
      label: `${i.name} - (${i.start_time} - ${i.end_time})`,
      value: i.id,
    }));
  const handleSubmit = async (formData: any) => {
    formData = {
      ...formData,
      dates: formData.dates.filter((i: Dayjs) => i),
      start_time: formData.time && dayjs(formData.time?.[0]).format("HH:mm"),
      end_time: formData.time && dayjs(formData.time?.[1]).format("HH:mm"),
      time: undefined,
    };
    try {
      const response = await axiosClient.post(
        service.NURSING_SHIFT_MANAGES,
        formData
      );
      toast.success(response.data.success);
      setCreateShift(createShift + 1);
      setIsStaffScheduleEdited(true);
      setIsShiftEdited(true);
      setOpenRegisterShift(false);
      form.resetFields();
    } catch (error) {
      toast.error((error as string).toString());
      setCreateShift(createShift + 1);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <ModalCustom
      className="shift-modal"
      title={title}
      closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      footer={
        <Row justify="end">
          <Space size={8}>
            <ButtonOutline
              onClick={() => {
                form.resetFields();
                setOpenRegisterShift(false);
                setCreateShift(false);
              }}
              className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[16px]"
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid
              onClick={form.submit}
              className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[54px]"
            >
              保存
            </ButtonSolid>
          </Space>
        </Row>
      }
      open={openRegisterShift}
      onCancel={() => setOpenRegisterShift(false)}
    >
      <Form
        layout="vertical"
        className="mt-[26px]"
        form={form}
        initialValues={initialFormValues}
        onFinish={handleSubmit}
      >
        <Item
          name="dates"
          label="日にち"
          required
          rules={[
            {
              validator: (rule, value) => {
                if (value.filter((i: Dayjs) => i).length)
                  return Promise.resolve();
                return Promise.reject();
              },
              message: MESSAGES.requireField,
            },
          ]}
        >
          <Form.List name="dates" initialValue={[null]}>
            {(fields, { add, remove }) =>
              fields.map((item, index) => (
                <div key={index} className="flex items-center">
                  <Item noStyle name={[index]}>
                    <DatePicker
                      onChange={() => form.validateFields(["dates"])}
                      format={JP_DATEPICKER_FORMAT}
                      placeholder="選択してください"
                      className="w-full"
                      defaultPickerValue={dayjs().add(1, "M").date(1)}
                      disabledDate={(date) =>
                        !date.add(-1, "M").isSame(dayjs(), "M")
                      }
                    />
                  </Item>
                  <Button
                    onClick={() => add(undefined, index + 1)}
                    variant="outline"
                    transform="scale(0.75)"
                    borderRadius="50%"
                    width="40px"
                    height="40px"
                    p="0px"
                    ml="2"
                    border="1px solid #000"
                  >
                    <img
                      src={Plus}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                  <Button
                    onClick={() => fields.length > 1 && remove(index)}
                    variant="outline"
                    transform="scale(0.75)"
                    borderRadius="50%"
                    width="40px"
                    height="40px"
                    p="0px"
                    border="1px solid #000"
                  >
                    <img
                      src={Minus}
                      alt=""
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Button>
                </div>
              ))
            }
          </Form.List>
        </Item>
        <Row className="mb-0" gutter={[0, 16]}>
          <Col span={24}>
            <Item
              name={checkbox ? "time" : "shift_registration_id"}
              rules={[{ required: true, message: MESSAGES.requireField }]}
              label={
                <Text className="shift">
                  シフト
                  <Checkbox
                    onChange={(e) => setCheckbox(e.target.checked)}
                    checked={checkbox}
                  >
                    自由入力
                  </Checkbox>
                </Text>
              }
            >
              {checkbox ? (
                <RangePicker
                  defaultValue={[undefined as any, undefined as any]}
                  placeholder={["00:00", "00:00"]}
                  format={"HH:mm"}
                />
              ) : (
                <SelectWithData
                  getOptions={getTimeOptions}
                  placeholder="選択してください"
                />
              )}
            </Item>
          </Col>
        </Row>
        <Item
          name="nurse_id"
          label="介護スタッフ"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <SelectWithData
            getOptions={getStaffOptions}
            placeholder="選択してください"
          />
        </Item>
      </Form>
    </ModalCustom>
  );
};

export default ShiftRegister;
