
import { SERVICE_CONTENT, SERVICE_REGISTRATION, TREATMENT } from '../apiConstant';
import BaseApi from 'api/baseApi';

class CareRegistrationApi extends BaseApi {
    createService(body: {
        service_name: string,
        unit_price: number,
        patient_type: string
    }) {
        return this.post(SERVICE_REGISTRATION, body);
    }
    getServiceList(body: {
        per: number,
        page: number,
        sortKey?: string,
        order?: string
    }, patientType: string) {
        return this.get(`${SERVICE_REGISTRATION}?patient_type=${patientType}`, body);
    }
    getServiceDetail(id: any) {
        return this.get(`${SERVICE_REGISTRATION}/${id}`);
    }
    updateService(body: {
        id: number,
        service_name: string,
        unit_price: number,
        patient_type: string
    }) {
        return this.put(`${SERVICE_REGISTRATION}/${body.id}`, body);
    }
    createServiceContent(body: {
        detail: string
    }) {
        return this.post(SERVICE_CONTENT, body);
    }
    updateServiceContent(body: {
        id: number,
        detail: string
    }) {
        return this.put(`${SERVICE_CONTENT}/${body.id}`, body);
    }
    getServiceContentList(body: {
        per: number,
        page: number,
        sortKey?: string, 
        order?: string
    }) {
        return this.get(SERVICE_CONTENT, body);
    }
    getServiceContentDetail(id: any) {
        return this.get(`${SERVICE_CONTENT}/${id}`);
    }

    createTreatment(body: {
        name: string,
        rate: number,
        patient_type: string
    }) {
        return this.post(TREATMENT, body);
    }
    updateTreatment(body: {
        id: number,
        name: string,
        rate: number,
        patient_type: string
    }) {
        return this.put(`${TREATMENT}/${body.id}`, body);
    }
    getTreatmentList(body: {
        per: number,
        page: number,
        sortKey?: string, 
        order?: string
    }, patientType: string) {
        return this.get(`${TREATMENT}?patient_type=${patientType}`, body);
    }
    getTreatmentDetail(id: any) {
        return this.get(`${TREATMENT}/${id}`);
    }
}
export default CareRegistrationApi;
