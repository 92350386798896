/* eslint-disable @typescript-eslint/no-explicit-any */
import { ROLE } from 'constant';
import { LOGIN_ADMIN, LOGIN_CLIENT, LOGIN_HS_ADMIN, LOGIN_STAFF, LOGOUT, ME, LOGIN_HS_STAFF } from './apiConstant';
import BaseApi from './baseApi';
import { toast } from 'react-toastify';

class AuthApi extends BaseApi {
    login(data: {
        username: string,
        password: string
    }, typeLogin: string) {
        let body = {}
        let url = ''
        switch (typeLogin) {
            case ROLE.ADMIN:
                body = {
                    user_code: data.username,
                    password: data.password
                }
                url = LOGIN_ADMIN
                break;
            case ROLE.STAFF:
                body = {
                    nurse_code: data.username,
                    password: data.password
                }
                url = LOGIN_STAFF
                break;
            case ROLE.CLIENT:
                body = {
                    patient_code: data.username,
                    password: data.password
                }
                url = LOGIN_CLIENT
                break;
            case ROLE.HS_ADMIN:
                body = {
                    user_code: data.username,
                    password: data.password
                }
                url = LOGIN_HS_ADMIN
                break;
            case ROLE.HS_STAFF:
                body = {
                    user_code: data.username,
                    password: data.password
                }
                url = LOGIN_HS_STAFF
                break;
            default:
                toast.error('Url login is invalid')
                break;
        }
        return this.post(url, body);
    }
    logout() {
        return this.delete(LOGOUT);
    }
    getMe() {
        return this.get(ME);
    }
}
export default AuthApi;
