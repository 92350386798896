import { Text } from "@chakra-ui/react";
import { Breadcrumb } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { twMerge } from "tailwind-merge";
import { ReactNode } from "react";

type PropsType = {
  title?: string | ReactNode;
  className?: string;
  breadCrumbItems?: ItemType[];
  breadCrumbClassName?: string;
};

const PageHeaderTitle = (props: PropsType) => {
  const { breadCrumbItems, title, className, breadCrumbClassName } = props;

  return (
    <div className={twMerge("text-xl z-[1]", className)}>
      {breadCrumbItems && (
        <Breadcrumb
          className={twMerge("mb-[12px]", breadCrumbClassName)}
          items={breadCrumbItems}
        />
      )}

      {title && <Text>{title}</Text>}
    </div>
  );
};

export default PageHeaderTitle;
