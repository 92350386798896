import { Button, DatePicker, Form, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import apiFactory from "api";
import { ButtonSolid } from "component/button";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import MESSAGES from "constant/messages";
import REGEX from "constant/regex";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { notificationCategoryOptions } from "utils/notification";

interface IProps {
  formType: "important" | "conveyed";
}

interface IFormValues {
  patient_id?: number;
  deployment_date?: Dayjs;
  notification_category_id?: number;
  content: string;
}

const NotificationForm: React.FC<IProps> = ({ formType }) => {
  const [form] = Form.useForm<IFormValues>();
  const [patientList, setPatientList] = useState<any[]>([]);
  const navigate = useNavigate();

  const fetchPatientList = async () => {
    const data = await apiFactory.patientAdminApi.getList({ per: 999999 });
    if (data) {
      setPatientList(
        data.patients.map((e: any) => ({
          label: e.first_name + " " + e.last_name,
          value: e.id,
        }))
      );
    }
  };

  useEffect(() => {
    if (formType === "important") {
      fetchPatientList();
    }
  }, [formType]);

  const handleSubmitData = async (data: IFormValues) => {
    if (formType === "important") {
      const stringifyDeploymentDate = dayjs(data.deployment_date).format(
        "YYYY-MM-DD"
      );
      try {
        const result = await apiFactory.boardAdminApi.createNotification({
          patient_id: data.patient_id!,
          deployment_date: stringifyDeploymentDate,
          content: data.content,
        });
        if (result) {
          toast.success(result.success);
          navigate("/admin/notice");
        }
      } catch (error) {
        //
      }
    }

    if (formType === "conveyed") {
      try {
        const result = await apiFactory.boardAdminApi.createNotificationGroup({
          notification_category_id: data.notification_category_id!,
          content: data.content,
        });
        if (result) {
          toast.success(result.success);
          navigate("/admin/notice");
        }
      } catch (error) {
        //
      }
    }
  };

  if (formType === "conveyed") {
    return (
      <Form form={form} onFinish={handleSubmitData}>
        <Form.Item
          label="カテゴリー"
          name="notification_category_id"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <Select
            options={notificationCategoryOptions}
            allowClear
            placeholder="選択してください"
          />
        </Form.Item>
        <Form.Item
          label="内容"
          name="content"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <TextArea />
        </Form.Item>

        <div className="flex items-center justify-end">
          <Button
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] w-[160px]"
            onClick={() => navigate("/admin/notice")}
          >
            キャンセル
          </Button>
          <ButtonSolid
            type="submit"
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px] w-[160px] ml-3"
          >
            保存
          </ButtonSolid>
        </div>
      </Form>
    );
  }

  return (
    <Form form={form} onFinish={handleSubmitData}>
      <Form.Item
        label="お客様名"
        name="patient_id"
        rules={[{ required: true, message: MESSAGES.requireField }]}
      >
        <Select
          options={patientList}
          allowClear
          placeholder="選択してください"
        />
      </Form.Item>
      <Form.Item
        label="展開日"
        name="deployment_date"
        rules={[
          { required: true, message: MESSAGES.requireField },
          {
            pattern: REGEX.atLeastOneNonSpaceCharacter,
            message: MESSAGES.requireField,
          },
        ]}
      >
        <DatePicker
          format={JP_DATEPICKER_FORMAT}
          className="w-full"
          placeholder="選択してください"
        />
      </Form.Item>
      <Form.Item
        label="内容"
        name="content"
        rules={[{ required: true, message: MESSAGES.requireField }]}
      >
        <TextArea />
      </Form.Item>

      <div className="flex items-center justify-end">
        <Button
          className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] w-[160px]"
          onClick={() => navigate("/admin/notice")}
        >
          キャンセル
        </Button>
        <ButtonSolid
          type="submit"
          className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px] w-[160px] ml-3"
        >
          保存
        </ButtonSolid>
      </div>
    </Form>
  );
};

export default NotificationForm;
