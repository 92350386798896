import styled from "@emotion/styled";

export const Styled = styled.div`
  border-bottom: 1px solid #b7b7b7;

  .ant-picker {
    border-color: transparent;

    input {
      font-size: 20px;
      width: 110px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .ant-picker-focused {
    box-shadow: none;
  }

  .ant-picker-suffix {
    display: none;
  }
`;
