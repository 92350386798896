// page 63 https://xd.adobe.com/view/ef189247-c8fd-4c31-b643-d4151912ea88-ba4b/screen/a183d692-ba46-49d3-a127-507d4b24666b/specs/
import { Form, Input } from "antd";
import axiosClient from "api/axiosClient";
import NumericInput from "component/NumberInput";
import { ButtonOutline, ButtonSolid } from "component/button";
import { MONTH_FORMAT } from "constant/date";
import { IPatient } from "constant/interfaces";
import MESSAGES from "constant/messages";
import ROUTES from "constant/routes";
import dayjs from "dayjs";
import useService from "hook/useService";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function RentBilling() {
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const service = useService();
  const navigate = useNavigate();
  const month = dayjs(searchParams.get("month") || Date.now());
  const billing = Form.useWatch([], form);
  const totalAmount = billing
    ? +billing.rent_cost +
      +billing.utility_cost +
      +billing.food_cost +
      +billing.living_cost
    : 0;
  const patient_id = searchParams.get("patient_id");
  const [patientData, setPatientData] = React.useState<IPatient>();
  const fetchInitialData = async () => {
    const res = await axiosClient({
      url: service.RENT_BILLINGS,
      params: {
        patient_id,
        year_month: month.format("YYYY/MM"),
      },
    });
    setPatientData(res.data.patient);
    Object.keys(res.data)
      .filter((key) => res.data[key])
      .forEach((key) => {
        form.setFieldValue(key, res.data[key]);
      });
  };
  const handleSubmit = async (formData: any) => {
    try {
      const res = await axiosClient({
        url: service.RENT_BILLINGS + (formData.id ? `/${formData.id}` : ""),
        method: formData.id ? "put" : "post",
        data: {
          patient_id,
          year_month: month.format("YYYY/MM"),
          ...formData,
        },
      });
      toast.success(res.data.success);
      navigate(`/${ROUTES.ADMIN}/${ROUTES.PAYMENT}`);
    } catch (error) {
      toast.error((error as string).toString());
    }
  };

  React.useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div className="page-title">
        家賃等請求&nbsp;
        {month.format(MONTH_FORMAT)} {patientData?.family_name || ""} 様
      </div>
      <div className="page-container">
        <Form.Item name="id" hidden>
          <div />
        </Form.Item>
        <div className="max-w-[700px]">
          <Form.Item name="rent_cost" label="家賃" initialValue="37000">
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item name="utility_cost" label="光熱費" initialValue="18000">
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item name="food_cost" label="食費" initialValue="40000">
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item name="living_cost" label="生活管理費" initialValue="25000">
            <NumericFormat
              allowNegative={false}
              decimalSeparator=""
              customInput={Input}
              addonAfter="円"
            />
          </Form.Item>
          <Form.Item label="小計">
            <NumericFormat
              customInput={Input}
              addonAfter="円"
              readOnly
              value={totalAmount}
            />
          </Form.Item>
        </div>
        <div className="flex justify-end gap-3 mt-10">
          <ButtonOutline onClick={() => navigate(-1)}>キャンセル</ButtonOutline>
          <ButtonSolid w={137} onClick={form.submit}>
            保存
          </ButtonSolid>
        </div>
      </div>
    </Form>
  );
}
