import BaseApi from "api/baseApi";
import {
  STAFF_NURSING_CARE_HISTORIES,
  STAFF_NURSING_CARE_HISTORIES_CALENDAR,
} from "../apiConstant";

class NursingCareHistoriesCalendar extends BaseApi {
  getNursingCareHistoriesCalendar<T>(params: { date: string }) {
    return this.get<T>(STAFF_NURSING_CARE_HISTORIES_CALENDAR, params);
  }

  getNursingCareHistories<T>(params: any) {
    return this.get<T>(STAFF_NURSING_CARE_HISTORIES, params);
  }

  getDetailNursingCareHistories<T>(id: string | number) {
    return this.get<T>(STAFF_NURSING_CARE_HISTORIES + `/${id}`);
  }

  updateDetailNursingCareHistories<T>(id: string | number, body: any) {
    return this.put<T>(STAFF_NURSING_CARE_HISTORIES + `/${id}`, body);
  }

  createNursingCareHistories<T>(body: any) {
    return this.post<T>(STAFF_NURSING_CARE_HISTORIES, body);
  }
}

export default NursingCareHistoriesCalendar;
