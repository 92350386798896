import { useEffect, useState } from 'react'
import { Box, Table, TableContainer, Text, Tr } from '@chakra-ui/react'
import { Tooltip } from 'antd'
import { FlexRow, StyleTd } from 'component/StyledComponent'
import { HOURS } from 'utils'
import styled from '@emotion/styled'
import './scroll.scss'
import clientScheduleApi from 'api/client/clientScheduleApi'
import Cookies from "js-cookie";
import { DATE_FORMAT, DATE_FORMAT_2 } from 'constant/date'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const dataShift = {
  timeStart: 0,
  timeEnd: 10,
  shiftStart: '20:00',
  shiftEnd: '10:00',
  action: [
    {
      name: '口腔ケア・排泄介助',
      staff: '介護スタ名',
      timeStart: ["7:30", "11:00", "13:00"]
    },
    {
      name: '入浴',
      staff: '介護スタ名',
      timeStart: ["10:00"]
    },
    {
      name: '排泄介助・体位交換',
      staff: '介護スタ名',
      timeStart: ["4:00"]
    },
  ]
}


interface ITableProps {
  classNameTable?: string;
  classNameTd?: string;
  classNameContainer?: string;
  classNameBoxTitle?: string;
  classNameShift?: string;
  classNameBoxEmpty?: string;
  date?: any;
}

const TableTimeScheduleClient = ({
  classNameTable = "",
  classNameTd = "",
  classNameContainer = "",
  classNameBoxTitle = "",
  classNameShift = "",
  classNameBoxEmpty = "",
  date
}: ITableProps) => {
  const [dataDate, setDataDate] = useState({})

  const getDaySchedule = async () => {
    try {
      const param = {
        specific_date: moment(date).format(DATE_FORMAT),
      }
      const idToken = Cookies.get("access_token")
      const res = await clientScheduleApi.getShiftSchedule(idToken, param)
      if (res) {
        // console.log('res', res.data.data)
        setDataDate(res.data.data)
      }
    } catch (error) {
       console.log(error);
    }
  }

  useEffect(() => {
    getDaySchedule()
  }, [date])


  useEffect(() => {
    document.getElementsByClassName('wrapper2')[0].addEventListener('scroll', () => {
      document.getElementsByClassName('wrapper1')[0].scrollLeft =
        document.getElementsByClassName('wrapper2')[0].scrollLeft;
    })

    document.getElementsByClassName('wrapper1')[0].addEventListener('scroll', () => {
      document.getElementsByClassName('wrapper2')[0].scrollLeft =
        document.getElementsByClassName('wrapper1')[0].scrollLeft;
    })
  }, []);

  const convertHours = (range: any, type: any) => {
    let hour = range.split("~")
    if (type === "start") {
      let dateTmp = new Date(`${moment(date).format(DATE_FORMAT_2)} ${hour[0]}`)
      return dateTmp.getHours()
    } else {
      let dateTmp = new Date(`${moment(date).format(DATE_FORMAT_2)} ${hour[1]}`)
      return dateTmp.getHours()
    }
  }
  const convertStringTimeToNum = (stringTime: string): number[] => {
    const stringTimeArr = stringTime.split(":");
    const numTimeArr = stringTimeArr.map((item: string) => Number(item));
    return [...numTimeArr];
  }
  const getTimePeriod = (startTime: string, endTime: string): number => {
    const startHour = convertStringTimeToNum(startTime)[0];
    const endHour = convertStringTimeToNum(endTime)[0];
    const endMinute = convertStringTimeToNum(endTime)[1];
    const startMinute = convertStringTimeToNum(startTime)[1];
    
    let period;
  
    if (endMinute > 0) {
      period = (endHour - startHour) + (endMinute / 60) - (startMinute/60);
      // (18-17) + 0.5
    } else {
      period = endHour - startHour;
    }
  
    return period;
  }
  const getScheduleBoxWidth = (startTime: string, endTime: string, unit: string = '%'): string => {
    if (!startTime || !endTime) {
      return "100%";
    }
  
    const period = getTimePeriod(startTime, endTime);
    const width = period * 100;
  
    let widthValue: string;
  
    if (unit) {
      widthValue = "" + width + unit;
    } else {
      widthValue = String(width);
    }
    return widthValue;
  }

  return (
    <div>
      <FlexRow>
        <div>
          <Box className={classNameBoxEmpty} height="67px" width="170px" />
          {(Object.keys(dataDate).length > 0) &&
            Object.entries(dataDate).map(([key, value]) => (
              <Box
                key={key}
                fontWeight="normal"
                height="88px"
                fontSize="11px"
                p="20px 0px"
                width="180px"
                border="1px solid #ececec"
                borderLeft="none"
                borderRight="none"
                // borderBottom={index !== dataShift.action.length - 1 ? "none" : "1px solid #ececec"}
                textAlign='left'
                paddingLeft='15px'
                display="flex"
                alignItems="center"
              >
                {key}
              </Box>
            ))}
        </div>
        <div className="overflow-auto">
          <StyleTableContainer
            className={`${classNameContainer} wrapper2`}
            zIndex="9999"
            overflowX="scroll"
            transform="rotateX(180deg)"
          >
            <Table
              className={classNameTable}
              width={`${70 * 23}px`}
              transform="rotateX(180deg)"
            >
              <Tr height="58px">
                {HOURS.map((item: any, index: any) => (
                  <StyleTd
                    className={classNameTd}
                    position="relative"
                    zIndex="9999"
                    padding="0px"
                    key={index}
                    style={{
                      border: "0px",
                      height: "50px",
                      width: "70px !important",
                    }}
                    fontSize="12px"
                  >
                    <Text
                      className="text-left"
                      zIndex="9999"
                      position="absolute"
                      left="-7px"
                    >
                      {item}
                    </Text>
                  </StyleTd>
                ))}
              </Tr>
              {(Object.keys(dataDate).length > 0) &&
                Object.entries(dataDate).map(([key, value]: any) => (
                  <Tr key={key} position="relative">
                    {HOURS.map((hour: any, indexHour: any) => (
                      <StyleTd
                        padding="0px"
                        position="relative"
                        key={indexHour}
                        style={{
                          border: "1px solid #ececec",
                          height: "88px",
                          width: "70px",
                        }}
                      >
                        {
                          value.map((ts: any, id: any) => {
                            const times = ts.time_range.split("~");
                            const boxWidth = getScheduleBoxWidth(times[0], times[1]);
                            const borderWidth = convertStringTimeToNum(times[1])[0] - convertStringTimeToNum(times[0])[0];
                            const leftDistance = (convertStringTimeToNum(times[0])[1] / 60) * 100;
                            return <Tooltip
                              arrow={false}
                              color='#ffffff'
                              overlayInnerStyle={{ color: '#000000', borderRadius: '9px', border: '1px solid #000000', padding: '12px' }}
                              title={
                                <div>
                                  <p>{ts.patient_name}</p>
                                  <p>{ts.time_range}</p>
                                  <p>口腔ケア・排泄介助</p>
                                  <p>担当：{ts.nurse_name}</p>

                                </div>
                              }
                            >
                              {convertStringTimeToNum(times[0])[0] === indexHour &&
                                <Box
                                  key={id}
                                  // className={`${classNameShift} ${ts.includes('30') ? 'ml-auto' : ''}`}
                                  className={`${classNameShift} 'ml-auto'`}
                                  // width="35px"
                                  height="100%"
                                  bg="#1AD633"
                                  border="2px solid var(--primary-color)"
                                  overflowY="hidden"
                                  textOverflow="ellipsis"
                                  display='flex'
                                  position="absolute"
                                  top={0}
                                  left={`${leftDistance}%`}
                                  zIndex={100}
                                  style={{ width: `calc(${boxWidth} + ${borderWidth}px - 1px` }}
                                >
                                  <Text
                                    paddingX="8px"
                                    lineHeight="13px"
                                    textAlign="center"
                                    fontSize="12px"
                                    whiteSpace="pre-wrap"
                                    marginTop="5px"
                                  >
                                    {" "}
                                    {ts.patient_name.replace(/(.{1})/g, "$1\n")}
                                  </Text>{" "}
                                </Box>
                              }

                              {/*{((convertHours(ts.time_range, "start") === indexHour)
                                && indexHour < (convertHours(ts.time_range, "end") - 1)) &&
                                <Box
                                  key={id}
                                  // className={`${classNameShift} ${ts.includes('30') ? 'ml-auto' : ''}`}
                                  className={`${classNameShift} 'ml-auto'`}
                                  // width="35px"
                                  width="100%"
                                  height="100%"
                                  bg="#1AD633"
                                  borderLeft="2px solid var(--primary-color)"
                                  borderTop="2px solid var(--primary-color)"
                                  borderBottom="2px solid var(--primary-color)"
                                  overflowY="hidden"
                                  textOverflow="ellipsis"
                                >
                                  <Text
                                    paddingX="8px"
                                    lineHeight="13px"
                                    textAlign="center"
                                    fontSize="12px"
                                    whiteSpace="pre-wrap"
                                    marginTop="5px"
                                  >
                                    {" "}
                                    {ts.patient_name.replace(/(.{1})/g, "$1\n")}
                                  </Text>{" "}
                                </Box>
                              }*/}

                              {/*{(indexHour === convertHours(ts.time_range, "end") - 1 &&
                                (convertHours(ts.time_range, "end") - 1 !==
                                  convertHours(ts.time_range, "start"))) &&
                                <Box
                                  key={id}
                                  // className={`${classNameShift} ${ts.includes('30') ? 'ml-auto' : ''}`}
                                  className={`${classNameShift} 'ml-auto'`}
                                  // width="35px"
                                  width="102%"
                                  height="100%"
                                  bg="#1AD633"
                                  borderRight="2px solid var(--primary-color)"
                                  borderTop="2px solid var(--primary-color)"
                                  borderBottom="2px solid var(--primary-color)"
                                  marginLeft="-1px"
                                />

                              }*/}

                              {/*{(indexHour > convertHours(ts.time_range, "start") &&
                                (indexHour < convertHours(ts.time_range, "end") - 1)) &&
                                <Box
                                  key={id}
                                  // className={`${classNameShift} ${ts.includes('30') ? 'ml-auto' : ''}`}
                                  className={`${classNameShift} 'ml-auto'`}
                                  // width="35px #1AD633"
                                  width="102%"
                                  height="88px"
                                  bg="yellow"
                                  borderTop="2px solid var(--primary-color)"
                                  borderBottom="2px solid var(--primary-color)"
                                  marginLeft="-1px"
                                />

                              }*/}


                            </Tooltip>

                          })
                        }
                      </StyleTd>
                    ))}
                  </Tr>
                ))}
            </Table>
          </StyleTableContainer>

          <StyleBox className={`${classNameContainer} wrapper1`} zIndex="9999" overflowX="scroll" paddingTop="7px">
            <Table className={classNameTable} width={`${70 * 23}px`}>
              <Tr height="58px">
                {HOURS.map((item: any, index: any) => (
                  <StyleTd
                    position="relative"
                    zIndex="9999"
                    padding="0px"
                    key={index}
                    style={{
                      border: "0px",
                      height: "50px",
                      width: "70px !important",
                    }}
                    fontSize="12px"
                  >
                    <Text
                      className="text-left"
                      zIndex="9999"
                      position="absolute"
                      top="1px"
                      left="-7px"
                    >
                      {item}
                    </Text>
                  </StyleTd>
                ))}
              </Tr>
            </Table>
          </StyleBox>
        </div>
      </FlexRow>
    </div>
  )
}

const StyleTableContainer = styled(TableContainer)`
  &::-webkit-scrollbar-track {
    background: #888888;
  }
  &::-webkit-scrollbar-thumb {
    background: #ffffff; 
    border-radius: 4px;
  }
`

const StyleBox = styled(Box)`
  &::-webkit-scrollbar-track {
    background: #888888; 
  }
  &::-webkit-scrollbar-thumb {
    background: #ffffff; 
    border-radius: 4px;
  }
`

export default TableTimeScheduleClient
