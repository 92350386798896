import { ReactNode } from "react";
import { Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonOutline, ButtonSolid } from "component/button";
import TableAdmin from "component/admin/table";
import type { ColumnsType, TableProps } from "antd/es/table";
import { StyleCheckbox } from "component/StyledComponent";
import { InputStyled } from "./styled";

interface DataType {
  key: React.Key;
  id: string;
  customerName?: ReactNode;
  dateOfBirth?: string;
  levelOfCare?: string;
  insuranceNumber?: string;
  warrantyPeriod?: ReactNode;
  careManagement?: string;
  phoneNumber?: string;
}

let data: DataType[] = [];
for (let i = 1; i <= 85; i++) {
  let strID = `000${i}`;
  strID = strID.slice(-4);
  data.push({
    key: i,
    id: strID,
    customerName: (
      <>
        井上 隆代
        <br /> (イノウエ タカヨ)
      </>
    ),
    dateOfBirth: "昭和21年6月21日",
    levelOfCare: "要介護5",
    insuranceNumber: "1006820000",
    warrantyPeriod: (
      <>
        令和5年3月1日から令
        <br />
        和8年11月30日まで
      </>
    ),
    careManagement: "菊池 慶",
    phoneNumber: "05212345678",
  });
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};
export default function RecordEachCustomer() {
  const columns: ColumnsType<DataType> = [
    {
      align: "center",
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      align: "center",
      title: "氏名",
      dataIndex: "customerName",
      sorter: {
        //compare: (a, b) => a.familyName - b.familyName,
        //multiple: 3,
      },
      width: 150,
    },
    {
      align: "center",
      title: "電話番号",
      dataIndex: "dateOfBirth",
      sorter: {
        //compare: (a, b) => a.math - b.math,
        //multiple: 2,
      },
    },
    {
      align: "center",
      title: "携帯番号",
      dataIndex: "levelOfCare",
      sorter: {
        //compare: (a, b) => a.english - b.english,
        //multiple: 1,
      },
    },
    {
      align: "center",
      title: "年齢",
      dataIndex: "insuranceNumber",
      sorter: {
        //compare: (a, b) => a.age - b.age,
        //multiple: 1,
      },
    },
    {
      align: "center",
      title: "性別",
      dataIndex: "warrantyPeriod",
      sorter: {
        //compare: (a, b) => a.english - b.english,
        //multiple: 1,
      },
    },
    {
      align: "center",
      title: "性別",
      dataIndex: "careManagement",
      sorter: {
        //compare: (a, b) => a.english - b.english,
        //multiple: 1,
      },
    },
    {
      align: "center",
      title: "性別",
      dataIndex: "phoneNumber",
      sorter: {
        //compare: (a, b) => a.english - b.english,
        //multiple: 1,
      },
    },
    {
      align: "center",
      title: "",
      dataIndex: "action",
      render: (_, record) => (
        // <Space size="middle">
        //   {/* <a>Invite {record.name}</a>
        //   <a>Delete</a> */}
        // </Space>
        <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
          詳細
        </ButtonOutline>
      ),
    },
  ];
  const renderTabContent = (
    <div>
      <div className="py-[16px] px-[25px] bg-[#F5F4F2]">
        <div className="flex items-center mb-[8px]">
          <p className="text-[#000000] text-[12px] leading-[20px] mr-[33px]">
            キーワード
          </p>
          <StyleCheckbox className=" mr-[15px]" colorScheme="brand2">
            <p className="text-xs">未記入</p>
          </StyleCheckbox>
          <StyleCheckbox colorScheme="brand2">
            <p className="text-xs">未記入</p>
          </StyleCheckbox>
        </div>
        <div className="flex gap-x-[21px]">
          <InputStyled
            className="customInp border-[#707070] !py-[0px]"
            placeholder="氏名、カナ"
            prefix={
              <SearchOutlined className="text-[16px] text-[#2699FB] mr-[10px]" />
            }
          />
          <ButtonOutline
            className="!py-[6px] !px-[63px] !bg-[#FFFFFF] !h-[25px]"
            _hover={{
              color: "brand.500]",
              bg: "brand.100",
            }}
          >
            検索
          </ButtonOutline>
        </div>
      </div>

      {/* table */}
      <div className="mt-[19px]">
        <TableAdmin data={data} columns={columns} onChange={onChange} />
      </div>
      <div className="flex justify-end mt-[164px]">
        <ButtonOutline className="!text-[14px] !leading[[24px] !px-[36px] !h-[35px]">
          戻る
        </ButtonOutline>
      </div>
    </div>
  );
  const items = [
    {
      key: "1",
      label: "お客様",
      children: renderTabContent,
    },
    {
      key: "2",
      label: "介護スタッフ",
      children: `Content of Tab Pane 2`,
    },
  ];
  return (
    <div>
      <div className="page-title">{/* title here */}</div>
      <div className="page-container">
        <Tabs items={items} />
      </div>
    </div>
  );
}
