import { ADMIN_DASHBOARD } from "api/apiConstant";
import BaseApi from "api/baseApi";
import { MONTH_FORMAT } from "constant/date";
import dayjs from "dayjs";

class Dashboard extends BaseApi {
  getDashboard(
    params: { year_month: string } = {
      year_month: dayjs().format(MONTH_FORMAT),
    }
  ) {
    return this.get(ADMIN_DASHBOARD, params);
  }

  getShiftsList<T>(params: { patient_id: string; date: string }) {
    return this.get<T>(ADMIN_DASHBOARD + `/shifts-list`, params);
  }
}

export default Dashboard;
