import axiosClient from './axiosClient'
import queryString from 'query-string'



class StaffApi {

  loginWithToken = (username: any, password: any) => {
    const url = `/login`
    const data = {
      username: username,
      password: password
    }
    return axiosClient.post(url, data)
  }

  getHoliday = (idToken: any) => {
    const url = `/staff/monthly_holidays`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient({url: url, method:'get', headers: config })
  }

  getHolidayStatus = (idToken: any) => {
    const url = `/staff/nursing_shift_manages/next_month_shift_status`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient({url: url, method:'get', headers: config })
  }

  getShiftNextMonth = (idToken: any, params: any) => {
    const url = `/staff/nursing_shift_manages`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    //return axiosClient.get(url, config)
    return axiosClient({url: url, method: "get", headers: config, params: params })
  }

  getStaffVisitSchedule = (idToken: any, param: any) => {
    const qs = queryString.stringify(param)
    const url = `/staff/nursing_shift_manages/summary?${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  RegisterHoliday = (body: any, idToken: any, method: any) => {
    const url = `/staff/monthly_holidays`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient({url: url, method: method, headers: config, data: body })
  }

  editStaffNursingShiftManages = (body: any, idToken: any) => {
    const url = `/staff/nursing_shift_manages`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient({url: url, method: "put", headers: config, data: body })
  }

  updateShiftStaffNursingShiftManages = (body: any, idToken: any) => {
    const url = `/staff/nursing_shift_manages/update_shift`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient({url: url, method: "put", headers: config, data: body })
  }
}

const staffApi = new StaffApi()
export default staffApi
