import React from "react";
import LeftArrow from "assets/leftarrow.png";
import RightArrow from "assets/rightarrow.png";
import styled from "@emotion/styled";
import { Button, Text } from "@chakra-ui/react";
import moment from "moment";

const WeekButton = ({ getPreviousWeek, getNextWeek, week }: any) => {
  return (
    <div className="flex flex-row justify-start items-center ">
      <StyleBtn
        className="mr-3 font-bold"
        transform="scale(0.63)"
        p={0}
        variant="outline"
        onClick={getPreviousWeek}
      >
        <img src={LeftArrow} alt="" style={{ height: "15px", width: "15px" }} />
      </StyleBtn>
      <Text className=""> {moment(week).format("YYYY/MM/DD")} </Text>
      <Text className=" mx-2 "> ~ </Text>
      <Text className="">
        {" "}
        {moment(week).add(6, "days").format("YYYY/MM/DD")}{" "}
      </Text>
      <StyleBtn
        className="ml-3 mr-6 font-bold"
        p={0}
        variant="outline"
        transform="scale(0.63)"
        onClick={getNextWeek}
      >
        <img
          src={RightArrow}
          alt=""
          style={{ height: "15px", width: "15px" }}
        />
      </StyleBtn>
    </div>
  );
};

const StyleBtn = styled(Button)({
  border: "1px solid black",
  fontSize: "20px",
});

export default WeekButton;
