const dataFields = {
  complexion: {
    options: [
      {
        label: "良",
        value: "good",
      },
      {
        label: "不良",
        value: "bad",
      },
    ],
  },
  sweating: {
    options: [
      { label: "有", value: "yes" },
      {
        label: "無",
        value: "no",
      },
    ],
  },
};

export default dataFields;
