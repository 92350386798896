import { configureStore } from "@reduxjs/toolkit";
import user, { IUserState } from "./slice/user";
import notice, { INoticeState } from "./slice/noticeSlice";
import hsAdminNoticeSlice, {
  IHSAdminNoticeState,
} from "./slice/hsAdminNoticeSlice";
import hsStaffNoticeSlice, {
  IHStaffNoticeState,
} from "./slice/hsStaffNoticeSlice";

const store = configureStore({
  reducer: {
    user: user.reducer,
    notice: notice.reducer,
    hsAdminNotice: hsAdminNoticeSlice.reducer,
    hsStaffNotice: hsStaffNoticeSlice.reducer,
  },
});

export interface RootState {
  user: IUserState;
  notice: INoticeState;
  hsAdminNotice: IHSAdminNoticeState;
  hsStaffNotice: IHStaffNoticeState;
}

export default store;
