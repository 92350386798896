import { Badge, Modal, Tag } from "antd";
import apiFactory from "api";
import clsx from "clsx";
import DashboardCalendar from "component/DashboardCalendar";
import PageHeaderTitle from "component/PageHeaderTitle";
import NotificationCollapse from "component/home-system-staff/NotificationCollapse";
import { MONTH_FORMAT, TIME_FORMAT } from "constant/date";
import dayjs from "dayjs";
import useContact from "hook/useContact";
import _ from "lodash";
import moment from "moment";
import {
  DashboardResponse,
  NotificationItem,
} from "page/homeSystem/Dashboard/_type";
import StyledNotificationItem from "page/homeSystem/Dashboard/styled";
import { useState } from "react";
import { IPatientStatus, IScheduleDate, IShiftCalendar } from "types/admin";
import { FORMAT } from "utils";
import { Room } from "./HomeSystem/Dashboard/_type";
import { useSelector } from "react-redux";
import { RootState } from "store";

const sortAndSliceNotifications = <T extends Record<string, any>>(
  items: T[],
  sliceCount: number = 10
): T[] => {
  if (!items) {
    return [];
  }

  return items.length ? items.slice(0, sliceCount) : [];
};

const EVENT_STATUS = {
  not_assign: {
    className: "info",
    bg: "rgba(115, 103, 240, 0.12)",
    color: "#7367F0",
  },
  not_enough_assign: {
    className: "error",
    color: "#F64747",
    bg: "rgba(247, 71, 71, 0.12)",
  },
  assigned: {
    className: "success",
    bg: "rgba(40, 199, 111, 0.12)",
    color: "#28C76F",
  },
};

export default function DashBoardAdmin() {
  const { onContact } = useContact();
  const { id: userId } = useSelector((state: RootState) => state.user);

  const [dataDashboard, setDataDashboard] = useState<DashboardResponse | null>(
    null
  );
  const [events, setEvents] = useState<any>([]);
  const [showListMore, setShowListMore] = useState(false);
  const [dataList, setDataList] = useState<any>({});
  const [dataDetail, setDataDetail] = useState<any>({});
  const [showDetail, setShowDetail] = useState(false);
  const [scheduleDates, setScheduleDates] = useState<IScheduleDate[]>([]);

  const handleTitleClick = (clickInfo: any, dateSelect?: any) => {
    closeModal();
    let _patientId = "";
    let _date = "";
    setShowDetail(true);
    if (!dateSelect) {
      setDataDetail(clickInfo);
      _patientId = clickInfo?.event?.extendedProps.patient_id;
      _date = clickInfo?.event?.startStr;
    } else {
      setDataDetail(dateSelect);
      _patientId = dateSelect.extendedProps.patient_id;
      _date = dateSelect.startStr;
    }

    if (_patientId && _date) {
      getShiftsList({
        patient_id: _patientId,
        date: moment(_date).format(FORMAT),
      });
    }
  };

  const handleEventClick = (clickInfo: any) => {
    closeModal();
    setDataList(clickInfo);
    setShowListMore(true);
  };

  const closeModal = () => {
    setShowListMore(false);
    setShowDetail(false);
    setScheduleDates([]);
  };

  const getDataDashboard = async (params?: { year_month: string }) => {
    const _data = await apiFactory.adminDashboardApi.getDashboard(params);

    if (_data) {
      _data.notifications = sortAndSliceNotifications<NotificationItem>(
        _data.notifications
      );
      _data.rooms = sortAndSliceNotifications<Room>(_data.rooms);

      setDataDashboard(_data);

      const _events = _.flatMap(
        _data.shifts_calendar.map((item: IShiftCalendar) => {
          return item.data.map((itemChild) => ({
            title: itemChild.patient_name,
            date: item.date,
            className: EVENT_STATUS[itemChild.status].className,
            extendedProps: itemChild,
          }));
        })
      );

      setEvents(_events);
    }
  };

  // Fetch shifts list
  const getShiftsList = async (params: {
    patient_id: string;
    date: string;
  }) => {
    const _res = await apiFactory.adminShiftManagement.getPatients<{
      schedule_dates: IScheduleDate[];
    }>(params);

    if (_res) {
      setScheduleDates(_res.schedule_dates);
    }
  };

  return (
    <>
      <PageHeaderTitle className="font-semibold mt-3" title="ダッシュボード" />

      <div className="page-container mt-5">
        {dataDashboard && (
          <div className="flex flex-col gap-y-[42px]">
            <NotificationCollapse heading={"掲示板"} activeKey={"1"}>
              {dataDashboard.notifications.map((note) => (
                <StyledNotificationItem
                  key={note.id}
                  className="py-3 px-4 flex gap-2 item"
                >
                  <div className="min-w-[230px]">
                    <p className="font-semibold text-base">
                      {note.patient?.family_name}
                    </p>
                    <p>
                      <span className="text-[rgba(0,0,0,0.55)]">送信日:</span>{" "}
                      <span>{dayjs(note.created_at).format(FORMAT)}</span>
                    </p>
                    <p>
                      <span className="text-[rgba(0,0,0,0.55)]">展開日:</span>{" "}
                      <span>{dayjs(note.deployment_date).format(FORMAT)}</span>
                    </p>
                  </div>
                  <div>
                    <p>{note.content}</p>
                  </div>
                </StyledNotificationItem>
              ))}
            </NotificationCollapse>

            <NotificationCollapse
              heading={
                <>
                  個別連絡{" "}
                  <Badge count={`${dataDashboard.rooms_unread_count} 未読`} />
                </>
              }
            >
              {dataDashboard.rooms.map((room) => (
                <StyledNotificationItem
                  key={room.id}
                  className="py-3 px-4 flex gap-2 item"
                >
                  <div className="max-w-[230px] flex-1 break-keep">
                    <p
                      className="font-semibold text-base hover:cursor-pointer"
                      onClick={() => onContact(room)}
                    >
                      {userId === room.target_id
                        ? room?.source?.user_name || room?.source?.family_name
                        : room?.target?.family_name || room?.target?.user_name}
                    </p>
                    <p>
                      <span className="text-[rgba(0,0,0,0.55)]">送信日:</span>{" "}
                      <span>{dayjs(room.lastest_send_at).format(FORMAT)}</span>
                    </p>
                  </div>
                  <div>
                    <p>{room.latest_msg.content}</p>
                  </div>
                </StyledNotificationItem>
              ))}
            </NotificationCollapse>
          </div>
        )}

        <div className="mt-[60px] px-3">
          <h3 className="font-bold text-[#191919] heading">次月シフト</h3>

          <div className="flex items-center gap-x-3 mt-[26px]">
            <div className="flex items-center gap-x-2">
              <div className={clsx("w-4 h-4 rounded-[50%]", "bg-[#D83535]")} />
              <span className="text-sm text-[#7A7A7A]">
                完全にアサインされない
              </span>
            </div>
            <div className="flex items-center gap-x-2">
              <div className={clsx("w-4 h-4 rounded-[50%]", "bg-[#7367F0]")} />
              <span className="text-sm text-[#7A7A7A]">未アサイン</span>
            </div>
            <div className="flex items-center gap-x-2">
              <div className={clsx("w-4 h-4 rounded-[50%]", "bg-[#28C76F]")} />
              <span className="text-sm text-[#7A7A7A]">アサイン済み</span>
            </div>
          </div>

          <div className="mt-6">
            <DashboardCalendar
              events={events}
              handleOnDateChange={(date) =>
                getDataDashboard({ year_month: date.format(MONTH_FORMAT) })
              }
              onTitleClick={handleTitleClick}
              onEventClick={handleEventClick}
              fullCalendarProps={{
                initialDate: dayjs().add(1, "M").format(),
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        open={showListMore}
        title={moment(dataList.date).format("YYYY.MM.DD")}
        footer={null}
        style={{ width: 520 }}
        onCancel={() => setShowListMore(false)}
        centered
      >
        <div className="flex flex-col gap-1 max-h-[272px] overflow-y-auto">
          {dataList?.allSegs?.map((event: any) => {
            const item: {
              patient_id: number;
              patient_name: string;
              status: IPatientStatus;
            } = event.event.extendedProps;

            const tagStyle = EVENT_STATUS[item.status];
            if (!tagStyle) {
              console.error(`Unknown status: ${item.status}`);
              return null;
            }
            return (
              <Tag
                bordered={false}
                key={item.patient_id}
                color={tagStyle.bg}
                style={{ color: tagStyle.color }}
                className="px-8 py-3 font-bold text-base hover:cursor-pointer"
                onClick={() => handleTitleClick(null, event.event)}
              >
                {item.patient_name}
              </Tag>
            );
          })}
        </div>
      </Modal>

      {/* Detail Modal */}
      <Modal
        title={
          <div className="wrapper-title shift-modal">
            <div className="title">
              ケアマネプラン一覧 - {dataDetail?.event?.title}
            </div>
            <div className="sub-title">
              {moment(dataDetail?.start || dataDetail?.event?.start).format(
                "YYYY.MM.DD"
              )}
            </div>
          </div>
        }
        open={showDetail}
        footer={null}
        style={{ width: 520 }}
        onCancel={closeModal}
        centered
      >
        <div className="flex flex-col gap-y-[10px]">
          {scheduleDates.map((schedule) => {
            const status =
              EVENT_STATUS?.[
                dataDetail?.event?._def?.extendedProps
                  ?.status as keyof typeof EVENT_STATUS
              ];

            return (
              <div
                key={schedule.id}
                className="py-[12px] px-[24px] rounded-[12px]"
                style={{
                  backgroundColor: status?.bg,
                  color: status?.color,
                }}
              >
                <div className="wraper-item-detail">
                  <div>
                    <div className="title">
                      {schedule?.scheduleable?.service?.service_name}
                    </div>
                    <div className="time">{schedule?.time_range}</div>
                    {schedule?.nurse && (
                      <div className="text">{schedule?.nurse?.family_name}</div>
                    )}
                    {!schedule?.nurse && (
                      <div className="text-img">
                        スタッフがまだ指定されていません。
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}
