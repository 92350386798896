import { Input } from "antd";
import "./style.scss";
import { PasswordProps } from "antd/es/input";
import React from "react";

interface IInputProps extends PasswordProps {}
export default function InputPassword({ ...restProps }: IInputProps) {
  const [readOnly, setReadOnly] = React.useState(true);
  return (
    <Input.Password
      readOnly={restProps.readOnly || readOnly}
      autoComplete="off"
      {...restProps}
      onFocus={(e) => {
        setReadOnly(false);
        restProps.onFocus?.(e);
      }}
      onBlur={(e) => {
        setReadOnly(true);
        restProps.onBlur?.(e);
      }}
    />
  );
}
