import { useState } from "react"
import { DisabilityEnhanceDetail } from "./DisabilityEnhanceDetail"
import { DisabilityEnhanceList } from "./DisabilityEnhanceList"

const DisabilityEnhance = () => {
    const [isList, setIsList] = useState<boolean>(true)
    const [serviceId, setServiceId] = useState(null)
    const [difference, setDifference] = useState({
        type: ''
    })
    const onChange = () => {
        setIsList(!isList)
    }
    return isList ? <DisabilityEnhanceList onChange={onChange} setServiceId={setServiceId} setDifference={setDifference}/> :
        <DisabilityEnhanceDetail onChange={onChange} id={serviceId} setServiceId={setServiceId} difference={difference} setDifference={setDifference}/>
}
export { DisabilityEnhance }
