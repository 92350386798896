import { Box, Button, Text } from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Input } from "antd";
import Header from "component/header/Header";
import RadioForm, { styleRadioProps } from "component/form/RadioForm";
import styled from "@emotion/styled";
import { FlexRow, StyleGrid, StyleGridItem } from "component/StyledComponent";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonOutline, ButtonSolid } from "component/button";

import "./style.scss";

/**Chau Tran: Add screen Menu Client "検索" */
const SearchPage = () => {
  return (
    <div className="page-container bg-[#FFFFFF] h-full">
      {/* <Header title="メインメニュー" /> */}
      <Box h="50px" />
      <FlexRow style={{ textAlign: "left", fontSize: "12px" }}>
        <div className="flex-[1_1_82%] py-[16px] px-[25px]">
          <StyleGrid
            templateColumns="repeat(5, 1fr)"
            gap={6}
            style={{ fontWeight: "normal" }}
          >
            <StyleGridItem colSpan={1}>
              <TextP style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                <span style={{ color: "var(--primary-color)" }}>※</span>姓
              </TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={2}>
              <FlexRow>
                <Input
                  type="text"
                  height="40px"
                  name="search"
                  className="customInp border-[#707070]"
                />
              </FlexRow>
            </StyleGridItem>
          </StyleGrid>
        </div>
      </FlexRow>
      <FlexRow
        style={{ textAlign: "left", fontSize: "12px" }}
        className="px-[15px]"
      >
        <div className="flex-[1_1_82%] py-[16px] px-[25px] bg-[#F5F4F2]">
          <p className="text-[#000000] text-[12px] leading-[20px] mb-[8px]">
            キーワード
          </p>
          <div className="flex gap-x-[21px]">
            <Input
              className="customInp border-[#707070]"
              placeholder="全文検索"
              prefix={
                <SearchOutlined className="text-[16px] text-[#2699FB] mr-[10px]" />
              }
            />
            <ButtonSolid
              className="!py-[6px] !px-[63px] !bg-[#E07B2E]"
              _hover={{
                color: "brand.500]",
                bg: "brand.100",
              }}
            >
              検索
            </ButtonSolid>
            <ButtonOutline
              className="!py-[6px] !px-[63px] !bg-[#FFFFFF]"
              _hover={{
                color: "brand.500]",
                bg: "brand.100",
              }}
            >
              詳細検索
            </ButtonOutline>
          </div>
        </div>
      </FlexRow>
      <FlexRow style={{ textAlign: "left", fontSize: "12px" }}>
        <div className="flex-[1_1_82%] py-[16px] px-[25px]">
          <StyleGrid
            templateColumns="repeat(5, 1fr)"
            gap={6}
            style={{ fontWeight: "normal" }}
          >
            <StyleGridItem colSpan={1}>
              <Box border="1px solid #000" borderRadius="0px">
                <Text className="p-1"> 排泄介助・体位交換　　　1</Text>
                <Text className="p-1"> 排泄介助・体位交換</Text>
                <Text className="p-1"> 入浴</Text>
                <Text className="p-1"> 口腔ケア・排泄介助</Text>
                <Text className="p-1"> </Text>
              </Box>
            </StyleGridItem>
          </StyleGrid>
        </div>
      </FlexRow>
      <FlexRow style={{ textAlign: "left", fontSize: "12px" }}>
        <div className="flex-[1_1_82%] py-[16px] px-[25px]">
          <StyleGrid
            templateColumns="repeat(5, 1fr)"
            gap={6}
            style={{ fontWeight: "normal" }}
          >
            <StyleGridItem colSpan={1}>
              <TextP style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                <span style={{ color: "var(--primary-color)" }}>※</span>姓
              </TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={2}>
              <FlexRow>
                <Input
                  type="text"
                  height="40px"
                  name="search"
                  className="customInp border-[#707070]"
                />
              </FlexRow>
            </StyleGridItem>
          </StyleGrid>
          <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                性別
              </TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={1} style={{ textAlign: "center" }}>
              <RadioForm rdTit1="男" rdTit2="女" color="#000" fontSize="12px" />
            </StyleGridItem>
          </StyleGrid>
        </div>
      </FlexRow>
    </div>
  );
};

const TextP = styled(Text)({
  fontSize: "12px",
  marginTop: "12px",
});

export default SearchPage;
