import { Dayjs } from "dayjs";

export function fillDateArray(array: any[] = [], month: Dayjs) {
  const newArray: any[] = [];
  [...Array(month.daysInMonth())].forEach((i, index) => {
    const curIndex = array.findIndex(
      (item: any) => item.date === month.date(index + 1).format("YYYY-MM-DD")
    );
    if (curIndex !== -1) newArray.push(array[curIndex]);
    else newArray.push(null);
  });
  return newArray;
}
