import { useMemo, useState } from "react";
import NotificationForm from "../components/form";
import { Tabs, TabsProps } from "antd";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      content: none;
    }
  }
  .ant-tabs-content-holder {
  }
`;

const CreateNoti: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.has("tab") && queryParams.get("tab");
  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "重要事項",
        children: <NotificationForm formType="important" />,
      },
      {
        key: "2",
        label: "申し送り事項",
        children: <NotificationForm formType="conveyed" />,
      },
    ],
    []
  );

  const onChange: TabsProps["onChange"] = (activeKey) => {
    navigate(`/admin/notice/create?tab=${activeKey}`);
  };

  return (
    <div>
      <div className="page-title">掲示板　親スレッド投稿</div>
      <div className="page-container">
        <div className="w-[490px]">
          <StyledTabs
            activeKey={activeTab || "1"}
            items={items}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateNoti;
