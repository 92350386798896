import React, { Dispatch, SetStateAction, useState } from "react";
import { Col, Row, Space } from "antd";
import { TabList, Tab, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { DateButton, WeekButton } from "component/timeBtn";
import { ButtonSolid, ButtonOutline } from "component/button";
import dayjs, { Dayjs } from "dayjs";
import SelectTime from "component/SelectTime";

interface IBar {
  tabIndex: number;
  type: string;
  dateState: [Date, (date: Date) => void];
  weekState: [Dayjs, (date: Dayjs) => void];
  monthState: [Dayjs, (date: Dayjs) => void];
  setOpenRegisterShift: (value: boolean) => void;
  formRegister: {
    title: string;
    child: JSX.Element;
  };
  editState: [boolean, Dispatch<SetStateAction<boolean>>];
  showRegisterButtons: boolean;
}

const Bar = ({
  tabIndex,
  type,
  dateState,
  weekState,
  monthState,
  setOpenRegisterShift,
  formRegister,
  editState,
  showRegisterButtons,
}: IBar) => {
  const [date, setDate] = dateState;
  const [startWeek, setStartWeek] = weekState;
  const month = monthState;
  const getPreviousDay = () => setDate(dayjs(date).subtract(1, "day").toDate());

  const getNextDay = () => setDate(dayjs(date).add(1, "day").toDate());

  const getPreviousWeek = () => setStartWeek(startWeek.subtract(1, "week"));

  const getNextWeek = () => setStartWeek(startWeek.add(1, "week"));

  const [editMode, setEditMode] = editState;

  return (
    <Row justify="space-between">
      <Col className="h-10 flex flex-col justify-center" span={6}>
        <Space>
          <TabList
            border="1px solid black"
            width="96px"
            height="30px"
            overflow="hidden"
            fontSize="12px"
            borderRadius="5px"
          >
            <StyleTab
              _selected={{ color: "white", bg: "brand.500" }}
              _after={{
                content: '""',
                position: "absolute",
                right: "-0.5px",
                background: "black",
                width: "1px",
                height: "20px",
              }}
              position="relative"
              color="black"
            >
              月
            </StyleTab>
            <Tab
              padding="0px"
              fontSize="12px"
              borderRadius={0}
              _selected={{ color: "white", bg: "brand.500" }}
              color="black"
            >
              <Text p="5px 10px" borderX="0px">
                週
              </Text>
            </Tab>
            <StyleTab
              _selected={{ color: "white", bg: "brand.500" }}
              _before={{
                content: '""',
                position: "absolute",
                left: "-0.5px",
                background: "black",
                width: "1px",
                height: "20px",
              }}
              position="relative"
              color="black"
            >
              日
            </StyleTab>
          </TabList>
          {tabIndex === 1 && (
            <WeekButton
              getPreviousWeek={getPreviousWeek}
              getNextWeek={getNextWeek}
              week={startWeek.toDate()}
            />
          )}
          {tabIndex === 0 && (
            <SelectTime
              value={month[0]}
              type="M"
              format="YYYY/MM"
              onChange={month[1]}
            />
          )}
          {tabIndex === 2 && (
            <DateButton
              getPreviousDay={getPreviousDay}
              getNextDay={getNextDay}
              date={date}
            />
          )}
        </Space>
      </Col>
      <Col className="flex flex-col justify-center">
        <Space size={8}>
          {tabIndex === 2 && type === "patient" && (
            <ButtonSolid className="!h-[35px] !px-[34px]">調整</ButtonSolid>
          )}
          {tabIndex === 2 && (type === "client" || type === "staff") && (
            editMode ? (
              <ButtonOutline className="!h-[35px] !px-[34px]" _hover={{ color: "brand.100", bg: "#fff" }} onClick={() => setEditMode(false)}>編集</ButtonOutline>
            ) : (
              <ButtonSolid className="!h-[35px] !px-[34px]" onClick={() => setEditMode(true)}>編集</ButtonSolid>
            )
          )}
          <ButtonSolid
            className="!h-[35px] !px-[34px]"
            onClick={() => setOpenRegisterShift(true)}
            hidden={!showRegisterButtons}
          >
            {formRegister.title}
          </ButtonSolid>
        </Space>
      </Col>
    </Row>
  );
};

const StyleTab = styled(Tab)({
  fontSize: "12px",
  borderRadius: "0px",
  padding: "5px 10px",
});

export default Bar;
