import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from "constant/apiRequest";
import { useState } from "react";

const usePagination = () => {
  const [limit, setLimit] = useState<number>(DEFAULT_PER_PAGE);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);

  return {
    limit,
    page,
    setLimit,
    setPage,
  };
};

export default usePagination;
