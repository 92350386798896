import { useState } from "react"
import { NursingCareServiceDetail } from "./NursingCareServiceDetail"
import { NursingCareServiceList } from "./NursingCareServiceList"

const NursingCareService = () => {
    const [isList, setIsList] = useState<boolean>(true)
    const [serviceId, setServiceId] = useState(null)
    const [difference, setDifference] = useState({
        type: ''
    })
    const onChange = () => {
        setIsList(!isList)
    }
    return isList ? <NursingCareServiceList onChange={onChange}
        setServiceId={setServiceId} setDifference={setDifference} /> :
        <NursingCareServiceDetail onChange={onChange}
            id={serviceId} setServiceId={setServiceId} difference={difference} setDifference={setDifference}/>
}
export { NursingCareService }
