import { BOARD_ADMIN } from "../apiConstant";
import BaseApi from "api/baseApi";

class BoardAdminApi extends BaseApi {
  createPost(body: {
    id?: number;
    title?: string;
    content?: string;
    reply_to?: string;
    is_important?: boolean;
    mentions?: [];
    is_deleted?: boolean;
  }) {
    return this.post(BOARD_ADMIN, body);
  }
  getPostList(body: {
    per?: number;
    page?: number;
    search?: string;
    notify_tab?: number;
    notification_category_id?: number;
    start_at?: string;
    end_at?: string;
  }) {
    return this.get(BOARD_ADMIN, body);
  }

  createNotification(body: {
    patient_id: number;
    deployment_date: string;
    content: string;
  }) {
    return this.post(BOARD_ADMIN, body);
  }

  createNotificationGroup(body: {
    notification_category_id: number;
    content: string;
  }) {
    return this.post(`${BOARD_ADMIN}/group`, body);
  }

  getPostDetail(postId: number) {
    return this.get(`${BOARD_ADMIN}/${postId}`);
  }

  getByParent(reply_to: number) {
    return this.get(`${BOARD_ADMIN}/${reply_to}/replies_by_parent`);
  }

  getServiceDetail(id: any) {
    return this.get(`${BOARD_ADMIN}/${id}`);
  }

  updatePost(body: {
    id?: number;
    title?: string;
    content?: string;
    reply_to?: string;
    is_important?: boolean;
    mentions?: [];
    is_deleted?: boolean;
  }) {
    return this.put(`${BOARD_ADMIN}/${body?.id}`, body);
  }
}
export default BoardAdminApi;
