import Styles from "./styled";
import { useState } from "react";
import HiddenTab from "component/tab";
import ExpenseList from "./List";
import CreateExpense from "./Create";

export interface ITabData {
  activeKey: string;
  data?: {
    copiedId: number;
  };
}

export default function Expense() {
  const [tab, setTab] = useState<ITabData>({
    activeKey: "list",
  });
  const tabItems = [
    {
      key: "list",
      children: (
        <ExpenseList onChangeTab={setTab} isActive={tab.activeKey === "list"} />
      ),
    },
    {
      key: "create",
      children: (
        <CreateExpense
          onChangeTab={setTab}
          isActive={tab.activeKey === "create"}
          copiedId={tab.data?.copiedId}
        />
      ),
    },
  ];
  return (
    <Styles>
      <HiddenTab activeKey={tab.activeKey} onChange={setTab} items={tabItems} />
    </Styles>
  );
}
