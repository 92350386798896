import BaseApi from "api/baseApi";
import { objectToFormData } from "utils/data";
import { ADMIN_EVALUE_TWOS } from "../apiConstant";
import { IEvalueTwo } from "types/admin";

type IEVALUE_PAGINATION = {
  page?: number | string;
  per_page?: number | string;
};

class evalueApi extends BaseApi {
  getEvalue<T>(params: IEVALUE_PAGINATION & { patient_id: number | string }) {
    return this.get<T>(ADMIN_EVALUE_TWOS, params);
  }

  // getEvalueDetail<T>(id: number | string) {
  //   return this.get<T>(ADMIN_EVALUE_TWOS + `/${id}`);
  // }

  createEvalue<T>(
    body:
      | any
      | (IEvalueTwo & {
          patient_id: number | string;
        })
  ) {
    return this.post<T>(ADMIN_EVALUE_TWOS, objectToFormData(body), {
      header: { "Content-Type": "multipart/form-data" },
    });
  }

  updateEvalue<T>(
    body: IEvalueTwo & {
      id: number;
    }
  ) {
    return this.put<T>(
      ADMIN_EVALUE_TWOS + `/${body.id}`,
      objectToFormData(body),
      {
        header: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  deleteEvalue<T>(id: number | string) {
    return this.delete<T>(ADMIN_EVALUE_TWOS + `/${id}`);
  }
}

export default evalueApi;
