import styled from "@emotion/styled";

const Styles = styled.div`
  td.ant-table-cell.shift-status {
    padding: 0 !important;
    height: 100px;
    user-select: none;
  }
  table,
  th,
  td {
    border: 1px solid lightgray !important;
    border-collapse: collapse;
  }
  .selectable {
    width: 100%;
    height: 100%;
    &.selected > div {
      background: blue !important;
    }
  }
  td,
  th {
    padding: 0 !important;
  }
`;
export default Styles;
