import { Form, Input } from "antd";
import { ButtonSolid } from "component/button";
import { INotification } from "constant/interfaces";
import { useState } from "react";
import apiFactory from "api";
import { useQuery } from "react-query";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_OPTIONS,
  DEFAULT_PER_PAGE,
} from "constant/apiRequest";
import useResponsive from "hook/useResponsive";
import { SearchOutlined } from "@ant-design/icons";
import CustomPagination from "component/CustomPagination";

const Important: React.FC = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const { isMobile } = useResponsive();

  const { data, refetch: refetchNotifications } = useQuery(
    ["important", perPage, page],
    () =>
      apiFactory.staffNoticeApi.getNotifications({
        page,
        per: perPage,
        notify_tab: 1,
        search: form.getFieldValue("search")?.trim(),
      })
  );

  const handleSearch = () => {
    setPage(DEFAULT_PAGE);
    refetchNotifications();
  };

  return (
    <div className="pt-[12px]">
      <Form form={form} onFinish={handleSearch}>
        <div className="flex">
          <Form.Item name="search" className="max-md:flex-1 w-[200px] mr-2">
            <Input
              placeholder="検索"
              className="!border-[#D9D9D9] max-md:h-[40px] h-[35px] max-md:rounded-[4px]"
              prefix={
                isMobile ? (
                  <SearchOutlined
                    style={{
                      fontSize: "20px",
                      color: "#666666",
                    }}
                  />
                ) : null
              }
            />
          </Form.Item>
          <ButtonSolid
            type="submit"
            className="max-md:w-[73px] max-md:h-[30px]"
            h={isMobile ? "40px" : "35px"}
          >
            検索
          </ButtonSolid>
        </div>
      </Form>

      <ul className="max-md:mb-[30px]">
        {data?.importants?.map((item: INotification) => {
          return (
            <div
              className="flex max-md:flex-col border border-solid border-[#EB752626] px-[15px] py-[8px] mb-[5px] bg-[#FDF1E9]"
              key={item?.id}
            >
              <div className="flex">
                <div className="mr-[7px]">
                  <span className="important p-[2px] rounded-[4px]">重</span>
                </div>

                <div className="md:mr-[80px] md:w-[180px]">
                  <p className="font-semibold max-md:font-[700]">
                    {item.patient?.family_name}
                  </p>
                  <p>
                    <span className="text-[#0000008C]">作成日:</span>{" "}
                    {item?.created_at}
                  </p>
                  <p className="max-md:hidden">
                    <span className="text-[#0000008C]">展開日:</span>{" "}
                    {item?.deployment_date}
                  </p>
                </div>
              </div>

              <div className="md:w-[700px]">
                <p>{item?.content}</p>
              </div>
            </div>
          );
        })}
      </ul>

      <CustomPagination
        current={page}
        setCurrent={setPage}
        total={data?.total_items || 0}
        perPage={perPage}
        setPerPage={setPerPage}
        pageSizeOptions={DEFAULT_PAGE_OPTIONS}
      />

      <ul className="max-md:mt-[30px]">
        {data?.notifications?.map((item: INotification) => {
          return (
            <div
              className="flex max-md:flex-col md:border-t border-b border-solid border-[#00000026] px-[15px] py-[8px]"
              key={item.id}
            >
              <div className="flex">
                <div className="mr-[7px]">
                  <span className="important p-[2px] rounded-[4px]">重</span>
                </div>

                <div className="md:mr-[80px] md:w-[180px]">
                  <p className="font-semibold max-md:font-[700]">
                    {item.patient?.family_name}
                  </p>
                  <p>
                    <span className="text-[#0000008C]">作成日:</span>{" "}
                    {item.created_at}
                  </p>
                  <p className="max-md:hidden">
                    <span className="text-[#0000008C]">展開日:</span>{" "}
                    {item.deployment_date}
                  </p>
                </div>
              </div>

              <div className="md:w-[700px]">
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Important;
