import { Checkbox } from "@chakra-ui/checkbox";
import { StyleGrid, StyleGridItem } from "component/StyledComponent";

const CheckboxForm = ({
  title,
  content,
  value,
  changeValue,
  name,
  disable,
  col = 5,
  fontSize = "16px",
}: any) => {
  return (
    <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
      <StyleGridItem colSpan={1}>
        <p style={{ color: "#555555", fontSize: fontSize }}>{title}</p>
      </StyleGridItem>
      <StyleGridItem colSpan={col - 1}>
        <Checkbox
          disabled={disable}
          colorScheme="brand2"
          name={name}
          isChecked={value}
          onChange={changeValue}
          borderColor="var(--primary-text-dark)"
        >
          <p style={{ fontSize: fontSize }}>{content}</p>
        </Checkbox>
      </StyleGridItem>
    </StyleGrid>
  );
};

export default CheckboxForm;
