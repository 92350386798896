import { useEffect, useState } from "react";
import { Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ButtonOutline } from "component/button";
import TableAdmin from "component/admin/table";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { formatJapanDate } from "constant/date";
import { InputStyled } from "./styled";

export const columns: ColumnsType<any> = [
  {
    align: "center",
    title: "ID",
    dataIndex: "patient_code",
    sorter: true,
  },
  {
    align: "center",
    title: "氏名",
    sorter: true,
    width: 150,
    dataIndex: "name",
    render: (value, record) => (
      <div>
        {record.family_name}
        <br />({record.name_kana})
      </div>
    ),
  },
  {
    align: "center",
    title: "年齢",
    dataIndex: "age",
    sorter: true,
  },
  {
    align: "center",
    title: "居住地",
    sorter: true,
    dataIndex: "residence",
    ellipsis: true,
  },
  {
    align: "center",
    title: "生年月日",
    dataIndex: "birth_date",
    sorter: true,
    render: (value) => formatJapanDate(value),
    className: "min-w-[150px] max-w-[150px]",
  },
  {
    align: "center",
    title: "介護度",
    dataIndex: "care_level",
    sorter: true,
    render: (value, record) => <div>{record?.insurance_card?.care_level}</div>,
  },
  {
    align: "center",
    title: "",
    dataIndex: "action",
    width: 200,
    render: (_, record) => (
      <Link to={`${record.id}`}>
        <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
          詳細
        </ButtonOutline>
      </Link>
    ),
  },
];

export default function StaffList() {
  const [data, setData] = useState<any[]>([]);
  const [form] = Form.useForm();
  const service = useService();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const getDatePatients = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: `${service.HS_ADMIN}/patients`,
        method: "get",
        params: { ...form.getFieldsValue() },
      });
      setData(res.data.patients);
      setLoading(false);
      setTotal(res.data.total_items);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    form.resetFields();
    getDatePatients();
    localStorage.setItem("backToScreen4", "false");
    localStorage.removeItem("patientChangeID");
  }, []);

  return (
    <Form form={form} onFinish={getDatePatients}>
      <div className="page-title">お客様一覧</div>
      <div className="page-container">
        <div className="py-[16px] px-[25px] bg-[#F5F4F2]">
          <p className="text-[#000000] text-[12px] leading-[20px] mb-[8px]">
            キーワード
          </p>
          <div className="flex gap-x-[21px] items-center">
            <Form.Item name="fullname" className="w-full mb-0">
              <InputStyled
                className="flex items-center customInp border-[#707070] focus:!text-transparent"
                placeholder="氏名、カナ"
                prefix={
                  <SearchOutlined className="text-[16px] text-[#2699FB] mr-[3px]" />
                }
              />
            </Form.Item>
            <ButtonOutline
              type="submit"
              onClick={() => {
                form.resetFields(["page"]);
                form.submit();
              }}
              className=" !px-[63px] !bg-[#FFFFFF] !max-h-[25px]"
              _hover={{
                color: "brand.500]",
                bg: "brand.100",
              }}
            >
              検索
            </ButtonOutline>
          </div>
        </div>
        <div>
          <TableAdmin
            form={form}
            data={data}
            columns={columns}
            total={total}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  );
}
