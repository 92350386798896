import { Box, Table, Text, Tr } from '@chakra-ui/react'
import { FlexRow, StyleTd } from 'component/StyledComponent'
import styled from '@emotion/styled'
import moment from 'moment'
import { FORMAT, getCookie } from 'utils'
import clientScheduleApi from 'api/client/clientScheduleApi'
import Cookies from "js-cookie";
import { useEffect, useState } from 'react'
import { DATE_FORMAT } from 'constant/date'

const data = [
  {
    sun: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
    mon: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
    tues: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
    wed: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
    thus: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
    fri: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
    sar: {
      name: '介護スタッフ名',
      time: '04:00〜04:30',
      type: '排泄介助・体位交換'
    },
  },
  {
    sun: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
    mon: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
    tues: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
    wed: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
    thus: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
    fri: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
    sar: {
      name: '介護スタッフ名',
      time: '07:30〜08:00',
      type: '口腔ケア・排泄介助'
    },
  },
  {
    sun: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
    mon: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
    tues: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
    wed: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
    thus: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
    fri: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
    sar: {
      name: '介護スタッフ名',
      time: '10:00〜10:30',
      type: '口腔ケア・排泄介助'
    },
  },
  {
    sun: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
    mon: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
    tues: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
    wed: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
    thus: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
    fri: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
    sar: {
      name: '介護スタッフ名',
      time: '11:00〜11:30',
      type: '口腔ケア・排泄介助'
    },
  },
]
interface IPropsVisitScheduleDayClient {
  weekStart: any;
  weekEnd: any;
  onChangeDate?: any;
}

const TableVisitScheduleDayClient = ({ weekStart, weekEnd, onChangeDate }: IPropsVisitScheduleDayClient) => {
  const handleGetDate = (pr: any) => {
    onChangeDate(pr)
  }
  const getNextDay = (num: number, type: string) => {
    if (type === 'renderDay') {
      return new Date(moment(weekStart).add(num, 'days').format(FORMAT)).getDate();
    } else {
      return new Date(moment(weekStart).add(num, 'days').format(FORMAT))
    }
  }
  const idToken = getCookie("access_token")

  const [dataWeek, setDataWeek]: any = useState([])

  const getWeekSchedule = async () => {
    try {
      const param = {
        week_start_date: moment(weekStart).format(DATE_FORMAT),
        week_end_date: moment(weekEnd).format(DATE_FORMAT),
      }
      const idToken = Cookies.get("access_token")
      const res = await clientScheduleApi.getShiftSchedule(idToken, param)
      if (res) {
        // console.log('res', res.data.data)
        setDataWeek(res.data.data)
      }
    } catch (error) {

    }
  }

  const getShift = (shift: any, day: string) => {
    if (Object.keys(shift).length > 0) {

      let newArr: any = []

      for (const [key, value] of Object.entries(shift)) {
        if (key === day) {
          newArr = value
        }

        // newArr.push({
        //   day: new Date(key).getDate(),
        //   data: [`訪問${newVal.count}`],
        //   color: newVal.has_color
        // })
      }
      return newArr?.map((item: any, index: any) => (
        <BoxTd className="my-[5px]" >

          <StyleText>{item.nurse_name}</StyleText>
          <StyleText>{item.time_range}</StyleText>
          <StyleText>{item.service_name}</StyleText>
        </BoxTd>
      ))
    }


  }

  useEffect(() => {
    getWeekSchedule()
  }, [weekStart, weekEnd])

  return (
    <div>
      <FlexRow>
        {/* <Box
          className="!text-center"
          fontWeight="bold"
          fontSize="14px"
          p="20px 0px"
          width="150px"
          bg="bgGray.100"
          border="1px solid #000"
          borderRight="0px"
        >
          介護スタッフ
        </Box> */}
        <Table className='text-xs' >
          <HeadTd>{`${weekStart.getDate()}日(日)`}</HeadTd>
          <HeadTd>{`${getNextDay(1, 'renderDay')}日(月)`}</HeadTd>
          <HeadTd>{`${getNextDay(2, 'renderDay')}日(火)`}</HeadTd>
          <HeadTd>{`${getNextDay(3, 'renderDay')}日(水)`}</HeadTd>
          <HeadTd>{`${getNextDay(4, 'renderDay')}日(木)`}</HeadTd>
          <HeadTd>{`${getNextDay(5, 'renderDay')}日(金)`}</HeadTd>
          <HeadTd>{`${weekEnd.getDate()}日(土)`}</HeadTd>
        </Table>
      </FlexRow>
      <FlexRow>
        <Table className={`text-xs `}>
          <Tr>
            <TableTd>
              {getShift(dataWeek, moment(weekStart).format("YYYY-MM-DD"))}

            </TableTd>
            <TableTd>
              {getShift(dataWeek, moment(weekStart).add(1, "day").format("YYYY-MM-DD"))}
            </TableTd>
            <TableTd>
              {getShift(dataWeek, moment(weekStart).add(2, "day").format("YYYY-MM-DD"))}
            </TableTd>
            <TableTd>
              {getShift(dataWeek, moment(weekStart).add(3, "day").format("YYYY-MM-DD"))}
            </TableTd>
            <TableTd>
              {getShift(dataWeek, moment(weekStart).add(4, "day").format("YYYY-MM-DD"))}
            </TableTd>
            <TableTd>
              {getShift(dataWeek, moment(weekStart).add(5, "day").format("YYYY-MM-DD"))}
            </TableTd>
            <TableTd>
              {getShift(dataWeek, moment(weekEnd).format("YYYY-MM-DD"))}
            </TableTd>
          </Tr>
        </Table>
      </FlexRow>
    </div>
  )
}
const HeadTd = styled(StyleTd)({
  background: '#f5f4f2',
  border: '1px solid #000',
  fontSize: '11px',
  width: '120px',
  fontWeight: 'bold',
  height: '42px'
})

const BoxTd = styled(Box)({
  padding: '2px 5px 15px 2px',
  border: '1px solid black'
})

const TableTd = styled(StyleTd)({
  border: '1px solid #000',
  fontSize: '11px',
  padding: '5px',
  width: '120px',
  fontWeight: 'bold',
  height: '42px',
  textAlign: 'left'
})

const StyleText = styled(Text)({
  fontSize: '12px'
})

export default TableVisitScheduleDayClient
