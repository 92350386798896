import styled from "@emotion/styled";
import { Select } from "antd";

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-color: #d9d9d9 !important;
    height: 35px !important;
    width: 200px !important;
  }
`;
