import { SearchOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { ColumnType } from "antd/es/table";
import axiosClient from "api/axiosClient";
import SelectTime from "component/SelectTime";
import EnhanceTable from "component/admin/table";
import { ButtonOutline } from "component/button";
import { DATE_FORMAT, MONTH_FORMAT } from "constant/date";
import dayjs, { Dayjs } from "dayjs";
import useService from "hook/useService";
import { InputStyled } from "page/admin/Staff/StaffList/styled";
import React, { useEffect, useMemo, useState, useLayoutEffect } from "react";
import { ITabData } from "..";
import useTable from "hook/useTable";
import moment from "moment";
import ReceiptPDF from "page/admin/Payment/PDF/ReceiptPDF";
import usePrint from "hook/usePrint";

interface IProps {
  onChangeTab: (data: ITabData) => void;
  isActive?: boolean;
  setRecord?: any;
}

export default function DepositList({
  onChangeTab,
  isActive,
  setRecord,
}: IProps) {
  const { data, setData, loading, setLoading, total, setTotal } = useTable();
  // const [loading, setLoading] = useState<boolean>(false);
  // const [total, setTotal] = useState<number>(0);
  const [form] = Form.useForm();
  const service = useService();
  const [tabKey, setTabKey] = React.useState<string>("1");
  const [month, setMonth] = React.useState<Dayjs>(dayjs());
  const { isCapturing, setCapturing, ref } = usePrint(true);
  const [pdfData, setPdfData] = useState();
  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: "ID",
        sorter: true,
        align: "center",
        dataIndex: "patient_code",
      },
      {
        width: 250,
        title: "氏名",
        sorter: true,
        align: "center",
        dataIndex: "family_name",
        render: (value, record) => (
          <span>
            {record.family_name}
            <br />({record.name_kana})
          </span>
        ),
        className: "min-w-[250px] max-w-[250px]",
        ellipsis: true,
      },
      {
        title: "ステータス",
        sorter: true,
        align: "center",
        dataIndex: "payment_history_status",
        render: (value, record) => record?.payment_history?.status,
        className: "min-w-[150px] max-w-[150px]",
        ellipsis: true,
      },
      {
        title: "入金予定日",
        sorter: true,
        align: "center",
        dataIndex: "payment_history_expected_date",
        render: (value, record) => (
          <div>
            {record?.payment_history?.expected_date &&
              moment(new Date(record?.payment_history?.expected_date)).format(
                DATE_FORMAT
              )}
          </div>
        ),
      },
      {
        title: "入金日",
        sorter: true,
        align: "center",
        dataIndex: "payment_history_actual_date",
        render: (value, record) => (
          <div>
            {record?.payment_history?.actual_date &&
              moment(new Date(record?.payment_history?.actual_date)).format(
                DATE_FORMAT
              )}
          </div>
        ),
      },
      {
        title: "",
        align: "center",
        render: (value, record) =>
          record.can_implement_payment_history && (
            <ButtonOutline
              h={23}
              fontSize={12}
              onClick={() => {
                onChangeTab({
                  activeKey: "detail",
                  data: { selectedId: record?.id },
                });
                setRecord({
                  ...record,
                  month: month,
                });
              }}
            >
              詳細
            </ButtonOutline>
          ),
        className: "min-w-[150px] max-w-[150px]",
      },
      {
        title: "",
        align: "center",
        render: (value, record) =>
          record?.payment_history?.status === "入金完了" && (
            <ButtonOutline
              h={23}
              fontSize={12}
              // onClick={() =>
              //   onChangeTab({
              //     activeKey: "detail",
              //     data: { selectedId: record?.id },
              //   })
              // }
              onClick={() => handleClickPrintPDF(record.id)}
            >
              領収書発行
            </ButtonOutline>
          ),
        className: "min-w-[150px] max-w-[150px]",
      },
    ],
    [month, isActive]
  );
  const handleClickPrintPDF = async (patient_id: number | string) => {
    const response = await axiosClient.get(
      service.BILLINGS + "/billing_history_invoice_1",
      {
        params: {
          patient_id,
          year_month: month.format("YYYY/MM"),
        },
      }
    );
    setPdfData(response?.data.data);
  };
  const renderPDF = React.useMemo(() => {
    return <ReceiptPDF type="invoice" data={pdfData} />;
  }, [pdfData]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.PATIENTS,
        method: "get",
        params: {
          ...form.getFieldsValue(),
          status: ["using"],
          year_month_payment: month.format("YYYY/MM"),
        },
      });
      setData(res.data.patients);
      setTotal(res.data.total_items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [month, isActive]);
  useLayoutEffect(() => {
    if (pdfData) setCapturing(true);
  }, [pdfData]);
  useLayoutEffect(() => {
    if (!isCapturing) setPdfData(undefined);
  }, [isCapturing]);

  return (
    <Form form={form} onFinish={fetchData}>
      <SelectTime
        type="month"
        format={MONTH_FORMAT}
        value={month}
        onChange={setMonth}
      />
      <div className="py-[16px] px-[25px] bg-[#F5F4F2] mt-[24px]">
        <p className="text-[#000000] text-[12px] leading-[20px]">キーワード</p>
        <div className="flex gap-x-[21px] items-center">
          <Form.Item name="fullname" className="w-full mb-0">
            <InputStyled
              className="flex items-center customInp border-[#707070] focus:!text-transparent"
              placeholder="氏名、カナ"
              prefix={
                <SearchOutlined className="text-[16px] text-[#2699FB] mr-[3px]" />
              }
            />
          </Form.Item>
          <ButtonOutline
            type="submit"
            onClick={() => {
              form.resetFields(["page"]);
            }}
            className=" !px-[63px] !bg-[#FFFFFF] !max-h-[25px]"
            _hover={{
              color: "brand.500]",
              bg: "brand.100",
            }}
          >
            検索
          </ButtonOutline>
        </div>
      </div>
      <EnhanceTable
        data={data}
        columns={columns}
        total={total}
        loading={loading}
        form={form}
      />
      <div ref={ref} hidden={!isCapturing}>
        {renderPDF}
      </div>
    </Form>
  );
}
