import { put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { setIsDeleted } from "store/slice/hsAdminNoticeSlice";

export const setIsDeletedAction = createAction("hs-admin/is-deleted");

function* setIsDeletedView(action: any) {
  yield put(setIsDeleted(action.payload));
}

function* hsAdminNoticeSaga() {
  yield takeLatest(setIsDeletedAction, setIsDeletedView);
}

export default hsAdminNoticeSaga;
