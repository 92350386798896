import { Box, Table, TableContainer, Text, Tr, calc } from "@chakra-ui/react";
import { Col, Popover, Row } from "antd";
import { FlexRow, StyleTd } from "component/StyledComponent";
import "./scroll.scss";
import { getCookie } from "utils";
import { useEffect, useState } from "react";
import moment from "moment";
import staffApi from "api/staffApi";
import { DATE_FORMAT_2 } from "constant/date";

const dataHour = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const dataShift = {
  timeStart: 10,
  timeEnd: 16,
  shiftStart: "07:00",
  shiftEnd: "16:00",
  action: [
    {
      name: "口腔ケア・排泄介助",
      staff: "加藤太",
      timeStart: 11,
      timeEnd: 12,
    },
    // {
    //   name: "入浴",
    //   staff: "井上孝代",
    //   timeStart: 10,
    //   timeEnd: 12,
    // },
    // {
    //   name: "入浴3",
    //   staff: "井上孝代",
    //   timeStart: 10,
    //   timeEnd: 13,
    // },
    // {
    //   name: "入浴2",
    //   staff: "井上孝代",
    //   timeStart: 10,
    //   timeEnd: 16,
    // },
    // {
    //   name: "入浴1",
    //   staff: "井上孝代",
    //   timeStart: 10,
    //   timeEnd: 12,
    // },
  ],
};


interface ITableProps {
  classNameTable1?: string;
  classNameTable2?: string;
  classNameTd?: string;
  classNameContainer?: string;
  classNameBoxTitle?: string;
  classNameShift?: string;
  classNameBoxEmpty?: string;
  date?: any
}

const TableTimeSchedule = ({
  classNameTable1 = "",
  classNameTable2 = "",
  classNameTd = "",
  classNameContainer = "",
  classNameBoxTitle = "",
  classNameShift = "",
  classNameBoxEmpty = "",
  date
}: ITableProps) => {

  const idToken = getCookie("access_token")
  const [daysData, setDaysData] = useState([])
  const getMonthSchedule = async () => {

    try {
      const param = {
        specific_date: moment(date).format(DATE_FORMAT_2),
      }

      const res = await staffApi.getStaffVisitSchedule(idToken, param)
      if (res.data.data && res.data.data.length > 0) {
        // console.log('data', res.data.data)
        setDaysData(res.data.data)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getMonthSchedule()
  }, [date])

  const getShiftLength = (item: any) => {
    if (Object.keys(item).length > 0) {
      // console.log('Object.keys(item)', Object.keys(item))
      return Object.keys(item).length
    }
    // console.log('item', item)
    return 1

  }

  const convertHours = (hour: any, date: any) => {
    // console.log('hour', hour)
    // if (hour === "16:30") {
    //   let dateTmp2 = new Date(`${date} ${hour}`)
    //   console.log('hour', dateTmp2.getHours())

    // }
    let dateTmp = new Date(`${date} ${hour}`)
    if (dateTmp.getHours() === 0 && hour === "24:00") {
      return 24
    }
    return dateTmp.getHours()
  }
  return (
    <>
      <Row>
        {/* <Col className="patient-title " style={{ height: "50px", background: 'f5f4f2', border: "1px solid #000", display: 'flex' }} span={2}>
          <Box margin='auto' textAlign="center" fontWeight='bold' >介護スタッフ</Box>
        </Col> */}
        {/* <Col span={4}>
          <Box />
        </Col>
        <Col className="patient-scroll-time" span={18}></Col> */}
      </Row>

      <Row>

        <Col className="patient-title" span={2}>
          <div style={{ height: "100%" }} >
            <Box height="67px" margin='auto' display="flex" textAlign="center" fontWeight='bold' border="1px solid #000" >
              <Text margin='auto' >介護スタッフ</Text>
            </Box>
            {daysData && daysData?.map((item: any, index) =>
              <Box
                bg={index === 0 ? "brand.500" : "white"}
                display="flex"
                fontWeight="bold"
                fontSize="11px"
                p="20px 0px"
                border="1px solid #000"
                height={`${(88 * getShiftLength(item?.shifts)) + 30}px`}

                textAlign="center"
              >
                <Text margin="auto" fontSize="16px" fontWeight="bold">{item.family_name}</Text>
              </Box>
            )}
          </div>

        </Col>
        <Col className="patient" span={4}>
          <Box height="67px"
          // borderTop="1px solid #ececec"
          />
          {daysData &&
            daysData.map((item: any, index: any) => (
              <>

                <Box height="30px" borderTop="1px solid #e0e0e0" />
                {item?.shifts && (Object.keys(item?.shifts).length > 0) ?
                  Object.entries(item?.shifts).map(([key, value]) => (
                    <Box borderTop={`${index === 0 ? '1px solid #e0e0e0' : 'none'}`}
                      borderBottom="1px solid #e0e0e0" display="flex" className="type-service" style={{ height: '88px' }} key={index}>
                      <Text ml={3} my="auto" >{key}</Text>
                    </Box>
                  )) :
                  <Box borderTop={`${index === 0 ? '1px solid #e0e0e0' : 'none'}`}
                    borderBottom="1px solid #e0e0e0" display="flex" className="type-service" style={{ height: '88px' }} key={index} />}
              </>

            ))}
        </Col>
        <Col span={18}
        // style={{ overflowX: "scroll", transform: "rotateX(180deg)" }} 
        >
          <TableContainer
            overflowX="scroll" transform="rotateX(180deg)"
          >
            <Table width={`${70 * 23}px`} transform="rotateX(180deg)" >
              {/* display 24 hours */}
              <Tr height="58px">
                {dataHour.map((item: any, index: any) => (
                  <StyleTd
                    position="relative"
                    zIndex="9999"
                    padding="0px"
                    key={index}
                    style={{
                      border: "0px",
                      height: "50px",
                      width: "70px !important",
                    }}
                    fontSize="12px"
                  >
                    <Text
                      className="text-left"
                      zIndex="9999"
                      position="absolute"
                      left="-7px"
                    >
                      {item}
                    </Text>
                  </StyleTd>
                ))}
              </Tr>
              {daysData && daysData.map((itemDate: any, index) => (
                <>
                  {/* display time range shift */}
                  <Tr borderTop="1px solid #e0e0e0">
                    {dataHour.map((item: any, indexHours: any) => (
                      <StyleTd
                        padding="0px"
                        position="relative"
                        key={indexHours}
                        style={{
                          border: "0px",
                          height: "30px",
                          maxWidth: "70px",
                          // overflowX:"hidden"
                        }}
                        fontSize="12px"
                      >
                        {itemDate && itemDate.shift_managements && itemDate.shift_managements.map((range: any, rangeHour: Number) => (
                          <Box
                            pb="5px"
                            height="25px"
                            width="69px"
                            position="absolute"
                            bottom="0px"
                            style={{
                              right: `${convertHours(range.start_time, range.shift_date) < indexHours && "0.5px"}`,
                            }}
                            bg={`${indexHours >= convertHours(range.start_time, range.shift_date) &&
                              indexHours < convertHours(range.end_time, range.shift_date) &&
                              "#f6d7c0"
                              }`}
                          >
                            {convertHours(range.start_time, range.shift_date) === indexHours && (
                              <Text width="70px" fontSize="12px">
                                {range.start_time}{" "}
                                <span className="text-xl">~</span>
                              </Text>
                            )}
                            {convertHours(range.start_time, range.shift_date) === indexHours - 1 && (
                              <Text
                                position="absolute"
                                left="-5px"
                                mt="7px"
                                textAlign="left"
                                width="70px"
                                fontSize="12px"
                              >
                                {range.end_time}
                              </Text>
                            )}
                          </Box>
                        ))}
                      </StyleTd>
                    ))}
                  </Tr>

                  {itemDate?.shifts && (Object.keys(itemDate?.shifts).length > 0) ?
                    Object.entries(itemDate?.shifts).map(([key, value]: any) => (
                      <Tr key={index}>
                        {dataHour.map((hour: any, indexHour: any) => (
                          <StyleTd
                            padding="0px"
                            position="relative"
                            key={indexHour}
                            style={{
                              border: "1px solid #e0e0e0",
                              height: "88px",
                              width: "70px",
                            }}
                          // bg={`${indexHour >= dataShift.timeStart &&
                          //   indexHour < dataShift.timeEnd &&
                          //   "#f6d7c0"
                          //   }`}
                          >
                            {itemDate && itemDate.shift_managements && itemDate.shift_managements.map((range: any, rangeHour: Number) => (
                              <Box style={{
                                background: `${indexHour >= convertHours(range.start_time, range.shift_date) &&
                                  indexHour < convertHours(range.end_time, range.shift_date) &&
                                  "#f6d7c0"
                                  }`, width: "100%", height: "100%",
                                position: "absolute",
                                top: "0px"
                              }}>
                                {value && value.map((patient: any, indexPatient: any) => (
                                  <Popover content={
                                    <div>
                                      <p>{`${patient.patient_name} ${patient.time_range}`}</p>
                                      <p>排泄介助・体位交換</p>
                                      <p>担当：{itemDate?.family_name}</p>
                                    </div>
                                  } title="">
                                    {/* {itemDate.family_name} */}
                                    {(convertHours(patient?.schedule_date?.start_time, patient?.schedule_date?.date) === indexHour
                                      && indexHour === (convertHours(patient?.schedule_date?.end_time, patient?.schedule_date?.date) - 1)) && (

                                        <Box
                                          // width="35px"
                                          height="98%"
                                          bg="white"
                                          border="2px solid var(--primary-color)"
                                          display='flex'
                                          overflowY="hidden"
                                          textOverflow="ellipsis"
                                        >
                                          <Text
                                            paddingX="8px"
                                            lineHeight="13px"
                                            textAlign="center"
                                            fontSize="12px"
                                            whiteSpace="pre-wrap"
                                            marginX='auto'
                                            mt="5px"
                                            paddingBottom="5px"
                                          >
                                            {" "}
                                            {patient.patient_name.replace(/(.{1})/g, "$1\n")}
                                          </Text>{" "}
                                        </Box>
                                      )}

                                    {(convertHours(patient?.schedule_date?.start_time, patient?.schedule_date?.date) === indexHour &&
                                      indexHour < (convertHours(patient?.schedule_date?.end_time, patient?.schedule_date?.date) - 1)) && (
                                        <Box
                                          height="98%"
                                          bg="white"
                                          borderLeft="2px solid var(--primary-color)"
                                          borderTop="2px solid var(--primary-color)"
                                          borderBottom="2px solid var(--primary-color)"
                                          display='flex'
                                          overflowY="hidden"
                                          textOverflow="ellipsis"
                                          paddingBottom="5px"
                                        >
                                          <Text
                                            paddingX="8px"
                                            lineHeight="13px"
                                            textAlign="center"
                                            fontSize="12px"
                                            whiteSpace="pre-wrap"
                                            marginX='auto'
                                            mt="5px"
                                          >
                                            {" "}
                                            {patient.patient_name.replace(/(.{1})/g, "$1\n")}
                                          </Text>{" "}
                                        </Box>

                                      )}
                                    {(indexHour === convertHours(patient?.schedule_date?.end_time, patient?.schedule_date?.date) - 1 &&
                                      (convertHours(patient?.schedule_date?.end_time, patient?.schedule_date?.date) - 1 !==
                                        convertHours(patient?.schedule_date?.start_time, patient?.schedule_date?.date))) && (
                                        <Box

                                          height="98%"
                                          bg="white"
                                          borderRight="2px solid var(--primary-color)"
                                          borderTop="2px solid var(--primary-color)"
                                          borderBottom="2px solid var(--primary-color)"
                                          display='flex'
                                          marginLeft="-2px"
                                        />
                                      )}

                                    {(indexHour > convertHours(patient?.schedule_date?.start_time, patient?.schedule_date?.date) && 
                                    (indexHour < convertHours(patient?.schedule_date?.end_time, patient?.schedule_date?.date) - 1)) && (
                                      <Box

                                        height="98%"
                                        bg="white"
                                        borderTop="2px solid var(--primary-color)"
                                        borderBottom="2px solid var(--primary-color)"
                                        display='flex'
                                        marginLeft="-2px"
                                      />
                                    )}
                                  </Popover>
                                ))}




                              </Box>
                            ))}


                          </StyleTd>
                        ))}
                      </Tr>
                    ))
                    :
                    <Tr>
                      {dataHour.map((hour: any, indexHour: any) => (
                        <StyleTd
                          padding="0px"
                          position="relative"
                          key={indexHour}
                          style={{
                            border: "1px solid #e0e0e0",
                            height: "88px",
                            width: "70px",
                          }}
                        // bg={`${indexHour >= dataShift.timeStart &&
                        //   indexHour < dataShift.timeEnd &&
                        //   "#f6d7c0"
                        //   }`}
                        >
                          {itemDate && itemDate.shift_managements && itemDate.shift_managements.map((range: any, rangeHour: Number) => (
                            <Box style={{
                              background: `${indexHour >= convertHours(range.start_time, range.shift_date) &&
                                indexHour < convertHours(range.end_time, range.shift_date) &&
                                "#f6d7c0"
                                }`, width: "100%", height: "100%",
                              position: "absolute",
                              top: "0px"
                            }}/>
                          ))}


                        </StyleTd>
                      ))}
                    </Tr>
                  }
                </>
              ))}

            </Table>
          </TableContainer>
        </Col>
      </Row>
    </>
  );
};

export default TableTimeSchedule;
