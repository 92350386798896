import apiFactory from "api";
import DateChanger from "../components/DateChanger";
import dayjs, { Dayjs } from "dayjs";
import ROUTES from "constant/routes";
import usePatientSchedules from "hook/usePatientSchedules";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { DATE_FORMAT, DATE_FORMAT_2 } from "constant/date";
import { Fragment, useEffect, useRef, useState } from "react";
import { toNumber } from "lodash";
import { PatientSchedule, Schedule } from "types/admin";
import { Flex, Spinner } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import ScheduleCard, { CARD_HEIGHT, GAP_Y } from "component/ScheduleCard";
import { shiftOptions } from "page/Customer/CustomerDetail/CarePlan/constants";

type ShiftManagementData = { patient_schedules: PatientSchedule[] };

function ShiftManagement() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState<Dayjs>();
  const navigate = useNavigate();
  const location = useLocation();
  const refGetScrollTop = useRef<HTMLDivElement>(null);
  const refIdTimeout = useRef<NodeJS.Timeout>();
  const keyScrollTop = "scrollTop"

  const { data, isFetching } = useQuery(
    ["shift-management", currentDate],
    () =>
      apiFactory.adminShiftManagement.getList({
        date: (currentDate as Dayjs)?.format(DATE_FORMAT),
      }),
    {
      enabled: !!currentDate,
    }
  );
  const { patientSchedules, scheduleTimes } = usePatientSchedules({
    patientSchedules: (data as ShiftManagementData)?.patient_schedules,
  });

  const handleChangeCurrentDate = (date: Dayjs) => {
    setCurrentDate(date);
    navigate({
      pathname: window.location.pathname,
      search: createSearchParams({
        currentDate: date.format(DATE_FORMAT),
      }).toString(),
    });
  };

  const handleRouteToNursingCareRecord = (schedule: Schedule) => () => {
    if (!schedule?.patient_id) {
      return;
    }

    const params = {
      date: (currentDate as Dayjs).format(DATE_FORMAT_2),
      start_time:
        schedule?.history_start_time_format ?? schedule?.start_time_format,
      end_time: schedule?.history_end_time_format ?? schedule?.end_time_format,
      schedule_date_id: schedule?.schedule_date_id,
      scheduleable: `${schedule?.scheduleable?.id}`,
      type: "edit",
      division: "disability",
      patient: schedule?.patient_name,
      redirectPath: location.pathname + location.search,
      seriveName: schedule?.service_name,
      isHistory: String(schedule?.is_history),
    };

    navigate({
      pathname: `/${ROUTES.ADMIN}/${ROUTES.SHIFT}/${ROUTES.SHIFT_MANAGEMENT}/${schedule?.patient_id}`,
      search: createSearchParams(params).toString(),
    });
  };

  useEffect(() => {
    const newDate = dayjs(searchParams.get("currentDate")).isValid()
      ? dayjs(searchParams.get("currentDate"))
      : dayjs();
    setCurrentDate(newDate);
  }, [searchParams.get("currentDate")]);

  useEffect(
   () => {
      const scrollTop = toNumber(searchParams.get(keyScrollTop));
      if (
        scrollTop &&
        refGetScrollTop.current 
      ) {
        refGetScrollTop.current.scrollTo({ top: scrollTop });
      }
  });

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const scrollTop = target.scrollTop + "";
    clearTimeout(refIdTimeout.current);
    refIdTimeout.current = setTimeout(() => {
      if (target.scrollHeight > target.clientHeight) {
        searchParams.set(keyScrollTop, scrollTop);
        setSearchParams(searchParams);
      }
    }, 100);
  };

  return (
    <Fragment>
      <p className="mb-[22px] mt-[10px]">実際登録</p>
      <div
        className="bg-white  overflow-x-auto  max-h-[calc(100vh-160px)] pb-[20px] 101010"
        ref={refGetScrollTop}
        onScroll={handleScroll}
      >
        <div className="bg-[white] sticky top-0 z-20 min-w-[1350px]">
          <DateChanger
            className="min-w-[1350px]"
            onChange={handleChangeCurrentDate}
          />

          <div className="pl-[90px] py-[20px] w-full grid grid-cols-[auto_auto_auto_auto_auto_auto_auto_auto_auto] bg-[#ffefe0] mt-[12px]">
            {shiftOptions?.map(({ label, value }) => {
              return (
                <div
                  key={value}
                  className="text-[14px] w-full min-w-[140px] h-[20px] border-r border-r-[#d9d9d9] flex items-center justify-center text-[#EA7E2C]"
                >
                  {label}
                </div>
              );
            })}
          </div>
        </div>

        {isFetching ? (
          <div className="w-full flex justify-center pt-[20px]">
            <Spinner color="#4759ff" />
          </div>
        ) : (
          <div className="flex mt-[20px]">
            <div className="min-w-[90px] flex flex-col gap-[10px] sticky left-0 z-10 bg-white pl-[10px]">
              {scheduleTimes?.map((item, index) => (
                <div
                  key={index}
                  className="text-[14px] h-[90px] w-[70px] flex justify-center items-center border border-[#d9d9d9] rounded-md p-[10px] sticky left-0 bg-white"
                >
                  {item}
                </div>
              ))}
            </div>

            <div className="grid grid-cols-[auto_auto_auto_auto_auto_auto_auto_auto_auto] pb-[10px] w-full">
              {patientSchedules?.map((schedules) => {
                return (
                  <div className="flex flex-col w-full relative min-w-[140px]">
                    {schedules?.map((schedule, scheduleIndex) => {
                      return (
                        <ScheduleCard
                          key={scheduleIndex}
                          name={schedule?.patient_name}
                          staffName={schedule?.nursing_staff?.family_name}
                          serviceName={schedule?.service_name}
                          onClick={handleRouteToNursingCareRecord(schedule)}
                          isHistory={schedule?.is_history}
                          numOfSections={schedule?.numOfSections}
                          style={{
                            top: schedule.offset * (CARD_HEIGHT + GAP_Y),
                          }}
                          className={`absolute left-0`}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default ShiftManagement;
