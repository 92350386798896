import { put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { createAction } from "@reduxjs/toolkit";
import { setIsDeleted } from 'store/slice/noticeSlice';

  
  export const setIsDeletedAction = createAction(
    "admin/is-deleted"
  );
  
  function* setIsDeletedView(action: any) {        
    yield put(setIsDeleted(action.payload));
  }
  
  function* noticeSaga() {
    yield takeLatest(setIsDeletedAction, setIsDeletedView);
    
  }
  export default noticeSaga;
  