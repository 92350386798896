import { Tabs } from "antd";
import TableRecordDailyMaster from "component/table/TableRecordDailyMaster";
import AdminLayout from "layouts/AdminLayout";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import TableHistoryStaff from "component/table/tableStaff/TableHistoryStaff";

const RecordDailyMaster = () => {
  const location = useLocation();
  const paramTab: any = queryString.parse(location.search)
  const items = [
    {
      key: "1",
      label: (
        <div className="flex customLabel">
          <div>お客様</div>
          {/* <div className="border-r-2 border-solid border-[#E0E0E0] ml-[14px]"></div> */}
        </div>
      ),
      children: <TableRecordDailyMaster />,
    },
    {
      key: "2",
      label: (
        <div className="flex customLabel">
          <div>介護スタッフ</div>
          {/* <div className="border-r-2 border-solid border-[#E0E0E0] ml-[14px]"></div> */}
        </div>
      ),
      children: <TableHistoryStaff />,
    },
  ];
  return (
    // <div className='text-left'  >
    //   <div className='flex flex-row w-full'  style={{ minWidth: '228px !important' }}>
    //     <Sidebar />
    //     <div style={{ width: '100%', background: 'var(--secondary-color)' }} >
    //       <Header title="" isMenu isMaster />
    //       <div className='mx-5' >
    //         <BreadCrumStyled title="介護記録・履歴" />
    //         {/* <p style={{ fontSize: '24px', marginTop: '50px' }} >介護記録・履歴　{location.state ? moment(location.state).format('YYYY/MM/DD') : '2023/06/06'}</p> */}

    //         <TableRecordDailyMaster />
    //       </div>
    //     </div>
    //   </div>

    // </div>
    <div>
      <div className="page-title">介護記録・履歴　{paramTab.date}</div>
      <div className="page-container">
        <Tabs defaultActiveKey={paramTab.tab} items={items} />
      </div>
    </div>
  );
};

export default RecordDailyMaster;
