// page 57, 58, 59 https://xd.adobe.com/view/ef189247-c8fd-4c31-b643-d4151912ea88-ba4b/screen/e3e5f7bf-e88b-4d3d-b07b-741ff9c0a215/specs/

import { Checkbox, Form, Modal, Popover, Select, Spin, Table } from "antd";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import dayjs from "dayjs";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Styles from "./styled";
import { useState, useEffect } from "react";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useWatch } from "antd/es/form/Form";
import { toast } from "react-toastify";
import { fillDateArray } from "./function";
import { IPatient } from "constant/interfaces";
import ROUTES from "constant/routes";

export type TUsage = "equipment" | "service" | "potoro";
interface IPopupState {
  rowIndex?: number | undefined;
  colIndex?: number | undefined;
  change?: number;
}
export default function UsageHistory() {
  const service = useService();
  const navigate = useNavigate();
  const [popupState, setPopupState] = useState<IPopupState>();
  const [patientData, setPatientData] = useState<IPatient>();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [isAllowUpdate, setIsAllowUpdate] = useState(true);
  const equipment_services = useWatch("equipment_services", form);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const month = dayjs(searchParams.get("month") || Date.now());
  const patient_id = searchParams.get("patient_id");
  const type: TUsage = (() => {
    const type = searchParams.get("type") as TUsage;
    if (["equipment", "service", "potoro"].includes(type)) return type;
    return "equipment";
  })();
  const renderTitle = () => {
    let jpTypeText = "";
    switch (type) {
      case "equipment":
        jpTypeText = "備品利用";
        break;
      case "service":
        jpTypeText = "サービス利用";
        break;
      case "potoro":
        jpTypeText = "ぽとろ利用";
        break;
      default:
        jpTypeText = "備品利用";
        break;
    }
    return (
      jpTypeText +
      month.format(" YYYY/MM ") +
      (patientData?.family_name || "") +
      " 様"
    );
  };
  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosClient.get(service.EQUIPMENT_SERVICES, {
        params: {
          year_month: month.format("YYYY/MM"),
          category: type,
          patient_id: patient_id,
        },
      });
      form.setFieldValue(
        "equipment_services",
        data.serialized_equipment_services.map((i: any) => ({
          ...i,
          unit_price: i.equipment_service_payments?.unit_price || i.unit_price,
          equipment_service_usages:
            i.equipment_service_payments &&
            fillDateArray(
              i.equipment_service_payments?.equipment_service_usages,
              month
            ),
        }))
      );
      form.setFieldValue("registered", data.registered);
      setPatientData(data.patient);
      setIsAllowUpdate(!data.registered);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const handleSubmit = async (formData: any) => {
    try {
      const res = await axiosClient.post(service.EQUIPMENT_SERVICE_PAYMENTS, {
        patient_id,
        registered: formData.registered,
        year_month: month.format("YYYY/MM"),
        category: type,
        equipment_service_payments: equipment_services
          .filter(
            (i: any) =>
              i.equipment_service_usages?.filter(
                (subItem: any) => subItem?.change
              )?.length
          )
          .map((i: any) => ({
            equipment_service_id: i.id,
            unit_price: i.unit_price,
            equipment_service_usages: i.equipment_service_usages?.filter(
              (subItem: any) => subItem?.change
            ),
          })),
      });
      toast.success(res.data.success);
      navigate(`/${ROUTES.ADMIN}/${ROUTES.PAYMENT}`);
    } catch (error) {
      toast.error((error as string).toString());
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, []);
  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={handleSubmit}>
        <Styles>
          <div className="page-title">{renderTitle()}</div>
          <div className="page-container">
            <Form.List name="equipment_services">
              {(field) => (
                <Table
                  rowKey={(row) => row.id}
                  dataSource={equipment_services}
                  bordered
                  scroll={{ x: true }}
                  pagination={false}
                >
                  <Column
                    title="項目名"
                    dataIndex="service_name"
                    className="font-[600]"
                  />
                  {[...Array(month.daysInMonth())].map((item, index) => (
                    <ColumnGroup
                      className="col-date"
                      title={index + 1}
                      align="center"
                      key={index}
                    >
                      <Column
                        className="col-date"
                        align="center"
                        title={month
                          .locale("ja")
                          .date(index + 1)
                          .format("ddd")}
                        render={(value, record: any, rowIndex) => {
                          const cellValue =
                            equipment_services?.[rowIndex]
                              .equipment_service_usages &&
                            (equipment_services[rowIndex]
                              .equipment_service_usages[index]?.quantity || 0) +
                              (equipment_services[rowIndex]
                                .equipment_service_usages[index]?.change || 0);
                          return (
                            <Popover
                              trigger="click"
                              onOpenChange={(value) =>
                                !value && setPopupState(undefined)
                              }
                              open={
                                isAllowUpdate &&
                                popupState?.rowIndex === rowIndex &&
                                popupState.colIndex === index
                              }
                              content={
                                <div>
                                  <p className="font-bold whitespace-nowrap mb-2">
                                    {record.service_name}
                                  </p>
                                  <Select
                                    value={0}
                                    onChange={(value) => {
                                      setPopupState(undefined);
                                      form.setFieldValue(
                                        [
                                          "equipment_services",
                                          rowIndex,
                                          "equipment_service_usages",
                                          index,
                                        ],
                                        {
                                          ...equipment_services[rowIndex]
                                            ?.equipment_service_usages?.[index],
                                          change:
                                            (equipment_services[rowIndex]
                                              ?.equipment_service_usages?.[
                                              index
                                            ]?.change || 0) + value,
                                          date: dayjs(
                                            month.date(index + 1)
                                          ).format("YYYY-MM-DD"),
                                        }
                                      );
                                    }}
                                    className="w-[70px]"
                                    options={[...Array(10)].map((i, idx) => ({
                                      label: idx + 1,
                                      value: idx + 1,
                                    }))}
                                  />
                                </div>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                  setPopupState({
                                    rowIndex: rowIndex,
                                    colIndex: index,
                                    change: 1,
                                  });
                                }}
                              >
                                {cellValue || <br />}
                              </div>
                            </Popover>
                          );
                        }}
                      />
                    </ColumnGroup>
                  ))}
                  <Column align="center" title="単価" dataIndex="unit_price" />
                  <Column
                    align="center"
                    title="利用数"
                    render={(value, record, index) =>
                      equipment_services[
                        index
                      ].equipment_service_usages?.reduce(
                        (c: any, n: any) =>
                          c + (n?.change || 0) + (n?.quantity || 0),
                        0
                      ) || ""
                    }
                  />
                  <Column
                    align="center"
                    title="在庫数"
                    render={(value, record: any, index) => {
                      const cellvalue =
                        record.stock_quantity === "管理しない"
                          ? "管理しない"
                          : record.stock_quantity -
                            (equipment_services[
                              index
                            ].equipment_service_usages?.reduce(
                              (c: number, n: any) => +c + (n?.change || 0),
                              0
                            ) || 0);
                      return (
                        <div
                          style={{
                            color:
                              cellvalue === "管理しない"
                                ? ""
                                : cellvalue >= 0
                                ? ""
                                : "red",
                          }}
                        >
                          {cellvalue}
                        </div>
                      );
                    }}
                  />
                  <Column
                    align="center"
                    title="合計"
                    render={(value, record: any, index) =>
                      (equipment_services[
                        index
                      ].equipment_service_usages?.reduce(
                        (c: any, n: any) =>
                          c + (n?.change || 0) + (n?.quantity || 0),
                        0
                      ) || 0) * record.unit_price
                    }
                  />
                </Table>
              )}
            </Form.List>
            <div className="text-right mt-10">
              <Form.Item
                hidden={!isAllowUpdate}
                noStyle
                name="registered"
                valuePropName="checked"
                initialValue={false}
              >
                <Checkbox>「登録済」にする</Checkbox>
              </Form.Item>
            </div>
            <div className="flex justify-end gap-3 mt-3">
              <ButtonOutline onClick={() => navigate(-1)}>
                キャンセル
              </ButtonOutline>
              <ButtonSolid
                hidden={!isAllowUpdate}
                w={137}
                onClick={form.submit}
              >
                保存
              </ButtonSolid>
            </div>
          </div>
        </Styles>
      </Form>
    </Spin>
  );
}
