import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TabPanel } from "@chakra-ui/react";
import { ButtonOutline, ButtonSolid } from "component/button";
import "./styles.scss";
import TableWeek from "./TableWeek";
import apiFactory from "api";
import { DATE_FORMAT_2 } from "constant/date";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import Prompt from "./promt";
import { toast } from "react-toastify";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

interface IWeek {
  startWeek: Dayjs;
  title: string;
  type: string;
  createShift: any;
  openRegisterShift: boolean;
  initial: boolean;
  setInitial: any;
  setShowRegisterButtons: Dispatch<SetStateAction<boolean>>;
  isShiftEditedState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const Week = ({
  startWeek,
  title,
  type,
  createShift,
  openRegisterShift,
  initial,
  setInitial,
  setShowRegisterButtons,
  isShiftEditedState,
}: IWeek) => {
  const [dataWeek, setDataWeek] = useState<any>([]);
  const [isShiftEdited, setIsShiftEdited] = isShiftEditedState;

  // useEffect(() => {
  //   window.addEventListener("beforeunload", function (e) {
  //     e.preventDefault();
  //     e.returnValue = '';
  //     console.log('beforeunload')
  // })
  // }, [])

  //handle API
  const fetchWeekSchedule = async () => {
    try {
      const res = await apiFactory.shiftManagementApi.getWeekSchedule({
        week_start_date: startWeek.format(DATE_FORMAT_2),
        week_end_date: startWeek.endOf("week").format(DATE_FORMAT_2),
        tab: type,
      });

      if (!res) {
        setInitial(false);
        return;
      }
      setInitial(res.has_initial);
      const mappingData = Object.values(res.data).map((item: any) => {
        const shifts = item.shifts;
        let newShift: any[] = [];

        for (let i = 0; i < 7; ++i) {
          const dayOfWeek = startWeek.add(i, "day").format(DATE_FORMAT_2);
          if (!shifts[dayOfWeek]) {
            newShift = [...newShift, []];
          } else {
            let shift: any = [];
            if (type === "staff") {
              shifts[dayOfWeek].forEach((item: any, index: any) => {
                shift.push({
                  nurse_name: item.patient_name,
                  time_range: item.time_range,
                  service_name: item.service_name,
                  bgColor: "#1AD633",
                });
              });
            } else {
              shifts[dayOfWeek].forEach((item: any, index: any) => {
                shift.push({
                  nurse_name: item.nurse_name,
                  time_range: item.time_range,
                  service_name: item.service_name,
                });
              });
            }

            newShift = [...newShift, shift];
          }
        }

        return {
          patient_name: item.family_name,
          shifts: newShift,
        };
      });
      setDataWeek(mappingData);
    } catch (error) {}
  };
  //end handle API

  //handle save or unsave draft

  const saveChange = async (type_button: string) => {
    try {
      if (type === "staff") {
        const res = await apiFactory.shiftManagementApi.saveWeekScheduleStaff({
          button_type: type_button,
        });
        if (res) {
          if (type_button === "draft") {
            toast.success(res.success);
          }
          fetchWeekSchedule();
        }
      } else {
        const res = await apiFactory.shiftManagementApi.saveWeekSchedule({
          button_type: type_button,
        });
        if (res) {
          toast.success(res.success);
          fetchWeekSchedule();
        }
      }
    } catch (error: any) {
      toast.success(error.message.messages);
    } finally {
      setIsShiftEdited(false);
    }
  };
  useEffect(() => {
    fetchWeekSchedule();
  }, [startWeek, createShift, openRegisterShift]);
  const handleMockClientData = () => {
    const mockData = [
      {
        patient_name: "井上 隆代",
        shifts: [
          [
            {
              nurse_name: "介護スタッフ名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
            {
              nurse_name: "介護スタッフ名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
        ],
      },
      {
        patient_name: "加藤 太",
        shifts: [
          [
            {
              nurse_name: "介護スタッフ名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
            {
              nurse_name: "介護スタッフ名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
          [
            {
              nurse_name: "",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#FFFFFF",
            },
          ],
        ],
      },
    ];
    // setDataWeek(mockData);
  };

  const handleMockStaffData = () => {
    const mockData = [
      {
        patient_name: "井上 隆代",
        shifts: [
          [
            {
              nurse_name: "お客様名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [],
          [
            {
              nurse_name: "お客様名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
            {
              nurse_name: "お客様名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [],
          [
            {
              nurse_name: "お客様名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [],
          [],
        ],
      },
      {
        patient_name: "加藤 太",
        shifts: [
          [
            {
              nurse_name: "お客様名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [],
          [],
          [],
          [
            {
              nurse_name: "お客様名",
              time_slot: "04:00〜04:30",
              service_name: "排泄介助・体位交換",
              bgColor: "#1AD633",
            },
          ],
          [],
          [],
        ],
      },
    ];
    // setDataWeek(mockData);
  };

  useEffect(() => {
    fetchWeekSchedule();
    if (type === "patient") {
      handleMockClientData();
    } else if (type === "staff") {
      handleMockStaffData();
    }
  }, [startWeek, type, openRegisterShift]);

  // useEffect(() => {
  //   return (() => {
  //     fetchWeekSchedule()
  //     if (initial) {
  //       let confirm = window.confirm("Are you sure you want to leave?")
  //       if (confirm) {
  //         setInitial(false)
  //         saveChange("cancel")
  //       } else {
  //         if (type === "staff") {
  //           setIsChange(2)
  //           return
  //         }
  //       }
  //     }
  //   })
  // }, [initial])
  useEffect(() => {
    if (dataWeek) {
      if (dataWeek.length > 0) {
        setShowRegisterButtons(true);
      } else {
        setShowRegisterButtons(false);
      }
    }
  }, [dataWeek])
  return (
    <>
      <TableWeek
        title={title}
        data={dataWeek}
        weekStart={startWeek.toDate()}
        weekEnd={startWeek.endOf("week").toDate()}
      />
      {type && (
        <Prompt
          when={isShiftEdited}
          message="Are you sure you want to leave?"
          change={saveChange}
          setInitial={setIsShiftEdited}
        />
      )}
      {isShiftEdited && (
        <div className="flex justify-end gap-x-[8px] mt-[42px] pb-[50px]">
          <ButtonOutline
            onClick={() => saveChange("cancel")}
            className="!text-[14px] leading-[24px] !px-[16px] !max-h-[35px]"
          >
            キャンセル
          </ButtonOutline>
          <ButtonSolid
            onClick={() => saveChange("draft")}
            className="!text-[14px] leading-[24px] !px-[34px] !max-h-[35px]"
          >
            下書き保存
          </ButtonSolid>
        </div>
      )}
    </>
  );
};

export default Week;
