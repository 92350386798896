import { useEffect, useLayoutEffect, useRef } from "react";

const useSelectArea = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const selectionRef = useRef<HTMLDivElement>(document.createElement("div"));
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.style.userSelect = "none";
    containerRef.current.onmousedown = (e) => {
      containerRef.current?.dispatchEvent(onAreaRemove);
      Array.prototype.forEach.call(
        containerRef.current?.getElementsByClassName("selectable"),
        (el: Element) => {
          el.classList.remove("selected");
        }
      );
      let startX = e.clientX;
      let startY = e.clientY;
      selectionRef.current.style.display = "";
      selectionRef.current.style.left = startX + "px";
      selectionRef.current.style.top = startY + "px";
      const handleMouseMove = (e: MouseEvent) => {
        // tạo width height cho area selection
        if (e.clientX - startX <= 0)
          selectionRef.current.style.left = e.clientX + "px";
        selectionRef.current.style.width = Math.abs(e.clientX - startX) + "px";
        if (e.clientY - startY <= 0)
          selectionRef.current.style.top = e.clientY + "px";
        selectionRef.current.style.height = Math.abs(e.clientY - startY) + "px";
        //
        let array: Element[] = [];
        if (containerRef.current) {
          array = [
            ...(containerRef.current.getElementsByClassName(
              "selectable"
            ) as any),
          ];
        }
        array.forEach((el) => {
          const domRect1 = el.getBoundingClientRect();
          const domRect2 = selectionRef.current.getBoundingClientRect();
          // nếu area select overlap với element
          if (
            !(
              domRect1.top > domRect2.bottom ||
              domRect1.right < domRect2.left ||
              domRect1.bottom < domRect2.top ||
              domRect1.left > domRect2.right
            )
          ) {
            if (!el.classList.contains("selected")) {
              el.dispatchEvent(selectEvent);
            }
          } else if (el.classList.contains("selected")) {
            el.dispatchEvent(deselectEvent);
          }
        });
      };
      const handleMouseLeave = (e: MouseEvent) => {
        containerRef.current?.dispatchEvent(onAreaSelect);
        selectionRef.current.style.display = "none";
        selectionRef.current.style.width = "0px";
        selectionRef.current.style.height = "0px";
        containerRef.current?.removeEventListener("mousemove", handleMouseMove);
        containerRef.current?.removeEventListener(
          "mouseleave",
          handleMouseLeave
        );
      };
      if (containerRef.current) {
        containerRef.current.addEventListener("mousemove", handleMouseMove);
        containerRef.current.addEventListener("mouseleave", handleMouseLeave);
        containerRef.current.onmouseup = handleMouseLeave;
      }
    };
  }, []);
  useLayoutEffect(() => {
    containerRef.current?.appendChild(selectionRef.current);
    selectionRef.current.style.display = "none";
    selectionRef.current.style.position = "fixed";
    selectionRef.current.style.zIndex = "1000";
    selectionRef.current.style.transitionDuration = "0s";
    selectionRef.current.style.border = "1px dashed red";
    selectionRef.current.style.pointerEvents = "none";
  }, []);
  return containerRef;
};

export const selectEvent = new Event("select", {
  bubbles: false,
  cancelable: true,
});
export const deselectEvent = new Event("deselect", {
  bubbles: false,
  cancelable: true,
});
const onAreaRemove = new Event("arearemove");
const onAreaSelect = new Event("areaselect");
export default useSelectArea;
