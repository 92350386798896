import CalendarMonthShiftAfter from "component/calendar/CalendarMonthShiftAfter";
import Header from "component/header/Header";
import React from "react";

const NextMonthShiftAfter = () => {
  return (
    <div className="page-container">
      {/* <Header title={`4月シフト申請`} /> */}
      <CalendarMonthShiftAfter />
    </div>
  );
};

export default NextMonthShiftAfter;
