import { useState } from "react"
import { ServiceDetail } from "./ServiceDetail"
import { ServiceList } from "./ServiceList"

const Service = () => {
    const [isList, setIsList] = useState<boolean>(true)
    const [serviceContentId, setServiceContentId] = useState(null)
    const [difference, setDifference] = useState({
        type: ''
    })
    const onChange = () => {
        setIsList(!isList)
    }
    return isList ? <ServiceList onChange={onChange} setServiceContentId={setServiceContentId} setDifference={setDifference} /> :
        <ServiceDetail onChange={onChange} id={serviceContentId} setServiceContentId={setServiceContentId}
            difference={difference} setDifference={setDifference} />
}
export { Service }
