import { Box } from "@chakra-ui/react";
import { ButtonOutline } from "component/button";
import { DateButton } from "component/timeBtn";
import TableAdmin from "component/admin/table";
import type { ColumnsType } from "antd/es/table";
import { FlexRow } from "component/StyledComponent";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { CLIENTPATH } from "route";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { DATE_FORMAT_2 } from "constant/date";
import { Form } from "antd";
import { getTypeCarePlanJP } from "component/table/TableRecordCustomer";

interface DataType {
  key: React.Key;
  date: string /*日付*/;
  time: string /*時間*/;
  start_time: string;
  end_time: string;
  type: string /*区分*/;
  body_temperature: string /*体温*/;
  blood_pressure: string /*血圧*/;
  urination: string /*排尿*/;
  defecation: string /*排便*/;
  moisture: string /*水分*/;
  full_body_bath: string /*全身浴*/;
  manager: string /*担当者*/;
  nursing_care_history: any;
  patient: any;
  time_range: string;
  scheduleable: { care_plan_type: string };
}

const ClientHistoryList = () => {
  const [date, setDate]: any = useState(new Date());
  const [data, setData] = useState<any[]>([]);
  const service = useService();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getPreviousDay = () => {
    const newDate = moment(date).subtract(1, "days").format();

    setDate(new Date(newDate));
  };
  const getNextDay = () => {
    const newDate = moment(date).add(1, "days").format();

    setDate(new Date(newDate));
  };

  const columns: ColumnsType<DataType> = [
    {
      align: "center",
      title: "日付",
      dataIndex: "date",
      sorter: true,
      render: (value, record) =>
        moment(record?.date)?.format("YYYY/MM/DD (dd)"),
    },
    {
      align: "center",
      title: "時間",
      dataIndex: "time_range",
      sorter: true,
    },
    {
      align: "center",
      title: "区分",
      dataIndex: "type",
      sorter: true,
      render: (value, record) => (
        <>{getTypeCarePlanJP(record?.scheduleable?.care_plan_type)}</>
      ),
    },
    {
      align: "center",
      title: "体温",
      dataIndex: "body_temperature",
      sorter: true,
    },
    {
      align: "center",
      title: "血圧",
      dataIndex: "blood_pressure",
      sorter: true,
    },
    {
      align: "center",
      title: "排尿",
      dataIndex: "urination",
      sorter: true,
      render: (value, record) => (
        <>{record?.nursing_care_history?.urination}回</>
      ),
    },
    {
      align: "center",
      title: "排便",
      dataIndex: "defecation",
      sorter: true,
      render: (value, record) => (
        <>{record?.nursing_care_history?.defecation}回</>
      ),
    },
    {
      align: "center",
      title: "水分",
      dataIndex: "moisture",
      sorter: true,
      render: (value, record) => (
        <>{record?.nursing_care_history?.hydration}cc</>
      ),
    },
    {
      align: "center",
      title: "全身浴",
      dataIndex: "full_body_bath",
      sorter: true,
      render: (value, record) => (
        <>{record?.nursing_care_history?.full_body_bath}</>
      ),
    },
    {
      align: "center",
      title: "担当者",
      dataIndex: "manager",
      sorter: true,
      render: (value, record) => (
        <>{record?.nursing_care_history?.nurse?.family_name}</>
      ),
    },
    {
      align: "center",
      title: "",
      dataIndex: "action",
      render: (_, record) => (
        <Link to={`${record?.nursing_care_history?.schedule_date?.id}`}>
          <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
            詳細
          </ButtonOutline>
        </Link>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.NURSING_CARE_HISTORIES,
        method: "get",
        params: {
          ...form.getFieldsValue(),
          date: moment(date).format(DATE_FORMAT_2),
        },
      });
      setData(res.data.data);
      setLoading(false);
      setTotal(res.data.total_items);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [date]);

  return (
    <div className="bg-[#FFFFFF] h-full">
      <Box h="50px" />
      <FlexRow style={{ textAlign: "left", fontSize: "12px" }}>
        <DateButton
          getPreviousDay={getPreviousDay}
          getNextDay={getNextDay}
          date={date}
        />
      </FlexRow>
      <Form form={form} onFinish={fetchData}>
        <div className="flex-[1_1_82%] py-[16px] px-[25px]">
          <TableAdmin
            form={form}
            data={data}
            total={total}
            columns={columns}
            loading={loading}
          />
        </div>
      </Form>
    </div>
  );
};
export default ClientHistoryList;
