import { useState } from "react";
import { ContactList } from "./ContactList";
import { ContactChat } from "./ContactChat";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
interface Contact {
  key: string;
  id: string;
  userId: number;
  nurseId: number;
  lastSendTime: string;
  unread: boolean;
  firstNameKana: string;
  lastNameKana: string;

  name: string;
  time: string;
}
interface Message {
  key: string;
  id: string;
  isGuest: boolean;
  name: string;
  time: string;
  date: string;
  content: string;
}
const Communication = ({ onChangeTitle, setContactTitle }: any) => {
  const [part, setPart] = useState("ContactList");
  const [contactId, setContactId] = useState(null);
  const [contactName, setContactName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const choosePart = () => {
    switch (part) {
      case "ContactList":
        onChangeTitle("個別連絡");
        setContactTitle("個別連絡");
        return (
          <ContactList
            setPart={setPart}
            setContactId={setContactId}
            setContactName={setContactName}
            navigate={navigate}
            dispatch={dispatch}
          />
        );
      case "ContactChat":
        onChangeTitle(`個別連絡　${contactName}`);
        setContactTitle(`個別連絡　${contactName}`);
        return <ContactChat setPart={setPart} contactId={contactId} />;
      default:
        break;
    }
  };
  return (
    <div className="bg-[#EEEEEE] px-[20px] py-[12px]">
      <div className="bg-white px-[12px] py-[18px] relative">
        {choosePart()}
      </div>
    </div>
  );
};
export { Communication };
export type { Contact, Message };
