import { DASHBOARD_HS_ADMIN } from "api/apiConstant";
import BaseApi from "api/baseApi";

class HSDashboard extends BaseApi {
  getDashboard() {
    return this.get(DASHBOARD_HS_ADMIN);
  }
}

export default HSDashboard;
