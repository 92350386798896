import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { FlexRow, Title } from "component/StyledComponent";
import Dot from "assets/dot.png";
import { useState, useEffect } from "react";
import ROUTES from "constant/routes";
import apiFactory from "api";
import { toast } from "react-toastify";
import { ROLE } from "constant";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import userSlice from "store/slice/user";

const Header = ({
  title,
  isImportant,
  content,
  isMenu = false,
  isMaster = false,
}: any) => {
  const navigate = useNavigate();
  const [newTitle, setNewTitle] = useState([]);
  const { role, name } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const logout = async () => {
    // const result = await apiFactory.authApi.logout()
    const result = true;
    if (result) {
      Cookies.remove("access_token");
      dispatch(userSlice.actions.clearUser());
      switch (role) {
        case ROLE.ADMIN:
          navigate(`/${ROUTES.ADMIN}/${ROUTES.LOGIN}`);
          break;
        case ROLE.STAFF:
          navigate(`/${ROUTES.STAFF}/${ROUTES.LOGIN}`);
          break;
        case ROLE.CLIENT:
          navigate(`/${ROUTES.LOGIN}`);
          break;
        case ROLE.HS_ADMIN:
          navigate(`/${ROUTES.HOME_SYSTEM}/${ROUTES.LOGIN}`);
          break;
        case ROLE.HS_STAFF:
          navigate(`/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.LOGIN}`);
          break;
        default:
          navigate(`/${ROUTES.LOGIN}`);
          break;
      }
    }
  };

  useEffect(() => {
    if (title.includes("・")) {
      const newTit = title.split("・");
      setNewTitle(newTit);
    }
  }, [title]);
  return (
    <Card className={`${isMaster ? "pl-[270px]" : ""}`}>
      {title.includes("・") ? (
        <Title className="flex flex-row">
          {newTitle[0]} <img src={Dot} alt="" className="h-3 w-3 mt-3 mx-2" />{" "}
          {newTitle[1]}
        </Title>
      ) : (
        <Title className="text-[24px] !mt-[5px] font-hiragino max-w-[1000px] overflow-hidden	 whitespace-nowrap text-ellipsis">
          {title && title}
          {isImportant ? (
            <span className="bg-[#E07B2E] border-[1px] border-solid border-[#000000] text-[15px] font-hiragino p-[2px]">
              重要
            </span>
          ) : (
            ""
          )}
          &nbsp;&nbsp;
          {content ? content : ""}
        </Title>
      )}
      <FlexRow className="py-2">
        {!isMenu && (
          <Button
            onClick={() => {
              navigate("/");
            }}
            variant="outline"
            bg="#fff"
            width="137px"
            height="35px"
            fontSize="14px"
            style={{
              color: "var(--primary-color)",
              border: "1px solid var(--primary-color)",
              borderRadius: "5px",
              marginRight: "15px",
            }}
          >
            メニューへ戻る
          </Button>
        )}
        <MenuRight>
          <Slash />
          <p className="mt-2">{name}</p>
        </MenuRight>
        <MenuRight>
          <Slash />
          <p onClick={logout} className="mt-2 cursor-pointer hover:font-bold">
            ログアウト
          </p>
        </MenuRight>
      </FlexRow>
    </Card>
  );
};
const Card = styled.div({
  zIndex: 10,
  position: "fixed",
  top: 0,
  width: "100%",
  background: "var(--header-color)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "0px 20px",
  height: "50px !important",
  // fontFamily: 'hiragino-interface'
});

const MenuRight = styled.div({
  color: "var(--primary-text-light)",
  fontSize: "14px",
  // borderLeft: '2px solid #fff',
  // padding: '10px 15px',
  height: "35px",
  marginBottom: "5px",
  display: "flex",
  flexDirection: "row",
});

const Slash = styled.div({
  color: "var(--primary-text-light)",
  background: "#fff",
  width: "1px",
  height: "35px",
  margin: "0px 10px",
});

export default Header;
