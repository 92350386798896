import {
  DatePicker,
  Modal,
  ModalProps,
  Select,
  Form,
  DatePickerProps,
} from "antd";
import { IAreaTableSelect } from "../../Attendance";
import { ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import { useWatch } from "antd/es/form/Form";
import { Button } from "@chakra-ui/react";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import SelectWithData from "component/SelectWithData";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export type TModal = "select-range-date" | "select-single-date";
interface IProps extends ModalProps {
  data: IAreaTableSelect;
  type: TModal;
}

export const CreateModal = ({ data, type, ...props }: IProps) => {
  const service = useService();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const content = useWatch("content", form);
  const dates = useWatch("dates", form);
  const staff_id = useWatch("staff_id", form);

  const handleSubmit = async (data: any) => {
    try {
      const response = await axiosClient.post(
        service.SHIFT_MANAGEMENTS + "/create_shifts",
        data
      );
      toast.success(response.data.success);
      props.onCancel?.(undefined as any);
    } catch (error) {
      toast.error((error as string).toString());
    }
  };

  const getStaffOptions = async () =>
    (
      await axiosClient.get(service.NURSING_STAFF, {
        params: { get_all: true },
      })
    ).data.data.map((i: any) => ({
      label: i.family_name,
      value: i.id,
    }));

  const getShiftRegistrationOptions = async () =>
    (
      await axiosClient.get(service.SHIFT_REGISTRATIONS, {
        params: { get_all: true },
      })
    ).data.data.map((i: any) => ({
      label: i.name,
      value: i.id,
    }));

  useEffect(() => {
    if (props.open) {
      form.setFieldValue(
        "dates",
        data.date_range.length ? data.date_range.map((item) => item.format("YYYY-MM-DD")) : [null]
      );
      form.setFieldValue("staff_id", data.staff_id);
      form.resetFields(["shift_registration_id"]);
    }
  }, [props.open]);
  return (
    <>
      <Modal
        title="休日・出勤調整"
        {...props}
        width={389}
        okText="保存"
        okButtonProps={{
          style: { width: 137, background: "var(--primary-color)" },
        }}
        onOk={form.submit}
        cancelButtonProps={{ style: { width: 101 } }}
        onCancel={(e) => {
          // if (content || dates || staff_id) {
          //   setIsConfirmModalOpen(true);
          //   return;
          // }
          props.onCancel && props.onCancel(e);
          // setIsConfirmModalOpen(false);
        }}
        cancelText="キャンセル"
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="dates" label="日にち" className="mt-[34px]">
            {type === "select-range-date" ? (
              <p className="">
                {data.date_range[0]?.format("YYYY/MM/DD")} ~{" "}
                {data.date_range[data.date_range.length - 1]?.format(
                  "YYYY/MM/DD"
                )}
              </p>
            ) : (
              <Form.List name="dates" initialValue={[null]}>
                {(fields, { add, remove }) =>
                  fields.map((item, index) => (
                    <div key={index} className="flex items-center">
                      <Form.Item noStyle name={[index]}>
                        <DatePicker
                          format={JP_DATEPICKER_FORMAT}
                          placeholder="選択してください"
                          className="w-full"
                          defaultPickerValue={dayjs().add(1, "M").date(1)}
                          disabledDate={(date) =>
                            !date.add(-1, "M").isSame(dayjs(), "M")
                          }
                        />
                      </Form.Item>
                      <Button
                        onClick={() => add(undefined, index + 1)}
                        variant="outline"
                        transform="scale(0.75)"
                        borderRadius="50%"
                        width="40px"
                        height="40px"
                        p="0px"
                        ml="2"
                        border="1px solid #000"
                      >
                        <img
                          src={Plus}
                          alt=""
                          style={{ height: "20px", width: "20px" }}
                        />
                      </Button>
                      <Button
                        onClick={() => fields.length > 1 && remove(index)}
                        variant="outline"
                        transform="scale(0.75)"
                        borderRadius="50%"
                        width="40px"
                        height="40px"
                        p="0px"
                        border="1px solid #000"
                      >
                        <img
                          src={Minus}
                          alt=""
                          style={{ height: "20px", width: "20px" }}
                        />
                      </Button>
                    </div>
                  ))
                }
              </Form.List>
            )}
          </Form.Item>
          <Form.Item name="shift_registration_id" label="登録内容">
            <SelectWithData
              getOptions={getShiftRegistrationOptions}
              className="w-[100%]"
            />
          </Form.Item>
          <Form.Item name="staff_id" label="介護スタッフ">
            <SelectWithData getOptions={getStaffOptions} className="w-[100%]" />
          </Form.Item>
        </Form>
      </Modal>
      {/* <Modal
        title="編集内容を破棄しますか？"
        open={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        onOk={(e) => {
          props.onCancel?.(e);
          setIsConfirmModalOpen(false);
        }}
        okButtonProps={{ style: { background: "var(--primary-color)" } }}
        cancelText="いいえ"
        okText="はい"
      ></Modal> */}
    </>
  );
};
