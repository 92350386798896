import { isEqual } from "lodash";

export const unique = <T = any>(array: Array<T>): Array<T> => {
  return array.reduce((acc: Array<T>, item: T) => {
    const isAccumulatorContainsItem = acc?.some((accItem) =>
      isEqual(accItem, item)
    );

    if (!isAccumulatorContainsItem) {
      acc?.push(item);
    }

    return acc;
  }, []);
};
