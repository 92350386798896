import { FlexRow, StyleTitle } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "antd";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { ClockCircleFilled } from "@ant-design/icons";
import ROUTES from "constant/routes";
import { useWatch } from "antd/es/form/Form";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Textarea } from "@chakra-ui/react";

const Styles = styled.div`
  .ant-row {
    display: flex;
  }
`;

const AssessmentSheet1 = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const changeable_id = useWatch("id", form);
  const service = useService();
  const [isEdit, setIsEdit] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const fetchInitialData = async () => {
    try {
      form.resetFields();
      const { data } = await axiosClient({
        method: "get",
        url: service.EVALUE_ONES,
        params: { patient_id: id },
      });
      form.setFieldsValue(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (formData: any) => {
    formData.patient_id = id;
    try {
      const res = await axiosClient({
        method: formData.id ? "put" : "post",
        url: service.EVALUE_ONES,
        data: formData,
      });
      toast.success(res.data.success);
      fetchInitialData();
      setIsEdit(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Styles>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ flex: "110px" }}
        labelWrap
        disabled={!isEdit}
      >
        <FlexRow className="justify-end">
          {user?.role === "staff" && (
            <ButtonSolid
              onClick={() => navigate(-1)}
              width="137px"
              height="35px"
              fontSize="16px"
              m={3}
            >
              一覧へ
            </ButtonSolid>
          )}
        </FlexRow>
        <div className="text-left max-w-[700px]">
          <StyleTitle>■基本情報</StyleTitle>
          <Form.Item label="生活歴" name="pre_history">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
          <Form.Item label="1日の生活リズム" name="heartbeat">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>

          <Form.Item label="日常生活の課題" name="daily_life_challenges">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
          <Form.Item label="その他注意事項" name="note">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
          <Form.Item label="性格" name="character">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
          <Form.Item label="趣味・レク" name="hobbies">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
          <Form.Item label="家族状況" name="family_circumstances">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
          <Form.Item label="特記・他サービス利用" name="special_notes">
            <Textarea border="1px solid #000" isReadOnly />
          </Form.Item>
        </div>
        <div className="flex items-center justify-between">
          <div>
            <Link
              to={`${ROUTES.EDIT_HISTORY}?changeable_type=EvalueOne&changeable_id=${changeable_id}`}
              hidden={!changeable_id}
            >
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
                <ClockCircleFilled />
                <span>履歴一覧</span>
              </div>
            </Link>
          </div>
          <div className="flex gap-x-[8px]" hidden={!isEdit}>
            <ButtonOutline
              onClick={() => {
                setIsEdit(false);
                fetchInitialData();
              }}
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            >
              キャンセル
            </ButtonOutline>
            {/* <ButtonSolid
              onClick={form.submit}
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
            >
              保存
            </ButtonSolid> */}
          </div>
        </div>
        <Form.Item name="id" hidden>
          <div />
        </Form.Item>
      </Form>
    </Styles>
  );
};

export default AssessmentSheet1;
