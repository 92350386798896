import styled from "@emotion/styled";

const Styles = styled.div`
  .collapsed-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  th {
    border: 1px solid #ececec;
  }
  .ant-row {
    display: flex;
  }
  .wrapper-action {
    display: flex;
    gap: 8px;
  }
  .icon-action {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #666666;
  }
  .container-delete {
  }
  .img-delete {
    display: flex;
    justify-content: center;
  }
  .title-delete {
    display: flex;
    justify-content: center;
    font-family: SF UI Display;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.0015em;
    text-align: center;
    color: linear-gradient(0deg, #ff4d4f, #ff4d4f);
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .btn-delete {
    display: flex;
    justify-content: center;
    gap: 0px;
  }
  .btn {
    min-width: 100px;
    border-radius: 0px;
  }
`;

export default Styles;
