import styled from "@emotion/styled";

const Styles = styled.div`
  .column-sort-custom, .column-action-custom {
    border-right: none !important;
  }
  .column-sort-custom::before {
    background-color: transparent !important;
  }
  
`

export {Styles}
