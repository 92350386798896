import { StyleTd } from 'component/StyledComponent'
import styled from '@emotion/styled'

const TableHeadTd = ({ title, changeValue, value, name, isSort }: any) => {

  const CustomTD = styled(StyleTd)({
    '&:hover': {
      background: '#f0f0f0',
    },
    background: `${value > 0 ? '#f0f0f0' : 'var(--secondary-color)'}`,
    cursor: 'pointer'
  })
  return (
    <>
      {isSort ? <CustomTD onClick={(evt: any) => changeValue(evt)} value={value} name={name}
        tabIndex={0} role='button'
        style={{
          whiteSpace: 'nowrap',
        }} >
        <div className='flex flex-row justify-center text-left h-[12px]'>
          <p className='font-bold mt-1' >{title}</p>
          {/* <img src={SortImg} alt="" style={{height:'12px', width:'8px', marginTop:'2px'}} className='ml-4 cursor-pointer' /> */}
          <div className='flex flex-col ml-4' style={{ transform: 'scaleY(0.50)' }} >
            <span style={{ color: `${value === 1 ? '#1677FF' : '#000'}` }}  >▲</span>
            <span style={{ color: `${value === 2 ? '#1677FF' : '#000'}` }} >▼</span>
          </div>
        </div>
      </CustomTD> :
        <CustomTD 
          style={{
            whiteSpace: 'nowrap',
          }} >
          <div className='flex flex-row justify-center text-left h-[12px]'>
            <p className='font-bold mt-1' >{title}</p>
          </div>
        </CustomTD>
      }
    </>
  )

}



export default TableHeadTd
