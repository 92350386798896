import { Button, Form, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import apiFactory from "api";
import warningIcon from "assets/iconWarning.png";
import deleteIcon from "assets/svg/delete.svg";
import detailIcon from "assets/svg/detail.svg";
import editIcon from "assets/svg/edit.svg";
import TableAdmin from "component/admin/table";
import { ButtonSolid } from "component/button";
import { JP_DATE_FORMAT } from "constant/date";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AssessmentSheet1Create from "./AssessmentCreate";
import AssessmentSheet1Edit from "./AssessmentEdit";
import { IEvalueOnesResponse } from "./_type";
import Styles from "./styled";
import { IEvalueOne } from "types/admin";
import _ from "lodash";
import { Copy } from "assets/icons";
import { IUpdateEvalueResponse } from "./AssessmentEdit/config";
import { toast } from "react-toastify";

const AssessmentSheet1 = () => {
  const { id: patientId } = useParams();

  const [form] = Form.useForm();

  //--- State
  const [total, setTotal] = useState<number>(0);
  const [data, setData] = useState<IEvalueOne[]>([]);
  const [dataDetail, setDataDetail] = useState({});
  const [display, setDisplay] = useState<{
    list: boolean;
    detail: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean | string;
  }>({
    list: true,
    detail: false,
    edit: false,
    create: false,
    delete: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  //--- Open edit
  const openEdit = (record: any) => {
    setDisplay({
      list: false,
      edit: true,
      create: false,
      detail: false,
      delete: false,
    });
    setDataDetail(record);
  };

  //--- Open detail
  const openDetail = (record: any) => {
    setDisplay({
      list: false,
      edit: false,
      create: false,
      detail: true,
      delete: false,
    });
    setDataDetail(record);
  };

  //--- Open delete
  const openDelete = (record: IEvalueOne) => {
    setDisplay({
      list: false,
      edit: false,
      create: false,
      detail: false,
      delete: record.id.toString(),
    });
  };

  //--- Open create
  const openCreate = () => {
    setDisplay({
      list: false,
      edit: false,
      create: true,
      detail: false,
      delete: false,
    });
  };

  //--- Close modal
  const onClose = (isRefresh?: boolean) => {
    setDisplay({
      list: true,
      edit: false,
      create: false,
      detail: false,
      delete: false,
    });

    if (isRefresh) {
      getEvalue();
    }
  };

  const handleDuplicateRecord = async (record: IEvalueOne) => {
    if (!patientId) return;
    try {
      const { id, ...requestBody } = record;

      const res =
        await apiFactory.adminEvalueOneApi.createEvalue<IUpdateEvalueResponse | null>(
          {
            patient_id: patientId!,
            ...requestBody,
          }
        );

      if (res && res.success) {
        toast.success(res.success);
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns: ColumnsType<IEvalueOne> = [
    {
      align: "center",
      title: "作成日",
      dataIndex: "created_date",
      render: (value, record) =>
        value
          ? dayjs(value).format(JP_DATE_FORMAT)
          : dayjs(record.created_at).format(JP_DATE_FORMAT),
      // sorter: true,
    },
    {
      align: "center",
      title: "生活歴",
      dataIndex: "life_history",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "1日の生活リズム",
      dataIndex: "daily_rhythm_of_life",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "日常生活の課題",
      dataIndex: "daily_life_challenges",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "その他注意事項",
      dataIndex: "note",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "性格",
      dataIndex: "character",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "趣味・レク",
      dataIndex: "hobbies",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "家族状況",
      dataIndex: "family_circumstances",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
      // sorter: true,
    },
    {
      align: "center",
      title: "特記・他サービス利用",
      dataIndex: "special_notes",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
    },
    {
      align: "center",
      title: "現病・既往歴",
      dataIndex: "current_illness_history",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
    },
    {
      align: "center",
      title: "ご家族聞き取り",
      dataIndex: "family_interview",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
    },
    {
      align: "center",
      title: "ご本人聞き取り",
      dataIndex: "personal_interview",
      render: (value) => (
        <>
          {_.truncate(value, {
            length:
              value && value.length > 20 && value.length < 23
                ? value.length + 3
                : 23,
            // omission:
          })}
        </>
      ),
    },
    {
      align: "center",
      title: "操作",
      dataIndex: "action",
      render: (_, record) => (
        <div className="wrapper-action justify-center">
          <div
            className="icon-action"
            onClick={() => handleDuplicateRecord(record)}
          >
            <Copy className="h-6 w-6" />
          </div>
          <div onClick={() => openEdit(record)} className="icon-action">
            <img src={editIcon} alt="" />
          </div>
          <div onClick={() => openDetail(record)} className="icon-action">
            <img src={detailIcon} alt="" />
          </div>
          <div onClick={() => openDelete(record)} className="icon-action">
            <img src={deleteIcon} alt="" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getEvalue();
  }, []);

  //--- Handle fetch evalue ones data
  const getEvalue = useCallback(async (args?: any) => {
    setLoading(true);
    try {
      const _data =
        await apiFactory.adminEvalueOneApi.getEvalue<IEvalueOnesResponse>({
          page: (args && args.page) || 1,
          per_page: (args && args.per) || 10,
          patient_id: patientId!,
        });

      if (_data) {
        setData(
          _data.evalue_ones.sort((a, b) => {
            //sort by created_date
            return (
              dayjs(b.created_date ? b.created_date : b.created_at).unix() -
              dayjs(a.created_date ? a.created_date : a.created_at).unix()
            );
          })
        );
        setTotal(_data.total_items);
      } else {
        setData([]);
        setTotal(0);
      }
    } catch (error) {}
    setLoading(false);
  }, []);

  //--- Handle delete evalue ones
  const handleDeleteEvalue = useCallback(async () => {
    try {
      if (display.delete && typeof display.delete === "string") {
        const res = await apiFactory.adminEvalueOneApi.deleteEvalue(
          display.delete
        );

        onClose(true);
      }
    } catch (error) {}
  }, [display.delete]);

  return (
    <>
      {(display?.list || display?.delete) && (
        <Styles>
          <div className="text-end">
            <ButtonSolid
              onClick={() => {
                openCreate();
              }}
              width={137}
            >
              新しく作る
            </ButtonSolid>
          </div>
          <div className="overflow-x-auto">
            <Form form={form} onFinish={getEvalue}>
              <TableAdmin
                form={form}
                data={data}
                columns={columns}
                loading={loading}
                total={total}
              />
            </Form>
          </div>
        </Styles>
      )}

      {display?.create && <AssessmentSheet1Create onClose={onClose} />}

      {(display?.edit || display?.detail) && (
        <AssessmentSheet1Edit
          display={display}
          dataDetail={dataDetail}
          onClose={onClose}
        />
      )}

      <Modal
        width={350}
        open={display?.delete ? true : false}
        onOk={() => onClose()}
        onCancel={() => onClose()}
        footer={null}
      >
        <Styles>
          <div className="container-delete">
            <div className="img-delete">
              <img width={80} height={80} alt="" src={warningIcon} />
            </div>
            <div className="title-delete">削除してよろしいですか。</div>
            <div className="btn-delete">
              <div>
                <Button
                  className="btn"
                  onClick={() => onClose()}
                  style={{ margin: "0 10px" }}
                >
                  いいえ
                </Button>
              </div>
              <div>
                <Button
                  className="btn"
                  onClick={handleDeleteEvalue}
                  type="primary"
                  danger
                  style={{ margin: "0 10px" }}
                >
                  はい
                </Button>
              </div>
            </div>
          </div>
        </Styles>
      </Modal>
    </>
  );
};

export default AssessmentSheet1;
