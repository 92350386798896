import { CardGray, FlexRow } from "component/StyledComponent";
import { useCallback, useEffect, useState } from "react";
import { ButtonOutline } from "component/button";
import {
  Text,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import LeftArrow from "assets/leftarrow.png";
import RightArrow from "assets/rightarrow.png";
import styled from "@emotion/styled";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { StyleCheckbox } from "component/StyledComponent";
import ROUTES from "constant/routes";
import queryString from "query-string";
import "moment/locale/ja";
import "moment-timezone";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { Form } from "antd";
import { IStaff } from "constant/interfaces";
import TableAdmin from "component/admin/table";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { FORMAT } from "utils";
import { DATE_FORMAT, DATE_FORMAT_2 } from "constant/date";
import SearchImg from "assets/search.png";

const TableHistoryStaff = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate]: any = useState(new Date());
  const [data, setData] = useState<IStaff[]>([]);
  // const [status, setStatus] = useState<string>("register");
  const [checkValue, onChangeCheckVal]: any = useState({
    on_leave: false,
    resigned: false,
  });
  const [searchVal, setSearchVal] = useState("");
  const [form] = Form.useForm();
  const service = useService();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const queryObj: any = queryString.parse(location.search);

  const fetchData = useCallback(
    async (text: any = "") => {
      let status = ["active"];
      for (const [key, value] of Object.entries(checkValue)) {
        if (value) {
          status.push(key);
        }
      }
      try {
        setLoading(true);
        if (text) {
          const res = await axiosClient({
            url: service.NURSING_STAFF,
            method: "get",
            params: {
              ...form.getFieldsValue(),
              status,
              care_plan_date: moment(date).format(DATE_FORMAT_2),
              fullname: text,
            },
          });
          setData(res.data.nursing_staffs);
          setLoading(false);
          setTotal(res.data.total_items);
        } else {
          const res = await axiosClient({
            url: service.NURSING_STAFF,
            method: "get",
            params: {
              ...form.getFieldsValue(),
              status,
              care_plan_date: moment(date).format(DATE_FORMAT_2),
            },
          });
          setData(res.data.nursing_staffs);
          setLoading(false);
          setTotal(res.data.total_items);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [checkValue, date]
  );
  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (location.search) {
      setDate(new Date(queryObj.date));
    }
  }, [location.search]);

  const columns: ColumnsType<IStaff> = [
    { align: "center", title: "ID", dataIndex: "nurse_code", sorter: true },
    {
      align: "center",
      title: "氏名",
      sorter: true,
      width: 150,
      dataIndex: "name",
      render: (value, record) => (
        <div>
          {record.family_name}
          <br />({record.name_kana})
        </div>
      ),
    },
    {
      align: "center",
      title: "電話番号",
      dataIndex: "desk_phone",
      sorter: true,
    },
    {
      align: "center",
      title: "携帯番号",
      dataIndex: "mobile_phone",
      sorter: true,
    },
    {
      align: "center",
      title: "年齢",
      dataIndex: "birth_date",
      sorter: true,
      render: (value) => dayjs(value).format(DATE_FORMAT),
    },
    { align: "center", title: "性別", dataIndex: "sex", sorter: true },
    {
      align: "center",
      title: "",
      dataIndex: "action",
      width: 200,
      render: (_, record) => (
        <ButtonOutline
          onClick={() =>
            navigate({
              pathname: `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${ROUTES.HISTORY_CUSTOMER}/${record?.id}`,
              search: `?date=${moment(date).format(FORMAT)}&tab=2&name=${
                record?.family_name
              }`,
            })
          }
          className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]"
        >
          詳細
        </ButtonOutline>
      ),
    },
  ];

  const getPreviousDay = () => {
    const newDate = moment(date).subtract(1, "days").format();

    setDate(new Date(newDate));
  };
  const getNextDay = () => {
    const newDate = moment(date).add(1, "days").format();

    setDate(new Date(newDate));
  };

  const onSearchName = () => {
    fetchData(searchVal);
  };

  return (
    <div className="w-full bg-white">
      <CardGray
        style={{
          padding: "16px 26px 19px 26px",
          fontSize: "12px",
          background: "var(--secondary-color)",
        }}
      >
        <FlexRow>
          <p className="font-bold">キーワード</p>

          <StyleCheckbox
            checked={checkValue.on_leave}
            onChange={(evt) =>
              onChangeCheckVal({ ...checkValue, on_leave: evt.target.checked })
            }
            mx={5}
            name="on_leave"
            colorScheme="brand2"
            borderColor="black"
          >
            <p className="font-bold text-xs">休職中</p>
          </StyleCheckbox>
          <StyleCheckbox
            checked={checkValue.resigned}
            onChange={(evt) =>
              onChangeCheckVal({ ...checkValue, resigned: evt.target.checked })
            }
            mx={5}
            name="resigned"
            colorScheme="brand2"
            borderColor="black"
          >
            <p className="font-bold text-xs">退職済</p>
          </StyleCheckbox>
        </FlexRow>
        <FlexRow className="mt-2">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <img
                src={SearchImg}
                alt=""
                style={{ width: "16px", height: "16px", marginBottom: "15px" }}
              />
            </InputLeftElement>
            <Input
              type="text"
              value={searchVal}
              onChange={(evt) => setSearchVal(evt.target.value)}
              _placeholder={{ color: "var(--primary-text-dark)" }}
              placeholder="氏名、カナ"
              bg="white"
              height="25px"
              fontSize="12px"
              borderRadius="0"
              marginRight="21px"
              border="1px solid #707070"
            />

            <ButtonOutline
              onClick={onSearchName}
              width="150px"
              height="25px"
              bg="white"
              fontSize="12px"
            >
              検索
            </ButtonOutline>
          </InputGroup>
        </FlexRow>
      </CardGray>

      <Form form={form} onFinish={fetchData}>
        <TableAdmin
          form={form}
          data={data}
          columns={columns}
          total={total}
          loading={loading}
        />
      </Form>
      <FlexRow className="justify-end" style={{ paddingBottom: "40px" }}>
        <ButtonOutline
          onClick={() =>
            navigate(
              `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}?tab=${queryObj.tab}`
            )
          }
          mt="232px"
          w="101px"
          h="35px"
          fontSize="14px"
        >
          戻る
        </ButtonOutline>
      </FlexRow>
    </div>
  );
};

const StyleBtn = styled(Button)({
  border: "1px solid black",
  fontSize: "20px",
});

export default TableHistoryStaff;
