import React, { useEffect, useState } from "react";
import { Calendar } from "antd";
import styled from "@emotion/styled";
import dayjs, { Dayjs } from "dayjs";
import { MonthButton } from "component/timeBtn";
import { Text } from "@chakra-ui/react";
import moment from "moment";
import { DATE_FORMAT } from "constant/date";
import clientScheduleApi from "api/client/clientScheduleApi";
import { getCookie } from "utils";

interface IPropsClientCalendar {
  onChangeDate?: any
}
const ClientCalendarVisitSchedule = ({ onChangeDate }: IPropsClientCalendar) => {
  const customDayInWeekend: any = {
    lang: {
      locale: "ja_JP",
      shortWeekDays: ["日", "月", "火", "水", "木", "金", "土"],
    },
  };
  const idToken = getCookie("access_token")

  const [monthSelect, setMonthSelect]: any = useState(new Date());
  const [dataMonth, setDataMonth]: any = useState([])

  const dateCellRender = (value: Dayjs) => {
    const listData: any = getListData(value);
    return (
      <ul className="events" style={{ background: '#e07b2e' }}>
        {listData?.data?.map((item: any) => (
          <Text bg='brand.500'>{item}</Text>
        ))}
      </ul>
    );
  };

  const cellRender = (current: Dayjs) => {
    return dateCellRender(current);
  };

  const onSelectDate = (newValue: any) => {
    const newDate = new Date(newValue);
    setMonthSelect(newDate);
  };

  const getListData = (value: Dayjs) => {
    let listData;
    dataMonth.map((item: any) => {
      if (item.day === value.date()) {
        listData = {
          data: item.data,
          color: item.color
        };
        return listData;
      }
    });
    return listData || {
      data: [],
      color: false
    };;
  };


  const getMonthSchedule = async () => {
    try {
      const param = {
        year_month: moment(monthSelect).format(DATE_FORMAT)
      }

      const { data } = await clientScheduleApi.getShiftSchedule(idToken, param)
      if (data) {
        const newRes: any = data.data
        const newArr: any = []
        for (const [key, value] of Object.entries(newRes)) {
          const newVal: any = value;
          newArr.push({
            day: new Date(key).getDate(),
            data: newVal,
          })
        }
        setDataMonth(newArr)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getMonthSchedule()
  }, [monthSelect])

  return (
    <div>
      <CalendarCustom
        fullCellRender={(value: any) => {
          const curMonth = new Date(value).getMonth();
          const newValue = getListData(value);
          if (monthSelect.getMonth() === curMonth) {
            return (
              <div style={{ background: `${(newValue.color) ? '#e07b2e' : '#fff'}` }} className="h-[92px] pt-[12px] pr-[9px]">
                <Text color={`${(newValue.color) ? 'white' : '#000'}`} >{dayjs(value).format('D')}</Text>
                <div className="absolute left-[10px] top-[10px]">
                  {newValue.data.map((item: any, index: any) => (
                    <Text color={`${(newValue.color) ? 'white' : '#000'}`} key={index} >{item}</Text>
                  ))}
                </div>

              </div>
            )
          } else {
            return (
              <div className="h-[92px] pt-[12px] pr-[9px]">
                <Text>{dayjs(value).format('D')}</Text>

              </div>
            )
          }

        }}
        cellRender={cellRender}
        headerRender={(props: any) => {
          return (
            <div
              className="flex flex-row justify-start ml-8 absolute"
              style={{ top: "76px", left: "16px" }}
            >
              <MonthButton
                month={props.value}
                getPreviousMonth={props.onChange}
                getNextMonth={props.onChange}
                className='!left-[68px]'
              />
            </div>
          );
        }}
        onChange={onSelectDate}
        locale={customDayInWeekend}
      />
    </div>
  );
};

export default ClientCalendarVisitSchedule;

export const CalendarCustom = styled(Calendar)`
  font-family: var(--primary-font-family);
  td > div {
    font-size: 13px;
  }
  th,
  td {
    border: 1px solid black !important;
    font-weight: 700 !important;
  }
  th {
    padding: 4.5px 4px !important;
    text-align: left;
    font-size: 15px !important;
    &:first-child {
      color: red;
    }
    &:last-child {
      color: #5c85b0;
    }
  }
  .ant-radio-group {
    display: none;
  }
  .ant-picker-calendar-header {
    justify-content: flex-start;
  }
  thead > tr {
    height: 28px;
    > th {
      background: var(--secondary-color);
      height: 28px;
      font-size: 14px !important;
    }
  }
`;
