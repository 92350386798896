import { useEffect, useState } from "react";
import { Button } from "@chakra-ui/react";
import { Checkbox, Form, Select, Table, TimePicker } from "antd";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import dayjs, { Dayjs } from "dayjs";
import SelectWithData from "component/SelectWithData";
import SelectTime from "component/SelectTime";
import InputCustom from "component/admin/input";
import { FlexRow } from "component/StyledComponent";
import { DATE_FORMAT, MONTH_FORMAT } from "constant/date";
import Plus from "assets/plus.png";
import Minus from "assets/minus.png";
import { Styles } from "./styled";
import { ButtonOutline, ButtonSolid } from "component/button";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { useNavigate, useParams } from "react-router-dom";
import { IService } from "constant/interfaces";
import { toast } from "react-toastify";
import MESSAGES from "constant/messages";
import { convertDateArrayToBooleanArray } from "page/Customer/CustomerDetail/CarePlan/function";
import ROUTES from "constant/routes";

export const toFEData = (
  data: any,
  year_month: Dayjs,
  lastMonth: boolean,
  form: any
) => {
  const newData = {
    schedule_at_homes: data.schedule_at_homes.map((schedule: any) => ({
      ...schedule,
      time: [
        dayjs(schedule.start_time, "HH:mm"),
        dayjs(schedule.end_time, "HH:mm"),
      ],
      schedule_date_at_homes: convertDateArrayToBooleanArray(
        schedule.schedule_date_at_homes,
        year_month.format(MONTH_FORMAT),
        lastMonth
      ),
    })),
    schedule_memo_at_homes: data.schedule_memo_at_homes.map(
      (schedule: any) => ({
        ...schedule,
        time: [
          dayjs(schedule.start_time, "HH:mm"),
          dayjs(schedule.end_time, "HH:mm"),
        ],
        schedule_date_at_homes: convertDateArrayToBooleanArray(
          schedule.schedule_date_at_homes,
          year_month.format(MONTH_FORMAT),
          lastMonth
        ),
      })
    ),
  };
  return newData;
};

export const toBEData = (data: any, patient_id: any, month: Dayjs) => {
  const newData = {
    ...data,
    patient_id: patient_id,
    year_month: month.format(MONTH_FORMAT),
    schedule_at_homes: data.schedule_at_homes?.map((item: any) => ({
      id: item.id,
      service_home_system_id: item.service_home_system_id,
      end_time: item.time?.[1].format("HH:mm"),
      start_time: item.time?.[0].format("HH:mm"),
      supplement_options: item?.supplement_options || "supplement_none",
      schedule_date_at_homes: item.schedule_date_at_homes
        .map((item: boolean, index: number) =>
          item ? month.date(index + 1).format(DATE_FORMAT) : null
        )
        ?.filter((i: any) => i),
    })),
    schedule_memo_at_homes: data.schedule_memo_at_homes?.map((item: any) => ({
      id: item.id,
      content: item.content,
      end_time: item.time?.[1].format("HH:mm"),
      start_time: item.time?.[0].format("HH:mm"),
      schedule_date_at_homes: item.schedule_date_at_homes
        .map((item: boolean, index: number) =>
          item ? month.date(index + 1).format(DATE_FORMAT) : null
        )
        ?.filter((i: any) => i),
    })),
  };
  return newData;
};

export default function CustomerDetail() {
  const [form] = Form.useForm();
  const schedules = Form.useWatch("schedule_at_homes", form);
  const schedulesMemo = Form.useWatch("schedule_memo_at_homes", form);
  const service = useService();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [month, setMonth] = useState<Dayjs>(dayjs());

  const [isCopy, setIsCopy] = useState(false);
  const [isShowButtons, setIsShowButtons] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);

  const idPatient =
    localStorage.getItem("backToScreen4") === "true" &&
    id !== localStorage.getItem("patientChangeID")
      ? localStorage.getItem("patientChangeID")
      : id;

  const supplementOptions = [
    { label: "なし", value: "supplement_none" },
    { label: "日勤者", value: "day_shift" },
    { label: "夜勤者", value: "night_shift" },
    { label: "入浴時", value: "bathing" },
  ];

  const getServiceOptions = async () =>
    (
      await axiosClient.get(`${service.HS_ADMIN}/service_home_systems`, {})
    ).data.data.map((item: IService) => ({
      label: item.service_name,
      value: item.id,
    }));

  const fetchInitialData = async (lastMonth = false) => {
    let year_month = month;
    if (lastMonth) year_month = year_month.add(-1, "month");
    try {
      const currentFormId = form.getFieldValue("id");
      form.resetFields();
      if (lastMonth) form.setFieldValue("id", currentFormId);
      form.setFieldValue("schedule_at_homes", [{}]);
      form.setFieldValue("schedule_memo_at_homes", [{}]);
      setLoading(true);
      const { data } = await axiosClient({
        method: "get",
        url: `${service.HS_ADMIN}/care_plan_at_homes`,
        params: {
          patient_id: idPatient,
          year_month: year_month.format(MONTH_FORMAT),
        },
      });
      if (!data?.data?.care_plan_at_home) return;
      localStorage.setItem("patientName", data?.data?.patient?.family_name);
      form.setFieldsValue(
        toFEData(data?.data?.care_plan_at_home, year_month, lastMonth, form)
      );
      setIsShowPreview(lastMonth ? false : true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      const res = await axiosClient({
        method:
          schedules?.filter((item: any) => item?.id)?.length > 0 && !isCopy
            ? "put"
            : "post",
        url: `${service.HS_ADMIN}/care_plan_at_homes`,
        data: toBEData(formData, idPatient, month),
      });
      toast.success(res.data.success);
      fetchInitialData();
      setIsCopy(false);
      setIsShowPreview(true);
    } catch (error) {
      toast.error((error as string).toString());
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [month]);

  return (
    <div>
      <div className="page-title">
        {localStorage.getItem("patientName") || ""} 様
      </div>
      <div className="page-container">
        <SelectTime
          format={MONTH_FORMAT}
          type="month"
          value={month}
          onChange={setMonth}
        />
        <div className="mt-[40px] mb-[24px] pb-[4px] flex items-end justify-between border-b border-solid border-[#000000]">
          <p className="font-semibold">■介護内容</p>
          <ButtonSolid
            onClick={() => {
              setIsCopy(true);
              fetchInitialData(true);
              setIsShowPreview(false);
            }}
          >
            先月をコピー
          </ButtonSolid>
        </div>
        <Styles>
          <Form
            form={form}
            initialValues={{
              schedule_at_homes: [{}],
              schedule_memo_at_homes: [{}],
            }}
            onFinish={handleSubmit}
          >
            {/* table1 */}
            <Form.List
              name="schedule_at_homes"
              rules={[
                {
                  validator: (rule, value) => {
                    if (
                      value?.some(
                        (item: any) =>
                          !item.schedule_date_at_homes?.filter((i: any) => i)
                            .length
                      )
                    )
                      return Promise.reject();
                    return Promise.resolve();
                  },
                  message: "入力されていない項目があります。再度ご確認ください",
                },
              ]}
            >
              {(field, { add, remove }, { errors }) => (
                <>
                  <Table
                    className="overflow-auto"
                    dataSource={field}
                    pagination={false}
                  >
                    <Column
                      align="center"
                      width={180}
                      title="サービス名称"
                      render={(value, record, index) => (
                        <>
                          <Form.Item name={[index, "id"]} hidden>
                            <div />
                          </Form.Item>
                          <Form.Item
                            name={[index, "service_home_system_id"]}
                            rules={[
                              {
                                required: true,
                                message: MESSAGES.requireField,
                              },
                            ]}
                            className="m-0 w-[180px]"
                          >
                            <SelectWithData
                              placeholder="選択してください"
                              getOptions={getServiceOptions}
                              className="w-full"
                            />
                          </Form.Item>
                        </>
                      )}
                    />
                    <Column
                      align="center"
                      width={200}
                      title="提供時間"
                      render={(value, record, index) => (
                        <Form.Item
                          name={[index, "time"]}
                          className="m-0 w-[200px]"
                          rules={[
                            { required: true, message: MESSAGES.requireField },
                          ]}
                        >
                          <TimePicker.RangePicker
                            format="HH:mm"
                            minuteStep={10}
                          />
                        </Form.Item>
                      )}
                    />
                    <Column
                      align="center"
                      width={180}
                      title="補足"
                      render={(value, record, index) => (
                        <Form.Item
                          name={[index, "supplement_options"]}
                          className="m-auto w-[100px]"
                        >
                          <Select
                            className="w-full"
                            placeholder="なし"
                            options={supplementOptions}
                          />
                        </Form.Item>
                      )}
                    />
                    {[...Array(month.daysInMonth())].map((item, index) => (
                      <ColumnGroup
                        title={index + 1}
                        className="col-date"
                        align="center"
                        key={index}
                      >
                        <Column
                          align="center"
                          className="col-date"
                          title={month
                            .locale("ja")
                            .date(index + 1)
                            .format("ddd")}
                          render={(value, record, subIndex) => (
                            <Form.Item
                              name={[subIndex, "schedule_date_at_homes", index]}
                              initialValue={false}
                              valuePropName="checked"
                              noStyle
                            >
                              <Checkbox
                                onChange={() => {
                                  form.validateFields(["schedule_at_homes"]);
                                  setIsShowButtons(true);
                                }}
                              />
                            </Form.Item>
                          )}
                        />
                      </ColumnGroup>
                    ))}
                    <Column
                      align="center"
                      title=""
                      className="col-date"
                      render={(value, record, index) => (
                        <>
                          {
                            schedules?.[index]?.schedule_date_at_homes?.filter(
                              (i: any) => i
                            ).length
                          }
                        </>
                      )}
                    />
                  </Table>
                  <Form.ErrorList errors={errors} className="text-[red]" />
                  <FlexRow
                    style={{ justifyContent: "flex-end", marginRight: "75px" }}
                    className="mt-2"
                  >
                    <Button
                      hidden={false}
                      onClick={add}
                      variant="outline"
                      transform="scale(0.75)"
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      p="0px"
                      border="1px solid #000"
                    >
                      <img
                        src={Plus}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                    <Button
                      hidden={false}
                      onClick={() =>
                        field.length > 1 && remove(field.length - 1)
                      }
                      variant="outline"
                      transform="scale(0.75)"
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      p="0px"
                      border="1px solid #000"
                    >
                      <img
                        src={Minus}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                  </FlexRow>
                </>
              )}
            </Form.List>
            {/* table1 */}

            <p className="mb-[24px] mt-[60px] font-semibold border-b border-solid border-[#000000] text-[20px]">
              申し送り
            </p>

            {/* tablel2 */}
            <Form.List
              name="schedule_memo_at_homes"
              //rules={[
              //  {
              //    validator: (rule, value) => {
              //      if (
              //        value?.some(
              //          (item: any) =>
              //            !item.schedule_date_at_homes?.filter((i: any) => i).length
              //        )
              //      )
              //        return Promise.reject();
              //      return Promise.resolve();
              //    },
              //    message: "入力されていない項目があります。再度ご確認ください",
              //  },
              //]}
            >
              {(field, { add, remove }, { errors }) => (
                <>
                  <Table
                    className="overflow-auto"
                    dataSource={field}
                    pagination={false}
                  >
                    <Column
                      align="center"
                      width={180}
                      title="内容"
                      render={(value, record, index) => (
                        <>
                          <Form.Item name={[index, "id"]} hidden>
                            <div />
                          </Form.Item>
                          <Form.Item
                            name={[index, "content"]}
                            rules={[
                              {
                                required: true,
                                message: MESSAGES.requireField,
                              },
                            ]}
                            className="m-0 w-[180px]"
                          >
                            <InputCustom />
                          </Form.Item>
                        </>
                      )}
                    />
                    <Column
                      align="center"
                      width={200}
                      title="提供時間"
                      render={(value, record, index) => (
                        <Form.Item
                          name={[index, "time"]}
                          className="m-0 w-[200px]"
                          rules={[
                            { required: true, message: MESSAGES.requireField },
                          ]}
                        >
                          <TimePicker.RangePicker
                            format="HH:mm"
                            minuteStep={10}
                          />
                        </Form.Item>
                      )}
                    />
                    {[...Array(month.daysInMonth())].map((item, index) => (
                      <ColumnGroup
                        title={index + 1}
                        className="col-date"
                        align="center"
                        key={index}
                      >
                        <Column
                          align="center"
                          className="col-date"
                          title={month
                            .locale("ja")
                            .date(index + 1)
                            .format("ddd")}
                          render={(value, record, subIndex) => (
                            <Form.Item
                              name={[subIndex, "schedule_date_at_homes", index]}
                              initialValue={false}
                              valuePropName="checked"
                              noStyle
                            >
                              <Checkbox
                                onChange={() => {
                                  form.validateFields();
                                  setIsShowButtons(true);
                                }}
                              />
                            </Form.Item>
                          )}
                        />
                      </ColumnGroup>
                    ))}
                    <Column
                      align="center"
                      title=""
                      className="col-date"
                      render={(value, record, index) => (
                        <>
                          {
                            schedulesMemo?.[
                              index
                            ]?.schedule_date_at_homes?.filter((i: any) => i)
                              .length
                          }
                        </>
                      )}
                    />
                  </Table>
                  <Form.ErrorList errors={errors} className="text-[red]" />
                  <FlexRow
                    style={{ justifyContent: "flex-end", marginRight: "75px" }}
                    className="mt-2"
                  >
                    <Button
                      hidden={false}
                      onClick={add}
                      variant="outline"
                      transform="scale(0.75)"
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      p="0px"
                      border="1px solid #000"
                    >
                      <img
                        src={Plus}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                    <Button
                      hidden={false}
                      onClick={() =>
                        field.length > 1 && remove(field.length - 1)
                      }
                      variant="outline"
                      transform="scale(0.75)"
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      p="0px"
                      border="1px solid #000"
                    >
                      <img
                        src={Minus}
                        alt=""
                        style={{ height: "20px", width: "20px" }}
                      />
                    </Button>
                  </FlexRow>
                </>
              )}
            </Form.List>
            {/* tablel2 */}
          </Form>
        </Styles>
        <div className="flex justify-end gap-x-[8px] mt-[85px]">
          {(schedules?.filter((item: any) => item?.id)?.length > 0 ||
            isShowButtons) && (
            <ButtonOutline
              onClick={() => {
                fetchInitialData();
                setIsShowButtons(false);
              }}
            >
              キャンセル
            </ButtonOutline>
          )}

          {schedules?.filter((item: any) => item?.id)?.length > 0 &&
            isShowPreview && (
              <ButtonOutline
                onClick={() => {
                  navigate(
                    `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_CUSTOMERS}/${idPatient}/preview`
                  );
                  localStorage.setItem("patientChangeID", idPatient || "");
                }}
              >
                プレビュー
              </ButtonOutline>
            )}

          {(schedules?.filter((item: any) => item?.id)?.length > 0 ||
            isShowButtons) && (
            <ButtonSolid className="!px-[54px]" onClick={form.submit}>
              保存
            </ButtonSolid>
          )}
        </div>
      </div>
    </div>
  );
}
