import Header from "component/header/Header";
import Dot from "assets/dot.png";
import { useNavigate } from "react-router-dom";
import { ButtonSolid } from "component/button";
import styled from "@emotion/styled";
import { CLIENTPATH } from "route";

/**Chau Tran: Add screen Menu Client "メインメニュー" */
const MenuClientPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col items-center">
        <StyleBtn
          onClick={() => navigate(CLIENTPATH.visitSchedule)}
          background="#E07B2E"
          style={{ marginTop: "154px" }}
        >
          訪問スケジュール
        </StyleBtn>
        <StyleBtn
          onClick={() => navigate(CLIENTPATH.historyList)}
          background="#E07B2E"
          style={{ marginTop: "25px" }}
        >
          対応記録 <img src={Dot} alt="" className="h-3 w-3 mx-2" /> 履歴
        </StyleBtn>
        {/* <StyleBtn
          onClick={() => navigate(CLIENTPATH.billingDepositHistory)}
          background="#E07B2E"
          style={{ marginTop: "25px" }}
        >
          請求 <img src={Dot} alt="" className="h-3 w-3 mx-2" /> 入金履歴
        </StyleBtn> */}
      </div>
    </div>
  );
};

const StyleBtn = styled(ButtonSolid)({
  height: "50px",
  width: "330px",
  padding: "10px 80px",
  border: "1px solid #000000",
  color: "var(--primary-text-light)",
  fontSize: "26px",
  borderRadius: "5px",
});
export default MenuClientPage;
