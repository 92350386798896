/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { ButtonOutline, ButtonSolid } from "component/button";
import apiFactory from "api";
import { NumericFormat } from "react-number-format";
import CustomPagination from "component/CustomPagination";

interface DataType {
  key: React.Key;
  id: string;
  serviceName: string;
  unitPrice: number;
}

const NursingCareServiceList = ({
  onChange,
  setServiceId,
  setDifference,
}: any) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortKey, setSortKey] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [dataList, setDataList] = useState([]);

  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: "ID",
        dataIndex: "service_code",
        sorter: {},
        width: "50px",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("service_code");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
      },
      {
        title: "サービス名称",
        dataIndex: "serviceName",
        sorter: {},
        render: (text) => {
          if (text.length > 30) {
            return text.slice(0, 30) + " ...";
          }
          return text;
        },
        width: "400px",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("service_name");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
      },
      {
        title: "単価",
        dataIndex: "unitPrice",
        render: (text) => {
          return (
            <NumericFormat
              className="bg-[initial]"
              disabled
              value={text}
              thousandsGroupStyle="wan"
              thousandSeparator=","
              decimalScale={2}
            />
          );
        },
        width: "250px",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("unit_price");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
      },
      {
        align: "right",
        title: "",
        key: "",
        render: (_, record) => (
          <ButtonOutline
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            onClick={() => onSelectItem(record.id, "view")}
          >
            詳細
          </ButtonOutline>
        ),
      },
    ],
    [sortKey, order]
  );

  const fetchData = async () => {
    const result = await apiFactory.careRegistrationApi.getServiceList(
      {
        per: limit,
        page: page,
        order: order,
        sortKey: sortKey,
      },
      "normal"
    );
    if (result) {
      setDataList(
        result?.serialized_services.map((e: any) => ({
          id: e.id,
          service_code: e.service_code,
          serviceName: e.service_name,
          unitPrice: e.unit_price,
        }))
      );
      setTotalItems(result?.total_items);
    }
  };
  const onSelectItem = (id: any, type: string) => {
    setServiceId(id);
    setDifference({
      type,
    });
    onChange();
  };
  useEffect(() => {
    fetchData();
  }, [page, limit, order, sortKey]);
  return (
    <div className="nursing-care-service-list pt-[7px]">
      <div className="text-end mb-[30px]">
        <ButtonSolid
          className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
          onClick={() => onSelectItem(null, "add")}
        >
          新規追加
        </ButtonSolid>
      </div>
      <div className="flex items-center justify-end wrapper-pagination mb-[15px]">
        <CustomPagination
          current={page}
          setCurrent={setPage}
          total={totalItems}
          perPage={limit}
          setPerPage={setLimit}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </div>
      <Table
        bordered
        rowClassName={(record, index) => {
          if (index % 2 !== 0) {
            return "bg-[#f5f4f2]";
          }
          return "";
        }}
        columns={columns}
        dataSource={dataList}
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: [5, 10],
          pageSize: limit,
          position: ["bottomCenter"],
          style: { display: "none" },
        }}
        showSorterTooltip={{ title: "" }}
      />
      <div className="flex items-center justify-end wrapper-pagination mt-[15px]">
        <CustomPagination
          current={page}
          setCurrent={setPage}
          total={totalItems}
          perPage={limit}
          setPerPage={setLimit}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </div>
    </div>
  );
};
export { NursingCareServiceList };
