import { CaretDown } from "assets/icons";
import { ReactNode, useState } from "react";
import StyledCollapse from "./styled";

type PropsType = {
  heading: string | ReactNode;
  children?: ReactNode;
  activeKey?: string | number | string[] | number[];
};

const NotificationCollapse = (props: PropsType) => {
  const [activeKey, setActiveKey] = useState<
    string | number | string[] | number[] | undefined
  >(props.activeKey);

  return (
    <StyledCollapse
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
      items={[
        {
          key: "1",
          label: (
            <div className="flex gap-4">
              <span className={`font-bold heading text-xl`}>
                {props.heading}
              </span>{" "}
              <span className={`transition-all icon`}>
                <CaretDown />
              </span>
            </div>
          ),
          showArrow: false,
          children: props.children,
        },
      ]}
    />
  );
};

export default NotificationCollapse;
