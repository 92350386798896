import { ClockCircleFilled } from "@ant-design/icons";
import { DatePicker, Form, Input } from "antd";
import { useWatch } from "antd/es/form/Form";
import apiFactory from "api";
import { FlexRow, StyleTitle } from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import ROUTES from "constant/routes";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IUpdateEvalueResponse } from "../AssessmentEdit/config";
import Styles from "./styled";
import { DATE_FORMAT, JP_DATEPICKER_FORMAT } from "constant/date";
import moment from "moment";
import dayjs from "dayjs";

const AssessmentSheet1Create = (props: any) => {
  const { onClose } = props;
  const { id: patientId } = useParams();
  const [form] = Form.useForm();

  const changeable_id = useWatch("id", form);

  //--- Handle submit
  const handleSubmit = async (formData: any) => {
    try {
      const res =
        await apiFactory.adminEvalueOneApi.createEvalue<IUpdateEvalueResponse | null>(
          {
            ...formData,
            created_date: formData.created_date
              ? formData.created_date.format(DATE_FORMAT)
              : dayjs().format(DATE_FORMAT),
            patient_id: patientId,
          }
        );

      if (res && res.success) {
        toast.success(res.success);
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Styles>
      <Form
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ flex: "110px" }}
        labelWrap
      >
        <FlexRow className="justify-end w-full">
          <ButtonSolid
            width="137px"
            height="35px"
            fontSize={14}
            onClick={() => {
              onClose();
            }}
          >
            戻る
          </ButtonSolid>
        </FlexRow>
        <div className="text-left max-w-[700px]">
          <StyleTitle>■基本情報</StyleTitle>
          <Form.Item label="生活歴" name="life_history">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="1日の生活リズム" name="daily_rhythm_of_life">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="日常生活の課題" name="daily_life_challenges">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="その他注意事項" name="note">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="性格" name="character">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="趣味・レク" name="hobbies">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="家族状況" name="family_circumstances">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="特記・他サービス利用" name="special_notes">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="現病・既往歴" name="current_illness_history">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="ご家族聞き取り" name="family_interview">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="ご本人聞き取り" name={"personal_interview"}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="作成日" name="created_date">
            <DatePicker
              disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              allowClear={true}
            />
          </Form.Item>
        </div>
        <div className="flex items-center justify-between w-full">
          <div>
            <Link
              to={`${ROUTES.EDIT_HISTORY}?changeable_type=EvalueOne&changeable_id=${changeable_id}`}
              hidden={!changeable_id}
            >
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
                <ClockCircleFilled />
                <span>履歴一覧</span>
              </div>
            </Link>
          </div>
          <div className="flex gap-x-[8px] justify-end">
            <ButtonOutline
              onClick={() => {
                onClose();
              }}
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid
              onClick={form.submit}
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
            >
              保存
            </ButtonSolid>
          </div>
        </div>
      </Form>
    </Styles>
  );
};

export default AssessmentSheet1Create;
