import { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputCustom from "component/admin/input";
import { ButtonOutline, ButtonSolid } from "component/button";
import { Styles } from "./styled";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import ROUTES from "constant/routes";
import { toast } from "react-toastify";
import axiosHS from "api/axiosHS";

export default function NursingCareDetail() {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { id } = useParams();
  const service = useService();
  const navigate = useNavigate();
  const location = useLocation();

  const [showInput, setShowInput] = useState(false);
  const [isDisabled, setIsDisabled] = useState(location.pathname.includes("create") ? false : true);
  const [firstValue, setFirstValue] = useState('');
  const [hiddenFirstInput, setHiddenFirstInput] = useState(false);
  const [isErrorFirstInp, setIsErrorFirstInp] = useState(false);
  const [errorServiceName, setErrorServiceName] = useState('');
  const [errorDisplayName, setErrorDisplayName] = useState('');

  const onTypeChange = (value: string) => {
    switch (value) {
      case 'checkbox':
        setShowInput(false)
        break;
      case 'dropdown':
        setShowInput(true);
        setHiddenFirstInput(false);
        break;
      case 'free_input':
        setShowInput(false)
        break;
      default:
    }
  };

  const handleChangeFirstInput = (e: any) => {
    setFirstValue(e.target.value); 
    setIsErrorFirstInp(!e.target.value ? true : false);
  }

  const handleBack = () => {
    if(!id) {
      navigate(-1);
    } else {
      fetchDetailService(); setIsDisabled(true)
    }
  }

  const onFinish = async (values: any) => {
    if(values?.selection_method === "dropdown" && !firstValue && (values?.dropdown_values === undefined || values?.dropdown_values?.length <= 0)) {
      setIsErrorFirstInp(true);
      return;
    }
    if(values?.selection_method === "dropdown" && (values?.dropdown_values === undefined || values?.dropdown_values?.length <= 0 || values?.dropdown_values?.length === undefined) && id) {
      setIsErrorFirstInp(true);
      return;
    }
    if (values?.dropdown_values && firstValue) {
      values?.dropdown_values?.unshift(`${firstValue}`)
    }
    try {
      const res = await axiosHS({
        method: id ? "put" : "post",
        url: `${service.HS_ADMIN}${id ? `/service_home_systems/${id}` : "/service_home_systems"}`,
        data: values?.dropdown_values ? values : { ...values, dropdown_values: [firstValue] },
      });
      
      if (res) {
        toast.success(res.data.success);
        setErrorServiceName('');
        setErrorDisplayName('');
        if (!id) {
          navigate(`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_NURSING_CARE}`);
        } else {
          setIsDisabled(true);
        }
      }
    } catch (err: any) {
      console.log('error: ', err);
      if(err.service_name && err.display_name) {
        setErrorServiceName(err.service_name);
        setErrorDisplayName(err.display_name);
      } else if(err.service_name) {
        setErrorServiceName(err.service_name);
        setErrorDisplayName('');
      } else if(err.display_name) {
        setErrorDisplayName(err.display_name);
        setErrorServiceName('');
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    if(errorInfo?.values?.selection_method === "dropdown" && !firstValue) {
      setIsErrorFirstInp(true);
    }
  };

  const fetchDetailService = async () => {
    if (!id) return;
    const res = await axiosClient({
      method: "get",
      url: `${service.HS_ADMIN}/service_home_systems/${id}`,
    });
    if (res) {
      form.setFieldsValue(res.data);
      if (res?.data?.dropdown_values) {
        setShowInput(true);
        setHiddenFirstInput(true);
      }
    }
  }

  useEffect(() => {
    fetchDetailService()
  }, [id])

  return (
    <Styles>
      <div className="page-title">{location.pathname.includes("create") ? "介護内容登録" : "介護内容詳細"}</div>
      <div className="page-container">
        {id && isDisabled && <div className="flex justify-end">
          <ButtonSolid onClick={() => setIsDisabled(false)} className="!px-[54px]">編集</ButtonSolid>
        </div>}
        <Form
          disabled={!id ? false : isDisabled}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="サービス名称" name="service_name"
            rules={[
              { required: true, message: 'この項目は必須です' },
            ]}>
            <InputCustom className={errorServiceName ? '!border-[#ff4d4f]' : ''} />
          </Form.Item>
           {errorServiceName && <p className="text-[#ff4d4f] ml-[159px] mt-[-20px]">{errorServiceName}</p>}
          <Form.Item label="表示名" name="display_name"
            rules={[
              { required: true, message: 'この項目は必須です' },
            ]}>
            <InputCustom className={errorDisplayName ? '!border-[#ff4d4f]' : ''} />
          </Form.Item>
          {errorDisplayName && <p className="text-[#ff4d4f] ml-[159px] mt-[-20px]">{errorDisplayName}</p>}
          <Form.Item label="選択項目" name="selection_method"
            rules={[
              { required: true, message: 'この項目は必須です' },
            ]}>
            <Select
              placeholder="選択してください"
              onChange={onTypeChange}
            >
              <Option value="checkbox">チェックボックス</Option>
              <Option value="dropdown">プルダウン</Option>
              <Option value="free_input">自由入力</Option>
            </Select>
          </Form.Item>

          {showInput &&
            <Form.List
              name="dropdown_values"
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {(!hiddenFirstInput && showInput) && (
                    <Form.Item
                      label={<p><span className="text-[#e07b2e]">※</span>選択肢</p>}
                      rules={[
                        { required: true, message: 'この項目は必須です' },
                      ]}
                    >
                      <div className="flex">
                        <InputCustom className={isErrorFirstInp ? '!border-[#ff4d4f]' : ''} onChange={handleChangeFirstInput} />
                        <div className="icon-container" onClick={() => add()}>
                          <PlusOutlined />
                        </div>
                        <div className="icon-container" style={{cursor: "not-allowed", opacity: 0.5}}>
                          <MinusOutlined />
                        </div>
                      </div>
                      {isErrorFirstInp && <p className="text-[#ff4d4f]">この項目は必須です</p>}
                    </Form.Item>
                  )}
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 && hiddenFirstInput ? <p><span className="text-[#e07b2e]">※</span>選択肢</p> : <></>}
                      key={field.key}
                    >
                      <div className="flex">
                        <Form.Item
                          {...field}
                          //validateTrigger={['onChange', 'onBlur']}
                          //noStyle
                          rules={[
                            { required: true, message: 'この項目は必須です' },
                          ]}
                          className="w-[50%] !mb-0"
                        >
                          <InputCustom className="!w-full" />
                        </Form.Item>
                        {
                          !isDisabled && (
                            <div className="flex">
                              <div className="icon-container" onClick={() => add()}>
                                <PlusOutlined />
                              </div>
                              <div className={`icon-container ${index === 0 && fields?.length <= 1 && hiddenFirstInput ? 'icon-not-allowed' : ''}`} onClick={() => index === 0 && fields?.length <= 1 && hiddenFirstInput ? {} : remove(field.name)}>
                                <MinusOutlined />
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </Form.Item>
                  ))}

                </>
              )}
            </Form.List>
          }
        </Form>
        {
          !isDisabled && (
            <div className="flex justify-end gap-x-[8px] mt-[85px]">
              <ButtonOutline onClick={handleBack}>キャンセル</ButtonOutline>
              <ButtonSolid onClick={form.submit} className="!px-[54px]">保存</ButtonSolid>
            </div>
          )
        }
      </div>
    </Styles >
  )
}
