import BaseApi from "api/baseApi";
import { DASHBOARD_HS_ADMIN } from "../apiConstant";

class HSAdminApi extends BaseApi {
  getDashboard() {
    return this.get(DASHBOARD_HS_ADMIN);
  }
}

export default HSAdminApi;
