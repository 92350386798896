import { ReactNode } from "react";
import Header from "component/header/Header";
import Sidebar from "component/sidebarAdmin";
import Breadcrumbs from "component/breadcrumb";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

const Styles = styled.div`
  .container-layout:has(.page-title-fixed) {
    .container-breadcrumbs {
      position: fixed;
      padding: 10px 0;
      top: 50px;
    }
  }
`;

export interface ILayoutProps {
  children?: ReactNode;
  isHeader?: boolean;
  isTabs?: boolean;
  items?: Array<{ key: string; label: ReactNode; children: ReactNode }>;
  title?: ReactNode;
  isShowSidebar?: boolean;
}

export default function AdminLayout({
  children,
  isHeader = true,
  isTabs = false,
  items = [],
  title,
  isShowSidebar = true,
}: ILayoutProps) {
  const { pathname } = useLocation();

  const adminNotiListLayout = pathname === "/admin/notice";

  return (
    <Styles>
      <div className="text-start">
        <Sidebar
          className={`fixed top-0 z-50 h-[100vh] w-[250px] ${
            isShowSidebar ? "" : "hidden"
          }`}
        />
        <Header title="" isMaster={true} isMenu={true} />
        <div
          className={`${
            isShowSidebar ? "w-[calc(100vw-255px)]" : "w-[calc(100vw-5px)]"
          } ${isShowSidebar ? "ml-[250px]" : ""} mt-[50px] relative`}
        >
          {adminNotiListLayout ? (
            <div
              className={`min-h-[calc(100vh-50px)] flex-[1_1_82%] text-[#000000]`}
            >
              {isHeader && (
                <div className="flex items-center gap-x-[13px] text-[12px] leading-[20px] px-[20px] pt-[12px] relative z-[1]">
                  <Breadcrumbs />
                </div>
              )}
              {children}
            </div>
          ) : (
            <div
              className={`min-h-[calc(100vh-50px)] bg-[#EEEEEE] flex-[1_1_82%] px-[20px] py-[12px] text-[#000000] container-layout`}
            >
              {isHeader && (
                <div className="flex items-center gap-x-[13px] text-[12px] leading-[20px] relative z-[1] w-full bg-[#EEEEEE] container-breadcrumbs">
                  <Breadcrumbs />
                </div>
              )}
              {children}
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
}
