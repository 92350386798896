import React from "react";

type PropsType = {
  children: React.ReactNode;
  className?: string;
};

const ContentLayout = (props: PropsType) => {
  return (
    <div className={`bg-white py-8 pl-3 pr-14 ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

export default ContentLayout;
