import { useState } from "react";
import { TabList, Tab, Tabs, TabPanels, TabPanel } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { DATE_FORMAT, MONTH_FORMAT } from "constant/date";
import dayjs, { Dayjs } from "dayjs";
import SelectTime from "component/SelectTime";
import { ButtonOutline } from "component/button";
import NursingCareRecords from "./NursingCareRecords";
import Tab2 from "./Tab2";
import { useLocation, useNavigate } from "react-router-dom";

export default function HomeSchedule() {
  const [tabIndex, setTabIndex] = useState(0);
  const [day, setDay] = useState<Dayjs>(dayjs());
  const [month, setMonth] = useState<Dayjs>(dayjs());
  const [openViewByDay, setOpenViewByDay] = useState(true);
  const [patient, setPatient] = useState<any>(null);
  const [patient2, setPatient2] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  
  const classNameContainer = `page-title ${location.pathname.includes("home-system-staff") ? 'hidden' : ''}`;

  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
    if (evt === 0) {
      setOpenViewByDay(true);
    }
    if (evt === 1) {
      setOpenViewByDay(false);
    }
    //if (evt === 2) {
    //  setTabName("日別");
    //}
  };
  const handleBack = () => {
    navigate(-1);
    localStorage.setItem("backToScreen4", "true");
  }
  return (
    <div>
      {location.pathname.includes("preview") ? (
        <div className="page-title">
          {tabIndex === 0 ? patient?.family_name : (patient2?.label ? patient2?.label : (location.pathname.includes("preview") ? localStorage.getItem("patientName") : ""))}  様
        </div>
      ) : <div className={classNameContainer}>
        ホーム　予定
      </div>}

      <div className="page-container">
        <Tabs onChange={onChangeTab}>
          <div className="flex justify-between">
            <div className="flex items-center gap-x-[20px]">
              <TabList
                border="1px solid black"
                width="fit-content"
                height="30px"
                overflow="hidden"
                fontSize="12px"
                borderRadius="5px"
              >
                <StyleTab _selected={{ color: "white", bg: "brand.500" }}>
                  介護記録
                </StyleTab>
                <StyleTab _selected={{ color: "white", bg: "brand.500" }}>
                  バイタル
                </StyleTab>
              </TabList>
              {
                openViewByDay ?
                  <SelectTime
                    format={DATE_FORMAT}
                    type="day"
                    value={day}
                    onChange={setDay}
                  /> :
                  <SelectTime
                    format={MONTH_FORMAT}
                    type="month"
                    value={month}
                    onChange={setMonth}
                  />
              }
            </div>
            {location.pathname.includes("preview") && (
              <ButtonOutline onClick={handleBack} className="!w-[116px]">戻る</ButtonOutline>
            )}
          </div>
          <TabPanels>
            <TabPanel>
              <NursingCareRecords setPatientInfo={setPatient} dataDay={day} />
            </TabPanel>
            <TabPanel>
              <Tab2 setPatientInfo={setPatient2} dataMonth={month} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  )
}

const StyleTab = styled(Tab)({
  fontSize: "12px",
  borderRadius: "0px",
  padding: "5px 10px",
  fontWeight: "bold"
});

