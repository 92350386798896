import { useState, useEffect } from 'react';
import { Box, Table, TableContainer, Text, Tr, Td } from "@chakra-ui/react";
import { Checkbox, Select, Tooltip } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { StyleContainer, Styles } from "./styled";
import ModalCustom from 'component/admin/modal';
import InputCustom from 'component/admin/input';
import { ButtonSolid } from 'component/button';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DATE_FORMAT, MONTH_FORMAT } from 'constant/date';
import axiosClient from 'api/axiosClient';
import useService from 'hook/useService';
import { toast } from 'react-toastify';
import ROUTES from "constant/routes";
interface ITableProps {
  dataDay: any;
  setPatientInfo: any;
}

export default function NursingCareRecords({ dataDay = null, setPatientInfo }: ITableProps) {
  const [openPopup, setOpenPopup] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<any>();
  const [isChangeInp, setIsChangeInp] = useState(false);
  const [infos, setInfos] = useState<any[]>([]);
  const [type, setType] = useState('');
  const [dropdownValues, setDropdownValues] = useState<any[]>([]);
  const [patient, setPatient] = useState<any>(null);
  const [pationOptions, setPatientOptions] = useState<any[]>([]);
  const [idSelectedRecord, setIdSelectedRecord] = useState('');
  const [idPatient, setIdPatient] = useState('');
  const [firstPatient, setFirstPatient] = useState<any>(null);

  const { id } = useParams();
  const service = useService();
  const location = useLocation();
  const isPreview = location.pathname.includes("preview");
  const SERVICE_NAME = location.pathname.includes("home-system-admin") ? service.HS_ADMIN : service.HS_STAFF;
  const [selected, setSelected] = useState<any>(null);
  const [isShowData, setIsShowData] = useState(false);

  const dataHour = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const classNamePatient = infos?.length <= 1 ? 'title mt-[34px]' : 'title';
  const styleTrCustom = infos?.length <= 1 ? {height: `${patient?.family_name?.length * 23}px`} : {}

  const renderClassNameBg = (arr: any, index: any) => {
    let className = '';
    arr?.map((item: any) => {
      let time_start = parseInt(item?.start_time?.slice(0, -3));
      let time_end = parseInt(item?.end_time?.slice(0, -3)) - 1
      if (index >= time_start && index <= time_end) {
        className = "cursor-pointer !border-t-[2px] !border-t-solid !border-t-[#000000] !border-b-[2px] !border-b-solid !border-b-[#000000]";
      }
    });
    return className;
  }

  const onChangeCheckedValue = async (e: CheckboxChangeEvent, indexHour: any, record: any) => {
    if(!record?.id) return;
    await handleSubmit(record?.id, e.target.checked.toString());
  }

  const renderValueAdapCellWidth = (text: any, numCells: number) => {
    if(!text) return '';
    let lengthText = text?.length;
    let totalTextForBox = 8 * numCells;
    if(lengthText > totalTextForBox) {
      return <p className='cell-content' style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: `${numCells*73.54}px`}}>{text}</p>
    } else {
      return <p className='cell-content'>{text}</p>
    }
  }

  const renderContentCell = (arr: any, indexHour: any, indexRow: any, type: any, dataCell: any) => (
    arr?.map((item: any, index: any) => {
      let time_start = parseInt(item?.start_time.slice(0, -3));
      let time_end = parseInt(item?.end_time.slice(0, -3)) - 1;
      let numCells = time_end - time_start + 1;
      let classNameCustom = '';
      //type === 'checkbox' && indexHour >= time_start && indexHour <= time_end
      if (type === 'checkbox') {
        if(indexHour >= time_start && indexHour <= time_end) {
          let classContainerCheckbox = '';
          if (time_start === indexHour) {
            classContainerCheckbox += `absolute w-[101.5%] h-full top-0 left-0 border-l-[2px] border-l-solid border-l-[#000000] ml-[-1px]`
          }
          if (time_end === indexHour) {
            classContainerCheckbox += 'absolute w-[101.5%] h-full top-0 left-0 border-r-[2px] border-r-solid border-r-[#000000]'
          }
          //checked={indexClick >= time_start && indexClick <= time_end ? checked : item?.value}
          return <div key={index} className={classContainerCheckbox}><Checkbox style={{ position: "absolute", top: '50%', left: '50%', transform: "translate(-50%, -50%)" }} checked={item?.value === 'true' ? true : false} onChange={(e) => onChangeCheckedValue(e, indexHour, item)} /></div>
        } else {
          if(index > 0) return;
          return <Checkbox disabled />
        }
        
      }

      if (numCells <= 1) {
        classNameCustom = "border-r-[2px] border-r-solid border-r-[#000000] w-[103%]"
      }
      if (time_start === indexHour) {
        return <div className={`absolute w-[101.5%] h-full top-0 left-0 border-l-[2px] border-l-solid border-l-[#000000] ml-[-1px] ${classNameCustom}`}>
          <Tooltip title={item?.value}>
            {renderValueAdapCellWidth(item?.value, numCells)}
          </Tooltip>
        </div>

      }
      if (time_end === indexHour) {
        return <p className={'absolute w-[101.5%] h-full top-0 left-0 border-r-[2px] border-r-solid border-r-[#000000]'}>
          {""}
        </p>
      }

    })
  )

  const handleOpenPopup = (arr: any, indexHour: any, name: any, record: any) => {
    //if(isPreview) return;
    if (record?.dropdown_values) {
      const arr = record?.dropdown_values?.map((it: any) => {
        return { value: it, label: it }
      })
      setDropdownValues(arr);
    }

    arr?.map((item: any) => {
      let time_start = parseInt(item?.start_time.slice(0, -3));
      let time_end = parseInt(item?.end_time.slice(0, -3))
      if (indexHour >= time_start && indexHour < time_end || indexHour === time_start) {
        setOpenPopup(true);
        setTitle(name);
        setType(record?.selection_method);
        setContent(item?.value);
        setIdSelectedRecord(item?.id);
      }
      else {
        return;
      }
    })
  }

  const handleSubmit = async(idParam: any, value: any) => {
    const data = {
      id: idParam,
      value: value || '',
    }
    try {
      const res = await axiosClient({
        method: "put",
        url: `${SERVICE_NAME}/schedule_date_at_homes`,
        data: data,
      });
      toast.success(res.data.success);
      setContent('');
      setIsChangeInp(false);
      //fetchPatientOptions();
      if(idPatient) {
        fetchSchedulesAtHome(idPatient);
      } else if(id) {
        fetchSchedulesAtHome(id);
      } else if(firstPatient) {
        fetchSchedulesAtHome(firstPatient?.value);
      }
      
    } catch (error) {
      toast.error((error as string).toString());
      console.error(error);
    }
  }

  const handleCancelPopup = async () => {
    setOpenPopup(false);
    if(!idSelectedRecord || !isChangeInp) return;
    await handleSubmit(idSelectedRecord, content)
  }

  const handleChangeInp = (e: any) => {
    setContent(e.target.value);
    setIsChangeInp(true);
  }

  const handleChangeSelectService = async (value: string) => {
    if(!value) return;
    await handleSubmit(idSelectedRecord, value);
    setOpenPopup(false);
  }
  const handleChangePatient = (value: string) => {
    if (!value) return;
    setIdPatient(value);
    fetchSchedulesAtHome(value);
    localStorage.setItem("patientChangeID", value);
    setFirstPatient(null);
    setSelected(value);
    setIsShowData(true);
  };

  const fetchSchedulesAtHome = async (id: any) => {
    if (!id) return;
    try {
      const res = await axiosClient({
        method: "get",
        url: `${SERVICE_NAME}/schedule_date_at_homes`,
        params: {
          patient_id: id,
          date: dataDay.format(DATE_FORMAT),
        },
      });

      setPatient(res?.data?.data[res?.data?.data?.length - 1]?.patient);
      setPatientInfo(res?.data?.data[res?.data?.data?.length - 1]?.patient);
      const infosHasSchedules = res?.data?.data?.filter((item: any) => item?.schedules?.length > 0)
      setInfos(infosHasSchedules);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPatientOptions = async () => {
    try {
      const res = await axiosClient({
        method: "get",
        url: `${SERVICE_NAME}/patients`,
        params: {
          schedule_date: dataDay.format(DATE_FORMAT),
        },
      });
      const options = res?.data?.data?.map((item: any) => {
        return { label: item?.family_name, value: item?.id }
      })
      setPatientOptions(options);
      if(options[0] && !id) {
        setFirstPatient(options[0]);
        setSelected(options[0]?.value)
        await fetchSchedulesAtHome(options[0]?.value);
      } else if(options[0] && id) {
        const optionHasData = options?.filter((item: { value: string; }) => item.value == id)
        setSelected(optionHasData?.length > 0 ? parseInt(id) : null)
        setIsShowData(optionHasData?.length > 0 ? true : false);
      } else {
        setPatient(null);
        setInfos([]);
        //setPatientOptions([]);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchSchedulesAtHome(id);
    }
    fetchPatientOptions();
    setIdPatient('');
    setSelected(null);
    if(!isPreview) {
      setIsShowData(true);
    }
  }, [dataDay]);
  return (
    <StyleContainer>
      <div className="flex items-center justify-between">
        <div className='flex items-center gap-x-[4px]'>
          <p className='text-[16px]'>お客様名</p>
          <Select
            style={{ width: 150, borderRadius: '8px' }}
            onChange={handleChangePatient}
            options={pationOptions || []}
            value={selected}
          />
        </div>
        {!isPreview && (
          <Link to={`/${location.pathname.includes("home-system-admin") ? ROUTES.HOME_SYSTEM : ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_EQUIPMENT_SERVICES}`}>
            <ButtonSolid>備品利用登録</ButtonSolid>
          </Link>
        )}
     
      </div>
      <Styles className='mb-[80px]'>
        {isShowData && (
          <div className={classNamePatient}>{patient?.family_name}</div>
        )}
        <TableContainer>
          <Table>
            <Tr>
              <Td></Td>
              {dataHour.map((item, index) => (
                <Td className='min-w-[70.25px]' key={index}>{item}</Td>
              ))}
            </Tr>
            {isShowData && infos?.map((item, index) => (
              <Tr style={styleTrCustom}>
                <Td
                  style={{
                    borderLeft: "1px solid #ececec",
                    whiteSpace: "normal",
                    //maxWidth: "50px",
                  }}
                >
                  <p>{item?.display_name}</p>
                </Td>
                {dataHour?.map((hour, indexHour) => (
                  <Td
                    style={{
                      border: "1px solid #ececec",
                      position: "relative",
                      //maxWidth: "50px",
                      whiteSpace: "normal"
                    }}
                    className={renderClassNameBg(item?.schedules, indexHour)}
                    onClick={() => item?.selection_method === 'checkbox' ? {} : handleOpenPopup(item?.schedules, indexHour, item?.display_name, item)}
                  >
                    {item?.schedules && renderContentCell(item?.schedules, indexHour, index, item?.selection_method, item)}
                  </Td>
                ))}
              </Tr>
            ))}

          </Table>
        </TableContainer>
        <ModalCustom width={230} footer={null} title={title} open={openPopup} onCancel={handleCancelPopup}>
          {
            type === 'dropdown' ? <Select
              value={content}
              onChange={handleChangeSelectService}
              options={dropdownValues}
              className='!w-full'
            /> : <InputCustom value={content} onChange={(e: any) => handleChangeInp(e)} />
          }

        </ModalCustom>
      </Styles>
    </StyleContainer>
  )
}
