import dayjs from "dayjs";
import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { Col, Row } from "antd";
import { 
  TabPanel, 
  Box, 
  Text, 
  TableContainer, 
  Tr, 
  Table,
  Tbody,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal
} from "@chakra-ui/react";
import { StyleTd } from "component/StyledComponent";
import { CellClickEvent } from "devextreme/ui/scheduler";
import ScheduleRegister from "../components/Modal/ScheduleRegister";
import apiFactory from "api";
import moment from "moment";
import { DATE_FORMAT_2 } from "constant/date";
import ScheduleDetail from "../components/Modal/ScheduleDetail";
import ShiftTimeAdjust from "../components/Modal/ShiftTimeAdjust";
import { 
  DndContext, 
  DragEndEvent, 
  DragOverlay,
  DragStartEvent,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
  MouseSensor,
  PointerSensor
} from "@dnd-kit/core";
import { ButtonOutline, ButtonSolid } from "component/button";
import { calculateTime, convertToTimeString, removePercentSign } from "../Calendar";
import StaffScheduleDetail from "../components/Modal/StaffScheduleDetail";
import { toast } from "react-toastify";
import Prompt from "../components/Week/promt";

interface Props {
  type: string;
  currentDate: any;
  editMode: boolean;
  setShowRegisterButtons: Dispatch<SetStateAction<boolean>>;
  createShift: any;
  isStaffScheduleEditedState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const dataHour = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const convertStringTimeToNum = (stringTime: string): number[] => {
  const stringTimeArr = stringTime.split(":");
  const numTimeArr = stringTimeArr.map((item: string) => Number(item));
  return [...numTimeArr];
}

const getTimePeriod = (startTime: string, endTime: string): number => {
  const startHour = convertStringTimeToNum(startTime)[0];
  const endHour = convertStringTimeToNum(endTime)[0];
  const endMinute = convertStringTimeToNum(endTime)[1];
  let period;

  if (endMinute > 0) {
    period = (endHour - startHour) + (endMinute / 60);
  } else {
    period = endHour - startHour;
  }

  return period;
}

const getScheduleBoxWidth = (startTime: string, endTime: string, unit: string = '%'): string => {
  if (!startTime || !endTime) {
    return "100%";
  }

  const period = getTimePeriod(startTime, endTime);
  const width = period * 100;

  let widthValue: string;

  if (unit) {
    widthValue = "" + width + unit;
  } else {
    widthValue = String(width);
  }

  return widthValue;
}

interface ScheduleCellProps {
  children: JSX.Element;
  bgColor: string;
  editMode: boolean;
  hour: string;
  staffName: string;
  serviceIndex: number;
  staffId: number;
}

const ScheduleCell: React.FC<ScheduleCellProps> = (props) => {
  const { isOver, setNodeRef: setDroppableNodeRef } = useDroppable({
    id: `${props.staffName}_${props.serviceIndex}_${props.hour}_droppableCell`,
    disabled: !props.editMode,
    data: { 
      hour: props.hour, 
      serviceIndex: props.serviceIndex, 
      staffName: props.staffName,
      staffId: props.staffId,
    }
  });
  const backgroundColor: string = (props.bgColor === "#f6d7c0" && isOver) 
    ? "#dbb9a0"
    : (props.bgColor === "#f6d7c0" && !isOver)
    ? "#f6d7c0"
    : (props.bgColor === "" && isOver)
    ? "#e6e6e6"
    : "";
  return (
    <StyleTd
      ref={setDroppableNodeRef}
      padding="0px"
      position="relative"
      style={{
        border: "1px solid #e0e0e0",
        height: "88px",
        width: "70px",
      }}
      bg={backgroundColor}
    >
      {props.children}
    </StyleTd>
  );
}

interface ScheduleItemBoxProps {
  editMode: boolean;
  scheduleItem: any;
  boxWidth: string;
  dragId: string;
  setSelectedScheduleItem: Dispatch<any>;
  setOpenDetail: Dispatch<SetStateAction<boolean>>;
  serviceIndex: number;
}

const ScheduleItemBox: React.FC<ScheduleItemBoxProps> = (props) => {
  const { attributes, listeners, setNodeRef: setDraggableNodeRef, isDragging } = useDraggable({
    id: props.dragId,
    disabled: !props.editMode,
    data: props
  });
  return (
    <Box
      ref={setDraggableNodeRef}
      width={`${props.boxWidth}`}
      height="100%"
      bg={props.scheduleItem.patient_name ? "#1AD633" : "white"}
      border="2px solid var(--primary-color)"
      display='flex'
      position="absolute"
      top={0}
      left={0}
      zIndex={100}
      onClick={() => {
        if (props.editMode) {
          props.setOpenDetail(true);
          props.setSelectedScheduleItem(props.scheduleItem);
        }
      }}
      {...listeners}
      {...attributes}
      visibility={isDragging ? "hidden" : "inherit"}
    >
      {
        props.editMode ? (
          <Text
            // paddingX="8px"
            lineHeight="13px"
            textAlign="center"
            fontSize="12px"
            whiteSpace="pre-wrap"
            margin='auto'
            width="100%"
            cursor="pointer"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {props.scheduleItem.patient_name || ""}
          </Text>
        ) : (
          <Popover trigger="hover">
            <PopoverTrigger>
              <Text
                // paddingX="8px"
                lineHeight="13px"
                textAlign="center"
                fontSize="12px"
                whiteSpace="pre-wrap"
                margin='auto'
                width="100%"
                cursor="pointer"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {props.scheduleItem.patient_name || ""}
              </Text>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <div>{props.scheduleItem.patient_name || ""}</div>
                  <div>{props.scheduleItem.time_range || ""}</div>
                  <div>{props.scheduleItem.service_name || ""}</div>
                  <div>担当 : {props.scheduleItem.staffName || ""}</div>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )
      }
    </Box>
  );
}

export default function StaffSchedule({ 
  type, 
  currentDate, 
  editMode, 
  setShowRegisterButtons, 
  createShift,
  isStaffScheduleEditedState,
}: Props) {
  const [popupState, setPopupState] = React.useState({});
  const [showScheduleAdjust, setShowScheduleAdjust] = useState<boolean>(false);
  const [dateView, setDateView] = useState(new Date())
  const [dataStaff, setDataStaff] = useState<any[]>([]);
  const [allShiftDatas, setAllShiftDatas] = useState<any[]>([]);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [openTimeAdjust, setOpenTimeAdjust] = useState<boolean>(false);
  const [selectedShiftItem, setSelectedShiftItem] = useState<any>();
  const [selectedTimeItem, setSelectedTimeItem] = useState<any>();
  const [draggedData, setDraggedData] = useState<ScheduleItemBoxProps | undefined>();
  const [isStaffScheduleEdited, setIsStaffScheduleEdited] = isStaffScheduleEditedState;
  
  const onGetDate = (evt: any) => {
    setDateView(evt)
  }

  const fetchShiftDatas = async () => {
    try {
      const res = await apiFactory.shiftManagementApi.getDaySchedule({
        specific_date: moment(currentDate).format(DATE_FORMAT_2),
        tab: type
      })
      if (res) {
        const mappingData = Object.values(res.data);
        let shiftDatas = mappingData.map((item: any, index: number) => {
          const staffName = item.family_name;
          let processedShifts = item.shifts;
          for (const key in processedShifts) {
            let shiftServiceData = processedShifts[key];
            processedShifts[key] = shiftServiceData.map((record: any, recordIndex: number) => {
              return ({
                ...record,
                staffName,
                staffId: item.id,
                dragId: `${staffName}_${key}_${record.schedule_date.id}_draggableBox`,
                schedule_date: {
                  ...record.schedule_date,
                  timePeriod: getTimePeriod(record.schedule_date.start_time, record.schedule_date.end_time)
                },
                edited: false,
                delete: false,
              })
            })
          }
          processedShifts = Object.values(processedShifts);

          let procressShiftManagement = item.shift_managements;
          let registeredHours: number[] = [];
          procressShiftManagement = procressShiftManagement.map((manageItem: any, manageIndex: number) => {
            const startHour = convertStringTimeToNum(manageItem.start_time)[0];
            const startMinute = convertStringTimeToNum(manageItem.start_time)[1];
            const  endHour = convertStringTimeToNum(manageItem.end_time)[0];
            const endMinute = convertStringTimeToNum(manageItem.end_time)[1];
            const itemHours: number[] = [];

            for (let h = startHour; h < endHour; h++) {
              itemHours.push(h);
              registeredHours.push(h);
            }

            return {
              ...manageItem,
              startHour,
              startMinute,
              endHour,
              endMinute,
              edited: false,
              delete: false,
              itemHours,
            }
          })
          return {
            ...item,
            shifts: processedShifts,
            shift_managements: procressShiftManagement,
            registeredHours,
            edited: false,
          };
        });

        // setAllShiftItems(
        //   mappingData.map((dataStaffItem: any) => dataStaffItem.shifts)
        //       .map((shiftItem: any) => {
        //         for (const key in shiftItem) {
        //           let shiftItemClientData = shiftItem[key];
        //           shiftItem[key] = shiftItemClientData.map((dataItem: any) => ({...dataItem, shiftName: key }));
        //         }
        //         return Object.values(shiftItem);
        //       })
        //       .flat()
        // );
        setDataStaff(mappingData);
        setAllShiftDatas(shiftDatas);
      }
    } catch (error) {
      // 
    }
  }

  const handleDragStart = (e: DragStartEvent) => {
    const eventDragBoxData = e.active.data.current as ScheduleItemBoxProps;
    const draggedBoxData = {
      ...eventDragBoxData,
      boxWidth: `${(Number(removePercentSign(eventDragBoxData.boxWidth)) / 100) * 70}px`
    };
    setDraggedData(draggedBoxData as ScheduleItemBoxProps);
  }

  const handleDragEnd = (e: DragEndEvent) => {
    if (!e.over) {
      setDraggedData(undefined);
      return;
    }

    const dropStaffRow = e.over.data.current?.staffId;
    const dropServiceRow = e.over.data.current?.serviceIndex;
    const dropHour = e.over.data.current?.hour;
    const dragStaffItem = e.active.data.current?.scheduleItem?.staffId;
    const dragServiceItem = e.active.data.current?.serviceIndex;
    const dragId = e.active.data.current?.dragId;
    const period = e.active.data.current?.scheduleItem?.schedule_date?.timePeriod;

    if (
      dropStaffRow === undefined ||
      dropServiceRow === undefined ||
      dropHour === undefined ||
      dragStaffItem === undefined ||
      dragServiceItem === undefined ||
      dragId === undefined ||
      period === undefined
    ) {
      setDraggedData(undefined);
      return;
    }

    if (dropStaffRow !== dragStaffItem || dropServiceRow !== dragServiceItem) {
      setDraggedData(undefined);
      return;
    }

    const newStartTime = convertToTimeString(dropHour);
    const calculatedNewEndTime = Number(dropHour) + period;
    const newEndTime = calculateTime(calculatedNewEndTime);

    if (newStartTime.includes("Invalid") || newEndTime.includes("Invalid")) {
      setDraggedData(undefined);
      return;
    }

    setAllShiftDatas((prevData) => {
      let newData = [...prevData];

      let targetStaffRow = newData.find((item) => item.id === dropStaffRow);
      let targetServiceRow = targetStaffRow.shifts[dropServiceRow];

      targetServiceRow = targetServiceRow.map((item: any) => {
        if (item.dragId === dragId) {
          item.schedule_date.start_time = newStartTime;
          item.schedule_date.end_time = newEndTime;
          item.time_range = `${newStartTime} ~ ${newEndTime}`;
          item.edited = true;
          return item;
        } else {
          return item;
        }
      });

      targetStaffRow.shifts[dropServiceRow] = targetServiceRow;
      targetStaffRow.edited = true;

      return newData.map((item) => {
        if (item.id === targetStaffRow.id) {
          return targetStaffRow;
        } else {
          return item;
        }
      });
    });

    setIsStaffScheduleEdited(true);
    setDraggedData(undefined);
  }

  const handleOpenTimeAdjust = (data: any) => {
    setOpenTimeAdjust(true);
    setSelectedTimeItem(data);
  }

  const handleUpdateRecords = async () => {
    let editedDatas: any[] = [];
    let editedScheduleDates: any[] = [];
    let editedShiftManagements: any[] = [];
    editedDatas = allShiftDatas.filter((item: any) => item.edited);
    editedDatas.forEach((item: any) => {
      item.shift_managements.forEach((manageItem: any) => {
        if (manageItem && manageItem.edited) {
          if (manageItem.delete) {
            editedShiftManagements.push({
              id: manageItem.id,
              nurse_id: item.id,
              delete: manageItem.delete,
            });
          } else {
            editedShiftManagements.push({
              id: manageItem.id,
              start_time: manageItem.start_time,
              end_time: manageItem.end_time,
              nurse_id: item.id,
            });
          }
        }
      });

      item.shifts.forEach((item: any) => {
        if (item) {
          item.forEach((subItem: any) => {
            if (subItem && subItem.edited) {
              editedScheduleDates.push({
                id: subItem.schedule_date?.id,
                start_time: subItem.schedule_date?.start_time,
                end_time: subItem.schedule_date?.end_time,
              });
            }
          })
        }
      })
    });

    try {
      const response = await apiFactory.shiftManagementApi.updateStaffShiftManage({
        button_type: "draft",
        schedule_dates: editedScheduleDates.length > 0 ? editedScheduleDates : undefined,
        shift_managements: editedShiftManagements.length > 0 ? editedShiftManagements : undefined,
      });
      if (response) {
        toast.success(response.success || "更新に成功しました");
      }
    } catch (error: any) {
      if (error && error.message && error.message.message) {
        toast.error(error.message.message);
      }
    } finally {
      fetchShiftDatas();
      setIsStaffScheduleEdited(false);
    }
  }

  const handleCancelUpdate = async () => {
    try {
      const response = await apiFactory.shiftManagementApi.updateStaffShiftManage({
        button_type: "cancel"
      });
      if (response) {
        toast.success(response.success || "更新に成功しました");
      }
      
    } catch (error: any) {
      if (error && error.message && error.message.message) {
        toast.error(error.message.message);
      }
    } finally {
      fetchShiftDatas();
      setIsStaffScheduleEdited(false);
    }
  }

  useEffect(() => {
    fetchShiftDatas();
  }, [currentDate, type, createShift])

  useEffect(() => {
    if (dataStaff) {
      if (dataStaff.length > 0) {
        setShowRegisterButtons(true);
      } else {
        setShowRegisterButtons(false);
      }
    }
  }, [dataStaff]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(pointerSensor);

  return (
    <>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
        <Row>
          <Col span={6}>
            <Row>
              <Col className="patient-title " style={{ height: "50px", background: '#f5f4f2', border: "1px solid #000", display:'flex' }} span={8}>
                <Box margin='auto' textAlign="center" fontWeight='bold' >介護スタッフ</Box>
              </Col>
            </Row>

            {
              dataStaff.map((dataStaffItem: any, dataStaffIndex: number) => {
                return (
                  <Row key={dataStaffItem.id}>
                    <Col className="patient-title" span={8}>
                      <Box
                        display="flex"
                        fontWeight="bold"
                        fontSize="11px"
                        bgColor="#ffffff"
                        border="1px solid #000"
                        height="calc(100% - 0px) !important"
                        textAlign="center"
                      >
                        <Text margin="auto" fontSize="16px" fontWeight="bold">{dataStaffItem?.family_name || ""}</Text>
                      </Box>
                    </Col>
                    <Col className="patient" span={16}>
                      <Box height="30px" borderTop="1px solid #e0e0e0" />
                      {
                        (dataStaffItem.shifts && Object.keys(dataStaffItem.shifts).length > 0 ? Object.keys(dataStaffItem.shifts) : [""]).map((shiftServiceName: string, shiftServiceIndex: number) => {
                          return (
                            <Box className="type-service" style={{ 
                              height: '88px', 
                              borderTop: "1px solid #e6e6e6", 
                              display: "flex", 
                              alignItems: "center" 
                            }} 
                              key={shiftServiceIndex}
                            >
                              <Text ml={3} >{shiftServiceName}</Text>
                            </Box>
                          )
                        })
                      }
                    </Col>
                  </Row>
                )
              })
            }
          </Col>

          <Col span={18}>
            <TableContainer overflowX="scroll" transform="rotateX(180deg)">
              <Table width={`${70 * 23}px`} transform="rotateX(180deg)" >
                <Tbody>
                  <Tr height="0px">
                    {dataHour.map((item: any, index: number) => {
                      return (
                        <StyleTd
                          position="relative"
                          zIndex={9999}
                          padding={0}
                          key={index}
                          style={{
                            border: "0px",
                            height: "41px",
                            width: "70px !important"
                          }}
                          fontSize="12px"
                        >
                          <Text
                            className="text-left"
                            zIndex={9999}
                            position="absolute"
                            left="-7px"
                          >{item}</Text>
                        </StyleTd>
                      )
                    })}
                  </Tr>

                  {
                    allShiftDatas.map((shiftDataItem: any, shiftDataIndex: number) => {
                      return (
                        <React.Fragment key={shiftDataItem.id}>
                          <Tr borderTop="1px solid #e0e0e0">
                            {
                              dataHour.map((hour: string, hourIndex: number) => {
                                return (
                                  <StyleTd 
                                    padding="0px"
                                    position="relative"
                                    key={hourIndex}
                                    style={{
                                      border: "0px",
                                      height: "30px",
                                      maxWidth: "70px",
                                    }}
                                    fontSize="12px"
                                  >
                                    {
                                      shiftDataItem.shift_managements.map((manageItem: any) => {
                                        if (hourIndex >= manageItem.startHour && hourIndex < manageItem.endHour && !manageItem.delete) {
                                          return (
                                            <Box 
                                              pb="5px"
                                              height="25px"
                                              width="69px"
                                              position="absolute"
                                              bottom="0px"
                                              bg="#f6d7c0"
                                              cursor={editMode ? "pointer" : "auto"}
                                              onClick={() => {
                                                if (editMode) {
                                                  const selectedData = {
                                                    ...manageItem,
                                                    staff_family_name: shiftDataItem.family_name,
                                                    staff_id: shiftDataItem.id,
                                                  };
                                                  handleOpenTimeAdjust(selectedData)
                                                }
                                              }}
                                            >
                                              {
                                                hourIndex === manageItem.startHour && (
                                                  <Text position="absolute" left="5px" top={0} zIndex={50}>{`${manageItem.start_time} 〜 ${manageItem.end_time}`}</Text>
                                                )
                                              }
                                            </Box>
                                          );
                                        }
                                        return <></>;
                                      })
                                    }
                                  </StyleTd>
                                )
                              })
                            }
                          </Tr>
                          {
                            (shiftDataItem.shifts && shiftDataItem.shifts.length > 0 ? shiftDataItem.shifts : [[]]).map((serviceItem: any, serviceIndex: number) => {
                              return (
                                <Tr position="relative" key={serviceIndex}>
                                  {
                                    dataHour.map((hour: string, hourIndex: number) => {
                                      return (
                                        <ScheduleCell
                                          key={hourIndex}
                                          bgColor={shiftDataItem.registeredHours.includes(hourIndex) ? "#f6d7c0" : ""}
                                          editMode={editMode}
                                          hour={hour}
                                          staffName={shiftDataItem.family_name}
                                          serviceIndex={serviceIndex}
                                          staffId={shiftDataItem.id}
                                        >
                                          {
                                            serviceItem && serviceItem.length > 0 && serviceItem.map((scheduleItem: any, scheduleIndex: number) => {
                                              if (scheduleItem 
                                                && scheduleItem.schedule_date 
                                                && convertStringTimeToNum(scheduleItem.schedule_date.start_time)[0] === hourIndex
                                              ) {
                                                const boxWidth = getScheduleBoxWidth(scheduleItem.schedule_date.start_time, scheduleItem.schedule_date.end_time);
                                                // editMode: boolean;
                                                // scheduleItem: any;
                                                // boxWidth: string;
                                                // dragId: string;
                                                // setSelectedScheduleItem: Dispatch<any>;
                                                // setOpenDetail: Dispatch<SetStateAction<boolean>>;
                                                return (
                                                  <ScheduleItemBox 
                                                    key={scheduleIndex}
                                                    editMode={editMode}
                                                    scheduleItem={scheduleItem}
                                                    boxWidth={boxWidth}
                                                    dragId={scheduleItem.dragId}
                                                    setSelectedScheduleItem={setSelectedShiftItem}
                                                    setOpenDetail={setOpenDetail}
                                                    serviceIndex={serviceIndex}
                                                  />
                                                  // <Box 
                                                  //   width="35px"
                                                  //   height="100%"
                                                  //   bg="white"
                                                  //   border="2px solid var(--primary-color)"
                                                  //   display='flex'
                                                  // >
                                                  //   <Text
                                                  //     // paddingX="8px"
                                                  //     lineHeight="13px"
                                                  //     textAlign="center"
                                                  //     fontSize="12px"
                                                  //     whiteSpace="pre-wrap"
                                                  //     marginX='auto'
                                                  //     mt="5px"
                                                  //   >
                                                  //     {scheduleItem.patient_name || ""}
                                                  //   </Text>
                                                  // </Box>
                                                );
                                              } else {
                                                return <></>;
                                              }
                                            })
                                          }
                                        </ScheduleCell>
                                      )
                                    })
                                  }
                                </Tr>
                              )
                            })
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </Tbody>
              </Table>
            </TableContainer>
          </Col>
        </Row>

        <DragOverlay>
          {draggedData ? <ScheduleItemBox {...draggedData} /> : null}
        </DragOverlay>
      </DndContext>

      {
        type && (
          <Prompt 
            when={isStaffScheduleEdited}
            message="Are you sure you want to leave?"
            change={() => handleCancelUpdate()}
            setInitial={setIsStaffScheduleEdited}
          />
        )
      }

      {
        isStaffScheduleEdited && (
          <div className="flex justify-end gap-x-[8px] mt-[42px] pb-[50px]">
            <ButtonOutline className="!text-[14px] leading-[24px] !px-[16px] !max-h-[35px]" onClick={() => handleCancelUpdate()}>
              キャンセル
            </ButtonOutline>
            <ButtonSolid className="!text-[14px] leading-[24px] !px-[34px] !max-h-[35px]" onClick={() => handleUpdateRecords()}>
              下書き保存
            </ButtonSolid>
          </div>
        )
      }

      {/* <ScheduleRegister
        title="スケジュール調整"
        openRegisterShift={showScheduleAdjust}
        setOpenRegisterShift={setShowScheduleAdjust}
      /> */}

      {/* {
        openDetail && (
          <ScheduleDetail 
            open={openDetail}
            setOpen={setOpenDetail}
            title="スケジュール調整"
            scheduleDateId={selectedId}
            refetchData={fetchDataDayShift}
          />
        )
      } */}

      {
        openTimeAdjust && (
          <ShiftTimeAdjust 
            openState={[openTimeAdjust, setOpenTimeAdjust]}
            selectedTimeItemState={[selectedTimeItem, setSelectedTimeItem]}
            setAllShiftDatas={setAllShiftDatas}
            setIsEdited={setIsStaffScheduleEdited}
          />
        )
      }

      {
        openDetail && (
          <StaffScheduleDetail 
            openState={[openDetail, setOpenDetail]}
            title="スケジュール"
            setIsEdited={setIsStaffScheduleEdited}
            allShiftDatasState={[allShiftDatas, setAllShiftDatas]}
            selectedShiftItemState={[selectedShiftItem, setSelectedShiftItem]}
            refetchData={fetchShiftDatas}
          />
        )
      }
    </>
  );
}

interface IRecord {
  id: string;
  name: string;
  service: string;
  events: {
    id: string;
    name: string;
    start: string;
    end: string;
  }[];
  business_hour?: {
    // optional, nếu là record của patient thì không có
    start: string;
    end: string;
  };
}
