import { Table, Tbody, Tr } from "@chakra-ui/table";
import { CardGray, FlexRow, StyleTd, StyleTr } from "component/StyledComponent";
import { useCallback, useEffect, useState } from "react";
import TableHeadTd from "./HeadTd";
import { ButtonOutline } from "component/button";
import { Text, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { StyleCheckbox } from "component/StyledComponent";
import ROUTES from "constant/routes";
import queryString from "query-string";
import "moment/locale/ja";
import "moment-timezone";
import { FORMAT, getCookie } from "utils";
import PaginationUI from "component/pagination/PaginationUI";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import { DATE_ERA_FORMAT, DATE_FORMAT_2 } from "constant/date";
import SearchImg from "assets/search.png";
import { Empty } from "antd";
import { RootState } from "store";
import { useSelector } from "react-redux";

export const fakeDataCustomerList = [
  {
    id: "0001",
    name: "井上隆代 ",
    birthdate: "昭和21年6月21日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護５",
    insuredNum: "1006820862",
    warrantyCover: "令和5年3月1日から",
    warrantyCover2: "令和8年11月30日まで",
    careManager: "菊池　慶",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0002",
    name: "薄田章子 ",
    birthdate: "昭和27年9月28日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護3",
    insuredNum: "1009056704",
    warrantyCover: "令和4年9月28日から",
    warrantyCover2: "令和5年9月30日まで",
    careManager: "菊池　慶",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0003",
    name: "小室咲子 ",
    birthdate: "昭和9年10月5日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護4",
    insuredNum: "1003313267",
    warrantyCover: "令和4年11月1日から",
    warrantyCover2: "令和5年10月31日まで",
    careManager: "坂部　明子",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0004",
    name: "近藤忠司 ",
    birthdate: "昭和27年5月30日",
    address: "愛知県　名古屋市　天白区",
    degreeCare: "要介護５",
    insuredNum: "1006293672",
    warrantyCover: "令和4年11月1日から",
    warrantyCover2: "令和8年10月31日まで",
    careManager: "亀和田　祥子",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0005",
    name: "嶋崎光弥 ",
    birthdate: "昭和28年1月3日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護3",
    insuredNum: "1009036433",
    warrantyCover: "令和5年2月1日から",
    warrantyCover2: "令和8年1月31日まで",
    careManager: "亀和田　祥子",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0006",
    name: "菅生靖子 ",
    birthdate: "昭和12年12月20日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護５",
    insuredNum: "1004266373",
    warrantyCover: "令和4年10月1日から",
    warrantyCover2: "令和8年9月30日まで",
    careManager: "菊池　慶",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0007",
    name: "谷田富子 ",
    birthdate: "昭和9年12月29日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護2",
    insuredNum: "1003366158",
    warrantyCover: "令和4年2月1日から",
    warrantyCover2: "令和8年1月31日まで",
    careManager: "菊池　慶",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0008",
    name: "塚田智代 ",
    birthdate: "昭和19年2月10日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護５",
    insuredNum: "1006167991",
    warrantyCover: "令和3年11月1日から",
    warrantyCover2: "令和6年10月31日まで",
    careManager: "藤井　輝代美",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0009",
    name: "丹羽　千代子 ",
    birthdate: "昭和6年3月5日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護2",
    insuredNum: "1002470407",
    warrantyCover: "令和4年8月1日から",
    warrantyCover2: "令和7年7月31日まで",
    careManager: "藤井　輝代美",
    telNum: "",
    userStatus: "利用中",
  },
  {
    id: "0010",
    name: "坂鉄子 ",
    birthdate: "昭和6年3月7日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護５",
    insuredNum: "1002471504",
    warrantyCover: "令和5年3月1日から",
    warrantyCover2: "令和8年2月28日まで",
    careManager: "坂部　明子",
    telNum: "",
    userStatus: "利用中",
  },
  // {
  //   id: '0011',
  //   name: '松岡ちゑ ',
  //   birthdate: '大正15年5月20日',
  //   address: '愛知県　名古屋市　千種区',
  //   degreeCare: '要介護3',
  //   insuredNum: '1001544582',
  //   warrantyCover: '令和4年5月2日から令和5年5月31日まで',
  //   careManager: '高橋　恭子',
  //   telNum: '',
  //   userStatus: '利用中'
  // },
];

const TableRecordDailyMaster = () => {
  const [checkValue, setCheckValue] = useState({
    pause: false,
    suspended: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [headVal, setHeadVal]: any = useState({});
  const [total, setTotal] = useState(10);
  const [dataPatient, setDataPatient] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const onChangeCheckValue = (evt: any) => {
    const name = evt.target.name;
    setCheckValue({ ...checkValue, [name]: evt.target.checked });
  };
  const [current, setCurrent] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [date, setDate]: any = useState(new Date());
  const user = useSelector((state: RootState) => state.user);
  const queryObj: any = queryString.parse(location.search);

  useEffect(() => {
    if (location.search) {
      setDate(new Date(queryObj.date));
    }
  }, [location.search]);

  const getListPatient = useCallback(
    async (text: any = "") => {
      const idToken = getCookie("access_token");
      let status = ["using"];
      for (const [key, value] of Object.entries(checkValue)) {
        if (value) {
          status.push(key);
        }
      }

      try {
        const param: any = {
          sortKey: Object.keys(headVal)[0],
          order: getSortType(Object.values(headVal)[0]),
          "status[]": status,
          page: current,
          care_plan_date: moment(date).format(DATE_FORMAT_2),
          per: perPage,
        };
        if (text) {
          param.fullname = text;
        }
        const res = await nursingCareRecordApi.getListPatientRecord(
          idToken,
          param,
          user?.role
        );
        if (res) {
          setCurrent(res.page);
          setDataPatient(res.patients);
          setTotal(res.total_items);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    [current, checkValue, date, headVal, perPage]
  );

  useEffect(() => {
    getListPatient();
  }, [getListPatient]);

  const getSortType = (value: any) => {
    switch (value) {
      case 1:
        return "ascend";
      case 2:
        return "descend";
      default:
        return null;
    }
  };

  const onSortHead = (evt: any) => {
    evt.preventDefault();
    const name = evt.currentTarget.getAttribute("name");
    let value = evt.currentTarget.getAttribute("value");
    setHeadVal({ [name]: Number(value) === 2 ? 0 : Number(value) + 1 });
  };

  const onSearchName = () => {
    getListPatient(searchVal);
  };
  return (
    <div className="w-full bg-white">
      <CardGray
        style={{
          padding: "16px 26px 19px 26px",
          fontSize: "12px",
          background: "var(--secondary-color)",
        }}
      >
        <FlexRow>
          <p className="font-bold">キーワード</p>

          <StyleCheckbox
            checked={checkValue.pause}
            onChange={onChangeCheckValue}
            mx={5}
            name="pause"
            colorScheme="brand2"
            borderColor="black"
          >
            <p className="font-bold text-xs">一時停止</p>
          </StyleCheckbox>
          <StyleCheckbox
            checked={checkValue.suspended}
            onChange={onChangeCheckValue}
            mx={5}
            name="suspended"
            colorScheme="brand2"
            borderColor="black"
          >
            <p className="font-bold text-xs">利用停止</p>
          </StyleCheckbox>
        </FlexRow>
        <FlexRow className="mt-2">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <img
                src={SearchImg}
                alt=""
                style={{ width: "16px", height: "16px", marginBottom: "15px" }}
              />
            </InputLeftElement>
            <Input
              type="text"
              value={searchVal}
              onChange={(evt) => setSearchVal(evt.target.value)}
              _placeholder={{ color: "var(--primary-text-dark)" }}
              placeholder="氏名、カナ"
              bg="white"
              height="25px"
              fontSize="12px"
              borderRadius="0"
              marginRight="21px"
              border="1px solid #707070"
            />

            <ButtonOutline
              onClick={onSearchName}
              width="150px"
              height="25px"
              bg="white"
              fontSize="12px"
            >
              検索
            </ButtonOutline>
          </InputGroup>
        </FlexRow>
      </CardGray>

      {dataPatient && (
        <PaginationUI
          current={current}
          setCurrent={setCurrent}
          total={total}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      )}
      <Table style={{ fontSize: "12px" }}>
        <Tbody>
          <StyleTr>
            <TableHeadTd
              title="ID"
              changeValue={onSortHead}
              value={headVal.patient_code}
              name="patient_code"
              isSort
            />
            <TableHeadTd
              title="お客様名"
              changeValue={onSortHead}
              value={headVal.family_name}
              name="family_name"
              isSort
            />
            <TableHeadTd
              title="生年月日"
              changeValue={onSortHead}
              value={headVal.birth_date}
              name="birth_date"
              isSort
            />
            <TableHeadTd
              title="介護度"
              changeValue={onSortHead}
              value={headVal.birth_date}
              name="birth_date"
            />
            <TableHeadTd
              title="被保険者番号"
              changeValue={onSortHead}
              value={headVal.birth_date}
              name="birth_date"
            />
            <TableHeadTd
              title="被保証適用期間 "
              changeValue={onSortHead}
              value={headVal.birth_date}
              name="birth_date"
            />
            <TableHeadTd
              title="担当ケアマネ"
              changeValue={onSortHead}
              value={headVal.birth_date}
              name="birth_date"
            />
            <TableHeadTd
              title="電話番号"
              changeValue={onSortHead}
              value={headVal.mobile_phone}
              name="mobile_phone"
            />
            <StyleTd />
          </StyleTr>
          {dataPatient &&
            dataPatient?.map((item: any, index: any) => (
              <Tr
                key={index}
                style={{
                  background: `${
                    index % 2
                      ? "var(--secondary-color) 0% 0% no-repeat padding-box"
                      : ""
                  }`,
                }}
              >
                <StyleTd style={{ width: "100px" }}>
                  {item?.patient_code}
                </StyleTd>

                <StyleTd style={{ textAlign: "center" }}>
                  {item?.family_name} <br />
                  {item?.name_kana}
                </StyleTd>
                <StyleTd whiteSpace="nowrap">
                  {moment(item?.birth_date).format(DATE_ERA_FORMAT)}
                </StyleTd>

                <StyleTd>{item?.degreeCare}</StyleTd>
                <StyleTd>{item?.insuredNum}</StyleTd>
                <StyleTd w="14%" whiteSpace="nowrap">
                  <Text>{item?.warrantyCover}</Text>
                  <Text>{item?.warrantyCover2}</Text>
                </StyleTd>
                <StyleTd>{item?.careManager}</StyleTd>
                <StyleTd>{item?.mobile_phone}</StyleTd>
                <StyleTd>
                  <ButtonOutline
                    className="!h-[23px]"
                    // hidden={index > 0}
                    onClick={() =>
                      navigate({
                        pathname: `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${ROUTES.HISTORY_CUSTOMER}/${item?.id}`,
                        search: `?date=${moment(date).format(
                          FORMAT
                        )}&tab=1&name=${item?.family_name}`,
                      })
                    }
                    width="70px"
                    fontSize="12px"
                    bg="white"
                  >
                    詳細
                  </ButtonOutline>
                </StyleTd>
              </Tr>
            ))}
        </Tbody>
      </Table>
      {dataPatient ? (
        <PaginationUI
          current={current}
          setCurrent={setCurrent}
          total={total}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <FlexRow className="justify-end" style={{ paddingBottom: "40px" }}>
        <ButtonOutline
          onClick={() =>
            navigate(
              `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}?tab=${queryObj.tab}`
            )
          }
          mt="232px"
          w="101px"
          h="35px"
          fontSize="14px"
        >
          戻る
        </ButtonOutline>
      </FlexRow>
    </div>
  );
};

export default TableRecordDailyMaster;
