import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Select as AntSelect, Input } from "antd";
import {
  LeftOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  RightOutlined,
} from "@ant-design/icons";
import "./style.scss";
import useResponsive from "hook/useResponsive";
import { Text } from "@chakra-ui/react";

interface Props {
  total: number;
  current: number;
  perPage: number;
  setCurrent: Dispatch<SetStateAction<number>>;
  setPerPage: Dispatch<SetStateAction<number>>;
  pageSizeOptions: number[];
}
const CustomPagination: React.FC<Props> = ({
  total,
  current,
  perPage,
  setCurrent,
  setPerPage,
  pageSizeOptions,
}) => {
  const [pageButtons, setPageButtons] = useState<(number | string)[]>([]);
  const numberOfPages = Math.ceil(total / perPage);
  const { isMobile } = useResponsive();

  useEffect(() => {
    let tempArr = [];
    for (let i = 1; i <= numberOfPages; i++) {
      tempArr.push(i);
    }
    if (numberOfPages >= 8) {
      if (current >= 1 && current <= 3) {
        setPageButtons([1, 2, 3, 4, "...", numberOfPages]);
      } else if (current === 4) {
        const sliced = tempArr.slice(0, 5);
        setPageButtons([...sliced, "...", numberOfPages]);
      } else if (current > 4 && current < numberOfPages - 2) {
        const sliced1 = tempArr.slice(current - 2, current);
        const sliced2 = tempArr.slice(current, current + 1);
        setPageButtons([
          1,
          "...",
          ...sliced1,
          ...sliced2,
          "...",
          numberOfPages,
        ]);
      } else if (current > numberOfPages - 3) {
        const sliced = tempArr.slice(numberOfPages - 4);
        setPageButtons([1, "...", ...sliced]);
      }
    } else {
      setPageButtons(tempArr);
    }
  }, [numberOfPages, current]);

  const handlePrevPage = () => {
    if (current <= 1) {
      return;
    }

    setCurrent((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (current >= numberOfPages) {
      return;
    }

    setCurrent((prev) => prev + 1);
  };

  if (isMobile) {
    return (
      <div className="flex justify-between px-[20px]">
        <Text color="#666666">表示アイテム数: {perPage || ""}</Text>

        <Text color="#666666">
          現在のページ: {current} / {numberOfPages}
        </Text>

        <div className="flex gap-x-[24px]">
          <div
            style={{
              color: current === 1 ? "#b3b3b3" : "black",
            }}
            onClick={handlePrevPage}
          >
            <LeftOutlined />
          </div>

          <div
            style={{
              color: current === numberOfPages ? "#b3b3b3" : "black",
            }}
            onClick={handleNextPage}
          >
            <RightOutlined />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="customPaginationContainer gap-x-[16px]">
      <div className="text-[#000000] text-[12px] leading-[20px]">
        {(current - 1) * perPage + 1} ~{" "}
        {current * perPage > total ? total : current * perPage}件(全{total}件中)
      </div>

      <div className="text-[#000000] text-[12px] leading-[20px] flex justify-between items-center">
        <AntSelect
          value={perPage}
          options={pageSizeOptions.map((value) => ({
            value: value,
            label: String(value),
          }))}
          onChange={(value: number) => {
            setPerPage(value);
            setCurrent(1);
          }}
        />
        <p className="text-[#000000] text-[12px] leading-[20px] ml-2">件表示</p>
      </div>

      <div className="pagination-bar-wrapper">
        <div
          className={`paginate-btn ${current === 1 ? "disabled" : ""}`}
          onClick={() => {
            setCurrent(1);
          }}
        >
          <DoubleLeftOutlined />
        </div>
        <div
          className={`paginate-btn ${current === 1 ? "disabled" : ""}`}
          onClick={() => {
            setCurrent((prev) => prev - 1);
          }}
        >
          <LeftOutlined />
        </div>

        {pageButtons.map((pageItem) => {
          return (
            <div
              className={`paginate-btn ${
                pageItem === current ? "active" : ""
              } ${pageItem === "..." ? "ellipsis" : ""}`}
              onClick={() => {
                if (typeof pageItem === "number") {
                  setCurrent(pageItem);
                }
              }}
              key={pageButtons.indexOf(pageItem)}
            >
              {pageItem}
            </div>
          );
        })}

        <div
          className={`paginate-btn ${
            current === numberOfPages ? "disabled" : ""
          }`}
          onClick={() => {
            setCurrent((prev) => prev + 1);
          }}
        >
          <RightOutlined />
        </div>
        <div
          className={`paginate-btn ${
            current === numberOfPages ? "disabled" : ""
          }`}
          onClick={() => {
            setCurrent(numberOfPages);
          }}
        >
          <DoubleRightOutlined />
        </div>
      </div>
      <Input
        onBlur={(evt) => {
          const targetPage = Number(evt.target.value);
          if (Number.isNaN(targetPage)) {
            return;
          }
          if (targetPage >= 1 && targetPage <= numberOfPages) {
            setCurrent(targetPage);
          }
        }}
        onChange={(evt) => {
          const targetPage = Number(evt.target.value);
          if (Number.isNaN(targetPage)) {
            return;
          }
          if (targetPage >= 1 && targetPage <= numberOfPages) {
            setCurrent(targetPage);
          }
        }}
        className="w-[56px] border-[#000000]"
        style={{ borderRadius: "8px" }}
      />
      <span className="text-[#000000] text-[12px] leading-[20px] ml-[5px]">
        ページ
      </span>
    </div>
  );
};

export default CustomPagination;
