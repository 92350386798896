import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import { FC, useEffect, useState } from "react";

export interface OptionI {
  label: string;
  value: string | number;
}

interface IProps extends SelectProps {
  getOptions?: (args?: any) => Promise<never[]> | never[];
  allowGetOption?: boolean;
  getOptionDepdendancy?: React.DependencyList;
}
const SelectWithData: FC<IProps> = ({
  getOptions,
  allowGetOption = true,
  ...props
}) => {
  const [options, setOptions] = useState<OptionI[]>(
    (props.options || []) as OptionI[]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const fetchData = async (args?: any) => {
    if (!getOptions) return;
    try {
      setLoading(true);
      const options = await getOptions(args);
      setLoading(false);
      setOptions(options);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleSearch = (value: string) => {
    fetchData(value);
    props.onSearch?.(value);
  };
  useEffect(() => {
    allowGetOption && fetchData();
  }, props.getOptionDepdendancy || []);
  return (
    <Select
      onSearch={handleSearch}
      placeholder="選択してください"
      {...props}
      loading={loading}
      options={options}
    />
  );
};

export default SelectWithData;
