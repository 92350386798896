import styled from "@emotion/styled";

const Styles = styled.div`
  .icon-container {
    width: 30px;
    height: 30px;
    border: 1px solid #000000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 12px;
  }
  input, .ant-select.ant-select-in-form-item {
    width: 50%;
  }
  .icon-not-allowed {
    cursor: "not-allowed";
    opacity: 0.5;
  }
`;

export {Styles}
