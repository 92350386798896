import { Table, Tbody, Text, Tr } from "@chakra-ui/react";
import { StyleTd, StyleTr } from "component/StyledComponent";
import React from "react";
import TableHeadTd from "./HeadTd";
import { ButtonOutline } from "component/button";
import { useNavigate } from "react-router-dom";
import ROUTES from "constant/routes";
import moment from "moment";
import { DATE_ERA_FORMAT } from "constant/date";

const TableHistoryCustomerList = ({ data, onSortHead, headVal }: any) => {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-white">
      <Table style={{ fontSize: "12px" }}>
        <Tbody>
          <StyleTr>
            <TableHeadTd title="ID" changeValue={onSortHead} value={headVal.patient_code} name="patient_code" isSort />
            <TableHeadTd title="お客様名" changeValue={onSortHead} value={headVal.family_name} name="family_name" isSort />
            <TableHeadTd title="生年月日" changeValue={onSortHead} value={headVal.birth_date} name="birth_date" isSort />
            <TableHeadTd title="介護度" changeValue={onSortHead} value={headVal.birth_date} name="birth_date" />
            <TableHeadTd title="被保険者番号" changeValue={onSortHead} value={headVal.birth_date} name="birth_date" />
            <TableHeadTd title="被保証適用期間 " changeValue={onSortHead} value={headVal.birth_date} name="birth_date" />
            <TableHeadTd title="担当ケアマネ" changeValue={onSortHead} value={headVal.birth_date} name="birth_date" />
            <TableHeadTd title="電話番号" changeValue={onSortHead} value={headVal.mobile_phone} name="mobile_phone" />
            <StyleTd />
          </StyleTr>
          {data?.map((item: any, index: any) => (
            <Tr
              key={index}
              style={{
                background: `${index % 2
                    ? "var(--secondary-color) 0% 0% no-repeat padding-box"
                    : ""
                  }`,
              }}
            >
              {/* <StyleTd style={{ width: "100px" }}>{item?.id}</StyleTd>

              <StyleTd style={{ textAlign: "center" }}>
                {item?.name} <br />
              </StyleTd>
              <StyleTd whiteSpace="nowrap">{item?.birthdate}</StyleTd>

              <StyleTd>{item?.degreeCare}</StyleTd>
              <StyleTd>{item?.insuredNum}</StyleTd>
              <StyleTd w="14%" whiteSpace="nowrap">
                <Text>{item?.warrantyCover}</Text>
                <Text>{item?.warrantyCover2}</Text>
              </StyleTd>
              <StyleTd>{item?.careManager}</StyleTd>
              <StyleTd>{item?.telNum}</StyleTd> */}

              <StyleTd style={{ width: "100px" }}>{item?.patient_code}</StyleTd>

              <StyleTd style={{ textAlign: "center" }}>
                {item?.family_name} <br />
                {item?.name_kana}
              </StyleTd>
              <StyleTd whiteSpace="nowrap">{moment(item?.birth_date).format(DATE_ERA_FORMAT)}</StyleTd>

              <StyleTd>{item?.degreeCare}</StyleTd>
              <StyleTd>{item?.insuredNum}</StyleTd>
              <StyleTd w="14%" whiteSpace="nowrap">
                <Text>{item?.warrantyCover}</Text>
                <Text>{item?.warrantyCover2}</Text>
              </StyleTd>
              <StyleTd>{item?.careManager}</StyleTd>
              <StyleTd>{item?.mobile_phone}</StyleTd>
              <StyleTd className="flex justify-end">
                <ButtonOutline
                  className="!h-[23px]"
                  // hidden={index > 0}
                  onClick={() => navigate(`/${ROUTES.STAFF}/${ROUTES.HISTORYCUSTOMERNAME}/${item?.id}`)}
                  width="70px"
                  fontSize="12px"
                  bg="white"
                >
                  詳細
                </ButtonOutline>
              </StyleTd>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};

export default TableHistoryCustomerList;
