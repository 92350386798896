import React from "react";
import { Result } from "antd";

const NotFound = () => {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <Result
          status="404"
          title="404"
          subTitle={"アクセスしたページは存在しません"}
        />
      </div>
    </div>
  );
};

export default NotFound;
