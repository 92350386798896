import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import { CardGray, FlexRow, StyleCheckbox } from "component/StyledComponent";
import { ButtonOutline } from "component/button";
import TableSearchForm from "component/form/search/TableSearchForm";
import Header from "component/header/Header";
import PaginationUI from "component/pagination/PaginationUI";
import TableHistoryCustomerList from "component/table/TableHistoryCustomerList";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getCookie } from "utils";
import SearchImg from "assets/search.png";

export const dataCustomerList = [
  {
    id: "0001",
    name: "井上隆代 ",
    birthdate: "昭和21年6月21日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護５",
    insuredNum: "1006820862",
    warrantyCover: "令和5年3月1日から",
    warrantyCover2: "令和8年11月30日まで",
    careManager: "菊池　慶",
    telNum: "05212345678",
    userStatus: "利用中",
  },
  {
    id: "0002",
    name: "薄田章子 ",
    birthdate: "昭和27年9月28日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護3",
    insuredNum: "1009056704",
    warrantyCover: "令和4年9月28日から",
    warrantyCover2: "令和5年9月30日まで",
    careManager: "菊池　慶",
    telNum: "05212345678",
    userStatus: "利用中",
  },
  {
    id: "0003",
    name: "小室咲子 ",
    birthdate: "昭和9年10月5日",
    address: "愛知県　名古屋市　守山区",
    degreeCare: "要介護4",
    insuredNum: "1003313267",
    warrantyCover: "令和4年11月1日から",
    warrantyCover2: "令和5年10月31日まで",
    careManager: "坂部　明子",
    telNum: "05212345678",
    userStatus: "利用中",
  },
  {
    id: "0004",
    name: "近藤忠司 ",
    birthdate: "昭和27年5月30日",
    address: "愛知県　名古屋市　天白区",
    degreeCare: "要介護５",
    insuredNum: "1006293672",
    warrantyCover: "令和4年11月1日から",
    warrantyCover2: "令和8年10月31日まで",
    careManager: "亀和田　祥子",
    telNum: "05212345678",
    userStatus: "利用中",
  },
];

const HistoryCustomerList = () => {
  const [checkValue, setCheckValue] = useState({
    pause: false,
    suspended: false,
  });
  const [searchVal, setSearchVal] = useState("");
  const onChangeCheckValue = (evt: any) => {
    const name = evt.target.name;
    setCheckValue({ ...checkValue, [name]: evt.target.checked });
  };
  const idToken = getCookie("access_token");
  const user = useSelector((state: RootState) => state.user);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(10);
  const [headVal, setHeadVal]: any = useState({});
  const [perPage, setPerPage] = useState(10);

  const [dataTable, setDataTable]: any = useState([]);
  const onSortHead = (evt: any) => {
    evt.preventDefault();
    const name = evt.currentTarget.getAttribute("name");
    let value = evt.currentTarget.getAttribute("value");
    setHeadVal({ [name]: Number(value) === 2 ? 0 : Number(value) + 1 });
  };
  const getListPatient = useCallback(async () => {
    let status = ["using"];
    for (const [key, value] of Object.entries(checkValue)) {
      if (value) {
        status.push(key);
      }
    }

    try {
      const param = {
        "status[]": status,
        page: current,
      };
      const res = await nursingCareRecordApi.getListPatientRecord(
        idToken,
        param,
        user?.role
      );
      if (res) {
        setCurrent(res.page);
        setDataTable(res.patients);
        setTotal(res.total_items);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [current, checkValue, headVal]);

  useEffect(() => {
    getListPatient();
  }, [getListPatient]);

  return (
    <div className="page-container bg-[#FFFFFF] h-full">
      <Header title="介護記録・履歴　お客様一覧" />
      <div className="mt-[46px]">
        <CardGray
          style={{
            padding: "16px 26px 19px 26px",
            fontSize: "12px",
            background: "var(--secondary-color)",
          }}
        >
          <FlexRow>
            <p className="font-bold">キーワード</p>

            <StyleCheckbox
              checked={checkValue.pause}
              onChange={onChangeCheckValue}
              mx={5}
              name="pause"
              colorScheme="brand2"
              borderColor="black"
            >
              <p className="font-bold text-xs">一時停止</p>
            </StyleCheckbox>
            <StyleCheckbox
              checked={checkValue.suspended}
              onChange={onChangeCheckValue}
              mx={5}
              name="suspended"
              colorScheme="brand2"
              borderColor="black"
            >
              <p className="font-bold text-xs">利用停止</p>
            </StyleCheckbox>
          </FlexRow>
          <FlexRow className="mt-2">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <img
                  src={SearchImg}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginBottom: "15px",
                  }}
                />
              </InputLeftElement>
              <Input
                type="text"
                value={searchVal}
                onChange={(evt) => setSearchVal(evt.target.value)}
                _placeholder={{ color: "var(--primary-text-dark)" }}
                placeholder="氏名、カナ"
                bg="white"
                height="25px"
                fontSize="12px"
                borderRadius="0"
                marginRight="21px"
                border="1px solid #707070"
              />

              <ButtonOutline
                width="150px"
                height="25px"
                bg="white"
                fontSize="12px"
              >
                検索
              </ButtonOutline>
            </InputGroup>
          </FlexRow>
        </CardGray>
      </div>
      <div>
        {/* <PaginationUI current={current} setCurrent={setCurrent} total={total} perPage={perPage} setPerPage={setPerPage} /> */}
        <TableHistoryCustomerList
          data={dataTable}
          onSortHead={onSortHead}
          headVal={headVal}
        />
        <PaginationUI
          current={current}
          setCurrent={setCurrent}
          total={total}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </div>
  );
};

export default HistoryCustomerList;
