import { useState } from "react"
import { CareEnhanceDetail } from "./CareEnhanceDetail"
import { CareEnhanceList } from "./CareEnhanceList"

const CareEnhance = () => {
    const [isList, setIsList] = useState<boolean>(true)
    const [serviceId, setServiceId] = useState(null)
    const [difference, setDifference] = useState({
        type: ''
    })
    const onChange = () => {
        setIsList(!isList)
    }
    return isList ? <CareEnhanceList onChange={onChange} setServiceId={setServiceId} setDifference={setDifference}/> :
        <CareEnhanceDetail onChange={onChange} id={serviceId} setServiceId={setServiceId} difference={difference} setDifference={setDifference}/>
}
export { CareEnhance }
