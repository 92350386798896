/* eslint-disable @typescript-eslint/no-explicit-any */

import { ROLE } from "constant";
import ROUTES from "constant/routes";
import useAuthenticate from "hook/useAuthenticate";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PublicRoute = ({ children }: any) => {
  const token = Cookies.get("access_token");
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const onNavigate = () => {
    if (token && role) {
      switch (role) {
        case ROLE.ADMIN:
          navigate(`/${ROUTES.ADMIN}`);
          break;
        case ROLE.STAFF:
          navigate(`/${ROUTES.STAFF}/${ROUTES.VISITSCHEDULE}`);
          break;
        case ROLE.CLIENT:
          navigate(`/${ROUTES.HOME}`);
          break;
        case ROLE.HS_ADMIN:
          navigate(`/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_CUSTOMERS}`);
          break;
        case ROLE.HS_STAFF:
          navigate(`/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_SCHEDULES}`);
          break;
        default:
          navigate(`/${ROUTES.HOME}`);
          break;
      }
    }
  };
  useEffect(() => {
    onNavigate();
  }, []);
  return children;
};

export default PublicRoute;
