import { Box, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import Header from "component/header/Header";
import { useState } from "react";
import {
  BasicInformation,
  DailyForm,
  ManagementPlanHeadForm,
} from "component/form";
import { FlexRow } from "component/StyledComponent";
import styled from "@emotion/styled";
import AssessmentSheet1 from "component/form/dailyFormStaff/assessment/AssessmentSheet1";
import AssessmentSheet2 from "component/form/dailyFormStaff/assessment/sheet2/AssessmentSheet2";

export const tablistTitle = [
  "介護記録",
  "基本情報",
  "アセスメントシート1",
  "アセスメントシート2",
  "ケアマネプラン(介護)",
  "ケアマネプラン(障害)",
];

const HistoryRecordDaily = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
  };

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        return <DailyForm />;
      case 1:
        return <BasicInformation />;
      case 2:
        return <AssessmentSheet1 />;
      case 3:
        return <AssessmentSheet2 />;
      case 4:
        return <ManagementPlanHeadForm isNormal={true} />;
      case 5:
        return <ManagementPlanHeadForm isNormal={false} />;
    }
  };

  return (
    <div>
      <Header title="介護記録・履歴　お客様名 様" />
      <div className="page-title"></div>
      <div className="page-container">
        <Tabs borderBottom="0px" onChange={onChangeTab}>
          <TabList
            borderBottom="0px"
            style={{ borderBottom: "0px !important" }}
          >
            {tablistTitle?.map((item: any, index: any) => (
              <FlexRow>
                <StyleTab
                  key={index}
                  _selected={{ borderBottom: "3px solid #28A138" }}
                >
                  <Text fontSize="14px">{item}</Text>
                </StyleTab>
                <Box height="17px" mt="5px" width="2px" background="#e0e0e0" />
              </FlexRow>
            ))}
          </TabList>

          {renderTab()}
        </Tabs>
      </div>
    </div>
  );
};

const StyleTab = styled(Tab)({
  fontSize: "12px",
  borderRadius: "0px",
  padding: "5px 20px",
});

export default HistoryRecordDaily;
