import { Form, Radio, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import axiosClient from "api/axiosClient";
import { ButtonSolid } from "component/button";
import { JP_DATE_FORMAT, formatJapanDate } from "constant/date";
import { IPublicExpense } from "constant/interfaces";
import dayjs from "dayjs";
import useService from "hook/useService";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ITabData } from "..";
import useTable from "hook/useTable";
import EnhanceTable from "component/admin/table";

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    return selectedRows;
  },
  columnTitle: "選択",
};

interface IProps {
  onChangeTab?: (tabData: ITabData) => void;
  isActive?: boolean;
}
const columns: ColumnsType<IPublicExpense> = [
  {
    align: "center",
    title: "公費適用期間",
    render: (value, record: any) =>
      `${formatJapanDate(record.start_date)}から${formatJapanDate(
        record.end_date
      )}まで`,
  },
  {
    align: "center",
    title: "制度名",
    dataIndex: "system_name",
    ellipsis: true,
    className: "min-w-[250px] max-w-[250px]",
  },
  {
    align: "center",
    title: "公費負担者番号",
    dataIndex: "cost_number",
    ellipsis: true,
  },
  {
    align: "center",
    title: "公費受給者番号",
    dataIndex: "beneficiary_number",
    className: "min-w-[250px] max-w-[250px]",
  },
  {
    align: "center",
    title: "本人負担額",
    dataIndex: "payment_amount",
    render: (value) => (value ? `${value}円` : ""),
  },
];

export default function ExpenseList(props: IProps) {
  const { setData, form, setLoading, setTotal, data } = useTable<any>();
  const { id } = useParams();
  const service = useService();
  const [selectedId, setSelectedId] = useState<number>(0);

  const fetchData = async () => {
    try {
      const res = await axiosClient({
        url: service.PUBLIC_EXPENSE,
        method: "get",
        params: { ...form.getFieldsValue(), patient_id: id },
      });
      setData(res.data.serialized_public_expenses);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (!props.isActive) return;
    form.resetFields();
    fetchData();
  }, [props.isActive]);

  return (
    <Form>
      <div className="text-right mb-5">
        <ButtonSolid
          hidden={!selectedId}
          width={137}
          height="35px"
          fontSize={14}
          className="mr-[8px]"
          onClick={() =>
            props.onChangeTab?.({
              activeKey: "create",
              data: { copiedId: selectedId },
            })
          }
        >
          コピーして登録
        </ButtonSolid>
        <ButtonSolid
          width={137}
          height="35px"
          fontSize={14}
          onClick={() => props.onChangeTab?.({ activeKey: "create" })}
        >
          新規追加
        </ButtonSolid>
      </div>
      <EnhanceTable
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        onRow={(data) => ({ onChange: () => setSelectedId(data.id) })}
        className="!my-0"
        pagination={false}
        data={data}
        columns={columns}
        rowKey={(row) => row.id}
      />
    </Form>
  );
}
