/* eslint-disable no-unreachable */
/* eslint-disable no-throw-literal */
/* eslint-disable no-unused-vars */

import type { AxiosInstance, AxiosResponse } from "axios";
import axios from "axios";
import { ROLE } from "constant";
import Cookies from "js-cookie";
import { set } from "lodash";
import qs from "qs";
import { toast } from "react-toastify";
import store from "store";
import userSlice from "store/slice/user";

const BAD_REQUEST = "Bad request !";
const UNAUTHENTICATED = "IDまたはパスワードが正しくありません";
const REQUEST_TIME_OUT = "Request timed out";
const SERVER_ERROR = "Server Error";

export interface $HttpClient {
  get<T = any>(path: string): Promise<T>;
  post<T = any>(path: string, body: any, config?: any): Promise<T>;
  put<T = any>(path: string, body: any): Promise<T>;
  patch<T = any>(path: string, body: any): Promise<T>;
  delete<T = any>(path: string, config?: any): Promise<T>;
}

export default class BaseApi implements $HttpClient {
  private $axiosInstance!: AxiosInstance;
  private readonly DEFAULT_TIMEOUT = 20000;
  private readonly BASE_URL = process.env.REACT_APP_BASE_API_URL || "";
  private successMsg: string | boolean = "";

  constructor() {
    this.$axiosInstance = this.initAxiosInstance();
  }
  /**
   * This method will config axios instance
   * If the request fails with a 401, then try to refresh the token and retry the request.
   *
   * @return The AxiosInstance is being returned.
   */
  private initAxiosInstance(): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: this.BASE_URL,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: this.DEFAULT_TIMEOUT,
    });

    axiosInstance.interceptors.request.use((request) => {
      const access_token = Cookies.get("access_token");
      if (!access_token) {
        return request;
      }
      set(request, "headers.Authorization", `Bearer ${access_token}`);
      return request;
    });

    axiosInstance.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => {
        if (this.successMsg) {
          toast.success(
            typeof this.successMsg === "string"
              ? this.successMsg
              : response?.data?.success
          );
          this.successMsg = "";
        }

        if (response && response.data) {
          return response.data;
        }
        return response;
      },
      async (error) => {
        if (this.successMsg) {
          this.successMsg = "";
        }

        const errorResponse = error.response;
        if (errorResponse && errorResponse.status === 422) {
          toast.error(
            errorResponse?.data?.error || "" || errorResponse?.data?.messages
          );
          return;
        }
        if (
          errorResponse &&
          errorResponse.status === 401 &&
          !window.location.pathname.includes("/login")
        ) {
          toast.error(UNAUTHENTICATED);
          Cookies.remove("access_token");
          store.dispatch(userSlice.actions.clearUser());
          const role = localStorage.getItem("role");
          switch (role) {
            case ROLE.CLIENT:
              window.location.assign("/");
              break;
            default:
              window.location.assign(`/${role || ""}`);
              break;
          }
          return;
        }
        if (errorResponse && errorResponse.status === 500) {
          toast.error(SERVER_ERROR);
          return;
        }
        if (errorResponse && errorResponse.status === 404) {
          if (errorResponse.data.error) {
            toast.error(errorResponse.data.error);
          }
          return;
        }
        if (
          error.code === "ECONNABORTED" &&
          error.message.includes("timeout")
        ) {
          toast.error(REQUEST_TIME_OUT);
          return;
        }
        if (typeof errorResponse?.data?.errors !== "string") {
          if (typeof errorResponse?.data === "object") {
            if (errorResponse.data?.data) {
              toast.error(errorResponse.data?.data);
              return { error: errorResponse.data?.data };
            }

            throw {
              status: errorResponse?.status,
              message: errorResponse?.data,
              success: false,
            };
          }
          toast.error(BAD_REQUEST);
          return;
        } else {
          toast.error(errorResponse?.data?.errors || errorResponse?.data?.data);
          return;
        }
      }
    );
    return axiosInstance;
  }

  /**
   * Set the success message indirectly.
   * @param msg The success message to set.
   */
  protected setSuccessMessage(msg: string | boolean) {
    this.successMsg = msg;
  }

  /**
   * This function returns a promise that resolves to an AxiosResponse object.
   * Get method
   * @param path The path to the resource you want to retrieve.
   * @param params {
   * @return The return type is a Promise of an AxiosResponse.
   */
  get<T = any>(path: string, params: any = {}, data: any = {}): Promise<T> {
    return this.$axiosInstance.get(path, {
      params,
      data,
    });
  }
  /**
   * A function that returns a promise.
   *  Post method
   * @param path string - The path to the endpoint you want to hit.
   * @param body any
   * @return The return type is a Promise of an AxiosResponse.
   */

  post<T = any>(path: string, body: any, config: any = {}): Promise<T> {
    const paramString = qs.stringify(config.params, {
      arrayFormat: "repeat",
      charset: "utf-8",
    });
    const urlPost = paramString ? `${path}?${paramString}` : path;

    return this.$axiosInstance.post(urlPost, body, config);
  }

  /**
   * This function returns a promise that resolves to an AxiosResponse object that contains a generic
   * type T.
   *  Put method
   * @param path The path to the endpoint you want to hit.
   * @param body The body of the request.
   * @return The return type is a Promise of an AxiosResponse of type T.
   */
  put<T = any>(path: string, body: any, config: any = {}): Promise<T> {
    return this.$axiosInstance.put(path, body, config);
  }

  /**
   * This function returns a promise that resolves to an AxiosResponse object.
   * Patch method
   * @param path The path to the endpoint you want to hit.
   * @param body The body of the request.
   * @return The return type is a Promise of an AxiosResponse of type T.
   */
  patch<T = any>(path: string, body: any): Promise<T> {
    return this.$axiosInstance.patch(path, body);
  }

  /**
   * This function returns a promise that resolves to an AxiosResponse object that contains a generic
   * type T.
   * Delete method
   * @param path The path to the resource you want to access.
   * @return The return type is a Promise of an AxiosResponse of type T.
   */
  delete<T = any>(path: string, config?: any): Promise<T> {
    return this.$axiosInstance.delete(path, config);
  }

  deleteMany<T = any>(path: string, body: any): Promise<T> {
    return this.$axiosInstance.delete(path, {
      data: body,
    });
  }
}
