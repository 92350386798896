import { Box, Table, Text, Tr } from "@chakra-ui/react";
import { FlexRow, StyleTd } from "component/StyledComponent";
import React from "react";
import styled from "@emotion/styled";
import moment from "moment";
import "../styles.scss";
import clsx from "clsx";

const TableWeek = ({
  weekStart,
  weekEnd,
  classNameBox1 = "",
  classNameTable1 = "",
  data = [],
  title = "",
}: any) => {
  return (
    <div>
      <FlexRow>
        <Box
          className="!text-center"
          fontWeight="bold"
          fontSize="11px"
          p="20px 0px"
          width="150px"
          bg="bgGray.100"
          border="1px solid #000"
          borderRight="0px"
        >
          {title}
        </Box>
        <Table className="text-xs">
          <HeadTd>{`${weekStart.getDate()}日(日)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(1, "day")
            .get("date")}日(月)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(2, "day")
            .get("date")}日(火)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(3, "day")
            .get("date")}日(水)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(4, "day")
            .get("date")}日(木)`}</HeadTd>
          <HeadTd>{`${moment(weekStart)
            .add(5, "day")
            .get("date")}日(金)`}</HeadTd>
          <HeadTd>{`${weekEnd.getDate()}日(土)`}</HeadTd>
        </Table>
      </FlexRow>
      {!!data.length &&
        data.map((item: any, idx: number) => (
          <FlexRow>
            <Box
              className={clsx(`${classNameBox1}`, {
                "bg-[#ffffff]": Number(idx % 2) === 0,
                "bg-[#f5f4f2]": Number(idx % 2) !== 0,
              })}
              // bg="brand.500"
              display="block"
              position="relative"
              fontSize="11px"
              p="20px 0px"
              width="150px"
              border="1px solid #000"
              borderRight="0px"
              borderTop="0px"
            >
              <Text
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                {item.patient_name}
              </Text>
            </Box>
            <Table className={`table-container text-xs ${classNameTable1}`}>
              <Tr>
                {item.shifts.map((e: any, index: any) => (
                  <TableTd key={index}>
                    {!!e.length &&
                      e.map((element: any) => (
                        <BoxTd
                          style={{
                            backgroundColor: element?.nurse_name && "#1AD633",
                          }}
                        >
                          <StyleText>{element?.nurse_name || "-"}</StyleText>
                          <StyleText>{element?.time_range || "-"}</StyleText>
                          <StyleText>
                            <p className="service-name">
                              {element?.service_name || "-"}
                            </p>
                          </StyleText>
                        </BoxTd>
                      ))}
                  </TableTd>
                ))}
              </Tr>
            </Table>
          </FlexRow>
        ))}
    </div>
  );
};
const HeadTd = styled(StyleTd)({
  background: "#f5f4f2",
  border: "1px solid #000",
  fontSize: "11px",
  padding: "20px 0px 0px 0px",
  width: "120px",
  fontWeight: "bold",
  height: "42px",
});

const BoxTd = styled(Box)({
  padding: "2px",
  border: "1px solid black",
});

const TableTd = styled(StyleTd)({
  border: "1px solid #000",
  borderTop: "none",
  fontSize: "11px",
  width: "120px",
  textAlign: "left",
  verticalAlign: "top",
});

const StyleText = styled(Text)({
  fontSize: "12px",
});

export default TableWeek;
