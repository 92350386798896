import { Stack } from '@chakra-ui/layout'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { StyleGrid, StyleGridItem } from 'component/StyledComponent'
import '../../../dailyform.scss'
import { Input } from '@chakra-ui/react'
import { Form } from 'antd'

export const styleRadioProps = {
  bg: 'brand2.500 !important', width: '65% !important', height: '65% !important',
}

const RadioSheet2Form = ({ title, rdTit = [], value, setValue, col = 5, name, valueAll = [], sign = '', isRequire = false,
  nameText, disable = false, color = "#555555", fontSize = '14px' }: any) => {
  return (
    <StyleGrid style={{padding:'0px'}}
      // templateColumns={`repeat(${col}, 1fr)`}
     >
      {/* <StyleGridItem colSpan={1} >
        <p style={{ color: color, fontSize: fontSize }}>
          <span style={{ color: `${isRequire && 'var(--primary-color)'}` }}>{sign}</span>{title}</p>
      </StyleGridItem> */}
      {/* <StyleGridItem  >
       

      </StyleGridItem> */}
      <Form.Item label={title} name={name} style={{marginBottom:'1px'}} required >
        <RadioGroup colorScheme='white' style={{ fontSize: fontSize }}
        //  onChange={(evt: any) => setValue({ ...valueAll, [name]: evt })} value={value}
        >
          <Stack direction='row'>
            {rdTit && rdTit.map((item: any, index: number) => (
              <Radio _before={styleRadioProps} mr='7px' isReadOnly
                isDisabled={disable} value={valueAll[index]} borderColor={`${value === valueAll[index] ? 'var(--header-color)' : '#000'}`} >
                <p style={{ fontSize: fontSize }} >{item}</p></Radio>
            ))}
          </Stack>
        </RadioGroup>
      </Form.Item>

      <Form.Item label=" " name={nameText}>
        <Input fontSize='12px' type="text" disabled={disable}
          height='32px' placeholder='特記事項' isReadOnly
          border='1px' borderColor='#000' borderRadius='0'
        />
      </Form.Item>
    </StyleGrid>
  )
}

export default RadioSheet2Form
