import { SearchOutlined } from "@ant-design/icons";
import { Button, Empty, Checkbox, Form, Input, Select, Spin } from "antd";
import { ButtonOutline } from "component/button";
import { useEffect, useState } from "react";
import apiFactory from "api";
import "./style.scss";
import MESSAGES from "constant/messages";
import { Option } from "antd/es/mentions";
import noticeSlice from "store/slice/noticeSlice";
import { useSelector } from "react-redux";
import { RootState } from "store";
import CustomPagination from "component/CustomPagination";

const generateContact = (
  contactList: any[],
  setPart: any,
  setContactId: any,
  setContactName: any,
  navigate: any,
  dispatch: any,
  userId: number
) => {
  const onContact = (e: any) => {
    const roomName =
      e?.source_id === userId ? e?.target?.family_name : e?.source?.family_name;

    navigate(`/admin/contact-chat/${e?.id}`, {
      state: { title: roomName },
    });
    dispatch(noticeSlice?.actions?.setTitle(roomName));
    setPart("ContactChat");
    setContactId(e?.id);
    setContactName(roomName);
  };
  return (
    <div>
      {contactList?.map((e, i) => {
        const roomName =
          e?.source_id === userId
            ? e?.target?.family_name
            : e.source?.family_name;

        return (
          <div className="contact underline-news">
            {!e.is_unread ? (
              <div className="white-dot" />
            ) : (
              <div className="dot" />
            )}
            <span
              onClick={() => {
                apiFactory.contactApi.readMessage(e?.id);
                onContact(e);
              }}
            >
              {roomName} {e.lastest_send_at}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const AsyncSelect = ({ chooseUser, setUsersSelect }: any) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  // const [currentTotal, setCurrentTotal] = useState(0)
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Array<any>>([]);
  const fetchUserList = async () => {
    const data = await apiFactory.nursingApi.getList({
      per: limit,
      page: page,
    });
    if (data) {
      setUsers(
        data.nursing_staffs.map((e: any) => (
          <Option key={e.id} value={e.id}>
            {e.family_name}
          </Option>
        ))
      );
      setTotalItems(data.total_items);
    }
  };
  const onscroll = async (event: any) => {
    if (
      event.currentTarget.scrollTop + event.currentTarget.clientHeight >=
        event.currentTarget.scrollHeight &&
      page * limit < totalItems &&
      !loading
    ) {
      users.push(
        <Option key={"loading"} value={"loading"} disabled>
          <Spin className="absolute left-[50%]" />
        </Option>
      );
      setUsers([...users]);
      setLoading(true);
      setTimeout(async () => {
        const data = await apiFactory.nursingApi.getList({
          per: limit,
          page: page + 1,
        });
        if (data) {
          users.pop();
          const newUsers = users.concat(
            data.nursing_staffs.map((e: any) => (
              <Option key={e.id} value={e.id}>
                {e.family_name}
              </Option>
            ))
          );
          setUsersSelect(newUsers.map((item) => item?.props));
          setUsers(newUsers);
          setPage(page + 1);
          setTotalItems(data.total_items);
        }
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  return (
    <>
      <Select
        style={{ width: 180 }}
        onChange={(e: any) => chooseUser(e)}
        onPopupScroll={onscroll}
      >
        {users}
      </Select>
    </>
  );
};

const ContactList = ({
  setPart,
  setContactId,
  setContactName,
  navigate,
  dispatch,
}: any) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [contacts, setContacts] = useState<[]>([]);
  const [usersSelect, setUsersSelect] = useState<Array<any>>([]);
  const [choosedUser, setChoosedUser] = useState<number>(0);
  const [chatSearch, setChatSearch] = useState("");

  const [unread, setUnread] = useState(false);
  const [form] = Form.useForm();
  const { tab } = useSelector((state: RootState) => state.notice);
  const { id: userId } = useSelector((state: RootState) => state.user);

  const fetchContactList = async () => {
    const data = await apiFactory.contactApi.getContactList({
      per: limit,
      page: unread ? 1 : page,
      unread: unread,
    });
    if (data) {
      setContacts(data.rooms);
      setTotalItems(data?.total_items);
    }
  };

  const handleSubmitSearch = async (e: any) => {
    setLimit(10);
    setPage(1);
    const data = await apiFactory.contactApi.getContactList({
      per: limit,
      page: page,
      unread: unread,
      search: e?.searchKeyword,
    });
    if (data) {
      setContacts(data.rooms);
      setTotalItems(data?.total_items);
    }
  };

  const fetchUserList = async () => {
    const data = await apiFactory.nursingApi.getList({
      per: limit,
      page: page,
    });
    if (data) {
      setUsersSelect(
        data.nursing_staffs.map((e: any) => ({
          label: e.family_name,
          value: e.id,
        }))
      );
    }
  };
  const chooseUser = (e: any) => {
    const u = usersSelect.find((f) => Number(f.value) === Number(e));
    setChoosedUser(u?.value);
  };
  const onNewContact = async () => {
    // check is this contact existed
    if (choosedUser !== 0) {
      const res = await apiFactory.contactApi.createRoom({
        target_id: choosedUser,
      });
      // if (res) {
      // setContactId(res?.id);

      const roomName =
        res?.source_id === userId
          ? res?.target?.family_name
          : res?.source?.family_name;

      setContactName(roomName);
      navigate(`/admin/contact-chat/${res?.id}`, {
        state: {
          title: roomName,
        },
      });
      // }
    }
  };
  const onRead = (e: any) => {
    setUnread(e.target.checked);
    fetchContactList();
  };
  const handleChangePagePerSizes = (size: number) => {
    setLimit(size);
    if (totalItems <= size) {
      return setPage(1);
    } else if (
      totalItems / size > 1 &&
      totalItems / size < 2 &&
      page > totalItems / size
    ) {
      return setPage(Math.floor(totalItems / size) + 1);
    } else if (
      totalItems / size > 2 &&
      page > totalItems / size &&
      totalItems % size !== 0
    ) {
      return setPage(Math.floor(totalItems / size) + 1);
    } else if (totalItems % size === 0 && page > totalItems / size) {
      return setPage(totalItems / size);
    }
  };
  useEffect(() => {
    fetchContactList();
    fetchUserList();
  }, [unread, limit, page, tab]);

  useEffect(() => {
    Number(tab) !== 2 &&
      form.setFieldsValue({
        searchKeyword: "",
      });
    setPage(1);
    setLimit(10);
    setChatSearch("");
  }, [tab]);
  return (
    <div className="contact-list">
      <Form
        initialValues={{ searchKeyword: "" }}
        form={form}
        onFinish={handleSubmitSearch}
        className="w-[100%]"
      >
        <div className="py-[16px] px-[25px] bg-[#F5F4F2]">
          <p className="text-[#000000] text-[12px] leading-[20px] mb-[8px]">
            キーワード
          </p>
          <div className="flex gap-x-[21px]">
            <Form.Item name="searchKeyword" className="w-[100%]">
              <Input
                className="customInp border-[#707070]"
                placeholder="氏名、カナ"
                prefix={
                  <SearchOutlined className="text-[16px] text-[#2699FB] mr-[10px]" />
                }
                value={chatSearch}
                onChange={(e: any) => setChatSearch(e.target.value)}
              />
            </Form.Item>
            <ButtonOutline
              className="!py-[6px] !px-[63px] !bg-[#FFFFFF]"
              _hover={{
                color: "brand.500]",
                bg: "brand.100",
              }}
              type="submit"
            >
              検索
            </ButtonOutline>
          </div>
        </div>
        <div className="text-end mt-[19px] mb-[21px] new-contact">
          <Form>
            <Form.Item
              label="スタッフ名"
              name="user"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <AsyncSelect
                chooseUser={chooseUser}
                setUsersSelect={setUsersSelect}
              />
            </Form.Item>
          </Form>
          <Button
            className="new-contact-button"
            htmlType="submit"
            onClick={onNewContact}
          >
            新規連絡
          </Button>
        </div>
        <div className="paging-head">
          <div className="flex items-center justify-between wrapper-pagination mt-[15px]">
            <Checkbox
              onChange={(e) => {
                setUnread(e.target.checked);
                setPage(1);
                setLimit(10);
              }}
            >
              未読のみ
            </Checkbox>
          </div>
        </div>
        {contacts.length > 0 ? (
          <div>
            {generateContact(
              contacts,
              setPart,
              setContactId,
              setContactName,
              navigate,
              dispatch,
              Number(userId)
            )}
            {contacts.length > 0 && (
              <div className="flex items-center justify-end wrapper-pagination mt-[15px]">
                <CustomPagination
                  current={page}
                  setCurrent={setPage}
                  total={totalItems}
                  perPage={limit}
                  setPerPage={setLimit}
                  pageSizeOptions={[10, 20, 50, 100]}
                />
              </div>
            )}
          </div>
        ) : (
          <Empty
            description="データがありません"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Form>
    </div>
  );
};
export { ContactList };
