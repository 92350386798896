import { ReactNode} from 'react';
import { Modal, ModalProps } from 'antd';

interface IModalCustomProps extends ModalProps{
    open: boolean
    onCancel: () => void
    title: ReactNode
    children: ReactNode
}
export default function ModalCustom({ open, onCancel, title, children, ...props }: IModalCustomProps) {
    return (
        <Modal title={title} open={open} onCancel={onCancel} {...props}>
            {children}
        </Modal>
    )
}
