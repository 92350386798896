import { Text } from '@chakra-ui/react'
import React from 'react'
import RadioSheet2Form from './RadioSheet2Form'

const Sheet2MentalState = ({disable}: any) => {
  return (
    <div>
      <Text fontSize='14px'>◯入浴</Text>
      <RadioSheet2Form disable={disable} title="認知度" sign="※"  valueAll={["mild", "moderate", "severe", "none"]}
       isRequire rdTit={["軽度", "中度", "重度", "なし"]} name="cognitive_level" col={4}
        nameText="cognitive" />

      <RadioSheet2Form disable={disable} title="意思表示" sign="※" valueAll={[true, false]}
       isRequire rdTit={["可能", "不可能"]} name="is_possible_declaration_intent" col={4}
        nameText="declaration_intent" />
      <RadioSheet2Form disable={disable} title="理解" sign="※" valueAll={[true, false]}
       isRequire rdTit={["可能", "不可能"]} name="is_possible_understanding" col={4}
        nameText="understanding" />

      <RadioSheet2Form disable={disable} title="徘徊" sign="※" isRequire valueAll={[true, false]}
      rdTit={["あり", "なし"]} name="is_wandering" col={4}
       nameText="wandering" />
      <RadioSheet2Form disable={disable} title="暴力" sign="※" isRequire valueAll={[true, false]}
      rdTit={["あり", "なし"]} name="is_violence" col={4}
        nameText="violence" />
      <RadioSheet2Form disable={disable} title="妄想" sign="※" isRequire valueAll={[true, false]}
      rdTit={["あり", "なし"]} name="is_delusion" col={4}
       nameText="delusion" />
      <RadioSheet2Form disable={disable} title="拒否" sign="※" isRequire valueAll={[true, false]}
      rdTit={["あり", "なし"]} name="is_denial" col={4}
        nameText="denial" />

      <Text fontSize='14px'>◯医療</Text>
      <RadioSheet2Form disable={disable} title="処置" sign="※" isRequire valueAll={[true, false]}
      rdTit={["あり", "なし"]} name="is_treatment" col={4}
       nameText="treatment" />

      <RadioSheet2Form disable={disable} title="投薬" sign="※"  valueAll={["independence", "supervision", "partial_assistance", "full_assistance"]}
      isRequire rdTit={["自立", "見守り", "一部介助", "全介助"]} name="dosage_level" col={4}
        nameText="dosage" />
    </div>
  )
}
export default Sheet2MentalState
