import styled from "@emotion/styled";
import { Input } from "antd";

export const InputStyled = styled(Input)`
  border-radius: 0px;
  max-height: 25px;
  .ant-input::placeholder {
      font-size: 12px;
      line-height: 20px;
  }
  .ant-input:focus::placeholder {
    color: transparent !important;
  }
`;
