import styled from "@emotion/styled";

const Styles = styled.div`
  th {
    // border-right: 1px solid #e0e0e0 !important;
  }
  td {
    &.ant-table-cell {
      // font-weight: 550;
    }
  }
`;
export default Styles;
