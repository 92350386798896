import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Form, Checkbox, DatePicker, TimePicker, Select, Input } from "antd";
import "dayjs/locale/zh-cn";
// import "./style.scss";
import dayjs, { Dayjs } from "dayjs";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import ModalCustom from "component/admin/modal";
import { Row, Space } from "antd";
import { toast } from "react-toastify";
import { useDrawerContext } from "@chakra-ui/react";
import { CloseOutlined } from "@ant-design/icons";
import MESSAGES from "constant/messages";
import SelectWithData from "component/SelectWithData";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { JP_DATEPICKER_FORMAT } from "constant/date";

const { RangePicker } = TimePicker;
const { Item, useWatch } = Form;

dayjs.extend(customParseFormat);

const initialFormValues = {
  // date: dayjs(),
  // time: [dayjs(), undefined],
  // isFilterNurse: false,
};

interface IScheduleRegister {
  openRegisterShift: boolean;
  setOpenRegisterShift: (value: boolean) => void;
  title: string;
  setIsClientScheduleEdited: Dispatch<SetStateAction<boolean>>;
  setIsShiftEdited: Dispatch<SetStateAction<boolean>>;
  setCreateShift: any;
}

const ScheduleRegister = ({
  openRegisterShift,
  setOpenRegisterShift,
  title,
  setIsClientScheduleEdited,
  setIsShiftEdited,
  setCreateShift,
}: IScheduleRegister) => {
  const [form] = Form.useForm();
  const disabledDate = (current: Dayjs) =>
    current && !current.add(-1, "M").isSame(dayjs(), "M");
  const service = useService();

  const dateValue = useWatch("date", form);
  const patientIdValue = useWatch("patient_id", form);
  const timeValue = useWatch("time", form);

  const getPatientOptions = async () =>
    (
      await axiosClient.get(service.PATIENTS, {
        params: { get_all: true, schedule_date: dateValue },
      })
    ).data.data.map((i: any) => ({ label: i.family_name, value: i.id }));

  const handleSubmit = async (fieldValues: any) => {
    try {
      const { time, date, isFilterNurse, ...rest } = fieldValues;
      const response =
        await apiFactory.shiftManagementApi.registerPatientSchedule({
          date: date?.toDate(),
          time_range: time,
          ...rest,
        });
      toast.success(response.success);
      setOpenRegisterShift(false);
      setCreateShift((prev: any) => prev + 1);
      setIsClientScheduleEdited(true);
      setIsShiftEdited(true);
      form.resetFields();
    } catch (error) {
      console.error(error);
      setCreateShift((prev: any) => prev + 1);
    }
  };
  const getTimeRangeOptions = async () =>
    (
      await axiosClient.get(service.SCHEDULES, {
        params: {
          patient_id: patientIdValue,
          date: dayjs(dateValue).format("YYYY/MM/DD"),
        },
      })
    ).data.map((i: any) => ({
      label: i.time_range,
      value: i.time_range,
      data: i,
    }));

  const getStaffOptions = async () =>
    (
      await axiosClient.get(service.SCHEDULES + "/nursing_staffs", {
        params: {
          date: dayjs(dateValue).format("YYYY/MM/DD"),
          time_range: timeValue,
        },
      })
    ).data.nursing_staffs.map((i: any) => ({
      label: i.family_name,
      value: i.id,
    }));

  useEffect(() => {
    if (timeValue)
      axiosClient
        .get(service.SCHEDULES, {
          params: {
            time_range: timeValue,
            patient_id: patientIdValue,
            date: dayjs(dateValue).format("YYYY/MM/DD"),
          },
        })
        .then((res) => {});
  }, [timeValue]);
  return (
    <ModalCustom
      className="shift-modal"
      title={title}
      closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      footer={
        <Row justify="end">
          <Space size={8}>
            <ButtonOutline
              onClick={() => {
                form.resetFields();
                setOpenRegisterShift(false);
                setCreateShift(false);
              }}
              className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[16px]"
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid
              onClick={form.submit}
              className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[54px]"
            >
              保存
            </ButtonSolid>
          </Space>
        </Row>
      }
      open={openRegisterShift}
      onCancel={() => setOpenRegisterShift(false)}
    >
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        className="mt-[26px]"
        form={form}
        initialValues={initialFormValues}
      >
        <Item
          name="date"
          label="日にち"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <DatePicker
            format={JP_DATEPICKER_FORMAT}
            onChange={() => {
              form.resetFields(["time"]);
              form.resetFields(["nurse_id"]);
              form.resetFields(["service"]);
              form.resetFields(["service_type"]);
              form.resetFields(["patient_id"]);
            }}
            className="w-full"
            disabledDate={disabledDate}
            placeholder="選択してください"
            defaultPickerValue={dayjs().add(1, "M").date(1)}
          />
        </Item>
        <Item
          rules={[{ required: true, message: MESSAGES.requireField }]}
          name="patient_id"
          label="お客様"
        >
          <SelectWithData
            disabled={!dateValue}
            onChange={() => {
              form.resetFields(["time"]);
              form.resetFields(["nurse_id"]);
              form.resetFields(["service"]);
              form.resetFields(["service_type"]);
              form.resetFields(["service_id"]);
              form.resetFields(["service_type_id"]);
            }}
            getOptions={getPatientOptions}
            placeholder="選択してください"
            allowGetOption={Boolean(dateValue)}
            getOptionDepdendancy={[dateValue]}
          />
        </Item>
        <Item
          name="time"
          label="時間"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <SelectWithData
            getOptions={getTimeRangeOptions}
            getOptionDepdendancy={[patientIdValue, dateValue]}
            allowGetOption={Boolean(patientIdValue && dateValue)}
            disabled={!patientIdValue || !dateValue}
            placeholder="選択してください"
            onChange={(value, option: any) => {
              form.setFieldValue(
                "service",
                option.data.scheduleable.service.service_name
              );
              form.setFieldValue(
                "service_id",
                option.data.scheduleable.service.id
              );
              form.setFieldValue(
                "service_type",
                option.data.scheduleable.service_type.detail
              );
              form.setFieldValue(
                "service_type_id",
                option.data.scheduleable.service_type.id
              );
              form.resetFields(["nurse_id"]);
            }}
          />
        </Item>
        <Item name="service_id" hidden>
          <div />
        </Item>
        <Item name="service" label="サービス名称">
          <Input disabled placeholder="選択してください" />
        </Item>
        <Item name="service_type_id" hidden>
          <div />
        </Item>
        <Item name="service_type" label="サービス内容">
          <Input disabled placeholder="選択してください" />
        </Item>
        <Item
          name="nurse_id"
          label="介護スタッフ"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <SelectWithData
            getOptions={getStaffOptions}
            allowGetOption={Boolean(timeValue && dateValue)}
            disabled={!dateValue || !timeValue}
            placeholder="選択してください"
          />
        </Item>
        {/* <Item name="isFilterNurse" valuePropName="checked">
          <Checkbox>該当時間内に勤務中・勤務予定の者のみ表示</Checkbox>
        </Item> */}
      </Form>
    </ModalCustom>
  );
};

export default ScheduleRegister;
