import styled from "@emotion/styled";
import { DatePicker, Form, Input, Select } from "antd";
import apiFactory from "api";
import CustomPagination from "component/CustomPagination";
import { ButtonSolid } from "component/button";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_OPTIONS,
  DEFAULT_PER_PAGE,
} from "constant/apiRequest";
import { formatDate } from "constant/date";
import { INotification } from "constant/interfaces";
import { useState } from "react";
import { useQuery } from "react-query";
import { notificationCategoryOptions } from "utils/notification";

const { RangePicker } = DatePicker;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-color: #d9d9d9 !important;
    height: 35px !important;
    width: 200px !important;
  }
`;

const Conveyed: React.FC = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);

  const { data, refetch: refetchNotifications } = useQuery(
    ["conveyed", perPage, page],
    () =>
      apiFactory.boardAdminApi.getPostList({
        page,
        per: perPage,
        notify_tab: 2,
        search: form.getFieldValue("search")?.trim(),
        start_at: form.getFieldValue("dateRange")?.[0]
          ? formatDate(form.getFieldValue("dateRange")?.[0])
          : "",
        end_at: form.getFieldValue("dateRange")?.[1]
          ? formatDate(form.getFieldValue("dateRange")?.[1])
          : "",
        notification_category_id: form.getFieldValue(
          "notification_category_id"
        ),
      })
  );

  const handleSearch = () => {
    setPage(DEFAULT_PAGE);
    refetchNotifications();
  };

  return (
    <div className="pt-[12px]">
      <Form form={form} onFinish={handleSearch}>
        <div className="flex">
          <Form.Item name="search" className="w-[200px] mr-2">
            <Input
              placeholder="内容を入力して検索する"
              className="!border-[#D9D9D9] h-[35px]"
            />
          </Form.Item>
          <Form.Item name="dateRange" className="mr-2">
            <RangePicker className="!border-[#D9D9D9] h-[35px]" />
          </Form.Item>
          <Form.Item name="notification_category_id" className="mr-2">
            <StyledSelect
              allowClear
              placeholder="カテゴリー"
              options={notificationCategoryOptions}
            />
          </Form.Item>
          <ButtonSolid type="submit">検索</ButtonSolid>
        </div>
      </Form>

      <CustomPagination
        current={page}
        setCurrent={setPage}
        total={data?.total_items || 0}
        perPage={perPage}
        setPerPage={setPerPage}
        pageSizeOptions={DEFAULT_PAGE_OPTIONS}
      />

      <ul>
        {data?.notifications?.map((item: INotification) => {
          return (
            <div
              className="flex border-t border-b border-solid border-[#00000026] px-[15px] py-[8px]"
              key={item?.id}
            >
              <div className="mr-[100px] w-[350px]">
                <p className="font-semibold">
                  {item?.notification_category?.name}
                </p>
                <p>
                  <span className="text-[#0000008C]">作成日:</span>
                  {item?.created_at}
                </p>
              </div>
              <div className="w-[500px]">
                <p>{item?.content}</p>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Conveyed;
