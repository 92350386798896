import styled from "@emotion/styled";
import { DatePicker, Form, Input, Select } from "antd";
import apiFactory from "api";
import { ButtonSolid } from "component/button";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_OPTIONS,
  DEFAULT_PER_PAGE,
} from "constant/apiRequest";
import { formatDate } from "constant/date";
import { INotification } from "constant/interfaces";
import { useState } from "react";
import { useQuery } from "react-query";
import { notificationCategoryOptions } from "utils/notification";
import { SearchOutlined } from "@ant-design/icons";
import useResponsive from "hook/useResponsive";
import CustomPagination from "component/CustomPagination";

const { RangePicker } = DatePicker;

const StyledRangePickerContainer = styled.div`
  @media (max-width: 576px) {
    .ant-picker-panels {
      flex-direction: column !important;
    }
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-color: #d9d9d9 !important;

    @media (min-width: 576px) {
      width: 200px !important;
      height: 35px !important;
    }

    @media (max-width: 576px) {
      border-radius: 4px;
      height: 40px !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        margin-top: 4px;
      }
    }
  }
`;

const Conveyed: React.FC = () => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const { isMobile } = useResponsive();

  const { data, refetch: refetchNotifications } = useQuery(
    ["conveyed", perPage, page],
    () =>
      apiFactory.staffNoticeApi.getNotifications({
        page,
        per: perPage,
        notify_tab: 2,
        search: form.getFieldValue("search")?.trim(),
        start_at: form.getFieldValue("dateRange")?.[0]
          ? formatDate(form.getFieldValue("dateRange")?.[0])
          : "",
        end_at: form.getFieldValue("dateRange")?.[1]
          ? formatDate(form.getFieldValue("dateRange")?.[1])
          : "",
        notification_category_id: form.getFieldValue(
          "notification_category_id"
        ),
      })
  );

  const handleSearch = () => {
    setPage(DEFAULT_PAGE);
    refetchNotifications();
  };

  return (
    <div className="pt-[12px]">
      <Form form={form} onFinish={handleSearch}>
        <div className="flex flex-wrap">
          <Form.Item
            name="search"
            className="max-md:w-[100%] w-[200px] md:mr-2 max-md:mb-[10px]"
          >
            <Input
              placeholder="内容を入力して検索する"
              className="!border-[#D9D9D9] h-[35px] max-md:!h-[40px] max-md:rounded-[4px]"
              prefix={
                isMobile ? (
                  <SearchOutlined
                    style={{
                      fontSize: "20px",
                      color: "#666666",
                    }}
                  />
                ) : null
              }
            />
          </Form.Item>

          <div className="flex flex-nowrap">
            <Form.Item name="dateRange" className="mr-2 max-md:w-[43%]">
              <RangePicker
                panelRender={(panelNode) => (
                  <StyledRangePickerContainer>
                    {panelNode}
                  </StyledRangePickerContainer>
                )}
                className="!border-[#D9D9D9] h-[35px] max-md:rounded-[4px] max-md:h-[40px]"
              />
            </Form.Item>

            <Form.Item
              name="notification_category_id"
              className="mr-2 max-md:w-[31%] max-md:max-w-[100px]"
            >
              <StyledSelect
                allowClear
                placeholder="カテゴリー"
                options={notificationCategoryOptions}
              />
            </Form.Item>

            <ButtonSolid type="submit" className="flex-1 max-md:!h-[40px]">
              検索
            </ButtonSolid>
          </div>
        </div>
      </Form>

      <CustomPagination
        current={page}
        setCurrent={setPage}
        total={data?.total_items || 0}
        perPage={perPage}
        setPerPage={setPerPage}
        pageSizeOptions={DEFAULT_PAGE_OPTIONS}
      />

      <ul className="max-md:mt-[30px]">
        {data?.notifications?.map((item: INotification) => {
          return (
            <div
              className="flex max-md:flex-col md:border-t border-b border-solid border-[#00000026] px-[15px] py-[8px]"
              key={item?.id}
            >
              <div className="mr-[100px] md:w-[350px]">
                <p className="font-semibold max-md:font-[700]">
                  {item?.notification_category?.name}
                </p>
                <p>
                  <span className="text-[#0000008C]">作成日:</span>
                  {item?.created_at}
                </p>
              </div>

              <div className="md:w-[500px]">
                <p>{item?.content}</p>
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Conveyed;
