import { useState } from "react";
import HiddenTab from "component/tab";
import InsuranceCardList from "./List";
import CreateInsuranceCard from "./Create";

export interface ITabData {
  activeKey: string;
  data?: {
    copiedId: number;
  };
}

export default function InsuranceCard() {
  const [tab, setTab] = useState<ITabData>({
    activeKey: "list",
  });

  const tabItems = [
    {
      key: "list",
      children: (
        <InsuranceCardList
          onChangeTab={setTab}
          isActive={tab.activeKey === "list"}
        />
      ),
    },
    {
      key: "create",
      children: (
        <CreateInsuranceCard
          copiedId={tab.data?.copiedId}
          onChangeTab={setTab}
          isActive={tab.activeKey === "create"}
        />
      ),
    },
  ];
  return (
    <div>
      <HiddenTab activeKey={tab.activeKey} onChange={setTab} items={tabItems} />
    </div>
  );
}
