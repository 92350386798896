import { Button, Col, Form, Input, Row } from "antd";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./style.scss";
import MESSAGES from "constant/messages";
import { NumericFormat } from "react-number-format";

const AccidentCareServiceDetail = ({
  onChange,
  id,
  setServiceId,
  difference,
  setDifference,
}: any) => {
  const [form] = Form.useForm();
  const [errorMeassage, setErrorsMeassage] = useState("");
  const [data, setData] = useState<{
    id: number | null;
    serviceName: string;
    unitPrice: number | null;
  }>({
    id: null,
    serviceName: "",
    unitPrice: null,
  });
  const onFinish = async (values: any) => {
    let unitPrice = values?.unitPrice;
    if (typeof values?.unitPrice !== "number") {
      unitPrice = Number(values?.unitPrice?.replaceAll(",", ""));
    }
    const body = {
      id: values.id,
      service_name: values.serviceName.trim(),
      unit_price: unitPrice,
      patient_type: "disability",
    };
    try {
      setErrorsMeassage("");
      let result;
      switch (difference.type) {
        case "add":
          result = await apiFactory.careRegistrationApi.createService(body);

          break;
        case "edit":
          result = await apiFactory.careRegistrationApi.updateService(body);

          break;
        default:
          break;
      }
      if (result) {
        toast.success(result.success);
        switch (difference.type) {
          case "add":
            setServiceId(null);
            onChange();
            break;
          case "edit":
            setServiceId(id);
            setDifference({
              type: "view",
            });
            break;
          default:
            break;
        }
      }
    } catch (error: any) {
      const err = Object.keys(error?.message).reduce((cumulator, e) => {
        return (cumulator += `${error?.message[e]} \n`);
      }, "");
      setErrorsMeassage(err);
      if (error && error.status === 422 && error.message) {
        if (error.message.service_name) {
          form.setFields([
            {
              name: "serviceName",
              errors: [error.message.service_name]
            }
          ]);
        }
      }
    }
  };

  const onBack = () => {
    if (difference.type === "edit") {
      setServiceId(id);
      setDifference({
        type: "view",
      });
    } else {
      setServiceId(null);
      onChange();
    }
  };
  const fetchData = async () => {
    if (id) {
      try {
        const result = await apiFactory.careRegistrationApi.getServiceDetail(
          id
        );
        if (result) {
          form.setFieldsValue({
            id: result?.id,
            code: result?.service_code,
            serviceName: result?.service_name,
            unitPrice: result?.unit_price,
          });
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [difference]);
  return (
    <div className="pt-[7px] accident-care-service-detail">
      {difference.type === "view" && (
        <div className="text-end mb-[30px]">
          <ButtonOutline
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] mr-[8px]"
            onClick={onBack}
          >
            一覧へ戻る
          </ButtonOutline>
          <ButtonSolid
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
            onClick={() =>
              setDifference({
                type: "edit",
              })
            }
          >
            編集
          </ButtonSolid>
        </div>
      )}
      <Form initialValues={data} onFinish={onFinish} form={form}>
        <Row>
          <Col span={11}>
            <Form.Item label="障害サービス名称ID" name="id">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="サービス名称"
              name="serviceName"
              rules={[
                { required: true, message: MESSAGES.requireField },
                { max: 255 },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value !== "" && value.trim() === "") {
                      return Promise.reject(errorMeassage);
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input disabled={difference.type === "view"} />
            </Form.Item>

            <Form.Item
              label="単価"
              name="unitPrice"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  validator: (_, value) => {
                    if (errorMeassage && value !== "") {
                      return Promise.reject("入力した値が範囲外です。");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <NumericFormat
                onChange={() => setErrorsMeassage("")}
                onKeyPress={(event: any) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                disabled={difference.type === "view"}
                customInput={Input}
                thousandsGroupStyle="wan"
                thousandSeparator=","
                decimalScale={2}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13} />
          <Col span={11}>
            <div className="flex items-center justify-between">
              <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
              <div className="flex gap-x-[8px]">
                {difference.type !== "view" && (
                  <ButtonOutline
                    className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                    onClick={onBack}
                  >
                    キャンセル
                  </ButtonOutline>
                )}
                {difference.type !== "view" && (
                  <Button className="save-button" htmlType="submit">
                    保存
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export { AccidentCareServiceDetail };
