import { put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { setIsDeleted } from "store/slice/hsStaffNoticeSlice";

export const setIsDeletedAction = createAction("hs-staff/is-deleted");

function* setIsDeletedView(action: any) {
  yield put(setIsDeleted(action.payload));
}

function* hsStaffNoticeSaga() {
  yield takeLatest(setIsDeletedAction, setIsDeletedView);
}

export default hsStaffNoticeSaga;
