const REGEX = {
  atLeastOneNonSpaceCharacter: /[^\s]+/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/u,
  onlyTextAndNumber: /^[a-zA-Z0-9\p{L}\p{M}\s]+$/gu, //  only text and number
  onlyText: /^[a-zA-Z\p{L}\p{M}\s]+$/gu,
  address: /^[\p{L}a-zA-Z0-9\s,-\/]+$/u, // include text, number and "," "-" character,
  onlyNumber: /^[0-9]+$/, //  only number
  onlyKatakanaText: /^(?!^\s)([ァ-ン\s]|ー)+$/,
  onlyNumeric: /^-?\d*(\.\d*)?$/, // number and "-", "."
  zipcode: /\d{3}-\d{4}/, // ###-####
  passwordStaff: /^(?=.*[0-9])(?=.*[a-z])(?!.* ).{8,}$/
};
export default REGEX;
