import { ChakraProvider, Box, Grid, extendTheme } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "routes";
import { Provider } from "react-redux";
import store from "store";
import { ConfigProvider } from "antd";
import ja_JP from "antd/es/locale/ja_JP";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const theme = extendTheme({
  colors: {
    brand: {
      50: "#e07b3f",
      100: "#e07b3f",
      500: "var(--primary-color)", // you need this
      900: "#f99900",
    },
    brand2: {
      50: "#e07b3f",
      100: "#e07b3f",
      500: "var(--header-color)", // you need this
      900: "#f99900",
    },
    bgGray: {
      50: "#eeeeee",
      100: "#f5f4f2",
      500: "#eeeeee",
    },
    black: {
      50: "var(--primary-text-dark)",
      100: "var(--primary-text-dark)",
      500: "var(--primary-text-dark)",
    },
    gray: {
      800: "#000",
    },
    primaryFontColor: {
      lightMode: "#000",
      darkMode: "#000",
    },
  },
  fonts: {
    heading: "var(--primary-font-family)",
    body: `var(--primary-font-family)`,
  },
  useSystemColorMode: true,
  initialColorMode: "light",
});

export const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={ja_JP}
        theme={{
          token: {
            fontFamily: "Arial",
          },
          components: {
            Button: {
              colorPrimary: "#e07b2e",
              algorithm: true,
            },
          },
        }}
      >
        <ChakraProvider theme={theme}>
          <CookiesProvider>
            <ToastContainer autoClose={1500} />
            <Box textAlign="center" fontSize="xl">
              <Grid minH="100vh">
                <AppRoutes />
              </Grid>
            </Box>
          </CookiesProvider>
        </ChakraProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </Provider>
);
