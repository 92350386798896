import { CONTACT } from "../apiConstant";
import BaseApi from "api/baseApi";

class ContactApi extends BaseApi {
  createRoom(body: { target_id: number }) {
    return this.post(CONTACT, body);
  }
  getContactList(body: {
    per?: number;
    page?: number;
    unread?: boolean;
    search?: string;
  }) {
    return this.get(CONTACT, body);
  }
  getContactDetail(body: { id: number; per: number; page: number }) {
    return this.get(`${CONTACT}/${body.id}?per=${body.per}&page=${body.page}`);
  }
  createMessage(body: { id: number; content: string }) {
    return this.post(`${CONTACT}/${body.id}/messages`, body);
  }
  readMessage(id: number) {
    return this.put(`${CONTACT}/${id}`, {});
  }
}
export default ContactApi;
