import { NURSING_MANAGEMENT_STAFF } from "../apiConstant";
import BaseApi from "api/baseApi";

class StaffNursingApi extends BaseApi {
  getList(body: {
    per: number;
    page: number;
    is_send_message?: boolean;

    // fullname: string | null,
    // status: string | null,
    // sortKey: string | null,
    // order: string | null,
  }) {
    return this.get(NURSING_MANAGEMENT_STAFF, body);
  }
}
export default StaffNursingApi;
