import styled from "@emotion/styled";

const Styles = styled.div`
  overflow: auto;
  transform: rotateX(180deg);
  .custom-tr:nth-child(2) .custom-td {
    border-bottom: none;
  }
  .custom-tr1 .custom-td {
    border-bottom: none;
  }
  .custom-tr2 .custom-td {
    border-bottom: 2px solid #ececec;
  }
  .custom-td {
    min-width: 60px;
    max-width: 60px;
    height: 60px;
    padding: 0;
    text-align: center;
  }
`

const StyleForm = styled.div`
  .icon-container {
    width: 30px;
    height: 30px;
    border: 1px solid #000000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 12px;
  }
  .ant-row {
    display: block;
  }
`;

const StyleContainer = styled.div `
  .ant-select-selector {
    border-radius: 6px !important;
  }
  `
export { Styles, StyleForm, StyleContainer }
