import { ButtonSolid } from "component/button";
import {
  FlexRow,
  StyleGrid,
  StyleGridItem,
  StyleSelect,
  StyleTitle,
} from "component/StyledComponent";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CLIENTPATH } from "route";
import { Text, Box, Tab, TabList, Tabs } from "@chakra-ui/react";
import RadioForm from "component/form/RadioForm";
import SelectForm from "component/form/SelectForm";
import styled from "@emotion/styled";
import { TimePicker } from "antd";
import { BODYTEMPERATURE, BLOODPRESSURE, getCookie } from "utils";
import locale from "antd/es/date-picker/locale/ja_JP";
import moment from "moment-timezone";
import "moment/locale/ja";
import "moment-timezone";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { CheckboxForm } from "component/form";

export const tablistTitle = ["対応記録"];

const RecordHistory = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const idToken = getCookie("access_token");
  const user = useSelector((state: RootState) => state.user);
  const timeJapan = "Asia/Tokyo";
  dayjs.tz.setDefault(timeJapan);

  const [defaultValue, setDefaultValue] = useState({
    id: "",
    division: "normal" /*区分*/,
    physicalCare: "body" /*身体介護*/,
    serviceNameNote: "" /*身体介護*/,
    serContent: "" /*サービス名称。*/,
    serviceDetail: "" /*サービス内容*/,
    staffInCharge: "" /*担当スタッフ*/,
    complexion: "good" /*顔色*/,
    sweating: "yes" /*発汗*/,
    bodyTemperature: "36" /*体温*/,
    bloodPressure1: "75" /*血圧*/,
    bloodPressure2: "130" /*血圧*/,
    urination: "0" /*排尿*/,
    defecation: "0" /*排便*/,
    hydration: "100" /*水分補給*/,
    eatingAssistance: "選択してください" /*摂食介助*/,
    cleaning: "選択してください" /*清拭*/,
    fullBodyBath: "選択してください" /*清拭*/,
    enviArr: false,
    consultAssist: false,
    record: false,
    toilet: false,
    diaper: false,
    pad: false,
    urinal: false,
    maintain: false,
    hair: false,
    bodyWash: false,
    wash: false,
    oral: false,
    dressing: false,
    position: false,
    mobility: false,
    watch: false,
  });

  const [timeStartReal, setTimeStartReal]: any = useState(
    new Date(new Date().setHours(9, 0, 0, 0))
  );
  const [timeEndReal, setTimeEndReal]: any = useState(
    new Date(new Date().setHours(9, 28, 0, 0))
  );
  const [dayDisplay, setDayDisplay]: any = useState(new Date());
  const [dayShow, setDayShow] = useState("");

  const onChangeValue = (evt: any) => {
    const name = evt.target.name;
    setDefaultValue({ ...defaultValue, [name]: evt.target.value });
  };

  /** #region: Defined Button action*/
  const navigate = useNavigate();
  const { id } = useParams();
  const service = useService();

  const [tabIndex, setTabIndex] = useState(0);
  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
  };

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <div>
            <FlexRow className="flex-wrap" style={{ textAlign: "left" }}>
              {/* Left */}
              <div className="w-1/2">
                {/* ■概要 */}
                <StyleTitle>■概要</StyleTitle>
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-2">日時</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={4}>
                    <Box border="1px solid #000" borderRadius="5px">
                      <Text className="p-2">
                        {" "}
                        {moment(dayDisplay).format("YYYY/MM/DD (ddd)")}　
                        {dayShow}
                      </Text>
                    </Box>
                    {/* <StyleDatePicker disabled={disable} format={moment(dateTime).tz('Japan').format('YYYY/MM/DD (ddd)')} onChange={onChangeDate} locale={locale} /> */}
                  </StyleGridItem>
                </StyleGrid>
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP>対応開始時間</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={4}>
                    <StyleTimePicker
                      disabled={true}
                      inputReadOnly
                      format="HH:mm"
                      value={timeStartReal ? dayjs(timeStartReal) : null}
                      showHour
                      showMinute
                      locale={locale}
                    />
                  </StyleGridItem>
                </StyleGrid>
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP>対応終了時間</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={4}>
                    <StyleTimePicker
                      disabled={true}
                      inputReadOnly
                      value={timeEndReal ? dayjs(timeEndReal) : null}
                      format="HH:mm"
                      showHour
                      showMinute
                      locale={locale}
                    />
                  </StyleGridItem>
                </StyleGrid>
                <RadioForm
                  title="区分"
                  rdTit1="介護"
                  rdTit2="障害"
                  value={defaultValue?.division === "normal" ? "介護" : "障害"}
                  color="#000"
                  fontSize="14px"
                />
                <RadioForm
                  title="身体介護"
                  rdTit1="身体"
                  rdTit2="生活"
                  value={
                    defaultValue?.physicalCare === "body" ? "身体" : "生活"
                  }
                  color="#000"
                  fontSize="14px"
                />
                <SelectForm
                  disable={true}
                  title="サービス名称"
                  holder={defaultValue?.serContent}
                  name="serContent"
                  color="#000"
                  fontSize="14px"
                />
                <SelectForm
                  disable={true}
                  title="サービス内容"
                  holder={defaultValue?.serviceDetail}
                  name="prefecture"
                  color="#000"
                  fontSize="14px"
                />
                <SelectForm
                  disable={true}
                  title="担当スタッフ"
                  holder={defaultValue?.staffInCharge}
                  name="staffInCharge"
                  color="#000"
                  fontSize="14px"
                />

                {/* ■事前チェック*/}
                <StyleTitle>■事前チェック</StyleTitle>
                <RadioForm
                  title="顔色"
                  rdTit1="良"
                  rdTit2="不良"
                  value={defaultValue?.complexion === "good" ? "良" : "不良"}
                  color="#000"
                  fontSize="14px"
                />
                <RadioForm
                  title="発汗"
                  rdTit1="有"
                  rdTit2="無"
                  value={defaultValue?.sweating === "yes" ? "有" : "無"}
                  color="#000"
                  fontSize="14px"
                />
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">体温</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <StyleSelect
                      ml="8px"
                      _placeholder={{ color: "var(--placeholder-color)" }}
                      style={{ fontWeight: "normal", borderRadius: "5px" }}
                      name="temperature"
                      value={defaultValue?.bodyTemperature}
                      onChange={onChangeValue}
                      disabled={true}
                    >
                      {BODYTEMPERATURE?.map((item: any, index: any) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </StyleSelect>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-2" style={{ fontSize: "17px" }}>
                      ℃
                    </TextP>
                  </StyleGridItem>
                </StyleGrid>
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">血圧</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={2}>
                    <FlexRow>
                      <StyleSelect
                        ml="8px"
                        _placeholder={{ color: "var(--placeholder-color)" }}
                        style={{ fontWeight: "normal", borderRadius: "5px" }}
                        name="bloodPressure1"
                        value={defaultValue?.bloodPressure1}
                        onChange={onChangeValue}
                        disabled={true}
                      >
                        {BLOODPRESSURE?.map((item: any, index: any) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </StyleSelect>
                      <Text className="p-2">／</Text>
                      <StyleSelect
                        _placeholder={{ color: "var(--placeholder-color)" }}
                        style={{ fontWeight: "normal", borderRadius: "5px" }}
                        name="bloodPressure2"
                        value={defaultValue?.bloodPressure2}
                        onChange={onChangeValue}
                        disabled={true}
                      >
                        {BLOODPRESSURE?.map((item: any, index: any) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </StyleSelect>
                    </FlexRow>
                  </StyleGridItem>
                </StyleGrid>
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="環境整備"
                  content="対応"
                  value={defaultValue.enviArr}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="相談援助"
                  content="対応"
                  value={defaultValue.consultAssist}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="記録"
                  content="対応"
                  value={defaultValue.record}
                  fontSize="14px"
                />

                {/*■排泄*/}
                <StyleTitle>■排泄</StyleTitle>
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="トイレ介助"
                  content="対応"
                  value={defaultValue.toilet}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="オムツ確認・交換"
                  content="対応"
                  value={defaultValue.diaper}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="パット確認・交換"
                  content="対応"
                  value={defaultValue.pad}
                  fontSize="14px"
                />
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">排尿</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <StyleSelect
                      ml="8px"
                      _placeholder={{ color: "var(--placeholder-color)" }}
                      style={{ fontWeight: "normal", borderRadius: "5px" }}
                      disabled={true}
                      placeholder={defaultValue?.urination}
                    ></StyleSelect>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">回</TextP>
                  </StyleGridItem>
                </StyleGrid>
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">排便</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <StyleSelect
                      ml="8px"
                      _placeholder={{ color: "var(--placeholder-color)" }}
                      style={{ fontWeight: "normal", borderRadius: "5px" }}
                      disabled={true}
                      placeholder={defaultValue?.defecation}
                    ></StyleSelect>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">回</TextP>
                  </StyleGridItem>
                </StyleGrid>
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="尿器洗浄"
                  content="対応"
                  value={defaultValue.urinal}
                  fontSize="14px"
                />
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-2"></TextP>
                  </StyleGridItem>
                </StyleGrid>
              </div>

              {/* Right */}
              <div className="w-1/2">
                {/* ■食事 */}
                <StyleTitle>■食事</StyleTitle>
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="姿勢の確保"
                  content="対応"
                  value={defaultValue?.maintain}
                  fontSize="14px"
                />
                <StyleGrid templateColumns="repeat(5, 1fr)" gap={6}>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-3">水分補給</TextP>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <StyleSelect
                      _placeholder={{ color: "var(--placeholder-color)" }}
                      style={{ fontWeight: "normal", borderRadius: "5px" }}
                      disabled={true}
                      placeholder={defaultValue?.hydration}
                    ></StyleSelect>
                  </StyleGridItem>
                  <StyleGridItem colSpan={1}>
                    <TextP className="mt-2" style={{ fontSize: "17px" }}>
                      cc
                    </TextP>
                  </StyleGridItem>
                </StyleGrid>
                <SelectForm
                  title="摂食介助"
                  holder={defaultValue?.eatingAssistance}
                  color="#000"
                  fontSize="14px"
                  disable={true}
                />

                {/* ■入浴　身体整容 */}
                <StyleTitle>■入浴　身体整容</StyleTitle>
                <SelectForm
                  title="清拭"
                  holder={defaultValue?.cleaning}
                  color="#000"
                  fontSize="14px"
                  disable={true}
                />
                <SelectForm
                  title="全身浴"
                  holder={defaultValue?.fullBodyBath}
                  color="#000"
                  fontSize="14px"
                  disable={true}
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="洗髪"
                  content="対応"
                  value={defaultValue.hair}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="洗身"
                  content="対応"
                  value={defaultValue.bodyWash}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="洗面"
                  content="対応"
                  value={defaultValue.wash}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="口腔ケア"
                  content="対応"
                  value={defaultValue.oral}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="更衣介助"
                  content="対応"
                  value={defaultValue.dressing}
                  fontSize="14px"
                />

                {/* ■移動 */}
                <StyleTitle>■移動</StyleTitle>
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="体位交換"
                  content="対応"
                  value={defaultValue.position}
                  fontSize="14px"
                />
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="移動・移乗介助"
                  content="対応"
                  value={defaultValue.mobility}
                  fontSize="14px"
                />

                {/* ■その他 */}
                <StyleTitle>■その他</StyleTitle>
                <CheckboxForm
                  col={5}
                  disable={true}
                  title="見守り"
                  content="対応"
                  value={defaultValue.watch}
                  fontSize="14px"
                />
              </div>
            </FlexRow>
          </div>
        );
    }
  };

  const fetchData = async () => {
    try {
      const res = await axiosClient({
        url: `${service.NURSING_CARE_HISTORIES}/schedule_date`,
        method: "get",
        params: { schedule_date_id: id },
      });
      const detail = res.data.data;
      setTimeStartReal(
        new Date(moment(detail?.start_time, "HH:mm").toISOString())
      );
      setTimeEndReal(new Date(moment(detail?.end_time, "HH:mm").toISOString()));
      setDayDisplay(new Date(detail?.schedule_date?.date));
      setDayShow(`${detail.start_time} ~ ${detail.end_time}`);
      setDefaultValue({
        ...defaultValue,
        division: detail?.division,
        physicalCare: detail?.physical_care,
        serContent: detail?.service?.service_name,
        serviceDetail: detail?.service_type?.detail,
        staffInCharge: detail?.nurse?.family_name,
        complexion: detail?.complexion,
        sweating: detail?.sweating,
        enviArr: detail?.environmental_arrangement,
        consultAssist: detail?.consultation_assistance,
        record: detail?.record,
        toilet: detail?.toilet_assistance,
        diaper: detail?.diaper_check,
        pad: detail?.pad_confirmation,
        urination: `${detail?.urination}`,
        defecation: `${detail?.defecation}`,
        urinal: detail?.urinal_cleaning,
        maintain: detail?.maintain_posture,
        hydration: `${detail?.hydration}`,
        eatingAssistance: detail?.eating_assistance,
        cleaning: detail?.cleaning,
        fullBodyBath: detail?.full_body_bath,
        hair: detail?.washing_hair,
        bodyWash: detail?.full_body_bath_procedure,
        wash: detail?.washbasin,
        oral: detail?.oral_care,
        dressing: detail?.dressing_assistance,
        position: detail?.position_exchange,
        mobility: detail?.transfer_assistance,
        watch: detail?.watch_over,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  return (
    <div className="page-container bg-[#FFFFFF] h-full">
      {/* <Header title="対応記録・履歴　お客様名様" /> */}
      <FlexRow style={{ justifyContent: "flex-end" }}>
        <ButtonSolid
          className="!py-[6px] !px-[63px] !bg-[#E07B2E]"
          onClick={() => navigate(CLIENTPATH.historyList)}
          width="137px"
          height="35px"
          fontSize="14px"
          m={3}
        >
          一覧へ
        </ButtonSolid>
      </FlexRow>
      <Tabs borderBottom="0px" onChange={onChangeTab}>
        <TabList borderBottom="0px" style={{ borderBottom: "0px !important" }}>
          {tablistTitle?.map((item: any, index: any) => (
            <FlexRow>
              <StyleTab
                key={index}
                _selected={{ borderBottom: "3px solid #28A138" }}
              >
                {item}
              </StyleTab>
              <Box height="17px" mt="5px" width="2px" background="#e0e0e0" />
            </FlexRow>
          ))}
        </TabList>
        {renderTab()}
      </Tabs>
    </div>
  );
};

const TextP = styled("p")({
  fontSize: "14px",
  color: "#555555",
});

const StyleTab = styled(Tab)({
  fontSize: "14px",
  borderRadius: "0px",
  padding: "5px 10px",
});

const StyleTimePicker = styled(TimePicker)({
  borderRadius: "0",
  fontWeight: "600",
  color: "var(--primary-text-dark)",
  border: "1px solid black",
  width: "160px",
});

export default RecordHistory;
