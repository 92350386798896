import { Stack } from "@chakra-ui/layout";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Text } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import styled from "@emotion/styled";
import { DatePickerProps, Form, Input, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import { useForm } from "antd/es/form/Form";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import {
  FlexRow,
  StyleGrid,
  StyleGridItem,
  StyleTitle,
} from "component/StyledComponent";
import { ButtonOutline, ButtonSolid } from "component/button";
import ROUTES from "constant/routes";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import "moment-timezone";
import moment from "moment-timezone";
import "moment/locale/ja";
import queryString from "query-string";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "store";
import { FORMAT, getCookie } from "utils";
import CheckboxForm from "./CheckboxForm";
import FormSelect from "./FormSelect";
import RadioForm, { styleRadioProps } from "./RadioForm";
import "./dailyform.scss";
import apiFactory from "api";
import {
  bodyBathData,
  dataNum,
  dataNumCC,
  eatingData,
  getTypeCarePlanParam,
  usePreviousValue,
} from "../_config";
import { DATE_FORMAT_2, TIME_FORMAT } from "constant/date";

const DailyForm = () => {
  const idToken = getCookie("access_token");
  const location = useLocation();
  const queryObj: any = queryString.parse(location.search);
  const user = useSelector((state: RootState) => state.user);

  const [form] = useForm();
  const { id: nursingCareHistoryId } = useParams();
  const [defaultValue, setDefaultValue] = useState({
    date: "",
    serContent: "",
    serDetail: "",
    staff: queryObj.staff_id || null,
    patient: null,
    patient_id: null,
    eating: "なし",
    cleaning: "なし",
    bodyBath: "なし",
    report: "",
    supportActDetail: "",
    urinate: "0",
    defecation: "0",
    hydration: "0",
  });

  const [checkValue, setCheckValue] = useState({
    enviArr: false,
    consultAssist: false,
    record: false,
    toilet: false,
    diaper: false,
    pad: false,
    urinal: false,
    maintain: false,
    hair: false,
    bodyWash: false,
    wash: false,
    oral: false,
    dressing: false,
    position: false,
    mobility: false,
    watch: false,
  });

  const [timeStart, setTimeStart]: any = useState(
    new Date(new Date().setHours(9, 0, 0, 0))
  );
  const [timeStartReal, setTimeStartReal]: any = useState(
    new Date(new Date().setHours(9, 0, 0, 0))
  );

  const [timeEnd, setTimeEnd]: any = useState(
    new Date(new Date().setHours(9, 30, 0, 0))
  );
  const [timeEndReal, setTimeEndReal]: any = useState(
    new Date(new Date().setHours(9, 28, 0, 0))
  );
  const [dayDisplay, setDayDisplay]: any = useState(new Date());
  const [dayShow, setDayShow] = useState("");
  const [division, setDivision] = useState(queryObj?.division || "");
  const [physicCare, setPhysicCare] = useState("body");
  const [complex, setComplex] = useState("");
  const [sweat, setSweat] = useState("");
  const [serviceDetail, setServiceDetail] = useState("");
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const [errorVal, setErrorVal] = useState({
    errComplex: "",
    errSweat: "",
  });
  const [serContentData, setSerContentData] = useState([]);
  const [serviceDetailData, setserviceDetailData] = useState([]);
  const [staffInCharge, setStaffInCharge] = useState([]);
  const [errorToScroll, setErrorToScroll] = useState("");

  //-- Handlers
  const onChangeValue = (evt: any, field?: string, value?: any) => {
    let _name = "";
    let _value = "";

    if (field && value) {
      _name = field;
      _value = value;
    } else {
      _name = evt.target.name;
      _value = evt.target.value;
    }

    setDefaultValue((prev) => ({ ...prev, [_name]: _value }));
  };

  const onChangeCheckValue = (evt: any) => {
    const name = evt.target.name;
    setCheckValue((prev) => ({ ...prev, [name]: evt.target.checked }));
  };

  const onChangeDivision = (evt: any) => {
    setDivision(evt);
    if (evt === "normal") {
      setPhysicCare("body");
    } else if (evt === "disability") {
      setPhysicCare("body");
    }
  };

  const getStaffActive = async () => {
    try {
      const param = {
        "status[]": ["active"],
        per: "10000",
      };
      const res = await nursingCareRecordApi.getStaffActive(
        idToken,
        param,
        user?.role
      );
      if (res) {
        const newRes = res.nursing_staffs.map((item: any) => ({
          value: item.id.toString(),
          content: `${item.family_name}`,
        }));
        setStaffInCharge(newRes);

        if (defaultValue.staff) {
          form.setFieldValue("staff", defaultValue.staff?.toString());
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSerDetail = useCallback(async () => {
    try {
      const param = {
        per: "10000",
      };
      const res = await nursingCareRecordApi.getServiceType(
        idToken,
        param,
        user?.role
      );
      if (res) {
        if (res.serialized_service_types) {
          const newRes = res.serialized_service_types.map((item: any) => ({
            value: item.id?.toString(),
            content: item.detail,
          }));
          setserviceDetailData(newRes);
        } else {
          const newRes = res.data.map((item: any) => ({
            value: item.id?.toString(),
            content: item.detail,
          }));
          setserviceDetailData(newRes);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const getNursingCare = async () => {
    try {
      const res =
        await apiFactory.staffNursingCareRecord.getDetailNursingCareHistories<
          any | null
        >(nursingCareHistoryId!);
      if (res) {
        setDefaultValue((prev) => ({
          ...prev,
          serContent: res.service_id,
          defecation: res.defecation,
          urinate: res.urination,
          cleaning: res.cleaning,
          eating: res.eating_assistance,
          bodyBath: res.full_body_bath,
          hydration: res.hydration,
          report: res.note,
          staff: res.nurse_id,
          patient: res.patient,
          patient_id: res.patient_id,
        }));
        form.setFieldValue("staff", res?.nurse_id?.toString());
        setCheckValue({
          ...checkValue,
          enviArr: res.environmental_arrangement,
          consultAssist: res.consultation_assistance,
          record: res.record,
          toilet: res.toilet_assistance,
          diaper: res.diaper_check,
          pad: res.pad_confirmation,
          urinal: res.urinal_cleaning,
          maintain: res.maintain_posture,
          hair: res.washing_hair,
          bodyWash: res.full_body_bath_procedure,
          wash: res.washbasin,
          oral: res.oral_care,
          dressing: res.dressing_assistance,
          position: res.position_exchange,
          mobility: res.transfer_assistance,
          watch: res.watch_over,
        });
        setDivision(res.division);
        setComplex(res.complexion);
        setTimeEndReal(
          new Date(
            moment(
              res?.schedule_date?.time_range?.split("~")?.[1]?.trim(),
              TIME_FORMAT
            ).toISOString()
          )
        );

        setTimeStartReal(
          new Date(
            moment(
              res?.schedule_date?.time_range?.split("~")?.[0]?.trim(),
              TIME_FORMAT
            ).toISOString()
          )
        );
        setPhysicCare(res?.physical_care || "body");
        setServiceDetail(res.service_type_id?.toString());
        setSweat(res.sweating);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSerContent = async (isUpdate = false) => {
    const param = {
      patient_type: division,
      per: "10000",
    };
    try {
      const res = await nursingCareRecordApi.getPatientService(
        idToken,
        param,
        user?.role
      );
      if (res) {
        if (res.serialized_services) {
          const newRes = res.serialized_services.map((item: any) => ({
            value: item.id,
            content: item.service_name,
          }));
          setSerContentData(newRes);
          if (isUpdate) {
            setDefaultValue((prev) => ({
              ...prev,
              serContent: newRes[0].value,
            }));
          }
        } else {
          const newRes = res.data.map((item: any) => ({
            value: item.id,
            content: item.service_name,
          }));
          setSerContentData(newRes);
          if (isUpdate) {
            setDefaultValue((prev) => ({
              ...defaultValue,
              serContent: newRes[0].value,
            }));
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getScheduleDetail = async () => {
    try {
      const res = await nursingCareRecordApi.getSchedule(
        idToken,
        queryObj.scheduleable,
        user?.role
      );
      if (res) {
        setServiceDetail(res.service_type.id?.toString());
        setDefaultValue((prev) => ({ ...prev, serContent: res.service.id }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleBack = () => {
    if (queryObj.redirectPath) {
      navigate(queryObj.redirectPath);
    } else {
      navigate(`/${ROUTES.STAFF}`);
    }
  };

  useEffect(() => {
    getSerDetail();
  }, [getSerDetail]);

  useEffect(() => {
    if (queryObj.type === "edit") {
      getNursingCare();
    } else if (queryObj.type === "create") {
      getScheduleDetail();
    }
    getStaffActive();
  }, []);

  const previousValue = usePreviousValue(division);

  useEffect(() => {
    if (previousValue) {
      getSerContent(true);
    } else {
      getSerContent(false);
    }
  }, [division]);

  const onChangeTimeStart: DatePickerProps["onChange"] = (date: any) => {
    if (date) {
      setTimeStartReal(new Date(date));
    } else {
      setTimeStartReal(null);
    }
  };

  const onChangeTimeEnd: DatePickerProps["onChange"] = (date: any) => {
    if (date) {
      setTimeEndReal(new Date(date));
    } else {
      setTimeEndReal(null);
    }
  };

  useEffect(() => {
    if (location.search) {
      setDayDisplay(new Date(queryObj.date));
      setDayShow(`${queryObj.start_time} ~ ${queryObj.end_time}`);
      setTimeStart(
        new Date(moment(queryObj.start_time, "HH:mm").toISOString())
      );
      setTimeEnd(new Date(moment(queryObj.end_time, "HH:mm").toISOString()));
      if (queryObj.type === "create") {
        setDivision(getTypeCarePlanParam(queryObj?.division));
        setTimeStartReal(
          new Date(moment(queryObj.start_time, "HH:mm").toISOString())
        );
        setTimeEndReal(
          // new Date(moment(queryObj.end_time, "HH:mm").toISOString()).setMinutes(
          new Date(moment(queryObj.end_time, "HH:mm").toISOString())
          // .getMinutes() - 2
          // )
        );
      }
    }
  }, [location?.search, setDefaultValue]);

  const validate = () => {
    let isValidate = false;
    let err: any = {};

    if (!complex) {
      err.errComplex = "選択してください";
      isValidate = true;

      setErrorToScroll((prev) => (!prev ? "errComplex" : prev));
    }

    if (!sweat) {
      err.errSweat = "選択してください";
      isValidate = true;

      setErrorToScroll((prev) => (!prev ? "errSweat" : prev));
    }

    setErrorVal(err);
    return isValidate;
  };

  const onSubmit = async () => {
    setErrorToScroll("");

    if (validate()) {
      toast.error("選択してください");
      return;
    } else {
      setErrorVal({
        errComplex: "",
        errSweat: "",
      });
      const body: any = {
        patient_id: defaultValue.patient_id,
        patient: defaultValue.patient,
        schedule_date_id: queryObj.schedule_date_id,
        full_body_bath: defaultValue.bodyBath,
        urination: defaultValue.urinate,
        defecation: defaultValue.defecation,
        hydration: defaultValue.hydration,
        division: division,
        service_id: defaultValue.serContent,
        service_type_id: Number(serviceDetail),
        nurse_id: defaultValue.staff,
        eating_assistance: defaultValue.eating,
        cleaning: defaultValue.cleaning,
        report: defaultValue.report,
        environmental_arrangement: checkValue.enviArr,
        consultation_assistance: checkValue.consultAssist,
        record: checkValue.record,
        toilet_assistance: checkValue.toilet,
        diaper_check: checkValue.diaper,
        pad_confirmation: checkValue.pad,
        urinal_cleaning: checkValue.urinal,
        maintain_posture: checkValue.maintain,
        washing_hair: checkValue.hair,
        full_body_bath_procedure: checkValue.bodyWash,
        washbasin: checkValue.wash,
        oral_care: checkValue.oral,
        dressing_assistance: checkValue.dressing,
        position_exchange: checkValue.position,
        transfer_assistance: checkValue.mobility,
        watch_over: checkValue.watch,
        physical_care: physicCare,
        complexion: complex,
        sweating: sweat,
        note: defaultValue.report,
        end_time: timeEndReal
          ? moment(timeEndReal).format("HH:mm")
          : moment(timeEnd).format("HH:mm"),
        start_time: timeStartReal
          ? moment(timeStartReal).format("HH:mm")
          : moment(timeStart).format("HH:mm"),
      };
      try {
        const res =
          await apiFactory.staffNursingCareRecord.updateDetailNursingCareHistories<any>(
            nursingCareHistoryId!,
            body
          );
        if (res) {
          toast.success(res?.success);
          handleBack();
        }
      } catch (error: any) {
        toast.error(error.message.messages);
      }
    }
    // }
  };

  // Handle scroll to error
  const handelScrollToError = (id?: string) => {
    const _id = id || errorToScroll;

    if (!_id) return;
    const element = document.getElementById(_id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (errorToScroll) {
      handelScrollToError();
    }
  }, [errorToScroll]);

  return (
    <div className="mb-12 md:mb-0">
      <FlexRow className="flex-wrap" style={{ textAlign: "left" }}>
        <StyleTitle className="w-full ">◯概要</StyleTitle>

        <div className="w-full md:w-1/2">
          <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
            <StyleGridItem colSpan={1}>
              <TextP>日付</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <Input
                value={
                  moment(dayDisplay).format("YYYY/MM/DD (ddd)") + " " + dayShow
                }
                onChange={() => {}}
                readOnly
                size="large"
                disabled={disable}
              />
            </StyleGridItem>
          </StyleGrid>

          <div className="grid grid-cols-2 md:grid-cols-1">
            <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
              <StyleGridItem colSpan={1}>
                <TextP>対応開始時間</TextP>
              </StyleGridItem>

              <StyleGridItem colSpan={3}>
                <StyleTimePicker
                  disabled={disable}
                  inputReadOnly
                  format="HH:mm"
                  value={timeStartReal ? dayjs(timeStartReal) : null}
                  showHour
                  showMinute
                  onChange={onChangeTimeStart}
                  locale={locale}
                  className="w-full"
                  size="large"
                  allowClear={false}
                  suffixIcon={<></>}
                />
              </StyleGridItem>
            </StyleGrid>

            <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
              <StyleGridItem colSpan={1}>
                <TextP>対応終了時間</TextP>
              </StyleGridItem>
              <StyleGridItem colSpan={3}>
                <StyleTimePicker
                  disabled={disable}
                  inputReadOnly
                  value={timeEndReal ? dayjs(timeEndReal) : null}
                  format="HH:mm"
                  showHour
                  showMinute
                  onChange={onChangeTimeEnd}
                  locale={locale}
                  className="w-full"
                  size="large"
                  allowClear={false}
                  suffixIcon={<></>}
                />
              </StyleGridItem>
            </StyleGrid>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-1">
            {/* Division */}

            <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
              <StyleGridItem colSpan={1}>
                <TextP>区分</TextP>
              </StyleGridItem>
              <StyleGridItem colSpan={3}>
                <RadioGroup
                  colorScheme="white"
                  name="division"
                  onChange={onChangeDivision}
                  value={division}
                >
                  <Stack direction="row">
                    <Radio
                      _before={styleRadioProps}
                      isDisabled={true}
                      value="normal"
                      borderColor={`${
                        division === "介護" ? "var(--header-color)" : "#000"
                      }`}
                    >
                      <p>介護</p>
                    </Radio>
                    <Radio
                      _before={styleRadioProps}
                      value="disability"
                      borderColor={`${
                        division === "障害" ? "var(--header-color)" : "#000"
                      }`}
                      isDisabled={true}
                    >
                      <p>障害</p>{" "}
                    </Radio>
                  </Stack>
                </RadioGroup>
              </StyleGridItem>
            </StyleGrid>

            {/* Physic Care */}
            {division === "normal" && (
              <StyleGrid
                className="grid-cols-1 !gap-y-2 md:grid-cols-4"
                gap={6}
              >
                <StyleGridItem colSpan={1}>
                  <TextP>身体介護</TextP>
                </StyleGridItem>
                <StyleGridItem colSpan={3}>
                  <RadioGroup
                    colorScheme="white"
                    name="physicCare"
                    onChange={setPhysicCare}
                    value={physicCare}
                  >
                    <Stack direction="row">
                      <Radio
                        value="body"
                        _before={styleRadioProps}
                        isDisabled={disable}
                        borderColor="#000"
                      >
                        <p>身体</p>
                      </Radio>
                      <Radio
                        value="life"
                        _before={styleRadioProps}
                        isDisabled={disable}
                        borderColor="#000"
                      >
                        <p>生活</p>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </StyleGridItem>
              </StyleGrid>
            )}
          </div>

          {/* Comprehensive Support Act for Persons with Disabilities */}
          {division === "disability" && (
            <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
              <StyleGridItem colSpan={1}>
                <TextP>障害者総合支援法</TextP>
              </StyleGridItem>
              <StyleGridItem colSpan={3}>
                <RadioGroup
                  colorScheme="white"
                  name="physicCare"
                  onChange={setPhysicCare}
                  value={physicCare}
                >
                  <Stack direction="row">
                    <Radio
                      value="body"
                      _before={styleRadioProps}
                      isDisabled={disable}
                      borderColor="#000"
                    >
                      <p>身体</p>{" "}
                    </Radio>
                    <Radio
                      value="severe_visit"
                      _before={styleRadioProps}
                      isDisabled={disable}
                      borderColor="#000"
                    >
                      <p>重度訪問</p>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </StyleGridItem>
            </StyleGrid>
          )}

          {/* Service Content */}
          <FormSelect
            col={4}
            disable={disable}
            title="サービス名称"
            value={defaultValue.serContent}
            name="serContent"
            changeValue={onChangeValue}
            data={serContentData}
          />

          {/* Service detail */}
          {/* <FormSelect
            col={4}
            disable={disable}
            title="サービス内容"
            value={serviceDetail}
            onChange={setServiceDetail}
            data={serviceDetailData}
          /> */}

          {/* Staff in Charge */}
          <Form
            form={form}
            name="form"
            onFinish={onSubmit}
            scrollToFirstError={{ block: "center" }}
            onFinishFailed={(errorInfo: any) => {
              setErrorToScroll(errorInfo.errorFields[0].name[0]);
              handelScrollToError(errorInfo.errorFields[0].name[0]);

              validate();
            }}
          >
            <div id="staff" className="scroll-mt-12">
              <StyledFormItem
                label=""
                name="staff"
                rules={[
                  {
                    required: true,
                    message: "選択してください",
                  },
                ]}
                initialValue={defaultValue.staff?.toString()}
              >
                <FormSelect
                  col={4}
                  disable={disable}
                  title="担当スタッフ"
                  value={defaultValue.staff?.toString()}
                  name="staff"
                  holder={"選択してください"}
                  changeValue={onChangeValue}
                  data={staffInCharge}
                />
              </StyledFormItem>
            </div>
          </Form>
        </div>

        <div className="w-full md:w-1/2 pl-3">
          <StyleGrid
            className="grid-cols-1 !gap-y-2 scroll-mt-14 md:grid-cols-4"
            gap={1}
          >
            <StyleGridItem colSpan={1} style={{ paddingRight: "0px" }}>
              <TextP>特記事項・申し送り</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <Textarea
                fontSize="16px"
                disabled={disable}
                value={defaultValue.report}
                onChange={onChangeValue}
                name="report"
                size="md"
                border="1px"
                borderColor="#000"
                borderRadius="0"
                rows={5}
              />
            </StyleGridItem>
          </StyleGrid>
        </div>

        <StyleTitle className="w-full">◯事前チェック</StyleTitle>
        <div className="w-full md:w-1/2">
          <div className="grid grid-cols-2 md:grid-cols-1">
            {/* Complexion */}
            <RadioForm
              col={4}
              disable={disable}
              title="顔色"
              rdTit1="良"
              rdTit2="不良"
              rdVal1="good"
              rdVal2="bad"
              value={complex}
              setValue={setComplex}
              error={!complex && errorVal.errComplex}
              id="errComplex"
            />

            {/* Sweating */}
            <RadioForm
              col={4}
              disable={disable}
              title="発汗"
              rdTit1="有"
              rdTit2="無"
              rdVal1="yes"
              rdVal2="no"
              value={sweat}
              setValue={setSweat}
              error={!sweat && errorVal.errSweat}
              id="errSweat"
            />

            {/* Environmental arrangement */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="環境整備"
              content="対応"
              value={checkValue.enviArr}
              changeValue={onChangeCheckValue}
              name="enviArr"
            />
            {/* Consultation assistance */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="相談援助"
              content="対応"
              value={checkValue.consultAssist}
              changeValue={onChangeCheckValue}
              name="consultAssist"
            />
            {/* record */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="記録"
              content="対応"
              value={checkValue.record}
              changeValue={onChangeCheckValue}
              name="record"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 pl-3"></div>
        <div className="w-full md:w-1/2 pl-3" />

        <StyleTitle className="w-full">◯排泄</StyleTitle>
        <div className="w-full md:w-1/2">
          <div className="grid grid-cols-2 md:grid-cols-1">
            {/* Toilet Assistant */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="トイレ介助"
              content="対応"
              value={checkValue.toilet}
              changeValue={onChangeCheckValue}
              name="toilet"
            />

            {/* Diaper check/change */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="オムツ確認・交換"
              content="対応"
              value={checkValue.diaper}
              changeValue={onChangeCheckValue}
              name="diaper"
            />

            {/* Pad confirmation/replacement */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="パット確認・交換"
              content="対応"
              value={checkValue.pad}
              changeValue={onChangeCheckValue}
              name="pad"
            />
          </div>

          {/* Urination */}
          <FormSelect
            col={4}
            disable={disable}
            title="排尿"
            value={defaultValue.urinate}
            name="urinate"
            changeValue={onChangeValue}
            data={dataNum}
            className="w-[193px]"
            unit={<TextP>回</TextP>}
          />

          {/* Defecation */}
          <FormSelect
            col={4}
            disable={disable}
            title="排便"
            value={defaultValue.defecation}
            name="defecation"
            changeValue={onChangeValue}
            data={dataNum}
            className="w-[193px]"
            unit={<TextP>回</TextP>}
          />

          {/* Urinal cleaning */}
          <CheckboxForm
            col={4}
            disable={disable}
            title="尿器洗浄"
            content="対応"
            value={checkValue.urinal}
            changeValue={onChangeCheckValue}
            name="urinal"
          />
        </div>

        <div className="w-full md:w-1/2 pl-3"></div>

        <div className="w-full md:w-1/2 pl-3" />
        <StyleTitle className="w-full">◯食事</StyleTitle>
        <div className="w-full md:w-1/2">
          {/* Maintain posture */}
          <CheckboxForm
            col={4}
            disable={disable}
            title="姿勢の確保"
            content="対応"
            value={checkValue.maintain}
            changeValue={onChangeCheckValue}
            name="maintain"
          />

          {/* Hydration */}
          <FormSelect
            col={4}
            disable={disable}
            title="水分補給"
            value={defaultValue.hydration}
            name="hydration"
            changeValue={onChangeValue}
            data={dataNumCC}
            className="w-[193px]"
            unit={<TextP>cc</TextP>}
          />

          {/* Eating assistancel */}
          <FormSelect
            col={4}
            disable={disable}
            title="摂食介助"
            value={defaultValue.eating}
            name="eating"
            changeValue={onChangeValue}
            data={eatingData}
            holder="選択してください"
          />
        </div>
        <StyleTitle className="w-full">◯入浴 身体整容</StyleTitle>
        <div className="w-full md:w-1/2">
          {/* cleaning */}
          <FormSelect
            col={4}
            disable={disable}
            title="清拭"
            value={defaultValue.cleaning}
            name="cleaning"
            changeValue={onChangeValue}
            data={eatingData}
            holder="選択してください"
          />

          {/* Full body bath */}
          <FormSelect
            col={4}
            disable={disable}
            title="全身浴"
            value={defaultValue.bodyBath}
            name="bodyBath"
            changeValue={onChangeValue}
            data={bodyBathData}
            holder="選択してください"
          />

          <div className="grid grid-cols-2 md:grid-cols-1">
            {/* Washing hari */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="洗髪"
              content="対応"
              value={checkValue.hair}
              changeValue={onChangeCheckValue}
              name="hair"
            />
            {/* Bdoy wash */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="洗身"
              content="対応"
              value={checkValue.bodyWash}
              changeValue={onChangeCheckValue}
              name="bodyWash"
            />

            {/* washbasin */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="洗面"
              content="対応"
              value={checkValue.wash}
              changeValue={onChangeCheckValue}
              name="wash"
            />

            {/* Oral care */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="口腔ケア"
              content="対応"
              value={checkValue.oral}
              changeValue={onChangeCheckValue}
              name="oral"
            />

            {/* Dressing assistance */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="更衣介助"
              content="対応"
              value={checkValue.dressing}
              changeValue={onChangeCheckValue}
              name="dressing"
            />
          </div>
        </div>
        <Text
          style={{
            fontSize: "16px",
            textAlign: "left",
            margin: "10px",
            padding: "10px 0px",
            fontWeight: "bold",
            color: "#000",
          }}
          className="w-full"
        >
          ■移動
        </Text>
        <div className="w-full md:w-1/2">
          <div className="grid grid-cols-2 md:grid-cols-1">
            {/* position exchange */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="体位交換"
              content="対応"
              value={checkValue.position}
              changeValue={onChangeCheckValue}
              name="position"
            />

            {/* Mobility/transfer assistance */}
            <CheckboxForm
              col={4}
              disable={disable}
              title="移動・移乗介助"
              content="対応"
              value={checkValue.mobility}
              changeValue={onChangeCheckValue}
              name="mobility"
            />
          </div>
        </div>
        <StyleTitle className="w-full">◯その他</StyleTitle>
        <div className="w-full md:w-3/5">
          {/*watch over */}
          <CheckboxForm
            col={4}
            disable={disable}
            title="見守り"
            content="対応"
            value={checkValue.watch}
            changeValue={onChangeCheckValue}
            name="watch"
          />

          {/* <StyleGrid className="grid-cols-1 gap-y-2 md:grid-cols-4"templateColumns="repeat(4, 1fr)" gap={1}>
            <StyleGridItem colSpan={1} style={{ paddingRight: "0px" }}>
              <TextP style={{ whiteSpace: "nowrap" }}>特記事項・申し送り</TextP>
            </StyleGridItem>
            <StyleGridItem colSpan={3}>
              <Textarea
                fontSize="16px"
                disabled={disable}
                value={defaultValue.report}
                onChange={onChangeValue}
                name="report"
                size="md"
                border="1px"
                borderColor="#000"
                borderRadius="0"
                rows={5}
              />
            </StyleGridItem>
          </StyleGrid> */}
        </div>
      </FlexRow>

      {!disable && (
        <FlexRow
          style={{ justifyContent: "flex-end" }}
          className="fixed bottom-0 w-full right-0 bg-white my-[46px] lg:my-0 lg:pr-2"
        >
          <ButtonSolid
            onClick={handleBack}
            m={3}
            height="32px"
            className="flex-1 !bg-grey-secondary md:flex-none md:!min-w-[155px]"
          >
            キャンセル
          </ButtonSolid>
          <ButtonSolid
            onClick={() => form.submit()}
            height="32px"
            m={3}
            className="flex-1 md:flex-none md:!min-w-[155px]"
          >
            保存
          </ButtonSolid>
        </FlexRow>
      )}
    </div>
  );
};

const TextP = styled("p")({
  fontSize: "16px",
  color: "#555555",
});

export default DailyForm;

const StyleTimePicker = styled(TimePicker)({
  borderRadius: "0",
  fontWeight: "600",
  color: "var(--primary-text-dark)",
  border: "1px solid black",
});

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-explain-error {
    margin-left: 27%;
  }
`;
