import { FlexRow, StyleTitle } from "component/StyledComponent";
import { ButtonSolid } from "component/button";
import { useEffect, useState } from "react";
import Sheet2MealForm from "./Sheet2MealForm";
import Sheet2EachPart from "./Sheet2EachPart";
import Sheet2Move from "./Sheet2Move";
import Sheet2Bath from "./Sheet2Bath";
import Sheet2ExcreteForm from "./Sheet2ExcreteForm";
import Sheet2MentalState from "./Sheet2MentalState";
import { Form } from "antd";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { convertArrayToObject } from "page/Customer/CustomerDetail/AssesmentSheet2/function";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";

const AssessmentSheet2 = () => {
  const [form] = Form.useForm();
  const service = useService();
  const { id } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  //--- State
  const [disable, setDisable] = useState(false);

  const onFinishFailed = (errorInfo: any) => {};

  const handleSubmit = async (formData: any) => {
    console.log("formData", formData);
  };

  const fetchInitialData = async () => {
    try {
      form.resetFields();
      const { data } = await axiosClient({
        method: "get",
        url: service.EVALUE_TWOS,
        params: { patient_id: id },
      });
      form.setFieldsValue(convertArrayToObject(data, true));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <>
      <FlexRow className="justify-end">
        {user?.role === "staff" && (
          <ButtonSolid
            onClick={() => navigate(-1)}
            width="137px"
            height="35px"
            fontSize="16px"
            m={3}
          >
            一覧へ
          </ButtonSolid>
        )}{" "}
      </FlexRow>
      <StyleTitle className="w-full md:w-1/2">■身体機能・状態</StyleTitle>
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <FlexRow>
          <div className="w-full md:w-1/2 px-2">
            <Sheet2MealForm disable={disable} />
            <Sheet2EachPart disable={disable} />
            <Sheet2Move disable={disable} />
          </div>
          <div className="w-full md:w-1/2 px-2">
            <Sheet2Bath disable={disable} />
            <Sheet2ExcreteForm disable={disable} />
            <Sheet2MentalState disable={disable} />
          </div>
        </FlexRow>
      </Form>
    </>
  );
};

export default AssessmentSheet2;
