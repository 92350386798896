import { STAFF_CONTACT } from "../apiConstant";
import BaseApi from "api/baseApi";

class StaffContactApi extends BaseApi {
  createRoom(body: { target_id: number; target_type: string }) {
    return this.post(STAFF_CONTACT, body);
  }
  getContactList(body: {
    per: number;
    page: number;
    unread: boolean;
    search: string;
  }) {
    return this.get(STAFF_CONTACT, body);
  }
  getContactDetail(body: { id: number; per: number; page: number }) {
    return this.get(
      `${STAFF_CONTACT}/${body.id}?per=${body.per}&page=${body.page}`
    );
  }
  createMessage(body: { id: number; content: string }) {
    return this.post(`${STAFF_CONTACT}/${body.id}/messages`, body);
  }
  readMessage(id: number) {
    return this.put(`${STAFF_CONTACT}/${id}`, {});
  }
}
export default StaffContactApi;
