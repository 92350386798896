import { memo } from "react";

interface IMessageDateProps {
  date: string;
}

const MessageDate = (props: IMessageDateProps) => {
  const { date } = props;

  return (
    <div className="flex items-center gap-x-[10px] justify-center mb-[10px]">
      <div className="h-[1px] w-[calc(50%-40px)] md:w-[140px] bg-[#e5e5e5]" />
      <div className="text-[12px] whitespace-nowrap">{date}</div>
      <div className="h-[1px] w-[calc(50%-40px)] md:w-[140px] bg-[#e5e5e5]" />
    </div>
  );
};

export default memo(MessageDate);
