import { DASHBOARD_HS_STAFF } from "api/apiConstant";
import BaseApi from "api/baseApi";

class HSDashboard extends BaseApi {
  getDashboard() {
    return this.get(DASHBOARD_HS_STAFF);
  }
}

export default HSDashboard;
