import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const StyledMenuLink = styled(Link)`
  &.active {
    color: var(--primary-color);
    border-left-color: var(--primary-color);
  }
`;

export default StyledMenuLink;
