import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TableRecordCustomer from "./_components/TableRecordCustomer";

const RecordHistoryCustomer = () => {
  const location = useLocation();
  const [date, setDate]: any = useState();
  const paramTab: any = queryString.parse(location.search);

  useEffect(() => {
    const queryObj: any = queryString.parse(location.search);
    if (location.search) {
      setDate(new Date(queryObj.date));
    } else {
      setDate(new Date());
    }
  }, [location.search]);

  return (
    <div className="page-header-bg md:px-5 after:h-[76px]">
      <div className="hidden md:block page-title px-6">
        {" "}
        介護記録・履歴{" "}
        {date ? (
          <span className="mx-5">
            {moment(new Date(date)).format("YYYY/MM/DD")}
          </span>
        ) : (
          <span className="mx-5">
            {moment(new Date()).format("YYYY/MM/DD")}
          </span>
        )}{" "}
        {paramTab?.name} 様
      </div>
      <div className="page-container text-left md:mt-10">
        <TableRecordCustomer dateparam={date && date} />
      </div>
    </div>
  );
};

export default RecordHistoryCustomer;
