import { Text } from '@chakra-ui/react'
import React from 'react'
import RadioSheet2Form from './RadioSheet2Form'

const Sheet2Bath = ({disable}: any) => {
  return (
    <div>
      <Text fontSize='14px'>◯入浴</Text>
      <RadioSheet2Form disable={disable} title="洗身" sign="※"valueAll={["independence", "partial_assistance", "full_assistance"]}
       rdTit={["自立", "一部介助", "全介助"]} name="wash_face_level" col={4}
        nameText="wash_face" />

      <RadioSheet2Form disable={disable} title="洗髪" sign="※"valueAll={["independence", "partial_assistance", "full_assistance"]}
       rdTit={["自立", "一部介助", "全介助"]} name="head_washing_level" col={4}
        nameText="head_washing" />

      <RadioSheet2Form disable={disable} title="着脱" sign="※"valueAll={["independence", "partial_assistance", "full_assistance"]}
       rdTit={["自立", "一部介助", "全介助"]} name="removable_level" col={4}
        nameText="removable" />

      <RadioSheet2Form disable={disable} title="整容" sign="※"valueAll={["independence", "partial_assistance", "full_assistance"]}
       rdTit={["自立", "一部介助", "全介助"]} name="grooming_level" col={4}
        nameText="grooming" />

    </div>
  )
}
export default Sheet2Bath
