
import { BOARD_STAFF } from '../apiConstant';
import BaseApi from 'api/baseApi';

class BoardStaffApi extends BaseApi {
    createPost(body: {
        id?: number,
        title?: string,
        content?: string,
        reply_to?: number,
        is_important?: boolean,
        mentions?: [],
        is_deleted?: boolean
    }) {
        return this.post(BOARD_STAFF, body);
    }
    getPostList(body: {
        per?: number,
        page?: number,
        search?: string
    }) {
        return this.get(BOARD_STAFF, body);
    }

    getPostDetail(postId: number) {
        return this.get(`${BOARD_STAFF}/${postId}`);
    }

    getByParent(reply_to: number) {
        return this.get(`${BOARD_STAFF}/${reply_to}/replies_by_parent`);
    }

    getServiceDetail(id: any) {
        return this.get(`${BOARD_STAFF}/${id}`);
    }

    updatePost(body: {
        id?: number,
        title?: string,
        content?: string,
        reply_to?: string,
        is_important?: boolean,
        mentions?: [],
        is_deleted?: boolean
    }) {
        return this.put(`${BOARD_STAFF}/${body?.id}`, body);
    }

}
export default BoardStaffApi;
