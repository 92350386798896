import { Form } from "antd";
import { useState } from "react";

export default function useTable<T>() {
  const [data, setData] = useState<T[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  return { data, setData, form, loading, setLoading, total, setTotal };
}
