
import { PATIENT_ADMIN } from '../apiConstant';
import BaseApi from 'api/baseApi';

class PatientAdminApi extends BaseApi {
    getList(body: {
        per?: number,
        page?: number,
        fullname?: string,
        status?: string,
        sortKey?: string,
        order?: string,
        care_plan_date?: string,
        year_month_payment?: string,
    }) {
        return this.get(PATIENT_ADMIN, body);
    }
    getDetail(id: any) {
        return this.get(`${PATIENT_ADMIN}/${id}`);
    }

}
export default PatientAdminApi;
