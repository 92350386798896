import { StarOutline } from "assets/icons";
import Logo from "assets/logo_without_text.png";
import { FlexRow } from "component/StyledComponent";
import ROUTES from "constant/routes";
import { useLocation } from "react-router-dom";
import MenuItem from "./menuItem";

export interface ISidebarProps {
  className?: string;
  type?: "admin" | "staff";
}

const menuItems = {
  staff: [
    {
      path: `/${ROUTES.HOME_SYSTEM_STAFF}`,
      name: "ダッシュボード",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: true,
    },
    // {
    //   path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_CUSTOMERS}`,
    //   name: "お客様一覧",
    //   icon: (
    //     <span>
    //       <StarOutline />
    //     </span>
    //   ),
    //   matchAll: false,
    // },
    {
      path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_SCHEDULES}`,
      name: "ホーム　予定",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: false,
    },
    {
      path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_EQUIPMENT_SERVICES}`,
      name: "備品利用一覧",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: true,
    },
    // {
    //   path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.HOME_NURSING_CARE}`,
    //   name: "介護内容登録・在庫",
    //   icon: (
    //     <span>
    //       <StarOutline />
    //     </span>
    //   ),
    //   matchAll: false,
    // },
    {
      path: `/${ROUTES.HOME_SYSTEM_STAFF}/${ROUTES.NOTICE}`,
      name: "お知らせ",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: false,
    },
  ],
  admin: [
    {
      path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_DASHBOARD}`,
      name: "ダッシュボード",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: true,
    },
    {
      path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_CUSTOMERS}`,
      name: "お客様一覧",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: false,
    },
    {
      path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_SCHEDULES}`,
      name: "ホーム　予定",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: false,
    },
    {
      path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_EQUIPMENT_SERVICES}`,
      name: "備品利用一覧",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: true,
    },
    {
      path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.HOME_NURSING_CARE}`,
      name: "介護内容登録・在庫",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: false,
    },
    {
      path: `/${ROUTES.HOME_SYSTEM}/${ROUTES.NOTICE}`,
      name: "お知らせ",
      icon: (
        <span>
          <StarOutline />
        </span>
      ),
      matchAll: false,
    },
  ],
};

export default function SidebarAdmin({
  className = "",
  type = "staff",
}: ISidebarProps) {
  const location = useLocation();

  return (
    <div className={`py-4 bg-[#FFFFFF] ${className}`}>
      <FlexRow className="mb-6 justify-center">
        <img src={Logo} alt="" />
      </FlexRow>
      {menuItems[type]?.map((menu, index) => (
        <MenuItem
          key={index}
          item={menu}
          isActive={
            menu.matchAll
              ? location.pathname === menu.path
              : location.pathname.includes(menu.path)
          }
        />
      ))}
    </div>
  );
}
