import styled from "@emotion/styled";

const Styles = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .ant-row {
    display: flex;
  }
  th,
  td {
    &.ant-table-cell {
      padding: 24px !important;
      &.col-date {
        padding: 0 !important;
        min-width: 20px;
      }
    }
  }
  table,
  th,
  td {
    padding: 20px;
    white-space: nowrap;
    border: 1px solid lightgray !important;
    border-collapse: collapse;
    .ant-form-item-explain-error {
      font-size: 12px;
    }
    .ant-form-item-explain-error {
      height: 0 !important;
    }
  }
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0px;
    border: 1px solid #000000;
  }
  .ant-select-selector {
    border-radius: 6px !important;
  }
  table,
  th,
  td {
    border-collapse: collapse;
    white-space: nowrap;
  }
  th,
  td {
    &.ant-table-cell {
      &.col-date {
        padding: 0 !important;
        min-width: 30px;
      }
    }
  }
`;

export {Styles}
