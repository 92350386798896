import {ReactNode} from 'react';
import { Radio, RadioProps } from 'antd';
import './style.scss';

interface IRadioProps extends RadioProps {
  children?: ReactNode
  className?: string
}
export default function RadioCustom({children, className = '', ...props}: IRadioProps) {
  return (
    <Radio className={className} {...props}>{children}</Radio>
  )
}
