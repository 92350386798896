import { Button, ButtonProps } from "@chakra-ui/button";

const ButtonOutline = (props: ButtonProps): JSX.Element => {
  const { className, isLoading, disabled, children, border, ...rest } =
    props;
  return (
    <Button
      variant="outline"
      className="rounded hover:text-white"
      border="2px"
      borderColor="brand.500"
      color="brand.500"
      colorScheme="brand"
      _active={{
        bg: "brand.100",
        transform: "scale(0.98)",
        borderColor: "brand.500",
      }}
      fontSize="14px"
      _hover={{
        color: "white",
        bg: "brand.100",
      }}
      borderRadius="4px"
      height={35}
      {...props}
      {...rest}
    >
      {" "}
      {children}{" "}
    </Button>
  );
};

export default ButtonOutline;
