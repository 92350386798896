import { useState, memo, useEffect } from "react";
import usePrint from "hook/usePrint";
import RecordHistoryCustomerPDFForm from "component/form/RecordHistoryCustomerPDFForm";
import { useQuery } from "react-query";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import { getCookie } from "utils";
import { RootState } from "store";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";

interface IPrintPdfNursingProps {
  id: number;
  setIsPrinting: React.Dispatch<React.SetStateAction<boolean>>;
  setIdNursePrint: React.Dispatch<React.SetStateAction<null | number>>;
  currentDate: Dayjs;
}
const PrintPdfNursingCareRecord = ({
  id,
  setIsPrinting,
  setIdNursePrint,
  currentDate,
}: IPrintPdfNursingProps) => {
  const user = useSelector((state: RootState) => state.user);
  const idToken = getCookie("access_token");
  const [dataPrint, setDataPrint]: any = useState(null);
  const [isReadyPrint, setIsReadyPrint] = useState(false)
  const { ref, handlePrint } = usePrint(true);
  const [filter] = useState({
    patient_id: "",
    start_date: dayjs(currentDate).startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs(currentDate).endOf("month").format("YYYY-MM-DD"),
    care_plan_type: "",
    tab: "staff",
    nurse_id: id,
    page: "",
    per: "",
    sortKey: "",
    order: "",
    is_pdf: true,
  });


  const { isLoading, isFetching } = useQuery(
    ["nursing_care_histories", id],
    () => 
      nursingCareRecordApi.getNursingCareHistory(idToken, filter, user?.role),
    {
      onSuccess: async (data) => {
        try {
          setDataPrint(data.serialized_nursing_care_histories);
        } catch (error) {
          setIsPrinting(false);
          setIdNursePrint(null);
          console.error(error);
        }
      },
      onError: async (error) => {
        setIsPrinting(false);
        setIdNursePrint(null);
        console.error(error);
      },
    }
  );

  useEffect(() => {
    if (dataPrint && !isLoading && !isFetching && isReadyPrint) {
      handlePrint();
      setIsPrinting(false);
      setDataPrint(null);
      setIdNursePrint(null);
    }
  }, [dataPrint, isLoading, isFetching, isReadyPrint]);

  return (
    <div>
      <RecordHistoryCustomerPDFForm
        ref={ref}
        dataPrint={dataPrint ? dataPrint : []}
        setIsReadyPrint={setIsReadyPrint}
      />
    </div>
  );
};

export default memo(PrintPdfNursingCareRecord);
