import ModalCustom from "component/admin/modal"
import { CloseOutlined } from "@ant-design/icons";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Form, DatePicker, Checkbox, TimePicker, Col, Row, Space } from "antd";
import { DATE_FORMAT_2, JP_DATEPICKER_FORMAT } from "constant/date";
import SelectWithData from "component/SelectWithData";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { useWatch } from "antd/es/form/Form";
import dayjs, { Dayjs } from "dayjs";
import { ButtonOutline, ButtonSolid } from "component/button";
import MESSAGES from "constant/messages";
import { convertToTimeString } from "page/Shift/Calendar";

const { RangePicker } = TimePicker;

interface ShiftTimeAdjustProps {
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
  selectedTimeItemState: [any, Dispatch<any>];
  setAllShiftDatas: Dispatch<SetStateAction<any[]>>;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}

interface FormValues {
  date: any;
  nurse: {
    value: number;
    label: string;
  };
  time: [Dayjs, Dayjs] | null;
  isDelete: boolean;
}

const ShiftTimeAdjust: React.FC<ShiftTimeAdjustProps> = ({
  openState,
  selectedTimeItemState,
  setAllShiftDatas,
  setIsEdited,
}) => {
  const [open, setOpen] = openState;
  const [selectedTimeItem, setSelectedTimeItem] = selectedTimeItemState;
  const handleClose = () => {
    setOpen(false);
    setSelectedTimeItem(undefined);
  }
  const [form] = Form.useForm();
  const service = useService();
  const dateValue = useWatch("date", form);
  const timeValue = useWatch("time", form);

  const getStaffOptions = async () => (
    await axiosClient.get(service.NURSING_STAFF, {
      params: { get_all: true, status: ["active"] },
    })
  ).data.data.map((i: any) => ({
    label: i.family_name,
    value: i.id,
  }));

  const getTimeRangeOptions = async () => (
    await axiosClient.get(service.SCHEDULES, {
      params: {
        patient_id: undefined,
        date: dayjs(dateValue).format("YYYY/MM/DD"),
      },
    })
  ).data.map((i: any) => ({
    label: i.time_range,
    value: i.time_range,
    data: i,
  }));

  const handleSubmit = async (submitData: FormValues) => {
    if (submitData.time) {
      const [startTime, endTime] = submitData.time;
      const startHour = startTime.hour();
      const startMinute = startTime.minute();
      const endHour = endTime.hour();
      const endMinute = endTime.minute();

      const stringStartTime = convertToTimeString(startHour, startMinute);
      const stringEndTime = convertToTimeString(endHour, endMinute);

      const isDifferent = (
        startHour !== selectedTimeItem.startHour || 
        startMinute !== selectedTimeItem.startMinute || 
        endHour !== selectedTimeItem.endHour || 
        endMinute !== selectedTimeItem.endMinute ||
        submitData.isDelete !== selectedTimeItem.delete
      );
      // submitData.date = submitData.date?.format(DATE_FORMAT_2)
      setAllShiftDatas((prevData) => {
        let newData = [...prevData];

        let targetStaff = newData.find((item) => item.id === selectedTimeItem.staff_id);
        targetStaff.shift_managements = targetStaff.shift_managements.map((item: any) => {
          if (item.id === selectedTimeItem.id) {
            item.startHour = startHour;
            item.startMinute = startMinute;
            item.endHour = endHour;
            item.endMinute = endMinute;
            item.start_time = stringStartTime;
            item.end_time = stringEndTime;
            item.delete = submitData.isDelete;
            item.edited = isDifferent ? true : false;
            const newItemHours: number[] = [];
            for (let i = startHour; i < endHour; i++) {
              newItemHours.push(i);
            }
            item.itemHours = newItemHours;
          }
          return item;
        });

        let newRegisteredHours: number[] = [];
        targetStaff.shift_managements.forEach((item: any) => {
          if (!item.delete) {
            newRegisteredHours = [...newRegisteredHours, ...item.itemHours];
          }
        })
        targetStaff.registeredHours = newRegisteredHours;
        
        if (isDifferent) {
          targetStaff.edited = true;
        }

        return newData.map((item) => {
          if (item.id === targetStaff.id) {
            return targetStaff;
          } else {
            return item;
          }
        });
      });
      if (isDifferent) {
        setIsEdited(true);
      }
      handleClose();
    }
  }

  useEffect(() => {
    if (!selectedTimeItem) {
      return;
    }

    form.setFieldValue("date", dayjs(selectedTimeItem?.shift_date));
    form.setFieldValue("nurse", {
      value: selectedTimeItem.staff_id,
      label: selectedTimeItem.staff_family_name,
    });
    form.setFieldValue("time", [
      dayjs(`${selectedTimeItem.shift_date} ${selectedTimeItem.start_time}`), 
      dayjs(`${selectedTimeItem.shift_date} ${selectedTimeItem.end_time}`),
    ]);
    form.setFieldValue("isDelete", selectedTimeItem.delete);
  }, [selectedTimeItem])

  return (
    <ModalCustom 
      className="shift-modal"
      title="シフト調整"
      closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      footer={null}
      open={open}
      onCancel={handleClose}
    >
      <Form 
        layout="vertical" 
        className="mt-[26px]" 
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item name="date" label="日にち" required>
          <DatePicker 
            format={JP_DATEPICKER_FORMAT}
            className="w-full"
            placeholder="選択してください"
            disabled
          />
        </Form.Item>
        <Form.Item
          name="time"
          label="時間"
          rules={[{ required: true, message: MESSAGES.requireField }]}
        >
          <RangePicker 
            placeholder={["00:00", "00:00"]}
            format={"HH:mm"}
          />
        </Form.Item>
        <Form.Item name="nurse" label="介護スタッフ" required>
          <SelectWithData 
            disabled
            getOptions={getStaffOptions}
            placeholder="選択してください"
          />
        </Form.Item>

        <Row justify="space-between">
          <Space>
            <Form.Item name="isDelete" valuePropName="checked">
              <Checkbox className="custom-checkbox" onChange={(e) => {
                form.setFieldValue("isDelete", e.target.checked);
              }}>削除</Checkbox>
            </Form.Item>
          </Space>
          <Space size={8}>
            <ButtonOutline
              className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[16px]" 
              type="button"
              onClick={handleClose}
            >
              キャンセル
            </ButtonOutline>
            <ButtonSolid 
              type="submit"
              className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[54px]"
            >
              保存
            </ButtonSolid>
          </Space>
        </Row>
      </Form>
    </ModalCustom>
  )
}

export default ShiftTimeAdjust;