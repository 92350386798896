import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { Calendar, CalendarProps } from "antd";
import next from "assets/svg/next-calendar.svg";
import prev from "assets/svg/prev-calendar.svg";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import "./dashboard-calendar.scss";
import { CalendarOptions } from "@fullcalendar/core";

dayjs.extend(utc);
dayjs.extend(timezone);

const MAX_ROW = 2;

type PropsTypes = {
  onTitleClick?: any;
  onEventClick?: any;
  events?: any;
  fullCalendarProps?: CalendarOptions;
  calendarProps?: CalendarProps<Dayjs>;
  handleOnDateChange?: (date: Dayjs) => void;
  initialDate?: Dayjs;
};

const CellStyle: { [key: string]: { bg: string; color: string } } = {
  error: {
    bg: "#EA7E2C",
    color: "white",
  },
  success: {
    bg: "#28C76F",
    color: "white",
  },
  info: {
    bg: "#735BF2",
    color: "white",
  },
  active: {
    bg: "#735BF2",
    color: "white",
  },
  filled: {
    bg: "#EA7E2C",
    color: "#ffffff",
  },
};

function DashboardCalendar(props: PropsTypes) {
  const {
    onTitleClick,
    onEventClick,
    events,
    fullCalendarProps,
    calendarProps,
    handleOnDateChange,
    initialDate,
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [date, setDate] = useState<Dayjs>(initialDate || dayjs());

  const dateCellRender = (value: any) => {
    const cellDate = dayjs(value);

    let dateSelect = events?.find(
      (event: any) =>
        dayjs(event.date).format("YYYY-MM-DD") === cellDate.format("YYYY-MM-DD")
    );

    if (dateSelect && dateSelect.className !== "default") {
      dateSelect.className = "filled";
    } else {
      dateSelect = {
        date: cellDate.format(),
        className:
          // check if is current date
          dayjs(new Date()).format("YYYY-MM-DD") ===
          cellDate.format("YYYY-MM-DD")
            ? "active"
            : "",
        extendedProps: dateSelect?.extendedProps || {},
      };
    }

    dateSelect.allSegs = events.filter(
      (event: any) =>
        dayjs(event.date).format("YYYY-MM-DD") === cellDate.format("YYYY-MM-DD")
    );

    return (
      <div
        onClick={() => {
          onTitleClick(null, dateSelect);
        }}
        style={
          dateSelect?.className
            ? {
                backgroundColor:
                  CellStyle[dateSelect?.className]?.bg || "#ffffff",
                color: CellStyle[dateSelect?.className]?.color || "#000000",
                borderRadius: "8px",
              }
            : {
                backgroundColor: "#ffffff",
                color: "#000000",
              }
        }
        className={`cell-date ${dateSelect?.className}`}
      >
        {cellDate.date()}
      </div>
    );
  };

  const onDateChange = (value: any) => {
    setDate(value);
    if (handleOnDateChange && dayjs(value).month() !== dayjs(date).month()) {
      handleOnDateChange(value);
    }
  };

  const prevMonth = () => {
    setDate(date.subtract(1, "month"));
    if (handleOnDateChange) {
      handleOnDateChange(date.subtract(1, "M"));
    }
  };

  const nextMonth = () => {
    setDate(date.add(1, "month"));
    if (handleOnDateChange) {
      handleOnDateChange(date.add(1, "M"));
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fullCalendarComponentProps: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: "dayGridMonth",
    events,
    eventClick: onTitleClick,
    moreLinkClick: onEventClick,
    moreLinkClassNames: "!text-[rgba(97, 97, 97, 0.76)]",
    headerToolbar: {
      start: "",
      center: "prev,title,next",
      end: "",
    },
    titleFormat: { year: "numeric", month: "2-digit" },
    moreLinkText: "他",
    views: {
      dayGridMonth: {
        dayMaxEventRows: MAX_ROW,
      },
    },
    datesSet: (args) => {
      if (handleOnDateChange) {
        handleOnDateChange(dayjs(args.view.currentStart));
      }
    },
    initialDate: dayjs(date).format(),
    dayMaxEvents: 2,
    ...(fullCalendarProps && fullCalendarProps),
  };

  const calendarComponentProps: CalendarProps<Dayjs> = {
    cellRender: dateCellRender,
    fullscreen: false,
    value: date,
    className: "custom-calendar",
    onSelect: onDateChange,
    ...(calendarProps && calendarProps),
  };

  return windowWidth <= 768 ? (
    <>
      <div className="flex justify-between py-1 px-2">
        <div className="flex items-center">
          <img onClick={prevMonth} src={prev} alt="" />
        </div>
        <div className="flex flex-col items-center">
          <div className="month">{date.format("M")}月</div>
          <div className="year">{date.format("YYYY")}</div>
        </div>
        <div className="flex items-center">
          <img onClick={nextMonth} src={next} alt="" />
        </div>
      </div>
      <Calendar {...calendarComponentProps} />
    </>
  ) : (
    <div
      className={`w-full rounded-[0px_6px_6px_0px] border border-solid border-[#E9EAEC] full-calendar pt-3.5 ${
        onTitleClick ? "custom-title" : ""
      } ${onEventClick ? "custom-more" : ""}`}
    >
      <FullCalendar {...fullCalendarComponentProps} />
    </div>
  );
}

export default DashboardCalendar;
