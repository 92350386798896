import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./style.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import noticeSlice from "store/slice/noticeSlice";
import { RootState } from "store";

const ContactChatView = ({ setPart }: any) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: userId } = useSelector((state: RootState) => state.user);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  //--- State
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [firstTime, setFirstTime] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [content, setContent] = useState("");
  const [messageList, setMessageList] = useState<any[]>([]);

  const fetchData = async () => {
    const data = await apiFactory.contactApi.getContactDetail({
      id: Number(id),
      per: limit,
      page: page,
    });
    if (data) {
      setMessageList(
        data?.messages.map((e: any) => ({
          ...e,
          date:
            e?.time_send_at.split(" ")[0] + " " + e?.time_send_at.split(" ")[2],
        }))
      );
      setTotalItems(data?.total_items);
      setFirstTime(true);
    }
  };

  const sendMessage = async () => {
    if (content.trim()) {
      const data = await apiFactory.contactApi.createMessage({
        id: Number(id),
        content: content,
      });
      if (data) {
        const data = await apiFactory.contactApi.getContactDetail({
          id: Number(id),
          per: limit,
          page: 1,
        });
        if (data) {
          setMessageList(
            data?.messages.map((e: any) => ({
              ...e,
              date:
                e?.time_send_at.split(" ")[0] +
                " " +
                e?.time_send_at.split(" ")[2],
            }))
          );
          setTotalItems(data?.total_items);
          setPage(1);
          setFirstTime(true);
          messagesEndRef?.current?.scrollIntoView({ block: "end" });
        }
        setContent("");
      } else {
        toast.error("Can not send message!");
      }
    }
  };

  let date = "";
  const onScroll = async (event: any) => {
    if (event.currentTarget.scrollTop === 0 && page * limit < totalItems) {
      messageList.unshift({
        loading: true,
      });
      setMessageList([...messageList]);
      const data = await apiFactory.contactApi.getContactDetail({
        id: Number(id),
        per: limit,
        page: page + 1,
      });
      if (data) {
        setTimeout(() => {
          messageList.shift();
          const concat = data?.messages
            .map((e: any) => ({
              ...e,
              date:
                e?.time_send_at.split(" ")[0] +
                " " +
                e?.time_send_at.split(" ")[2],
            }))
            .concat(messageList);
          setMessageList(concat);
          setTotalItems(data?.total_items);
          setPage(page + 1);
          setFirstTime(false);
        }, 500);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // when click view message => update message to read
    apiFactory.contactApi.readMessage(Number(id));
  }, []);

  useEffect(() => {
    if (firstTime) {
      messagesEndRef?.current?.scrollIntoView({ block: "end" });
      if (ref?.current?.scrollHeight) {
        setPrevScrollHeight(ref?.current?.scrollHeight);
      }
    }
  }, [firstTime]);

  useEffect(() => {
    if (!firstTime) {
      const newScrollHeight = ref?.current?.scrollHeight;
      if (newScrollHeight) {
        ref.current?.scrollTo(0, newScrollHeight - prevScrollHeight);
        setPrevScrollHeight(newScrollHeight);
      }
    }
  }, [page]);

  return (
    <>
      <div className="page-title">{location?.state?.title}</div>
      <div className="page-container">
        <div className="admin-contact-chat">
          <div className="text-end mb-[30px]">
            <ButtonOutline
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
              onClick={() => {
                navigate("/admin/notice");
                dispatch(noticeSlice?.actions.setTab(2));
              }}
            >
              一覧へ戻る
            </ButtonOutline>
          </div>
          <div className="message-list" ref={ref} onScroll={onScroll}>
            {messageList.map((e: any) => {
              if (e.loading)
                return (
                  <div className="text-center mb-[15px]">
                    <Spin />
                  </div>
                );
              if (e.date !== date) {
                date = e.date;
                if (e.sender_id !== userId) {
                  return (
                    <div>
                      <Row>
                        <Col span={10} />
                        <Col span={4}>
                          <div className="message-date">{e.date}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={10}>
                          <div className="message-left-content">
                            <div
                              className="me-content-cover"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              <div
                                className="guest-content"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {e?.content}
                              </div>
                            </div>
                            <div className="time-message">
                              <div>{e?.read_at ? "既読" : "未読"}</div>
                              <div>{e?.hour_at}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <Row>
                        <Col span={10} />
                        <Col span={4}>
                          <div className="message-date">{e.date}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={14} />
                        <Col span={10}>
                          <div className="message-right-content flex gap-4 justify-end">
                            <div className="time-message">
                              <div>{e?.read_at ? "既読" : "未読"}</div>
                              <div>{e.hour_at}</div>
                            </div>
                            <div className="me-content-cover">
                              <div
                                className="me-content"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {e.content}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              } else {
                if (e.sender_id !== userId) {
                  return (
                    <div>
                      <Row>
                        <Col span={10}>
                          <div className="message-left-content">
                            <div
                              className="me-content-cover"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              <div
                                className="guest-content"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {e?.content}
                              </div>
                            </div>
                            <div className="time-message">
                              <div>{e?.read_at ? "既読" : "未読"}</div>
                              <div>{e?.hour_at}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <Row>
                        <Col span={14} />
                        <Col span={10}>
                          <div className="message-right-content flex gap-4 justify-end">
                            <div className="time-message">
                              <div>{e?.read_at ? "既読" : "未読"}</div>
                              <div>{e.hour_at}</div>
                            </div>
                            <div
                              className="me-content-cover"
                              // style={{ whiteSpace: 'pre-line' }}
                            >
                              <div
                                className="me-content"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {e.content}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              }
            })}
            <div ref={messagesEndRef} />
          </div>
          <div className="bottom-input">
            <TextArea
              placeholder="コメント"
              className="w-[376px]"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <ButtonSolid
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
              disabled={content.trim() === ""}
              onClick={sendMessage}
            >
              送信
            </ButtonSolid>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactChatView;
