
import { FACILITY_REGISTRATION } from '../apiConstant';
import BaseApi from 'api/baseApi';

interface PositionAndStock {
  equipment_services: {
    category: string,
    records: {
      id: number,
      position?: number,
      stock_quantity?: number
    }[]
  }[]
}

class FacilityRegistrationApi extends BaseApi {
  create(body: {
    display_name: string,
    service_name: string,
    category: string,
    stock_quantity: string,
    unit_price: string,
    unit_price_setting: string
  }) {
    return this.post(FACILITY_REGISTRATION, body);
  }
  getList(body: {
    per?: number,
    page?: number
  }, type?: string) {
    return this.get(`${FACILITY_REGISTRATION}`, body);
  }
  getDetail(id: any) {
    return this.get(`${FACILITY_REGISTRATION}/${id}`);
  }
  update(body: {
    id: number,
    display_name: string,
    service_name: string,
    category: string,
    stock_quantity: string,
    unit_price: string,
    unit_price_setting: string
  }) {
    return this.put(`${FACILITY_REGISTRATION}/${body.id}`, body);
  }
  updatePositionAndStock(body: PositionAndStock) {
    return this.put(`${FACILITY_REGISTRATION}/update_positions_and_stock`, body);
  }
}
export default FacilityRegistrationApi;
export type { PositionAndStock };
