/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Col, Form, Input, Row, Select, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import apiFactory from "api";
import { ButtonOutline } from "component/button";
import Header from "component/header/Header";
import MESSAGES from "constant/messages";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const PostDetail = ({ difference }: any) => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const [replyTo, setReplyTo] = useState(null);
  const [form] = Form.useForm();
  const [patientList, setPatientList] = useState<any>([]);
  const [data, setData] = useState<{
    id: string;
    contributor: string;
    replyTo?: number | null;
    title: string;
    patients: [];
    important: boolean;
    content: string;
    delete: boolean;
    reply_to: string;
  }>({
    id: "",
    contributor: "",
    replyTo: null,
    title: "",
    patients: [],
    important: false,
    content: "",
    delete: false,
    reply_to: "",
  });
  const fetchPatientList = async () => {
    const data = await apiFactory.patientStaffApi.getList({});
    if (data) {
      setPatientList(
        data.patients.map((e: any) => ({
          label: e.family_name,
          value: e.id,
        }))
      );
    }
  };
  const fetchData = async () => {
    const result = await apiFactory.boardStaffApi.getPostDetail(Number(postId));
    if (result) {
      form.setFieldsValue({
        id: postId,
        contributor: Cookies.get("user_name"),
        replyTo: replyTo,
        title: result?.title,
        patients: result?.mention_notifications?.map((e: any) => ({
          label: e?.name,
          value: e?.m_id,
        })),
        important: result?.is_important,
        content: result?.content,
        delete: result?.is_deleted,
      });
      const preparePatient = result?.mention_notifications?.map(
        (e: any) => e?.m_id
      );

      setData({
        id: result?.id,
        contributor: result?.id,
        replyTo: replyTo,
        title: result?.title,
        patients: result?.mention_notifications?.map((e: any) => ({
          label: e?.name,
          value: e?.m_id,
        })),
        important: result?.is_important,
        content: result?.content,
        delete: result?.is_delelted,
        reply_to: result?.reply_to,
      });

      const patients = await apiFactory.patientStaffApi.getList({});
      if (patients) {
        setPatientList(
          patients.patients.map((e: any) => {
            if (preparePatient.includes(e?.id)) {
              return {
                label: e?.family_name,
                value: e?.id,
                disabled: true,
              };
            }
            return {
              label: e?.family_name,
              value: e?.id,
            };
          })
        );
      }
    }
  };

  const PatientSelection = useCallback(
    ({ value, onChange }: any) => {
      const removeItem = (e: any) => {
        const index = value.findIndex((f: any) => f.value === e);
        value.splice(index, 1);
        const clonePatientList = patientList.map((f: any) => {
          if (f.value === e) {
            f.disabled = false;
          }
          return f;
        });
        setPatientList(clonePatientList);
      };
      return (
        <>
          <Select
            options={patientList}
            onChange={(e: any) => {
              const patient = patientList.find((f: any) => f.value === e);
              value.push(patient);
              const clonePatientList = patientList.map((f: any) => {
                if (f.value === e) {
                  f.disabled = true;
                }
                return f;
              });
              setPatientList(clonePatientList);
              onChange([...value]);
            }}
            placeholder="選択してください"
          />
          <div className="patient-list">
            {value?.map((e: any) => {
              return (
                <Tag
                  color={"green"}
                  closable={true}
                  onClose={() => removeItem(e.value)}
                  style={{ marginRight: 3 }}
                >
                  {e.label}
                </Tag>
              );
            })}
          </div>
        </>
      );
    },
    [patientList, data]
  );

  const onFinish = async (values: any) => {
    const body = {
      id: values.id,
      title: values?.title.trim(),
      mentions: values?.patients?.map((e: any) => e.value),
      is_important: values?.important,
      content: values?.content,
      is_deleted: values?.delete,
    };
    try {
      if (difference.type === "add") {
        const data = await apiFactory.boardStaffApi.createPost(body);
        if (data) {
          toast.success(data.success);
          navigate("/staff/bulletin-board");
        }
      }
      if (difference.type === "edit") {
        body.id = postId;
        const data = await apiFactory.boardStaffApi.updatePost(body);
        if (data) {
          toast.success("更新に成功しました");
          navigate(`/staff/bulletin-board`);
        }
      }
    } catch (error: any) {
      if (typeof error?.message === "string") {
        toast.error(error?.message);
        return;
      }
      let stringError = "";
      Object.entries(error?.message?.errors).map((e: any) => {
        stringError += `${e[0]}: ${e[1]} `;
      });
      toast.error(stringError);
    }
  };
  useEffect(() => {
    form.setFieldValue("contributor", Cookies.get("user_name"));

    if (difference.type === "add") {
      fetchPatientList();
    }
    if (difference.type === "edit") {
      fetchData();
    }
  }, [replyTo]);

  return (
    <div className="page-container text-left">
      <Header title={`掲示板　親スレッド投稿`} />
      <div className="p-[30px]">
        <div className="pt-[7px] post-detail">
          <Form onFinish={onFinish} form={form} initialValues={data}>
            <Row>
              <Col span={11}>
                <Form.Item label="投稿者" name="contributor">
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label="タイトル"
                  name="title"
                  rules={[{ required: true, message: MESSAGES.requireField }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="該当お客様" name="patients">
                  <PatientSelection />
                </Form.Item>
                {!data?.reply_to && (
                  <Form.Item
                    label="重要"
                    name="important"
                    valuePropName="checked"
                  >
                    <Checkbox className="custom-checkbox">重要</Checkbox>
                  </Form.Item>
                )}
                <Form.Item
                  label="内容"
                  name="content"
                  className="w-[200%]"
                  rules={[{ required: true, message: MESSAGES.requireField }]}
                >
                  <TextArea />
                </Form.Item>
                {postId && (
                  <Form.Item
                    label="投稿を削除"
                    name="delete"
                    valuePropName="checked"
                  >
                    <Checkbox className="custom-checkbox">削除</Checkbox>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={13} />
              <Col span={11}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
                  <div className="flex gap-x-[8px]">
                    <ButtonOutline
                      className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                      onClick={() => {
                        if (difference.type === "add") {
                          navigate("/staff/bulletin-board");
                          return;
                        }
                        if (difference.type === "edit") {
                          navigate(`/staff/bulletin-board-content/${postId}`);
                          return;
                        }
                      }}
                    >
                      キャンセル
                    </ButtonOutline>
                    <Button className="save-button" htmlType="submit">
                      保存
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
export { PostDetail };
