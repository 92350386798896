import { Tabs, TabsProps } from "antd";
import CareRecord from "page/Customer/CustomerDetail/CareRecord";
import { useLocation, useParams } from "react-router-dom";
import BasicInfo from "../BasicInfo";
import AssessmentSheet1 from "../AssessmentSheet";
import CarePlan from "../CarePlan";
import AssessmentSheet2 from "../AssesmentSheet2";
import queryString from "query-string";
import { cn } from "utils/classnames";
import { useState } from "react";

export default function CreateRecord() {
  const { id } = useParams();

  const location = useLocation();

  const queryObj: any = queryString.parse(location.search);
  const [activeKey, setActiveKey] = useState(
    "1" as TabsProps["defaultActiveKey"]
  );

  // const tabItems: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: `介護記録`,
  //     disabled: !id,
  //     children: <CareRecord />,
  //   },
  //   {
  //     key: "2",
  //     label: `基本情報`,
  //     children: <BasicInfo />,
  //   },
  //   {
  //     key: "3",
  //     label: `アセスメントシート1`,
  //     disabled: !id,
  //     children: <AssessmentSheet1 />,
  //   },
  //   {
  //     key: "4",
  //     label: `アセスメントシート2`,
  //     disabled: !id,
  //     children: <AssessmentSheet2 />,
  //   },
  //   {
  //     key: "5",
  //     label: `ケアマネプラン(介護)`,
  //     disabled: !id,
  //     children: <CarePlan isNormal={true} />,
  //   },
  //   {
  //     key: "6",
  //     label: `ケアマネプラン(障害)`,
  //     disabled: !id,
  //     children: <CarePlan isNormal={false} />,
  //   },
  //   {
  //     key: "7",
  //     label: `請求方法`,
  //     disabled: !id,
  //     children: <Payment />,
  //   },
  // ];

  return (
    <div>
      <div className="page-title bg-[#EEEEEE] w-full opacity-100 fixed z-10  top-[30px] h-20 py-8">{queryObj?.patient} 様 介護記録・履歴</div>
      <div className={`${cn("page-container", activeKey === "1" && "mb-16")} mt-20`}>
        <CareRecord />
        {/* <Tabs
          items={tabItems}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
        /> */}
      </div>
    </div>
  );
}
