import { Tabs, TabsProps } from "antd";
import BasicInfo from "./BasicInfo";
import CarePlan from "./CarePlan";
import Expense from "./Expense";
import Payment from "./Payment";
import AssessmentSheet1 from "./AssessmentSheet";
import InsuranceCard from "./InsuranceCard";
import { useParams } from "react-router-dom";
import AssessmentSheet2 from "./AssesmentSheet2";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import React from "react";

export default function CustomerDetail() {
  const { id } = useParams();
  const service = useService();
  const [patientName, setPatientName] = React.useState("");
  const getPatientName = async () => {
    try {
      const { data } = await axiosClient({
        method: "get",
        url: `${service.PATIENTS}/${id}`,
      });
      setPatientName(data.family_name);
    } catch (error) {
      console.error(error);
    }
  };

  const [title, setTitle] = React.useState("基本情報")

  const onChangeTabIndex = (evt: any) => {
    switch (Number(evt)) {
      case 2:
        setTitle("基本情報")
        break
      case 3:
        setTitle("アセスメントシート1")
        break
      case 4:
        setTitle("アセスメントシート2")
        break
      case 5:
        setTitle("ケアマネプラン(介護)")
        break
      case 6:
        setTitle("ケアマネプラン(障害)")
        break
      case 7:
        setTitle("請求方法")
        break
      case 8:
        setTitle("被保険者証情報")
        break
      case 9:
        setTitle("公費情報")
        break
    }
  }

  const tabItems: TabsProps["items"] = [
    {
      key: "2",
      label: `基本情報`,
      children: <BasicInfo />,
    },
    {
      key: "3",
      label: `アセスメントシート1`,
      disabled: !id,
      children: <AssessmentSheet1 />,
    },
    {
      key: "4",
      label: `アセスメントシート2`,
      disabled: !id,
      children: <AssessmentSheet2 />,
    },
    {
      key: "5",
      label: `ケアマネプラン(介護)`,
      disabled: !id,
      children: <CarePlan isNormal={true} />,
    },
    {
      key: "6",
      label: `ケアマネプラン(障害)`,
      disabled: !id,
      children: <CarePlan isNormal={false} />,
    },
    {
      key: "7",
      label: `請求方法`,
      disabled: !id,
      children: <Payment />,
    },
    {
      key: "8",
      label: `被保険者証情報`,
      disabled: !id,
      children: <InsuranceCard />,
    },
    {
      key: "9",
      label: `公費情報`,
      disabled: !id,
      children: <Expense />,
    },
  ];
  React.useEffect(() => {
    if (id) getPatientName();
  }, []);
  return (
    <div>
      <div className="page-title">
        {id ? patientName + " 様" : "お客様登録"} <span className="ml-3" >{title}</span>
      </div>
      <div className="page-container">
        <Tabs items={tabItems} defaultActiveKey="2" onChange={onChangeTabIndex} />
      </div>
    </div>
  );
}
