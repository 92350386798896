import React from "react";

const BellRingOutline = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10 21H14C14 21.5304 13.7893 22.0391 13.4142 22.4142C13.0392 22.7893 12.5305 23 12 23C11.4696 23 10.9609 22.7893 10.5858 22.4142C10.2107 22.0391 10 21.5304 10 21ZM21 19V20H3.00002V19L5.00002 17V11C5.00002 7.9 7.03002 5.17 10 4.29V4C10 3.46957 10.2107 2.96086 10.5858 2.58579C10.9609 2.21071 11.4696 2 12 2C12.5305 2 13.0392 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4V4.29C16.97 5.17 19 7.9 19 11V17L21 19ZM17 11C17 9.67392 16.4732 8.40215 15.5356 7.46447C14.5979 6.52678 13.3261 6 12 6C10.6739 6 9.40217 6.52678 8.46449 7.46447C7.5268 8.40215 7.00002 9.67392 7.00002 11V18H17V11ZM19.75 3.19L18.33 4.61C19.1758 5.44542 19.8473 6.44042 20.3056 7.53732C20.764 8.63422 21 9.8112 21 11H23C23 8.07 21.84 5.25 19.75 3.19ZM1.00002 11H3.00002C3.00002 8.6 3.96002 6.3 5.67002 4.61L4.25002 3.19C3.2179 4.21157 2.39909 5.42807 1.84116 6.76881C1.28323 8.10955 0.997315 9.54781 1.00002 11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BellRingOutline;
