import dayjs from "dayjs";
import Styles from "./styled";
import { DATE_FORMAT, formatJapanDate } from "constant/date";

export default function UsagePDF({ data }: any) {
  if (data)
    return (
      <Styles className="w-[848px] h-[1121px] p-[50px_40px]">
        <div className="text-center text-[1.5rem] font-bold">
          備品・サービス・ポトロ利用一覧
        </div>
        <div className="text-center text-[1.5rem] font-bold">
          {dayjs(data.year_month).format("YYYY年M月分")} {data.patient_name} 様
        </div>
        <div hidden={!data.equipments?.length}>
          <div className="text-[1rem] mt-10 mb-3 font-bold">
            <span className="text-[1.5rem]">■</span>備品利用
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th>日付</th>
                <th>項目名</th>
                <th>単価</th>
                <th>数量</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-[5px]" />
              {Array(...data.equipments).map((i, index) => (
                <tr key={index}>
                  <td width="15%">{dayjs(i.date).format(DATE_FORMAT)}</td>
                  <td width="35%">{i.service_name}</td>
                  <td width="15%" align="right">
                    {(+i.unit_price).toLocaleString()}
                  </td>
                  <td width="15%" align="right">
                    {(+i.quantity).toLocaleString()}
                  </td>
                  <td width="15%" align="right">
                    {(+i.sub_total).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td align="center">小計</td>
                <td></td>
                <td></td>
                <td align="right">
                  {(+data.equipment_total).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div hidden={!data.services_usage?.length}>
          <div className="text-[1rem] mt-10 mb-3 font-bold">
            <span className="text-[1.5rem]">■</span>サービス利用
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th>日付</th>
                <th>項目名</th>
                <th>単価</th>
                <th>数量</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-[5px]" />
              {Array(...data.services_usage).map((i, index) => (
                <tr key={index}>
                  <td width="15%">{dayjs(i.date).format(DATE_FORMAT)}</td>
                  <td width="35%">{i.service_name}</td>
                  <td width="15%" align="right">
                    {(+i.unit_price).toLocaleString()}
                  </td>
                  <td width="15%" align="right">
                    {(+i.quantity).toLocaleString()}
                  </td>
                  <td width="15%" align="right">
                    {(+i.sub_total).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td align="center">小計</td>
                <td></td>
                <td></td>
                <td align="right">{(+data.service_total).toLocaleString()}</td>
              </tr>
              <tr>
                <td colSpan={4}></td>
                <td align="right">
                  {(+data.service_total * 2).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div hidden={!data.potoroes?.length}>
          <div className="text-[1rem] mt-10 mb-3 font-bold">
            <span className="text-[1.5rem]">■</span>ぽとろ
          </div>
          <table className="w-full">
            <thead>
              <tr>
                <th>日付</th>
                <th>項目名</th>
                <th>単価</th>
                <th>数量</th>
                <th>金額</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-[5px]" />
              {Array(...data.potoroes).map((i, index) => (
                <tr key={index}>
                  <td width="15%">{dayjs(i.date).format(DATE_FORMAT)}</td>
                  <td width="35%">{i.service_name}</td>
                  <td width="15%" align="right">
                    {(+i.unit_price).toLocaleString()}
                  </td>
                  <td width="15%" align="right">
                    {(+i.quantity).toLocaleString()}
                  </td>
                  <td width="15%" align="right">
                    {(+i.sub_total).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td align="center">小計</td>
                <td></td>
                <td></td>
                <td align="right">{(+data.potoro_total).toLocaleString()}</td>
              </tr>
              <tr>
                <td colSpan={4}></td>
                <td align="right">
                  {(+data.potoro_total * 2).toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Styles>
    );
  return <></>;
}
