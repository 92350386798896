import dayjs from "dayjs";
import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { Col, Row } from "antd";
import { 
  TabPanel, 
  Box, 
  Text, 
  TableContainer, 
  Tr, 
  Table,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal
} from "@chakra-ui/react";
import { StyleTd } from "component/StyledComponent";
import "devextreme/dist/css/dx.light.css";
import "./style.scss";
import ScheduleRegister from "../components/Modal/ScheduleRegister";
import apiFactory from "api";
import moment from "moment";
import { DATE_FORMAT_2 } from "constant/date";
import ScheduleDetail from "../components/Modal/ScheduleDetail";
import { ButtonOutline, ButtonSolid } from "component/button";
import { 
  DndContext, 
  DragEndEvent, 
  DragOverlay, 
  DragStartEvent, 
  useDraggable, 
  useDroppable,
  useSensor,
  useSensors,
  MouseSensor,
  PointerSensor
} from "@dnd-kit/core";
import { toast } from "react-toastify";
import Prompt from "../components/Week/promt";

interface Props {
  type: string;
  currentDate: any;
  editMode: boolean;
  setShowRegisterButtons: Dispatch<SetStateAction<boolean>>;
  isClientScheduleEditedState: [boolean, Dispatch<SetStateAction<boolean>>];
  createShift: any;
  setCreateShift: any;
  setIsShiftEdited: Dispatch<SetStateAction<boolean>>;
}

const dataHour = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const convertToTimeString = (hour: string | number = 0, minute: string | number = 0): string => {
  if (!hour && !minute) {
    return "00:00";
  }

  const numHour = Number(hour);
  const numMinute = Number(minute);

  if (numHour < 0 || numHour > 24 || numMinute < 0 || numMinute > 59) {
    return "Invalid";
  }

  const stringHour = numHour < 10 ? `0${numHour}` : `${numHour}`;
  const stringMinute = numMinute < 10 ? `0${numMinute}` : `${numMinute}`;

  return `${stringHour}:${stringMinute}`;
}

export const calculateTime = (inputTime: number): string => {
  if (!inputTime) {
    return "00:00";
  }

  if ((inputTime * 10) % 10 !== 0) {
    const hour = Math.floor(inputTime);
    const remainMinutes = inputTime - hour;
    const minute = remainMinutes * 60;
    return convertToTimeString(hour, minute);
  } else {
    return convertToTimeString(inputTime);
  }
}

const convertStringTimeToNum = (stringTime: string): number[] => {
  const stringTimeArr = stringTime.split(":");
  const numTimeArr = stringTimeArr.map((item: string) => Number(item));
  return [...numTimeArr];
}

const getTimePeriod = (startTime: string, endTime: string): number => {
  const startHour = convertStringTimeToNum(startTime)[0];
  const endHour = convertStringTimeToNum(endTime)[0];
  const endMinute = convertStringTimeToNum(endTime)[1];
  let period;

  if (endMinute > 0) {
    period = (endHour - startHour) + (endMinute / 60);
  } else {
    period = endHour - startHour;
  }

  return period;
}

const getScheduleBoxWidth = (startTime: string, endTime: string, unit: string = '%'): string => {
  if (!startTime || !endTime) {
    return "100%";
  }

  const period = getTimePeriod(startTime, endTime);
  const width = period * 100;

  let widthValue: string;

  if (unit) {
    widthValue = "" + width + unit;
  } else {
    widthValue = String(width);
  }

  return widthValue;
}

export const removePercentSign = (percentString: string): string => {
  if (!percentString) {
    return "";
  }
  return percentString.slice(0, -1);
}

interface ScheduleCellProps {
  children: JSX.Element;
  hour: string;
  index: number;
  editMode: boolean;
  isLastRow: boolean;
}

const ScheduleCell: React.FC<ScheduleCellProps> = (props) => {
  const { isOver, setNodeRef: setDroppableNodeRef } = useDroppable({
    id: `${props.index}_${props.hour}_droppableCell`,
    disabled: !props.editMode,
    data: { hour: props.hour, row: props.index }
  });
  if (props.isLastRow) {
    return (
      <StyleTd
        ref={setDroppableNodeRef}
        padding="0px"
        position="relative"
        style={{
          border: "1px solid #e6e6e6",
          borderBottom: "2px solid #e6e6e6",
          height: "88px",
          width: "70px",
          backgroundColor: isOver ? "#e6e6e6" : ""
        }}
      >
        {props.children}
      </StyleTd>
    )
  }
  return (
    <StyleTd
      ref={setDroppableNodeRef}
      padding="0px"
      position="relative"
      style={{
        border: "1px solid #e6e6e6",
        height: "88px",
        width: "70px",
        backgroundColor: isOver ? "#e6e6e6" : ""
      }}
    >
      {props.children}
    </StyleTd>
  )
}

interface ScheduleItemBoxProps {
  hour: string;
  clientName: string;
  shiftServiceName: string;
  editMode: boolean;
  shiftItem: any;
  boxWidth: string;
  setOpenDetail: Dispatch<SetStateAction<boolean>>;
  dragId: string;
  setSelectedShiftItem: Dispatch<any>;
}

const ScheduleItemBox: React.FC<ScheduleItemBoxProps> = (props) => {
  const { attributes, listeners, setNodeRef: setDraggableNodeRef, isDragging } = useDraggable({
    id: props.dragId,
    disabled: !props.editMode,
    data: props
  });
  return (
    <Box
      ref={setDraggableNodeRef}
      width={`${props.boxWidth}`}
      height="100%"
      bg={props.shiftItem.nurse_name ? "#1AD633" : "white"}
      border="2px solid var(--primary-color)"
      display='flex'
      position="absolute"
      top={0}
      left={0}
      zIndex={100}
      onClick={() => {
        if (props.editMode) {
          props.setOpenDetail(true);
          props.setSelectedShiftItem(props.shiftItem);
        }
      }}
      {...listeners}
      {...attributes}
      visibility={isDragging ? "hidden" : "inherit"}
    >
      {
        props.editMode ? (
          <Text
            // paddingX="8px"
            lineHeight="13px"
            textAlign="center"
            fontSize="12px"
            whiteSpace="pre-wrap"
            margin='auto'
            width="100%"
            cursor="pointer"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {" "}
            {props.shiftItem.nurse_name ? props.shiftItem.nurse_name : ""}
          </Text>
        ) : (
          <Popover trigger="hover">
            <PopoverTrigger>
              <Text
                // paddingX="8px"
                lineHeight="13px"
                textAlign="center"
                fontSize="12px"
                whiteSpace="pre-wrap"
                margin='auto'
                width="100%"
                cursor="pointer"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {" "}
                {props.shiftItem.nurse_name ? props.shiftItem.nurse_name : ""}
              </Text>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <div>{props.shiftItem.clientName || ""}</div>
                  <div>{props.shiftItem.time_range || ""}</div>
                  <div>{props.shiftItem.shiftName || ""}</div>
                  <div>担当 : {props.shiftItem.nurse_name || ""}</div>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )
      }
    </Box>
  )
}

export default function Schedule({ type, currentDate, editMode, setShowRegisterButtons, isClientScheduleEditedState, createShift, setCreateShift, setIsShiftEdited }: Props) {
  const [popupState, setPopupState] = React.useState({});
  const [showScheduleAdjust, setShowScheduleAdjust] = useState<boolean>(false);
  const [dateView, setDateView] = useState(new Date())
  const [dataStaff, setDataStaff]: any = useState([]);
  const [allShiftItems, setAllShiftItems] = useState<any[]>([]);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [draggedData, setDraggedData] = useState<ScheduleItemBoxProps | undefined>();
  const [selectedShiftItem, setSelectedShiftItem] = useState<any>();
  const [isClientScheduleEdited, setIsClientScheduleEdited] = isClientScheduleEditedState;
  
  const onGetDate = (evt: any) => {
    setDateView(evt)
  }

  const fetchDataDayShift = async () => {
    try {
      const res = await apiFactory.shiftManagementApi.getDaySchedule({
        specific_date: moment(currentDate).format(DATE_FORMAT_2),
        tab: type
      })
      if (res) {
        const mappingData = Object.values(res.data);
        let shiftDatas = mappingData.map((item: any, clientIdx: number) => {
          const clientName = item.family_name;
          const shifts = item.shifts;
          for (const key in shifts) {
            let shiftItemClientData = shifts[key];
            shifts[key] = shiftItemClientData.map((dataItem: any) => (
              {
                ...dataItem, 
                shiftName: key, 
                clientName, 
                dragId: `${clientName}_${key}_${dataItem.schedule.id}_draggableBox`,
                clientIdx,
                schedule_date: {
                  ...dataItem.schedule_date,
                  timePeriod: getTimePeriod(dataItem.schedule_date.start_time, dataItem.schedule_date.end_time)
                },
                edited: false,
                delete: false,
              }
            ));
          }
          return Object.values(shifts);
        }).flat().map((flatted: any, flattedIdx) => {
          return flatted.map((subFlatted: any) => ({...subFlatted, originalRow: flattedIdx}));
        });
        setAllShiftItems(shiftDatas);
        setDataStaff(mappingData)
      }
    } catch (error) {

    }
  }

  const handleDragStart = (e: DragStartEvent) => {
    const eventDragBoxData = e.active.data.current as ScheduleItemBoxProps;
    const draggedBoxData = {
      ...eventDragBoxData,
      boxWidth: `${(Number(removePercentSign(eventDragBoxData.boxWidth)) / 100) * 70}px`
    }
    setDraggedData(draggedBoxData as ScheduleItemBoxProps);
  }

  const handleDragEnd = (e: DragEndEvent) => {
    if (!e.over) {
      setDraggedData(undefined);
      return;
    }
    const targetRowIdx = e.over.data.current?.row;
    const targetHour = e.over.data.current?.hour;
    const dataId = e.active.data.current?.dragId;
    const originalRowIdx = e.active.data.current?.shiftItem?.originalRow;
    const period = e.active.data.current?.shiftItem?.schedule_date?.timePeriod;
    
    if (
      targetRowIdx === undefined || 
      targetHour === undefined || 
      dataId === undefined || 
      originalRowIdx === undefined ||
      period === undefined
    ) {
      setDraggedData(undefined);
      return;
    }

    if (targetRowIdx !== originalRowIdx) {
      setDraggedData(undefined);
      return;
    }

    const newStartTime = convertToTimeString(targetHour);
    const calculatedNewEndTime = Number(targetHour) + period;
    const newEndTime = calculateTime(calculatedNewEndTime);

    if (newStartTime.includes("Invalid") || newEndTime.includes("Invalid")) {
      setDraggedData(undefined);
      return;
    }

    const shiftDatas = [...allShiftItems];
    const targetRow = shiftDatas[targetRowIdx];
    const newTargetRow = targetRow.map((item: any) => {
      if (item.dragId === dataId) {
        item.schedule_date.start_time = newStartTime;
        item.schedule_date.end_time = newEndTime;
        item.time_range = `${newStartTime} ~ ${newEndTime}`;
        item.edited = true;

        return item;
      } else {
        return item;
      }
    });
    shiftDatas[targetRowIdx] = newTargetRow;
    setAllShiftItems(shiftDatas);
    setIsClientScheduleEdited(true);
    setDraggedData(undefined);
  }

  const handleUpdateRecords = async () => {
    let editedScheduleItem: any[] = [];
    allShiftItems.forEach((item: any[]) => {
      item.forEach((subItem: any) => {
        if (subItem.edited) {
          if (subItem.delete) {
            const cloneItem = { 
              id: subItem.schedule_date.id, 
              delete: subItem.delete,
            };
            editedScheduleItem.push(cloneItem);
          } else {
            const cloneItem = { 
              id: subItem.schedule_date.id, 
              start_time: subItem.schedule_date.start_time,
              end_time: subItem.schedule_date.end_time,
            };
            editedScheduleItem.push(cloneItem);
          }
        }
      })
    });
    try {
      const response = await apiFactory.shiftManagementApi.updateShiftSchedules({
        button_type: "draft",
        schedule_dates: editedScheduleItem
      });
      if (response) {
        fetchDataDayShift();
        setIsClientScheduleEdited(false);
        toast.success(response.success || "更新に成功しました");
      }
    } catch (error) {
      // 
    }
  }

  const handleCancelUpdate = async () => {
    try {
      const response = await apiFactory.shiftManagementApi.updateShiftSchedules({
        button_type: "cancel"
      });
      if (response) {
        fetchDataDayShift();
        setIsClientScheduleEdited(false);
        toast.success(response.success || "更新に成功しました");
      }
    } catch (error) {
      // 
    }
  };

  useEffect(() => {
    fetchDataDayShift();
  }, [currentDate, type, createShift])

  useEffect(() => {
    if (dataStaff) {
      if (dataStaff.length > 0) {
        setShowRegisterButtons(true);
      } else {
        setShowRegisterButtons(false);
      }
    }
  }, [dataStaff]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(pointerSensor);

  return (
    <>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
        <Row>
          <Col span={6}>
            <Row>
              <Col className="patient-title " style={{ height: "50px", background: '#f5f4f2', border: "1px solid #000", display:'flex' }} span={8}>
                <Box margin='auto' textAlign="center" fontWeight='bold' >お客様</Box>
              </Col>
            </Row>
            {
              dataStaff.map((item: any, dataStaffIndex: number) => {
                return (
                  <Row key={item.id}>
                    <Col className="patient-title" span={8}>
                      <Box
                        // bg="brand.500"
                        display="flex"
                        fontWeight="bold"
                        fontSize="11px"
                        // p="20px 0px"
                        bgColor={dataStaffIndex % 2 !== 0 ? "#f5f4f2" : "#ffffff"}
                        border="1px solid #000"
                        height="calc(100% - 0px) !important"
                        textAlign="center"
                      >
                        <Text margin="auto" fontSize="16px" fontWeight="bold">{item?.family_name || ""}</Text>
                      </Box>
                    </Col>
                    <Col className="patient" span={16}>
                      {
                        (item.shifts ? Object.keys(item.shifts) : []).map((shiftItem: any, index: number) => {
                          if (
                            (index === (Object.keys(item.shifts) || []).length - 1)
                            && (dataStaffIndex === (dataStaff.length - 1))
                          ) {
                            return (
                              <Box className="type-service" style={{ 
                                height: '88px', 
                                borderTop: "1px solid #e6e6e6", 
                                borderBottom: "1px solid #e6e6e6", 
                                display: "flex", 
                                alignItems: "center" 
                              }} 
                                key={index}
                              >
                                <Text ml={3} >{shiftItem}</Text>
                              </Box>
                            )
                          }
                          return (
                            <Box className="type-service" style={{ 
                              height: '88px', 
                              borderTop: "1px solid #e6e6e6", 
                              display: "flex", 
                              alignItems: "center" 
                            }} 
                              key={index}
                            >
                              <Text ml={3} >{shiftItem}</Text>
                            </Box>
                        )})
                      }
                    </Col>
                  </Row>
                )})
            }
          </Col>

          <Col span={18}>
          <TableContainer overflowX="scroll" transform="rotateX(180deg)">
              <Table width={`${70 * 23}px`} transform="rotateX(180deg)">
                <Tr height="0px">
                  {dataHour.map((item: any, index: number) => (
                    <StyleTd
                      position="relative"
                      zIndex="9999"
                      padding="0px"
                      key={index}
                      style={{
                        border: "0px",
                        height: "41px",
                        width: "70px !important",
                      }}
                      fontSize="12px"
                    >
                      <Text
                        className="text-left"
                        zIndex="9999"
                        position="absolute"
                        left="-7px"
                      >{item}</Text>
                    </StyleTd>
                  ))}
                </Tr>

                {
                  allShiftItems.map((item: any, idx: number) => {
                    const isLastRow = idx === (allShiftItems.length - 1);
                    return (
                      <Tr key={idx}>
                      {dataHour.map((hour: string, index: number) => (
                        <ScheduleCell 
                          key={index} 
                          hour={hour} 
                          editMode={editMode}
                          index={idx}
                          isLastRow={isLastRow}
                        >
                          {
                            item.map((shiftItem: any, shiftItemIndex: number) => {
                              if (shiftItem 
                                && shiftItem.schedule_date 
                                && convertStringTimeToNum(shiftItem.schedule_date.start_time)[0] === index
                                && !shiftItem.delete
                              ) {
                                const boxWidth = getScheduleBoxWidth(shiftItem.schedule_date.start_time, shiftItem.schedule_date.end_time);
                                return (
                                  <ScheduleItemBox 
                                    key={shiftItemIndex}
                                    hour={hour}
                                    clientName={shiftItem.clientName}
                                    shiftServiceName={shiftItem.shiftName}
                                    editMode={editMode}
                                    boxWidth={boxWidth}
                                    shiftItem={shiftItem}
                                    setOpenDetail={setOpenDetail}
                                    dragId={shiftItem.dragId}
                                    setSelectedShiftItem={setSelectedShiftItem}
                                  />
                                );
                              } else {
                                return <></>;
                              }
                            })
                          }
                        </ScheduleCell>
                      ))}
                    </Tr>
                    )
                  })
                }
              </Table>
            </TableContainer>
          </Col>
        </Row>

        <DragOverlay>
          {draggedData ? <ScheduleItemBox {...draggedData} /> : null}
        </DragOverlay>
      </DndContext>

      {
        type && (
          <Prompt
            when={isClientScheduleEdited}
            message="Are you sure you want to leave?"
            change={() => handleCancelUpdate()}
            setInitial={setIsClientScheduleEdited}
          />
        )
      }

      {
        isClientScheduleEdited && (
          <div className="flex justify-end gap-x-[8px] mt-[42px] pb-[50px]">
            <ButtonOutline className="!text-[14px] leading-[24px] !px-[16px] !max-h-[35px]" onClick={() => handleCancelUpdate()}>
              キャンセル
            </ButtonOutline>
            <ButtonSolid className="!text-[14px] leading-[24px] !px-[34px] !max-h-[35px]" onClick={() => handleUpdateRecords()}>
              下書き保存
            </ButtonSolid>
          </div>
        )
      }

      <ScheduleRegister
        title="スケジュール調整"
        openRegisterShift={showScheduleAdjust}
        setOpenRegisterShift={setShowScheduleAdjust}
        setIsClientScheduleEdited={setIsClientScheduleEdited}
        setCreateShift={setCreateShift}
        setIsShiftEdited={setIsShiftEdited}
      />

      {
        openDetail && (
          <ScheduleDetail 
            openState={[openDetail, setOpenDetail]}
            title="スケジュール調整"
            selectedShiftItemState={[selectedShiftItem, setSelectedShiftItem]}
            refetchData={fetchDataDayShift}
            allShiftItemsState={[allShiftItems, setAllShiftItems]}
            setIsEdited={setIsClientScheduleEdited}
          />
        )
      }
    </>
  );
}

interface IRecord {
  id: string;
  name: string;
  service: string;
  events: {
    id: string;
    name: string;
    start: string;
    end: string;
  }[];
  business_hour?: {
    // optional, nếu là record của patient thì không có
    start: string;
    end: string;
  };
}
