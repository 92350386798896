import { Tabs, TabsProps } from "antd";
import BillingList from "./BillingList";
import DespositHistory from "./DepositHistory";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "請求一覧",
    children: <BillingList />,
  },
  {
    key: "2",
    label: "入金履歴",
    children: <DespositHistory />,
  },
];

export default function PaymentPage() {
  return (
    <div>
      <div className="page-title">請求・入金履歴</div>
      <div className="page-container">
        <Tabs items={items} />
      </div>
    </div>
  );
}
