import { Pagination, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { ButtonOutline, ButtonSolid } from "component/button";
import apiFactory from "api";
import { NumericFormat } from "react-number-format";
import CustomPagination from "component/CustomPagination";

interface DataType {
  key: React.Key;
  id: string;
  name: string;
}

const DisabilityEnhanceList = ({
  onChange,
  setServiceId,
  setDifference,
}: any) => {
  const [sortKey, setSortKey] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("id");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        width: "50px",
      },
      {
        title: "名称",
        dataIndex: "name",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("name");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        render: (text) => {
          if (text.length > 30) {
            return text.slice(0, 30) + " ...";
          }
          return text;
        },
        width: "400px",
      },
      {
        title: "掛け率",
        dataIndex: "rate",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("rate");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        render: (text) => {
          return (
            <NumericFormat
              className="bg-[initial]"
              disabled
              value={text}
              thousandsGroupStyle="wan"
              thousandSeparator=","
              decimalScale={2}
            />
          );
        },
        width: "250px",
      },
      {
        align: "right",
        title: "",
        key: "action",
        render: (_, record) => (
          <ButtonOutline
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
            onClick={() => onSelectItem(record.id, "view")}
          >
            詳細
          </ButtonOutline>
        ),
      },
    ],
    [sortKey, order]
  );
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [dataList, setDataList] = useState([]);

  const fetchData = async () => {
    const result = await apiFactory.careRegistrationApi.getTreatmentList(
      {
        per: limit,
        page: page,
        sortKey,
        order,
      },
      "disability"
    );
    if (result) {
      setDataList(
        result?.serialized_treatment_improvements.map((e: any) => ({
          id: e.id,
          name: e.name,
          rate: e.rate,
        }))
      );
      setTotalItems(result?.total_items);
    }
  };
  const onSelectItem = (id: any, type: string) => {
    setServiceId(id);
    setDifference({
      type,
    });
    onChange();
  };
  useEffect(() => {
    fetchData();
  }, [page, limit, sortKey, order]);
  return (
    <div className="disability-enhance-list pt-[7px]">
      <div className="text-end mb-[30px]">
        <ButtonSolid
          className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
          onClick={() => onSelectItem(null, "add")}
        >
          新規追加
        </ButtonSolid>
      </div>
      <div className="flex items-center justify-end wrapper-pagination mb-[15px]">
        <CustomPagination
          current={page}
          setCurrent={setPage}
          total={totalItems}
          perPage={limit}
          setPerPage={setLimit}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </div>
      <Table
        bordered
        showSorterTooltip={{ title: "" }}
        rowClassName={(record, index) => {
          if (index % 2 !== 0) {
            return "bg-[#f5f4f2]";
          }
          return "";
        }}
        columns={columns}
        dataSource={dataList}
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: [5, 10],
          pageSize: limit,
          position: ["bottomCenter"],
          style: { display: "none" },
        }}
      />
      <div className="flex items-center justify-end wrapper-pagination mt-[15px]">
        <CustomPagination
          current={page}
          setCurrent={setPage}
          total={totalItems}
          perPage={limit}
          setPerPage={setLimit}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </div>
    </div>
  );
};
export { DisabilityEnhanceList };
