import { DatePicker, Form, Input } from "antd";
import { JP_DATEPICKER_FORMAT } from "constant/date";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
export interface ILongTermGoal {
  name: string;
  label: string;
}

interface ICareSectionProps {
  title: string;
  longTermGoals: ILongTermGoal[];
  isEdit: boolean;
  isLongTerm: boolean;
  setGoals: Dispatch<SetStateAction<ILongTermGoal[]>>;
  onAddGoals?: () => void;
  onRemoveGoals?: (name: string) => void;
}

const CareSection = (props: ICareSectionProps) => {
  const {
    title,
    longTermGoals,
    isEdit = false,
    isLongTerm,
    setGoals,
    onRemoveGoals,
  } = props;

  const handleAddGoals = () => {
    const orderIndex = longTermGoals?.length + 1;
    const newLongTermGoal = {
      name: `${isLongTerm ? "long" : "short"}_term_goal_${orderIndex}`,
      label: `${isLongTerm ? "長期目標" : "短期目標"}${orderIndex}`,
    };

    setGoals((prevGoals) => [...prevGoals, newLongTermGoal]);
  };

  const handleRemoveGoals = () => {
    if (longTermGoals.length === 1) {
      return;
    }

    setGoals((prevGoals) => prevGoals?.slice(0, prevGoals.length - 1));
    onRemoveGoals?.(longTermGoals?.[longTermGoals.length - 1]?.name);
  };

  return (
    <Box>
      <Text fontSize="14px" fontWeight="bold" mb="12px">
        {title}
      </Text>

      <Form.Item
        label="期間"
        name={`${isLongTerm ? "long" : "short"}_term_date`}
        labelCol={{
          span: 5,
        }}
      >
        <DatePicker.RangePicker
          className="w-full"
          format={JP_DATEPICKER_FORMAT}
        />
      </Form.Item>

      {longTermGoals?.map((item) => {
        return (
          <Form.Item
            name={item?.name}
            label={item?.label}
            labelCol={{
              span: 5,
            }}
            key={item?.name}
          >
            <Input />
          </Form.Item>
        );
      })}

      {isEdit && (
        <Flex justifyContent="flex-end">
          <Flex
            borderRadius="50%"
            border="1px solid black"
            h="32px"
            w="32px"
            justifyContent="center"
            alignItems="center"
            mr="8px"
            cursor="pointer"
            onClick={handleAddGoals}
            className="hover:bg-[#EDF2F7]"
          >
            <PlusOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </Flex>

          <Flex
            borderRadius="50%"
            border="1px solid black"
            h="32px"
            w="32px"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            className="hover:bg-[#EDF2F7]"
            onClick={handleRemoveGoals}
          >
            <MinusOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default CareSection;
