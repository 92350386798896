import styled from "@emotion/styled";
import { DATE_ERA_FORMAT, TIME_FORMAT } from "constant/date";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "moment-timezone";
import moment from "moment-timezone";
import "moment/locale/ja";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import CheckBoxPDF from "component/CheckBoxPDF";
import { CARE_PLAN_TYPE_OPTIONS } from "constant";
import { isEmpty } from "lodash";

const timeJapan = "Asia/Tokyo";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(timeJapan);

type PrintProp = {
  dataPrint: Array<any>;
  info?: { [key: string]: any };
  setIsReadyPrint: Dispatch<boolean>;
};
const paddingOfPage = 20; //px

const RecordHistoryCustomerPDFForm = React.forwardRef<
  HTMLDivElement,
  PrintProp
>((props, ref: any) => {
  const { dataPrint: data, setIsReadyPrint } = props;
  const refForCount = useRef<HTMLDivElement>(null);
  const [caclHeight, setCaclHeight] = useState<{ [key: string]: number }>({});

  const getPhysicalCare = (value: any) => {
    switch (value) {
      case "body":
        return "身体";
      case "life":
        return "生活";
      case "severe_visit":
        return "重度訪問";
      default:
        return "身体";
    }
  };

  function calcScreenDPI() {
    const el = document.createElement("div") as HTMLElement;
    el.style.width = "1in";
    document.body.appendChild(el);
    const dpi = el.offsetWidth;
    document.body.removeChild(el);
    return dpi;
  }

  useEffect(() => {
    if (refForCount.current) {
      const trTags = refForCount.current.querySelectorAll("tr");
      const termCaclHeight: typeof caclHeight = {};
      trTags.forEach((x) => {
        const index = x.dataset.index + "";
        if (termCaclHeight.hasOwnProperty(index)) {
          termCaclHeight[index] = termCaclHeight[index] + x.offsetHeight;
        } else {
          termCaclHeight[index] = x.offsetHeight;
        }
      });
      if (isEmpty(caclHeight) && !isEmpty(termCaclHeight))
        setCaclHeight(termCaclHeight);
    }
  });

  useEffect(() => {
    if (!isEmpty(caclHeight)) setIsReadyPrint(true);
  }, [caclHeight]);

  const DPI = calcScreenDPI();
  let remainHeightOfA4Page = 842 * (DPI / 72);

  return (
    <>
      <div
        ref={refForCount}
        className={`p-[${paddingOfPage}px] w-[210mm] fixed top-[-9999px] opacity-0`}
      >
        <StyledTable>
          <tbody>
            {data &&
              data?.length > 0 &&
              data?.map((item: any, index: any) => (
                <React.Fragment key={index}>
                  <tr
                    data-index={index}
                    style={{ height: "12.0pt" }}
                    className="name-patient"
                  >
                    <td colSpan={3} style={{ height: "12pt" }}>
                      <div className="flex text-xs">
                        <div className="mr-3">
                          {item.date &&
                            moment(item.date).format(DATE_ERA_FORMAT)}
                        </div>
                        <div className="mr-5">
                          {CARE_PLAN_TYPE_OPTIONS.find(
                            (option) =>
                              option.value === item!.scheduleable.care_plan_type
                          )?.content || ""}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    data-index={index}
                    style={{ height: "15.0pt" }}
                    className="name-patient"
                  >
                    <td colSpan={3} style={{ height: "12pt" }}>
                      <div className="flex mt-1 text-xs">
                        <div className="mr-1">氏名:</div>
                        <div className="mr-1">
                          {item.scheduleable?.patient.last_name}
                        </div>
                        <div className="mr-1">
                          {item.scheduleable?.patient.first_name}
                        </div>
                        <div className="mr-10">様</div>
                      </div>
                    </td>
                  </tr>
                  <tr data-index={index} style={{ height: "15.0pt" }}>
                    <td
                      align="center"
                      rowSpan={3}
                      width={64}
                      style={{ height: "45.0pt", width: "50pt" }}
                    >
                      {moment(new Date(item?.date)).format("DD 日 (ddd)")}
                    </td>
                    <td
                      align="center"
                      colSpan={1}
                      rowSpan={3}
                      width={64}
                      style={{ width: "50pt" }}
                    >
                      <div>
                        {item?.start_time
                          ? `${moment(item?.start_time)
                              .utc()
                              .format("ss:mm")} - ${moment(item?.end_time)
                              .utc()
                              .format("ss:mm")}`
                          : "8:30 - 9:00"}
                      </div>
                      <div>
                        {item?.nursing_care_history
                          ? `${
                              moment(
                                item?.nursing_care_history?.start_time
                              ).isValid()
                                ? moment(
                                    item?.nursing_care_history?.start_time
                                  ).format(TIME_FORMAT)
                                : item?.nursing_care_history?.start_time
                            } - ${
                              moment(
                                item?.nursing_care_history?.end_time
                              ).isValid()
                                ? moment(
                                    item?.nursing_care_history?.end_time
                                  ).format(TIME_FORMAT)
                                : item?.nursing_care_history?.end_time
                            }`
                          : `${moment(item?.start_time).format(
                              TIME_FORMAT
                            )} ~ ${moment(item?.end_time).format(TIME_FORMAT)}`}
                      </div>
                    </td>
                    <td align="left" colSpan={6} rowSpan={8}>
                      <div className="grid grid-cols-[1fr_1.1fr_1fr_1fr] gap-x-1 mx-3">
                        <div className="underline">事前チェック</div>
                        <div className="underline">排泄</div>
                        <div className="underline">食事</div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.washbasin}
                          />
                          洗面
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.complexion && true
                            }
                          />
                          顔色
                          {item?.nursing_care_history?.complexion === "good"
                            ? "（良）"
                            : "(不良）"}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.toilet_assistance
                            }
                          />
                          トイレ介助
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.maintain_posture
                            }
                          />
                          姿勢の確保
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.oral_care}
                          />
                          口腔ケア
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.sweating && true
                            }
                          />
                          発汗
                          {item?.nursing_care_history?.sweating === "yes"
                            ? `（有）`
                            : `（無）`}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.diaper_check}
                          />
                          オムツ確認・交換
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              Number(item?.nursing_care_history?.hydration) > 0
                            }
                          />
                          水分補給{" "}
                          {Number(item?.nursing_care_history?.hydration) > 0 &&
                            `${item?.nursing_care_history?.hydration}cc`}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.dressing_assistance
                            }
                          />
                          更衣介助
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.temperature}
                          />
                          体温
                          {item?.nursing_care_history?.temperature
                            ? ` ${item?.nursing_care_history?.temperature}℃`
                            : ""}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.pad_confirmation
                            }
                          />
                          パット確認・交換
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.eating_assistance &&
                              item?.nursing_care_history?.eating_assistance !==
                                "なし" &&
                              true
                            }
                          />
                          摂食介助
                          {item?.nursing_care_history?.eating_assistance &&
                            item?.nursing_care_history?.eating_assistance !==
                              "なし" &&
                            `（${item?.nursing_care_history?.eating_assistance}）`}
                        </div>
                        <div className="underline">移動</div>
                        <div>
                          <CheckBoxPDF
                            checked={item?.nursing_care_history?.blood_pressure}
                            className="mr-1"
                          />
                          血圧 {item?.nursing_care_history?.blood_pressure}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              Number(item?.nursing_care_history?.urination) > 0
                            }
                          />
                          排尿{" "}
                          {Number(item?.nursing_care_history?.urination) > 0
                            ? `${item?.nursing_care_history?.urination}回`
                            : "0 回"}
                        </div>
                        <div className="underline">入浴 身体整容</div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.position_exchange
                            }
                          />
                          体位交換
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history
                                ?.environmental_arrangement
                            }
                          />
                          環境整備
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              Number(item?.nursing_care_history?.defecation) > 0
                            }
                          />
                          排便{" "}
                          {Number(item?.nursing_care_history?.defecation) > 0
                            ? `${item?.nursing_care_history?.defecation}回`
                            : "0 回"}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.cleaning &&
                              item?.nursing_care_history?.cleaning !== "なし" &&
                              true
                            }
                          />
                          清拭
                          {item?.nursing_care_history?.cleaning &&
                            item?.nursing_care_history?.cleaning !== "なし" &&
                            `（${item?.nursing_care_history?.cleaning}）`}
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.transfer_assistance
                            }
                          />
                          移動・移乗介助
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history
                                ?.consultation_assistance
                            }
                          />
                          相談援助
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.urinal_cleaning
                            }
                          />
                          尿気洗浄
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history?.full_body_bath &&
                              item?.nursing_care_history?.full_body_bath !==
                                "なし" &&
                              true
                            }
                          />
                          全身浴
                          {item?.nursing_care_history?.full_body_bath &&
                            item?.nursing_care_history?.full_body_bath !==
                              "なし" &&
                            `（${item?.nursing_care_history?.full_body_bath}）`}
                        </div>
                        <div className="underline">その他</div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.record}
                          />
                          記録
                        </div>
                        <div></div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.washing_hair}
                          />
                          洗髪 &nbsp;
                          <CheckBoxPDF
                            className="mr-1"
                            checked={
                              item?.nursing_care_history
                                ?.full_body_bath_procedure
                            }
                          />{" "}
                          洗身
                        </div>
                        <div>
                          <CheckBoxPDF
                            className="mr-1"
                            checked={item?.nursing_care_history?.watch_over}
                          />
                          見守り
                        </div>
                      </div>
                    </td>
                    <td
                      align="center"
                      colSpan={3}
                      width={192}
                      style={{ width: "144pt" }}
                    >
                      特記事項
                    </td>
                  </tr>
                  <tr
                    data-index={index}
                    style={{
                      height: "60.0pt",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      width: "500pt",
                      borderRight: "1px solid #000",
                      whiteSpace: "pre-line",
                    }}
                  >
                    <div style={{ fontSize: "12px" }}>
                      <td
                        colSpan={3}
                        style={{
                          height: "60.0pt",
                          whiteSpace: "pre-line",
                          padding: "5px",
                          textAlign: "left",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          width: "500pt",
                        }}
                      >
                        <div>{item?.nursing_care_history?.note}</div>
                      </td>
                    </div>
                  </tr>
                  <tr data-index={index} style={{ height: "0pt" }}></tr>
                  <tr data-index={index} style={{ height: "15.0pt" }}>
                    <td colSpan={3} rowSpan={3} className="h-[45pt] pl-3">
                      <div>
                        {item?.nursing_care_history?.division === "normal"
                          ? "身体介護"
                          : "障害者総合支援法"}{" "}
                      </div>
                      <div>
                        <div className="flex justify-between">
                          <div className="flex items-center">
                            {getPhysicalCare(
                              item?.nursing_care_history?.physical_care
                            )}
                            {`(${item?.nursing_care_history?.service?.service_name})`}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      colSpan={3}
                      align="center"
                      style={{ height: "0pt", borderBottom: "0px" }}
                    >
                      担当ヘルパー
                    </td>
                  </tr>

                  <tr data-index={index} style={{ height: "15.0pt" }}>
                    <td
                      align="center"
                      colSpan={3}
                      style={{ height: "15.0pt", borderTop: "0px" }}
                    ></td>
                  </tr>
                  <tr data-index={index} style={{ height: "0pt" }}></tr>
                  <tr data-index={index} style={{ height: "15.0pt" }}>
                    <td colSpan={3} rowSpan={2} style={{ height: "30.0pt" }}>
                      <div className="ml-3">
                        {item?.nursing_care_history?.service_type &&
                          item?.nursing_care_history?.service_type?.detail}
                      </div>
                    </td>
                    <td colSpan={3} rowSpan={2} className="text-center">
                      {item?.nursing_care_history?.nurse?.family_name}
                    </td>
                  </tr>
                  <tr data-index={index} style={{ height: "15.0pt" }}></tr>
                  <tr
                    data-index={index}
                    style={{ height: "20.0pt" }}
                    className="last-row"
                  >
                    <td></td>
                  </tr>
                </React.Fragment>
              ))}

            {/* end row */}
          </tbody>
        </StyledTable>
      </div>
      <div ref={ref} className={`p-[20px]`} hidden>
        <StyledTable>
          <tbody>
            {data &&
              data?.length > 0 &&
              data?.map((item: any, index: number) => {
                const isBreak =
                  remainHeightOfA4Page < caclHeight[index] &&
                  caclHeight[index] < 842 * (DPI / 72);
                remainHeightOfA4Page = !isBreak
                  ? remainHeightOfA4Page - caclHeight[index]
                  : 842 * (DPI / 72);
                return (
                  <React.Fragment key={index}>
                    {isBreak && (
                      <>
                        <tr className="break-after-page"></tr>
                        <tr className="h-5"></tr>
                      </>
                    )}
                    <tr
                      data-index={index}
                      style={{ height: "12.0pt" }}
                      className="name-patient"
                    >
                      <td colSpan={3} style={{ height: "12pt" }}>
                        <div className="flex text-xs">
                          <div className="mr-3">
                            {item.date &&
                              moment(item.date).format(DATE_ERA_FORMAT)}
                          </div>
                          <div className="mr-5">
                            {CARE_PLAN_TYPE_OPTIONS.find(
                              (option) =>
                                option.value ===
                                item!.scheduleable.care_plan_type
                            )?.content || ""}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      data-index={index}
                      style={{ height: "15.0pt" }}
                      className="name-patient"
                    >
                      <td colSpan={3} style={{ height: "12pt" }}>
                        <div className="flex mt-1 text-xs">
                          <div className="mr-1">氏名:</div>
                          <div className="mr-1">
                            {item.scheduleable?.patient.last_name}
                          </div>
                          <div className="mr-1">
                            {item.scheduleable?.patient.first_name}
                          </div>
                          <div className="mr-10">様</div>
                        </div>
                      </td>
                    </tr>
                    <tr data-index={index} style={{ height: "15.0pt" }}>
                      <td
                        align="center"
                        rowSpan={3}
                        width={64}
                        style={{ height: "45.0pt", width: "50pt" }}
                      >
                        {moment(new Date(item?.date)).format("DD 日 (ddd)")}
                      </td>
                      <td
                        align="center"
                        colSpan={1}
                        rowSpan={3}
                        width={64}
                        style={{ width: "50pt" }}
                      >
                        <div>
                          {item?.start_time
                            ? `${moment(item?.start_time)
                                .utc()
                                .format("ss:mm")} - ${moment(item?.end_time)
                                .utc()
                                .format("ss:mm")}`
                            : "8:30 - 9:00"}
                        </div>
                        <div>
                          {item?.nursing_care_history
                            ? `${
                                moment(
                                  item?.nursing_care_history?.start_time
                                ).isValid()
                                  ? moment(
                                      item?.nursing_care_history?.start_time
                                    ).format(TIME_FORMAT)
                                  : item?.nursing_care_history?.start_time
                              } - ${
                                moment(
                                  item?.nursing_care_history?.end_time
                                ).isValid()
                                  ? moment(
                                      item?.nursing_care_history?.end_time
                                    ).format(TIME_FORMAT)
                                  : item?.nursing_care_history?.end_time
                              }`
                            : `${moment(item?.start_time).format(
                                TIME_FORMAT
                              )} ~ ${moment(item?.end_time).format(
                                TIME_FORMAT
                              )}`}
                        </div>
                      </td>
                      <td align="left" colSpan={6} rowSpan={8}>
                        <div className="grid grid-cols-[1fr_1.1fr_1fr_1fr] gap-x-1 mx-3">
                          <div className="underline">事前チェック</div>
                          <div className="underline">排泄</div>
                          <div className="underline">食事</div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.washbasin}
                            />
                            洗面
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.complexion && true
                              }
                            />
                            顔色
                            {item?.nursing_care_history?.complexion === "good"
                              ? "（良）"
                              : "(不良）"}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.toilet_assistance
                              }
                            />
                            トイレ介助
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.maintain_posture
                              }
                            />
                            姿勢の確保
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.oral_care}
                            />
                            口腔ケア
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.sweating && true
                              }
                            />
                            発汗
                            {item?.nursing_care_history?.sweating === "yes"
                              ? `（有）`
                              : `（無）`}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.diaper_check}
                            />
                            オムツ確認・交換
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                Number(item?.nursing_care_history?.hydration) >
                                0
                              }
                            />
                            水分補給{" "}
                            {Number(item?.nursing_care_history?.hydration) >
                              0 && `${item?.nursing_care_history?.hydration}cc`}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.dressing_assistance
                              }
                            />
                            更衣介助
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.temperature}
                            />
                            体温
                            {item?.nursing_care_history?.temperature
                              ? ` ${item?.nursing_care_history?.temperature}℃`
                              : ""}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.pad_confirmation
                              }
                            />
                            パット確認・交換
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.eating_assistance &&
                                item?.nursing_care_history
                                  ?.eating_assistance !== "なし" &&
                                true
                              }
                            />
                            摂食介助
                            {item?.nursing_care_history?.eating_assistance &&
                              item?.nursing_care_history?.eating_assistance !==
                                "なし" &&
                              `（${item?.nursing_care_history?.eating_assistance}）`}
                          </div>
                          <div className="underline">移動</div>
                          <div>
                            <CheckBoxPDF
                              checked={
                                item?.nursing_care_history?.blood_pressure
                              }
                              className="mr-1"
                            />
                            血圧 {item?.nursing_care_history?.blood_pressure}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                Number(item?.nursing_care_history?.urination) >
                                0
                              }
                            />
                            排尿{" "}
                            {Number(item?.nursing_care_history?.urination) > 0
                              ? `${item?.nursing_care_history?.urination}回`
                              : "0 回"}
                          </div>
                          <div className="underline">入浴 身体整容</div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.position_exchange
                              }
                            />
                            体位交換
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history
                                  ?.environmental_arrangement
                              }
                            />
                            環境整備
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                Number(item?.nursing_care_history?.defecation) >
                                0
                              }
                            />
                            排便{" "}
                            {Number(item?.nursing_care_history?.defecation) > 0
                              ? `${item?.nursing_care_history?.defecation}回`
                              : "0 回"}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.cleaning &&
                                item?.nursing_care_history?.cleaning !==
                                  "なし" &&
                                true
                              }
                            />
                            清拭
                            {item?.nursing_care_history?.cleaning &&
                              item?.nursing_care_history?.cleaning !== "なし" &&
                              `（${item?.nursing_care_history?.cleaning}）`}
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.transfer_assistance
                              }
                            />
                            移動・移乗介助
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history
                                  ?.consultation_assistance
                              }
                            />
                            相談援助
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.urinal_cleaning
                              }
                            />
                            尿気洗浄
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history?.full_body_bath &&
                                item?.nursing_care_history?.full_body_bath !==
                                  "なし" &&
                                true
                              }
                            />
                            全身浴
                            {item?.nursing_care_history?.full_body_bath &&
                              item?.nursing_care_history?.full_body_bath !==
                                "なし" &&
                              `（${item?.nursing_care_history?.full_body_bath}）`}
                          </div>
                          <div className="underline">その他</div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.record}
                            />
                            記録
                          </div>
                          <div></div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.washing_hair}
                            />
                            洗髪 &nbsp;
                            <CheckBoxPDF
                              className="mr-1"
                              checked={
                                item?.nursing_care_history
                                  ?.full_body_bath_procedure
                              }
                            />{" "}
                            洗身
                          </div>
                          <div>
                            <CheckBoxPDF
                              className="mr-1"
                              checked={item?.nursing_care_history?.watch_over}
                            />
                            見守り
                          </div>
                        </div>
                      </td>
                      <td
                        align="center"
                        colSpan={3}
                        width={192}
                        style={{ width: "144pt" }}
                      >
                        特記事項
                      </td>
                    </tr>
                    <tr
                      data-index={index}
                      style={{
                        height: "60.0pt",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        width: "500pt",
                        borderRight: "1px solid #000",
                        whiteSpace: "pre-line",
                      }}
                    >
                      <div style={{ fontSize: "12px" }}>
                        <td
                          colSpan={3}
                          style={{
                            height: "60.0pt",
                            whiteSpace: "pre-line",
                            padding: "5px",
                            textAlign: "left",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            width: "500pt",
                          }}
                        >
                          <div>{item?.nursing_care_history?.note}</div>
                        </td>
                      </div>
                    </tr>
                    <tr data-index={index} style={{ height: "0pt" }}></tr>
                    <tr data-index={index} style={{ height: "15.0pt" }}>
                      <td colSpan={3} rowSpan={3} className="h-[45pt] pl-3">
                        <div>
                          {item?.nursing_care_history?.division === "normal"
                            ? "身体介護"
                            : "障害者総合支援法"}{" "}
                        </div>
                        <div>
                          <div className="flex justify-between">
                            <div className="flex items-center">
                              {getPhysicalCare(
                                item?.nursing_care_history?.physical_care
                              )}
                              {`(${item?.nursing_care_history?.service?.service_name})`}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        colSpan={3}
                        align="center"
                        style={{ height: "0pt", borderBottom: "0px" }}
                      >
                        担当ヘルパー
                      </td>
                    </tr>

                    <tr data-index={index} style={{ height: "15.0pt" }}>
                      <td
                        align="center"
                        colSpan={3}
                        style={{ height: "15.0pt", borderTop: "0px" }}
                      ></td>
                    </tr>
                    <tr data-index={index} style={{ height: "0pt" }}></tr>
                    <tr data-index={index} style={{ height: "15.0pt" }}>
                      <td colSpan={3} rowSpan={2} style={{ height: "30.0pt" }}>
                        <div className="ml-3">
                          {item?.nursing_care_history?.service_type &&
                            item?.nursing_care_history?.service_type?.detail}
                        </div>
                      </td>
                      <td colSpan={3} rowSpan={2} className="text-center">
                        {item?.nursing_care_history?.nurse?.family_name}
                      </td>
                    </tr>
                    <tr data-index={index} style={{ height: "15.0pt" }}></tr>
                    {index !== data.length - 1 && (
                      <tr
                        data-index={index}
                        style={{ height: "20.0pt" }}
                        className="last-row"
                      >
                        <td></td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}

            {/* end row */}
          </tbody>
        </StyledTable>
      </div>
    </>
  );
});
const StyledTable = styled.table`
  width: auto; // width of a4
  > tbody > tr > td {
    font-size: 10px;
    border: 1px solid;
    white-space: nowrap;
    & > div > div {
      display: flex;
      align-items: center;
      line-height: 20px;
    }
  }
  > tbody > tr.name-patient td,
  > tbody > tr.last-row td {
    border: unset;
  }
`;

export default RecordHistoryCustomerPDFForm;
