import { Table, Tbody, Tr } from "@chakra-ui/react";
import { StyleTd, StyleTr } from "component/StyledComponent";
import React from "react";
import TableHeadTd from "./HeadTd";
import moment from "moment";
import { TIMEJAPAN } from "utils";
import "moment/locale/ja";
import "moment-timezone";
import "dayjs/locale/ja";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTES from "constant/routes";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getTypeCarePlanJP } from "./TableRecordCustomer";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(TIMEJAPAN);

const TableCustomerName = ({ dataTable, date }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);

  return (
    <Table className="text-xs">
      <Tbody>
        <StyleTr>
          <TableHeadTd title="日付" />
          <TableHeadTd title="時間" />
          <TableHeadTd title="お客様名" />
          <TableHeadTd title="区分" />
          <TableHeadTd title="体温" />
          <TableHeadTd title="血圧" />
          <TableHeadTd title="排尿" />
          <TableHeadTd title="排便" />
          <TableHeadTd title="水分" />
          <TableHeadTd title="全身浴" />
          <TableHeadTd title="担当者" />
          <StyleTd />
        </StyleTr>
        {dataTable?.map((item: any, index: any) => (
          <Tr
            style={{
              background: `${
                index % 2
                  ? "var(--secondary-color) 0% 0% no-repeat padding-box"
                  : ""
              }`,
              height: "55px",
            }}
          >
            <StyleTd whiteSpace="nowrap">
              {moment(item?.date).format("YYYY/MM/DD (ddd)")}
            </StyleTd>
            <StyleTd style={{ whiteSpace: "nowrap" }}>
              {/* {item?.time} */}
              {/* {`${dayjs.tz(item?.timeStart, TIMEJAPAN).format("HH:mm")}~${dayjs
                .tz(item?.timeEnd, TIMEJAPAN)
                .format("HH:mm")}`} */}
              {`${item?.start_time} ~ ${item?.end_time}`}
            </StyleTd>
            <StyleTd width="15%">
              {item?.nursing_care_history?.patient?.family_name} <br />
              {item?.nursing_care_history?.patient?.name_kana}
            </StyleTd>
            <StyleTd>
              {item?.nursing_care_history &&
                getTypeCarePlanJP(item?.scheduleable?.care_plan_type)}
            </StyleTd>
            <StyleTd>{item?.nursing_care_history && "35.5"}</StyleTd>
            <StyleTd>{item?.nursing_care_history && "125/80"}</StyleTd>
            <StyleTd>
              {item?.nursing_care_history &&
                `${item?.nursing_care_history?.urination} 回`}
            </StyleTd>
            <StyleTd>
              {item?.nursing_care_history &&
                `${item?.nursing_care_history?.defecation} 回`}
            </StyleTd>
            <StyleTd>
              {item?.nursing_care_history &&
                `${item?.nursing_care_history?.hydration} cc`}
            </StyleTd>
            <StyleTd>
              {item?.nursing_care_history &&
                item?.nursing_care_history?.full_body_bath}
            </StyleTd>
            <StyleTd whiteSpace="nowrap" w="11%">
              {item?.nursing_care_history &&
                `${item?.nursing_care_history?.nurse?.family_name}`}
            </StyleTd>
            {item?.nursing_care_history ? (
              <StyleTd py={1}>
                <div className="flex flex-col items-center text-xs">
                  <ButtonOutline
                    onClick={() =>
                      navigate({
                        pathname:
                          user?.role === "admin"
                            ? `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${ROUTES.CREATE_RECORD_DAILY}/${item?.scheduleable?.patient_id}`
                            : `/${ROUTES.STAFF}/${ROUTES.RECORDHISTORYDAILY}/${item?.scheduleable?.patient_id}`,
                        search: `?schedule_date_id=${item?.id}&date=${item?.date}&start_time=${item?.start_time}&end_time=${item?.end_time}&type=edit&patient=${item?.scheduleable?.patient?.family_name}`,
                      })
                    }
                  >
                    詳細
                  </ButtonOutline>
                  {/* <Text whiteSpace='nowrap' fontSize='10px' className='mt-3' >登録：{item?.register}</Text> */}
                </div>
              </StyleTd>
            ) : (
              <StyleTd pt={0}>
                <div className="flex flex-col items-center">
                  <ButtonSolid
                    onClick={() =>
                      navigate({
                        pathname:
                          user?.role === "admin"
                            ? `/${ROUTES.ADMIN}/${ROUTES.NURSING_CARE}/${ROUTES.CREATE_RECORD_DAILY}/${item?.scheduleable?.patient_id}`
                            : `/${ROUTES.STAFF}/${ROUTES.RECORDHISTORYDAILY}/${item?.scheduleable?.patient_id}`,
                        search: `?schedule_date_id=${item?.id}&date=${item?.date}&start_time=${item?.start_time}&end_time=${item?.end_time}&staff_id=${user.id}&type=create&scheduleable=${item?.scheduleable.id}&division=${item?.scheduleable?.care_plan_type}&patient=${item?.scheduleable?.patient?.family_name}`,
                      })
                    }
                    className="text-xs mb-2"
                    width="62px"
                    height="23px"
                  >
                    登録
                  </ButtonSolid>
                </div>
              </StyleTd>
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default TableCustomerName;
