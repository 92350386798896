import { Text } from '@chakra-ui/react'
import React from 'react'
import Sheet2Checkbox from './Sheet2Checkbox'
import RadioSheet2Form from './RadioSheet2Form'
import { OPTIONS, SelectOptions } from 'page/Customer/CustomerDetail/AssesmentSheet2/function'

const Sheet2MealForm = ({ disable }: any) => {
  return (
    <div>
      <Text fontSize='14px'>◯食事</Text>
      {/* <Sheet2Checkbox disable={disable} title="形態・主食" sign="※" col={4} nameText="staple_food_option"
        content={["常食", "粥食", "軟飯"]} name={["regulaDiet", "porridge", "softRice"]} />

      <Sheet2Checkbox disable={disable} title="形態・副食" sign="※" col={4}
        nameText="sideDish"
        content={["常食", "きざみ", "ミキサー", "一口大"]} name={["dietSideDish", "incision", "mixer", "biteSize"]}
      /> */}

      <SelectOptions readOnly data={OPTIONS.staple_food_option} />
      <SelectOptions readOnly data={OPTIONS.side_meal_option} />

      <RadioSheet2Form disable={disable} title="形態・トロミ" sign="※" rdTit={["あり", "なし"]} valueAll={[true, false]} name="is_toromi" col={4}
        nameText="toromi"
      />

      <RadioSheet2Form disable={disable} title="介助" sign="※" valueAll={["independence", "supervision", "partial_assistance", "full_assistance"]}
        rdTit={["自立", "見守り", "一部介助", "全介助"]} name="support_level" col={4}
        nameText="support"
      />

      {/* <Sheet2Checkbox disable={disable} title="道具" sign="※" col={4}
        nameText="sideDish"
        content={["箸", "スプーン", "フォーク"]} name={["chopstick", "spoon", "fork", "allUtensil"]} />
      <Sheet2Checkbox disable={disable} title="嗜好品" sign="※" col={4}
        nameText="sideDish"
        content={["あり(タバコ)", "あり(酒)", "なし"]} name={["tobacco", "liquor", "none"]} /> */}

      <SelectOptions readOnly data={OPTIONS.eating_utensil_option} />
      <SelectOptions readOnly data={OPTIONS.luxury_goods_option} />

      <RadioSheet2Form disable={disable} valueAll={[true, false]}
        title="アレルギー" sign="※" rdTit={["あり", "なし"]} name="is_allergic" col={4}
        nameText="allergic"
      />
    </div>
  )
}
export default Sheet2MealForm
