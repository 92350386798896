import { Button } from '@chakra-ui/button'
import styled from '@emotion/styled'
import LogoLogin from 'assets/logo.png'
import { StyleInput } from 'component/StyledComponent'
import { useNavigate } from 'react-router-dom'

const LoginForm = ({ username, password, onChangeValue, url }: any) => {
  const navigate = useNavigate()
  return (
    <Card  >
      <CardItem>
        <img src={LogoLogin} alt="" className="mb-4 mt-9" width='407px' height='131px' />
        <StyleInput className="my-3 " mt='64px'
          style={{ width: '250px', height:'40px', border:'1px solid #abaaaa' }} fontSize='15px'
          type="text" name="username" value={username} placeholder="ID" onChange={onChangeValue} />
        <br />
        <StyleInput className="my-3 "
          style={{ width: '250px', height:'40px', border:'1px solid #abaaaa' }} fontSize='15px'
          type="password" name="password" value={password} placeholder="パスワード" onChange={onChangeValue} />
        <br />
        <Button onClick={() => navigate(url)}
        variant="outline" width='119px' height='33px' style={{ border: '1px solid #707070', fontSize: '15px', marginTop: "58px", borderRadius:'0' }} >
          ログイン
        </Button>
      </CardItem>
    </Card>
  )
}

const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5% 20%'
})

const CardItem = styled.div({
  background: '#f5f4f2',
  padding: '75px',
  marginTop:'60px',
  borderRadius:'12px'
})

export default LoginForm
