import ROUTES from "constant/routes";
import { Room } from "page/homeSystem/Dashboard/_type";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import noticeSlice from "store/slice/noticeSlice";

const useContact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId } = useSelector((state: RootState) => state.user);

  const onContact = (
    e: Room | null,
    target?: { title: string; id: number }
  ) => {
    const _targetName = e
      ? (e.source_id === userId ? e.target.user_name : e.source.user_name) || ""
      : target?.title || "";

    navigate(
      `/${ROUTES.ADMIN}/${ROUTES.CONTACT_CHAT.replace(
        ":id",
        (e?.id || target?.id || "").toString()
      )}`,
      {
        state: {
          title: _targetName || target?.title,
        },
      }
    );
    dispatch(noticeSlice?.actions?.setTitle(_targetName));
  };
  return { onContact };
};

export default useContact;
