import { useEffect, useRef, useState, Fragment } from 'react'
import { Box, Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import { Form, Select, Input, Button, Space, DatePicker, InputNumber } from "antd";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputCustom from "component/admin/input";
import { FlexRow, StyleTd } from 'component/StyledComponent'
import ModalCustom from 'component/admin/modal';
import { Styles, StyleForm, StyleContainer } from './styled'
import { ButtonOutline, ButtonSolid } from 'component/button';
import axiosClient from 'api/axiosClient';
import useService from 'hook/useService';
import { DATE_FORMAT, DATE_FORMAT_2, MONTH_FORMAT } from 'constant/date';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

const { Option } = Select;

let dataFake = [
  {
    id: '1',
    name: '体温',
    service: "body_temperature",
    infos: [
      {
        day: 1,
        result: ['36.4', '36.2']
      },
      {
        day: 7,
        result: ['31', '32']
      },
      {
        day: 10,
        result: ['36.4', '36.2']
      }
    ],
    max: 2
  },
  {
    id: '2',
    name: '血圧',
    service: 'blood_pressure',
    infos: [
      {
        day: 1,
        result: ['116 60', '125 78']
      }
    ],
    max: 2
  },
  {
    id: '3',
    name: '脈拍',
    service: 'pulse',
    infos: [
      {
        day: 1,
        result: ['64', '60']
      }
    ],
    max: 2
  },
  {
    id: '4',
    name: '酸素',
    service: 'oxygen',
    infos: [
      {
        day: 1,
        result: ['99', '']
      }
    ],
    max: 2
  },
  {
    id: '5',
    name: '入浴',
    service: 'bathing',
    infos: [
      {
        day: 1,
        result: ['スタッフ']
      },
    ],
    max: 1,
  },
  {
    id: '6',
    name: '清拭',
    infos: [
    ],
    service: 'wiping',
    max: 1
  },
];

interface ITableProps {
  classNameTable?: string;
  classNameTd?: string;
  classNameContainer?: string;
  classNameBoxTitle?: string;
  classNameShift?: string;
  classNameBoxEmpty?: string;
  dataMonth: any;
  setPatientInfo: any;
}

const Tab2 = ({
  classNameTable = "",
  classNameTd = "",
  classNameContainer = "",
  classNameBoxTitle = "",
  classNameShift = "",
  classNameBoxEmpty = "",
  dataMonth = null,
  setPatientInfo = null,
}: ITableProps) => {
  const location = useLocation();
  const [indexD, setIndexD] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [pationOptions, setPatientOptions] = useState<any[]>([]);
  const [namePatient, setNamePatient] = useState(location.pathname.includes("preview") ? localStorage.getItem("patientName") : "");
  const [isHiddenFirstDatePicker, setIsHiddenFirstDatePicker] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [idPatient, setIdPatient] = useState('');
  const [firstPatient, setFirstPatient] = useState<any>(null);
  const [selected, setSelected] = useState<any>(null);

  const [form] = Form.useForm();
  const service = useService();
  const { id } = useParams();
  const SERVICE_NAME = location.pathname.includes("home-system-admin") ? service.HS_ADMIN : service.HS_STAFF;
  //dataFake = dataFake.map((dt, index) => {
  //  let length = 0;
  //  dt.infos.map((info, indexInfo) => {
  //    for (let i = 0; i < info.result.length; i++) {
  //      if (info.result.length > length) {
  //        length = info.result.length;
  //      }
  //    }
  //  })
  //  return { ...dt, max: length }
  //});

  const escapedNewLineToLineBreakTag = (str: string) => str.split(' ').map((item, index) => (index === 0) ? item : [<br key={index} />, item])

  const renderDay = (data: any, max: number, ind: number) => {
    const rows: any[] = []; // Create an array to store list of tr

    for (let i = 0; i < max; i++) {
      rows.push(
        <Tr className={`custom-tr ${max === 1 ? "custom-tr1" : "custom-tr2"}`}><Td className={`${ind + 1 === indexD ? 'bg-[#000AFF] !border-none' : ''} custom-td`}>{data[i] ? escapedNewLineToLineBreakTag(data[i]) : ''}</Td></Tr>
      );
    }
    return rows;
  }

  const getResultByDay = (data: any, day: number) => {
    let filterDay = data.filter((info: { day: number; }) => info.day === day);
    if (filterDay === undefined || filterDay.length === 0) {
      return [];
    }
    return filterDay[0].result;
  }

  const handleOnClickByDay = (indexDay: any) => {
    setIndexD(indexDay + 1);
    setOpenPopup(true);

    let dataByDay: any = null;
    dataSource?.map(item => {
      dataByDay = item?.infos?.filter((info: any) => info.day === indexDay + 1)
     
    });
    const dayItem = `${dataMonth.format(MONTH_FORMAT)}/${indexDay + 1}`
    form.setFieldValue('dates', [moment(`${dayItem}`)])
    setIsHiddenFirstDatePicker(true);

    if(!dataByDay[0]) {
      //form.resetFields();
      return;
    } else {
      form.setFieldsValue(dataByDay[0]);
      //form.setFieldValue('dates', [moment(`${dayItem}`)])
      //if(dataByDay[0]?.date) {
      //  setIsHiddenFirstDatePicker(true);
      //  form.setFieldValue('dates', [moment(`${dayItem}`)])
      //}
      
    }
  }

  const handleCancelPopup = () => {
    setOpenPopup(false);
    setIndexD(0);
    setIsHiddenFirstDatePicker(false);
    form.resetFields();
  }

  const onFinish = async (values: any) => {
    //if (!id) return;
    const datesFormat = values?.dates?.map((date: any) => {
      return date.format(DATE_FORMAT_2)
    })
    let idPatientSendToBE = "";
    if(idPatient) {
      idPatientSendToBE = idPatient
    } else if(id) {
      idPatientSendToBE = id;
    } else if(firstPatient) {
      idPatientSendToBE = firstPatient?.value
    }
    const data = {
      ...values,
      dates: datesFormat,
      patient_id: idPatientSendToBE,
      year_month: dataMonth.format(MONTH_FORMAT)
    }
  
    try {
      const res = await axiosClient({
        method: "post",
        url: `${SERVICE_NAME}/daily_healths`,
        data: data,
      });
      toast.success(res.data.success);
      form.resetFields();
      setOpenPopup(false);
      fetchDailyHealths(idPatientSendToBE);
      setIndexD(0);
      //fetchPatientOptions();
    } catch (error) {
      toast.error((error as string).toString());
      console.error(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleChangePatient = (value: string) => {
    if (!value) return;
    fetchDailyHealths(value);
    setIdPatient(value)
    setFirstPatient(null);
    setSelected(value);
    localStorage.setItem("patientChangeID", value);
    setNamePatient(pationOptions?.filter(item => item.value === value)[0]?.label)
    setPatientInfo(pationOptions?.filter(item => item.value === value)[0])
  };

  const fetchPatientOptions = async () => {
    try {
      const res = await axiosClient({
        method: "get",
        url: `${SERVICE_NAME}/patients`,
        params: {
          year_month: dataMonth.format(MONTH_FORMAT),
        },
      });
      const options = res?.data?.data?.map((item: any) => {
        return { label: item?.family_name, value: item?.id }
      })
      setPatientOptions(options);
      if(options[0] && !id) {
        setFirstPatient(options[0]);
        fetchDailyHealths(options[0].value);
        setNamePatient(options[0].label);
        setPatientInfo(options[0]);
        setSelected(options[0]?.value);
      } else if(options[0] && id) {
        const optionHasData = options?.filter((item: { value: string; }) => item.value == id)
        setSelected(optionHasData?.length > 0 ? parseInt(id) : null);
      } else if(!options[0] && !id) {
        setDataSource([]);
        setNamePatient("");
        setPatientInfo(null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchDailyHealths = async (idParam: any) => {
    if (!idParam) return;
    try {
      const res = await axiosClient({
        method: "get",
        url: `${SERVICE_NAME}/daily_healths`,
        params: {
          patient_id: idParam,
          year_month: dataMonth.format(MONTH_FORMAT),
        },
      });
      const dataApi = res.data.data;
      let arrData = [];

      for (let i = 0; i < dataFake?.length; i++) {
        if (dataFake[i].service === "body_temperature") {
          let infosData = dataApi?.map((item: any) => {
            return {
              day: new Date(item?.date)?.getDate(), result: [item?.body_temperature_first, item?.body_temperature_second
              ], ...item
            }
          })
          arrData.push({ ...dataFake[i], infos: infosData })
        }

        if (dataFake[i].service === "blood_pressure") {
          let infosData = dataApi?.map((item: any) => {
            return {
              day: new Date(item?.date)?.getDate(), result: [`${item?.blood_pressure_first || ''} ${item?.blood_pressure_second || ''}`, `${item?.blood_pressure_third || ''} ${item?.blood_pressure_fourth || ''}`
              ], ...item
            }
          })
          arrData.push({ ...dataFake[i], infos: infosData })
        }

        if (dataFake[i].service === "pulse") {
          let infosData = dataApi?.map((item: any) => {
            return {
              day: new Date(item?.date)?.getDate(), result: [item?.pulse_first, item?.pulse_second], ...item
            }
          })
          arrData.push({ ...dataFake[i], infos: infosData })
        }

        if (dataFake[i].service === "oxygen") {
          let infosData = dataApi?.map((item: any) => {
            return {
              day: new Date(item?.date)?.getDate(), result: [item?.oxygen_first, item?.oxygen_second], ...item
            }
          })
          arrData.push({ ...dataFake[i], infos: infosData })
        }

        if (dataFake[i].service === "bathing") {
          let infosData = dataApi?.map((item: any) => {
            return {
              day: new Date(item?.date)?.getDate(), result: [item?.bathing], ...item
            }
          })
          arrData.push({ ...dataFake[i], infos: infosData })
        }

        if (dataFake[i].service === "wiping") {
          let infosData = dataApi?.map((item: any) => {
            return {
              day: new Date(item?.date)?.getDate(), result: [item?.wiping], ...item
            }
          })
          arrData.push({ ...dataFake[i], infos: infosData })
        }
      }
      setDataSource(arrData);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(id) {
      fetchDailyHealths(id);
    }
    fetchPatientOptions();
    setIdPatient('');
    setSelected(null);
  }, [dataMonth])

  useEffect(() => {
    document.getElementsByClassName('wrapper2')[0].addEventListener('scroll', () => {
      document.getElementsByClassName('wrapper1')[0].scrollLeft =
        document.getElementsByClassName('wrapper2')[0].scrollLeft;
    })

    document.getElementsByClassName('wrapper1')[0].addEventListener('scroll', () => {
      document.getElementsByClassName('wrapper2')[0].scrollLeft =
        document.getElementsByClassName('wrapper1')[0].scrollLeft;
    })
  }, []);


  return (
    <StyleContainer>
      <div className='flex items-center gap-x-[4px] mb-[12px]'>
        <p className='text-[16px]'>お客様名</p>
        <Select
          style={{ width: 150}}
          onChange={handleChangePatient}
          options={pationOptions || []}
          value={selected}
        />
      </div>
      <FlexRow className='items-center gap-x-[6px]'>
        <div className='w-[35px] whitespace-pre-wrap'>
          {namePatient}
        </div>
        <Styles className='wrapper2'>
          <TableContainer
            className={`${classNameContainer}`}
            zIndex="9999"
            overflowX="unset" overflowY="unset"
          >
            <Table
              className={classNameTable}
              width={`${70 * 23}px`}
              transform="rotateX(180deg)"
            >
              <Thead>
                <Tr height="58px">
                  <Td position="sticky" left={0} zIndex="9" background={'white'}></Td>
                  {[...Array(dataMonth.daysInMonth())].map((item: any, index: any) => (
                    <Td
                      className={`${classNameTd} ${index + 1 === indexD ? 'bg-[#000AFF] text-[#000AFF]' : ''}`}
                      position="relative"
                      zIndex="8"
                      padding="0px"
                      key={index}
                      style={{
                        textAlign: 'center',
                        border: "0px",
                        width: "70px !important",
                        borderLeft: "1px solid #ffffff",
                        borderRight: "1px solid #ffffff",
                      }}
                      fontSize="12px"
                    >
                      {index + 1}
                    </Td>
                  ))}

                </Tr>
              </Thead>
              <Tbody>
                {dataSource?.length > 0 &&
                  dataSource?.map((item: any, index: number) => (
                    <Fragment>
                      <Tr key={index}>
                        <Td position="sticky" left={0} zIndex="9" background={'white'} border='2px solid #ececec'>
                          {item.name}
                        </Td>
                        {
                          [...Array(dataMonth.daysInMonth())].map((day: any, ind: number) => (
                            <Td
                              padding="0px"
                              position="relative"
                              key={ind}
                              style={{
                                border: "2px solid #ececec",
                                width: "70px",
                                cursor: "pointer"
                              }}
                              onClick={() => {handleOnClickByDay(ind)}}
                              className={ind + 1 === indexD ? 'bg-[#000AFF] !border-none' : ''}
                            >
                              {renderDay(getResultByDay(item.infos, ind + 1), item.max, ind)}
                            </Td>
                          ))
                        }
                      </Tr>

                    </Fragment>
                  ))}

              </Tbody>
            </Table>
          </TableContainer>
        </Styles>
      </FlexRow>
      <Box className={`${classNameContainer} wrapper1`} zIndex="9" overflowX="scroll">
        <Table className={classNameTable} width={`${70 * 23}px`}>
          <Tr>
            {[...Array(dataMonth.daysInMonth())].map((item: any, index: any) => (
              <Td></Td>
            ))}
          </Tr>
        </Table>
      </Box>
      <ModalCustom width={370} footer={null} title={'日々の記録'} open={openPopup} onCancel={handleCancelPopup}>
        <StyleForm>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.List name="dates">
              {(fields, { add, remove }) => {
                fields?.unshift({ fieldKey: 0, key: 0, name: 0 });
                return (
                  <div>
                    {fields?.map((field, index) => (
                      <>
                        {index === 0 && !isHiddenFirstDatePicker && (
                          <Form.Item
                            //key={fields[0].key}
                            className='!mb-[0px]'
                          >
                            <div className="flex">
                              <Form.Item rules={[{ required: true, message: "この項目は必須です" }]} {...fields[0]} className='w-[70%] !mb-[12px]'>
                                <DatePicker format={"YYYY-MM-DD"} placeholder='選択してください' className='w-full' />
                              </Form.Item>
                              <div className="icon-container" onClick={() => { add(); setIsHiddenFirstDatePicker(true); add() }}>
                                <PlusOutlined />
                              </div>
                              <div className="icon-container" onClick={() => remove(field.name)}>
                                <MinusOutlined />
                              </div>
                            </div>
                          </Form.Item>
                        )}
                        {index >= 1 && (
                          <Form.Item
                            key={field.key}
                            className='!mb-[0px]'
                          >
                            <div className="flex">
                              <Form.Item rules={[{ required: true, message: "この項目は必須です" }]} {...field} className='w-[70%]'>
                                <DatePicker placeholder='選択してください' className='w-full' />
                              </Form.Item>
                              <div className="icon-container" onClick={() => add()}>
                                <PlusOutlined />
                              </div>
                              <div className="icon-container" onClick={() => remove(field.name)}>
                                <MinusOutlined />
                              </div>
                            </div>
                          </Form.Item>
                        )}
                      </>
                    ))}
                  </div>
                );
              }}
            </Form.List>

            <p>体温</p>
            <div className='flex items-center gap-x-[15px]'>
              <Form.Item className='w-[20%]' name="body_temperature_first" label="">
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
              <Form.Item className='w-[20%]' name="body_temperature_second" label={''}>
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
            </div>

            <p>血圧</p>
            <div className='flex items-center gap-x-[4px]'>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="blood_pressure_first" label="">
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
              <p className='text-[20px] mt-[-20px]'>/</p>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="blood_pressure_second" label={''}>
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
            </div>
            <div className='flex items-center gap-x-[4px]'>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="blood_pressure_third" label="">
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
              <p className='text-[20px] mt-[-20px]'>/</p>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="blood_pressure_fourth" label={''}>
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
            </div>

            <p>脈拍</p>
            <div className='flex items-center gap-x-[15px]'>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="pulse_first" label="">
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="pulse_second" label={''}>
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
            </div>

            <p>酸素飽和度(SpO2)</p>
            <div className='flex items-center gap-x-[15px]'>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="oxygen_first" label="">
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
              <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[20%]' name="oxygen_second" label={''}>
                <InputNumber type='number' style={{ borderRadius: '4px' }} />
              </Form.Item>
            </div>

            <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[43.5%]' name="bathing" label="入浴">
              <InputNumber type='number' style={{ borderRadius: '4px' }} />
            </Form.Item>

            <Form.Item rules={[{ required: false, pattern: new RegExp(/^[0-9]+$/), message: "" }]} className='w-[43.5%]' name="wiping" label="清拭">
              <InputNumber type='number' style={{ borderRadius: '4px' }} />
            </Form.Item>
          </Form>
          <div className='flex justify-end gap-x-[8px] mt-[30px]'>
            <ButtonOutline onClick={handleCancelPopup}>キャンセル</ButtonOutline>
            <ButtonSolid onClick={form.submit} className='!px-[54px]'>保存</ButtonSolid>
          </div>
        </StyleForm>
      </ModalCustom>
    </StyleContainer>
  )
}

export default Tab2
