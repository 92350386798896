import {
  SHIFT_MANAGEMENT_LIST_STAFF,
  GET_ALL_SERVICES,
  GET_ALL_STAFFS,
  GET_ALL_USING_PATIENTS,
  GET_DETAIL_SERVICES,
  REGISTER_SHIFT,
  GET_WEEK_SCHEDULE,
  SHIFT_MANAGEMENT_LIST_DATA_BY_MONTH,
  GET_WEEK_SCHEDULE_STAFF,
  NURSING_SHIFT_MANAGE,
  SCHEDULE_DATES,
  AUTO_SHIFTS,
} from "../apiConstant";
import BaseApi from "api/baseApi";
import store from "store";
import qs from "qs";

interface ScheduleDateParams {
  id: number;
  start_time?: string;
  end_time?: string;
  delete?: boolean;
}

interface ShiftManagementsParams {
  id: number;
  start_time: string;
  end_time: string;
  nurse_id: number;
}

const getCurrentRole = () => store.getState().user?.role;
class ShiftManagementApi extends BaseApi {
  getListStaffTotalTime(query: { month_date: string; tab: string }) {
    const route = `${getCurrentRole()}${SHIFT_MANAGEMENT_LIST_STAFF}?${qs.stringify(
      query
    )}`;
    return this.get(route);
  }
  getListStaffMonthShift(query: { month_date: string; tab: string }) {
    const route = `${getCurrentRole()}${SHIFT_MANAGEMENT_LIST_DATA_BY_MONTH}?${qs.stringify(
      query
    )}`;
    return this.get(route);
  }
  getAllUsingPatients() {
    const route = `${getCurrentRole()}${GET_ALL_USING_PATIENTS}`;
    const data = new FormData();
    data.append("status[]", "using");
    return this.get(route, {}, data);
  }
  getAllServices() {
    const route = `${getCurrentRole()}${GET_ALL_SERVICES}`;
    return this.get(route);
  }
  getDetailServices(id: string) {
    const route = `${getCurrentRole()}${GET_DETAIL_SERVICES}`;
    return this.get(route);
  }
  getAllStaff(query: { only_working_staff: boolean; date?: Date }) {
    const route = `${getCurrentRole()}${GET_ALL_STAFFS}?${qs.stringify(query)}`;
    return this.get(route);
  }
  registerPatientSchedule(body: {
    date: Date;
    start_time: string;
    end_time: string;
    patient_id: string;
    nurse_id: string;
    service_id: string;
    service_type_id: string;
  }) {
    const route = `${getCurrentRole()}${REGISTER_SHIFT}`;
    return this.post(route, body);
  }
  getWeekSchedule(query: {
    week_start_date: string;
    week_end_date: string;
    tab: string;
  }) {
    const route = `${getCurrentRole()}${GET_WEEK_SCHEDULE_STAFF}?${qs.stringify(
      query
    )}`;
    return this.get(route);
  }

  getDaySchedule(query: { specific_date: string; tab: string }) {
    const route = `${getCurrentRole()}${GET_WEEK_SCHEDULE_STAFF}?${qs.stringify(
      query
    )}`;
    return this.get(route);
  }

  saveWeekSchedule(body: { button_type: string }) {
    const route = `${getCurrentRole()}${REGISTER_SHIFT}`;
    return this.put(route, body);
  }

  saveWeekScheduleStaff(body: { button_type: string }) {
    const route = `${getCurrentRole()}${NURSING_SHIFT_MANAGE}/update_shift`;
    return this.put(route, body);
  }

  updateNurseStaffForSchedule(body: {
    nurse_id: number;
    scheduleDateId: number;
  }) {
    const { nurse_id, scheduleDateId } = body;
    const route = `${getCurrentRole()}${SCHEDULE_DATES}/${scheduleDateId}`;
    return this.put(route, { nurse_id });
  }

  updateShiftSchedules(body: {
    button_type: "draft" | "cancel" | "sent";
    schedule_dates?: ScheduleDateParams[];
  }) {
    const route = `${getCurrentRole()}${REGISTER_SHIFT}`;
    return this.put(route, body);
  }

  updateStaffShiftManage(body: {
    button_type: "draft" | "sent" | "cancel";
    shift_managements?: ShiftManagementsParams[];
    schedule_dates?: ScheduleDateParams[];
  }) {
    const route = `${getCurrentRole()}${NURSING_SHIFT_MANAGE}/update_shift`;
    return this.put(route, body);
  }

  autoRegisterShift(body: { year_month: string }) {
    const route = `${getCurrentRole()}${AUTO_SHIFTS}`;
    return this.post(route, body);
  }
}
export default ShiftManagementApi;
