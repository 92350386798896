import { Breadcrumb } from "antd";
import { useMatches } from "react-router-dom";

export default function Breadcrumbs() {
  const match = useMatches();

  let crumb = match
    .filter((item) => (item.handle as any)?.breadcrumb)
    .map((item, index) => ({
      key: index,
      title: (item.handle as any)?.breadcrumb(item.data),
    }));

  return <Breadcrumb separator={"/"} items={crumb} />;
}
