/* eslint-disable no-restricted-globals */
import { Button, Col, Form, Row, Select } from "antd";
import apiFactory from "api";
import Input from "component/admin/input";
import { ButtonOutline, ButtonSolid } from "component/button";
import MESSAGES from "constant/messages";
import ROUTES from "constant/routes";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";

export default function ShiftRegistrationDetail({ difference }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState<{
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    abbreviation: string;
    bgColor: string;
    letterColor: string;
  }>({
    id: "",
    name: "",
    startTime: "",
    endTime: "",
    abbreviation: "",
    bgColor: "",
    letterColor: "",
  });
  const onFinish = async (values: any) => {
    const body = {
      id: values.id,
      name: values.name.trim(),
      start_time: values.time.startTime,
      end_time: values.time.endTime,
      abbreviation: values.abbreviation.trim(),
      background_color: values.bgColor,
      text_color: values.letterColor,
    };
    try {
      let result;
      switch (difference.type) {
        case "add":
          result = await apiFactory.shiftRegistrationApi.create(body);
          break;
        case "edit":
          result = await apiFactory.shiftRegistrationApi.update(body);
          break;
        default:
          break;
      }
      if (result) {
        if (result) {
          toast.success(result.success);
          switch (difference.type) {
            case "add":
              navigate(
                `/${ROUTES.ADMIN}/${ROUTES.MENU_SHIFT_REGISTRATION_LIST}`
              );
              break;
            case "edit":
              navigate(`/${ROUTES.ADMIN}/menu-shift/${id}`);
              break;
            default:
              break;
          }
        }
      }
    } catch (error: any) {
      if (error && error.message && error.message.message === "シフト名が既に存在している。") {
        form.setFields([
          {
            name: "name",
            errors: [error.message.message]
          }
        ]);
      }
    }
  };

  const timeGap = [
    { value: "00:00", label: "00:00" },
    { value: "00:30", label: "00:30" },
    { value: "01:00", label: "01:00" },
    { value: "01:30", label: "01:30" },
    { value: "02:00", label: "02:00" },
    { value: "02:30", label: "02:30" },
    { value: "03:00", label: "03:00" },
    { value: "03:30", label: "03:30" },
    { value: "04:00", label: "04:00" },
    { value: "04:30", label: "04:30" },
    { value: "05:00", label: "05:00" },
    { value: "05:30", label: "05:30" },
    { value: "06:00", label: "06:00" },
    { value: "06:30", label: "06:30" },
    { value: "07:00", label: "07:00" },
    { value: "07:30", label: "07:30" },
    { value: "08:00", label: "08:00" },
    { value: "08:30", label: "08:30" },
    { value: "09:00", label: "09:00" },
    { value: "09:30", label: "09:30" },
    { value: "10:00", label: "10:00" },
    { value: "10:30", label: "10:30" },
    { value: "11:00", label: "11:00" },
    { value: "11:30", label: "11:30" },
    { value: "12:00", label: "12:00" },
    { value: "12:30", label: "12:30" },
    { value: "13:00", label: "13:00" },
    { value: "13:30", label: "13:30" },
    { value: "14:00", label: "14:00" },
    { value: "14:30", label: "14:30" },
    { value: "15:00", label: "15:00" },
    { value: "15:30", label: "15:30" },
    { value: "16:00", label: "16:00" },
    { value: "16:30", label: "16:30" },
    { value: "17:00", label: "17:00" },
    { value: "17:30", label: "17:30" },
    { value: "18:00", label: "18:00" },
    { value: "18:30", label: "18:30" },
    { value: "19:00", label: "19:00" },
    { value: "19:30", label: "19:30" },
    { value: "20:00", label: "20:00" },
    { value: "20:30", label: "20:30" },
    { value: "21:00", label: "21:00" },
    { value: "21:30", label: "21:30" },
    { value: "22:00", label: "22:00" },
    { value: "22:30", label: "22:30" },
    { value: "23:00", label: "23:00" },
    { value: "23:30", label: "23:30" },
    { value: "24:00", label: "24:00" },
  ];

  const colors = [
    { value: "#fff", label: "white" },
    { value: "#eb4034", label: "red" },
    { value: "#f7f71b", label: "yellow" },
    { value: "#1bf738", label: "green" },
    { value: "#1b7af7", label: "blue" },
    { value: "#941bf7", label: "purple" },
    { value: "#f71bb1", label: "pink" },
    // { value: '#000', label: 'black' },
  ];
  const colorMap = new Map(
    colors.map((element) => [element.value, element.label])
  );
  const fetchData = async () => {
    if (id) {
      try {
        const result = await apiFactory.shiftRegistrationApi.getDetail(id);
        if (result) {
          form.setFieldsValue({
            id: result?.id,
            code: result?.shift_code,
            name: result?.name,
            time: {
              startTime: result.start_time,
              endTime: result.end_time,
            },
            abbreviation: result?.abbreviation,
            bgColor: result?.background_color,
            letterColor: result?.text_color,
          });
          setData({
            ...data,
            bgColor: result?.background_color,
            letterColor: result?.text_color,
          });
        }
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
  };
  const TimeSelect = useCallback(
    ({ value, onChange }: any) => {
      return (
        <>
          <Select
            value={value?.startTime}
            style={{ width: 120 }}
            options={timeGap}
            onChange={(e) => {
              onChange({ ...value, startTime: e });
            }}
            disabled={difference.type === "view"}
          />
          <span className="ml-[5px] mr-[5px]">~</span>
          <Select
            style={{ width: 120 }}
            value={value?.endTime}
            options={timeGap}
            onChange={(e) => {
              onChange({ ...value, endTime: e });
            }}
            disabled={difference.type === "view"}
          />
        </>
      );
    },
    [difference]
  );
  useEffect(() => {
    fetchData();
  }, [difference]);

  return (
    <div className="shift-registration-detail">
      <div className="page-title">{`管理メニュー　シフト登録 ${difference?.title}`}</div>
      <div className="page-container">
        {difference.type === "view" && (
          <div className="text-end mb-[30px]">
            <ButtonOutline
              className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px] mr-[8px]"
              onClick={() =>
                navigate(
                  `/${ROUTES.ADMIN}/${ROUTES.MENU_SHIFT_REGISTRATION_LIST}`
                )
              }
            >
              一覧へ戻る
            </ButtonOutline>

            <ButtonSolid
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              onClick={() => navigate(`/admin/menu-shift/edit/${id}`)}
            >
              編集
            </ButtonSolid>
          </div>
        )}
        <Form initialValues={data} onFinish={onFinish} form={form}>
          <Row>
            <Col span={11}>
              <Form.Item label="シフトID" name="id">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="シフト名"
                name="name"
                rules={[
                  { max: 255 },
                  { required: true, message: MESSAGES.requireField },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value !== "" && value.trim() === "") {
                        return Promise.reject("この項目は必須です");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input disabled={difference.type === "view"} maxLength={255} />
              </Form.Item>
              <Form.Item
                label="勤務時間"
                name="time"
                rules={[
                  { required: true, message: MESSAGES.requireField },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value) {
                        if (!value.startTime || !value.endTime) {
                          return Promise.reject(MESSAGES.requireField);
                        }
                        if (value.startTime >= value.endTime) {
                          return Promise.reject(
                            "終了時間は開始時間以降で入力してください"
                          );
                        }
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <TimeSelect />
              </Form.Item>
              <Form.Item
                label="略称"
                name="abbreviation"
                rules={[
                  { max: 10 },
                  { required: true, message: MESSAGES.requireField },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value !== "" && value.trim() === "") {
                        return Promise.reject("この項目は必須です");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input disabled={difference.type === "view"} />
              </Form.Item>
              <Form.Item
                label="背景色"
                name="bgColor"
                rules={[
                  { max: 10 },
                  { required: true, message: MESSAGES.requireField },
                ]}
              >
                <Select
                  style={{ width: 120 }}
                  value={data.bgColor}
                  options={colors}
                  onChange={(e) => setData({ ...data, bgColor: e })}
                  disabled={difference.type === "view"}
                  className={colorMap.get(data.bgColor)}
                />
              </Form.Item>
              <Form.Item
                label="文字色"
                name="letterColor"
                rules={[
                  { max: 10 },
                  { required: true, message: MESSAGES.requireField },
                ]}
              >
                <Select
                  style={{ width: 120 }}
                  value={data.letterColor}
                  options={colors}
                  onChange={(e) => setData({ ...data, letterColor: e })}
                  disabled={difference.type === "view"}
                  className={colorMap.get(data.letterColor)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={13} />
            <Col span={11}>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
                <div className="flex gap-x-[8px]">
                  {difference.type !== "view" && (
                    <ButtonOutline
                      className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                      onClick={() => {
                        if (difference.type === "edit") {
                          navigate(`/admin/menu-shift/${id}`);
                        } else {
                          navigate(
                            `/${ROUTES.ADMIN}/${ROUTES.MENU_SHIFT_REGISTRATION_LIST}`
                          );
                        }
                      }}
                    >
                      キャンセル
                    </ButtonOutline>
                  )}
                  {difference.type !== "view" && (
                    <Button className="save-button" htmlType="submit">
                      保存
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
