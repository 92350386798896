import { Button, ButtonProps } from "@chakra-ui/button";
import { twMerge } from "tailwind-merge";

const ButtonSolid = (props: ButtonProps): JSX.Element => {
  const { className, isLoading, disabled, children, border, ...rest } = props;
  return (
    <Button
      variant="solid"
      colorScheme="brand"
      className={twMerge("rounded hover:text-gray-100", className)}
      _active={{
        bg: "brand.100",
        transform: "scale(0.98)",
        borderColor: "brand.500",
      }}
      h={35}
      color="white"
      fontSize="14px"
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonSolid;
