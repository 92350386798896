import { Text } from "@chakra-ui/react";
import { DatePickerProps } from "antd";
import nursingCareRecordApi from "api/admin/nursingCareRecord";
import {
  FlexRow,
  StyleCheckbox,
  StyleDatePicker,
  StyleSelect,
} from "component/StyledComponent";
import TableSearchForm from "component/form/search/TableSearchForm";
import Header from "component/header/Header";
import PaginationUI from "component/pagination/PaginationUI";
import TableCustomerName from "component/table/TableCustomerName";
import {
  getSortType,
  getTypeCarePlan,
} from "component/table/TableRecordCustomer";
import { fakeData } from "component/table/TableRecordHistoryByDate";
import { DATE_FORMAT, DATE_FORMAT_2 } from "constant/date";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { FORMAT, getCookie } from "utils";
import locale from "antd/es/date-picker/locale/ja_JP";
import { ButtonSolid } from "component/button";
import ROUTES from "constant/routes";

const data = [
  {
    value: "1",
    content: "介護",
  },
  {
    value: "2",
    content: "障害",
  },
];

const HistoryCustomerNameList = () => {
  const [checkValue, setCheckValue] = useState({
    pause: false,
    suspect: false,
  });
  const [searchVal, setSearchVal] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { id } = useParams();
  const onChangeCheckValue = (evt: any) => {
    const name = evt.target.name;
    setCheckValue({ ...checkValue, [name]: evt.target.checked });
  };
  const user = useSelector((state: RootState) => state.user);
  const [value, setValue] = useState("介護");
  const navigate = useNavigate();
  const [total, setTotal] = useState(10);

  const location = useLocation();
  const [headVal, setHeadVal]: any = useState({});
  const idToken = getCookie("access_token");

  const [perPage, setPerPage] = useState(10);

  const getRecordCustomer = useCallback(async () => {
    try {
      const param = {
        patient_id: id,
        sortKey: Object.keys(headVal)[0],
        order: getSortType(Object.values(headVal)[0]),
        start_date: moment(startDate).format(DATE_FORMAT_2),
        end_date: moment(endDate).format(DATE_FORMAT_2),
        care_plan_type: getTypeCarePlan(value),
        tab: "patient",
      };
      const res = await nursingCareRecordApi.getNursingCareHistory(
        idToken,
        param,
        user?.role
      );
      if (res) {
        setDataTable(res.serialized_nursing_care_histories);
        setCurrent(res.page);
        setTotal(res.total_items);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [startDate, endDate, headVal]);

  const [current, setCurrent] = useState(1);

  const [dataTable, setDataTable]: any = useState([]);
  useEffect(() => {
    getRecordCustomer();
  }, [getRecordCustomer]);
  useEffect(() => {
    const dataLocal: any = localStorage.getItem("tableData2");
    if (dataLocal) {
      setDataTable(JSON.parse(dataLocal));
    } else {
      setDataTable(fakeData);
      localStorage.setItem("tableData2", JSON.stringify(fakeData));
    }
  }, []);

  const onChangeDate: DatePickerProps["onChange"] = (date: any) => {
    setStartDate(new Date(date));
  };

  const onChangeDateEnd: DatePickerProps["onChange"] = (date: any) => {
    setEndDate(new Date(date));
  };

  return (
    <div className="page-container bg-[#FFFFFF] h-full">
      <Header title="介護記録・履歴　井上 隆代 様" />
      <div className="mt-[46px]">
        <TableSearchForm
          checkValue={checkValue}
          onChangeCheckValue={onChangeCheckValue}
          searchVal={searchVal}
          setSearchVal={setSearchVal}
        />
      </div>
      <FlexRow className="justify-between mb-4 mt-3 text-xs">
        <FlexRow>
          <StyleDatePicker
            style={{ width: "180px" }}
            onChange={onChangeDate}
            format={DATE_FORMAT}
            locale={locale}
            value={dayjs(startDate)}
          />
          <Text mx="9px" mt="8px" fontSize="xl" fontWeight="bold">
            ~
          </Text>
          <StyleDatePicker
            style={{ width: "180px" }}
            onChange={onChangeDateEnd}
            format={DATE_FORMAT}
            locale={locale}
            value={dayjs(endDate)}
          />
          <StyleSelect
            mx="46px"
            style={{ fontWeight: "normal", borderRadius: "4px" }}
            w="78px"
            h="31px"
            fontSize="12px"
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
          >
            {data?.map((item: any, index: any) => (
              <option key={index} value={item.content}>
                {item.content}
              </option>
            ))}
          </StyleSelect>

          <StyleCheckbox size="md" ml="64px" mb="3px" colorScheme="brand2">
            <p className="text-xs">未記入</p>
          </StyleCheckbox>
        </FlexRow>
      </FlexRow>
      <div>
        <PaginationUI
          current={current}
          setCurrent={setCurrent}
          total={total}
          perPage={perPage}
          setPerPage={setPerPage}
        />
        <TableCustomerName
          dataTable={dataTable}
          date={moment(new Date()).format(FORMAT)}
        />
        <PaginationUI
          current={current}
          setCurrent={setCurrent}
          total={total}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </div>
    </div>
  );
};

export default HistoryCustomerNameList;
