import { useEffect, useRef } from "react";

export const getTypeCarePlanParam = (type: string) => {
  switch (type) {
    case "NursingCarePlan":
      return "normal";
    case "DisabilityCarePlan":
      return "disability";
    default:
      return "normal";
  }
};

export const eatingData = [
  {
    value: "なし",
    content: "なし",
  },
  {
    value: "全部",
    content: "全部",
  },
  {
    value: "一部",
    content: "一部",
  },
];

export const dataNum = [
  {
    value: "0",
    content: "0",
  },
  {
    value: "1",
    content: "1",
  },
  {
    value: "2",
    content: "2",
  },
  {
    value: "3",
    content: "3",
  },
  {
    value: "4",
    content: "4",
  },
  {
    value: "5",
    content: "5",
  },
  {
    value: "6",
    content: "6",
  },
  {
    value: "7",
    content: "7",
  },
  {
    value: "8",
    content: "8",
  },
  {
    value: "9",
    content: "9",
  },
  {
    value: "10",
    content: "10",
  },
];
export const dataNumCC = [
  {
    value: "0",
    content: "0",
  },
  {
    value: "50",
    content: "50",
  },
  {
    value: "100",
    content: "100",
  },
  {
    value: "150",
    content: "150",
  },
  {
    value: "200",
    content: "200",
  },
  {
    value: "250",
    content: "250",
  },
  {
    value: "300",
    content: "300",
  },
  {
    value: "350",
    content: "350",
  },
  {
    value: "400",
    content: "400",
  },
  {
    value: "450",
    content: "450",
  },
  {
    value: "500",
    content: "500",
  },
];

export const bodyBathData = [
  {
    value: "なし",
    content: "なし",
  },
  {
    value: "入浴",
    content: "入浴 ",
  },
  {
    value: "シャワー浴",
    content: "シャワー浴",
  },
];

export function usePreviousValue(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
