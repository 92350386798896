import styled from "@emotion/styled";
import { Pagination, PaginationProps } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import useResponsive from "hook/useResponsive";
import { Text } from "@chakra-ui/react";
import { twMerge } from "tailwind-merge";

interface ICustomPaginationProps extends PaginationProps {
  onPrev?: () => void;
  onNext?: () => void;
  className?: string;
}

const StyledPagination = styled(Pagination)`
  .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-pagination-item {
    border: 1px solid #d9d9d9 !important;
    border-radius: 4px !important;
  }

  .ant-pagination-item-active {
    border: 1px solid #1890ff !important;
  }
`;

const CustomPagination = (props: ICustomPaginationProps) => {
  const { total, current, pageSize, className, onPrev, onNext, ...rest } =
    props;
  const { isMobile } = useResponsive();
  const numberOfPages = Math.ceil(Number(total) / Number(pageSize));

  if (isMobile) {
    return (
      <div
        className={twMerge(
          "flex justify-between px-[20px] text-[14px]",
          className
        )}
      >
        <Text color="#666666">表示アイテム数: {pageSize || ""}</Text>

        <Text color="#666666">
          現在のページ: {current} / {numberOfPages}
        </Text>

        <div className="flex gap-x-[24px]">
          <div
            style={{
              color: current === 1 ? "#b3b3b3" : "black",
            }}
            onClick={onPrev}
          >
            <LeftOutlined />
          </div>

          <div
            style={{
              color: current === numberOfPages ? "#b3b3b3" : "black",
            }}
            onClick={onNext}
          >
            <RightOutlined />
          </div>
        </div>
      </div>
    );
  }

  return (
    <StyledPagination
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      locale={{ items_per_page: `/ page` }}
      total={total}
      current={current}
      pageSize={pageSize}
      {...rest}
    />
  );
};

export default CustomPagination;
