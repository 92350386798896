import { Checkbox, Form, Input, Radio } from "antd";
import MESSAGES from "constant/messages";

interface IOption {
  name: string;
  label: string;
  txtName: string;
  options: {
    label: string;
    value: string | number;
  }[];
  type: "checkbox" | "radio";
}

interface IOptions {
  staple_food: IOption;
  side_dish: IOption;
  thoromi: IOption;
  assistance: IOption;
  tool: IOption;
  hobby_goods: IOption;
  allergy: IOption;
  head: IOption;
  upper_limbs: IOption;
  waist: IOption;
  hip_joint: IOption;
  lower_limbs: IOption;
  eyesight: IOption;
  glasses: IOption;
  hearing: IOption;
  language: IOption;
  morphology: IOption;
  move: IOption;
  standing_position: IOption;
  seat: IOption;
  wash_body: IOption;
  hair_washing: IOption;
  attaching_and_detaching: IOption;
  plastic_surgery: IOption;
  excretion_form: IOption;
  urge_to_urinate: IOption;
  convenience: IOption;
  incontinence: IOption;
  chew: IOption;
  swallowing: IOption;
  denture: IOption;
  understand: IOption;
  expression_of_meaning: IOption;
  understanding: IOption;
  wander: IOption;
  violence: IOption;
  delusion: IOption;
  rejection: IOption;
  treatment: IOption;
  dosage: IOption;
}

export const OPTIONS: IOptions = {
  // Meal
  staple_food: {
    name: "staple_food_opt",
    label: "形態・主食",
    txtName: "staple_food_txt",
    options: [
      { label: "常食", value: "has_regular_staple_food" },
      { label: "粥食", value: "has_porridge_staple_food" },
      { label: "軟飯", value: "has_rice_staple_food" },
    ],
    type: "checkbox",
  },
  side_dish: {
    name: "side_dish_opt",
    label: "形態・副食",
    txtName: "side_dish_txt",
    options: [
      { label: "常食", value: "has_regular_side_meal" },
      { label: "きざみ", value: "has_minced_side_meal" },
      { label: "ミキサー", value: "has_blended_side_meal" },
      { label: "一口大", value: "has_bite_sized_side_meal" },
    ],
    type: "checkbox",
  },
  thoromi: {
    name: "thoromi_opt",
    label: "形態・トロミ",
    txtName: "thoromi_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  assistance: {
    name: "assistance_opt",
    label: "介助",
    txtName: "assistance_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "見守り",
        value: "supervision",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
  tool: {
    name: "tool_opt",
    label: "道具",
    txtName: "tool_txt",
    options: [
      { label: "箸", value: "has_chopsticks" },
      { label: "スプーン", value: "has_spoon" },
      { label: "フォーク", value: "has_fork" },
    ],
    type: "checkbox",
  },
  hobby_goods: {
    name: "hobby_goods_opt",
    label: "嗜好品",
    txtName: "hobby_goods_txt",
    options: [
      { label: "あり(タバコ)", value: "has_tobacco" },
      { label: "あり(酒)", value: "has_alcohol" },
      { label: "なし", value: "has_no_luxury_goods" },
    ],
    type: "checkbox",
  },
  allergy: {
    name: "allergy_opt",
    label: "アレルギー",
    txtName: "allergy_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },

  // Body parts
  head: {
    name: "head_opt",
    label: "首",
    txtName: "head_txt",
    options: [
      { label: "麻痺", value: "has_paralysis_neck" },
      { label: "拘縮", value: "has_contracture_neck" },
      { label: "その他", value: "has_other_neck" },
      { label: "問題なし", value: "has_no_problem_neck" },
    ],
    type: "checkbox",
  },
  upper_limbs: {
    name: "upper_limbs_opt",
    label: "上肢",
    txtName: "upper_limbs_txt",
    options: [
      { label: "麻痺", value: "has_paralysis_upper_limb" },
      { label: "拘縮", value: "has_contracture_upper_limb" },
      { label: "その他", value: "has_other_upper_limb" },
      { label: "問題なし", value: "has_no_problem_upper_limb" },
    ],
    type: "checkbox",
  },
  waist: {
    name: "waist_opt",
    label: "腰",
    txtName: "waist_txt",
    options: [
      { label: "麻痺", value: "has_paralysis_belt" },
      { label: "拘縮", value: "has_contracture_belt" },
      { label: "その他", value: "has_other_belt" },
      { label: "問題なし", value: "has_no_problem_belt" },
    ],
    type: "checkbox",
  },
  hip_joint: {
    name: "hip_joint_opt",
    label: "股関節",
    txtName: "hip_joint_txt",
    options: [
      { label: "麻痺", value: "has_paralysis_hip_joint" },
      { label: "拘縮", value: "has_contracture_hip_joint" },
      { label: "その他", value: "has_other_hip_joint" },
      { label: "問題なし", value: "has_no_problem_hip_joint" },
    ],
    type: "checkbox",
  },
  lower_limbs: {
    name: "lower_limbs_opt",
    label: "下肢",
    txtName: "lower_limbs_txt",
    options: [
      { label: "麻痺", value: "has_paralysis_bottom" },
      { label: "拘縮", value: "has_contracture_bottom" },
      { label: "その他", value: "has_other_bottom" },
      { label: "問題なし", value: "has_no_problem_bottom" },
    ],
    type: "checkbox",
  },
  eyesight: {
    name: "eyesight_opt",
    label: "視力",
    txtName: "eyesight_txt",
    options: [
      { label: "障害あり", value: "with_disability" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  glasses: {
    name: "glasses_opt",
    label: "眼鏡",
    txtName: "glasses_txt",
    options: [
      { label: "障害あり", value: "with_disability" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  hearing: {
    name: "hearing_opt",
    label: "聴力",
    txtName: "hearing_txt",
    options: [
      { label: "障害あり", value: "with_disability" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  language: {
    name: "language_opt",
    label: "言語",
    txtName: "language_txt",
    options: [
      { label: "障害あり", value: "with_disability" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },

  // Mobility
  morphology: {
    name: "morphology_opt",
    label: "形態",
    txtName: "morphology_txt",
    options: [
      { label: "自立", value: "has_independence" },
      { label: "杖", value: "has_cane" },
      { label: "車椅子", value: "has_wheelchair" },
      { label: "歩行器", value: "has_walker" },
      { label: "付添", value: "has_support_person" },
    ],
    type: "checkbox",
  },
  move: {
    name: "move_opt",
    label: "移動",
    txtName: "move_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "見守り",
        value: "supervision",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
  standing_position: {
    name: "standing_position_opt",
    label: "立位",
    txtName: "standing_position_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "見守り",
        value: "supervision",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
  seat: {
    name: "seat_opt",
    label: "座位",
    txtName: "seat_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "見守り",
        value: "supervision",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },

  // Bathing
  wash_body: {
    name: "wash_body_opt",
    label: "洗身",
    txtName: "wash_body_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
  hair_washing: {
    name: "hair_washing_opt",
    label: "洗髪",
    txtName: "hair_washing_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
  attaching_and_detaching: {
    name: "attaching_and_detaching_opt",
    label: "着脱",
    txtName: "attaching_and_detaching_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
  plastic_surgery: {
    name: "plastic_surgery_opt",
    label: "整容",
    txtName: "plastic_surgery_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },

  // Excretion
  excretion_form: {
    name: "excretion_form_opt",
    label: "形態",
    txtName: "excretion_form_txt",
    options: [
      { label: "自立", value: "has_independence_excretory" },
      { label: "リハパン", value: "has_rehab_pant_excretory" },
      { label: "紙オムツ", value: "has_paper_diaper_excretory" },
      { label: "パット", value: "has_pad_excretory" },
    ],
    type: "checkbox",
  },
  urge_to_urinate: {
    name: "urge_to_urinate_opt",
    label: "尿意",
    txtName: "urge_to_urinate_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  convenience: {
    name: "convenience_opt",
    label: "便意",
    txtName: "convenience_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  incontinence: {
    name: "incontinence_opt",
    label: "失禁",
    txtName: "incontinence_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },

  // Oral capacity
  chew: {
    name: "chew_opt",
    label: "咀嚼",
    txtName: "chew_txt",
    options: [
      { label: "できる", value: "can_be" },
      { label: "できない", value: "none" },
    ],
    type: "radio",
  },
  swallowing: {
    name: "swallowing_opt",
    label: "嚥下",
    txtName: "swallowing_txt",
    options: [
      { label: "正常", value: "normal" },
      { label: "困難", value: "difficult" },
    ],
    type: "radio",
  },
  denture: {
    name: "denture_opt",
    label: "義歯",
    txtName: "denture_txt",
    options: [
      { label: "あり(全)", value: "has_full_dentures" },
      { label: "あり(上)", value: "has_upper_dentures" },
      { label: "あり(下)", value: "has_lower_dentures" },
      { label: "あり(一部)", value: "has_partial_dentures" },
      { label: "なし", value: "has_no_dentures" },
    ],
    type: "checkbox",
  },

  // Mental State
  understanding: {
    name: "understanding_opt",
    label: "認知度",
    txtName: "understanding_txt",
    options: [
      { label: "軽度", value: "mild" },
      { label: "中度", value: "moderate" },
      { label: "重度", value: "severe" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  expression_of_meaning: {
    name: "expression_of_meaning_opt",
    label: "意思",
    txtName: "expression_of_meaning_txt",
    options: [
      { label: "可能", value: "possible" },
      { label: "不可能", value: "impossible" },
    ],
    type: "radio",
  },
  understand: {
    name: "understand_opt",
    label: "理解",
    txtName: "understand_txt",
    options: [
      { label: "可能", value: "possible" },
      { label: "不可能", value: "impossible" },
    ],
    type: "radio",
  },
  wander: {
    name: "wander_opt",
    label: "徘徊",
    txtName: "wander_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  violence: {
    name: "violence_opt",
    label: "暴力",
    txtName: "violence_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  delusion: {
    name: "delusion_opt",
    label: "妄",
    txtName: "delusion_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  rejection: {
    name: "rejection_opt",
    label: "拒否",
    txtName: "rejection_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  treatment: {
    name: "treatment_opt",
    label: "処置",
    txtName: "treatment_txt",
    options: [
      { label: "あり", value: "can_be" },
      { label: "なし", value: "none" },
    ],
    type: "radio",
  },
  dosage: {
    name: "dosage_opt",
    label: "投薬",
    txtName: "dosage_txt",
    options: [
      {
        label: "自立",
        value: "independence",
      },
      {
        label: "見守り",
        value: "supervision",
      },
      {
        label: "一部介助",
        value: "partial_assistance",
      },
      {
        label: "全介助",
        value: "full_assistance",
      },
    ],
    type: "radio",
  },
};

export const SelectOptions = ({
  data,
  readOnly = false,
  type = "checkbox",
}: {
  data: IOption;
  readOnly?: boolean;
  type?: "checkbox" | "radio";
}) => (
  <>
    <Form.Item
      label={data.label}
      className="mb-0"
      name={data.name}
      rules={[{ required: true, message: MESSAGES.requireField }]}
    >
      {type === "checkbox" && <Checkbox.Group options={data.options} />}

      {type === "radio" && <Radio.Group options={data.options} />}
    </Form.Item>
    <Form.Item label=" " name={data.txtName}>
      <Input placeholder="特記事項" readOnly={readOnly} />
    </Form.Item>
  </>
);

export const convertArrayToObject = (data: any, reverse = false) => {
  let newData: any = {};

  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key] !== null) {
      if (!reverse)
        newData[key] = data[key].reduce(
          (cur: any, next: string) => ({ ...cur, [next]: true }),
          OPTIONS[key as keyof IOptions]
            ? OPTIONS[key as keyof IOptions].options.reduce(
                (c: any, n: any) => ({ ...c, [n.value]: false }),
                {}
              )
            : {}
        );
      else
        newData[key] = Object.keys(data[key]).filter(
          (subKey) => data[key][subKey]
        );
    } else newData[key] = data[key];
  });

  return newData;
};
