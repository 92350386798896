import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, Radio } from "antd";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import REGEX from "constant/regex";
import InputPassword from "component/admin/input/InputPassword";
import dayjs from "dayjs";
import Styles from "./styled";
import { ButtonOutline, ButtonSolid } from "component/button";
import { IContact, IDistrict, IHospital } from "constant/interfaces";
import MESSAGES from "constant/messages";
import ROUTES from "constant/routes";
import { DATE_FORMAT_2, JP_DATEPICKER_FORMAT } from "constant/date";
import { ClockCircleFilled } from "@ant-design/icons";
import NumericInput from "component/NumberInput";
import { PatternFormat } from "react-number-format";
import SelectWithData from "component/SelectWithData";

const defaultContactRelative: Partial<IContact> = {
  address: "",
  cellphone_number: "",
  telephone_number: "",
  name: "",
  relationship: "",
};

const defaultHospital: Partial<IHospital> = {
  address: "",
  clinical_department: "",
  name: "",
  phone: "",
};

const BasicInfo = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const district = Form.useWatch("district", form);
  const city = Form.useWatch("city", form);
  const [isEdit, setIsEdit] = useState(!Boolean(id));
  const service = useService();
  const navigate = useNavigate();

  const handleSubmit = async (formData: any) => {
    formData.contact_relatives_attributes = formData.contact_relatives;
    formData.hospital_attributes = formData.hospital;
    formData.password = formData.password || undefined;
    delete formData.contact_relatives;
    delete formData.hospital;
    formData.birth_date = formData.birth_date?.format(DATE_FORMAT_2);
    formData.period_contract = formData.period_contract?.format(DATE_FORMAT_2);
    try {
      const res = await axiosClient({
        method: id ? "put" : "post",
        url: `${service.PATIENTS}${id ? `/${id}` : ""}`,
        data: formData,
      });
      toast.success(res.data.success);
      setIsEdit(false);
      !id && navigate(`/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}`);
    } catch (error) {
      toast.error("Error");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const fetchData = async () => {
    try {
      const { data } = await axiosClient({
        method: "get",
        url: `${service.PATIENTS}/${id}`,
      });
      form.setFieldsValue(data);
      data.birth_date &&
        form.setFieldValue("birth_date", dayjs(data.birth_date));
      data.period_contract &&
        form.setFieldValue("period_contract", dayjs(data.period_contract));
    } catch (error) {
      console.error(error);
    }
  };

  const getDistrictOptions = async () =>
    (await axiosClient.get(service.DISTRICTS)).data.districts.map(
      (item: IDistrict) => ({ label: item.name, value: item.name })
    );

  const getCityOptions = async () =>
    (
      await axiosClient.get(service.CITIES, {
        params: { district_name: district },
      })
    ).data.cities.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getAddressOptions = async () =>
    (
      await axiosClient.get(service.ADDRESSES, {
        params: { city_name: city, district_name: district },
      })
    ).data.addresses.map((item: IDistrict) => ({
      label: item.name,
      value: item.name,
    }));

  const getZipCodeByAddress = async (address: string) => {
    if (city && district && address) {
      const { data } = await axiosClient.get(service.POST_CODE, {
        params: {
          district_name: district,
          city_name: city,
          address_name: address,
        },
      });
      form.setFieldValue("zipcode", data.post_code);
    }
  };

  const getAddressByZipCode = async (zipcode: string) => {
    try {
      if (zipcode.replace("_", "").length === 8) {
        const { data } = await axiosClient.get(service.INFO_ADDRESS_BY_CODE, {
          params: {
            code: zipcode,
          },
        });
        form.setFieldValue("district", data.info_address.district.name);
        form.setFieldValue("city", data.info_address.city.name);
        form.setFieldValue("street", data.info_address.address.name);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  return (
    <Styles>
      <Form
        disabled={!isEdit}
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        initialValues={{
          contact_relatives: [defaultContactRelative, defaultContactRelative],
          hospital: defaultHospital,
        }}
        autoComplete="off"
        scrollToFirstError={{ block: "center" }}
      >
        <div className="text-end mb-[30px]">
          <ButtonSolid
            width={137}
            height={35}
            hidden={isEdit}
            fontSize={14}
            onClick={() => setIsEdit(true)}
          >
            編集
          </ButtonSolid>
        </div>
        <div className="max-w-[700px] w-full">
          <Form.Item label="お客様ID" name="patient_code">
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="パスワード"
            name="password"
            rules={[
              { required: !id, message: MESSAGES.requireField },
              {
                pattern: REGEX.passwordStaff,
                min: 8,
                message: MESSAGES.containTextNumber,
              },
            ]}
          >
            <InputPassword
              placeholder="************"
              visibilityToggle={false}
              autoComplete="off"
            />
          </Form.Item>
          <div className="flex gap-x-[16px]">
            <Form.Item
              label="氏名"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: MESSAGES.requireField,
                },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                { max: 50 },
                {
                  pattern: REGEX.onlyText,
                  message: MESSAGES.onlyText,
                },
              ]}
            >
              <Input placeholder="姓" />
            </Form.Item>
            <Form.Item
              label=""
              name="first_name"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },
                { max: 50 },
                {
                  pattern: REGEX.onlyText,
                  message: MESSAGES.onlyText,
                },
              ]}
            >
              <Input placeholder="名" />
            </Form.Item>
          </div>
          <div className="flex gap-x-[16px]">
            <Form.Item
              label="氏名(カナ)"
              name="last_name_kana"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },

                {
                  pattern: REGEX.onlyKatakanaText,
                  message: MESSAGES.invalidKatakanaCharacter,
                },
                { max: 50 },
              ]}
            >
              <Input placeholder="姓(カナ)" />
            </Form.Item>
            <Form.Item
              label=""
              name="first_name_kana"
              rules={[
                { required: true, message: MESSAGES.requireField },
                {
                  pattern: REGEX.atLeastOneNonSpaceCharacter,
                  message: MESSAGES.requireField,
                },

                {
                  pattern: REGEX.onlyKatakanaText,
                  message: MESSAGES.invalidKatakanaCharacter,
                },
                { max: 50 },
              ]}
            >
              <Input placeholder="名(カナ)" />
            </Form.Item>
          </div>
          <Form.Item label="性別" name="sex" initialValue="男">
            <Radio.Group>
              <Radio value="男"> 男</Radio>
              <Radio value="女"> 女 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="生年月日"
            name="birth_date"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <DatePicker
              disabledDate={(date) => date.isAfter(Date.now())}
              format={JP_DATEPICKER_FORMAT}
              className="w-full"
              placeholder="選択してください"
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            label="郵便番号"
            name="zipcode"
            rules={[
              { len: 8 },
              { required: true, message: MESSAGES.requireField },
              {
                pattern: REGEX.zipcode,
                message: MESSAGES.invalidPostCode,
              },
            ]}
          >
            <PatternFormat
              customInput={Input}
              format="###-####"
              mask="_"
              onChange={(e) => getAddressByZipCode(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="district"
            label="都道府県"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <SelectWithData
              onChange={() => {
                form.setFieldValue("city", undefined);
                form.setFieldValue("street", undefined);
              }}
              getOptions={getDistrictOptions}
              placeholder="選択してください"
            />
          </Form.Item>

          <Form.Item
            label="市区町村"
            name="city"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="町名・番地"
            name="street"
            rules={[{ required: true, message: MESSAGES.requireField }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="ビル・マンション名"
            name="building_name"
            rules={[
              { max: 255 },
              // { required: true, message: MESSAGES.requireField },
              // {
              //   pattern: REGEX.atLeastOneNonSpaceCharacter,
              //   message: MESSAGES.requireField,
              // },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="携帯番号1"
            name="telephone_number"
            rules={[
              {
                min: 10,
                max: 20,
                message: MESSAGES.min10max20number,
              },
              { required: true, message: MESSAGES.requireField },
            ]}
          >
            <NumericInput maxLength={20} />
          </Form.Item>
          <Form.Item
            label="携帯番号2"
            name="cellphone_number"
            rules={[
              {
                min: 10,
                max: 20,
                message: MESSAGES.min10max20number,
              },
            ]}
          >
            <NumericInput maxLength={20} />
          </Form.Item>

          <Form.List name="contact_relatives">
            {(fields) =>
              fields.map((field, index) => (
                <React.Fragment key={field.key}>
                  <p className="text-[#000000] text-[16px] leading-[27px] border-b border-solid border-[#000000] w-[101%] mb-4">
                    ■緊急連絡先 {index === 0 ? "①" : "②"}
                  </p>
                  <Form.Item
                    label="氏名"
                    name={[field.name, "name"]}
                    rules={[
                      { max: 50 },
                      {
                        pattern: REGEX.onlyText,
                        message: MESSAGES.onlyText,
                      },
                    ]}
                  >
                    <Input name="name" />
                  </Form.Item>
                  <Form.Item
                    label="続柄"
                    name={[field.name, "relationship"]}
                    rules={[
                      { max: 255 },
                      {
                        pattern: REGEX.onlyText,
                        message: MESSAGES.onlyText,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="住所"
                    name={[field.name, "address"]}
                    rules={[
                      { max: 255 },
                      {
                        pattern: REGEX.address,
                        message: MESSAGES.invalidCharacter,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="電話番号"
                    name={[field.name, "telephone_number"]}
                    rules={[
                      {
                        min: 10,
                        max: 20,
                        message: MESSAGES.min10max20number,
                      },
                      // { required: true, message: MESSAGES.requireField },
                    ]}
                  >
                    <NumericInput maxLength={20} />
                  </Form.Item>
                  <Form.Item
                    label="携帯番号"
                    name={[field.name, "cellphone_number"]}
                    rules={[
                      {
                        min: 10,
                        max: 20,
                        message: MESSAGES.min10max20number,
                      },
                      // { required: true, message: MESSAGES.requireField },
                    ]}
                  >
                    <NumericInput maxLength={20} />
                  </Form.Item>
                </React.Fragment>
              ))
            }
          </Form.List>
          <Form.Item label="利用状況" name="status" initialValue="using">
            <Radio.Group>
              <Radio value="using">利用中</Radio>
              <Radio value="pause">一時停止</Radio>
              <Radio value="suspended">利用停止</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="契約日"
            name="period_contract"
            initialValue={dayjs()}
          >
            <DatePicker
              placeholder="日付を選択"
              format={JP_DATEPICKER_FORMAT}
              allowClear={false}
              className="w-full"
            />
          </Form.Item>
        </div>
        <div className="max-w-[700px] w-full">
          <p className="text-[#000000] text-[16px] leading-[27px] border-b border-solid border-[#000000] w-[101%] mb-4">
            ■病院
          </p>
          <Form.Item hidden name={["hospital", "id"]}>
            <div />
          </Form.Item>
          <Form.Item label="病院名" name={["hospital", "name"]}>
            <Input />
          </Form.Item>
          <Form.Item label="診療科" name={["hospital", "clinical_department"]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="電話番号"
            name={["hospital", "phone"]}
            rules={[
              {
                min: 10,
                max: 20,
                message: MESSAGES.min10max20number,
              },
              // { required: true, message: MESSAGES.requireField },
            ]}
          >
            <NumericInput maxLength={20} />
          </Form.Item>
          <Form.Item label="住所" name={["hospital", "address"]}>
            <Input autoComplete="new-password" />
          </Form.Item>
          {/* <Form.Item label="現病・既往歴" name="current_illness_history">
            <Input />
          </Form.Item>
          <Form.Item label="ご家族聞き取り" name="family_interview">
            <Input />
          </Form.Item>
          <Form.Item label="ご本人聞き取り" name={"personal_interview"}>
            <Input />
          </Form.Item> */}
        </div>
      </Form>
      <div className="flex items-center justify-between">
        <div>
          <Link
            to={`${ROUTES.EDIT_HISTORY}?changeable_type=Patient&changeable_id=${id}`}
            hidden={!id}
          >
            <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]">
              <ClockCircleFilled />
              <span>履歴一覧</span>
            </div>
          </Link>
        </div>
        <div className="flex gap-x-[8px]" hidden={!isEdit}>
          <ButtonOutline
            onClick={() => {
              setIsEdit(false);
              fetchData();
              !id && navigate(-1);
            }}
            className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
          >
            キャンセル
          </ButtonOutline>
          <ButtonSolid
            onClick={form.submit}
            className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
          >
            保存
          </ButtonSolid>
        </div>
      </div>
    </Styles>
  );
};

export default BasicInfo;
