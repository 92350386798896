import { useState } from "react";
import { Table, Pagination, Form } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { FormInstance, PaginationProps } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { ButtonOutline } from "component/button";
import clsx from "clsx";
import "./style.scss";
import { useWatch } from "antd/es/form/Form";
import Styles, { StylesPagination } from "./styled";

// Custom pagination component
interface IPaginationProps extends PaginationProps {
  total?: number;
  onChange?: any;
  current?: number;
  perPage?: number;
}
const MyPagination = ({
  total,
  onChange,
  current,
  perPage,
  ...props
}: IPaginationProps) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined className="iconArrow" />;
    }
    if (type === "next") {
      return <RightOutlined className="iconArrow" />;
    }
    return originalElement;
  };
  return (
    <StylesPagination>
      <Pagination
        onChange={onChange}
        total={total}
        defaultCurrent={current}
        itemRender={itemRender}
        // showQuickJumper
        pageSizeOptions={[5, 10, 20, 50, 100]}
        pageSize={perPage}
        locale={{ jump_to: "件表示", page: "", items_per_page: "" }}
        {...props}
      />
    </StylesPagination>
  );
};

interface ITableProps extends TableProps<any> {
  data: any;
  isShowPagination?: boolean;
  className?: string;
  rowSelection?: any;
  pageSize?: number;
  form?: FormInstance;
  total?: number;
}
export default function EnhanceTable({
  data = [],
  onChange,
  form,
  className = "",
  total = 0,
  ...props
}: ITableProps) {
  const page = useWatch("page", form);
  const per = useWatch("per", form);
  const handleChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    form?.setFieldValue(
      "page",
      extra.action === "paginate" && page !== pagination.current
        ? pagination.current
        : 1
    );
    form?.setFieldValue("per", pagination.pageSize);
    form?.setFieldValue(
      "sortKey",
      (sorter as any).order ? (sorter as any).field : undefined
    );
    form?.setFieldValue("order", (sorter as any).order);
    form?.submit();
    onChange?.(pagination, filters, sorter, extra);
  };

  return (
    <Styles>
      <Table
        rowKey={(row) => row.id?.toString?.()}
        showSorterTooltip={false}
        scroll={{ x: true }}
        className={`my-[29px] with-color-row ${className} `}
        bordered
        dataSource={data}
        onChange={handleChange}
        pagination={{
          position: ["bottomRight", "topRight"],
          showSizeChanger: true,
          showTotal: () => (
            <div>
              {(page - 1) * per + 1} ~ {page * per > total ? total : page * per}
              件(全{total}件中)
            </div>
          ),
          showQuickJumper: true,
          total,
          pageSize: per,
          current: page,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        {...props}
      />
      {form && (
        <>
          <Form.Item hidden name="sortKey" children={<div />} />
          <Form.Item hidden initialValue={1} name="page" children={<div />} />
          <Form.Item hidden initialValue={10} name="per" children={<div />} />
          <Form.Item hidden name="order" children={<div />} />
        </>
      )}
    </Styles>
  );
}
export { MyPagination };
