// design https://docs.google.com/spreadsheets/d/1SRBKSoW9Bkghyv_CeqmXIa4NU0JZLfAP/edit#gid=2137926933
import { FaEquals, FaMinus, FaPlus } from "react-icons/fa";
import Styles from "./styled";
import { formatJapanDate } from "constant/date";
import dayjs from "dayjs";

interface IProps {
  type: "nursing_care" | "disability";
  data: any;
}

export default function BillingPDF({ type, data }: IProps) {
  if (data)
    return (
      <Styles className="w-[848px] h-[1121px] p-[10px_30px]">
        <div className="text-right">発行日：{formatJapanDate(Date())}</div>
        <div className="flex mb-[30px]">
          <div className="w-full pl-[70px]">
            <p>〒463-0067</p>
            <p>愛知県名古屋市守山区守山1-10-16</p>
          </div>
          <div className="w-full">
            <p>〒463-0067</p>
            <p>愛知県名古屋市守山区天子田四丁目1302番地</p>
          </div>
        </div>
        <div className="flex">
          <div className="w-full pl-[70px]">
            <p className="font-bold text-[18px]">{data.patient_name}　様</p>
          </div>
          <div className="w-full">
            <p>&nbsp;</p>
            <p>訪問介護まるくる</p>
            <p>TEL 052-799-8443</p>
          </div>
        </div>
        <div className="flex justify-center my-[10px]">
          <div className="border-4 border-solid border-black text-[24px] p-1 font-bold bg-[lightgray]">
            利用料請求書
          </div>
        </div>
        <div className="flex my-[10px]">
          <div className="w-full pl-[70px] text-[18px]">
            <b>ご利用者</b>
            <p>{data.patient_name}　様分</p>
          </div>
          <div className="w-full">
            <div className="border-solid border-2 border-black flex justify-between px-1 font-bold text-[18px]">
              <p>請求年月</p>
              <p>{formatJapanDate(data.date, { day: undefined })}</p>
            </div>
            <div className="border-solid border-2 border-black flex justify-between mt-[-2px] px-1 font-bold text-[18px]">
              <p>御請求金額（税込）</p>
              <p>{(+data.row_9).toLocaleString()}円</p>
            </div>
            <div className="border-solid border-2 border-black flex justify-between mt-[10px]">
              <div className="text-right font-bold pl-1 w-[10%] bg-[lightgray]">
                10
              </div>
              <div
                className="font-bold bg-[lightgray] w-[20%]"
                style={{ borderRight: "2px solid" }}
              >
                %対象
              </div>
              <div className="text-right w-[40%]">0 円 (消費税 </div>
              <div className="text-right w-[30%]">0 円) </div>
            </div>
            <div className="border-solid border-2 border-black flex justify-between mt-[-2px]">
              <div className="text-right font-bold pl-1 w-[10%] bg-[lightgray]">
                8
              </div>
              <div
                className="font-bold bg-[lightgray] w-[20%]"
                style={{ borderRight: "2px solid" }}
              >
                %対象☆
              </div>
              <div className="text-right w-[40%]">0 円 (消費税 </div>
              <div className="text-right w-[30%]">0 円) </div>
            </div>
            <div> ☆ 軽減税率</div>
            <div className="border border-solid border-black mt-3 h-[100px]"></div>
          </div>
        </div>
        <div className="flex justify-end whitespace-nowrap">
          <div className="border border-solid border-black text-center w-full">
            <b>費用総額 (保険)</b>
            <div>{(+data.row_1).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaMinus />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>{type === "nursing_care" ? "介護保険給付額" : "介護給付額"}</b>
            <div>{(+data.row_7).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaMinus />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>公費負担額</b>
            <div>{(+data.payment_amount).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaMinus />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>助成負担額</b>
            <div>{(+data.subsidy_amount).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaEquals />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>利用者負担額 (保険内)</b>
            <div>{(+data.user_burden).toLocaleString()}円</div>
          </div>
        </div>
        <div className="flex justify-end mt-2 whitespace-nowrap">
          <div className="border border-solid border-black text-center w-full">
            <b>利用者負担額 (保険内)</b>
            <div>{(+data.user_burden).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaPlus />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>限度額超過負担分</b>
            <div>{(+data.exceeding_amount).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaPlus />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>その他利用者負担額※</b>
            <div>{(+data.other_charge).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaMinus />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>請求済金額</b>
            <div>{(+data.billing_amount).toLocaleString()}円</div>
          </div>
          <div className="text-[30px] flex items-center">
            <FaEquals />
          </div>
          <div className="border border-solid border-black text-center w-full">
            <b>今回ご請求分</b>
            <div>{(+data.row_9).toLocaleString()}円</div>
          </div>
        </div>
        <table className="mt-2 w-full">
          <thead>
            <tr>
              <th className="!font-bold">項目名</th>
              <th className="!font-bold">単価</th>
              <th className="!font-bold">数量</th>
              <th className="!font-bold">単位数</th>
              <th className="!font-bold">内訳</th>
              <th className="!font-bold">金額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width={300}>
                保険内サービス(
                {formatJapanDate(dayjs(data.date).format("YYYY/MM"), {
                  day: undefined,
                })}
                )
              </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">{(+data.row_9).toLocaleString()}円</td>
            </tr>
            <tr>
              <td width={300}>　費用総額(保険内)</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">{(+data.row_1).toLocaleString()}円</td>
              <td align="right"></td>
            </tr>
            {Array(...data.bill_detail).map((i, index) => (
              <tr key={index}>
                <td width={300}>　　{i.service_name}</td>
                <td align="right">{i.unit_price}</td>
                <td align="right">{i.quantity}</td>
                <td align="right" className="max-w-[200px]">
                  {(+i.subtotal).toLocaleString()}
                </td>
                <td align="right"></td>
                <td align="right"></td>
              </tr>
            ))}
            {Array(...data.treatments).map((i, index) => (
              <tr key={index}>
                <td width={300}>　　{i.service_name}</td>
                <td align="right">{i.unit_price}</td>
                <td align="right">{i.quantity}</td>
                <td align="right" className="max-w-[200px]">
                  {(+i.subtotal).toLocaleString()}
                </td>
                <td align="right"></td>
                <td align="right"></td>
              </tr>
            ))}
            <tr>
              <td width={300}>　介護保険給付額</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">▲{(+data.row_7).toLocaleString()}円</td>
              <td align="right"></td>
            </tr>
            <tr hidden={+data.payment_amount === 0}>
              <td width={300}>　公費負担額</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">
                ▲{(+data.payment_amount).toLocaleString()}円
              </td>
              <td align="right"></td>
            </tr>
            <tr hidden={+data.subsidy_amount === 0}>
              <td width={300}>　助成負担額</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">
                ▲{(+data.subsidy_amount).toLocaleString()}円
              </td>
              <td align="right"></td>
            </tr>
            <tr hidden={+data.exceeding_amount === 0}>
              <td width={300}>　限度額超過負担分</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">
                {(+data.exceeding_amount).toLocaleString()}円
              </td>
              <td align="right"></td>
            </tr>
            <tr hidden={+data.other_charge === 0}>
              <td width={300}>　その他利用者負担額</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">{(+data.other_charge).toLocaleString()}円</td>
              <td align="right"></td>
            </tr>
            <tr hidden={+data.billing_amount === 0}>
              <td width={300}>　請求済金額</td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right">
                ▲{(+data.billing_amount).toLocaleString()}円
              </td>
              <td align="right"></td>
            </tr>
            <tr>
              <td width={300}>
                　　(※1単位あたり、{(+data.additional).toLocaleString()}円)
              </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
            </tr>
            {/* {8 - data.bill_detail.length - data.treatments.length <= 0
              ? ""
              : [
                  ...Array(
                    8 - data.bill_detail.length - data.treatments.length
                  ),
                ].map((i, index) => (
                  <tr key={index}>
                    <td width={300}>　</td>
                    <td align="right"></td>
                    <td align="right"></td>
                    <td align="right"></td>
                    <td align="right"></td>
                    <td align="right"></td>
                  </tr>
                ))} */}
          </tbody>
        </table>
        <div className="mt-4 font-bold">
          ※　その他利用者負担額(税込)=保険外サービスまたは保険外レンタル+その他費用
        </div>
      </Styles>
    );
  return <></>;
}
