import { createSlice } from "@reduxjs/toolkit";
import { ROLE } from "constant";
import Cookies from "js-cookie";

export interface IUserState {
  code?: string;
  role?: string;
  roleCls?: string;
  id?: number;
  isMessageUnread?: string;
  isNoticeUnread?: string;
  name?: string;
}

const initialState: IUserState = {
  role: undefined,
  id: undefined,
  roleCls: undefined,
  isMessageUnread: undefined,
  isNoticeUnread: undefined,
};

const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    changeRole: (state, action) => {
      state.role = action.payload;
    },
    storeUser: (state, action) => {
      if (action.payload?.role) {
        localStorage.setItem("role", action.payload?.role);
      }
      return action.payload;
    },
    setIdCurrent: (state, action) => {
      state.id = action.payload;
    },
    setRoleCls: (state, action) => {
      state.roleCls = action.payload;
    },
    setIsMessageUnread: (state, action) => {
      state.isMessageUnread = action.payload;
    },
    setIsNoticeUnread: (state, action) => {
      state.isNoticeUnread = action.payload;
    },
    clearUser: () => {
      Cookies.remove("access_token");
      return initialState;
    },
  },
});

export default userSlice;
