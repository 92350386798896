import { ReactNode } from "react";
import Header from "component/header/Header";
import Sidebar from "component/sidebarHomeSystem";
import Breadcrumbs from "component/breadcrumb";

export interface ILayoutProps {
  children?: ReactNode;
  isHeader?: boolean;
  isTabs?: boolean;
  items?: Array<{ key: string; label: ReactNode; children: ReactNode }>;
  title?: ReactNode | string;
  isShowSidebar?: boolean;
  className?: string;
  type?: "staff" | "admin";
}

export default function HomeSystemLayout({
  children,
  isHeader = true,
  isShowSidebar = true,
  title = "",
  className = "",
  type = "staff",
}: ILayoutProps) {
  return (
    <div className="">
      <div className="text-start ">
        <Sidebar
          type={type}
          className={`fixed top-0 z-50 h-[100vh] w-[250px] shadow-right-default ${
            isShowSidebar ? "" : "hidden"
          }`}
        />

        <Header title={title || ""} isMaster={true} isMenu={true} />

        <div
          className={`${
            isShowSidebar ? "w-[calc(100vw-255px)]" : "w-[calc(100vw-5px)]"
          } ${isShowSidebar ? "ml-[250px]" : ""} mt-[50px]`}
        >
          <div
            className={`min-h-[calc(100vh-50px)] bg-light-default flex-[1_1_82%] px-6 pt-4 pb-12 relative ${className}`}
          >
            {isHeader && (
              <div className="flex items-center gap-x-[13px] text-[12px] leading-[20px] relative z-[1]">
                <Breadcrumbs />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
