import { StyleGrid, StyleGridItem } from "component/StyledComponent";
import StyledFormSelect from "./styled";

const FormSelect = ({
  title,
  value,
  changeValue,
  onChange,
  data,
  name,
  disable,
  color = "#555555",
  fontSize = "16px",
  holder = "",
  col = 5,
  className = "w-full",
  unit = "",
}: any) => {
  return (
    <StyleGrid className="grid-cols-1 !gap-y-2 md:grid-cols-4" gap={6}>
      <StyleGridItem colSpan={1}>
        <p className="mt-3" style={{ color: color, fontSize: fontSize }}>
          {title}
        </p>
      </StyleGridItem>
      <StyleGridItem colSpan={col - 1} className="flex items-center gap-3">
        <StyledFormSelect
          disabled={disable}
          value={value}
          size="large"
          className={`${className}`}
          onChange={(value) => {
            changeValue && changeValue(null, name, value);
            onChange && onChange(value);
          }}
          placeholder={holder}
          options={data.map((item: any) => ({
            label: item.content,
            value: item.value,
          }))}
        />
        {unit}
      </StyleGridItem>
    </StyleGrid>
  );
};

export default FormSelect;
