import styled from "@emotion/styled";
import { Tabs } from "antd";

export const StyledBoardTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0;
    width: 80%;

    &:before {
      content: none;
    }

    .ant-tabs-tab {
      border: 1px solid #00000026;
      border-radius: 15px;
      .ant-tabs-tab-btn {
        color: #0000008c;
      }

      &.ant-tabs-tab-active {
        border: 1px solid #3492d7;
        background: #cae9ff;
        .ant-tabs-tab-btn {
          color: #0b3f65 !important;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
  .ant-tabs-content-holder {
  }
`;
