import { Button, Col, Form, Input, Row, Select, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ButtonOutline, ButtonSolid } from "component/button";
import { useCallback, useEffect, useState } from "react";
// import { DetailModal } from "./PostList"
import { RightOutlined } from "@ant-design/icons";
import MESSAGES from "constant/messages";
import apiFactory from "api";
import { toast } from "react-toastify";
import { DetailModal } from "page/admin/Notice/Board/PostList";
import { useNavigate, useParams } from "react-router-dom";
import Header from "component/header/Header";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { RootState } from "store";
import clsx from "clsx";

const CommentDetail = () => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const { postId } = useParams();
  const [part, setPart] = useState("PostList");
  const [detailType, setDetailType] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [patientList, setPatientList] = useState<any>([]);
  const [read, setRead] = useState([]);
  const [unread, setUnread] = useState([]);
  const [comment, setComment] = useState<{
    id: string;
    contributor: string;
    title: string;
    patients: [];
    content: string;
  }>({
    id: "",
    contributor: "",
    title: "",
    patients: [],
    content: "",
  });

  const [data, setData] = useState<{
    user?: string;
    time?: string;
    read?: [];
    content?: string;
    sender_type?: string;
    is_important?: boolean;
    title?: string;
    is_editable?: boolean;
    sender_id?: number;
  }>({});
  const [notificationViewLogs, setNotificationViewLogs] = useState([]);
  
  const parseDate = () => {
    let split_str = data?.title?.split("：")[1];
    let output = split_str?.slice(0, 8);
    return output?.replace("年", "/").replace("月", "");
  };

  const showModal = async (id: any) => {
    setIsModalOpen(true);
    const result = await apiFactory.boardStaffApi.getPostDetail(Number(postId));
    setRead(result.notification_view_logs);
    setIsModalOpen(true);
    setUnread(result.unread_list);
  };

  const removePTags = (str: any) => {
    return str.replace(/<\/?p>/g, "").trim();
  };

  const arrayFromDataContent: any =
    data.content && data.content.split("\n").map(removePTags);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fetchData = async () => {
    const result = await apiFactory.boardStaffApi.getPostDetail(Number(postId));
    if (result) {
      setData({
        user: result?.poster,
        time: result?.created_at,
        read: result?.read_list,
        content: result?.content,
        sender_type: result?.sender_type,
        is_important: result?.is_important,
        title: result?.title,
        is_editable: result?.is_editable,
        sender_id: result?.sender_id,
      });
      setNotificationViewLogs(result?.notification_view_logs);
    }
  };

  const fetchPatientList = async () => {
    const data = await apiFactory.patientStaffApi.getList({});
    if (data) {
      setPatientList(
        data.patients.map((e: any) => ({
          label: e?.family_name,
          value: e.id,
        }))
      );
    }
  };

  const PatientSelection = useCallback(
    ({ value, onChange }: any) => {
      const removeItem = (e: any) => {
        const index = value.findIndex((f: any) => f.value === e);
        value.splice(index, 1);
        const clonePatientList = patientList.map((f: any) => {
          if (f.value === e) {
            f.disabled = false;
          }
          return f;
        });
        setPatientList(clonePatientList);
      };
      return (
        <>
          {!data?.is_editable ? (
            <Select
              disabled
              options={patientList}
              onChange={(e: any) => {
                const patient = patientList.find((f: any) => f.value === e);
                value.push(patient);
                const clonePatientList = patientList.map((f: any) => {
                  if (f.value === e) {
                    f.disabled = true;
                  }
                  return f;
                });
                setPatientList(clonePatientList);
                onChange([...value]);
              }}
              placeholder="選択してください"
            />
          ) : (
            <Select
              options={patientList}
              onChange={(e: any) => {
                const patient = patientList.find((f: any) => f.value === e);
                value.push(patient);
                const clonePatientList = patientList.map((f: any) => {
                  if (f.value === e) {
                    f.disabled = true;
                  }
                  return f;
                });
                setPatientList(clonePatientList);
                onChange([...value]);
              }}
              placeholder="選択してください"
            />
          )}
          <div className="patient-list">
            {value?.map((e: any) => {
              return (
                <Tag
                  color={"green"}
                  closable={true}
                  onClose={() => removeItem(e.value)}
                  style={{ marginRight: 3 }}
                >
                  {e.label}
                </Tag>
              );
            })}
          </div>
        </>
      );
    },
    [patientList]
  );

  const onFinish = async (values: any) => {
    const body = {
      id: values.id,
      reply_to: Number(postId),
      title: values?.title.trim(),
      mentions: values?.patients?.map((e: any) => e.value),
      is_important: values?.important,
      content: values?.content,
      delete: values?.delete,
    };
    try {
      const data = await apiFactory.boardStaffApi.createPost(body);
      if (data) {
        toast.success(data.success);
        setPart("PostList");
        navigate(`/staff/bulletin-board`);
      }
    } catch (error: any) {
      if (typeof error?.message === "string") {
        toast.error(error?.message);
        return;
      }
      let stringError = "";
      Object.entries(error?.message?.errors).map((e: any) => {
        stringError += `${e[0]}: ${e[1]} `;
      });
      toast.error(stringError);
    }
  };
  useEffect(() => {
    form.setFieldValue("contributor", Cookies.get("user_name"));
    form.setFieldValue("title", "Re:" + data?.title);
    fetchData();
    fetchPatientList();
  }, [data?.title]);

  return (
    <div className="page-container text-left">
      <Header title="" isImportant={data?.is_important} content={data?.title} />

      <div className="communication p-[30px]">
        <div className="text-end mt-[19px] mb-[21px] ">
          {!data?.is_editable ||
          (data?.sender_type !== user?.roleCls &&
            Number(data?.sender_id) !== Number(user?.id)) ? (
            <ButtonSolid className="w-[137px] cursor-no-drop" disabled>
              修正
            </ButtonSolid>
          ) : (
            <ButtonSolid
              className="w-[137px]"
              onClick={() =>
                navigate(`/staff/bulletin-board-parent-thread/${postId}`)
              }
            >
              修正
            </ButtonSolid>
          )}
        </div>
      </div>
      <div>
        <div className="mb-[10px]" onClick={() => showModal(postId)}>
          {data?.sender_type === "UserAdmin" ? (
            <span className="text-[18px] font-hiragino">
              <span className="text-[34px]">■</span>
              <b>管理人</b> &nbsp;&nbsp;
              {data.user}
              <b>様 &nbsp;&nbsp;{data.time}</b>&nbsp;&nbsp;
              <span className="text-[15px]">
                既読
                {notificationViewLogs?.length}
                <RightOutlined />
              </span>
            </span>
          ) : (
            <span className="text-[18px] font-hiragino">
              <span className="text-[32px] mt-[3px]">■</span>
              <b>
                {data.user}&nbsp;&nbsp;{data.time}
              </b>
              &nbsp;&nbsp;
              <span className="text-[15px]">
                既読 {notificationViewLogs?.length} <RightOutlined />
              </span>
            </span>
          )}
        </div>
        <div className="border-[1px] border-solid border-black pt-[5px] px-[10px] pb-[40px]">
          {arrayFromDataContent?.map((item: any, index: number) => (
            <p
              onClick={() => {
                index === 2 && item.includes('/staff/month-shift-after') && navigate(`${item.replace('リンク先URL ', '')}`, {
                  state: { dateTime: parseDate() },
                });
              }}
              className={clsx("text-[14px]", {
                "cursor-pointer hover:text-[blue] hover:font-semibold hover:italic": index === 2 && item.includes('/staff/month-shift-after'),
              })}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="mt-[50px] font-bold">
        <span className="text-[34px] ">■</span>スレッドに返信
      </div>
      <div className="pt-[7px] post-detail">
        <Form onFinish={onFinish} form={form} initialValues={comment}>
          <Row>
            <Col span={11}>
              <Form.Item
                label="投稿者"
                name="contributor"
                className="font-bold"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="タイトル"
                name="title"
                className="font-bold"
                rules={[{ required: true, message: MESSAGES.requireField }]}
              >
                {!data?.is_editable ? (
                  <Input
                    readOnly
                    className="bg-[#F5F5F5] cursor-no-drop !border-[#d9d9d9]"
                    style={{ color: "rgba(0, 0, 0, 0.25)" }}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
              <Form.Item
                label="該当お客様"
                name="patients"
                className="font-bold"
              >
                <PatientSelection />
              </Form.Item>

              <Form.Item
                label="内容"
                name="content"
                className="w-[200%] font-bold"
                rules={[{ required: true, message: MESSAGES.requireField }]}
              >
                {!data?.is_editable ? (
                  <TextArea
                    readOnly
                    className="bg-[#F5F5F5] cursor-no-drop !border-[#d9d9d9]"
                    style={{ color: "rgba(0, 0, 0, 0.25)" }}
                  />
                ) : (
                  <TextArea />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={13} />
            <Col span={11}>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
                <div className="flex gap-x-[8px]">
                  <ButtonOutline
                    className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                    onClick={() => navigate("/staff/bulletin-board")}
                  >
                    キャンセル
                  </ButtonOutline>
                  {!data?.is_editable ? (
                    <Button disabled className="save-button cursor-not-drop">
                      保存
                    </Button>
                  ) : (
                    <Button className="save-button" htmlType="submit">
                      保存
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      {DetailModal({ isModalOpen, handleOk, handleCancel, read, unread })}
    </div>
  );
};
export { CommentDetail };
