import { Checkbox, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { CardGray, FlexRow } from 'component/StyledComponent'
import { ButtonOutline } from 'component/button'
import SearchImg from 'assets/search.png'
import React from 'react'

const TableSearchForm = ({checkValue, onChangeCheckValue, setSearchVal, searchVal}: any) => {
  return (
    <CardGray style={{ padding: '16px 26px 19px 26px', fontSize: "12px", background: 'var(--secondary-color)' }} >
      <FlexRow>
        <p className='font-bold' >キーワード</p>

        <Checkbox checked={checkValue.pause} onChange={onChangeCheckValue} mx={5}
          name="pause" colorScheme='brand2' borderColor='black'>
          <p className='font-bold text-xs'>一時停止</p>
        </Checkbox>
        <Checkbox
          checked={checkValue.Suspension} onChange={onChangeCheckValue} mx={5} name="Suspension" colorScheme='brand2' borderColor='black'>
          <p className='font-bold text-xs'>利用停止</p>
        </Checkbox>
      </FlexRow>
      <FlexRow className='mt-2'>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <img src={SearchImg} alt="" style={{ width: '16px', height: '16px', marginBottom: '15px' }} />
          </InputLeftElement>
          <Input type='text' value={searchVal} onChange={(evt) => setSearchVal(evt.target.value)} _placeholder={{ color: 'var(--primary-text-dark)' }}
            placeholder='氏名、カナ' bg='white' height='25px' fontSize='12px' borderRadius='0' marginRight='21px'
            border='1px solid #707070' />

          <ButtonOutline width="150px" height='25px' bg='white' fontSize='12px' >検索</ButtonOutline>
        </InputGroup>

      </FlexRow>


    </CardGray>
  )
}

export default TableSearchForm