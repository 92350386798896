import { Checkbox } from "@chakra-ui/checkbox";
import { Input } from "@chakra-ui/input";
import { Grid, GridItem } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Td, Tr } from "@chakra-ui/table";
import styled from "@emotion/styled";
import { DatePicker } from "antd";
import { Calendar } from "antd";

export const FlexRow = styled.div({
  display: "flex",
  flexDirection: "row",
});

export const Title = styled.div({
  color: "var(--primary-text-light)",
  fontSize: "25px",
  fontWeight: "bolder",
  marginTop: "10px",
});

export const StyleTd = styled(Td)({
  borderRight: "1px solid #e6e6e6",
  textAlign: "center",
  // padding: '8px 16px'
  height: "70px",
  color: "var(--primary-text-dark)",
});

export const StyleTitle = styled.div({
  // borderBottom: "1px solid #000000",
  fontSize: "16px",
  textAlign: "left",
  margin: "10px",
  padding: "10px 0px",
  fontWeight: "bold",
  color: "#000",
  // color: 'var(--header-color)'
});

export const StyleGrid = styled(Grid)({
  padding: "9px 0px",
  fontSize: "16px",
  fontWeight: "bold",
});

export const StyleGridItem = styled(GridItem)({
  padding: "0px 10px",
  fontSize: "16px",
});

export const StyleInput = styled(Input)({
  padding: "0px 10px",
  border: "1px solid #000000",
  borderRadius: "0",
  fontSize: "14px",
  "::placeholder": {
    opacity: "1",
    color: "var(--primary-text-dark)",
    fontWeight: "600",
  },
});

export const StyleCheckbox = styled(Checkbox)({
  fontSize: "12px",
  borderColor: "#000",
  fontWeight: "bold",
});

export const StyleSelect = styled(Select)({
  padding: "0px 5px",
  border: "1px solid #000000",
  borderRadius: "0",
});

export const CardGray = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "30px 0px",
  margin: "15px",
  background: "#eeeeee",
});

export const StyleTr = styled(Tr)({
  background: "var(--secondary-color) 0% 0% no-repeat padding-box",
  color: "var(--primary-text-dark)",
});

export const StyleDatePicker = styled(DatePicker)({
  border: "1px solid black",
  height: "31px",
  width: "135px",
  borderRadius: "0",
  fontWeight: "600",
  color: "var(--primary-text-dark)",
  fontSize: "12px",
});

export const CalendarCustom = styled(Calendar)`
  table {
    border: 1px solid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-spacing: 0;
    border-collapse: separate !important;
    & > thead > tr:not(:last-child) > th,
    & > thead > tr:not(:last-child) > td,
    & > tbody > tr:not(:last-child) > th,
    & > tbody > tr:not(:last-child) > td,
    & > tfoot > tr:not(:last-child) > th,
    & > tfoot > tr:not(:last-child) > td,
    & > tr:not(:last-child) > td,
    & > tr:not(:last-child) > th,
    & > thead:not(:last-child),
    & > tbody:not(:last-child),
    & > tfoot:not(:last-child) {
      border-bottom: 1px solid black;
    }
    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid black;
    }
  }
  font-family: var(--primary-font-family);
  td > div {
    font-size: 13px;
  }
  th,
  td {
    font-weight: 700 !important;
  }
  th {
    border-bottom: 1px solid black;
    padding: 4.5px 4px !important;
    text-align: left;
    font-size: 15px !important;
    &:first-child {
      border-top-left-radius: 5px;
      color: red;
    }
    &:last-child {
      border-top-right-radius: 5px;
      color: #5c85b0;
    }
  }
  .ant-radio-group {
    display: none;
  }
  .ant-picker-calendar-header {
    justify-content: flex-start;
  }
  thead > tr {
    height: 28px;
    > th {
      background: var(--secondary-color);
      height: 28px;
      font-size: 14px !important;
    }
  }
`;
