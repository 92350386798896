import styled from "@emotion/styled";
import { Tabs } from "antd";

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      content: none;
    }
  }
  .ant-tabs-content-holder {
  }
`;
