import { Form, Input, Tabs } from "antd";
import { ButtonOutline, ButtonSolid } from "component/button";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useEffect, useState } from "react";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { IPatient } from "constant/interfaces";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ROUTES from "constant/routes";
import { SearchOutlined } from "@ant-design/icons";
import TableAdmin from "component/admin/table";
import { InputStyled } from "page/admin/RecordEachCustomer/styled";
import { formatJapanDate } from "constant/date";
import { FORMAT } from "utils";
import moment from "moment";

export const columns: ColumnsType<IPatient> = [
  {
    align: "center",
    title: "ID",
    dataIndex: "patient_code",
    sorter: true,
  },
  {
    align: "center",
    title: "氏名",
    sorter: true,
    dataIndex: "name",
    render: (value, record) => (
      <span>
        {record.family_name}
        <br />({record.name_kana})
      </span>
    ),
    className: "min-w-[250px] max-w-[250px]",
    ellipsis: true,
  },
  {
    align: "center",
    title: "年齢",
    dataIndex: "age",
    sorter: true,
  },
  {
    align: "center",
    title: "居住地",
    sorter: true,
    dataIndex: "residence",
    ellipsis: true,
  },
  {
    align: "center",
    title: "生年月日",
    dataIndex: "birth_date",
    sorter: true,
    render: (value) => formatJapanDate(value),
    className: "min-w-[150px] max-w-[150px]",
  },
  {
    align: "center",
    title: "介護度",
    dataIndex: "care_level",
    render: (value, record) => record.insurance_card?.care_level,
    sorter: true,
    className: "min-w-[100px] max-w-[100px]",
  },
  // {
  //   align: "center",
  //   title: "被保険者番号",
  //   dataIndex: "insurance_number",
  //   render: (value, record) => record.insurance_card?.insurance_number,
  //   sorter: true,
  //   className: "min-w-[150px] max-w-[150px]",
  //   ellipsis: true,
  // },
  // {
  //   align: "center",
  //   title: "被保証適用期間",
  //   dataIndex: "insurance_period",
  //   render: (value, record) =>
  //     record.insurance_card
  //       ? `${formatJapanDate(
  //           record.insurance_card?.start_insurance
  //         )} - ${formatJapanDate(record.insurance_card?.end_insurance)}`
  //       : "",
  //   sorter: true,
  //   className: "min-w-[270px] max-w-[270px]",
  //   ellipsis: true,
  // },
  // {
  //   align: "center",
  //   title: "担当ケアマネ",
  //   dataIndex: "responsible_policy_management",
  //   render: (value, record) =>
  //     record.insurance_card?.responsible_policy_management,
  //   sorter: true,
  //   className: "min-w-[150px] max-w-[150px]",
  //   ellipsis: true,
  // },
  // {
  //   align: "center",
  //   title: "電話番号",
  //   dataIndex: "telephone_number",
  //   sorter: true,
  //   className: "min-w-[200px] max-w-[200px]",
  //   ellipsis: true,
  // },
  {
    align: "center",
    title: "",
    dataIndex: "action",
    render: (_, record) => (
      <>
        <div className="mb-[8px]">
          <Link to={record.id.toString()}>
            <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
              詳細
            </ButtonOutline>
          </Link>
        </div>
        <div>
          <Link
            to={`/${ROUTES.ADMIN}/${ROUTES.CUSTOMER}/${ROUTES.HISTORY_CUSTOMER}/${record?.id}?tab=1&name=${record?.family_name}`}
          >
            <ButtonOutline className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]">
              介護記録・履歴
            </ButtonOutline>
          </Link>
        </div>
      </>
    ),
  },
];
const items = [
  {
    key: "using",
    label: "利用中",
  },
  {
    key: "pause",
    label: "一時停止",
  },
  {
    key: "suspended",
    label: "利用停止",
  },
];

export default function CustomerList() {
  const [data, setData] = useState<IPatient[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [status, setStatus] = useState<string>("using");
  const [form] = Form.useForm();
  const service = useService();
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosClient({
        url: service.PATIENTS,
        method: "get",
        params: { ...form.getFieldsValue(), status: [status] },
      });
      setData(res.data.patients);
      setTotal(res.data.total_items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    fetchData();
  }, [status]);

  return (
    <Form form={form} onFinish={fetchData}>
      <div className="page-title">お客様一覧</div>
      <div className="page-container">
        <Tabs items={items} onChange={setStatus} activeKey={status} />
        <div className="py-[16px] px-[25px] bg-[#F5F4F2]">
          <p className="text-[#000000] text-[12px] leading-[20px]">
            キーワード
          </p>
          <div className="flex gap-x-[21px] items-center">
            <Form.Item name="fullname" className="w-full mb-0">
              <InputStyled
                className="flex items-center customInp border-[#707070] focus:!text-transparent"
                placeholder="氏名、カナ"
                prefix={
                  <SearchOutlined className="text-[16px] text-[#2699FB] mr-[3px]" />
                }
              />
            </Form.Item>
            <ButtonOutline
              type="submit"
              onClick={() => {
                form.resetFields(["page"]);
              }}
              className=" !px-[63px] !bg-[#FFFFFF] !max-h-[25px]"
              _hover={{
                color: "brand.500]",
                bg: "brand.100",
              }}
            >
              検索
            </ButtonOutline>
          </div>
        </div>
        <div className="text-end mt-[19px] mb-[21px]">
          <Link to={ROUTES.CREATE}>
            <ButtonSolid width={137}>お客様登録</ButtonSolid>
          </Link>
        </div>
        {/* table */}
        <TableAdmin
          form={form}
          data={data}
          columns={columns}
          loading={loading}
          total={total}
        />
      </div>
    </Form>
  );
}
