import { Button, Form, Select, Tag, Col, Row, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import MESSAGES from "constant/messages";
import { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { DetailModal } from "../PostList";
import "./style.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import noticeSlice from "store/slice/noticeSlice";
import { RootState } from "store";
import clsx from "clsx";
const CommentDetailView = ({ postId, setReplyTo }: any) => {
  const { id } = useParams();
  const [titleNotice, setTitleNotice]: any = useState("");
  const [idCreateNotice, setIdCreateNotice] = useState("");
  const [roleCreateNotice, setRoleCreateNotice] = useState("");
  const [isEditTable, setIsEditTable] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const readOnly = location?.state?.isDeleted;
  const [isImportant, setIsImportant] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [patientList, setPatientList] = useState<any>([]);
  const [read, setRead] = useState([]);
  const [unread, setUnread] = useState([]);
  const user = useSelector((state: RootState) => state.user);
  const [comment, setComment] = useState<{
    id: string;
    contributor: string;
    title: string;
    patients: [];
    content: string;
  }>({
    id: "",
    contributor: "",
    title: "",
    patients: [],
    content: "",
  });

  const [data, setData] = useState<{
    user?: string;
    time?: string;
    read?: [];
    content?: string;
    title?: string;
    sender_type?: string;
  }>({});
  const [notificationViewLogs, setNotificationViewLogs] = useState([]);

  const parseDate = () => {
    var split_str = titleNotice.split("：")[1];
    var output = split_str?.slice(0, 8);
    return output?.replace("年", "/").replace("月", "");
  };

  const removePTags = (str: any) => {
    return str.replace(/<\/?p>/g, "").trim();
  };

  const arrayFromDataContent: any =
    data.content && data.content.split("\n").map(removePTags);

  const showModal = async (id: any) => {
    setIsModalOpen(true);
    const result = await apiFactory.boardAdminApi.getPostDetail(id);
    setRead(result.notification_view_logs);
    setIsModalOpen(true);
    setUnread(result.unread_list);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fetchData = async () => {
    const result = await apiFactory.boardAdminApi.getPostDetail(Number(id));
    if (result) {
      setIsEditTable(result?.is_editable);
      setTitleNotice(result?.title);
      setIsImportant(result?.is_important);
      setIdCreateNotice(result?.sender_id);
      setRoleCreateNotice(result?.sender_type);
      setData({
        user: result?.poster,
        time: result?.created_at,
        read: result?.read_list,
        content: result?.content,
        title: result?.title,
        sender_type: result?.sender_type,
      });
      setNotificationViewLogs(result?.notification_view_logs);
    }
  };

  const fetchPatientList = async () => {
    const data = await apiFactory.patientAdminApi.getList({ per: 999999 });
    if (data) {
      setPatientList(
        data.patients.map((e: any) => ({
          label: `${e.last_name} ${e.first_name}`,
          value: e.id,
        }))
      );
    }
  };

  const PatientSelection = useCallback(
    ({ value, onChange }: any) => {
      const removeItem = (e: any) => {
        const index = value.findIndex((f: any) => f.value === e);
        value.splice(index, 1);
        const clonePatientList = patientList.map((f: any) => {
          if (f.value === e) {
            f.disabled = false;
          }
          return f;
        });
        setPatientList(clonePatientList);
      };
      return (
        <>
          {!isEditTable ? (
            <Select
              disabled
              options={patientList}
              onChange={(e: any) => {
                const patient = patientList.find((f: any) => f.value === e);
                value.push(patient);
                const clonePatientList = patientList.map((f: any) => {
                  if (f.value === e) {
                    f.disabled = true;
                  }
                  return f;
                });
                setPatientList(clonePatientList);
                onChange([...value]);
              }}
            />
          ) : (
            <Select
              options={patientList}
              onChange={(e: any) => {
                const patient = patientList.find((f: any) => f.value === e);
                value.push(patient);
                const clonePatientList = patientList.map((f: any) => {
                  if (f.value === e) {
                    f.disabled = true;
                  }
                  return f;
                });
                setPatientList(clonePatientList);
                onChange([...value]);
              }}
            />
          )}
          <div className="patient-list">
            {value?.map((e: any) => {
              return (
                <Tag
                  color={"green"}
                  closable={true}
                  onClose={() => removeItem(e.value)}
                  style={{ marginRight: 3 }}
                >
                  {e.label}
                </Tag>
              );
            })}
          </div>
        </>
      );
    },
    [patientList]
  );

  const onFinish = async (values: any) => {
    const body = {
      id: values.id,
      reply_to: id,
      title: values?.title.trim(),
      mentions: values?.patients?.map((e: any) => e.value),
      is_important: values?.important,
      content: values?.content,
      delete: values?.delete,
    };
    try {
      const data = await apiFactory.boardAdminApi.createPost(body);
      if (data) {
        toast.success(data.success);
        navigate("/admin/notice");
      }
    } catch (error: any) {
      if (typeof error?.message === "string") {
        toast.error(error?.message);
        return;
      }
      let stringError = "";
      Object.entries(error?.message?.errors).map((e: any) => {
        stringError += `${e[0]}: ${e[1]} `;
      });
      toast.error(stringError);
    }
  };
  useEffect(() => {
    form.setFieldValue("contributor", Cookies.get("user_name"));
    form.setFieldValue("title", `Re: ${data?.title}`);
    fetchData();
    fetchPatientList();
  }, [data?.title]);
  return (
    <>
      <div>
        <div className="page-title-detail">
          {isImportant && <span className="important px-1 mr-3">重要</span>}
          {titleNotice}
        </div>
        <div className="page-container">
          <div className="communication">
            <div className="text-end mt-[19px] mb-[21px] ">
              {!isEditTable ||
              (roleCreateNotice !== user?.roleCls &&
                Number(idCreateNotice) !== Number(user?.id)) ? (
                <ButtonSolid disabled className="w-[137px] cursor-no-drop">
                  修正
                </ButtonSolid>
              ) : (
                <ButtonSolid
                  className="w-[137px]"
                  onClick={() => {
                    // setReplyTo(postId)
                    dispatch(noticeSlice?.actions?.setDetailType("edit"));
                    // setPart("PostDetail");
                    navigate(`/admin/post-detail/${id}`, {
                      state: { readOnly: readOnly },
                    });
                  }}
                >
                  修正
                </ButtonSolid>
              )}
            </div>
            <div>
              <div className="mb-[10px]" onClick={() => showModal(id)}>
                <b>
                  <span className="text-[22px]">■</span>
                  {data?.sender_type === "UserAdmin" && "管理人"} {data.user}
                  {data?.sender_type === "UserAdmin" && "様"} &nbsp;
                  {data.time}
                </b>{" "}
                既読 {notificationViewLogs?.length}＞
              </div>
              {/* <TextArea
                value={data.content}
                rows={5}
                style={{ height: 120 }}
                disabled
              /> */}
              <div className="border-[1px] border-solid border-black pt-[5px] px-[10px] pb-[40px]">
                {arrayFromDataContent?.map((item: any, index: number) => (
                  <p
                    onClick={() => {
                      index === 2 &&
                        item.includes("/admin/shift/shift_holiday") &&
                        navigate(`${item.replace("リンク先URL ", "")}`, {
                          state: { dateTime: parseDate() },
                        });
                    }}
                    className={clsx("text-[14px]", {
                      "cursor-pointer hover:text-[blue] hover:font-semibold hover:italic":
                        index === 2 &&
                        item.includes("/admin/shift/shift_holiday"),
                    })}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
            <div className="mt-[50px]">
              <span className="text-[22px]">■</span>スレッドに返信
            </div>
            <div className="pt-[7px] post-detail">
              <Form onFinish={onFinish} form={form} initialValues={comment}>
                <Row>
                  <Col span={11}>
                    <Form.Item label="投稿者" name="contributor">
                      <Input
                        readOnly
                        className="bg-[#F5F5F5] cursor-no-drop !border-[#d9d9d9]"
                        style={{ color: "rgba(0, 0, 0, 0.25)" }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="タイトル"
                      name="title"
                      rules={[
                        { required: true, message: MESSAGES.requireField },
                      ]}
                    >
                      {!isEditTable ? (
                        <Input
                          readOnly
                          className="bg-[#F5F5F5] cursor-no-drop !border-[#d9d9d9]"
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      ) : (
                        <Input />
                      )}
                    </Form.Item>
                    <Form.Item label="該当お客様" name="patients">
                      <PatientSelection />
                    </Form.Item>

                    <Form.Item
                      label="内容"
                      name="content"
                      className="w-[200%]"
                      rules={[
                        { required: true, message: MESSAGES.requireField },
                      ]}
                    >
                      {!isEditTable ? (
                        <TextArea
                          readOnly
                          className="bg-[#F5F5F5] cursor-no-drop !border-[#d9d9d9]"
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                        />
                      ) : (
                        <TextArea />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={13} />
                  <Col span={11}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center text-[12px] leading-[20px] gap-x-[6px]"></div>
                      <div className="flex gap-x-[8px]">
                        <ButtonOutline
                          className="!h-[35px] !px-[16px] !text-[14px] !leading-[24px]"
                          onClick={() => {
                            navigate("/admin/notice");
                          }}
                        >
                          キャンセル
                        </ButtonOutline>
                        {!isEditTable ? (
                          <Button
                            disabled
                            className="save-button"
                            htmlType="submit"
                          >
                            保存
                          </Button>
                        ) : (
                          <Button className="save-button" htmlType="submit">
                            保存
                          </Button>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            {DetailModal({ isModalOpen, handleOk, handleCancel, read, unread })}
          </div>
        </div>
      </div>
    </>
  );
};
export default CommentDetailView;
