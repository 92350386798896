import { Tabs } from "antd";
import TableRecordCustomer from "component/table/TableRecordCustomer";
import TableRecordHistoryByDate from "component/table/TableRecordHistoryByDate";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, Text } from "@chakra-ui/react";
import { FlexRow, StyleCheckbox } from "component/StyledComponent";
import LeftArrow from "assets/leftarrow.png";
import RightArrow from "assets/rightarrow.png";
import { ButtonOutline } from "component/button";
import queryString from "query-string";

const TAB_PATIENT = "1";
const TAB_NURSE = "2";

const CustomerHistoryList = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const paramTab: any = queryString.parse(location.search);
  const initialDate = paramTab.date ? new Date(paramTab.date) : "";
  const [date, setDate]: any = useState(initialDate);

  const getPreviousDay = () => {
    const newDate = moment(date).subtract(1, "days").format();

    setDate(new Date(newDate));
  };
  const getNextDay = () => {
    const newDate = moment(date).add(1, "days").format();

    setDate(new Date(newDate));
  };

  useEffect(() => {
    const queryObj: any = queryString.parse(location.search);
    if (location.search) {
      setDate(queryObj.date ? new Date(queryObj.date) : "");
    } else {
      setDate(new Date());
    }
  }, [location.search]);

  const items = [
    {
      key: "1",
      label: "お客様",
      children: (
        <div className="text-[#000000] h-full ">
          <span className="flex flex-row" style={{ fontSize: "24px" }}></span>

          <TableRecordCustomer dateparam={date && date} />
        </div>
      ),
      disabled: paramTab.tab === TAB_NURSE,
    },
    {
      key: "2",
      label: "介護スタッフ",
      children: (
        <div className="text-[#000000] h-full ">
          <span className="flex flex-row" style={{ fontSize: "24px" }}></span>
          <TableRecordHistoryByDate date={date} />
        </div>
      ),
      disabled: paramTab.tab === TAB_PATIENT,
    },
  ];
  return (
    <div>
      <div className="page-title">
        {paramTab?.name} 様 <span className="mr-5">介護記録・履歴</span>
        {date && (
          <span className="mr-5">
            {moment(new Date(date)).format("YYYY/MM/DD")}
          </span>
        )}
      </div>
      <div className="page-container text-left">
        {/* <Tabs defaultActiveKey={paramTab.tab} items={items} /> */}
        <TableRecordCustomer dateparam={date && date} />
        <FlexRow className="justify-end" style={{ paddingBottom: "40px" }}>
          <ButtonOutline
            onClick={() => navigate(-1)}
            fontSize="14px"
            mt="232px"
            w="101px"
            h="35px"
          >
            戻る
          </ButtonOutline>
        </FlexRow>
      </div>
    </div>
  );
};

const StyleBtn = styled(Button)({
  border: "1px solid black",
  fontSize: "20px",
});

export default CustomerHistoryList;
