import { memo } from "react";

interface IMessageProps {
  readAt?: string;
  hourAt?: string;
  content: string;
  isGuest?: boolean;
  isUnread?: boolean;
}

const Message = (props: IMessageProps) => {
  const { readAt, hourAt, content, isGuest, isUnread, ...rest } = props;

  return (
    <div className="py-[10px] max-md:px-[10px]" {...rest}>
      <div
        className="flex items-center gap-x-[10px]"
        style={{
          justifyContent: isGuest ? "start" : "end",
          flexDirection: isGuest ? "row" : "row-reverse",
        }}
      >
        <div
          className="p-[12px] max-md:max-w-[60vw] max-w-[40vw]"
          style={{
            whiteSpace: "pre-line",
            borderRadius: isGuest ? "2px 16px 16px 16px" : "16px 2px 16px 16px",
            backgroundColor: isGuest ? "#f0f0f0" : "#cadefc",
          }}
        >
          {content}
        </div>

        <div className="flex flex-col items-center text-[12px] text-[#969696]">
          <span>{!!readAt ? "既読" : "未読"}</span>
          <span>{hourAt}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(Message);
