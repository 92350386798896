import { useEffect, useState } from "react";
import LogoLogin from "assets/logo.png";
import { Button, Form, Input, notification } from "antd";
import "./style.scss";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import apiFactory from "api";
import { RootState } from "store";
import { toast } from "react-toastify";
import MESSAGES from "constant/messages";
import REGEX from "constant/regex";
import { useLocation } from "react-router-dom";
import { LoginErrorI } from "types/auth";

interface IProps {
  typeLogin: string;
}

const LoginPage = ({ typeLogin }: IProps) => {
  const EXPIRY_TIME = process.env.REACT_APP_EXPIRY_TIME || "4";
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { role } = useSelector((state: RootState) => state.user);

  const location = useLocation();

  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const handleLogin = async (values: {
    username: string;
    password: string;
  }) => {
    try {
      setIsLoading(true);
      const result = await apiFactory.authApi.login(
        {
          username: values.username,
          password: values.password,
        },
        typeLogin
      );
      if (result) {
        let expires = new Date(
          new Date().setHours(new Date().getHours() + parseInt(EXPIRY_TIME))
        );
        Cookies.set("access_token", result?.access_token, {
          path: "/",
          expires,
        });
        Cookies.set("user_name", result?.user_name || result.name_kana);
        window.location.assign("/");
      }
    } catch (error) {
      notification.error({ message: (error as LoginErrorI)?.message?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const getText = () => {
    if (location.pathname.includes("home-system-admin")) {
      return "ホームシステム　マスター用";
    } else if (location.pathname.includes("home-system-staff")) {
      return "ホームシステム スタッフ用";
    } else if (location.pathname.includes("staff")) {
      return "スタッフ用";
    } else if (location.pathname.includes("admin")) {
      return "マスター用";
    } else {
      return "お客様用";
    }
  };

  useEffect(() => {
    const error = localStorage.getItem("error");
    if (error) toast.error(error);
    localStorage.removeItem("error");
  }, []);

  return (
    <div
      className={`login-admin ${
        location.pathname.includes("home-system") ? "!bg-[#ffffff]" : ""
      } w-full px-2 md:w-2/5`}
    >
      <Form initialValues={data} onFinish={handleLogin} autoComplete="off">
        <img
          src={LogoLogin}
          alt=""
          className="mb-[5%]"
          width="407px"
          height="131px"
        />
        <p className="mb-[15%] font-bold text-[16px]">{getText()}</p>

        <div className="w-[250px] m-auto text-left">
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
              {
                pattern: REGEX.atLeastOneNonSpaceCharacter,
                message: MESSAGES.requireField,
              },
              {
                pattern: REGEX.onlyTextAndNumber,
                message: MESSAGES.invalidCharacter,
              },
            ]}
          >
            <Input
              className="input"
              placeholder="ID"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: MESSAGES.requireField,
              },
              // {
              //   pattern: REGEX.atLeastOneNonSpaceCharacter,
              //   message: MESSAGES.requireField,
              // },
              // {
              //   min: 6,
              //   max: 15,
              //   message: MESSAGES.min6max15character,
              // },
            ]}
          >
            <Input
              className="input"
              placeholder="パスワード"
              type="password"
              autoComplete="new-password"
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            className={`button ${
              location.pathname.includes("home-system") ? "button-hs" : ""
            }`}
            htmlType="submit"
            loading={isLoading}
          >
            ログイン
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
