import BaseApi from "api/baseApi";
import { objectToFormData } from "utils/data";
import { ADMIN_EVALUE_ONES } from "../apiConstant";
import { IEvalueOne } from "types/admin";

type IEVALUE_PAGINATION = {
  page?: number | string;
  per_page?: number | string;
};

class evalueApi extends BaseApi {
  getEvalue<T>(params: IEVALUE_PAGINATION & { patient_id: number | string }) {
    return this.get<T>(ADMIN_EVALUE_ONES, params);
  }

  getEvalueDetail<T>(id: number | string) {
    return this.get<T>(ADMIN_EVALUE_ONES + `/${id}`);
  }

  createEvalue<T>(
    body:
      | any
      | (IEvalueOne & {
          patient_id: number | string;
        })
  ) {
    return this.post<T>(ADMIN_EVALUE_ONES, objectToFormData(body), {
      header: { "Content-Type": "multipart/form-data" },
    });
  }

  updateEvalue<T>(
    body: IEvalueOne & {
      id: number;
    }
  ) {
    return this.put<T>(
      ADMIN_EVALUE_ONES + `/${body.id}`,
      objectToFormData(body),
      {
        header: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  deleteEvalue<T>(id: number | string) {
    return this.delete<T>(ADMIN_EVALUE_ONES + `/${id}`);
  }
}

export default evalueApi;
