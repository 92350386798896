import React from "react";

const Phone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8.67187 11.7C9.44364 13.2938 10.7324 14.5792 12.3281 15.3469C12.4458 15.4026 12.576 15.4268 12.7059 15.4169C12.8358 15.407 12.9608 15.3635 13.0687 15.2907L15.4125 13.725C15.516 13.6548 15.6357 13.6119 15.7603 13.6005C15.8849 13.589 16.0104 13.6093 16.125 13.6594L20.5125 15.5438C20.6625 15.6062 20.7877 15.7162 20.869 15.8568C20.9504 15.9974 20.9832 16.1608 20.9625 16.3219C20.8234 17.4073 20.2937 18.4048 19.4723 19.1278C18.6509 19.8508 17.5943 20.2498 16.5 20.25C13.1185 20.25 9.87548 18.9067 7.48439 16.5156C5.0933 14.1246 3.75 10.8815 3.75 7.50003C3.75025 6.40578 4.1492 5.34911 4.87221 4.52774C5.59522 3.70637 6.59274 3.17659 7.67812 3.03753C7.83922 3.01684 8.00266 3.04967 8.14326 3.13099C8.28386 3.2123 8.39384 3.33758 8.45625 3.48753L10.3406 7.88441C10.3896 7.99723 10.4101 8.12038 10.4003 8.24299C10.3905 8.36561 10.3507 8.48393 10.2844 8.58753L8.71875 10.9688C8.64905 11.0765 8.60814 11.2003 8.59993 11.3283C8.59172 11.4563 8.61649 11.5843 8.67187 11.7V11.7Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Phone;
