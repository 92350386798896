import Breadcrumbs from "component/breadcrumb";
import Header from "component/header/Header";
import { StaffMenu } from "component/staff";
import { ReactNode } from "react";

export interface ILayoutProps {
  children?: ReactNode;
  isHeader?: boolean;
  isTabs?: boolean;
  items?: Array<{ key: string; label: ReactNode; children: ReactNode }>;
  title?: ReactNode;
}

export default function StaffLayout({
  children,
  isHeader = true,
}: ILayoutProps) {
  return (
    <div className="w-100% overflow-x-hidden">
      <div className="text-start ">
        <Header title="" isMaster={true} isMenu={true} />
        <StaffMenu />

        <div className="flex flex-col min-h-[calc(100vh-50px)] mt-[50px] md:bg-light-default flex-[1_1_82%] relative max-w-[100vw] overflow-x-hidden lg:ml-[208px]">
          {children}
        </div>
      </div>
    </div>
  );
}
