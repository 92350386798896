import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FlexRow } from "component/StyledComponent";
import { CalendarVisitSchedule } from "component/calendar";
import TableTimeSchedule from "component/table/TableTimeSchedule";
import TableVisitScheduleDay from "component/table/TableVisitScheduleDay";
import { DateButton, WeekButton } from "component/timeBtn";
import moment from "moment";
import React, { useState } from "react";
import dayjs from "dayjs";
import Header from "component/header/Header";

const getMonday = (d: any) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

const VisitSchedulePage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabName, setTabName] = useState("月別");
  const [date, setDate]: any = useState(new Date());
  const [week, setWeek] = useState(dayjs().startOf("week"));
  // const [month, setMonth]: any = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  // const [lastMonth, setLastMonth]: any = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))

  const getPreviousDay = () => {
    const newDate = moment(date).subtract(1, "days").format();

    setDate(new Date(newDate));
  };
  const getNextDay = () => {
    const newDate = moment(date).add(1, "days").format();

    setDate(new Date(newDate));
  };

  const getPreviousWeek = () => setWeek(week.subtract(1, 'week'))
  const getNextWeek = () => setWeek(week.add(1, 'week'))

  // const getPreviousMonth = () => {
  //   const newDate = moment(month).subtract(1, 'months').format()

  //   setMonth(new Date(newDate))
  // }
  // const getNextMonth = () => {
  //   const newDate = moment(month).add(1, 'months').format()
  //   const newLastDate = moment(lastMonth).add(1, 'months').format()
  //   console.log("new date month",dayjs(newDate))
  //   setLastMonth(new Date(newLastDate))
  //   setMonth(new Date(newDate))
  // }

  const onChangeTab = (evt: any) => {
    setTabIndex(evt);
    if (evt === 0) {
      setTabName("月別");
    }
    if (evt === 1) {
      setTabName("週別");
    }
    if (evt === 2) {
      setTabName("日別");
    }
  };

  const renderTimeButton = () => {
    switch (tabIndex) {
      case 0:
        // setTabName('月別')
        return "";
      case 1:
        // setTabName('週別')
        return (
          <WeekButton
            getPreviousWeek={getPreviousWeek}
            getNextWeek={getNextWeek}
            week={week.toDate()}
          />
        );
      case 2:
        // setTabName('日別')
        return (
          <DateButton
            getPreviousDay={getPreviousDay}
            getNextDay={getNextDay}
            date={date}
          />
        );
      default:
        break;
    }
  };
  return (
    <div>
      <Header
        title={`訪問スケジュール　${tabName}`}
      />
      <div className="page-title">{/* title here */}</div>
      <div className="page-container">
        <Tabs
          tabIndex={tabIndex}
          variant="soft-rounded"
          colorScheme="brand"
          onChange={onChangeTab}
        >
          <FlexRow className="mt-9">
            <TabList
              border="1px solid black"
              width="104px"
              height="30px"
              mt="5px"
              overflow="hidden"
              fontSize="12px"
              ml="10px"
              borderRadius="5px"
            >
              <StyleTab _selected={{ color: "white", bg: "brand.500" }}>
              <Text fontWeight='bold' fontSize='14px'> 月</Text> 
              </StyleTab>
              <Tab
                padding="0px"
                fontSize="12px"
                borderRadius={0}
                borderLeft='1px solid black'
                borderRight='1px solid black'
                _selected={{ color: "white", bg: "brand.500" }}
              >
                {" "}
                <Text fontWeight='bold' fontSize='14px' p="5px 10px" borderX="0px">
                  週
                </Text>
              </Tab>
              <StyleTab _selected={{ color: "white", bg: "brand.500" }}>
                 <Text fontWeight='bold' fontSize='14px'> 日</Text> 
              </StyleTab>
            </TabList>
            {renderTimeButton()}
          </FlexRow>
          <TabPanels>
            <TabPanel>
              {tabIndex === 0 && <CalendarVisitSchedule />}
            </TabPanel>
            <TabPanel>
              {tabIndex === 1 && <TableVisitScheduleDay
                weekStart={week.toDate()}
                weekEnd={
                  week.endOf("week").toDate()
                }
              />}
            </TabPanel>
            <TabPanel>
              {tabIndex === 2 && <TableTimeSchedule date={date} />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

const StyleTab = styled(Tab)({
  fontSize: "12px",
  borderRadius: "0px",
  padding: "5px 10px",
});

export default VisitSchedulePage;
