import React, { useContext, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import type { FormInstance } from 'antd/es/form';
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import dayjs, { Dayjs } from "dayjs";
import { Styles } from "./styled";
import { ButtonOutline, ButtonSolid } from 'component/button';
import axiosClient from 'api/axiosClient';
import useService from 'hook/useService';
import { MONTH_FORMAT } from 'constant/date';
import SelectTime from 'component/SelectTime';
import { Checkbox, Form, Modal, Popover, Select, Spin, Table } from "antd";
import { useWatch } from 'antd/es/form/Form';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { fillDateArray } from 'page/admin/Payment/Detail/Usage/function';

interface IPopupState {
  rowIndex?: number | undefined;
  colIndex?: number | undefined;
  change?: number;
}

export default function HomeServiceDeviceList() {
  const [month, setMonth] = useState<Dayjs>(dayjs());
  const [count, setCount] = useState(2);
  const [openPopup, setOpenPopup] = useState(false);
  const [infoItem, setInfoItem] = useState({ title: '', quantity: 0 });
  const [quantity, setQuantity] = useState(0);
  const [pationOptions, setPatientOptions] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);
  const [patient, setPatient] = useState<any>(null);
  const [isAllowUpdate, setIsAllowUpdate] = useState(true);

  const [form] = Form.useForm();
  const equipment_services = useWatch("equipment_services", form);
  const [popupState, setPopupState] = useState<IPopupState>();


  const service = useService();
  const navigate = useNavigate();
  const location = useLocation();
  const SERVICE_NAME = location.pathname.includes("home-system-admin") ? service.HS_ADMIN : service.HS_STAFF;
  const classNameTitle = location.pathname.includes("home-system-admin") ? "page-title" : "px-[20px] page-title"
  const handleChangePatient = (value: string) => {
    if (!value) return;
    setSelected(value);
  };

  const fetchPatientOptions = async () => {
    try {
      const res = await axiosClient({
        method: "get",
        url: `${SERVICE_NAME}/patients`,
        params: {
          year_month: month.format(MONTH_FORMAT),
        },
      });
      const options = res?.data?.data?.map((item: any) => {
        return { label: item?.family_name, value: item?.id }
      })
      setPatientOptions(options);
      setSelected(options[0]?.value);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchEquipmentServices = async () => {
    if(!selected) return;
    try {
      const {data} = await axiosClient({
        method: "get",
        url: `${SERVICE_NAME}/equipment_services`,
        params: {
          year_month: month.format(MONTH_FORMAT),
          patient_id: selected
        },
      });
      form.setFieldValue(
        "equipment_services",
        data.data.equipment_services
        .map((i: any) => ({
          ...i,
          unit_price: i.equipment_home_systems?.unit_price || i.unit_price,
          equipment_usage_home_systems:
            i.equipment_home_systems &&
            fillDateArray(
              i.equipment_home_systems?.equipment_usage_home_systems,
              month
            ),
        }))
      );
     setPatient(data.data.patient);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async () => {
    const data = {
      patient_id: selected,
      year_month: month.format("YYYY/MM"),
      equipment_home_systems: equipment_services
        .filter(
          (i: any) =>
            i.equipment_usage_home_systems?.filter(
              (subItem: any) => subItem?.change
            )?.length
        )
        .map((i: any) => ({
          equipment_service_id: i.id,
          unit_price: i.unit_price,
          equipment_usage_home_systems: i.equipment_usage_home_systems?.filter(
            (subItem: any) => subItem?.change
          ),
        })),
    }
    try {
      const res = await axiosClient({
        method: "post",
        url: `${SERVICE_NAME}/equipment_home_systems`,
        data: data,
      });
      toast.success(res.data.success);
      navigate(-1);
    } catch (error) {
      toast.error((error as string).toString());
    }
  };

  useEffect(() => {
    fetchPatientOptions();
  }, [])

  useEffect(() => {
      fetchEquipmentServices();
  }, [selected, month])

  return (
    <Styles>
      <Form form={form} onFinish={handleSubmit}>
        <Styles>
          <div className={classNameTitle}>備品利用一覧</div>
          <div className="page-container">
            <div className='flex gap-x-[24px] items-center mb-[24px]'>
              <div className='flex gap-x-[4px] items-center'>
                <p className='text-[16px]'>お客様名</p>
                <Select
                  style={{ width: 150 }}
                  onChange={handleChangePatient}
                  options={pationOptions || []}
                  value={selected}
                />
              </div>
              <SelectTime
                format={MONTH_FORMAT}
                type="month"
                value={month}
                onChange={setMonth}
              />
            </div>
            <Form.List name="equipment_services">
              {(field) => (
                <Table
                  rowKey={(row) => row.id}
                  dataSource={equipment_services}
                  bordered
                  scroll={{ x: true }}
                  pagination={false}
                >
                  <Column
                    title="項目名"
                    dataIndex="service_name"
                    className="font-[600]"
                  />
                  {[...Array(month.daysInMonth())].map((item, index) => (
                    <ColumnGroup
                      className="col-date"
                      title={index + 1}
                      align="center"
                      key={index}
                    >
                      <Column
                        className="col-date"
                        align="center"
                        title={month
                          .locale("ja")
                          .date(index + 1)
                          .format("ddd")}
                        render={(value, record: any, rowIndex) => {
                          const cellValue =
                            equipment_services?.[rowIndex]
                              .equipment_usage_home_systems &&
                            (equipment_services[rowIndex]
                              .equipment_usage_home_systems[index]?.quantity || 0) +
                              (equipment_services[rowIndex]
                                .equipment_usage_home_systems[index]?.change || 0);
                          return (
                            <Popover
                              trigger="click"
                              onOpenChange={(value) =>
                                !value && setPopupState(undefined)
                              }
                              open={
                                isAllowUpdate &&
                                popupState?.rowIndex === rowIndex &&
                                popupState.colIndex === index
                              }
                              content={
                                <div>
                                  <p className="font-bold whitespace-nowrap mb-2">
                                    {record.service_name}
                                  </p>
                                  <Select
                                    value={0}
                                    onChange={(value) => {
                                      setPopupState(undefined);
                                      form.setFieldValue(
                                        [
                                          "equipment_services",
                                          rowIndex,
                                          "equipment_usage_home_systems",
                                          index,
                                        ],
                                        {
                                          ...equipment_services[rowIndex]
                                            ?.equipment_usage_home_systems?.[index],
                                          change:
                                            (equipment_services[rowIndex]
                                              ?.equipment_usage_home_systems?.[
                                              index
                                            ]?.change || 0) + value,
                                          date: dayjs(
                                            month.date(index + 1)
                                          ).format("YYYY-MM-DD"),
                                        }
                                      );
                                    }}
                                    className="w-[70px]"
                                    options={[...Array(11)].map((i, idx) => ({
                                      label: idx,
                                      value: idx,
                                    }))}
                                  />
                                </div>
                              }
                            >
                              <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                  setPopupState({
                                    rowIndex: rowIndex,
                                    colIndex: index,
                                    change: 1,
                                  });
                                }}
                              >
                                {cellValue || <br />}
                              </div>
                            </Popover>
                          );
                        }}
                      />
                    </ColumnGroup>
                  ))}
                  <Column align="center" title="単価" dataIndex="unit_price" />
                  <Column
                    align="center"
                    title="利用数"
                    render={(value, record, index) =>
                      equipment_services[
                        index
                      ].equipment_usage_home_systems?.reduce(
                        (c: any, n: any) =>
                          c + (n?.change || 0) + (n?.quantity || 0),
                        0
                      ) || ""
                    }
                  />
                  <Column
                    align="center"
                    title="在庫数"
                    render={(value, record: any, index) => {
                      const cellvalue =
                        record.stock_quantity === "管理しない"
                          ? "管理しない"
                          : record.stock_quantity -
                            (equipment_services[
                              index
                            ].equipment_usage_home_systems?.reduce(
                              (c: number, n: any) => +c + (n?.change || 0),
                              0
                            ) || 0);
                      return (
                        <div
                          style={{
                            color:
                              cellvalue === "管理しない"
                                ? ""
                                : cellvalue >= 0
                                ? ""
                                : "red",
                          }}
                        >
                          {cellvalue}
                        </div>
                      );
                    }}
                  />
                  <Column
                    align="center"
                    title="合計"
                    render={(value, record: any, index) =>
                      (equipment_services[
                        index
                      ].equipment_usage_home_systems?.reduce(
                        (c: any, n: any) =>
                          c + (n?.change || 0) + (n?.quantity || 0),
                        0
                      ) || 0) * record.unit_price
                    }
                  />
                </Table>
              )}
            </Form.List>
            <div className="flex justify-end gap-3 mt-[24px]">
              <ButtonOutline onClick={() => navigate(-1)}>
                キャンセル
              </ButtonOutline>
              <ButtonSolid
                hidden={!isAllowUpdate}
                w={137}
                onClick={form.submit}
              >
                保存
              </ButtonSolid>
            </div>
          </div>
        </Styles>
      </Form>
    </Styles>
  )
}
