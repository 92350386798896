import { TabsProps } from "antd";
import { ButtonSolid } from "component/button";
import ROUTES from "constant/routes";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import noticeSlice from "store/slice/hsAdminNoticeSlice";
import Conveyed from "../components/board/Conveyed";
import Important from "../components/board/Important";
import { StyledBoardTabs } from "./styled";

const NewBoard: React.FC = () => {
  const { boardInnerTab } = useSelector(
    (state: RootState) => state.hsAdminNotice
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "重要事項",
        children: <Important />,
      },
      {
        key: "2",
        label: "申し送り事項",
        children: <Conveyed />,
      },
    ],
    []
  );

  const onChange: TabsProps["onChange"] = (activeKey) => {
    dispatch(noticeSlice.actions.setBoardInnerTab(activeKey));
  };

  return (
    <div className="bg-white px-[12px] py-[18px] relative mt-4">
      <ButtonSolid
        className="!absolute top-[18px] right-[12px] w-[135px]"
        onClick={() =>
          navigate(
            `/${ROUTES.HOME_SYSTEM}/${ROUTES.NOTICE}/${ROUTES.CREATE}?tab=${boardInnerTab}`
          )
        }
      >
        親スレッド投稿
      </ButtonSolid>

      <StyledBoardTabs
        activeKey={boardInnerTab}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default NewBoard;
