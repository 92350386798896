import queryString from "query-string";
import { useLocation } from "react-router-dom";
import DailyForm from "./_components/DailyForm";

export default function CreateRecordDaily() {
  const location = useLocation();

  const queryObj: any = queryString.parse(location.search);

  return (
    <div className="page-header-bg mb-20 bg-light-default sm:mb-24 md:mb-20 md:px-5 md:after:h-[76px]">
      <div className="page-title text-base px-6 md:px-0 md:text-xl">
        介護記録・履歴 {queryObj?.patient} 様
      </div>
      <div className="!p-4 !py-0 md:!p-5 md:!py-5 page-container mt-4 md:mt-10">
        <DailyForm />
      </div>
    </div>
  );
}
