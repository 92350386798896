import { createSlice } from "@reduxjs/toolkit";

const initialState: IHSAdminNoticeState = {
  tab: "1",
  title: "",
  detailType: "",
  isDeleted: false,
  boardInnerTab: "1",
};

export interface IHSAdminNoticeState {
  tab: string;
  title: string;
  detailType: string;
  isDeleted: boolean;
  boardInnerTab: string;
}

const hsAdminNoticeSlice = createSlice({
  initialState,
  name: "hs-admin-notice",
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDetailType: (state, action) => {
      state.detailType = action.payload;
    },
    setIsDeleted: (state, action) => {
      state.isDeleted = action.payload;
    },
    setBoardInnerTab: (state, action) => {
      state.boardInnerTab = action.payload;
    },
  },
});

export const { setIsDeleted } = hsAdminNoticeSlice.actions;

export default hsAdminNoticeSlice;
