import { Text } from '@chakra-ui/react'
import React from 'react'
import Sheet2Checkbox from './Sheet2Checkbox'
import RadioSheet2Form from './RadioSheet2Form'
import { OPTIONS, SelectOptions } from 'page/Customer/CustomerDetail/AssesmentSheet2/function'

const Sheet2ExcreteForm = ({ disable }: any) => {
  return (
    <div>
      <Text fontSize='14px'>◯排泄</Text>
      {/* <Sheet2Checkbox disable={disable} title="形態" sign="※" col={4}
       nameText="formExcreteText"
        content={["自立", "リハパン", "紙オムツ", "パット"]} name={["formExcreteIndependence", "rehapan", "paperDiaper", "pat"]}
         /> */}
      <SelectOptions readOnly data={OPTIONS.excretory_form_option} />
      <RadioSheet2Form disable={disable} title="尿意" sign="※" valueAll={[true, false]}
        rdTit={["あり", "なし"]} name="is_urgent_urination" col={4}
        nameText="urgent_urination_excretion" />
      <RadioSheet2Form disable={disable} title="便意" sign="※" valueAll={[true, false]}
        rdTit={["あり", "なし"]} name="is_benefit" col={4}
        nameText="benefit_excretion" />
      <RadioSheet2Form disable={disable} title="失禁" sign="※" valueAll={[true, false]}
        rdTit={["あり", "なし"]} name="is_incontinence" col={4}
        nameText="recognition" />

      <Text fontSize='14px'>◯排泄</Text>

      <RadioSheet2Form disable={disable} title="咀嚼" sign="※" valueAll={[true, false]} rdTit={["できる", "できない"]} name="is_crushing_process" col={4}
        nameText="crushing_process" />
      <RadioSheet2Form disable={disable} title="嚥下" sign="※" valueAll={[true, false]} rdTit={["正常", "困難"]} name="is_normal_swallow" col={4}
        nameText="swallow" />
      {/* <Sheet2Checkbox disable={disable} title="義歯" sign="※" col={4}
        nameText="denturesText"
        content={["あり(全)", "あり(上)", "あり(下)", "あり(一部)", "なし"]} name={["dentureAll", "dentureTop", "dentureBottom", "denturePartial", "dentureNone"]}
      /> */}
      <SelectOptions readOnly data={OPTIONS.dentures_option} />
    </div>
  )
}
export default Sheet2ExcreteForm
