import axiosClient from 'api/axiosClient'
import queryString from 'query-string'


const client = "/client/"

class ClientScheduleApi {


  getShiftSchedule = (idToken: any, param: any) => {
    const qs = queryString.stringify(param)
    const url = `${client}schedules/summary?${qs}`
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient.get(url, config)
  }

  RegisterHoliday = (body: any, idToken: any, method: any) => {
    const url = `/staff/monthly_holidays`
    const config: any = {
      headers: { Authorization: `Bearer ${idToken}` },
    }
    return axiosClient({ url: url, method: method, headers: config, data: body })
  }
}

const clientScheduleApi = new ClientScheduleApi()
export default clientScheduleApi
