import {ReactNode} from 'react';
import { Radio, RadioGroupProps } from "antd";

interface IRadioGroup extends RadioGroupProps {
  children?: ReactNode
  className?: string
}
export default function RadioGroup({children, className = '', ...props}: IRadioGroup) {
  return(
    <Radio.Group className={className} {...props}>{children}</Radio.Group>
  )
}
