import { Select } from '@chakra-ui/react'
import { Input } from 'antd'
import { MyPagination } from 'component/admin/table'
import React from 'react'

const PaginationUI = ({total, current, setCurrent, perPage, setPerPage}: any) => {
  return (
    <div className='flex items-center justify-end gap-x-[16px] relative px-[10px] py-[20px] '>
      <div className='text-[#000000] text-[12px] leading-[20px]'>
      {(current - 1) * perPage + 1} ~ {current * perPage > total ? total : current * perPage}件(全{total}件中)
      </div>
      <div className='text-[#000000] text-[12px] leading-[20px]'>
        <Select border="1px solid #000" borderRadius='4px' height={8} value={perPage} onChange={(evt:any) => setPerPage(evt.target.value)} >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
        </Select>
        
      </div>
      <p className='text-[#000000] text-[12px] leading-[20px]' >件表示</p>
      <MyPagination
        total={total}
        defaultCurrent={current}
        onChange={setCurrent}
        perPage={perPage}
      />
      <div>
        <Input value={current} onChange={(evt:any) => setCurrent(evt.target.value)} className='w-[56px] border-[#000000]' style={{borderRadius:'4px'}} />
        <span className='text-[#000000] text-[12px] leading-[20px] ml-[2px]'>ページ</span>
      </div>
    </div>
  )
}

export default PaginationUI
