import { PATIENT_HS_STAFF } from "api/apiConstant";
import BaseApi from "api/baseApi";

class HSPatient extends BaseApi {
  getList(body: {
    per?: number;
    page?: number;
    fullname?: string;
    status?: string;
    sortKey?: string;
    order?: string;
    care_plan_date?: string;
    year_month_payment?: string;
  }) {
    return this.get(PATIENT_HS_STAFF, body);
  }

  getDetail(id: any) {
    return this.get(`${PATIENT_HS_STAFF}/${id}`);
  }
}

export default HSPatient;
