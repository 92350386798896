import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Form, Checkbox, DatePicker, TimePicker, Select, Input, Spin } from "antd";
import "dayjs/locale/zh-cn";
// import "./style.scss";
import dayjs, { Dayjs } from "dayjs";
import apiFactory from "api";
import { ButtonOutline, ButtonSolid } from "component/button";
import ModalCustom from "component/admin/modal";
import { Row, Space } from "antd";
import { toast } from "react-toastify";
import { useDrawerContext } from "@chakra-ui/react";
import { CloseOutlined } from "@ant-design/icons";
import MESSAGES from "constant/messages";
import SelectWithData from "component/SelectWithData";
import axiosClient from "api/axiosClient";
import useService from "hook/useService";
import { DATE_FORMAT_2, JP_DATEPICKER_FORMAT } from "constant/date";
import { LoadingOutlined } from "@ant-design/icons";

const { RangePicker } = TimePicker;
const { Item, useWatch } = Form;

dayjs.extend(customParseFormat);

const initialFormValues = {
  // date: dayjs(),
  // time: [dayjs(), undefined],
  // isFilterNurse: false,
  isDelete: false,
};

const antLoadIcon = <LoadingOutlined style={{ fontSize: 32, color: "#000" }} spin />;

interface IScheduleDetail {
  openState: [boolean, Dispatch<SetStateAction<boolean>>]
  title: string;
  refetchData: () => void;
  selectedShiftItemState: [any, Dispatch<any>];
  allShiftItemsState: [any[], Dispatch<SetStateAction<any[]>>];
  setIsEdited: Dispatch<SetStateAction<boolean>>;
}

const ScheduleDetail = ({
  openState,
  title,
  refetchData,
  selectedShiftItemState,
  allShiftItemsState,
  setIsEdited,
}: IScheduleDetail) => {
  const [form] = Form.useForm();
  const disabledDate = (current: Dayjs) =>
    current && !current.isAfter(dayjs(), "M");
  const service = useService();
  const [open, setOpen] = openState;
  const [selectedShiftItem, setSelectedShiftItem] = selectedShiftItemState;
  const [allShiftItems, setAllShiftItems] = allShiftItemsState;
  const [loading, setLoading] = useState<boolean>(true);

  const dateValue = useWatch("date", form);
  const patientValue = useWatch("patient", form);
  const timeValue = useWatch("time", form);

  const getPatientOptions = async () =>
    (
      await axiosClient.get(service.PATIENTS, {
        params: { get_all: true, schedule_date: dateValue },
      })
    ).data.data.map((i: any) => ({ label: i.family_name, value: i.id }));

  const handleClose = () => {
    setOpen(false);
    setSelectedShiftItem(undefined);
  };

  const handleSubmit = async (fieldValues: any) => {
    if (!selectedShiftItem?.schedule_date?.id) {
      return;
    }
    const { isDelete, nurse } = fieldValues;
    if (isDelete !== selectedShiftItem.delete) {
      const shiftDatas = [...allShiftItems];
      const currentRow = shiftDatas[selectedShiftItem.originalRow];
      const updatedRow = currentRow.map((item: any) => {
        if (item.dragId === selectedShiftItem.dragId) {
          item.delete = isDelete;
          item.edited = true;
          return item;
        } else {
          return item;
        }
      });
      shiftDatas[selectedShiftItem.originalRow] = updatedRow;
      setAllShiftItems(shiftDatas);
      handleClose();
      setIsEdited(true);
    }

    if (!nurse || nurse === selectedShiftItem.schedule_date.nurse_id) {
      return;
    }
    // fieldValues.date = fieldValues.date?.format(DATE_FORMAT_2)
    try {
      const response = await apiFactory.shiftManagementApi.updateNurseStaffForSchedule({
        scheduleDateId: selectedShiftItem.schedule_date.id,
        nurse_id: nurse,
      })
      if (response) {
        form.resetFields();
        refetchData();
        handleClose();
        setIsEdited(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTimeRangeOptions = async () =>
    (
      await axiosClient.get(service.SCHEDULES, {
        params: {
          patient_id: patientValue?.value,
          date: dayjs(dateValue).format("YYYY/MM/DD"),
        },
      })
    ).data.map((i: any) => ({
      label: i.time_range,
      value: i.time_range,
      data: i,
    }));

  const getStaffOptions = async () => selectedShiftItem?.schedule_date?.id &&
    (
      await axiosClient.get(service.SCHEDULES + "/nursing_staffs", {
        params: {
          date: dayjs(dateValue).format("YYYY/MM/DD"),
          time_range: timeValue,
          schedule_date_id: selectedShiftItem.schedule_date.id,
        },
      })
    ).data.nursing_staffs.map((i: any) => ({
      label: i.family_name,
      value: i.id,
    }));

  useEffect(() => {
    if (timeValue)
      axiosClient
        .get(service.SCHEDULES, {
          params: {
            time_range: timeValue,
            patient_id: patientValue?.value,
            date: dayjs(dateValue).format("YYYY/MM/DD"),
          },
        })
        .then((res) => {});
  }, [timeValue]);

  useEffect(() => {
    if (!selectedShiftItem?.schedule_date?.id) {
      return;
    }

    const getScheduleDateDetail = async () => {
      try {
        const response = await axiosClient.get(`${service.SCHEDULE_DATES}/${selectedShiftItem.schedule_date.id}`);
        if (response) {
          form.setFieldValue("date", dayjs(response.data.date));
          form.setFieldValue("patient", {
            value: response.data.scheduleable.patient.id,
            label: response.data.scheduleable.patient.family_name
          });
          form.setFieldValue("time", response.data.time_range);
          form.setFieldValue("service", response.data.scheduleable.service.service_name);
          form.setFieldValue("service_type", response.data.scheduleable.service_type.detail);
          if (response.data.nurse) {
            form.setFieldValue("nurse", {
              value: response.data.nurse.id,
              label: response.data.nurse.family_name
            });
          }
        }
        form.setFieldValue("isDelete", selectedShiftItem.delete);
      } catch (error) {
        // error
      } finally {
        setLoading(false);
      }
    }

    getScheduleDateDetail();
  }, [selectedShiftItem])
  return (
    <ModalCustom
      className="shift-modal"
      title={title}
      closeIcon={<CloseOutlined style={{ color: "#000000" }} />}
      footer={null}
      open={open}
      onCancel={handleClose}
    >
      {
        loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spin indicator={antLoadIcon} />
          </div>
        ) : (
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            className="mt-[26px]"
            form={form}
            initialValues={initialFormValues}
          >
            <Item
              name="date"
              label="日にち"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <DatePicker
                format={JP_DATEPICKER_FORMAT}
                onChange={() => {
                  form.resetFields(["time"]);
                  form.resetFields(["nurse"]);
                  form.resetFields(["service"]);
                  form.resetFields(["service_type"]);
                  form.resetFields(["patient"]);
                }}
                className="w-full"
                disabledDate={disabledDate}
                disabled
                placeholder="選択してください"
                defaultPickerValue={dayjs().add(1, "M").date(1)}
              />
            </Item>
            <Item
              rules={[{ required: true, message: MESSAGES.requireField }]}
              name="patient"
              label="お客様"
            >
              <SelectWithData
                disabled
                onChange={() => {
                  form.resetFields(["time"]);
                  form.resetFields(["nurse"]);
                  form.resetFields(["sefrvice"]);
                  form.resetFields(["service_type"]);
                  form.resetFields(["service_id"]);
                  form.resetFields(["service_type_id"]);
                }}
                getOptions={getPatientOptions}
                placeholder="選択してください"
                allowGetOption={Boolean(dateValue)}
                getOptionDepdendancy={[dateValue]}
              />
            </Item>
            <Item
              name="time"
              label="時間"
              rules={[{ required: true, message: MESSAGES.requireField }]}
            >
              <SelectWithData
                getOptions={getTimeRangeOptions}
                getOptionDepdendancy={[patientValue?.value, dateValue]}
                allowGetOption={Boolean(patientValue?.value && dateValue)}
                disabled
                placeholder="選択してください"
                onChange={(value, option: any) => {
                  form.setFieldValue(
                    "service",
                    option.data.scheduleable.service.service_name
                  );
                  form.setFieldValue(
                    "service_id",
                    option.data.scheduleable.service.id
                  );
                  form.setFieldValue(
                    "service_type",
                    option.data.scheduleable.service_type.detail
                  );
                  form.setFieldValue(
                    "service_type_id",
                    option.data.scheduleable.service_type.id
                  );
                  form.resetFields(["nurse"]);
                }}
              />
            </Item>
            <Item name="service_id" hidden>
              <div />
            </Item>
            <Item name="service" label="サービス名称">
              <Input disabled placeholder="選択してください" />
            </Item>
            <Item name="service_type_id" hidden>
              <div />
            </Item>
            <Item name="service_type" label="サービス内容">
              <Input disabled placeholder="選択してください" />
            </Item>
            <Item
              name="nurse"
              label="介護スタッフ"
              // rules={[{ required: deleteValue ? false : true, message: MESSAGES.requireField }]}
            >
              <SelectWithData
                getOptions={getStaffOptions}
                allowGetOption={Boolean(timeValue && dateValue)}
                getOptionDepdendancy={[timeValue, dateValue]}
                disabled={!dateValue || !timeValue}
                placeholder="選択してください"
              />
            </Item>
            {/* <Item name="isFilterNurse" valuePropName="checked">
              <Checkbox>該当時間内に勤務中・勤務予定の者のみ表示</Checkbox>
            </Item> */}

            <Row justify="space-between">
              <Space>
                <Item name="isDelete" valuePropName="checked">
                  <Checkbox className="custom-checkbox" onChange={(e) => {
                    form.setFieldValue("isDelete", e.target.checked);
                  }}>削除</Checkbox>
                </Item>
              </Space>
              <Space size={8}>
                <ButtonOutline
                  onClick={handleClose}
                  className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[16px]"
                >
                  キャンセル
                </ButtonOutline>
                <ButtonSolid
                  // onClick={form.submit}
                  type="submit"
                  className="!max-h-[35px] !text-[14px] !leading-[24px] !px-[54px]"
                >
                  保存
                </ButtonSolid>
              </Space>
            </Row>
          </Form>
        )
      }
    </ModalCustom>
  );
};

export default ScheduleDetail;
