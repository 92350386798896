import { NURSING_HS_ADMIN } from "../../apiConstant";
import BaseApi from "api/baseApi";

class HSNursing extends BaseApi {
  getList(body: {
    per: number;
    page: number;
    // fullname: string | null,
    // status: string | null,
    // sortKey: string | null,
    // order: string | null,
  }) {
    return this.get(NURSING_HS_ADMIN, body);
  }
}

export default HSNursing;
