export const objectToFormData = (object: { [key: string]: any }): FormData => {
  const formData = new FormData();

  Object.keys(object).forEach((key) => {
    const value = object[key] || "";
    if (Array.isArray(value)) {
      formData.append(key, value.join(","));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const convertToPlainObject = (
  object: { [key: string]: any },
  keyArrayConditions?: (key: string) => boolean
) => {
  let _object: any = {};

  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (
      value &&
      typeof value === "string" &&
      (value.includes(",") || (keyArrayConditions && keyArrayConditions(key)))
    ) {
      _object[key] = value.split(",");
    } else {
      _object[key] = value;
    }
  });

  return _object;
};
