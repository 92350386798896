import { TablePaginationConfig } from "antd";
import { DEFAULT_PAGE_OPTIONS } from "constant/apiRequest";
import { Dispatch, SetStateAction } from "react";

interface UsePaginationSettingsProps {
  page: number;
  limit: number;
  total: number;
  setLimit: Dispatch<SetStateAction<number>>;
  setPage: Dispatch<SetStateAction<number>>;
}

const usePaginationSettings = (props: UsePaginationSettingsProps) => {
  const { page, limit, total, setPage, setLimit } = props;

  return {
    position: ["bottomRight", "topRight"],
    showSizeChanger: true,
    showTotal: () => (
      <div>
        {(page - 1) * limit + 1} ~ {page * limit > total ? total : page * limit}
        件(全{total}件中)
      </div>
    ),
    showQuickJumper: true,
    total,
    pageSize: limit,
    current: page,
    pageSizeOptions: DEFAULT_PAGE_OPTIONS,
    onChange: setPage,
    onShowSizeChange(_, size) {
      setLimit(size);
    },
  } as TablePaginationConfig;
};

export default usePaginationSettings;
