import React, { Dispatch, SetStateAction, useMemo } from "react";
import { CalendarCustom } from "component/StyledComponent";
import { TabPanel } from "@chakra-ui/react";
import { ButtonOutline, ButtonSolid } from "component/button";
import TableInfoShift from "component/admin/table/TableInfoShift";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./styles.scss";
import { Col, Row, Space, Table, Typography } from "antd";
import { IShiftManagementScheduleByMonth } from "../../ViewCalendar";
import { DATE_FORMAT_2 } from "constant/date";
import useService from "hook/useService";
import axiosClient from "api/axiosClient";
import { ColumnType } from "antd/es/table";
import { toast } from "react-toastify";
import Prompt from "../Week/promt";

dayjs.extend(customParseFormat);

interface IMonth {
  dataHeadTd: string[];
  nurseTimeInfo: {
    name: string;
    time: string;
  }[][];
  month: Dayjs;
  setChangeMonth: (value: Dayjs) => void;
  type: "client" | "staff";
  modalOpen: boolean;
  setInitial: any;
  initial: boolean;
  setShowRegisterButtons: Dispatch<SetStateAction<boolean>>;
  isShiftEditedState: [boolean, Dispatch<SetStateAction<boolean>>];
  createShift: any;
}
const columns: ColumnType<any>[] = [
  { title: "介護スタッフ", dataIndex: "name" },
  { title: "シフト合計時間", dataIndex: "value" },
];

export default function Month({
  setChangeMonth,
  month,
  type,
  modalOpen,
  initial,
  setInitial,
  setShowRegisterButtons,
  isShiftEditedState,
  createShift,
}: IMonth) {
  const service = useService();
  const [data, setData] = React.useState<any>();
  const [isShiftEdited, setIsShiftEdited] = isShiftEditedState;

  const fullCellComponent = (date: Dayjs, info: any) => {
    const isToday = date.format("YYYY/MM/DD") === dayjs().format("YYYY/MM/DD");
    const bgColor =
      date.format("MM") !== month.format("MM") ||
        !data?.shifts_with_nurses_per_day?.[date.format("YYYY-MM-DD")]
        ? "white"
        : data?.shifts_with_nurses_per_day?.[date.format("YYYY-MM-DD")] ===
          data?.total_shifts_per_day?.[date.format("YYYY-MM-DD")]
          ? "#1ad633"
          : "#ffff00";
    return (
      <div
        className={`ant-picker-cell-inner ant-picker-calendar-date ${isToday ? "ant-picker-calendar-date-today" : ""
          }`}
        style={{ backgroundColor: bgColor, margin: 0 }}
      >
        <div className="ant-picker-calendar-date-value !text-black">
          {date.format("D")}
        </div>
        <div className="ant-picker-calendar-date-content">
          {date.format("MM") === month.format("MM") &&
            data?.total_shifts_per_day?.[date.format("YYYY-MM-DD")] && (
              <Space size={0} direction="vertical" className="font-normal pt-1">
                <p>
                  {data?.shifts_with_nurses_per_day?.[
                    date.format("YYYY-MM-DD")
                  ] || 0}
                  /{data?.total_shifts_per_day?.[date.format("YYYY-MM-DD")]}
                </p>
                <p>
                  {data?.total_time_shifts_per_day?.[date.format("YYYY-MM-DD")]}
                </p>
              </Space>
            )}
        </div>
      </div>
    );
  };
  const workTimeData = (data?.nurse_work_duration_on_month || []).map((item: any) => ({
    name: item.family_name,
    value: item.total_work_time,
  }));
  const isShowSentButton =
    data?.confirm_shift?.confirmed_by_staff &&
    !data?.confirm_shift?.confirmed_by_admin;
  const isShowApproveButton =
    data?.confirm_shift?.confirmed_by_staff &&
    data?.confirm_shift?.confirmed_by_admin &&
    data?.confirm_shift?.status !== "approved";
  const onPanelChange = (value: Dayjs) => setChangeMonth(value);
  const fetchData = async () => {
    try {
      const response: any = await axiosClient.get(
        service.NURSING_SHIFT_MANAGES + "/summary",
        {
          params: {
            month_date: month.format("YYYY/MM/01"),
            tab: type,
          },
        }
      );
      // setInitial(response.data.has_initial);
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSave = async (button_type: "cancel" | "draft" | "sent") => {
    try {
      const response = await axiosClient.put(
        type === "client"
          ? service.SCHEDULES
          : service.NURSING_SHIFT_MANAGES + "/update_shift",
        {
          button_type,
        }
      );
      toast.success(response.data.success);
      setInitial(false)
      fetchData();
    } catch (error) {
      toast.error((error as string).toString());
    } finally {
      setIsShiftEdited(false);
    }
  };
  React.useEffect(() => {
    if (!modalOpen) fetchData();
  }, [type, month, modalOpen, createShift]);
  React.useEffect(() => {
    if (data) {
      if (Object.values(data).length === 0) {
        setShowRegisterButtons(false);
        return;
      }

      if (data.confirm_shift && (Object.values(data.confirm_shift).length === 0)) {
        setShowRegisterButtons(false);
        return;
      }

      setShowRegisterButtons(true);
    }
  }, [data]);
  if (data)
    return (
      <>
        <CalendarCustom
          headerRender={() => false}
          value={month}
          className="calendar pt-10"
          // cellRender={cellRenderComponent}
          onPanelChange={onPanelChange}
          fullCellRender={fullCellComponent}
        />
        <p className="text-[#000000] text-[16px] leading-[18px] font-sans mt-[82px] mb-[10px] font-bold">
          労働時間
        </p>
        <Row gutter={[8, 16]}>
          <Col span={8}>
            <Row className="font-bold bg-[var(--secondary-color)] border border-solid border-black -mt-[1px]">
              <Col
                className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                span={12}
                style={{ borderRight: "1px solid" }}
              >
                介護スタッフ
              </Col>
              <Col
                className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                span={12}
              >
                シフト合計時間
              </Col>
            </Row>
            {(workTimeData.slice(0, Math.ceil(workTimeData.length / 3)).length
              ? workTimeData.slice(0, Math.ceil(workTimeData.length / 3))
              : [{ name: "--", value: "--" }]
            ).map((item: any, index: any) => (
              <Row
                style={{
                  background: index % 2 === 1 ? "var(--secondary-color)" : "",
                }}
                key={index}
                className=" border border-solid border-black -mt-[1px]"
              >
                <Col
                  className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  span={12}
                  style={{ borderRight: "1px solid" }}
                >
                  {item.name}
                </Col>
                <Col
                  className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  span={12}
                >
                  {item.value}
                </Col>
              </Row>
            ))}
          </Col>
          <Col span={8}>
            <Row className="font-bold bg-[var(--secondary-color)] border border-solid border-black -mt-[1px]">
              <Col
                className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                span={12}
                style={{ borderRight: "1px solid" }}
              >
                介護スタッフ
              </Col>
              <Col
                className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                span={12}
              >
                シフト合計時間
              </Col>
            </Row>
            {(workTimeData.slice(
              Math.ceil(workTimeData.length / 3),
              Math.ceil(workTimeData.length / 3) * 2
            ).length
              ? workTimeData.slice(
                Math.ceil(workTimeData.length / 3),
                Math.ceil(workTimeData.length / 3) * 2
              )
              : [{ name: "--", value: "--" }]
            ).map((item: any, index: any) => (
              <Row
                style={{
                  background: index % 2 === 1 ? "var(--secondary-color)" : "",
                }}
                key={index}
                className=" border border-solid border-black -mt-[1px]"
              >
                <Col
                  className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  span={12}
                  style={{ borderRight: "1px solid" }}
                >
                  {item.name}
                </Col>
                <Col
                  className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  span={12}
                >
                  {item.value}
                </Col>
              </Row>
            ))}
          </Col>
          <Col span={8}>
            <Row className="font-bold bg-[var(--secondary-color)] border border-solid border-black -mt-[1px]">
              <Col
                className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                span={12}
                style={{ borderRight: "1px solid" }}
              >
                介護スタッフ
              </Col>
              <Col
                className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                span={12}
              >
                シフト合計時間
              </Col>
            </Row>
            {(workTimeData.slice(
              Math.ceil(workTimeData.length / 3) * 2,
              Math.ceil(workTimeData.length)
            ).length
              ? workTimeData.slice(
                Math.ceil(workTimeData.length / 3) * 2,
                Math.ceil(workTimeData.length)
              )
              : [{ name: "--", value: "--" }]
            ).map((item: any, index: any) => (
              <Row
                style={{
                  background: index % 2 === 1 ? "var(--secondary-color)" : "",
                }}
                key={index}
                className=" border border-solid border-black -mt-[1px]"
              >
                <Col
                  className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  span={12}
                  style={{ borderRight: "1px solid" }}
                >
                  {item.name}
                </Col>
                <Col
                  className="p-2 whitespace-nowrap overflow-hidden text-ellipsis"
                  span={12}
                >
                  {item.value}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        {
          type && (
            <Prompt 
              when={isShiftEdited}
              message="Are you sure you want to leave?"
              change={() => handleSave("cancel")}
              setInitial={setIsShiftEdited}
            />
          )
        }
        <div className="flex justify-end gap-x-[8px] mt-[36px] mb-[39px]">
          <ButtonOutline
            hidden={!isShiftEdited}
            className="!text-[14px] !leading-[24px] !px-[15px] !max-h-[35px]"
            onClick={() => handleSave("cancel")}
          >
            キャンセル
          </ButtonOutline>
          <ButtonSolid
            hidden={!isShiftEdited}
            className="!text-[14px] !leading-[24px] !px-[34px] !max-h-[35px]"
            onClick={() => handleSave("draft")}
          >
            下書き保存
          </ButtonSolid>
        </div>
        <div
          className="flex justify-end mb-[36px]"
          hidden={!month.isAfter(dayjs(), "M") || !isShowSentButton}
        >
          <ButtonSolid
            className="!text-[14px] !leading-[24px] !px-[34px] !max-h-[35px]"
            onClick={() => handleSave("sent")}
          >
            シフトを送付
          </ButtonSolid>
        </div>
        <div
          className="flex justify-end"
          hidden={!month.isAfter(dayjs(), "M") || !isShowApproveButton}
        >
          <ButtonSolid className="!text-[14px] !leading-[24px] !px-[34px] !max-h-[35px]">
            シフトを確定
          </ButtonSolid>
        </div>
      </>
    );
  return <></>;
}
