const MESSAGES = {
  requireField: "この項目は必須です",
  tokenExpired:
    "トークンが無効または期限切れです。もう一度ログインしてください",
  serverError: "内部サーバーエラー",
  min6max15character: "パスワードの長さは 6 ～ 15 文字である必要があります",
  invalidCharacter: "無効な文字です",
  onlyText: "文字だけを入力してください",
  min10max20number: "この項目は10桁から20桁以内で入力してください",
  min10max25number: "この項目は10桁から25桁以内で入力してください",
  requirePasswordPattern:
    "大文字と小文字、数字を使用し、特殊文字を含める必要があります",
  invalidKatakanaCharacter: "カタカナで入力してください",
  invalidFileFormat: "アップロード可能なファイル拡張子はJPEG・PNG・GIFです",
  invalidFileCapacity_1GB: "画像容量が１GBを超えています",
  invalidPostCode: "郵便番号は８桁の数字で入力してください",
  containTextNumber: "8桁以上の英数字で入力してください。",
};

export default MESSAGES;
