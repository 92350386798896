import styled from "@emotion/styled";

const StyleFilterHistory = styled("div")`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input,
  .ant-select-selector {
    border-color: #d9d9d9 !important;
  }
`;

export default StyleFilterHistory;
