import { Text } from '@chakra-ui/react'
import React from 'react'
import Sheet2Checkbox from './Sheet2Checkbox'
import RadioSheet2Form from './RadioSheet2Form'
import { OPTIONS, SelectOptions } from 'page/Customer/CustomerDetail/AssesmentSheet2/function'

const Sheet2Move = ({ disable }: any) => {
  return (
    <div>
      <Text fontSize='14px'>◯移動</Text>
      {/* <Sheet2Checkbox disable={disable} title="形態" sign="※" col={4}
       nameText="formText"
        content={["自立", "車椅子", "歩行器", "付添"]} name={["formIndependece", "formWheelchair", "formWalk", "formAttendant"]} /> */}
      <SelectOptions readOnly data={OPTIONS.form_movement_option} />
      <RadioSheet2Form disable={disable} title="移動" sign="※" valueAll={["independence", "supervision", "partial_assistance", "full_assistance"]}
      rdTit={["自立", "見守り", "一部介助", "全介助"]} name="move_level" col={4}
        nameText="move" />

      <RadioSheet2Form disable={disable} title="立位" sign="※" valueAll={["independence", "supervision", "partial_assistance", "full_assistance"]}
      rdTit={["自立", "見守り", "一部介助", "全介助"]} name="stand_level" col={4}
        nameText="stand" />

      <RadioSheet2Form disable={disable} title="座位" sign="※" valueAll={["independence", "supervision", "partial_assistance", "full_assistance"]}
      rdTit={["自立", "見守り", "一部介助", "全介助"]} name="sit_level" col={4}
        nameText="sit" />

    </div>
  )
}
export default Sheet2Move
