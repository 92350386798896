export enum MappingNursingStatus {
  active = "在籍中",
  on_leave = "休職中",
  resigned = "退職済",
}

export enum MappingComplextion {
  good = "良",
  bad = "不良",
}

export enum MappingSweating {
  yes = "有",
  no = "無",
}

export enum MappingDevision {
  normal = "介護",
  disability = "障害",
}
