import styled from "@emotion/styled";

const Styles = styled.div`
  table,
  th,
  td {
    border-collapse: collapse;
    white-space: nowrap;
  }
  th,
  td {
    &.ant-table-cell {
      &.col-date {
        padding: 0 !important;
        min-width: 30px;
      }
    }
  }
`;
export default Styles;
