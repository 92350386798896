import { Text } from '@chakra-ui/react'
import React from 'react'
import Sheet2Checkbox from './Sheet2Checkbox'
import RadioSheet2Form from './RadioSheet2Form'
import { OPTIONS, SelectOptions } from 'page/Customer/CustomerDetail/AssesmentSheet2/function'

const Sheet2EachPart = ({ disable }: any) => {
  return (
    <div>
      <Text fontSize='14px'>◯各部位</Text>
      {/* <Sheet2Checkbox disable={disable} title="首" sign="※" col={4}
        nameText="neckText"
        content={["麻痺", "拘縮", "その他", "問題なし"]} name={["neckParalysis", "neckContracture", "neckOthers", "neckNoProblem"]}
      />

      <Sheet2Checkbox disable={disable} title="上肢" sign="※" col={4}
        nameText="upperLimbText"
        content={["麻痺", "拘縮", "その他", "問題なし"]} name={["upperLimbParalysis", "upperLimbContracture", "upperLimbOthers", "upperLimbNoProblem"]}
      />

      <Sheet2Checkbox disable={disable} title="腰" sign="※" col={4}

        content={["麻痺", "拘縮", "その他", "問題なし"]} name={["waistParalysis", "waistContracture", "waistOthers", "waistNoProblem"]}
      />

      <Sheet2Checkbox disable={disable} title="股関節" sign="※" col={4}
        nameText="hipJointText"
        content={["麻痺", "拘縮", "その他", "問題なし"]} name={["hipJointParalysis", "hipJointContracture", "hipJointOthers", "hipJointNoProblem"]}
        />

      <Sheet2Checkbox disable={disable} title="下肢" sign="※" col={4}
        nameText="lowerLimbText"
        content={["麻痺", "拘縮", "その他", "問題なし"]} name={["lowerLimbParalysis", "lowerLimbContracture", "lowerLimbOthers", "lowerLimbNoProblem"]}
         /> */}
      <SelectOptions readOnly data={OPTIONS.neck_option} />
      <SelectOptions readOnly data={OPTIONS.upper_limb_option} />
      <SelectOptions readOnly data={OPTIONS.belt_option} />
      <SelectOptions readOnly data={OPTIONS.hip_joint_option} />
      <SelectOptions readOnly data={OPTIONS.bottom_option} />

      <RadioSheet2Form disable={disable} valueAll={[true, false]}
      title="視力" sign="※" rdTit={["障害あり", "なし"]} name="is_disability_sight" col={4}
        nameText="sight" />

      <RadioSheet2Form disable={disable} valueAll={[true, false]}
      title="眼鏡" sign="※" rdTit={["障害あり", "なし"]} name="has_glasses" col={4}
        nameText="glasses" />

      <RadioSheet2Form disable={disable} valueAll={[true, false]}
      title="聴力" sign="※" rdTit={["障害あり", "なし"]} name="is_disability_hearing" col={4}
        nameText="hearing" />

      <RadioSheet2Form disable={disable} valueAll={[true, false]}
      title="言語" sign="※" rdTit={["障害あり", "なし"]} name="is_disability_language" col={4}
        nameText="language" />
    </div>
  )
}
export default Sheet2EachPart
