import styled from "@emotion/styled";
import { Collapse } from "antd";

const StyledCollapse = styled(Collapse)`
  border-top: 0;
  border-left: 0;
  border-right: 0;

  .ant-collapse-header {
    background-color: transparent;
    border-radius: 0 !important;
  }

  .ant-collapse-item {
    background-color: var(--white-color);
    border-radius: 0 !important;
  }

  .ant-collapse-content {
    background-color: transparent;
    border-top: 0;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-item-active {
    background-color: #ecf5ff;

    .heading {
      color: var(--iris-100);
    }
    .icon {
      transform: rotate(180deg);
    }
  }
`;

export default StyledCollapse;
