import React from "react";

const HealthyCare = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_159_6950)">
        <path
          d="M19.3369 16.0115C19.659 15.7056 19.9156 15.3373 20.0908 14.9292C20.2661 14.521 20.3565 14.0814 20.3565 13.6372C20.3565 13.193 20.2661 12.7534 20.0908 12.3452C19.9156 11.937 19.659 11.5688 19.3369 11.2629C18.6776 10.6371 17.8031 10.2882 16.8941 10.2882C15.985 10.2882 15.1106 10.6371 14.4512 11.2629L11.9998 13.7143L9.54836 11.2629C8.88898 10.6371 8.01457 10.2882 7.1055 10.2882C6.19642 10.2882 5.32202 10.6371 4.66264 11.2629C4.34052 11.5688 4.08402 11.937 3.90874 12.3452C3.73346 12.7534 3.64307 13.193 3.64307 13.6372C3.64307 14.0814 3.73346 14.521 3.90874 14.9292C4.08402 15.3373 4.34052 15.7056 4.66264 16.0115L11.9998 23.1429L19.3369 16.0115ZM11.9998 7.71432C12.9091 7.71432 13.7812 7.3531 14.4242 6.71012C15.0671 6.06713 15.4284 5.19506 15.4284 4.28575C15.4284 3.37644 15.0671 2.50436 14.4242 1.86138C13.7812 1.2184 12.9091 0.857178 11.9998 0.857178C11.0905 0.857178 10.2184 1.2184 9.57542 1.86138C8.93244 2.50436 8.57121 3.37644 8.57121 4.28575C8.57121 5.19506 8.93244 6.06713 9.57542 6.71012C10.2184 7.3531 11.0905 7.71432 11.9998 7.71432Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_159_6950">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HealthyCare;
