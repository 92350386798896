import { HOURS_PER_DAY, TIME_FORMAT } from "constant/date";
import dayjs from "dayjs";
import { flattenDeep, intersection, cloneDeep } from "lodash";
import { useMemo } from "react";
import { PatientSchedule, Schedule } from "types/admin";
import { unique } from "utils/array";

interface usePatientSchedulesProps {
  patientSchedules: PatientSchedule[];
}

const MAX_LOOP_COUNT = 10000;
const HAFT_OF_HOUR = 30;
const NUM_OF_SECTIONS = 48;
const SEPERATOR = ":";

const usePatientSchedules = ({
  patientSchedules,
}: usePatientSchedulesProps) => {
  const scheduleTimes = useMemo(() => {
    const startTime = dayjs().startOf("day");

    const scheduleTimes = Array.from({ length: NUM_OF_SECTIONS }).map(
      (_, index) => {
        return dayjs(startTime)
          .add(index * HAFT_OF_HOUR, "minutes")
          .format(TIME_FORMAT);
      }
    );

    return scheduleTimes;
  }, []);

  const mappingPatientSchedules = (patientSchedules: PatientSchedule[]) => {
    const listSchedules = patientSchedules?.map((patientSchedule) => {
      return patientSchedule.schedules?.map((item) => {
        const [startTimeHours, startTimeMinutes] =
          item.start_time_format?.split(SEPERATOR);
        const [endTimeHours, endTimeMinutes] =
          item.end_time_format?.split(SEPERATOR);

        let startTime = dayjs()
          .set("hours", parseInt(startTimeHours))
          .set("minutes", parseInt(startTimeMinutes))
          .set("milliseconds", 0);

        let endTime = dayjs()
          .set(
            "hours",
            parseInt(endTimeHours) === HOURS_PER_DAY
              ? 0
              : parseInt(endTimeHours)
          )
          .set("minutes", parseInt(endTimeMinutes))
          .set("milliseconds", 0);

        if (endTime.get("hour") === 0 && endTime.get("minute") === 0) {
          endTime = endTime.set("hour", 23).set("minute", 59).set("second", 59);
        }

        const rootTime = dayjs().startOf("day");

        const offset =
          dayjs(startTime).diff(rootTime, "minutes") / HAFT_OF_HOUR;

        const numOfSections = Math.round(
          dayjs(endTime).diff(startTime, "minutes") / HAFT_OF_HOUR
        );

        return { ...item, offset, numOfSections };
      });
    });

    return listSchedules;
  };

  const isConflict = (
    currentSchedules: Schedule[],
    prevSchedules: Schedule[],
    currentSchedule: Schedule
  ): boolean => {
    const prevColumn = unique(prevSchedules?.map((item) => item?.time));
    const currentColumn = unique(
      currentSchedules
        ?.filter((item) => item?.label === currentSchedule?.label)
        ?.map((item) => item?.time)
    );

    return intersection(currentColumn, prevColumn).length > 0;
  };

  const getSchedulesByColumnIndex = (
    schedules: Schedule[][],
    columnIndex: number
  ): Schedule[] => {
    const currentSchedules: Schedule[] = [];

    for (let i = 0; i < schedules.length; i++) {
      for (let j = 0; j < schedules[i].length; j++) {
        if (j === columnIndex) {
          currentSchedules.push(schedules[i][j]);
        }
      }
    }

    return currentSchedules?.filter((item) => !!item);
  };

  const uniqueSchedules = (schedules: Schedule[]): Schedule[] => {
    const newSchedules: Schedule[] = [];

    schedules.forEach((schedule) => {
      const isExist = newSchedules?.some(
        (item) => item?.label === schedule?.label
      );

      if (!isExist) {
        newSchedules.push(schedule);
      }
    });

    return newSchedules;
  };

  const removeEmptyColumns = (listSchedules: Schedule[][]): Schedule[][] => {
    const cloneListSchedules = cloneDeep(listSchedules);

    return cloneListSchedules?.map((listSchedule) => {
      const newSchedules = listSchedule?.reduce(
        (acc: Schedule[], schedule, columnIndex) => {
          const isEmptyColumn = cloneListSchedules?.every(
            (schedules) => !schedules?.[columnIndex]
          );

          if (!isEmptyColumn) {
            acc?.push(schedule);
          }

          return acc;
        },
        []
      );

      return newSchedules;
    });
  };

  const transformPatientSchedules = (
    patientSchedules: PatientSchedule[]
  ): PatientSchedule[] => {
    let schedulesMaxLength = uniqueSchedules(
      flattenDeep(patientSchedules?.map((item) => item?.schedules))
    );

    schedulesMaxLength = schedulesMaxLength?.sort(
      (a: Schedule, b: Schedule) => {
        return (
          new Date(a?.created_at)?.getTime() -
          new Date(b?.created_at)?.getTime()
        );
      }
    );

    const newPatientSchedules = patientSchedules?.map((item) => {
      const schedules = schedulesMaxLength?.map((baseSchedule) => {
        const insertedSchedule = item?.schedules?.find(
          (item) =>
            item?.patient_id === baseSchedule?.patient_id &&
            item?.label === baseSchedule?.label
        );

        return insertedSchedule
          ? {
              ...insertedSchedule,
              time: item.time,
            }
          : null;
      });

      return {
        ...item,
        schedules: schedules as Schedule[],
      };
    });

    return newPatientSchedules;
  };

  const canContinueSorting = (listSchedules: Schedule[][]): boolean => {
    let canSort = false;

    let listSchedulesLength = listSchedules?.length;

    for (let i = 0; i < listSchedulesLength; i++) {
      const cloneSchedules = cloneDeep(listSchedules[i]);

      let schedulesLength = cloneSchedules.length;

      for (let j = 0; j < schedulesLength; j++) {
        const schedule = cloneSchedules[j];

        if (!schedule || j === 0) {
          continue;
        }

        for (let k = 0; k < j; k++) {
          const preSchedules = getSchedulesByColumnIndex(listSchedules, k);
          const currentSchedules = getSchedulesByColumnIndex(listSchedules, j);

          if (!isConflict(currentSchedules, preSchedules, schedule)) {
            //Limit number of loops
            schedulesLength = j;
            listSchedulesLength = i;

            canSort = true;

            break;
          }
        }
      }
    }

    return canSort;
  };

  const moveColumn = (
    schedule: Schedule,
    to: number,
    listSchedules: Schedule[][]
  ): Schedule[][] => {
    let cloneListSchedules = cloneDeep(listSchedules);

    for (let i = 0; i < listSchedules?.length; i++) {
      const cloneSchedules = cloneDeep(cloneListSchedules[i]);

      for (let j = 0; j < cloneSchedules.length; j++) {
        if (!cloneSchedules[j] || j === 0) {
          continue;
        }

        for (let k = 0; k < j; k++) {
          if (schedule?.label === cloneSchedules?.[j]?.label && to === k) {
            [cloneSchedules[k], cloneSchedules[j]] = [
              cloneSchedules[j],
              cloneSchedules[k],
            ];
          }
        }
      }

      cloneListSchedules = cloneListSchedules?.map((schedules, rowIndex) => {
        if (rowIndex === i) {
          return cloneSchedules;
        }

        return schedules;
      });
    }

    return removeEmptyColumns(cloneListSchedules);
  };

  const isExistPrevColumn = (
    listSchedules: Schedule[][],
    columnIndex: number,
    nextSchedules: Schedule[]
  ) => {
    let cloneListSchedules = cloneDeep(listSchedules);
    let listSchedulesLength = cloneListSchedules?.length;

    let existed = false;

    for (let i = 0; i < listSchedulesLength; i++) {
      const cloneSchedules = cloneDeep(cloneListSchedules[i]);

      let schedulesLength = columnIndex;

      for (let j = 0; j < schedulesLength; j++) {
        const schedule = cloneDeep(cloneSchedules[j]);

        if (!schedule || j === 0) {
          continue;
        }

        for (let k = 0; k < j; k++) {
          const preSchedules = getSchedulesByColumnIndex(cloneListSchedules, k);
          const currentSchedules = getSchedulesByColumnIndex(
            cloneListSchedules,
            j
          );

          if (
            !isConflict(currentSchedules, preSchedules, schedule) &&
            isConflict(currentSchedules, nextSchedules, schedule)
          ) {
            //Limit number of loops
            schedulesLength = j;
            listSchedulesLength = i;

            existed = true;

            break;
          }
        }
      }
    }

    return existed;
  };

  const sortListSchedules = (listSchedules: Schedule[][]): Schedule[][] => {
    let cloneListSchedules = cloneDeep(listSchedules);
    let loopCount = 0;

    do {
      loopCount += 1;

      let listSchedulesLength = cloneListSchedules?.length;

      for (let i = 0; i < listSchedulesLength; i++) {
        const cloneSchedules = cloneDeep(cloneListSchedules[i]);

        let schedulesLength = cloneSchedules.length;

        for (let j = 0; j < schedulesLength; j++) {
          const schedule = cloneDeep(cloneSchedules[j]);

          if (!schedule || j === 0) {
            continue;
          }

          for (let k = 0; k < j; k++) {
            const preSchedules = getSchedulesByColumnIndex(
              cloneListSchedules,
              k
            );
            const currentSchedules = getSchedulesByColumnIndex(
              cloneListSchedules,
              j
            );

            if (
              !isConflict(currentSchedules, preSchedules, schedule) &&
              !isExistPrevColumn(cloneListSchedules, j, currentSchedules)
            ) {
              //Limit number of loops
              schedulesLength = j;
              listSchedulesLength = i;

              cloneListSchedules = moveColumn(schedule, k, cloneListSchedules);

              break;
            }
          }
        }
      }
    } while (
      canContinueSorting(cloneListSchedules) &&
      loopCount < MAX_LOOP_COUNT
    );

    return cloneListSchedules;
  };

  const checkExistScheduleBefore = (
    listSchedules: Schedule[][],
    schedule: Schedule,
    rowIndex: number
  ) => {
    let isExist = false;

    for (let i = 0; i < rowIndex; i++) {
      const schedules = listSchedules[i];

      isExist = schedules.some((item) => item?.label === schedule?.label);
    }

    return isExist;
  };

  const groupSchedules = (listSchedules: Schedule[][]): Schedule[][] => {
    const newListSchedules = listSchedules?.map((schedules, rowIndex) => {
      return schedules?.map((schedule) => {
        const isExistSchedule = checkExistScheduleBefore(
          listSchedules,
          schedule,
          rowIndex
        );

        if (isExistSchedule || !schedule) {
          return null;
        }

        const numOfSections =
          dayjs(schedule?.scheduleable?.end_time).diff(
            schedule?.scheduleable?.start_time,
            "minutes"
          ) / HAFT_OF_HOUR;

        return { ...schedule, numOfSections };
      });
    });

    return newListSchedules as Schedule[][];
  };

  const newPatientSchedules = useMemo(() => {
    return mappingPatientSchedules(patientSchedules);
  }, [patientSchedules]);

  return { patientSchedules: newPatientSchedules, scheduleTimes };
};

export default usePatientSchedules;
