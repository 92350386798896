import { useEffect, useState } from 'react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import apiFactory from "api";

function Prompt({ when = false, message, change, setInitial }: any) {
  const [block, setBlock] = useState(false)
  useEffect(() => {
    if (when) {
      setBlock(when)
    }
  }, [when])
  //handle save or unsave draft

  // const saveChange = async (type: string) => {
  //   try {
  //     const res = await apiFactory.shiftManagementApi.saveWeekSchedule({
  //       button_type: type
  //     })
  //     if (res) {
  //     }
  //   } catch (error: any) {
  //     console.log('error.message', error.message)
  //   }
  // }

  useBlocker(() => {
    if (when) {
      const confirm = window.confirm(message)
      if (confirm) {
        setInitial(false)
        change("cancel")
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  })

  // useEffect(() => {
  //   if (confirm) {
  //     console.log('confirm sucess')
  //   }
  // }, [confirm])
  return (
    when && <div key={when}>

    </div>
  )
}

export default Prompt