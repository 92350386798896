import styled from "@emotion/styled";

const Styles = styled.div`
  .ant-row {
    display: flex;
  }
  th,
  td {
    &.ant-table-cell {
      padding: 24px !important;
      &.col-date {
        padding: 0 !important;
        min-width: 20px;
      }
    }
  }
  table,
  th,
  td {
    padding: 20px;
    white-space: nowrap;
    border: 1px solid lightgray !important;
    border-collapse: collapse;
    .ant-form-item-explain-error {
      font-size: 12px;
    }
    .ant-form-item-explain-error {
      height: 0 !important;
    }
  }
`;
export default Styles;
