import { ReactNode, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Show } from "@chakra-ui/react";

type MenuItemType = {
  path: string;
  name?: ReactNode;
  isSub?: boolean;
  icon?: ReactNode;
  childrens?: Array<{ path: string; name?: ReactNode }>;
};
export interface IMenuItemProps {
  item: MenuItemType;
  className?: string;
  isActive?: boolean;
}

export default function MenuItem({
  item,
  className = "",
  isActive,
}: IMenuItemProps) {
  const navigate = useNavigate();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const handleClick = () => {
    setShowSubMenu(!showSubMenu);
  };
  const classActive = clsx("", {
    ["bg-[#EEEEEE]"]: isActive,
  });
  return (
    <div
      className={`cursor-pointer text-[14px] leading-6 text-[#000000] ${className}`}
    >
      <Link
        to={item?.path}
        className={`flex items-center justify-between mb-[13px] px-[18px] py-[13px] ${classActive}`}
      >
        <div
          onClick={handleClick}
          className={`flex items-center justify-between gap-x-[10px] w-[100%]`}
        >
          <div className="flex items-center gap-x-[10px]">
            <div className="text-[16px] leading-6 flex">{item?.icon}</div>
            <div>{item?.name}</div>
          </div>
          {item?.isSub && (
            <DownOutlined
              className="transition-all"
              style={{ transform: showSubMenu ? "rotate(180deg)" : "unset" }}
            />
          )}
        </div>
      </Link>
      {showSubMenu &&
        item?.childrens?.map((subItem, i) => (
          <div
            className={`pl-[24px] py-[12px] mb-[2px] leading-[18px] ${
              window.location.href.includes(subItem?.path) ? "bg-[#EEEEEE]" : ""
            }`}
            key={i}
          >
            {/* <Link to={subItem?.path}>{subItem?.name}</Link> */}
            <div onClick={() => navigate(subItem?.path)}>{subItem?.name}</div>
          </div>
        ))}
    </div>
  );
}
