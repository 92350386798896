/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { ButtonOutline, ButtonSolid } from "component/button";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import ROUTES from "constant/routes";
import apiFactory from "api";
import { Pagination } from "antd";
import CustomPagination from "component/CustomPagination";
interface DataType {
  key: React.Key;
  id: string;
  shiftName: string;
  workingHour: string;
  abbreviation: string;
  backgroundColor: string;
  letterColor: string;
  textColor: string;
}
const colors = [
  { value: "#fff", label: "white" },
  { value: "#eb4034", label: "red" },
  { value: "#f7f71b", label: "yellow" },
  { value: "#1bf738", label: "green" },
  { value: "#1b7af7", label: "blue" },
  { value: "#941bf7", label: "purple" },
  { value: "#f71bb1", label: "pink" },
];
const colorMap = new Map(
  colors.map((element) => [element.value, element.label])
);

export default function ShiftRegistrationList() {
  const [sortKey, setSortKey] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const navigate = useNavigate();
  const columns = useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: "ID",
        dataIndex: "shiftCode",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("shift_code");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
      },
      {
        title: "シフト名",
        dataIndex: "shiftName",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("name");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        render: (text) => {
          if (text.length > 30) {
            return text.slice(0, 30) + " ...";
          }
          return text;
        },
        width: "500px",
      },
      {
        title: "勤務時間",
        dataIndex: "workingHour",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("time");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        width: "120px",
      },
      {
        title: "略称",
        dataIndex: "abbreviation",
        sorter: {},
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("abbreviation");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        render: (text) => {
          if (text.length > 30) {
            return text.slice(0, 30) + " ...";
          }
          return text;
        },
      },
      {
        align: "center",
        title: "表示色",
        dataIndex: "backgroundColor",
        sorter: {},
        className: "",
        onHeaderCell: (column) => {
          return {
            onClick: () => {
              setSortKey("background_color");
              if (order === "descend") {
                setOrder("ascend");
              } else {
                setOrder("descend");
              }
            },
          };
        },
        render(text, record) {
          return {
            props: {
              style: { backgroundColor: `${record?.backgroundColor}` },
            },
            children: (
              <p style={{ color: `${record?.textColor}` }}>
                {record?.abbreviation}
              </p>
            ),
          };
        },
      },
      {
        align: "right",
        title: "",
        dataIndex: "action",
        render: (_, record) => (
          <ButtonOutline
            className="!text-[12px] !bg-[#FFFFFF] !leading-[20px] !text-[#E07B2E] !px-[19px] !h-[23px]"
            onClick={() => navigate(`${location.pathname}/${record.id}`)}
          >
            詳細
          </ButtonOutline>
        ),
      },
    ],
    [sortKey, order]
  );

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [dataList, setDataList] = useState([]);

  const fetchData = async () => {
    const result = await apiFactory.shiftRegistrationApi.getList({
      per: limit,
      page: page,
      order: order,
      sortKey: sortKey,
    });
    if (result) {
      setDataList(
        result?.shift_registrations.map((e: any) => ({
          id: e.id,
          shiftCode: e?.shift_code,
          shiftName: e.name,
          workingHour: `${e.start_time} ~ ${e.end_time}`,
          abbreviation: e.abbreviation,
          backgroundColor: e.background_color,
          textColor: e.text_color,
        }))
      );
      setTotalItems(result?.total_items);
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, limit, order, sortKey]);
  return (
    <div>
      <div className="page-title">管理メニュー　シフト登録</div>
      <div className="shift-registration-list page-container">
        <div className="pt-[7px]">
          <div className="text-end mb-[30px]">
            <ButtonSolid
              className="!h-[35px] !px-[54px] !text-[14px] !leading-[24px]"
              onClick={() =>
                navigate(
                  `/${ROUTES.ADMIN}/${ROUTES.MENU_SHIFT_REGISTRATION_ADD}`
                )
              }
            >
              新規追加
            </ButtonSolid>
          </div>
          <div className="flex items-center justify-end wrapper-pagination mb-[15px]">
            <CustomPagination
              current={page}
              setCurrent={setPage}
              total={totalItems}
              perPage={limit}
              setPerPage={setLimit}
              pageSizeOptions={[10, 20, 50, 100]}
            />
          </div>
          <Table
            bordered
            showSorterTooltip={{ title: "" }}
            rowClassName={(record, index) => {
              if (index % 2 !== 0) {
                return "bg-[#f5f4f2]";
              }
              return "";
            }}
            columns={columns}
            dataSource={dataList}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [5, 10],
              pageSize: limit,
              position: ["bottomCenter"],
              style: { display: "none" },
            }}
          />
          <div className="flex items-center justify-end wrapper-pagination mt-[15px]">
            <CustomPagination
              current={page}
              setCurrent={setPage}
              total={totalItems}
              perPage={limit}
              setPerPage={setLimit}
              pageSizeOptions={[10, 20, 50, 100]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
