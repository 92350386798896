import { useState } from "react"
import { AccidentCareServiceDetail } from "./AccidentCareServiceDetail"
import { AccidentCareServiceList } from "./AccidentCareServiceList"
import './style.scss'

const AccidentCareService = () => {
    const [isList, setIsList] = useState<boolean>(true)
    const [serviceId, setServiceId] = useState(null)
    const [difference, setDifference] = useState({
        type: ''
    })
    const onChange = () => {
        setIsList(!isList)
    }
    return <div className="page-container accident-care-service">
        {
            isList ? <AccidentCareServiceList onChange={onChange} setServiceId={setServiceId} setDifference={setDifference} /> :
                <AccidentCareServiceDetail onChange={onChange} id={serviceId} setServiceId={setServiceId}
                    difference={difference} setDifference={setDifference}
                />
        }
    </div>

}
export { AccidentCareService }
