import { STAFF_ROUTINES } from "../apiConstant";
import BaseApi from "api/baseApi";

class RoutineApi extends BaseApi {
  getRoutines() {
    return this.get(STAFF_ROUTINES);
  }

  updateRoutines(data: { value: number; text: string; checked: boolean }[]) {
    this.setSuccessMessage("登録に成功しました");

    // Convert data to FormData
    const formData = new FormData();
    formData.append(`data`, JSON.stringify(data));

    return this.post(STAFF_ROUTINES, formData, {
      header: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default RoutineApi;
