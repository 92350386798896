/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { ButtonOutline, ButtonSolid } from "component/button";
import { Form, Empty, Input, Modal, Tabs, TabsProps, Tree } from "antd";
import {
  CaretDownOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { GrClose } from "react-icons/gr";
import apiFactory from "api";
import "./style.scss";
import CustomPagination from "component/CustomPagination";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsDeletedAction } from "store/sagas/notice/hsStaffNoticeSaga";
import { RootState } from "store";

const DetailList = ({ data }: any) => {
  const generate = (e: string) => {
    return <div>{e}</div>;
  };
  return <div> {data?.map((e: any) => generate(e?.name))}</div>;
};

const DetailModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  read,
  unread,
}: any) => {
  const onChange = (key: string) => {};

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `既読(${read?.length})`,
      children: <DetailList data={read} />,
    },
    {
      key: "2",
      label: `未読(${unread?.length})`,
      children: <DetailList data={unread} />,
    },
  ];
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<GrClose />}
    >
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Modal>
  );
};

const PostList = ({
  setPart,
  setPostId,
  part,
  setDetailType,
  setReplyTo,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [dataList, setDataList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [read, setRead] = useState([]);
  const [unread, setUnread] = useState([]);
  const [searchContent, setSearchContent] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { tab } = useSelector((state: RootState) => state.hsStaffNotice);

  const generatePost = ({ e, index }: any) => {
    return (
      <div>
        {index === 0 && <div className="underline-news" />}
        <Tree
          expandedKeys={expandedKeys
            .filter((f: any) => f.disabled === false)
            .map((f: any) => f.key)}
          autoExpandParent={false}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={[e]}
          showIcon={true}
        />
        <div className="underline-news" />
      </div>
    );
  };

  function updatePropertyById({ id, property, data, value }: any) {
    if (data.id === id) {
      if (property === "children") {
        data["children"] = value;
        if (value?.length > 0) {
          data.icon = <CaretDownOutlined />;
        }
      } else {
        data.icon = <RightOutlined />;
      }
      return;
    } else {
      if (data.children !== undefined && data.children.length > 0) {
        data.children.forEach((e: any) => {
          e = updatePropertyById({ id, property, data: e, value });
        });
      }
      return;
    }
  }

  const expandNode = async (key: number, isDeleted: boolean) => {
    const index = expandedKeys.findIndex((e: any) => e.key === key);
    if (index > -1) {
      expandedKeys[index].disabled = !expandedKeys[index].disabled;
      setExpandedKeys([...expandedKeys]);
      dataList.forEach((e: any) => {
        if (e.key === key) {
          updatePropertyById({ id: key, property: "icons", data: e });
        }
      });
    } else {
      expandedKeys.push({
        key: key,
        disabled: false,
      });
      setExpandedKeys([...expandedKeys]);
      const res = await apiFactory.boardAdminApi.getByParent(key);
      if (res) {
        const result = res?.notifications?.map((e: any) => ({
          key: e?.id,
          icon: e?.is_child_exists ? (
            <RightOutlined />
          ) : (
            <>
              <RightOutlined className="hidden" />
            </>
          ),
          title: e?.is_deleted ? (
            <span className="!cursor-pointer">
              <span>
                <span className="line-through inline-flex text-[#000000e0]">
                  <span className="title-post inline-block">{e?.title}</span>(
                  {e?.created_at})
                </span>
              </span>
              &#12288;
              <span className="line-through text-[#000000e0]">
                既読 {e?.notification_view_logs?.length}
              </span>
              <span>＞</span>
            </span>
          ) : (
            <span>
              <span onClick={(f) => onViewDetail(f, e, isDeleted)}>
                {e?.is_important && <span className="important">重</span>}&nbsp;
                <span className="underline inline-flex">
                  <span className="title-post inline-block">{e?.title}</span> (
                  {e?.created_at})
                </span>
              </span>
              &#12288;
              <span onClick={(f) => showModal({ e: f, item: e })}>
                <span className="underline">
                  既読 {e?.notification_view_logs?.length}
                </span>
                <span>＞</span>
              </span>
            </span>
          ),
          id: e?.id,
          content: e?.content,
          level: e?.level,
          replayTo: e?.replay_to,
          isImportant: e?.is_important,
          isDeleted: e?.is_deleted,
          createdAt: e?.create_at,
          replies: e?.replies,
          viewCount: e?.notification_view_logs?.length,
          mentions: e?.mention_notifications,
          is_child_exists: e?.is_child_exists,
          children: e?.is_child_exists ? [{}] : [],
          collapse: true,
          // disabled: e?.is_deleted,
        }));
        dataList.forEach((e: any) => {
          updatePropertyById({
            id: key,
            property: "children",
            data: e,
            value: result,
          });
        });
      }
      setDataList([...dataList]);
    }
  };

  const showModal = async ({ e, item }: any) => {
    e.stopPropagation();
    const result = await apiFactory.boardAdminApi.getPostDetail(item?.id);
    setRead(result?.notification_view_logs);
    setIsModalOpen(true);
    setUnread(item.unread_list);
  };

  const onViewDetail = (e: any, item: any, isDeleted?: any) => {
    // dispatch(noticeSlice?.actions?.setIsDeleted(isDeleted));
    dispatch(setIsDeletedAction(isDeleted));
    e.stopPropagation();
    setReplyTo(item.reply_to);
    // setPart("CommentDetail");
    setPostId(item.id);
    navigate(`/admin/comment-detail/${item?.id}`, {
      state: { isDeleted: isDeleted },
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = async () => {
    const result = await apiFactory.boardAdminApi.getPostList({
      per: limit,
      page: page,
      search: searchContent,
    });
    if (result) {
      setDataList(
        result?.notifications?.map((e: any) => ({
          key: e?.id,
          icon: e?.is_child_exists ? (
            <RightOutlined className="!cursor-pointer" />
          ) : (
            <></>
          ),
          title: e?.is_deleted ? (
            <span className="!cursor-pointer">
              <span>
                {e?.is_important && <span className="important">重</span>}&nbsp;
                <span className="line-through	text-[#000000e0] inline-flex">
                  <span className="title-post inline-block">{e?.title}</span>
                  &nbsp; (
                  {e?.sender_type === "UserAdmin" && (
                    <span>管理人 &nbsp; </span>
                  )}
                  {e?.poster}
                  {e?.sender_type === "UserAdmin" ? (
                    <span>様 &nbsp;</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                  {e?.created_at})
                </span>
              </span>
              &#12288;
              <span className="line-through	text-[#000000e0]">
                既読 {e?.notification_view_logs?.length}
              </span>
              <span>＞</span>
            </span>
          ) : (
            <span>
              <span onClick={(f) => onViewDetail(f, e)}>
                {e?.is_important && <span className="important">重</span>}&nbsp;
                <span className="underline inline-flex">
                  <span className="title-post inline-block">{e?.title}</span>{" "}
                  &nbsp; (
                  {e?.sender_type === "UserAdmin" && (
                    <span>管理人 &nbsp; </span>
                  )}
                  {e?.poster}
                  {e?.sender_type === "UserAdmin" ? (
                    "様　"
                  ) : (
                    <span>&nbsp;</span>
                  )}
                  {e?.created_at})
                </span>
              </span>
              &#12288;
              <span onClick={(f) => showModal({ e: f, item: e })}>
                <span className="underline">
                  既読 {e?.notification_view_logs?.length}
                </span>
                <span>＞</span>
              </span>
            </span>
          ),
          id: e?.id,
          content: e?.content,
          level: e?.level,
          replayTo: e?.replay_to,
          isImportant: e?.is_important,
          isDeleted: e?.is_deleted,
          createdAt: e?.create_at,
          replies: e?.replies,
          viewCount: e?.notification_view_logs?.length,
          mentions: e?.mention_notifications,
          is_child_exists: e?.is_child_exists,
          children: e?.is_child_exists ? [{}] : [],
          collapse: true,
          // disabled: e?.is_deleted,
        }))
      );
      setTotalItems(result?.total_items);
    }
  };

  const onSelect = (selectedKeys: any, info: any) => {
    expandNode(info?.node?.key, info?.node?.isDeleted);
    setSelectedKeys(selectedKeys);
  };

  useEffect(() => {
    fetchData();
    setExpandedKeys([]);
  }, [part, limit, page, tab]);

  useEffect(() => {
    setPostId(null);
  }, []);

  useEffect(() => {
    Number(tab) !== 1 &&
      form.setFieldsValue({
        searchKeyword: "",
      });
    setLimit(10);
    setPage(1);
    setSearchContent("");
  }, [tab]);

  const handleSubmitSearch = async (e: any) => {
    setLimit(10);
    setPage(1);
    const result = await apiFactory.boardAdminApi.getPostList({
      per: limit,
      page: page,
      search: e?.searchKeyword,
    });
    if (result) {
      setDataList(
        result?.notifications?.map((e: any) => ({
          key: e?.id,
          icon: e?.is_child_exists ? (
            <RightOutlined className="!cursor-pointer" />
          ) : (
            <></>
          ),
          title: e?.is_deleted ? (
            <span className="!cursor-pointer">
              <span>
                {e?.is_important && <span className="important">重</span>}&nbsp;
                <span className="line-through	text-[#000000e0] inline-flex">
                  <span className="title-post inline-block">{e?.title}</span>
                  &nbsp; (
                  {e?.sender_type === "UserAdmin" && (
                    <span>管理人 &nbsp; </span>
                  )}
                  {e?.poster}
                  {e?.sender_type === "UserAdmin" ? (
                    <span>様 &nbsp;</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                  {e?.created_at})
                </span>
              </span>
              &#12288;
              <span className="line-through	text-[#000000e0]">
                既読 {e?.notification_view_logs?.length}
              </span>
              <span>＞</span>
            </span>
          ) : (
            <span>
              <span onClick={(f) => onViewDetail(f, e)}>
                {e?.is_important && <span className="important">重</span>}&nbsp;
                <span className="underline inline-flex">
                  <span className="title-post inline-block">{e?.title}</span>
                  &nbsp; (
                  {e?.sender_type === "UserAdmin" && (
                    <span>管理人 &nbsp; </span>
                  )}
                  {e?.poster}
                  {e?.sender_type === "UserAdmin" ? (
                    "様　"
                  ) : (
                    <span>&nbsp;</span>
                  )}
                  {e?.created_at})
                </span>
              </span>
              &#12288;
              <span onClick={(f) => showModal({ e: f, item: e })}>
                <span className="underline">
                  既読 {e?.notification_view_logs?.length}
                </span>
                <span>＞</span>
              </span>
            </span>
          ),
          id: e?.id,
          content: e?.content,
          level: e?.level,
          replayTo: e?.replay_to,
          isImportant: e?.is_important,
          isDeleted: e?.is_deleted,
          createdAt: e?.create_at,
          replies: e?.replies,
          viewCount: e?.notification_view_logs?.length,
          mentions: e?.mention_notifications,
          is_child_exists: e?.is_child_exists,
          children: e?.is_child_exists ? [{}] : [],
          collapse: true,
          // disabled: e?.is_deleted,
        }))
      );
      setTotalItems(result?.total_items);
    }
  };

  return (
    <div className="news-board">
      <div className="py-[16px] px-[25px] bg-[#F5F4F2]">
        <p className="text-[#000000] text-[12px] leading-[20px] mb-[8px]">
          キーワード
        </p>
        <div className="flex gap-x-[21px]">
          <Form
            initialValues={{ searchKeyword: "" }}
            form={form}
            onFinish={handleSubmitSearch}
            className="w-[100%]"
          >
            <div className="flex gap-x-[21px]">
              <Form.Item name="searchKeyword" className="w-[100%]">
                <Input
                  className="customInp border-[#707070] w-[100%]"
                  placeholder="全文検索"
                  prefix={
                    <SearchOutlined className="text-[16px] text-[#2699FB] mr-[10px]" />
                  }
                  value={searchContent}
                  onChange={(e: any) => setSearchContent(e.target.value)}
                />
              </Form.Item>
              <ButtonOutline
                type="submit"
                className="!py-[6px] !px-[63px] !bg-[#FFFFFF]"
                _hover={{
                  color: "brand.500]",
                  bg: "brand.100",
                }}
              >
                検索
              </ButtonOutline>
            </div>
          </Form>
        </div>
      </div>
      <div className="text-end mt-[19px] mb-[21px]">
        <ButtonSolid
          onClick={() => {
            setReplyTo(null);
            setDetailType("add");
            setPart("PostDetail");
          }}
        >
          親スレッド投稿
        </ButtonSolid>
      </div>

      {dataList?.length > 0 ? (
        <div>
          <div>
            <CustomPagination
              current={page}
              setCurrent={setPage}
              total={totalItems}
              perPage={limit}
              setPerPage={setLimit}
              pageSizeOptions={[10, 20, 50, 100]}
            />

            {dataList.map((e, index) => generatePost({ e, index }))}
            <CustomPagination
              current={page}
              setCurrent={setPage}
              total={totalItems}
              perPage={limit}
              setPerPage={setLimit}
              pageSizeOptions={[10, 20, 50, 100]}
            />
            {DetailModal({ isModalOpen, handleOk, handleCancel, read, unread })}
          </div>
          {DetailModal({ isModalOpen, handleOk, handleCancel, read, unread })}
        </div>
      ) : (
        <Empty
          description="データがありません"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
};

export { PostList, DetailModal };
