import { Badge } from "antd";
import apiFactory from "api";
import PageHeaderTitle from "component/PageHeaderTitle";
import ContentLayout from "component/home-system/ContentLayout";
import NotificationCollapse from "component/home-system/NotificationCollapse";
import ROUTES from "constant/routes";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import noticeSlice from "store/slice/noticeSlice";
import { FORMAT } from "utils";
import { DashboardResponse, NotificationItem, Room } from "./_type";
import StyledNotificationItem from "./styled";

const sortAndSliceNotifications = <T extends Record<string, any>>(
  items: T[],
  sliceCount: number = 10
): T[] => {
  if (!items) {
    return [];
  }

  return items.length ? items.slice(0, sliceCount) : [];
};

const AdminDashboard = () => {
  const { id: userId } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //--- State
  const [dataDashboard, setDataDashboard] = useState<DashboardResponse | null>(
    null
  );

  //--- Get data dashboard
  const getDataDashboard = async () => {
    const _data = await apiFactory.hsAdminApi.getDashboard();

    if (_data) {
      _data.notifications = sortAndSliceNotifications<NotificationItem>(
        _data.notifications
      );
      _data.rooms = sortAndSliceNotifications<Room>(_data.rooms);

      setDataDashboard(_data);
    }
  };

  //-- Redirect to contact page
  const onContact = (e: Room) => {
    const _targetName =
      (e.source_id === userId ? e.target.user_name : e.source.user_name) || "";

    navigate(
      `/${ROUTES.HOME_SYSTEM}/${ROUTES.CONTACT_CHAT.replace(
        ":id",
        e.id.toString()
      )}`,
      {
        state: {
          title: _targetName,
        },
      }
    );
    dispatch(noticeSlice?.actions?.setTitle(_targetName));
  };

  useEffect(() => {
    getDataDashboard();
  }, []);

  return (
    <>
      <PageHeaderTitle title="ダッシュボード" />

      <ContentLayout className="mt-9">
        {dataDashboard && (
          <div className="flex flex-col gap-y-[42px]">
            <NotificationCollapse heading={"掲示板"} activeKey={"1"}>
              {dataDashboard.notifications.map((note) => (
                <StyledNotificationItem
                  key={note.id}
                  className="py-3 px-4 flex gap-2 item"
                >
                  <div className="max-w-[230px] flex-1 break-keep">
                    <p className="font-semibold text-base">
                      {note?.patient?.family_name}
                    </p>
                    <p>
                      <span className="text-[rgba(0,0,0,0.55)]">送信日:</span>{" "}
                      <span>{dayjs(note.created_at).format(FORMAT)}</span>
                    </p>
                    <p>
                      <span className="text-[rgba(0,0,0,0.55)]">展開日:</span>{" "}
                      <span>{dayjs(note.deployment_date).format(FORMAT)}</span>
                    </p>
                  </div>
                  <div>
                    <p>{note.content}</p>
                  </div>
                </StyledNotificationItem>
              ))}
            </NotificationCollapse>

            <NotificationCollapse
              heading={
                <>
                  個別連絡{" "}
                  <Badge count={`${dataDashboard.rooms_unread_count} 未読`} />
                </>
              }
            >
              {dataDashboard.rooms.map((room) => (
                <StyledNotificationItem
                  key={room.id}
                  className="py-3 px-4 flex gap-2 item"
                >
                  <div className="min-w-[230px]">
                    <p
                      className="font-semibold text-base hover:cursor-pointer"
                      onClick={() => onContact(room)}
                    >
                      {room.source_id === userId
                        ? room.target.user_name
                        : room.source.user_name}
                    </p>
                    <p>
                      <span className="text-[rgba(0,0,0,0.55)]">送信日:</span>{" "}
                      <span>{dayjs(room.lastest_send_at).format(FORMAT)}</span>
                    </p>
                  </div>
                  <div>
                    <p>{room.latest_msg.content}</p>
                  </div>
                </StyledNotificationItem>
              ))}
            </NotificationCollapse>
          </div>
        )}
      </ContentLayout>
    </>
  );
};

export default AdminDashboard;
