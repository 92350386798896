import { createSlice } from "@reduxjs/toolkit";

const initialState: INoticeState = {
  tab: "1",
  title: "",
  detailType: "",
  isDeleted: false,
  boardInnerTab: "1",
};

export interface INoticeState {
  tab: string;
  title: string;
  detailType: string;
  isDeleted: boolean;
  boardInnerTab: string;
}

const noticeSlice = createSlice({
  initialState,
  name: "notice",
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDetailType: (state, action) => {
      state.detailType = action.payload;
    },
    setIsDeleted: (state, action) => {
      state.isDeleted = action.payload;
    },
    setBoardInnerTab: (state, action) => {
      state.boardInnerTab = action.payload;
    },
  },
});
export const { setIsDeleted } = noticeSlice.actions;
export default noticeSlice;
